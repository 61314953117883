<template>
  <div v-if="[{}, null, undefined].includes(InfoDay)">
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-icon center v-bind="attrs" v-on="on">
          mdi-information-off-outline
        </v-icon>
      </template>
      <span>Sin información</span>
    </v-tooltip>
  </div>
  <div v-else-if="InfoDay.falta === 1 && !InfoDay.festivo">falta</div>
  <div v-else-if="InfoDay.falta === 1 && InfoDay.festivo">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon center v-bind="attrs" v-on="on"> mdi-party-popper </v-icon>
      </template>
      <span>Día festivo</span>
    </v-tooltip>
  </div>
  <div v-else-if="!InfoDay.labora">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon center v-bind="attrs" v-on="on"> mdi-coffee </v-icon>
      </template>
      <span>Descansa</span>
    </v-tooltip>
  </div>
  <div v-else-if="Object.keys(InfoDay.incidencia).length === 0" align="center">
    {{ InfoDay.ingreso }} - {{ InfoDay.salida }}
  </div>
  <!--  -->
  <div
    v-else-if="Object.keys(InfoDay.incidencia).length > 0"
    align="center"
  >
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          v-if="InfoDay.incidencia.desc == 'Incapacidad'"
          >mdi-hospital-box</v-icon
        >
        <v-icon
          v-bind="attrs"
          v-on="on"
          v-if="InfoDay.incidencia.desc == 'Tiempo por Tiempo'"
          >mdi-history</v-icon
        >
        <div v-if="InfoDay.incidencia.desc == 'Salida anticipada'">
          {{ InfoDay.entrada }}
          <v-icon v-bind="attrs" v-on="on">mdi-door-open</v-icon>
          {{ InfoDay.salida }}
        </div>
        <div v-if="InfoDay.incidencia.desc == 'Llegada tarde'">
          {{ InfoDay.entrada }}
          <v-icon v-bind="attrs" v-on="on">mdi-clock</v-icon>
          {{ InfoDay.salida }}
        </div>
        <v-icon
          v-bind="attrs"
          v-on="on"
          v-if="InfoDay.incidencia.desc == 'Permiso especial'"
          >mdi-account-child-circle</v-icon
        >
        <v-icon
          v-bind="attrs"
          v-on="on"
          v-if="InfoDay.incidencia.desc == 'Vacaciones'"
          >mdi-beach</v-icon
        >
        <v-icon
          v-bind="attrs"
          v-on="on"
          v-if="
            ['Home office', 'Teletrabajo'].includes(InfoDay.incidencia.desc)
          "
          >mdi-home</v-icon
        >
        <v-icon
          v-bind="attrs"
          v-on="on"
          v-if="InfoDay.incidencia.desc == 'Flex time'"
          >mdi-timer-sync-outline</v-icon
        >
        <v-icon
          v-bind="attrs"
          v-on="on"
          v-if="InfoDay.incidencia.desc == 'Permuta'"
          >mdi-briefcase-arrow-up-down</v-icon
        >
        <!-- <v-icon
          v-bind="attrs"
          v-on="on"
          v-if="getStatus(InfoDay) == 'Teletrabajo'"
          >mdi-home-account</v-icon
        > -->
      </template>
      <span>{{ InfoDay.incidencia.desc }}</span>
    </v-tooltip>
    <div v-if="InfoDay.incidencia.desc == 'Flex time'">
      {{ InfoDay.ingreso }} - {{ InfoDay.salida }}
    </div>
  </div>
  <div v-else>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-icon center v-bind="attrs" v-on="on">
          mdi-information-off-outline
        </v-icon>
      </template>
      <span>Sin información</span>
    </v-tooltip>
  </div>
</template>
  
  <script>
export default {
  name: "reporte-asistencias-informacion-de-dia",
  data: () => ({}),
  components: {},
  props: ["InfoDay"],
  mounted() {},
  methods: {
    getStatus(item) {
      if (
        item.inc == 0 &&
        item.entrada == null &&
        item.entrada == null &&
        item.labora
      ) {
        return "falta";
      } else {
        if (item.length == 0) {
          return "n-i";
        } else if (item.inc != 0) {
          return item.inc;
        } else if (!item.labora) {
          return "n-l";
        } else {
          return "no inc";
        }
      }
    },
  },
};
</script>