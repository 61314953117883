<template>
  <v-dialog v-model="dialog" max-width="70%" transition="dialog-bottom-transition" scrollable persistent
    content-class="fix-border-dialog">
    <v-card>
      <v-card-title class=" primary white--text">
        <h3> Crear solicitud</h3>
        <v-spacer></v-spacer>
        <v-icon class="mr-4" v-if="!alert" dark @click="alert = true">mdi-chat-question
        </v-icon>
        <v-icon dark @click="$emit('cierraFormInconformidades', 'cancelar')">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-alert v-model="alert" color="cyan" border="left" elevation="2" colored-border dismissible>
          <v-row align="center">
            <v-col class="grow">
              <div class="font-italic">
                Aclaraciones con respecto a vacaciones, ausentismos, fondo de
                ahorro o tiempo extra con su nomina. Clic en
                <label @click="openFormAclaraciones = true" style="color: green">SOLICITAR ACLARACION.</label>
              </div>
            </v-col>
            <v-col class="shrink">
              <v-btn class="rounded-lg elevation-0" color="teal darken-1" dark small
                @click="openFormAclaraciones = true" :loading="loading">
                Solicitar aclaración
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <v-form class="mt-1" ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" xl="7" lg="7" md="7" sm="12" xs="12">
              <v-combobox v-model="form.motivo" :items="motivos" label="¿Cuál es su motivo de solicitud?" hide-selected
                outlined small-chips item-text="motivo" item-value="id" color="cyan" clearable @change="onMotivoChange">
                <template v-slot:selection="data">
                  <v-chip :key="JSON.stringify(data.item)" v-bind="data.attrs" :input-value="data.selected"
                    :disabled="data.disabled" @click:close="data.parent.selectItem(data.item)">
                    <v-avatar class="indigo darken-4 white--text" left>
                      {{ data.item.inicial }}
                    </v-avatar>
                    {{ data.item.motivo }}
                  </v-chip>
                </template>
              </v-combobox>

            </v-col>
            <v-col cols="12" xl="5" lg="5" md="5" sm="12" xs="12">
              <v-menu v-model="calendario" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">

                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="lblDate" label="Fecha de solicitud" prepend-inner-icon="mdi-calendar" readonly
                    outlined color="cyan" :disabled="habilitarFecha" v-bind="attrs" v-on="on">
                  </v-text-field>
                </template>

                <v-date-picker v-model="form.fechaInconformidad" @input="closePicker">
                </v-date-picker>
              </v-menu>

            </v-col>
            <v-col v-show="habilitarTarjetas" cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
              <v-switch v-model="reporteAnonimo" inset
                :label="`Reportar de manera anónima: ${reporteAnonimo ? 'Sí' : 'No'}`" />
            </v-col>
            <v-col v-show="habilitarTarjetas" cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
              <v-select prepend-icon="mdi-factory" :items="itemsPlantas" item-text="descripcion" v-model="plantaId"
                label="Selecciona tu planta" outlined></v-select>
            </v-col>
            <v-col v-show="habilitarTarjetas" cols="12">
              <v-row>
                <v-col cols="auto">
                  <v-checkbox v-model="selectedTipo" label="Acto inseguro" value="Acto inseguro"></v-checkbox>
                </v-col>
                <v-col cols="auto">
                  <v-checkbox v-model="selectedTipo" label="Condición insegura" value="Condición insegura"></v-checkbox>
                </v-col>
                <v-col cols="auto">
                  <v-checkbox v-model="selectedTipo" label="Maquina insegura" value="Maquina insegura"></v-checkbox>
                </v-col>
                <v-col cols="auto">
                  <v-checkbox v-model="selectedTipo" label="Falta de método de trabajo"
                    value="Falta de método de trabajo"></v-checkbox>
                </v-col>
                <v-col cols="auto">
                  <v-checkbox v-model="selectedTipo" label="Falta de certificación"
                    value="Falta de certificación"></v-checkbox>
                </v-col>
                <v-col cols="auto">
                  <v-checkbox v-model="selectedTipo" label="Falta de certificación" value="Falta de certificación"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-show="habilitarTarjetas" cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
              <v-text-field v-model="form.maquina"
                label="¿Podría contarnos que maquina y linea es donde se presenta el problema?" :rules="nameRules"
                maxlength="450" row-height="2" type="text" required outlined counter color="cyan"></v-text-field>
            </v-col>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
              <v-textarea auto-grow :disabled="item ? true : false" v-model="form.comentario"
                label="¿Nos podrías contar un poco más sobre el problema que estás teniendo u observando?"
                :rules="nameRules" maxlength="450" row-height="2" type="text" required outlined counter
                color="cyan"></v-textarea>
            </v-col>
          </v-row>
          <v-row v-if="!item">
            <v-col class="d-flex justify-end" cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
              <v-expand-transition transition="slide-y-transition">
                <div v-show="expand">
                  <v-avatar size="50" rounded>
                    <img :src="vistapreviaimg" />
                  </v-avatar>
                  <v-avatar size="50" rounded>
                    <img :src="vistapreviaimg1" />
                  </v-avatar>
                  <v-avatar size="50" rounded>
                    <img :src="vistapreviaimg2" />
                  </v-avatar>
                  <v-avatar size="50" rounded>
                    <img :src="vistapreviaimg3" />
                  </v-avatar>
                  <v-btn v-if="fileBase64.length < 4" class="mx-2" fab dark small color="teal" @click="openFileInput">
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </div>
              </v-expand-transition>
              <v-tooltip top color="teal lighten-2">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-if="!expand" class="rounded-lg mx-2" small depressed dark color="teal"
                    @click="expand = !expand" v-bind="attrs" v-on="on">
                    <v-icon left>mdi-image-plus</v-icon>Cargar evidencia
                  </v-btn>
                </template>
                <span>Opcional</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
              <v-file-input v-model="evidenciaInconformidad" ref="fileInput" v-on:change="cargarImg"
                placeholder="Ahora puedes agregar evidencia" show-size accept="image/*" class="rounded-lg"
                style="display: none" outlined dense></v-file-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end">
        <v-spacer></v-spacer>

        <v-btn v-if="habilitarLista && !item" class="rounded-lg elevation-0" color="teal darken-1" dark small
          @click="openEvaluacionInconformidad = true">
          <v-icon left>mdi-table-search</v-icon>Mis solicitudes
        </v-btn>
        <v-btn v-if="form.comentario && form.motivo && form.fechaInconformidad &&
          (!habilitarTarjetas || (habilitarTarjetas && form.maquina))" class="rounded-lg elevation-0 mx-2"
          color="teal darken-1" :loading="loading" dark small @click="enviar">
          <v-icon left>mdi-send-variant-outline</v-icon> Enviar
        </v-btn>
        <v-btn class="rounded-lg elevation-0" v-else disabled small>
          <v-icon left>mdi-send-variant-outline</v-icon> Enviar
        </v-btn>
      </v-card-actions>
      <v-dialog v-model="loading" max-width="600px" transition="dialog-bottom-transition" persistent>
        <v-card>
          <v-card-title class="text-subtitle-2 primary white--text">
            Guardando solicitud
            <v-spacer></v-spacer>
          </v-card-title>
          <div v-show="loading" class="text-center">
            <v-progress-circular color="primary" indeterminate :size="70" :width="7"></v-progress-circular>
            <h2 class="text-center blue-grey--text text--lighten-2 py-5 mb-5">
              Por favor espere...
            </h2>
          </div>
        </v-card>
      </v-dialog>
      <SnackBar v-if="muestraBarra" :muestraBarra="muestraBarra" :texto="textoBarra" :color="colorBarra"
        @cierraBarra="muestraBarra = false" />
    </v-card>

    <lista-inconformidades-colaborador v-if="openEvaluacionInconformidad" :dialog="openEvaluacionInconformidad"
      ruta="notificacion" @cierraReporteInconformidades="cerrarReporte">
    </lista-inconformidades-colaborador>
    <mi-nomina-form-aclaraciones v-if="openFormAclaraciones" :dialog="openFormAclaraciones"
      @cierraFormAclaraciones="openFormAclaraciones = false"></mi-nomina-form-aclaraciones>
  </v-dialog>
</template>

<script>
import axios from "axios";
import SnackBar from "../../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import { formatDate, deleteToken } from "../../store/Funciones/funciones";
import MiNominaFormAclaraciones from "./MiNominaFormAclaraciones.vue";
import ListaInconformidadesColaborador from '../../views/generated/AdmGeneral/Inconformidades/components/ListaInconformidadesColaborador.vue';


export default {
  name: "mi-nomina-form-inconformidades",
  components: {
    SnackBar,
    MiNominaFormAclaraciones,
    ListaInconformidadesColaborador,
  },
  props: ["dialog", "item"],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,

    valid: true,
    form: {
      motivo: "",
      comentario: "",
      fechaInconformidad: "",
      maquina: "",
    },
    nameRules: [
      (v) => !!v || "El comentario es requerido",
      (v) =>
        v.length < 450 || "Llegaste al limite de caracteres del comentario",
    ],
    motivos: [],
    loading: false,
    calendario: false,
    lblDate: "",
    openFormAclaraciones: false,
    alert: true,
    verMotivos: false,
    evidenciaInconformidad: null,
    vistapreviaimg: "",
    vistapreviaimg1: "",
    vistapreviaimg2: "",
    vistapreviaimg3: "",
    expand: false,
    fileBase64: [],
    nombresFotosEvidencia: [],
    habilitarLista: false,
    openEvaluacionInconformidad: false,
    //
    habilitarTarjetas: false,
    reporteAnonimo: false,
    habilitarFecha: false,
    loadingPlantas: false,
    itemsPlantas: [],
    plantaId: 0,
    selectedTipo: [],
  }),
  created() { },
  mounted() {
    this.getInconformidades();
    this.getMotivosInconformidades();
    this.saveMonitoreo();
    this.tipoAccion();
    this.getPlantas();
    this.vistapreviaimg = require("@/assets/img/img-icon.png");
    this.vistapreviaimg1 = require("@/assets/img/img-icon.png");
    this.vistapreviaimg2 = require("@/assets/img/img-icon.png");
    this.vistapreviaimg3 = require("@/assets/img/img-icon.png");
  },
  methods: {
    saveMonitoreo() {
      let params = {
        Opcion: 10,
        pantalla: "Formulario inconformidades",
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          console.log(response.data);
        });
    },
    enviar() {
      this.loading = true;
      if (!this.item) {
        let params = {
          Opcion: 1,
          inconformidad: this.form,
          anonimo: this.reporteAnonimo,
          tipo: this.selectedTipo,
          planta: this.plantaId,
          token: localStorage.getItem("token"),
          evidencia: this.fileBase64,
          evidencia2: [
            this.vistapreviaimg,
            this.vistapreviaimg1,
            this.vistapreviaimg2,
            this.vistapreviaimg3,
          ],
          filename: this.nombresFotosEvidencia,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
            params
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status == "OK") {
              this.textoBarra = "Inconformidad registrada correctamente.";
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.clean();
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve(this.$emit("cierraFormInconformidades", "OK"));
                }, 2000);
              });
            } else if (response.data.status == "ERROR") {
              this.textoBarra = "Se ha producido un error al registrar .";
              this.colorBarra = "error";
              this.muestraBarra = true;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            }
          });
      } else {
        this.editar();
      }
    },
    editar() {
      let params = {
        Opcion: 10,
        form: this.form,
        token: localStorage.getItem("token"),
      };
      console.log(params);
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
          params
        )
        .then((response) => {
          this.loading = false;
          if (response.data.status == "OK") {
            this.textoBarra = "Inconformidad registrada correctamente.";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.clean();
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve(this.$emit("cierraFormInconformidades", "OK"));
              }, 2000);
            });
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "Se ha producido un error al registrar .";
            this.colorBarra = "error";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
    },
    getMotivosInconformidades() {
      //   this.loading = true;
      let params = {
        Opcion: 3,
        ruta: "form",
        token: localStorage.getItem("token"),
      };
      console.log(params);
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
          params
        )
        .then((response) => {
          //   this.loading = false;
          if (response.data.status == "OK") {
            this.motivos = response.data.motivos;
          } else if (response.data.status == "VACIO") {
            console.log("vacio");
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
    },
    getInconformidades() {
      let params = {
        Opcion: 2,
        ruta: "notificacion",
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.habilitarLista = true;
          } else if (response.data.status == "VACIO") {
            console.log("Vacio");
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
    },
    cerrarReporte(response) {
      console.log(response);
      this.openEvaluacionInconformidad = false;
    },
    clean() {
      this.form.motivo = "";
      this.form.comentario = "";
      this.form.fechaInconformidad = "";
      this.form.maquina = "";
      this.lblDate = "";
      this.plantaId = null;
    },
    closePicker() {
      this.calendario = false;
      this.fixFormatDate();
    },
    fixFormatDate() {
      this.lblDate = formatDate(this.form.fechaInconformidad);
    },
    openFileInput() {
      // Obtener la referencia al componente v-file-input
      const fileInput = this.$refs.fileInput;

      // Activar el método click() del componente v-file-input para abrir el selector de archivos
      fileInput.$el.querySelector('input[type="file"]').click();
    },
    validarEvidencia() {
      const params = {
        Opcion: 1,
        inconformidad: this.form,
        token: localStorage.getItem("token"),
        evidencia: this.fileBase64,
        filename: this.nombresFotosEvidencia,
      };
      console.log(params);
    },

    cargarImg() {
      if (this.evidenciaInconformidad) {
        let cont = this.fileBase64.length;
        this.nombresFotosEvidencia.push(this.evidenciaInconformidad.name);
        this.fileBase64.push(
          this.readFileAsBase64(this.evidenciaInconformidad, cont)
        );
        // console.log(this.fileBase64);
        // console.log(this.fileBase64.length);
      }
    },

    async readFileAsBase64(file, cont) {
      return new Promise((resolve, reject) => {
        if (!file) {
          reject(new Error("No file provided."));
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          if (cont == 0) {
            this.vistapreviaimg = reader.result;
          } else if (cont == 1) {
            this.vistapreviaimg1 = reader.result;
          } else if (cont == 2) {
            this.vistapreviaimg2 = reader.result;
          } else if (cont == 3) {
            this.vistapreviaimg3 = reader.result;
          }
          resolve(reader.result);
        };

        reader.onerror = (error) => {
          reject(error);
        };
      });
    },
    tipoAccion() {
      if (this.item) {
        this.alert = false;
        this.form = Object.assign({}, this.item);
        this.fixFormatDate();
      }
    },
    getCurrentDate() {
      const today = new Date();
      return today.toISOString().split('T')[0]; // Formato YYYY-MM-DD
    },
    onMotivoChange(newValue) {
      if (newValue?.id === 35) {
        this.habilitarTarjetas = true;
        this.form.fechaInconformidad = this.getCurrentDate(); // Poner fecha actual
        this.lblDate = this.form.fechaInconformidad; // Mostrar la fecha en el campo
        this.habilitarFecha = true; // Deshabilitar el campo de fecha
      } else {
        this.habilitarTarjetas = false;
        this.form.fechaInconformidad = null; // Limpiar la fecha
        this.lblDate = ''; // Limpiar el label
        this.habilitarFecha = false; // Habilitar el campo de fecha
      }
    },
    getPlantas() {
      this.loadingPlantas = false;
      axios
        .get(`${this.$store.getters.getHostNode}/api/get-plantas`, {
          headers: {
            authorization: `${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.data.status == "OK") {
            this.itemsPlantas = response.data.data;
          } else {
            this.itemsPlantas = [];
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingPlantas = false;
        });
    },
  },
};
</script>