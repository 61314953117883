var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"center-item-in-div py-6"},[_c('v-btn',{staticClass:"mx-2 rounded-pill",attrs:{"elevation":"0","color":"primary","outlined":_vm.selection !== 1,"disabled":_vm.loadingRanking},on:{"click":function($event){_vm.selection = 1; _vm.updateTable()}}},[_vm._v("manufactura")]),_c('v-btn',{staticClass:"mx-2 rounded-pill",attrs:{"elevation":"0","color":"primary","outlined":_vm.selection !== 2,"disabled":_vm.loadingRanking},on:{"click":function($event){_vm.selection = 2; _vm.updateTable()}}},[_vm._v("logística")])],1),_c('v-card',{staticClass:"elevation-0 rounded-xl",attrs:{"color":"blue-grey lighten-5"}},[_c('v-data-table',{staticStyle:{"background-color":"transparent"},attrs:{"dense":"","headers":_vm.headers,"items":_vm.rankingCalculado,"loading":_vm.loadingRanking,"search":_vm.search,"footer-props":{
        'items-per-page-text': 'Elementos por página',
        pageText: '{0}-{1} de {2}',
      },"no-data-text":"No hay registros","loading-text":"Cargando información..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"px-2 py-2",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-text-field',{staticClass:"rounded-pill",attrs:{"dense":"","outlined":"","prepend-inner-icon":"mdi-magnify","placeholder":"Buscar..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('div',[_c('vue-json-to-csv',{attrs:{"json-data":_vm.rankingCalculado,"labels":_vm.headers_to_export,"csv-title":"ReporteEmpleadosCajaAhorro"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"green darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill mr-2",attrs:{"depressed":"","outlined":"","fab":"","small":"","color":"green darken-2","dark":"","loading":_vm.loadingRanking}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v("Exportar CSV")])])],1),_c('v-tooltip',{attrs:{"bottom":"","color":"blue-grey darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"depressed":"","fab":"","small":"","outlined":"","color":"blue-grey darken-2","loading":_vm.loadingRanking},on:{"click":_vm.updateTable}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v("Actualizar tabla")])])],1)],1)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }