var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return ([{}, null, undefined].includes(_vm.InfoDay))?_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"center":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-off-outline ")])]}}],null,false,1487717564)},[_c('span',[_vm._v("Sin información")])])],1):(_vm.InfoDay.falta === 1 && !_vm.InfoDay.festivo)?_c('div',[_vm._v("falta")]):(_vm.InfoDay.falta === 1 && _vm.InfoDay.festivo)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"center":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-party-popper ")])]}}])},[_c('span',[_vm._v("Día festivo")])])],1):(!_vm.InfoDay.labora)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"center":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-coffee ")])]}}])},[_c('span',[_vm._v("Descansa")])])],1):(Object.keys(_vm.InfoDay.incidencia).length === 0)?_c('div',{attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.InfoDay.ingreso)+" - "+_vm._s(_vm.InfoDay.salida)+" ")]):(Object.keys(_vm.InfoDay.incidencia).length > 0)?_c('div',{attrs:{"align":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.InfoDay.incidencia.desc == 'Incapacidad')?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-hospital-box")]):_vm._e(),(_vm.InfoDay.incidencia.desc == 'Tiempo por Tiempo')?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-history")]):_vm._e(),(_vm.InfoDay.incidencia.desc == 'Salida anticipada')?_c('div',[_vm._v(" "+_vm._s(_vm.InfoDay.entrada)+" "),_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-door-open")]),_vm._v(" "+_vm._s(_vm.InfoDay.salida)+" ")],1):_vm._e(),(_vm.InfoDay.incidencia.desc == 'Llegada tarde')?_c('div',[_vm._v(" "+_vm._s(_vm.InfoDay.entrada)+" "),_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-clock")]),_vm._v(" "+_vm._s(_vm.InfoDay.salida)+" ")],1):_vm._e(),(_vm.InfoDay.incidencia.desc == 'Permiso especial')?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-account-child-circle")]):_vm._e(),(_vm.InfoDay.incidencia.desc == 'Vacaciones')?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-beach")]):_vm._e(),(
          ['Home office', 'Teletrabajo'].includes(_vm.InfoDay.incidencia.desc)
        )?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-home")]):_vm._e(),(_vm.InfoDay.incidencia.desc == 'Flex time')?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-timer-sync-outline")]):_vm._e(),(_vm.InfoDay.incidencia.desc == 'Permuta')?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-briefcase-arrow-up-down")]):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.InfoDay.incidencia.desc))])]),(_vm.InfoDay.incidencia.desc == 'Flex time')?_c('div',[_vm._v(" "+_vm._s(_vm.InfoDay.ingreso)+" - "+_vm._s(_vm.InfoDay.salida)+" ")]):_vm._e()],1):_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"center":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-off-outline ")])]}}])},[_c('span',[_vm._v("Sin información")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }