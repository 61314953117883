<template>
  <v-card elevation="3">
    <v-card-title style="color: rgba(0, 120, 180, 1)">
      <strong> Preguntas clave </strong>
    </v-card-title>
    <v-card-text>
      <p>Sin registros encotrados.</p>
      <v-data-table
        :headers="headersTablaPreguntas"
        :items="preguntas"
        :items-per-page="5"
        class="elevation-1"
      ></v-data-table>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn elevation="2" dark color="teal"> Asignar </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'
import axios from "axios";

export default {
  name: "puesto-preguntas",
  components: {},
  props: ["idPuesto"],

  data: () => ({
    preguntas: [],
    hayPreguntas: false,
    headersTablaPreguntas: [
      {
        text: "ID",
        align: "start",
        value: "id",
      },
      {
        text: "Pregunta",
        value: "pregunta",
      },
      {
        text: "Respuesta",
        value: "Respuesta",
      },
    ],
  }),

  mounted() {
    this.getPreguntas(this.idPuesto);
  },

  methods: {
    getPreguntas(jobcode) {
      var params = {
        jobcode: jobcode,
      };
      axios
        .post(`${this.$store.getters.getHost}/api/getPreguntas`, params)
        .then((response) => {
          var data = response.data.map((pregunta) => {
            return pregunta;
          });
          this.hayPreguntas = true;
          this.preguntas = data;
          //(response.data);
        });
    },
  },
};
</script>
