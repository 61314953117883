<template>
  <v-dialog max-width="600" v-model="dialog" persistent>
    <v-card>
      <v-toolbar color="primary" dark>
        Cargar archivo
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraUpload', null)">mdi-close</v-icon>
      </v-toolbar>
      <v-card-text>
        <v-file-input
          v-model="file"
          show-size
          :accept="tipo"
          label="Entrada de archivo"
          hint="Seleccione un documento que valide su selección"
          tipo
          :disabled="tipo == ''"
        >
        </v-file-input>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          elevation="2"
          @click="submitFile()"
          :loading="loadingBotonGuardar"
          >Cargar</v-btn
        >
      </v-card-actions>
    </v-card>
    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import SnackBarErrorIncidencias from "../../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "carga-archivo-documentos",
  props: ["dialog", "item", "emplid", "tipoDocumento"],
  components: {
    SnackBarErrorIncidencias,
  },
  created() {},
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      file: null,
      loadingBotonGuardar: false,
      encryptionKey: null,
      iv: null,
      encryptedData: null,
      tipo: ".pdf",
    };
  },
  mounted() {},
  methods: {
    async submitFile() {
      if (!this.file) {
        console.error("No hay archivo seleccionado");
        return;
      }
      this.loadingBotonGuardar = true;
      try { 
        const fileArrayBuffer = await this.readFileAsArrayBuffer(this.file);
        const { encryptData, iv, key } = await this.encrypt(fileArrayBuffer);
        this.iv = iv;
        this.encryptionKey = key;
        this.encryptedData = encryptData;
        const encryptedFile = new Blob([encryptData], {
          type: "application/octet-stream",
        });
        const formData = new FormData();
        formData.append(
          "encryptedFile",
          encryptedFile,
          `${this.item.descr}`
        );
        formData.append("iv", JSON.stringify(Array.from(iv)));
        formData.append("key", JSON.stringify(await this.exportKey(key)));
        formData.append("emplid", this.emplid);
        formData.append("id", this.item.id);
        formData.append("tipoDocumento", this.tipoDocumento);
        formData.append("tipo", this.tipo);
        const response = await axios.post(
          `${this.$store.getters.getHostNode}/api/upload-archivo-curriculum`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        if (response.data.status) {
          this.textoBarra = "Archivo guardado correctamente.";
          this.colorBarra = "success";
          this.muestraBarra = true;
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(this.$emit("cierraUpload", true));
            }, 1500);
          });
        } else {
          this.textoBarra = "Archivo no pudo ser cifrado.";
          this.colorBarra = "error";
          this.muestraBarra = true;
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(this.$emit("cierraUpload", false));
            }, 1500);
          });
        }
      } catch (error) {
        this.textoBarra = "Error al enviar archivo.";
        this.colorBarra = "error";
        this.muestraBarra = true;
      } finally {
        this.loadingBotonGuardar = false;
        this.file = null;
        // this.$emit("cierraUpload", null);
      }
    },

    async encrypt(data) {
      try {
        const key = await crypto.subtle.generateKey(
          {
            name: "AES-GCM",
            length: 256,
          },
          true,
          ["encrypt", "decrypt"]
        );

        const iv = crypto.getRandomValues(new Uint8Array(12));
        const encryptData = await crypto.subtle.encrypt(
          {
            name: "AES-GCM",
            iv: iv,
          },
          key,
          data
        );

        return { encryptData, iv, key };
      } catch (error) {
        console.error("Error al cifrar los datos:", error);
        throw error;
      }
    },

    async exportKey(key) {
      const exported = await crypto.subtle.exportKey("jwk", key);
      return exported;
    },

    readFileAsArrayBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = () => {
          reject(reader.error);
        };
        reader.readAsArrayBuffer(file);
      });
    },
  },
};
</script>