<template>
  <div>
    <v-dialog v-model="dialog" max-width="700px" persistent scrollable>
      <v-card class="rounded-lg" :loading="verifiandoPermiso">
        <v-card-title class="primary white--text subtitle-1">
          <span>Registro de Home Office</span>
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('cierra', 0)">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div v-if="!tienePermiso">
            <p class="mb-0 mt-1" style="font-weight: bolder">
              {{ mensajePermiso }}
            </p>
          </div>
          <div v-else>
            <v-alert
              :value="alert"
              :color="colorAlert"
              dark
              border="top"
              :icon="iconAlert"
              transition="scale-transition"
            >
              <v-row align="center">
                <v-col class="grow">
                  <!-- {{ respuestaApiGuardaIncidencia.message }}
               -->
                  {{ textAlert }}
                </v-col>
                <v-col class="shrink">
                  <v-row align="center">
                    <v-col cols="12" sm="6">
                      <v-btn small @click="muestraAlertDetalles()">
                        {{ textButtonAlert }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-alert>
            <p class="font-weight-bold">Ingresa los datos necesarios.</p>
            <div v-if="esAdmin != true" align="center" style="height: 87px">
              <v-select
                :items="limitesSemanas.limites"
                v-model="semanaSelect"
                label="Selecciona la semana en la que se registrará la incidencia."
                outlined
                dense
                :rules="rules.semanaSeleccionada"
                :value="limitesSemanas.semana"
                :loading="loadingSemanas"
                :disabled="loadingSemanas"
              ></v-select>
            </div>

            <div v-else align="center" style="height: 87px">
              <v-menu
                v-model="selSemana"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaSeleccionadaAdmin"
                    label="Seleccion de semana"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaSeleccionadaAdmin"
                  @input="getIncioFinSemana"
                ></v-date-picker>
              </v-menu>
            </div>
            <div
              v-show="fechaMostrarAdmin != '' && esAdmin === true"
              style="height: 41px; margin-top: -35px"
            >
              <p class="text-center">{{ fechaMostrarAdmin }}</p>
            </div>
            <v-combobox
              v-model="diasSeleccionados"
              :items="diasSemana.es"
              :rules="rules.diasSeleccionadosRules"
              label="Selecciona los días de la semana a registrar"
              :disabled="loadingBotonGuardar"
              multiple
              outlined
              dense
              small-chips
            >
              <template v-slot:selection="data">
                <v-chip
                  :key="JSON.stringify(data.item)"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  :disabled="data.disabled"
                  close
                  @click:close="data.parent.selectItem(data.item)"
                >
                  <v-avatar
                    class="accent white--text"
                    left
                    v-text="data.item.slice(0, 1).toUpperCase()"
                  ></v-avatar>
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>
            <v-text-field
              outlined
              dense
              label="Agrega un comentario"
              :rules="rules.comentario"
              v-model="comentario"
              :disabled="loadingBotonGuardar"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="tienePermiso"
            class="rounded-lg"
            color="teal darken-1"
            dark
            elevation="2"
            @click="guardarInformacion()"
            :loading="loadingBotonGuardar"
            :rules="rules.comentario"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-sheet id="muestra-incidencias-cargadas">
      <muestra-incidencias-cargadas
        v-if="muestraDetalles"
        :muestraDetalles="muestraDetalles"
        :datosTabla="respuestaApiGuardaIncidencia.data"
        :alertErrorDosDias="alertErrorDosDias"
        @cierraTabla="muestraDetalles = false"
      />
    </v-sheet>
    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias
        v-if="muestraBarra"
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-sheet>
  </div>
</template>

<script>
import axios from "axios";
import MuestraIncidenciasCargadas from "./componentsExtra/MuestraIncidenciasCargadas.vue";
import SnackBarErrorIncidencias from "./componentsExtra/SnackBarErrorIncidencias.vue";
import { formatDate } from "../../../../../store/Funciones/funciones";
// import { defineComponent } from '@vue/composition-api';
export default {
  name: "incidencia-home-office",
  data: () => ({
    muestraBarra: false,
    verifiandoPermiso: true,
    fechaSeleccionadaAdmin: "",
    selSemana: false,
    textoBarra: "",
    colorAlert: "pink",
    iconAlert: "mdi-alert-circle",
    colorBarra: "red accent-2",
    diasSemana: {
      es: ["Martes", "Miércoles", "Jueves"],
      en: ["Tuesday", "Wednesday", "Thrusday"],
    },
    proxSemanas: [],
    respuestaApiGuardaIncidencia: [],
    limitesSemanas: {
      limites: [],
      fechas: [],
    },
    muestraDetalles: false,
    loadingSemanas: true,
    loadingBotonGuardar: false,
    fechaSeleccionada: "",
    semanaSelect: "",
    diasSeleccionados: [],
    textAlert: "",
    textButtonAlert: "Ver más",
    alertErrorDosDias: false,
    tienePermiso: false,
    // allWeek: null,
    comentario: "",
    alert: false,
    mensajePermiso: "",
    // time: 1000,
    rules: {
      comentario: [
        (val) =>
          (val || "").length > 0 || "Es necesario que escribas un comentario.",
      ],
      semanaSeleccionada: [
        (val) => (val || "").length > 0 || "Selecciona una semana.",
      ],
      diasSeleccionadosRules: [
        (val) => (val || "").length > 0 || "Selecciona los días a registrar.",
      ],
      permiso: [],
    },
    fechaMostrarAdmin: "",
  }),
  components: {
    MuestraIncidenciasCargadas,
    SnackBarErrorIncidencias,
  },
  props: ["dialog", "esAdmin", "empleado"],
  mounted() {
    this.getSemanasProx();
    this.getAccesoHomeOffice();
  },
  methods: {
    getIncioFinSemana() {
      this.selSemana = false;
      var date = this.fechaSeleccionadaAdmin;
      var curr = new Date(date);
      var first = curr.getDate() - curr.getDay();
      var last = first + 4;

      var firstday = new Date(curr.setDate(first)).toISOString();
      var lastday = new Date(curr.setDate(last)).toISOString();
      this.fechaSeleccionada =
        firstday.substring(0, 10) + "," + lastday.substring(0, 10);
      this.fechaMostrarAdmin =
        "Del " +
        formatDate(firstday.substring(0, 10)) +
        " al " +
        formatDate(lastday.substring(0, 10));
    },
    muestraAlertDetalles() {
      if (this.textButtonAlert == "Saber más") {
        this.textButtonAlert = "";
        this.muestraDetalles = true;
        this.alertErrorDosDias = true;
      } else {
        this.alertErrorDosDias = false;
        this.muestraDetalles = true;
      }

      if (this.textButtonAlert == "Cerrar") {
        this.alert = false;
      }
      // this.textButtonAlert == "Ver más"
      this.alert = false;
    },
    remove(item) {
      this.diasSeleccionados.splice(this.diasSeleccionados.indexOf(item), 1);
      this.diasSeleccionados = [...this.diasSeleccionados];
    },
    getSemanasProx() {
      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-home-office.php`,
          params
        )
        .then((response) => {
          var data = response.data.proxTresSemanas;
          this.proxSemanas = data;
          this.concatenaFechas();
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    concatenaFechas() {
      this.proxSemanas.forEach((element) => {
        this.limitesSemanas.limites.push(element.fli + " - " + element.flf);
        this.limitesSemanas.fechas.push(element.inicio + "," + element.fin);
        this.loadingSemanas = false;
      });
    },
    getAccesoHomeOffice() {
      const params = {
        Opcion: 2,
        token: localStorage.getItem("token"),
        esAdmin: this.esAdmin,
        empleado: this.empleado,
      };

      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-home-office.php`,
          params
        )
        .then((response) => {
          var data = response.data.permiso;
          this.permiso = data;
          this.getModificaPermiso();
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    getModificaPermiso() {
      this.verifiandoPermiso = false;
      if (this.permiso == 0) {
        this.mensajePermiso =
          "Para poder solicitar home office debes tener la autorización por dirección y gerencia de tu área y enviar un correo a tu generalista. " +
          " O puede que ya hayas solicitado más de 4 días este mes.";
        this.tienePermiso = false;
      } else {
        this.tienePermiso = true;
      }
      // this.muestraProgreso = false;
    },
    guardarInformacion() {
      if (
        (this.semanaSelect == "" && this.fechaSeleccionadaAdmin == "") ||
        this.diasSeleccionados.length == 0 ||
        this.comentario == ""
      ) {
        this.textoBarra = "Tienes que llenar todos los campos";
        this.muestraBarra = true;
      } else {
        if (this.diasSeleccionados.length > 2) {
          if (this.esAdmin === true) {
            this.textAlert =
              "Este usuario no puede solicitar más de dos días de Home Office.";
          } else {
            this.textAlert =
              "No puedes solicitar más de dos días de Home Office.";
          }
          this.textButtonAlert = "Saber más";
          this.colorAlert = "pink";
          this.iconAlert = "mdi-content-save-check-outline";
          this.alert = true;
        } else {
          this.loadingBotonGuardar = true;
          this.textButtonAlert = "Ver más";
          if (this.admin != true) {
            for (var i = 0; i < this.limitesSemanas.limites.length; i++) {
              if (this.limitesSemanas.limites[i] == this.semanaSelect) {
                this.fechaSeleccionada = this.limitesSemanas.fechas[i];
              }
            }
          }
        }
        const params = {
          Opcion: 3,
          token: localStorage.getItem("token"),
          semana: this.fechaSeleccionada,
          dias: this.diasSeleccionados,
          comentario: this.comentario,
          tipo: 7,
          esAdmin: this.esAdmin,
          empleado: this.empleado,
        };

        // console.table(params);

        axios
          .post(
            `${this.$store.getters.getHost}/Incidencias/ws-incidencias-home-office.php`,
            params
          )
          .then((response) => {
            var data = response.data.response;
            this.respuestaApiGuardaIncidencia = data;
            this.evaluaRespuesta();
          })
          .catch(function (e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      }
    },
    evaluaRespuesta() {
      if (this.respuestaApiGuardaIncidencia["opcion"] == 1) {
        // this.textAlert = this.respuestaApiGuardaIncidencia["message"];
        this.alert = true;
        this.loadingBotonGuardar = false;
      }
      if (this.respuestaApiGuardaIncidencia["opcion"] == 5) {
        // this.textAlert = this.respuestaApiGuardaIncidencia["message"];
        this.colorAlert = "green";
        this.iconAlert = "mdi-content-save-check-outline";
        this.alert = true;
        for (var i = 0; i <= this.diasSeleccionados.length; i++) {
          this.diasSeleccionados.pop();
        }
        this.semanaSelect = "";
        this.comentario = "";
        this.loadingBotonGuardar = false;
        // this.textoBarra = "Incidencia cargada con éxito.";
        // this.colorBarra = "primary";
        // this.muestraBarra = true;
        this.$emit("cierra", 1);
      } else {
        this.colorAlert = "pink";
        this.iconAlert = "mdi-alert-circle";
        this.textButtonAlert = "Cerrar";
        this.alert = true;
      }
    },
  },
};
</script>
