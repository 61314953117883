<template>
  <div>
    <v-dialog
      v-model="showSendMail"
      persistent
      width="700px"
      class="shadow-card-mod"
      scrollable
    >
      <v-card
        v-if="muestraLoading"
        class="shadow-card-mod pa-10 center-item-in-div-column"
        height="300"
      >
        <div class="text-center">
          <label class="overline">{{ textoLoading }}</label>
        </div>
        <v-progress-circular
          :size="70"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-card>
      <v-card v-else class="shadow-card-mod">
        <v-card-title class="text-subtitle-2 primary white--text">
          {{ $t("DH360.send") }} {{ $t("DH360.mail") }}
          <v-spacer></v-spacer>
          <v-icon small dark @click="$emit('hideSendMail', 0)"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-form ref="form" class="mt-5" v-model="valid" lazy-validation>
            <v-text-field
              v-model="para"
              :disabled="esVisitaInt"
              :label="$t('DH360.to')"
              outlined
              dense
              required
              flat
              :rules="emailRules"
            ></v-text-field>
            <v-text-field
              v-model="asunto"
              :label="$t('DH360.subject')"
              :disabled="esVisitaInt"
              outlined
              dense
              required
              flat
            ></v-text-field>
            <v-textarea
              v-model="mensaje"
              :label="$t('DH360.message')"
              outlined
              dense
              required
              flat
              rows="6"
            >
            </v-textarea>
          </v-form>
          <label v-show="!esVisitaInt" class="overline">vista previa</label>
          <v-sheet
            v-show="!esVisitaInt"
            style="border: solid 1px #455A64"
            class="pa-2"
            v-html="mensaje"
          ></v-sheet>
        </v-card-text>
        <div style="display:flex; justify-content: center;">
          <button
            class="button-send"
            @click="
              muestraLoading = true;
              enviaCorreo();
            "
          >
            <div class="svg-wrapper-1">
              <div class="svg-wrapper">
                <v-icon class="svg" color="primary" width="24" height="24">
                  mdi-email-fast-outline
                </v-icon>
              </div>
            </div>
            <span>{{ $t("DH360.notify") }}</span>
          </button>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../store/Funciones/funciones";

export default {
  name: "muestra-evaluaciones-pendientes-detalle",
  components: {},
  props: ["showSendMail", "defaultMsg", "receptor", "title", "esVisita"],
  data() {
    return {
      valid: true,
      para: "",
      mensaje: "",
      asunto: "",
      muestraLoading: false,
      muestraBarra: false,
      esVisitaInt: false,
      textoLoading: " Enviando notificación... ",
      colorBarra: "",
      textoBarra: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  computed: {},
  mounted() {
    this.mensaje = this.defaultMsg;
    if(this.esVisita){
      this.esVisitaInt = true;
    }
    if (this.title == null || this.title == undefined) {
      this.asunto = this.$t("DH360.titleMsgMailToAnsweredAssasment");
    } else {
      this.asunto = this.title;
    }
    this.para = this.receptor;
  },
  methods: {
    enviaCorreo() {
      if (this.$refs.form.validate()) {
        let params = {
          Opcion: 27,
          token: localStorage.getItem("token"),
          asunto: this.asunto,
          mailTo: this.para,
          mensaje: this.mensaje,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
            params
          )
          .then((response) => {
            this.muestraLoading = false;
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              if (response.data.respuesta == 1) {
                // this.$refs.form.reset();
                this.para = ''
                // this.$refs.form.resetValidation();
                // console.log(response);
                this.$emit("hideSendMail", 1);
              } else {
                this.$emit("hideSendMail", -1);
              }
              this.loadingTabla = false;
            }
          });
      }
    },
  },
};
</script>

<style>
.shadow-card-mod {
  border-radius: 15px !important;
  box-shadow: 1px 5px 60px 0px #100a886b !important;
}

.button-send {
  font-size: 15px;
  background: transparent;
  color: rgb(0, 120, 195);
  padding: 0.7em 1em;
  padding-left: 0.9em;
  display: flex;
  align-items: center;
  border: 0.5px;
  border-color: rgb(0, 120, 195);
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.2s;
}

.button-send span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

.button-send .svg {
  display: block;
  transform-origin: center center;
  /* transition: transform 0.3s ease-in-out; */
}

.button-send:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.button-send:hover .svg {
  transform: translateX(1.2em) rotate(5deg) scale(1.1);
}

.button-send:hover span {
  transform: translateX(5em);
}

.button-send:active {
  transform: scale(0.95);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}
</style>
