<template>
  <v-dialog v-model="muestraDetalleIncidencia" persistent max-width="500" scrollable>
    <v-card class="rounded-lg">
      <v-card-title class="primary white--text subtitle-1">
        Detalle de incidencia
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraDetalle')"> mdi-close </v-icon>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-row v-if="loadingDetalle" class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            Obteniento información...
          </v-col>
          <v-col cols="6">
            <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
          </v-col>
        </v-row>
        <div v-else>

          <v-sheet class="rounded-lg elevation-1 pa-3 mb-3">
            <label class="blue-grey--text text--darken-1 font-weight-bold caption text-uppercase">
              Datos de empleado
            </label>
            <v-divider class="mt-1 mb-3 mr-10"></v-divider>
            <v-row dense>
              <v-col cols="6">
                <div class="subtitle-2">
                  <strong>No. de empleado: </strong>{{ detalleIncidencia.NumeroPersonal }}
                </div>
              </v-col>
              <v-col cols="6">
                <div class="subtitle-2">
                  <strong>Departamento: </strong>{{ detalleIncidencia.DEPTID }}
                </div>
              </v-col>
              <v-col cols="12">
                <div class="subtitle-2">
                  <strong>Nombre: </strong>{{ detalleIncidencia.NAME }}
                </div>
              </v-col>
            </v-row>
          </v-sheet>
          <v-sheet class="rounded-lg elevation-1 pa-3">
            <label class="blue-grey--text text--darken-1 font-weight-bold caption text-uppercase">
              Datos de incidencia
            </label>
            <v-divider class="mt-1 mb-3 mr-10"></v-divider>
            <div v-if="detalleIncidencia.TipoIncidencia === 1">
              <div style="display: flex; justify-content: space-around;">
                <div class="text-center">
                  <strong>Días correspondientes: </strong>{{ detalleIncidencia.DiasTotales }}
                </div>
                <div class="text-center">
                  <strong>Días a disfrutar: </strong>{{ detalleIncidencia.DiasSolicitados }}
                </div>
              </div>
              <div style="display: flex; justify-content: space-around;">
                <div class="text-center">
                  <strong>Fecha de inicio: </strong>{{ fixFormatDate(detalleIncidencia.FechaInicialDes.date) }}
                </div>
                <div class="text-center">
                  <strong>Fecha fin: </strong>{{ fixFormatDate(detalleIncidencia.FechaFinalDes.date) }}
                </div>
              </div>
              <div class="text-center">
                Los cuales pertenecen a {{ detalleIncidencia.PeriodoCorrespondiente }} años de servicio prestados
              </div>
              <div class="text-center"><strong>Días restantes: </strong>{{ detalleIncidencia.DiasPendientes }}</div>
              <div class="text-center"><strong>Día de regreso: </strong>{{
                fixFormatDate(detalleIncidencia.FechaPresentacion.date) }}</div>
              <!-- {{ detalleIncidencia }} -->
            </div>
            <div v-if="detalleIncidencia.TipoIncidencia === 2">
              <div style="display: flex; justify-content: space-around;">
                <div class="text-center">
                  <strong>Fecha: </strong>{{ fixFormatDate(detalleIncidencia.FechaInicialDes.date) }}
                </div>
                <div class="text-center">
                  <strong>Hora: </strong>{{ getHour(detalleIncidencia.FechaInicialDes.date) }}
                </div>
              </div>
              <div style="display: flex; justify-content: space-around;">
                <div class="text-center">
                  <strong>{{
                    detalleIncidencia.Regresa === 1
                      ? "Si regresa"
                      : "No regresa"
                  }}
                  </strong>
                </div>
                <div class="text-center" v-show="detalleIncidencia.Regresa === 1">
                  <strong>Hora de regreso: </strong>{{ getHour(detalleIncidencia.FechaInicialDes.date) }}
                </div>
              </div>
              <div style="display: flex; justify-content: space-around;" v-if="
                detalleIncidencia.FechaHoraRealSalida != null &&
                detalleIncidencia.FechaHoraRealRegreso != null
              ">
                <div class="text-center" v-if="detalleIncidencia.FechaHoraRealSalida != null">
                  <strong>Hora real salida: </strong>{{ getHour(detalleIncidencia.FechaHoraRealSalida.date) }}
                </div>
                <div class="text-center" v-if="detalleIncidencia.FechaHoraRealRegreso != null">
                  <strong>Hora real regreso: </strong>{{ getHour(detalleIncidencia.FechaHoraRealRegreso.date) }}
                </div>
              </div>
              <div class="text-center">
                <strong>Motivo: </strong>{{ detalleIncidencia.DescOtro }}
              </div>
            </div>
            <div v-if="detalleIncidencia.TipoIncidencia === 3">
              <div style="display: flex; justify-content: space-around;">
                <div class="text-center">
                  <strong>Día que descansa: </strong>{{ fixFormatDate(detalleIncidencia.FechaInicialDes.date) }}
                </div>
                <div class="text-center">
                  <strong>Día que trabaja: </strong>{{ fixFormatDate(detalleIncidencia.FechaInicialPago.date) }}
                </div>
              </div>
            </div>
            <div v-if="detalleIncidencia.TipoIncidencia === 4">
              <div class="text-center font-weight-bold">
                {{
                  detalleIncidencia.GoceSueldo === 0
                    ? "Sin goce de sueldo"
                    : "Con goce de sueldo"
                }}
              </div>
              <div class="text-center">
                <strong>Tipo de permiso: </strong>{{ detalleIncidencia.PermDescripcion }}
              </div>
              <div style="display: flex; justify-content: space-around;">
                <div class="text-center">
                  <strong>Fecha de inicio: </strong>{{ fixFormatDate(detalleIncidencia.FechaInicialDes.date) }}
                </div>
                <div class="text-center">
                  <strong>Fecha fin: </strong>{{ fixFormatDate(detalleIncidencia.FechaFinalDes.date) }}
                </div>
              </div>
            </div>
            <div v-if="detalleIncidencia.TipoIncidencia === 5">
              <div style="display: flex; justify-content: space-around;">
                <div class="text-center">
                  <strong>Fecha: </strong>{{ fixFormatDate(detalleIncidencia.FechaInicialDes.date) }}
                </div>
                <div class="text-center">
                  <strong>Hora: </strong>{{ getHour(detalleIncidencia.FechaInicialDes.date) }}
                </div>
              </div>
              <div class="text-center">
                <strong>Motivo: </strong>{{ detalleIncidencia.DescOtro }}
              </div>
              <!-- {{ detalleIncidencia }} -->
            </div>
            <div v-if="detalleIncidencia.TipoIncidencia === 6">
              <div style="display: flex; justify-content: space-around;">
                <div class="text-center">
                  <strong>Fecha de inicio: </strong>{{ fixFormatDate(detalleIncidencia.FechaInicialDes.date) }}
                </div>
                <div class="text-center">
                  <strong>Fecha fin: </strong>{{ fixFormatDate(detalleIncidencia.FechaFinalDes.date) }}
                </div>
              </div>
              <div style="display: flex; justify-content: space-around;">
                <div class="text-center">
                  <strong>Hora de entrada: </strong>{{ getHour(detalleIncidencia.HoraDeEntrada.date) }}
                </div>
                <div class="text-center">
                  <strong>Hora de salida: </strong>{{ getHour(detalleIncidencia.HoraDeSalida.date) }}
                </div>
              </div>
              <div class="text-center">
                <strong>Cambia por:</strong>
                {{ detalleIncidencia.nTurno }}
              </div>
            </div>
            <div v-if="
              detalleIncidencia.TipoIncidencia === 7 ||
              detalleIncidencia.TipoIncidencia === 11
            ">
              <div style="display: flex; justify-content: space-around;">
                <div class="text-center">
                  <strong>Fecha de inicio: </strong>{{ fixFormatDate(detalleIncidencia.FechaInicialDes.date) }}
                </div>
                <div class="text-center">
                  <strong>Fecha fin: </strong>{{ fixFormatDate(detalleIncidencia.FechaFinalDes.date) }}
                </div>
              </div>
            </div>
            <div v-if="detalleIncidencia.TipoIncidencia === 8">
              <div style="display: flex; justify-content: space-around;">
                <div class="text-center">
                  <strong>Fecha de tiempo extra: </strong>{{ fixFormatDate(detalleIncidencia.FechaInicialDes.date) }}
                </div>
                <div class="text-center">
                  <strong>Número de horas: </strong>{{ detalleIncidencia.TEhoras }}
                </div>
              </div>
            </div>
            <div v-if="detalleIncidencia.TipoIncidencia === 12">
              <div style="display: flex; justify-content: space-around;">
                <div class="text-center">
                  <strong>Fecha de inicio: </strong>{{ fixFormatDate(detalleIncidencia.FechaInicialDes.date) }}
                </div>
                <div class="text-center">
                  <strong>Fecha fin: </strong>{{ fixFormatDate(detalleIncidencia.FechaFinalDes.date) }}
                </div>
              </div>
              <div style="display: flex; justify-content: space-around;">
                <div class="">
                  <strong>Hora de entrada: </strong>{{ getHour(detalleIncidencia.HoraDeEntrada.date) }}
                </div>

                <div class="text-center">
                  <strong>Hora de salida: </strong>{{ getHour(detalleIncidencia.HoraDeSalida.date) }}
                </div>
              </div>
            </div>
          </v-sheet>
          <v-sheet v-show="detalleIncidencia.Comentario" class="rounded-lg elevation-1 pa-3 mt-3">
            <label class="blue-grey--text text--darken-1 font-weight-bold caption text-uppercase">
              Comentarios
            </label>
            <v-divider class="mt-1 mb-3 mr-10"></v-divider>
            <v-row dense>
              <v-col cols="12">
                <div class="subtitle-2">
                  <strong>Comentario: </strong>{{ detalleIncidencia.Comentario }}
                </div>
              </v-col>
              <v-col cols="12" v-if="
                detalleIncidencia.ComentarioAutorizacion != '' &&
                detalleIncidencia.ComentarioAutorizacion != null
              ">
                <div class="subtitle-2">
                  <strong>Comentario aut.: </strong>{{ detalleIncidencia.ComentarioAutorizacion }}
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { formatDate } from "../../../../../../store/Funciones/funciones";
export default {
  name: "muestra-detalle-incidencia",
  props: ["muestraDetalleIncidencia", "idIncidencia"],
  data() {
    return {
      detalleIncidencia: {},
      loadingDetalle: true,
    };
  },
  mounted() {
    this.getDetalleIncidencia();
  },
  methods: {
    getDetalleIncidencia() {
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
        id: this.idIncidencia,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/Reportes/ws-IncidenciasPorFechas.php`,
          params
        )
        .then((response) => {
          console.log(typeof (response.data.detalle))
          this.detalleIncidencia = response.data.detalle;
          this.loadingDetalle = false;
        }).catch(e => {
          alert(e)
        });
    },
    fixFormatDate(date) {
      return formatDate(date.substring(0, 10));
    },
    getHour(hora) {
      let date = new Date(hora);
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      const time = hours + ":" + minutes + " " + ampm;
      return time;
    },
  },
};
</script>
