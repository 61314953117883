<template>
  <div>
    <v-sheet
      v-if="loadingData"
      max-width="700px"
      height="300"
      class="center-item-in-div"
    >
      <v-progress-circular
        :size="70"
        :width="12"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-sheet>
    <v-sheet v-else max-width="700px" class="elevation-2 pa-5 mt-8">
      <v-row dense>
        <v-col :cols="isSmallScreen ? 12 : 7">
          <v-text-field
            v-model="passe.nombre"
            class="rounded-lg"
            label="Nombre"
            outlined
            dense
            style="max-height: 42px !important;"
            disabled
          ></v-text-field>
        </v-col>
        <v-col :cols="isSmallScreen ? 12 : 5">
          <v-text-field
            :value="fixDate(passe.fecha_solicitud.date.substring(0, 10))"
            class="rounded-lg"
            label="Fecha de solicitud"
            outlined
            dense
            style="max-height: 42px !important;"
            disabled
          ></v-text-field>
        </v-col>
        <v-col :cols="isSmallScreen ? 6 : 2">
          <v-text-field
            :value="passe.numero_personal"
            class="rounded-lg"
            label="Número personal"
            outlined
            dense
            style="max-height: 42px !important;"
            disabled
          ></v-text-field>
        </v-col>
        <v-col :cols="isSmallScreen ? 6 : 3">
          <v-text-field
            :value="passe.proveedor"
            class="rounded-lg"
            label="Proveedor"
            outlined
            dense
            style="max-height: 42px !important;"
            disabled
          ></v-text-field>
        </v-col>
        <v-col :cols="isSmallScreen ? 7 : 4">
          <v-text-field
            :value="passe.domicilio"
            class="rounded-lg"
            label="Domicilio"
            outlined
            dense
            style="max-height: 42px !important;"
            disabled
          ></v-text-field>
        </v-col>
        <v-col :cols="isSmallScreen ? 5 : 3">
          <v-text-field
            :value="passe.telefono"
            class="rounded-lg"
            label="Teléfono"
            outlined
            dense
            style="max-height: 42px !important;"
            disabled
          ></v-text-field>
        </v-col>
        <v-col :cols="isSmallScreen ? 6 : 6">
          <v-text-field
            :value="passe.areaDesc"
            class="rounded-lg"
            label="Área"
            outlined
            dense
            style="max-height: 42px !important;"
            disabled
          ></v-text-field>
        </v-col>
        <v-col :cols="isSmallScreen ? 6 : 6">
          <v-text-field
            :value="passe.razonDesc"
            class="rounded-lg"
            label="Razón"
            outlined
            dense
            style="max-height: 42px !important;"
            disabled
          ></v-text-field>
        </v-col>
        <v-col :cols="12">
          <v-text-field
            :value="passe.observaciones"
            class="rounded-lg"
            label="Observaciones"
            outlined
            dense
            style="max-height: 42px !important;"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-simple-table dense class="mt-2">
        <thead>
          <tr class="primary">
            <th class="overline white--text text-center">cantidad</th>
            <th class="overline white--text text-center">unidad</th>
            <th class="overline white--text text-center">código</th>
            <th class="overline white--text text-center" colspan="2">
              descripción
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(m, index) in passe.material" :key="index">
            <td style="border: 0.7px solid #A8A8A7;">
              <div>{{ m.cantidad }}</div>
            </td>
            <td style="border: 0.7px solid #A8A8A7;">
              <div>{{ m.unidad }}</div>
            </td>
            <td style="border: 0.7px solid #A8A8A7;">
              <div>{{ m.codigo }}</div>
            </td>
            <td colspan="2" style="border: 0.7px solid #A8A8A7;">
              <div>{{ m.descripcion }}</div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <div class="pt-5 center-item-in-div">
        <v-chip color="#A8A8A7" dark class="overline font-weight-bold">
          {{
            passe.regresa === 0
              ? "No regresa"
              : "Regresa el día " + fixDate(passe.regreso.date.substring(0, 10))
          }}
        </v-chip>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import { formatDate } from "../../../../../store/Funciones/funciones";
import axios from "axios";
export default {
  name: "muestra-pase-de-salida",
  props: ["idPase"],
  data() {
    return {
      passe: {},
      loadingData: true,
    };
  },
  async mounted() {
    this.passe = await this.getInfoPass();
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.name === "xs" ? true : false;
    },
  },
  methods: {
    async getInfoPass() {
      let params = {
        Opcion: 8,
        token: localStorage.getItem("token"),
        idPase: this.idPase,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            }
          })
          .catch((error) => {
            reject([{ nombre: error }]);
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    fixDate(date) {
      return formatDate(date);
    },
  },
};
</script>
