<template>
  <v-dialog
    v-model="muestraDialogActualizaSalario"
    max-width="400"
    content-class="fix-border-dialog"
    persistent
  >
    <v-card class="rouded-lg">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-icon
          color="pink darken-3"
          right
          @click="$emit('ocultaDialog', null)"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="pt-5 pb-1">
        <v-form ref="form" v-model="valid" lazy-validation>
          <label class="text-uppercase subtitle-2"
            >Ingresa el salario mensual</label
          >
          <v-text-field
            v-model="montoAux"
            class="rounded-lg"
            outlined
            dense
            type="number"
            prefix="$"
            required
            placeholder="Ingresa el salario..."
            :rules="rules"
          >
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="rounded-lg" color="teal darken-1" dark depressed @click="validaCampo()">
          añadir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-actualiza-salario-por-posicion",
  props: ["muestraDialogActualizaSalario", "monto"],
  data() {
    return {
      valid: true,
      rules: [(v) => !!v || v >= 0 || "El comentario es requerido."],
      montoAux: 0
    };
  },
  mounted(){
    this.montoAux = this.monto
  },
  methods: { 
    validaCampo() {
        if(this.$refs.form.validate()){
            this.$emit('ocultaDialog', this.montoAux);
        }
    } 
},
};
</script>
