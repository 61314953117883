<template>
  <div v-if="valoresCatalogCargados" class="valores-container">
    <v-row class="ma-0 mt-1 pa-0 align-center">
      <div class="grid-container">
        <article v-for="(valor, index) in valoresCatalog" :key="index" @mouseover="valor.hover = true"
          @mouseleave="valor.hover = false" class="grid-item">
          <v-tooltip v-if="valor" bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" @click="openPopupValoraciones(valor.valoracionesPersona, valor.image)"
                v-ripple class="rounded-lg elevation-5">
                <v-badge bottom color="primary" :content="valor.counter" offset-x="18" offset-y="18"
                  v-if="valor.counter > 0">
                  <img class="rounded-lg valor" :src="require('@/assets/icons/' + valor.image)" alt="" />
                </v-badge>
                <img v-else class="rounded-lg valor" :src="require('@/assets/icons/' + valor.image)" alt="" />
              </div>
            </template>
            <span>{{ valor.titulo }}</span>
          </v-tooltip>
        </article>
      </div>
    </v-row>

    <PersonaValoresDialog v-if="valoresCatalogCargados" :valoraciones="valoracionesSeleccionadasList"
      :imagen="valoracionesSeleccionadasImg" :dialogVisible="valoracionesDialogVisible"
      @closed="valoracionesDialogVisible = false" />
    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias :muestraBarra="muestraBarra" :texto="textoBarra" :color="colorBarra"
        @cierraBarra="muestraBarra = false" />
    </v-sheet>
    <!-- child component -->
  </div>
</template>
<script>
import axios from "axios";
import PersonaValoresDialog from "@/components/PersonaValoresDialog.vue";
import SnackBarErrorIncidencias from "../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "persona-noticias-preview",
  data: () => ({
    valoresCatalog: [],
    valoresRecibidosPersona: [],
    valoresTotalesRecibidos: [],
    valoracionesSeleccionadasList: [],
    valoracionesSeleccionadasImg: '',
    valoracionesDialogVisible: false,
    muestraBarra: false,
    textoBarra: "",
    colorBarra: "",
    valoresCatalogCargados: false,
  }),
  components: {
    PersonaValoresDialog,
    SnackBarErrorIncidencias,
  },
  props: ["userId"],
  mounted() {
    this.getValoresValoraciones();
  },
  methods: {
    async getValoresValoraciones() {
      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
      };
      try {
        let response = await axios.post(`${this.$store.getters.getHost}/Valores/ws-valores.php`, params);
        this.valoresCatalog = response.data.datosValores;
        this.valoresCatalogCargados = true;
      } catch (error) {
        let errorMessage = "Ha ocurrido un error al cargar los valores.";
        if (error.response && error.response.status === 401) {
          window.location.reload();
        } else {
          alert(errorMessage);
        }
      }
    },
    openPopupValoraciones(valoraciones, imagen) {
      this.valoracionesSeleccionadasList = valoraciones;
      this.valoracionesSeleccionadasImg = imagen;
      this.valoracionesDialogVisible = true;
    },
  },
};
</script>
<style lang="sass" scoped>
.grid-container 
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 10px
.valores-container
  display: flex
  max-height: 100%
  .row
    max-width: 100%
    display: grid
    grid-template-columns: auto auto auto
    justify-content: space-around
    align-content: center
    gap: 0.5rem
    article
      position: relative
      div:first-child
        display: flex
        align-items: stretch
      img.valor
        width: 100%
        object-fit: contain
        object-position: center
        display: inline-block
      .static
        position: absolute
        top: 5px
        right: 5px
        width: 20px
        height: 20px
        border-radius: 50%
        z-index: 9
        background-color: var(--v-success-base)
        display: flex
        align-items: center
        justify-content: center
        color: #ffffff !important
        span
          margin-right: 1px
      .v-btn--absolute
        background: var(--v-primary-base)
        opacity: 0.85
        color: #ffffff !important
        &.v-btn--left
          left: 4px !important
        &.v-btn--right
          right: 4px !important
        &.v-btn--bottom
          bottom: 4px !important
    article:hover
      cursor: pointer
</style>
