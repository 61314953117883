<template>
  <v-container>
    <v-card class="rounded-xl" elevation="0" color="blue-grey lighten-5">
      <v-data-table :headers="headers" :items="empleados" :search="search" dense class="elevation-0 rounded-lg"
        style="background-color:transparent;" no-data-text="Sin empleados registrados"
        loading-text="Cargando información..." :loading="loadingData" :footer-props="{
          'items-per-page-text': 'Elementos por página',
          pageText: '{0}-{1} de {2}',
        }">
        <template v-slot:top>
          <v-toolbar color="primary" flat class="rounded-xl rounded-b-0 pt-3">
            <div style="display: flex; justify-content: space-between; align-items: start; width: 100%;">
              <v-text-field v-model="search" placeholder="Buscar..." prepend-inner-icon="mdi-magnify" class="rounded-lg"
                solo-inverted dark flat dense style="max-width: 650px;"></v-text-field>
              <div>
                <!-- <AgregaPadrino @cierraRegistro="cierraRegistroAhijado" /> -->
                <v-btn small class="rounded-pill overline" dark outlined fab @click="updateTable">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </div>
            </div>
          </v-toolbar>
        </template>

        <template v-slot:item.acciones="{ item }">
          <AgregaFaltaAdministrativaForm :empleado="item" @cierraRegistroSolicitud="cierraRegistroSolicitud" />
        </template>
      </v-data-table>
    </v-card>

    <SnackBarErrorIncidencias v-if="muestraBarra" :muestraBarra="muestraBarra" :color="colorBarra" :texto="textoBarra"
      @cierraBarra="muestraBarra = false" />
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import SnackBarErrorIncidencias from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import AgregaFaltaAdministrativaForm from "./AgregaFaltaAdministrativaForm"

export default {
  components: { SnackBarErrorIncidencias, AgregaFaltaAdministrativaForm },
  data() {
    return {
      headers: [
        {
          text: "emplid",
          value: "id",
          class: "primary overline font-weight-bold white--text",
        },
        {
          text: "nombre",
          value: "title",
          class: "primary overline font-weight-bold white--text",
        },
        {
          text: "puesto",
          value: "name",
          class: "primary overline font-weight-bold white--text",
        },
        {
          text: "acciones",
          value: "acciones",
          align: "center",
          sortable: false,
          class: "primary overline font-weight-bold white--text",
        },
      ],
      empleados: [],
      loadingData: true,
      muestraBarra: false,
      colorBarra: "",
      textoBarra: "",
      search: "",
    };
  },
  async mounted() {
    this.empleados = await this.getEmpleados();
  },
  methods: {
    showSnackBar(texto, color = "pink darken-2") {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    async updateTable() {
      this.loadingData = true;
      this.padrinos = await this.getEmpleados();
    },
    getEmpleados() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        let folio = localStorage.getItem("folio");
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-organigrama-supervisor-lider/${folio}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data.children);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    setFormatDate(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      return diaUTC + "/" + mesUTC + "/" + anioUTC;
    },
    cierraRegistroSolicitud(respuesta) {
      const { message, status } = respuesta;
      const color = status ? "teal darken-2" : "pink darken-2";

      console.log(JSON.stringify(respuesta, null, 2))

      this.showSnackBar(message, color);

      if (status) {
        this.updateTable();
      }
    },
  },
};
</script>
