<template>
  <div>
    <SnackBar v-if="muestraBarra" :muestraBarra="muestraBarra" :texto="textoBarra" :color="colorBarra"
      @cierraBarra="muestraBarra = false;" />

    <v-dialog v-model="dialogSeleccionaNomina" width="500" content-class="fix-border-dialog">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="primary" class="rounded-pill elevation-0 mb-3" block>
          <v-icon left> mdi-file-cloud-outline </v-icon>
          Administración Prenóminas
        </v-btn>
      </template>

      <v-card class="rounded-xl">
        <v-card-title class="overline font-weight-bold white--text primary">
          Selecciona el tipo de nómina
        </v-card-title>

        <v-card-text class="pt-5">
          <div class="center-item-in-div-column">
            <v-btn block color="primary" class="rounded-lg mb-3" x-large @click="abrirDialogEstatus('semanal')">
              Semanal
            </v-btn>
            <v-btn block color="primary" class="rounded-lg mb-3" x-large @click="abrirDialogEstatus('quincenal')">
              Quincenal
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEstatusPrenomina" width="500" content-class="fix-border-dialog">
      <v-card class="rounded-xl">
        <v-card-title class="overline font-weight-bold white--text primary">
          Estatus Actual de la Prenómina {{ tipoNomina }}
        </v-card-title>

        <v-card-text class="pt-5">
          <div class="text-center">
            <v-select v-model="opcionPrenomina" :loading="loadingSelect" :items="opcionesPrenomina"
              label="Selecciona una opción" outlined dense></v-select>
            <v-btn color="success" class="rounded-pill mt-3" :loading="loading" :disabled="loading"
              @click="actualizarEstatus">
              Cambiar Estatus
            </v-btn>
          </div>

        </v-card-text>


      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from 'axios';
import SnackBar from '../../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue'

export default {
  mounted() {
  
  },
  components: { SnackBar },
  data() {
    return {
      dialogSeleccionaNomina: false,
      dialogEstatusPrenomina: false,
      tipoNomina: null,
      opcionPrenomina: null,
      opcionesPrenomina: [
        { text: "Prenómina Normal", value: 0 },
        { text: "Forzar Mostrar", value: 1 },
        { text: "Forzar Ocultar", value: 2 },
      ],
      muestraBarra: false,
      textoBarra: "",
      colorBarra: "",
      loading: false,
      loadingSelect: false,
    };
  },
  methods: {
    abrirDialogEstatus(tipo) {
      this.tipoNomina = tipo;
      this.dialogSeleccionaNomina = false;
      this.dialogEstatusPrenomina = true;

      this.getStatusPrenom(tipo);
    },
    showSnackbar(texto, color) {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    cerrarDialogos() {
      this.dialogEstatusPrenomina = false;
    },
    async cambiarEstatus(estatus, tipo) {
      this.loading = true;
      try {
        const response = await axios.put(`${this.$store.getters.getHostNode}/api/setEstatusPrenomina/${estatus}/${tipo}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        if (response.status) {
          this.showSnackbar(
            "Éxito. Se modificó el estatus de la prenomina.",
            "success"
          );
          console.log('Estatus cambiado correctamente');
      
        }
      } catch (error) {
        console.error('Error al modificar el estatus de la prenómina:', error);
        this.showSnackbar('Error al actualizar el estatus de prenómina');
      } finally {
        this.loading = false;
      }
    },
    async getStatusPrenom(tipoPago) {
      this.loadingSelect = true;
      try {
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/getEstatusPrenomina/${tipoPago}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        if (response.data && response.data.data !== undefined) {
          this.opcionPrenomina = response.data.data; // Asigna el valor recibido al select
          console.log('Estatus recibido:', response.data.data);
        } else {
          console.error('Respuesta inválida del servidor:', response.data);
        }
      } catch (error) {
        console.error('Error al obtener el estatus de prenómina:', error);
        this.showSnackbar('Error al obtener el estatus de prenómina');
      } finally {
        this.loadingSelect = false; // Finaliza el loading del select
      }
    },
    actualizarEstatus() {
      if (this.opcionPrenomina !== null && this.tipoNomina) {
        this.cambiarEstatus(this.opcionPrenomina, this.tipoNomina);
      } else {
        console.warn('Debe seleccionar un tipo de nómina y un estatus válido');
      }
    },
  },
};
</script>

<style>
.sdw-btn {
  box-shadow: 11px 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
}
</style>