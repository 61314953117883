<template>
  <div fluid>
    <v-data-table :headers="headerReporteEncuestas" :items="encuestas" dense no-data-text="No hay encuestas"
      :search="search" :loading="loading"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'pageText': '{0}-{1} de {2}' }">

      <template v-slot:loading>
        <div class="text-center py-4">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <p class="mt-2">Cargando datos, por favor espere...</p>
        </div>
      </template>

      <template v-slot:top>
        <div style="display: flex; justify-content: space-between; align-items: center;"
          class="primary pt-4 rounded-l-t-header-pases-admin rounded-r-t-header-pases-admin rounded-lg rounded-b-0">
          <v-text-field v-model="search" label="Buscar..." dense flat solo-inverted dark clearable
            append-icon="mdi-magnify" style="max-height: 42px; max-width: 400px;"
            class="mx-4 rounded-lg"></v-text-field>

          <v-btn color="teal" dark small @click="getEncuestas()" style="margin-right: 16px;">
            <v-icon left>mdi-refresh</v-icon>Actualizar
          </v-btn>

        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-chip-group column>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" x-small fab rounded elevation="1" color="teal darken-1" dark
                @click="EstadoEncuesta(item)">
                <v-icon>mdi-swap-horizontal</v-icon>
              </v-btn>
            </template>
            <span>Cambiar estatus</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" x-small fab rounded elevation="1" color="primary" dark
                @click="abrirEditorEncuesta(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" x-small fab rounded elevation="1" color="primary" dark
                @click="getUsuariosAsignados(item)">
                <v-icon>mdi-account-multiple-plus</v-icon>
              </v-btn>
            </template>
            <span>Cargar Usuarios</span>
          </v-tooltip>
        </v-chip-group>
      </template>

    </v-data-table>
    <v-dialog v-model="mostrarUsuariosDialog" max-width="800px" scrollable>
      <v-card>
        <v-toolbar color="primary" dark>
          Usuarios Asignados
          <v-spacer></v-spacer>
          <v-btn icon dark @click="mostrarUsuariosDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-data-table :headers="userHeaders" :items="usuariosAsignados" dense no-data-text="No hay usuarios asignados"
            :footer-props="{ 'items-per-page-text': 'Elementos por página', 'pageText': '{0}-{1} de {2}' }">
            <template v-slot:item.index="{ index }">
              <span>{{ index + 1 }}</span>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn x-small fab rounded elevation="1" color="teal darken-1" dark @click="EstadoUsuario(item)">
                <v-icon>mdi-swap-horizontal</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="teal darken-1" dark @click="mostrarCargarUsuarios = true">Cargar Usuarios</v-btn>
          <v-btn color="error" @click="mostrarUsuariosDialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="mostrarCargarUsuarios" max-width="700" scrollable content-class="fix-border-dialog">
      <v-card class="rounded-xl">
        <v-card-title class="primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold">
          Cargar Usuarios
          <v-spacer></v-spacer>
          <v-btn icon dark @click="mostrarCargarUsuarios = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
            </v-col>
            <v-col cols="12" sm="8" md="8">
              <v-file-input accept=".xlsx, .xls, .csv" placeholder="Selecciona el archivo"
                prepend-icon="mdi-microsoft-excel" v-model="file"></v-file-input>
              <v-btn color="primary" class="mt-2" @click="iniciarCargaArchivo" :disabled="!file">
                Cargar Archivo
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <editorEncuesta :dialogPreguntas.sync="muestraDialogEdicion" @cierraDialogEdicion="muestraDialogEdicion = false"
      :idEncuesta="selectedEncuestaId" :nombre="nombreEncuesta" />

    <snack-bar-error-incidencias v-if="muestraBarra" :muestraBarra="muestraBarra" :texto="textoBarra"
      :color="colorBarra" @cierraBarra="muestraBarra = false"></snack-bar-error-incidencias>
  </div>
</template>
<script>
import axios from 'axios';
import editorEncuesta from './editorEncuesta.vue';
import SnackBarErrorIncidencias from '../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue';

export default {
  components: { editorEncuesta, SnackBarErrorIncidencias },
  data() {
    return {
      file: null,
      textoBarra: '',
      colorBarra: '',
      muestraBarra: false,
      encuestas: [],
      mostrarCargarUsuarios: false,
      muestraDialogEdicion: false,
      mostrarUsuariosDialog: false,
      usuariosAsignados: [],
      search: '',
      headerReporteEncuestas: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'ENCUESTA', value: 'nombreEncuesta' },
        { text: 'USUARIO REGISTRO', value: 'Nombre' },
        { text: 'DESCRIPCION', value: 'Descripcion' },
        { text: 'ESTADO', value: 'Estado', sortable: false },
        { text: 'FECHA DE REGISTRO', value: 'Fecha', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false }
      ],
      userHeaders: [
        { text: '#', value: 'index' },
        { text: 'FOLIO', value: 'FOLIO' },
        { text: 'Nombre', value: 'EMP_NOMEMP' },
        { text: 'Estatus', value: 'ESTATUS' },
        { text: 'Acciones', value: 'actions', sortable: false }
      ],
      loading: false,
      selectedEncuestaId: null,
      nombreEncuesta: ''
    };
  },
  mounted() {
    this.getEncuestas();
  },
  methods: {
    mostrarSnackBar(mensaje, color) {
      this.textoBarra = mensaje;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    abrirEditorEncuesta(item) {
      this.muestraDialogEdicion = true;
      this.selectedEncuestaId = item.id;
      this.nombreEncuesta = item.nombreEncuesta;
    },
    async EstadoEncuesta(item) {
      this.loading = true;
      try {
        const response = await axios.put(
          `${this.$store.getters.getHostNode}/api/updateEstadoEncuesta/${item.id}/${item.Estado}`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        if (response.data.status) {
          this.getEncuestas();
        }
      } catch (error) {
        console.error('Error al actualizar el estado de la encuesta:', error);
      } finally {
        this.loading = false;
      }
    },
    async getEncuestas() {
      this.loading = true;
      try {
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/getEncuestas`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        this.encuestas = response.data.data.map((encuesta) => ({
          id: encuesta.ID,
          nombreEncuesta: encuesta.NOMBREENCUESTA,
          Nombre: encuesta.Nombre,
          Descripcion: encuesta.DESCRIPCION,
          Estado: encuesta.ESTADO === 1 ? 'Activa' : 'Innactiva',
          Fecha: encuesta.FECHA_ACTUALIZA.split('T')[0]
        }));
      } catch (error) {
        console.error('Error al obtener encuestas:', error);
      } finally {
        this.loading = false;
      }
    },
    async getUsuariosAsignados(item) {
      this.loading = true;
      try {
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/getUsuariosAsignados/${item.id}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        if (response.data.status) {
          this.usuariosAsignados = response.data.data.map(usuario => ({
            ...usuario,
            ESTATUS: usuario.ESTATUS === 1 ? 'Activo' : 'Inactivo'
          }));
          this.mostrarUsuariosDialog = true;
          // Almacena el idEncuesta para utilizarlo luego en el handleFileUpload
          this.idEncuestaActual = item.id;
        }
      } catch (error) {
        console.error('Error al obtener usuarios asignados:', error);
        this.mostrarSnackBar('Error al obtener usuarios asignados', 'red accent-2');
      } finally {
        this.loading = false;
      }
    },

    async EstadoUsuario(item) {
      try {
        // Realiza la solicitud para actualizar el estado del usuario
        const response = await axios.put(
          `${this.$store.getters.getHostNode}/api/updateEstadoUsuario/${item.FOLIO}/${item.ESTATUS}/${item.ID_ENCUESTA}`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        if (response.data.status) {
          this.mostrarSnackBar('Estado del usuario actualizado correctamente', 'green');

          await this.getUsuariosAsignados({ id: item.ID_ENCUESTA });
        } else {
          this.mostrarSnackBar('Error al actualizar el estado del usuario', 'red accent-2');
        }
      } catch (error) {
        console.error('Error al actualizar el estado del usuario:', error);
        this.mostrarSnackBar('Error al actualizar el estado del usuario', 'red accent-2');
      }
    },
    iniciarCargaArchivo() {
      if (!this.file) {
        this.mostrarSnackBar('Por favor, selecciona un archivo', 'red accent-2');
        return;
      }
      this.handleFileUpload(this.file);
      this.mostrarSnackBar('Archivo cargado correctamente', 'green');
      this.mostrarCargarUsuarios = false;
    },
    async handleFileUpload(event) {
      this.file = event;
      if (!this.file) return;

      if (!this.idEncuestaActual) {
        this.mostrarSnackBar('Por favor, seleccione una encuesta antes de cargar usuarios', 'red accent-2');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('idEncuesta', this.idEncuestaActual); // Usar `idEncuestaActual`

      try {
        const response = await axios.post(
          `${this.$store.getters.getHostNode}/api/cargarUsuariosCsv`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        if (response.data.status === 'OK') {
          this.mostrarSnackBar('Archivo procesado correctamente', 'green');
          this.mostrarCargarUsuarios = false;
        } else {
          this.mostrarSnackBar('Error al procesar el archivo', 'red accent-2');
        }
      } catch (error) {
        console.error('Error de servidor al cargar el archivo:', error);
        this.mostrarSnackBar('Error de servidor: ' + error.message, 'red accent-2');
      } finally {
        this.file = null;
      }
    },

  }
};
</script>
