var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-2 my-2 rounded-lg elevation-0",attrs:{"color":"blue-grey lighten-5"}},[(_vm.visitantes.length > 0)?_c('div',[_c('v-data-table',{staticStyle:{"background-color":"transparent"},attrs:{"dense":"","headers":_vm.headers,"items":_vm.visitantes,"single-expand":true,"expanded":_vm.expanded,"item-key":"int","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0 ma-0",attrs:{"colspan":headers.length}},[_c('v-sheet',{staticClass:"elevation-1 pa-2",attrs:{"color":"blue-grey lighten-5"}},[_c('MuestraMaterialesPorVisitante',{attrs:{"idVisitante":item.int}})],1)],1)]}},{key:"item.materiales",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mr-1",attrs:{"small":"","dark":"","color":item.registroArtPersonales === 0
              ? 'blue-grey darken-1'
              : 'teal darken-1'}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(item.registroArtPersonales === 0 ? "mdi-close-octagon" : "mdi-checkbox-marked-circle"))]),_vm._v(" Art. Personales ")],1),_c('v-chip',{staticClass:"mr-1",attrs:{"small":"","dark":"","color":item.registroConsumibles === 0
              ? 'blue-grey darken-1'
              : 'teal darken-1'}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(item.registroConsumibles === 0 ? "mdi-close-octagon" : "mdi-checkbox-marked-circle"))]),_vm._v(" Consumibles ")],1),_c('v-chip',{staticClass:"mr-1",attrs:{"small":"","dark":"","color":item.registroHerramientas === 0
              ? 'blue-grey darken-1'
              : 'teal darken-1'}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(item.registroHerramientas === 0 ? "mdi-close-octagon" : "mdi-checkbox-marked-circle"))]),_vm._v(" Herramientas ")],1),_c('v-chip',{staticClass:"mr-1",attrs:{"small":"","dark":"","color":item.registroVehiculo === 0
              ? 'blue-grey darken-1'
              : 'teal darken-1'}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(item.registroVehiculo === 0 ? "mdi-close-octagon" : "mdi-checkbox-marked-circle"))]),_vm._v(" Vehículo ")],1)]}}],null,false,3903323772)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }