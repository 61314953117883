
<template>
  <v-container class="py-1">
    <v-btn class="ma-2" x-small>
      {{$t('Ver Políticas')}}
      <v-icon right color="primary" dark>
        mdi-book-open-variant
      </v-icon>
    </v-btn>
    <v-btn class="ma-2" x-small>
      {{$t('Ver Cupones')}}
      <v-icon right color="primary" dark>
        mdi-cards
      </v-icon>
    </v-btn>
    <v-btn class="ma-2" x-small>
      {{$t('Tutorial ALU')}}
      <v-icon right color="primary" dark>
        mdi-chat-question
      </v-icon>
    </v-btn>
  </v-container>
</template>
<script>
  export default {
    name: 'persona-botones-finales',
    components: {
    },
    props: [
      'userId'
    ],
    methods: {
    }
  }
</script>
<style lang="sass" scoped>
</style>