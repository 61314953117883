<template>
  <v-dialog
    v-model="verInfoEquipo"
    max-width="700"
    persistent
    scrollable
    content-class="fix-border-dialog"
  >
    <v-card class="rounded-xl">
      <v-card-title class="px-3 py-2 primary">
        <label class="text-uppercase white--text subtitle-1 font-weight-bold">
          Información de equipo asignado.
        </label>
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cerrarEquipoAsignado')"> mdi-close </v-icon>
      </v-card-title>
      <v-card-text>
        <div v-if="!loadingContent && !sinInformacion">
          <v-expansion-panels focusable>
            <v-expansion-panel v-for="(item, index) in equipos" :key="index">
              <v-expansion-panel-header>{{
                item.Modelo
              }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense>
                  <v-col cols="6">
                    <v-card class="pa-1" outlined tile>
                      <v-subheader
                        class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                      >
                        Fecha de asignación
                      </v-subheader>
                      <v-subheader class="tam-subheader py-0 mt-0">
                        {{ item.FechaAsignacion }}
                      </v-subheader>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card class="pa-1" outlined tile>
                      <v-subheader
                        class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                      >
                        Número de serie
                      </v-subheader>
                      <v-subheader class="tam-subheader py-0 mt-0">
                        {{ item.NumeroSerie }}
                      </v-subheader>
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                    <v-card class="pa-1" outlined tile>
                      <v-subheader
                        class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                      >
                        Nombre de equipo
                      </v-subheader>
                      <v-subheader class="tam-subheader py-0 mt-0">
                        {{ item.NombreEquipo }}
                      </v-subheader>
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                    <v-card class="pa-1" outlined tile>
                      <v-subheader
                        class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                      >
                        Tipo de equipo
                      </v-subheader>
                      <v-subheader class="tam-subheader py-0 mt-0">
                        {{ item.TipoEquipo }}
                      </v-subheader>
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                    <v-card class="pa-1" outlined tile>
                      <v-subheader
                        class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                      >
                        Ubicación física
                      </v-subheader>
                      <v-subheader class="tam-subheader py-0 mt-0">
                        {{ item.UbicacionFisica }}
                      </v-subheader>
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                    <v-card class="pa-1" outlined tile>
                      <v-subheader
                        class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                      >
                        Procesador
                      </v-subheader>
                      <v-subheader class="tam-subheader py-0 mt-0">
                        {{ item.Procesador }}
                      </v-subheader>
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                    <v-card class="pa-1" outlined tile>
                      <v-subheader
                        class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                      >
                        RAM
                      </v-subheader>
                      <v-subheader class="tam-subheader py-0 mt-0">
                        {{ item.Descripcion }}
                      </v-subheader>
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                    <v-card class="pa-1" outlined tile>
                      <v-subheader
                        class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                      >
                        S. O.
                      </v-subheader>
                      <v-subheader class="tam-subheader py-0 mt-0">
                        {{ item.SistemaOperativo }}
                      </v-subheader>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card class="pa-1" outlined tile>
                      <v-subheader
                        class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                      >
                        Estatus del equipo
                      </v-subheader>
                      <v-subheader class="tam-subheader py-0 mt-0">
                        {{ item.Estatus }}
                      </v-subheader>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card class="pa-1" outlined tile>
                      <v-subheader
                        class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold"
                      >
                        Compañia
                      </v-subheader>
                      <v-subheader class="tam-subheader py-0 mt-0">
                        {{ item.NombreCompania }}
                      </v-subheader>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div v-else-if="!loadingContent && sinInformacion" class="py-10">
          <div
            class="my-4 text-center overline"
          >
            Sin información registrada.
          </div>
        </div>
        <v-row
          v-else
          class="fill-height"
          align-content="center"
          justify="center"
        >
          <v-col class="text-subtitle-1 text-center mt-10" cols="12">
            Obteniendo información...
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="primary"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>

        <div class="text-center">
          <v-btn
            depressed
            class="rounded-lg"
            dark
            large
            elevation="3"
            color="primary"
            @click="abrirModalTicket = true"
          >
            <v-icon left>mdi-ticket-confirmation-outline</v-icon>
            levantar ticket
          </v-btn>
        </div>
      </v-card-text>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>

    </v-card-actions> -->
    </v-card>

    <LevantaTicktET
      :abrirModalTicket="abrirModalTicket"
      @cerrarTicket="abrirModalTicket = false"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import LevantaTicktET from "./componentsETAsignado/LevantaTicktET.vue";
export default {
  name: "persona-equipo-asignado",
  components: { LevantaTicktET },
  props: ["verInfoEquipo"],
  data: () => ({
    loadingContent: true,
    sinInformacion: false,
    abrirModalTicket: false,
    equipos: [],
  }),
  computed: {},
  mounted() {
    this.getDatosEqipoDeTrabajoAsignado();
  },
  methods: {
    getDatosEqipoDeTrabajoAsignado() {
      this.equipos.length = 0;
      let params = {
        Opcion: 17,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.equipos = response.data.datosEquipo;
            this.loadingContent = false;
          } else if (response.data.status == "NO DATA") {
            this.loadingContent = false;
            this.sinInformacion = true;
          }
        });
    },
  },
};
</script>

<style>
.tam-subheader {
  height: 25px;
  max-height: 75px;
}
</style>
