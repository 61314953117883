<template>
    <v-card class="rounded-md elevation-3 mt-0 pa-4" color="#ECEFF1">
        <v-card class="rounded-xl elevation-2 mt-1 pa-4 ">
            <v-row>
                <v-col cols="12" sm="6" md="3">
                    <v-select dense outlined rounded clearable v-model="tipoReporte" :items="listaReportes"
                        label="Reporte" item-text="nombre" item-value="id"></v-select>
                </v-col>
                <!-- <v-col v-if="tipoReporte != 16 && tipoReporte !=18" cols="12" sm="6" md="2">
                <v-select dense clearable v-model="estiloReporte" :items="listaEstilo" label="Estilo" item-text="nombre"
                    item-value="id"></v-select>
            </v-col> -->
                <v-col cols="12" sm="6" md="2"
                    v-show="tipoReporte != 1 && tipoReporte != 17 && tipoReporte != 2 && tipoReporte && tipoReporte != 19 && tipoReporte != 21 && tipoReporte != 22 && tipoReporte != 23">
                    <v-select dense outlined rounded clearable v-model="gPReporte" :items="listaGP" label="Grupo pago"
                        item-text="nombre" item-value="id" @change="consultarDatos"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2"
                    v-show="tipoReporte != 1 && tipoReporte != 2 && tipoReporte && tipoReporte != 7 && tipoReporte != 17 && tipoReporte != 19 && tipoReporte != 10 && tipoReporte != 21 && tipoReporte != 22 && tipoReporte != 23">
                    <v-select dense outlined rounded clearable v-model="yearReporte" :items="yearsList" label="Año"
                        item-text="year" item-value="id" @change="clearSelectedMonths"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2"
                    v-show="tipoReporte != 1 && tipoReporte != 2 && tipoReporte && tipoReporte != 9 && tipoReporte != 7 && tipoReporte != 19 && tipoReporte != 16 && tipoReporte != 11 && tipoReporte != 17 && tipoReporte != 10 && tipoReporte != 21 && tipoReporte != 22 && tipoReporte != 23">
                    <v-select dense outlined rounded clearable v-model="selectedMonths" :items="months" label="Meses"
                        multiple :menu-props="{ closeOnContentClick: false }" item-text="monthName" item-value="month"
                        @change="updateSelectedMonths"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2"
                    v-show="tipoReporte != 1 && tipoReporte != 2 && tipoReporte && tipoReporte != 10 && tipoReporte != 17 && tipoReporte != 19 && tipoReporte != 11 && tipoReporte != 9 && tipoReporte != 21 && tipoReporte != 22 && tipoReporte != 23">
                    <v-select dense outlined rounded clearable v-model="periodoReporte" :items="periodos"
                        label="Periodo" item-text="period" item-value="id" :disabled="periodosResponse"
                        :loading="periodosResponseloading" no-data-text="Periodos no disponibles"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2" v-show="tipoReporte === 11 || tipoReporte === 9 || tipoReporte == 10">
                    <v-select dense outlined rounded clearable v-model="periodoReporte" :items="periodos"
                        label="Periodo" item-text="period" item-value="id" multiple :disabled="periodosResponse"
                        :loading="periodosResponseloading" no-data-text="Periodos no disponibles"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2"
                    v-show="tipoReporte != 1 && tipoReporte != 2 && tipoReporte != 11 && tipoReporte != 9 && tipoReporte != 19 && tipoReporte != 10 && tipoReporte && tipoReporte != 21 && tipoReporte != 16 && tipoReporte != 17 && tipoReporte != 7 && tipoReporte != 10 && tipoReporte != 22 && tipoReporte != 23">
                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field outlined rounded v-model="dateRange" label="Rango" prepend-icon="mdi-calendar"
                                readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateRange" @change="menu = false" range locale="mx-es" :max="maxDate"
                            min="2020-01" type="month" scrollable></v-date-picker>
                    </v-menu>
                </v-col>

                <v-col cols="12" sm="3" md="2">
                    <v-btn v-show="tipoReporte != 22" @click="Reportes()" :loading="loadingAsignacion" dark rounded
                        outlined :disabled="!tipoReporte" class="font-weight-bold" color="primary">
                        <v-icon left>mdi-magnify</v-icon>
                        {{ loadingAsignacion ? 'Cargando...' : 'Filtrar' }}
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="3" md="2">
                    <v-btn v-show="tipoReporte === 22" @click="Reportes()" :loading="loadingAsignacion" dark rounded
                        outlined :disabled="!tipoReporte" class="font-weight-bold" color="primary">
                        <v-icon left>mdi-magnify</v-icon>
                        {{ loadingAsignacion ? 'Cargando...' : 'Filtrar' }}
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="3" md="2" v-if="habilitarexcel && itemsreportes.length > 0">
                    <vue-json-to-csv v-show="tipoReporte != 22" :json-data="itemsreportes"
                        :labels="headers_report_to_export" :csv-title="title">
                        <v-btn color="green darken-2" dark class="font-weight-bold" rounded outlined
                            @click="exportToCsv()">
                            <v-icon left>mdi-microsoft-excel</v-icon>
                            Exportar
                        </v-btn>
                    </vue-json-to-csv>
                </v-col>
                <v-col cols="12">
                    <v-alert v-if="loadingAsignacion && tipoReporte !== 10" type="info">
                        El tiempo promedio estimado para la carga y procesamiento de la información es de
                        aproximadamente 4
                        minutos.
                    </v-alert>
                    <v-alert v-if="loadingAsignacion && tipoReporte === 10" type="info">
                        El tiempo promedio estimado para la carga y procesamiento de la información es de
                        aproximadamente 14
                        minutos.
                        Solo considera periodos cerrados de los meses seleccionados.
                    </v-alert>
                </v-col>

            </v-row>
        </v-card>
        <v-card v-show="itemsreportes.length > 0" class="rounded-xl mt-3 elevation-0 pa-4 ">
            <v-data-table v-show="itemsreportes.length > 0" dense :headers="headersreportes" :items="itemsreportes"
                no-data-text="Ningún elemento coincide con el criterio de búsqueda" class="ma-3" :footer-props="{
                    'items-per-page-text': 'Elementos por página',
                }" :header-props="{ 'short-by-text': 'Ordenar por', }" loading-text="Cargando contenido..."
                :loading="loadingAsignacion">
                <template v-slot:item.total="{ item }">
                    {{
                        parseFloat(item.total) == 0
                            ? ""
                            : formatMXN(parseFloat(item.total))
                    }}
                </template>
            </v-data-table>
        </v-card>
    </v-card>
</template>

<script>
import axios from "axios";
import VueJsonToCsv from 'vue-json-to-csv'

export default {
    name: "muestra-reporte",
    components: { VueJsonToCsv },

    data() {
        return {

            headers16: [
                {
                    text: "Tipo",
                    value: "type",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Concepto",
                    value: "key",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Descripción",
                    value: "descr",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Cantidad",
                    value: "counts",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Monto",
                    value: "total",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }
            ],
            headers18: [
                {
                    text: "Tipo",
                    value: "type",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Concepto",
                    value: "key",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Descripción",
                    value: "descr",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Cantidad",
                    value: "counts",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Monto",
                    value: "total",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }
            ],
            headers7: [
                {
                    text: "Folio",
                    value: "folio",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Nombre",
                    value: "name",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Grupo Pago",
                    value: "gPago",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Periodo",
                    value: "period",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Monto",
                    value: "total",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }
            ],
            headers10: [
                {
                    text: "Folio",
                    value: "clave",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Nombre",
                    value: "name",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Grupo Pago",
                    value: "gPago",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Departamento",
                    value: "depto",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Año",
                    value: "year",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Periodo",
                    value: "periodo",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Concepto",
                    value: "concepto",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Monto",
                    value: "monto",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }
            ],
            headers9: [
                {
                    text: "Folio",
                    value: "clave",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Nombre",
                    value: "name",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Año",
                    value: "year",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Concepto",
                    value: "concepto",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Monto",
                    value: "monto",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }
            ],
            headers1: [],
            headersGeneral: [
                { text: "Folio", value: "EMPLID", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Nombre", value: "NAME", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "RFC", value: "NATIONAL_ID_FORMAT", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "CURP", value: "NATIONAL_ID_MSK", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Grupo Pago", value: "DESCRSHORT", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Registro Patronal", value: "RegistroPatronal", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Sucursal", value: "BRANCHID", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Id Área", value: "DEPTID", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Área", value: "DEPTIDName", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Id Departamento", value: "EMP_KEYDEP", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Departamento", value: "DESCR1", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Id Posición", value: "EMP_KEYPUE", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Posición", value: "DESCR", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Distrito", value: "district", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "División", value: "division", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Grupo", value: "GROUP", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Región", value: "Region", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Código Postal", value: "POSTAL", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Estado", value: "STATE", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Municipio", value: "ADDRESS4", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Colonia", value: "ADDRESS3", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Domicilio", value: "ADDRESS1", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Correo", value: "EMAIL", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Teléfono", value: "PHONE", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Género", value: "SEX", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Estudios", value: "ESTUDIOS", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Estado Civil", value: "MAR_STATUS", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Fecha Nacimiento", value: "BORN", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "NoResponsable", value: "NoResponsable", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Responsable", value: "Responsable", class: "text-uppercase font-weight-bold blue primary white--text" },
            ],
            headersMoney: [
                { text: "Folio", value: "EMPLID", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Nombre", value: "NAME", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "RFC", value: "NATIONAL_ID_FORMAT", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "CURP", value: "NATIONAL_ID_MSK", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Grupo Pago", value: "DESCRSHORT", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Registro Patronal", value: "RegistroPatronal", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Sucursal", value: "BRANCHID", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Id Área", value: "DEPTID", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Área", value: "DEPTIDName", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Id Departamento", value: "EMP_KEYDEP", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Departamento", value: "DESCR1", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Id Posición", value: "EMP_KEYPUE", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Posición", value: "DESCR", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Distrito", value: "district", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "División", value: "division", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Grupo", value: "GROUP", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Región", value: "Region", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Código Postal", value: "POSTAL", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Estado", value: "STATE", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Municipio", value: "ADDRESS4", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Colonia", value: "ADDRESS3", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Domicilio", value: "ADDRESS1", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Correo", value: "EMAIL", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Teléfono", value: "PHONE", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Género", value: "SEX", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Estudios", value: "ESTUDIOS", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Estado Civil", value: "MAR_STATUS", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Fecha Nacimiento", value: "BORN", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Fecha Contratación", value: "EMP_FECING", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Último día calculado", value: "LAST_PAYMENT_DATE", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Tipo Contrato", value: "contract_type_name", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Tipo Jornada", value: "workday_type_name", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Tipo Régimen", value: "tax_regime_name", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Régimen Fiscal", value: "contractual_regime_name", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Tipo Trabajador", value: "employee_type_name", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Tipo Salario", value: "salary_type_name", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Tipo Semana", value: "week_type_name", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Tabla Prestaciones", value: "benefits_table_name", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "IMSS", value: "SSN", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Fecha Mov Nomina", value: "ACTION_DT", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Sal/Día", value: "DAILY_RT", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "SD Integrado", value: "GPMX_SDI_TOT", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Fecha Movimiento", value: "fecha_mov", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Tipo Movimiento AF", value: "type_mov", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Salario Base Fijo", value: "fixed_contribution_base_salary", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Salario Base Variable", value: "variable_contribution_base_salary", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Salario Base Cotización", value: "contribution_base_salary", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Banco", value: "BANK_NAME", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Tarjeta", value: "CARD", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Nº Cuenta", value: "ACCOUNT", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Clabe", value: "CLABE", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "NoResponsable", value: "NoResponsable", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Responsable", value: "Responsable", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Estructura", value: "Estructura", class: "text-uppercase font-weight-bold blue primary white--text" },
            ],

            headers20: [
                {
                    text: "No Empleado",
                    value: "employee_id",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Neto Pagar",
                    value: "net",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },

            ],
            headers2: [
                {
                    text: "# Zentric",
                    value: "id",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "# Dh",
                    value: "employee_id",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Colaborador",
                    value: "emp_full_name",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Nómina",
                    value: "payroll",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Tipo de crédito",
                    value: "credit_type",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Nº Prest",
                    value: "credit_id",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Inicio",
                    value: "start_date",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },

                {
                    text: "Monto",
                    value: "amount",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Descuento",
                    value: "discount",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Saldo",
                    value: "balance",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }

            ],
            headers11: [
                {
                    text: "Folio",
                    value: "clave",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Nombre",
                    value: "name",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Grupo Pago",
                    value: "gPago",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "No. IMSS",
                    value: "numIMSS",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }, {
                    text: "Registro Patronal",
                    value: "employerRegistry",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Año",
                    value: "year",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Concepto",
                    value: "concepto",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Monto",
                    value: "monto",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }
            ],
            headers19: [
                {
                    text: "Folio",
                    value: "EMPLID",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Nombre",
                    value: "NAME",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Grupo Pago",
                    value: "DESCRSHORT",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Ingreso",
                    value: "HIRE_DT",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }, {
                    text: "Salario Diario",
                    value: "DAILY_RT",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Aguinaldo",
                    value: "christmas_days",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Prima Vacacional",
                    value: "vacation_allowances",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Vacaciones",
                    value: "vacation_days",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "FI",
                    value: "integrating_factor",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }
            ],
            headers21: [
                {
                    text: "Folio",
                    value: "EMPLID",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Nombre",
                    value: "NAME",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Datos Nomimas",
                    value: "payroll_name_datos",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Nomina IMSS",
                    value: "payroll_name_imss",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }, {
                    text: "Mov Nomina",
                    value: "payroll_name_salario",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Mov Afiliatrios",
                    value: "payroll_name_mov",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Estructura",
                    value: "Estructura",
                    class: "text-uppercase font-weight-bold blue primary white--text"
                },
                {
                    text: "Ingreso",
                    value: "EMP_FECING",
                    class: "text-uppercase font-weight-bold blue primary white--text"
                },

            ],
            headers23: [
                {
                    value: "",
                    text: "EMPLEADO",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    value: "",
                    text: "NOMBRE",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    value: "",
                    text: "AÑO",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },

            ],
            //
            headers_report_to_export23Comisionistas: {
                empleado: {
                    title: "EMPLEADO"
                },
                nombre: {
                    title: "NOMBRE"
                },
                año: {
                    title: "AÑO"
                },
                periodo: {
                    title: "PERIODO"
                },
                vacaciones_gravado_p02: {
                    title: "Vacaciones GRAVADO P02"
                },
                vacaciones_exento_p02: {
                    title: "Vacaciones EXENTO P02"
                },
                vacaciones_monto_p02: {
                    title: "Vacaciones MONTO P02"
                },
                sueldo_gravado_p01: {
                    title: "Sueldo GRAVADO P01"
                },
                sueldo_exento_p01: {
                    title: "Sueldo EXENTO P01"
                },
                sueldo_monto_p01: {
                    title: "Sueldo MONTO P01"
                },
                prima_vacacional_gravado_p04: {
                    title: "Prima Vacacional GRAVADO P04"
                },
                prima_vacacional_exento_p04: {
                    title: "Prima Vacacional EXENTO P04"
                },
                prima_vacacional_monto_p04: {
                    title: "Prima Vacacional MONTO P04"
                },
                vales_de_despensa_gravado_p23: {
                    title: "Vales de despensa GRAVADO P23"
                },
                vales_de_despensa_exento_p23: {
                    title: "Vales de despensa EXENTO P23"
                },
                vales_de_despensa_monto_p23: {
                    title: "Vales de despensa MONTO P23"
                },
                fondo_de_ahorro_empresa_gravado_p128: {
                    title: "Fondo de ahorro empresa GRAVADO P128"
                },
                fondo_de_ahorro_empresa_exento_p128: {
                    title: "Fondo de ahorro empresa EXENTO P128"
                },
                fondo_de_ahorro_empresa_monto_p128: {
                    title: "Fondo de ahorro empresa MONTO P128"
                },
                festivo_laborado_gravado_p131: {
                    title: "Festivo laborado GRAVADO P131"
                },
                festivo_laborado_exento_p131: {
                    title: "Festivo laborado EXENTO P131"
                },
                festivo_laborado_monto_p131: {
                    title: "Festivo laborado MONTO P131"
                },
                fondo_de_ahorro_empresa_gravado_p127: {
                    title: "Fondo de ahorro Empresa GRAVADO P127"
                },
                fondo_de_ahorro_empresa_exento_p127: {
                    title: "Fondo de ahorro Empresa EXENTO P127"
                },
                fondo_de_ahorro_empresa_monto_p127: {
                    title: "Fondo de ahorro Empresa MONTO P127"
                },
                comisiones_del_mes_gravado_p87: {
                    title: "Comisiones del mes GRAVADO P87"
                },
                comisiones_del_mes_exento_p87: {
                    title: "Comisiones del mes EXENTO P87"
                },
                comisiones_del_mes_monto_p87: {
                    title: "Comisiones del mes MONTO P87"
                },
                vacaciones_sueldo_promedio_gravado_p133: {
                    title: "Vacaciones sueldo promedio GRAVADO P133"
                },
                vacaciones_sueldo_promedio_exento_p133: {
                    title: "Vacaciones sueldo promedio EXENTO P133"
                },
                vacaciones_sueldo_promedio_monto_p133: {
                    title: "Vacaciones sueldo promedio MONTO P133"
                },
                prima_vacacional_gravado_p134: {
                    title: "Prima vacacional GRAVADO P134"
                },
                prima_vacacional_exento_p134: {
                    title: "Prima vacacional EXENTO P134"
                },
                prima_vacacional_monto_p134: {
                    title: "Prima vacacional MONTO P134"
                },
                complemento_sdo_jornada_ord_gravado_p83: {
                    title: "COMPLEMENTO SDO. JORNADA ORD. GRAVADO P83"
                },
                complemento_sdo_jornada_ord_exento_p83: {
                    title: "COMPLEMENTO SDO. JORNADA ORD. EXENTO P83"
                },
                complemento_sdo_jornada_ord_monto_p83: {
                    title: "COMPLEMENTO SDO. JORNADA ORD. MONTO P83"
                },
                retroactivo_de_sueldo_gravado_p86: {
                    title: "Retroactivo de sueldo GRAVADO P86"
                },
                retroactivo_de_sueldo_exento_p86: {
                    title: "Retroactivo de sueldo EXENTO P86"
                },
                retroactivo_de_sueldo_monto_p86: {
                    title: "Retroactivo de sueldo MONTO P86"
                },
                prima_seguro_gtos_m_m_gravado_p123: {
                    title: "Prima seguro Gtos. M. M. GRAVADO P123"
                },
                prima_seguro_gtos_m_m_exento_p123: {
                    title: "Prima seguro Gtos. M. M. EXENTO P123"
                },
                prima_seguro_gtos_m_m_monto_p123: {
                    title: "Prima seguro Gtos. M. M. MONTO P123"
                },
                gratificación_especial_gravado_p81: {
                    title: "Gratificación especial GRAVADO P81"
                },
                gratificación_especial_exento_p81: {
                    title: "Gratificación especial EXENTO P81"
                },
                gratificación_especial_monto_p81: {
                    title: "Gratificación especial MONTO P81"
                },
                total_percepcion: {
                    title: "TOTAL PERCEPCION"
                },
                infonavit_gravado_d05: {
                    title: "INFONAVIT GRAVADO D05"
                },
                infonavit_exento_d05: {
                    title: "INFONAVIT EXENTO D05"
                },
                infonavit_monto_d05: {
                    title: "INFONAVIT MONTO D05"
                },
                infonavit_gravado_d06: {
                    title: "INFONAVIT GRAVADO D06"
                },
                infonavit_exento_d06: {
                    title: "INFONAVIT EXENTO D06"
                },
                infonavit_monto_d06: {
                    title: "INFONAVIT MONTO D06"
                },
                isr_gravado_d08: {
                    title: "ISR GRAVADO D08"
                },
                isr_exento_d08: {
                    title: "ISR EXENTO D08"
                },
                isr_monto_d08: {
                    title: "ISR MONTO D08"
                },
                fondo_de_ahorro_empresa_gravado_d10: {
                    title: "Fondo de Ahorro Empresa GRAVADO D10"
                },
                fondo_de_ahorro_empresa_exento_d10: {
                    title: "Fondo de Ahorro Empresa EXENTO D10"
                },
                fondo_de_ahorro_empresa_monto_d10: {
                    title: "Fondo de Ahorro Empresa MONTO D10"
                },
                fondo_de_ahorro_gravado_d09: {
                    title: "Fondo de Ahorro GRAVADO D09"
                },
                fondo_de_ahorro_exento_d09: {
                    title: "Fondo de Ahorro EXENTO D09"
                },
                fondo_de_ahorro_monto_d09: {
                    title: "Fondo de Ahorro MONTO D09"
                },
                deducción_vales_gravado_d111: {
                    title: "Deducción Vales GRAVADO D111"
                },
                deducción_vales_exento_d111: {
                    title: "Deducción Vales EXENTO D111"
                },
                deducción_vales_monto_d111: {
                    title: "Deducción Vales MONTO D111"
                },
                crédito_fonacot_gravado_d15: {
                    title: "Crédito FONACOT GRAVADO D15"
                },
                crédito_fonacot_exento_d15: {
                    title: "Crédito FONACOT EXENTO D15"
                },
                crédito_fonacot_monto_d15: {
                    title: "Crédito FONACOT MONTO D15"
                },
                imss_gravado_d07: {
                    title: "IMSS GRAVADO D07"
                },
                imss_exento_d07: {
                    title: "IMSS EXENTO D07"
                },
                imss_monto_d07: {
                    title: "IMSS MONTO D07"
                },
                pensión_alimenticia_gravado_d13: {
                    title: "Pensión Alimenticia GRAVADO D13"
                },
                pensión_alimenticia_exento_d13: {
                    title: "Pensión Alimenticia EXENTO D13"
                },
                pensión_alimenticia_monto_d13: {
                    title: "Pensión Alimenticia MONTO D13"
                },
                pensión_alimenticia_gravado_d109: {
                    title: "Pensión alimenticia GRAVADO D109"
                },
                pensión_alimenticia_exento_d109: {
                    title: "Pensión alimenticia EXENTO D109"
                },
                pensión_alimenticia_monto_d109: {
                    title: "Pensión alimenticia MONTO D109"
                },
                caja_de_ahorros_cuenta_gravado_d106: {
                    title: "Caja de ahorros cuenta GRAVADO D106"
                },
                caja_de_ahorros_cuenta_exento_d106: {
                    title: "Caja de ahorros cuenta EXENTO D106"
                },
                caja_de_ahorros_cuenta_monto_d106: {
                    title: "Caja de ahorros cuenta MONTO D106"
                },
                abono_de_prestamos_gravado_d88: {
                    title: "Abono de prestamos GRAVADO D88"
                },
                abono_de_prestamos_exento_d88: {
                    title: "Abono de prestamos EXENTO D88"
                },
                abono_de_prestamos_monto_d88: {
                    title: "Abono de prestamos MONTO D88"
                },
                caja_ahorros_abono_gravado_d107: {
                    title: "Caja ahorros abono GRAVADO D107"
                },
                caja_ahorros_abono_exento_d107: {
                    title: "Caja ahorros abono EXENTO D107"
                },
                caja_ahorros_abono_monto_d107: {
                    title: "Caja ahorros abono MONTO D107"
                },
                computadora_gravado_d90: {
                    title: "Computadora GRAVADO D90"
                },
                computadora_exento_d90: {
                    title: "Computadora EXENTO D90"
                },
                computadora_monto_d90: {
                    title: "Computadora MONTO D90"
                },
                ayudate_gravado_d98: {
                    title: "Ayudate GRAVADO D98"
                },
                ayudate_exento_d98: {
                    title: "Ayudate EXENTO D98"
                },
                ayudate_monto_d98: {
                    title: "Ayudate MONTO D98"
                },
                deposito_externo_gravado_d108: {
                    title: "Deposito externo GRAVADO D108"
                },
                deposito_externo_exento_d108: {
                    title: "Deposito externo EXENTO D108"
                },
                deposito_externo_monto_d108: {
                    title: "Deposito externo MONTO D108"
                },
                tarjeta_de_credito_gravado_d87: {
                    title: "Tarjeta de credito GRAVADO D87"
                },
                tarjeta_de_credito_exento_d87: {
                    title: "Tarjeta de credito EXENTO D87"
                },
                tarjeta_de_credito_monto_d87: {
                    title: "Tarjeta de credito MONTO D87"
                },
                fondo_de_ahorro_empresa_gravado_d114: {
                    title: "Fondo de Ahorro Empresa GRAVADO D114"
                },
                fondo_de_ahorro_empresa_exento_d114: {
                    title: "Fondo de Ahorro Empresa EXENTO D114"
                },
                fondo_de_ahorro_empresa_monto_d114: {
                    title: "Fondo de Ahorro Empresa MONTO D114"
                },
                fondo_de_ahorro_gravado_d113: {
                    title: "Fondo de Ahorro GRAVADO D113"
                },
                fondo_de_ahorro_exento_d113: {
                    title: "Fondo de Ahorro EXENTO D113"
                },
                fondo_de_ahorro_monto_d113: {
                    title: "Fondo de Ahorro MONTO D113"
                },
                fondo_fijo__variable_gravado_d85: {
                    title: "Fondo fijo / variable GRAVADO D85"
                },
                fondo_fijo__variable_exento_d85: {
                    title: "Fondo fijo / variable EXENTO D85"
                },
                fondo_fijo__variable_monto_d85: {
                    title: "Fondo fijo / variable MONTO D85"
                },
                seguro_automovil_gravado_d94: {
                    title: "Seguro automovil GRAVADO D94"
                },
                seguro_automovil_exento_d94: {
                    title: "Seguro automovil EXENTO D94"
                },
                seguro_automovil_monto_d94: {
                    title: "Seguro automovil MONTO D94"
                },
                anticipos__ctas_banamex_gravado_d89: {
                    title: "Anticipos / Ctas. Banamex GRAVADO D89"
                },
                anticipos__ctas_banamex_exento_d89: {
                    title: "Anticipos / Ctas. Banamex EXENTO D89"
                },
                anticipos__ctas_banamex_monto_d89: {
                    title: "Anticipos / Ctas. Banamex MONTO D89"
                },
                ayuda_voluntaria_gravado_d96: {
                    title: "Ayuda voluntaria GRAVADO D96"
                },
                ayuda_voluntaria_exento_d96: {
                    title: "Ayuda voluntaria EXENTO D96"
                },
                ayuda_voluntaria_monto_d96: {
                    title: "Ayuda voluntaria MONTO D96"
                },
                prima_seg_gtos_m_m_gravado_d86: {
                    title: "Prima seg. Gtos. M. M. GRAVADO D86"
                },
                prima_seg_gtos_m_m_exento_d86: {
                    title: "Prima seg. Gtos. M. M. EXENTO D86"
                },
                prima_seg_gtos_m_m_monto_d86: {
                    title: "Prima seg. Gtos. M. M. MONTO D86"
                },
                prima_seg_de_gtos_med_may_gravado_d83: {
                    title: "Prima seg. de Gtos. Med. May. GRAVADO D83"
                },
                prima_seg_de_gtos_med_may_exento_d83: {
                    title: "Prima seg. de Gtos. Med. May. EXENTO D83"
                },
                prima_seg_de_gtos_med_may_monto_d83: {
                    title: "Prima seg. de Gtos. Med. May. MONTO D83"
                },
                muestras_gravado_d93: {
                    title: "Muestras GRAVADO D93"
                },
                muestras_exento_d93: {
                    title: "Muestras EXENTO D93"
                },
                muestras_monto_d93: {
                    title: "Muestras MONTO D93"
                },
                infonavit_ajuste_gravado_d80: {
                    title: "Infonavit ajuste GRAVADO D80"
                },
                infonavit_ajuste_exento_d80: {
                    title: "Infonavit ajuste EXENTO D80"
                },
                infonavit_ajuste_monto_d80: {
                    title: "Infonavit ajuste MONTO D80"
                },
                intereses_ssi_caja_de_ahorro_gravado_d103: {
                    title: "Intereses s/si caja de ahorro GRAVADO D103"
                },
                intereses_ssi_caja_de_ahorro_exento_d103: {
                    title: "Intereses s/si caja de ahorro EXENTO D103"
                },
                intereses_ssi_caja_de_ahorro_monto_d103: {
                    title: "Intereses s/si caja de ahorro MONTO D103"
                },
                infonavit_gravado_d118: {
                    title: "INFONAVIT GRAVADO D118"
                },
                infonavit_exento_d118: {
                    title: "INFONAVIT EXENTO D118"
                },
                infonavit_monto_d118: {
                    title: "INFONAVIT MONTO D118"
                },
                tarjeta_vales__varios_gravado_d95: {
                    title: "Tarjeta vales / varios GRAVADO D95"
                },
                tarjeta_vales__varios_exento_d95: {
                    title: "Tarjeta vales / varios EXENTO D95"
                },
                tarjeta_vales__varios_monto_d95: {
                    title: "Tarjeta vales / varios MONTO D95"
                },
                pensión_alimenticia_gravado_d121: {
                    title: "Pensión alimenticia GRAVADO D121"
                },
                pensión_alimenticia_exento_d121: {
                    title: "Pensión alimenticia EXENTO D121"
                },
                pensión_alimenticia_monto_d121: {
                    title: "Pensión alimenticia MONTO D121"
                },
                total_deduccion: {
                    title: "TOTAL DEDUCCION"
                },
                ayuda_por_nacimiento_gravado_o88: {
                    title: "Ayuda por nacimiento GRAVADO O88"
                },
                ayuda_por_nacimiento_exento_o88: {
                    title: "Ayuda por nacimiento EXENTO O88"
                },
                ayuda_por_nacimiento_monto_o88: {
                    title: "Ayuda por nacimiento MONTO O88"
                },
                retiro_caja_de_ahorro_gravado_o83: {
                    title: "Retiro caja de ahorro GRAVADO O83"
                },
                retiro_caja_de_ahorro_exento_o83: {
                    title: "Retiro caja de ahorro EXENTO O83"
                },
                retiro_caja_de_ahorro_monto_o83: {
                    title: "Retiro caja de ahorro MONTO O83"
                },
                prestamo_sin_intereses_gravado_o84: {
                    title: "Prestamo sin intereses GRAVADO O84"
                },
                prestamo_sin_intereses_exento_o84: {
                    title: "Prestamo sin intereses EXENTO O84"
                },
                prestamo_sin_intereses_monto_o84: {
                    title: "Prestamo sin intereses MONTO O84"
                },
                prestamo_caja_de_ahorro_gravado_o82: {
                    title: "Prestamo caja de ahorro GRAVADO O82"
                },
                prestamo_caja_de_ahorro_exento_o82: {
                    title: "Prestamo caja de ahorro EXENTO O82"
                },
                prestamo_caja_de_ahorro_monto_o82: {
                    title: "Prestamo caja de ahorro MONTO O82"
                },
                deposito_externo_gravado_p124: {
                    title: "Deposito externo GRAVADO P124"
                },
                deposito_externo_exento_p124: {
                    title: "Deposito externo EXENTO P124"
                },
                deposito_externo_monto_p124: {
                    title: "Deposito externo MONTO P124"
                },
                prestamo_fondo_de_ahorro_gravado_o85: {
                    title: "Prestamo fondo de ahorro GRAVADO O85"
                },
                prestamo_fondo_de_ahorro_exento_o85: {
                    title: "Prestamo fondo de ahorro EXENTO O85"
                },
                prestamo_fondo_de_ahorro_monto_o85: {
                    title: "Prestamo fondo de ahorro MONTO O85"
                },
                ayuda_por_defunción_gravado_o90: {
                    title: "Ayuda por defunción GRAVADO O90"
                },
                ayuda_por_defunción_exento_o90: {
                    title: "Ayuda por defunción EXENTO O90"
                },
                ayuda_por_defunción_monto_o90: {
                    title: "Ayuda por defunción MONTO O90"
                },
                devolución_de_fonacot_gravado_o92: {
                    title: "Devolución de Fonacot GRAVADO O92"
                },
                devolución_de_fonacot_exento_o92: {
                    title: "Devolución de Fonacot EXENTO O92"
                },
                devolución_de_fonacot_monto_o92: {
                    title: "Devolución de Fonacot MONTO O92"
                },

                lentes_graduados_gravado_o94: {
                    title: "Lentes graduados GRAVADO O94"
                },
                lentes_graduados_exento_o94: {
                    title: "Lentes graduados EXENTO O94"
                },
                lentes_graduados_monto_o94: {
                    title: "Lentes graduados MONTO O94"
                },
                rembolsos_gravado_o95: {
                    title: "Rembolsos GRAVADO O95"
                },
                rembolsos_exento_o95: {
                    title: "Rembolsos EXENTO O95"
                },
                rembolsos_monto_o95: {
                    title: "Rembolsos MONTO O95"
                },
                total_otros_pagos: {
                    title: "TOTAL OTROS PAGOS"
                },
                isn_gravado_r04: {
                    title: "ISN GRAVADO R04"
                },
                isn_exento_r04: {
                    title: "ISN EXENTO R04"
                },
                isn_monto_r04: {
                    title: "ISN MONTO R04"
                },
                prov_p_vacacional_gravado_r02: {
                    title: "Prov. P. Vacacional GRAVADO R02"
                },
                prov_p_vacacional_exento_r02: {
                    title: "Prov. P. Vacacional EXENTO R02"
                },
                prov_p_vacacional_monto_r02: {
                    title: "Prov. P. Vacacional MONTO R02"
                },
                prov_aguinaldo_gravado_r01: {
                    title: "Prov. Aguinaldo GRAVADO R01"
                },
                prov_aguinaldo_exento_r01: {
                    title: "Prov. Aguinaldo EXENTO R01"
                },
                prov_aguinaldo_monto_r01: {
                    title: "Prov. Aguinaldo MONTO R01"
                },
                imss_patrón_gravado_r03: {
                    title: "IMSS Patrón GRAVADO R03"
                },
                imss_patrón_exento_r03: {
                    title: "IMSS Patrón EXENTO R03"
                },
                imss_patrón_monto_r03: {
                    title: "IMSS Patrón MONTO R03"
                },
                total_provisiones: {
                    title: "TOTAL PROVISIONES"
                },
                total_de_percepciones_gravado_i18: {
                    title: "Total de Percepciones GRAVADO I18"
                },
                total_de_percepciones_exento_i18: {
                    title: "Total de Percepciones EXENTO I18"
                },
                total_de_percepciones_monto_i18: {
                    title: "Total de Percepciones MONTO I18"
                },
                neto_a_pagar_gravado_i20: {
                    title: "Neto a Pagar GRAVADO I20"
                },
                neto_a_pagar_exento_i20: {
                    title: "Neto a Pagar EXENTO I20"
                },
                neto_a_pagar_monto_i20: {
                    title: "Neto a Pagar MONTO I20"
                },
                total_de_deducciones_gravado_i19: {
                    title: "Total de Deducciones GRAVADO I19"
                },
                total_de_deducciones_exento_i19: {
                    title: "Total de Deducciones EXENTO I19"
                },
                total_de_deducciones_monto_i19: {
                    title: "Total de Deducciones MONTO I19"
                },
                comisionista_temporal_gravado_i94: {
                    title: "COMISIONISTA TEMPORAL GRAVADO I94"
                },
                comisionista_temporal_exento_i94: {
                    title: "COMISIONISTA TEMPORAL EXENTO I94"
                },
                comisionista_temporal_monto_i94: {
                    title: "COMISIONISTA TEMPORAL MONTO I94"
                },
                comisionista_temporal_gravado_i98: {
                    title: "Comisionista temporal GRAVADO I98"
                },
                comisionista_temporal_exento_i98: {
                    title: "Comisionista temporal EXENTO I98"
                },
                comisionista_temporal_monto_i98: {
                    title: "Comisionista temporal MONTO I98"
                },
                riesgo_de_trabajo_patrón_gravado_i17: {
                    title: "Riesgo de Trabajo Patrón GRAVADO I17"
                },
                riesgo_de_trabajo_patrón_exento_i17: {
                    title: "Riesgo de Trabajo Patrón EXENTO I17"
                },
                riesgo_de_trabajo_patrón_monto_i17: {
                    title: "Riesgo de Trabajo Patrón MONTO I17"
                },
                retiro_patrón_gravado_i14: {
                    title: "Retiro Patrón GRAVADO I14"
                },
                retiro_patrón_exento_i14: {
                    title: "Retiro Patrón EXENTO I14"
                },
                retiro_patrón_monto_i14: {
                    title: "Retiro Patrón MONTO I14"
                },
                prestaciones_en_dinero_patrón_gravado_i10: {
                    title: "Prestaciones en Dinero Patrón GRAVADO I10"
                },
                prestaciones_en_dinero_patrón_exento_i10: {
                    title: "Prestaciones en Dinero Patrón EXENTO I10"
                },
                prestaciones_en_dinero_patrón_monto_i10: {
                    title: "Prestaciones en Dinero Patrón MONTO I10"
                },
                prestaciones_en_dinero_obrero_gravado_i04: {
                    title: "Prestaciones en Dinero Obrero GRAVADO I04"
                },
                prestaciones_en_dinero_obrero_exento_i04: {
                    title: "Prestaciones en Dinero Obrero EXENTO I04"
                },
                prestaciones_en_dinero_obrero_monto_i04: {
                    title: "Prestaciones en Dinero Obrero MONTO I04"
                },
                isr_causado_gravado_i01: {
                    title: "ISR Causado GRAVADO I01"
                },
                isr_causado_exento_i01: {
                    title: "ISR Causado EXENTO I01"
                },
                isr_causado_monto_i01: {
                    title: "ISR Causado MONTO I01"
                },
                invalidez_y_vida_patrón_gravado_i12: {
                    title: "Invalidez y Vida Patrón GRAVADO I12"
                },
                invalidez_y_vida_patrón_exento_i12: {
                    title: "Invalidez y Vida Patrón EXENTO I12"
                },
                invalidez_y_vida_patrón_monto_i12: {
                    title: "Invalidez y Vida Patrón MONTO I12"
                },
                invalidez_y_vida_obrero_gravado_i06: {
                    title: "Invalidez y Vida Obrero GRAVADO I06"
                },
                invalidez_y_vida_obrero_exento_i06: {
                    title: "Invalidez y Vida Obrero EXENTO I06"
                },
                invalidez_y_vida_obrero_monto_i06: {
                    title: "Invalidez y Vida Obrero MONTO I06"
                },
                infonavit_patrón_gravado_i16: {
                    title: "INFONAVIT Patrón GRAVADO I16"
                },
                infonavit_patrón_exento_i16: {
                    title: "INFONAVIT Patrón EXENTO I16"
                },
                infonavit_patrón_monto_i16: {
                    title: "INFONAVIT Patrón MONTO I16"
                },
                guarderías_patrón_gravado_i13: {
                    title: "Guarderías Patrón GRAVADO I13"
                },
                guarderías_patrón_exento_i13: {
                    title: "Guarderías Patrón EXENTO I13"
                },
                guarderías_patrón_monto_i13: {
                    title: "Guarderías Patrón MONTO I13"
                },
                gastos_médicos_patrón_gravado_i11: {
                    title: "Gastos Médicos Patrón GRAVADO I11"
                },
                gastos_médicos_patrón_exento_i11: {
                    title: "Gastos Médicos Patrón EXENTO I11"
                },
                gastos_médicos_patrón_monto_i11: {
                    title: "Gastos Médicos Patrón MONTO I11"
                },
                gastos_médicos_obrero_gravado_i05: {
                    title: "Gastos Médicos Obrero GRAVADO I05"
                },
                gastos_médicos_obrero_exento_i05: {
                    title: "Gastos Médicos Obrero EXENTO I05"
                },
                gastos_médicos_obrero_monto_i05: {
                    title: "Gastos Médicos Obrero MONTO I05"
                },
                cuota_fija_patrón_gravado_i08: {
                    title: "Cuota Fija Patrón GRAVADO I08"
                },
                cuota_fija_patrón_exento_i08: {
                    title: "Cuota Fija Patrón EXENTO I08"
                },
                cuota_fija_patrón_monto_i08: {
                    title: "Cuota Fija Patrón MONTO I08"
                },
                cuota_adicional_patrón_gravado_i09: {
                    title: "Cuota Adicional Patrón GRAVADO I09"
                },
                cuota_adicional_patrón_exento_i09: {
                    title: "Cuota Adicional Patrón EXENTO I09"
                },
                cuota_adicional_patrón_monto_i09: {
                    title: "Cuota Adicional Patrón MONTO I09"
                },
                cuota_adicional_obrero_gravado_i03: {
                    title: "Cuota Adicional Obrero GRAVADO I03"
                },
                cuota_adicional_obrero_exento_i03: {
                    title: "Cuota Adicional Obrero EXENTO I03"
                },
                cuota_adicional_obrero_monto_i03: {
                    title: "Cuota Adicional Obrero MONTO I03"
                },
                cesantía_y_vejez_patrón_gravado_i15: {
                    title: "Cesantía y Vejez Patrón GRAVADO I15"
                },
                cesantía_y_vejez_patrón_exento_i15: {
                    title: "Cesantía y Vejez Patrón EXENTO I15"
                },
                cesantía_y_vejez_patrón_monto_i15: {
                    title: "Cesantía y Vejez Patrón MONTO I15"
                },
                cesantía_y_vejez_obrero_gravado_i07: {
                    title: "Cesantía y Vejez Obrero GRAVADO I07"
                },
                cesantía_y_vejez_obrero_exento_i07: {
                    title: "Cesantía y Vejez Obrero EXENTO I07"
                },
                cesantía_y_vejez_obrero_monto_i07: {
                    title: "Cesantía y Vejez Obrero MONTO I07"
                },
                subsidio_causado_gravado_i02: {
                    title: "Subsidio Causado GRAVADO I02"
                },
                subsidio_causado_exento_i02: {
                    title: "Subsidio Causado EXENTO I02"
                },
                subsidio_causado_monto_i02: {
                    title: "Subsidio Causado MONTO I02"
                },
                incapacidad_gravado_d21: {
                    title: "Incapacidad GRAVADO D21"
                },
                incapacidad_exento_d21: {
                    title: "Incapacidad EXENTO D21"
                },
                incapacidad_monto_d21: {
                    title: "Incapacidad MONTO D21"
                },
                total_otros_pagos_gravado_i21: {
                    title: "Total Otros Pagos GRAVADO I21"
                },
                total_otros_pagos_exento_i21: {
                    title: "Total Otros Pagos EXENTO I21"
                },
                total_otros_pagos_monto_i21: {
                    title: "Total Otros Pagos MONTO I21"
                },
                acumulado_fondo_de_ahorro_gravado_i103: {
                    title: "Acumulado Fondo de ahorro GRAVADO I103"
                },
                acumulado_fondo_de_ahorro_exento_i103: {
                    title: "Acumulado Fondo de ahorro EXENTO I103"
                },
                acumulado_fondo_de_ahorro_monto_i103: {
                    title: "Acumulado Fondo de ahorro MONTO I103"
                },
                acumulado_caja_de_ahorros_cuenta_gravado_i105: {
                    title: "Acumulado Caja de ahorros cuenta GRAVADO I105"
                },
                acumulado_caja_de_ahorros_cuenta_exento_i105: {
                    title: "Acumulado Caja de ahorros cuenta EXENTO I105"
                },
                acumulado_caja_de_ahorros_cuenta_monto_i105: {
                    title: "Acumulado Caja de ahorros cuenta MONTO I105"
                },
                acumulado_caja_ahorros_abono_gravado_i107: {
                    title: "Acumulado Caja ahorros abono GRAVADO I107"
                },
                acumulado_caja_ahorros_abono_exento_i107: {
                    title: "Acumulado Caja ahorros abono EXENTO I107"
                },
                acumulado_caja_ahorros_abono_monto_i107: {
                    title: "Acumulado Caja ahorros abono MONTO I107"
                },
                administrativo_temporal_gravado_i90: {
                    title: "ADMINISTRATIVO TEMPORAL GRAVADO I90"
                },
                administrativo_temporal_exento_i90: {
                    title: "ADMINISTRATIVO TEMPORAL EXENTO I90"
                },
                administrativo_temporal_monto_i90: {
                    title: "ADMINISTRATIVO TEMPORAL MONTO I90"
                },
                sueldo_promedio_gravado_i87: {
                    title: "Sueldo promedio GRAVADO I87"
                },
                sueldo_promedio_exento_i87: {
                    title: "Sueldo promedio EXENTO I87"
                },
                sueldo_promedio_monto_i87: {
                    title: "Sueldo promedio MONTO I87"
                },
                comisiones_periodo_anterior_gravado_i112: {
                    title: "Comisiones Periodo Anterior GRAVADO I112"
                },
                comisiones_periodo_anterior_exento_i112: {
                    title: "Comisiones Periodo Anterior EXENTO I112"
                },
                comisiones_periodo_anterior_monto_i112: {
                    title: "Comisiones Periodo Anterior MONTO I112"
                },
                falta_gravado_d01: {
                    title: "Falta GRAVADO D01"
                },
                falta_exento_d01: {
                    title: "Falta EXENTO D01"
                },
                falta_monto_d01: {
                    title: "Falta MONTO D01"
                },
                total_informativos: {
                    title: "TOTAL INFORMATIVOS"
                },
                total_isr: {
                    title: "TOTAL ISR"
                },
                ajuste_subsidio_causado_gravado_d23: {
                    title: "Ajuste Subsidio Causado GRAVADO D23"
                },
                ajuste_subsidio_causado_exento_d23: {
                    title: "Ajuste Subsidio Causado EXENTO D23"
                },
                ajuste_subsidio_causado_monto_d23: {
                    title: "Ajuste Subsidio Causado MONTO D23"
                },
                ajuste_isr_gravado_d22: {
                    title: "Ajuste ISR GRAVADO D22"
                },
                ajuste_isr_exento_d22: {
                    title: "Ajuste ISR EXENTO D22"
                },
                ajuste_isr_monto_d22: {
                    title: "Ajuste ISR MONTO D22"
                },
                ajuste_isr_por_subsidio_gravado_o06: {
                    title: "Ajuste ISR por Subsidio GRAVADO O06"
                },
                ajuste_isr_por_subsidio_exento_o06: {
                    title: "Ajuste ISR por Subsidio EXENTO O06"
                },
                ajuste_isr_por_subsidio_monto_o06: {
                    title: "Ajuste ISR por Subsidio MONTO O06"
                },
                ajuste_isr_a_favor_gravado_o02: {
                    title: "Ajuste ISR a Favor GRAVADO O02"
                },
                ajuste_isr_a_favor_exento_o02: {
                    title: "Ajuste ISR a Favor EXENTO O02"
                },
                ajuste_isr_a_favor_monto_o02: {
                    title: "Ajuste ISR a Favor MONTO O02"
                },
                uuid: {
                    title: "UUID"
                },
                canceled: {
                    title: "Cancelado"
                },
                active: {
                    title: "active"
                }
            },
            headers_report_to_export23Quincenal: {
                empleado: {
                    title: "EMPLEADO"
                },
                nombre: {
                    title: "NOMBRE"
                },
                año: {
                    title: "AÑO"
                },
                periodo: {
                    title: "PERIODO"
                },
                vacaciones_gravado_p02: {
                    title: "Vacaciones GRAVADO P02"
                },
                vacaciones_exento_p02: {
                    title: "Vacaciones EXENTO P02"
                },
                vacaciones_monto_p02: {
                    title: "Vacaciones MONTO P02"
                },
                sueldo_gravado_p01: {
                    title: "Sueldo GRAVADO P01"
                },
                sueldo_exento_p01: {
                    title: "Sueldo EXENTO P01"
                },
                sueldo_monto_p01: {
                    title: "Sueldo MONTO P01"
                },
                fondo_de_ahorro_empresa_gravado_p16: {
                    title: "Fondo de Ahorro Empresa GRAVADO P16"
                },
                fondo_de_ahorro_empresa_exento_p16: {
                    title: "Fondo de Ahorro Empresa EXENTO P16"
                },
                fondo_de_ahorro_empresa_monto_p16: {
                    title: "Fondo de Ahorro Empresa MONTO P16"
                },
                prima_seguro_gtos_m_m_gravado_p123: {
                    title: "Prima seguro Gtos. M. M. GRAVADO P123"
                },
                prima_seguro_gtos_m_m_exento_p123: {
                    title: "Prima seguro Gtos. M. M. EXENTO P123"
                },
                prima_seguro_gtos_m_m_monto_p123: {
                    title: "Prima seguro Gtos. M. M. MONTO P123"
                },
                lentes_graduados_gravado_p90: {
                    title: "Lentes graduados GRAVADO P90"
                },
                lentes_graduados_exento_p90: {
                    title: "Lentes graduados EXENTO P90"
                },
                lentes_graduados_monto_p90: {
                    title: "Lentes graduados MONTO P90"
                },
                vales_de_despensa_gravado_p23: {
                    title: "Vales de despensa GRAVADO P23"
                },
                vales_de_despensa_exento_p23: {
                    title: "Vales de despensa EXENTO P23"
                },
                vales_de_despensa_monto_p23: {
                    title: "Vales de despensa MONTO P23"
                },
                festivo_laborado_gravado_p131: {
                    title: "Festivo laborado GRAVADO P131"
                },
                festivo_laborado_exento_p131: {
                    title: "Festivo laborado EXENTO P131"
                },
                festivo_laborado_monto_p131: {
                    title: "Festivo laborado MONTO P131"
                },
                prima_vacacional_gravado_p125: {
                    title: "Prima vacacional GRAVADO P125"
                },
                prima_vacacional_exento_p125: {
                    title: "Prima vacacional EXENTO P125"
                },
                prima_vacacional_monto_p125: {
                    title: "Prima vacacional MONTO P125"
                },
                horas_extras_triples_gravado_p15: {
                    title: "Horas Extras Triples GRAVADO P15"
                },
                horas_extras_triples_exento_p15: {
                    title: "Horas Extras Triples EXENTO P15"
                },
                horas_extras_triples_monto_p15: {
                    title: "Horas Extras Triples MONTO P15"
                },
                horas_extras_dobles_gravado_p14: {
                    title: "Horas Extras Dobles GRAVADO P14"
                },
                horas_extras_dobles_exento_p14: {
                    title: "Horas Extras Dobles EXENTO P14"
                },
                horas_extras_dobles_monto_p14: {
                    title: "Horas Extras Dobles MONTO P14"
                },
                festivo_laborado_gravado_p84: {
                    title: "Festivo laborado GRAVADO P84"
                },
                festivo_laborado_exento_p84: {
                    title: "Festivo laborado EXENTO P84"
                },
                festivo_laborado_monto_p84: {
                    title: "Festivo laborado MONTO P84"
                },
                bono_desempeño_mensual_gravado_p107: {
                    title: "Bono desempeño mensual GRAVADO P107"
                },
                bono_desempeño_mensual_exento_p107: {
                    title: "Bono desempeño mensual EXENTO P107"
                },
                bono_desempeño_mensual_monto_p107: {
                    title: "Bono desempeño mensual MONTO P107"
                },
                ayuda_por_matrimonio_gravado_p92: {
                    title: "Ayuda por matrimonio GRAVADO P92"
                },
                ayuda_por_matrimonio_exento_p92: {
                    title: "Ayuda por matrimonio EXENTO P92"
                },
                ayuda_por_matrimonio_monto_p92: {
                    title: "Ayuda por matrimonio MONTO P92"
                },
                gratificación_especial_gravado_p81: {
                    title: "Gratificación especial GRAVADO P81"
                },
                gratificación_especial_exento_p81: {
                    title: "Gratificación especial EXENTO P81"
                },
                gratificación_especial_monto_p81: {
                    title: "Gratificación especial MONTO P81"
                },
                prima_dominical_gravado_p112: {
                    title: "Prima dominical GRAVADO P112"
                },
                prima_dominical_exento_p112: {
                    title: "Prima dominical EXENTO P112"
                },
                prima_dominical_monto_p112: {
                    title: "Prima dominical MONTO P112"
                },
                retroactivo_de_sueldo_gravado_p86: {
                    title: "Retroactivo de sueldo GRAVADO P86"
                },
                retroactivo_de_sueldo_exento_p86: {
                    title: "Retroactivo de sueldo EXENTO P86"
                },
                retroactivo_de_sueldo_monto_p86: {
                    title: "Retroactivo de sueldo MONTO P86"
                },
                complemento_sdo_jornada_ord_gravado_p83: {
                    title: "COMPLEMENTO SDO. JORNADA ORD. GRAVADO P83"
                },
                complemento_sdo_jornada_ord_exento_p83: {
                    title: "COMPLEMENTO SDO. JORNADA ORD. EXENTO P83"
                },
                complemento_sdo_jornada_ord_monto_p83: {
                    title: "COMPLEMENTO SDO. JORNADA ORD. MONTO P83"
                },
                prima_dominical_gravado_p20: {
                    title: "Prima Dominical GRAVADO P20"
                },
                prima_dominical_exento_p20: {
                    title: "Prima Dominical EXENTO P20"
                },
                prima_dominical_monto_p20: {
                    title: "Prima Dominical MONTO P20"
                },
                comisiones_del_mes_gravado_p87: {
                    title: "Comisiones del mes GRAVADO P87"
                },
                comisiones_del_mes_exento_p87: {
                    title: "Comisiones del mes EXENTO P87"
                },
                comisiones_del_mes_monto_p87: {
                    title: "Comisiones del mes MONTO P87"
                },
                descanso_laborado_gravado_p135: {
                    title: "Descanso laborado GRAVADO P135"
                },
                descanso_laborado_exento_p135: {
                    title: "Descanso laborado EXENTO P135"
                },
                descanso_laborado_monto_p135: {
                    title: "Descanso laborado MONTO P135"
                },
                bono_desempeño_trimestral_gravado_p108: {
                    title: "Bono desempeño trimestral GRAVADO P108"
                },
                bono_desempeño_trimestral_exento_p108: {
                    title: "Bono desempeño trimestral EXENTO P108"
                },
                bono_desempeño_trimestral_monto_p108: {
                    title: "Bono desempeño trimestral MONTO P108"
                },
                prima_dominical_gravado_p113: {
                    title: "Prima dominical GRAVADO P113"
                },
                prima_dominical_exento_p113: {
                    title: "Prima dominical EXENTO P113"
                },
                prima_dominical_monto_p113: {
                    title: "Prima dominical MONTO P113"
                },
                apoyo_de_transporte_gravado_p121: {
                    title: "Apoyo de transporte GRAVADO P121"
                },
                apoyo_de_transporte_exento_p121: {
                    title: "Apoyo de transporte EXENTO P121"
                },
                apoyo_de_transporte_monto_p121: {
                    title: "Apoyo de transporte MONTO P121"
                },
                vales_de_despensa_gravado_p140: {
                    title: "Vales de despensa GRAVADO P140"
                },
                vales_de_despensa_exento_p140: {
                    title: "Vales de despensa EXENTO P140"
                },
                vales_de_despensa_monto_p140: {
                    title: "Vales de despensa MONTO P140"
                },
                total_percepcion: {
                    title: "TOTAL PERCEPCION"
                },
                isr_gravado_d08: {
                    title: "ISR GRAVADO D08"
                },
                isr_exento_d08: {
                    title: "ISR EXENTO D08"
                },
                isr_monto_d08: {
                    title: "ISR MONTO D08"
                },
                imss_gravado_d07: {
                    title: "IMSS GRAVADO D07"
                },
                imss_exento_d07: {
                    title: "IMSS EXENTO D07"
                },
                imss_monto_d07: {
                    title: "IMSS MONTO D07"
                },
                fondo_de_ahorro_empresa_gravado_d10: {
                    title: "Fondo de Ahorro Empresa GRAVADO D10"
                },
                fondo_de_ahorro_empresa_exento_d10: {
                    title: "Fondo de Ahorro Empresa EXENTO D10"
                },
                fondo_de_ahorro_empresa_monto_d10: {
                    title: "Fondo de Ahorro Empresa MONTO D10"
                },
                fondo_de_ahorro_gravado_d09: {
                    title: "Fondo de Ahorro GRAVADO D09"
                },
                fondo_de_ahorro_exento_d09: {
                    title: "Fondo de Ahorro EXENTO D09"
                },
                fondo_de_ahorro_monto_d09: {
                    title: "Fondo de Ahorro MONTO D09"
                },
                prima_seg_gtos_m_m_gravado_d86: {
                    title: "Prima seg. Gtos. M. M. GRAVADO D86"
                },
                prima_seg_gtos_m_m_exento_d86: {
                    title: "Prima seg. Gtos. M. M. EXENTO D86"
                },
                prima_seg_gtos_m_m_monto_d86: {
                    title: "Prima seg. Gtos. M. M. MONTO D86"
                },
                prima_seg_de_gtos_med_may_gravado_d83: {
                    title: "Prima seg. de Gtos. Med. May. GRAVADO D83"
                },
                prima_seg_de_gtos_med_may_exento_d83: {
                    title: "Prima seg. de Gtos. Med. May. EXENTO D83"
                },
                prima_seg_de_gtos_med_may_monto_d83: {
                    title: "Prima seg. de Gtos. Med. May. MONTO D83"
                },
                infonavit_gravado_d05: {
                    title: "INFONAVIT GRAVADO D05"
                },
                infonavit_exento_d05: {
                    title: "INFONAVIT EXENTO D05"
                },
                infonavit_monto_d05: {
                    title: "INFONAVIT MONTO D05"
                },
                crédito_fonacot_gravado_d15: {
                    title: "Crédito FONACOT GRAVADO D15"
                },
                crédito_fonacot_exento_d15: {
                    title: "Crédito FONACOT EXENTO D15"
                },
                crédito_fonacot_monto_d15: {
                    title: "Crédito FONACOT MONTO D15"
                },
                ayudate_gravado_d98: {
                    title: "Ayudate GRAVADO D98"
                },
                ayudate_exento_d98: {
                    title: "Ayudate EXENTO D98"
                },
                ayudate_monto_d98: {
                    title: "Ayudate MONTO D98"
                },
                seguro_automovil_gravado_d94: {
                    title: "Seguro automovil GRAVADO D94"
                },
                seguro_automovil_exento_d94: {
                    title: "Seguro automovil EXENTO D94"
                },
                seguro_automovil_monto_d94: {
                    title: "Seguro automovil MONTO D94"
                },
                lentes_gravado_d91: {
                    title: "Lentes GRAVADO D91"
                },
                lentes_exento_d91: {
                    title: "Lentes EXENTO D91"
                },
                lentes_monto_d91: {
                    title: "Lentes MONTO D91"
                },
                infonavit_gravado_d06: {
                    title: "INFONAVIT GRAVADO D06"
                },
                infonavit_exento_d06: {
                    title: "INFONAVIT EXENTO D06"
                },
                infonavit_monto_d06: {
                    title: "INFONAVIT MONTO D06"
                },
                pensión_alimenticia_gravado_d12: {
                    title: "Pensión Alimenticia GRAVADO D12"
                },
                pensión_alimenticia_exento_d12: {
                    title: "Pensión Alimenticia EXENTO D12"
                },
                pensión_alimenticia_monto_d12: {
                    title: "Pensión Alimenticia MONTO D12"
                },
                abono_de_prestamos_gravado_d88: {
                    title: "Abono de prestamos GRAVADO D88"
                },
                abono_de_prestamos_exento_d88: {
                    title: "Abono de prestamos EXENTO D88"
                },
                abono_de_prestamos_monto_d88: {
                    title: "Abono de prestamos MONTO D88"
                },
                pensión_alimenticia_gravado_d110: {
                    title: "Pensión alimenticia GRAVADO D110"
                },
                pensión_alimenticia_exento_d110: {
                    title: "Pensión alimenticia EXENTO D110"
                },
                pensión_alimenticia_monto_d110: {
                    title: "Pensión alimenticia MONTO D110"
                },
                tarjeta_de_credito_gravado_d87: {
                    title: "Tarjeta de credito GRAVADO D87"
                },
                tarjeta_de_credito_exento_d87: {
                    title: "Tarjeta de credito EXENTO D87"
                },
                tarjeta_de_credito_monto_d87: {
                    title: "Tarjeta de credito MONTO D87"
                },
                computadora_gravado_d90: {
                    title: "Computadora GRAVADO D90"
                },
                computadora_exento_d90: {
                    title: "Computadora EXENTO D90"
                },
                computadora_monto_d90: {
                    title: "Computadora MONTO D90"
                },
                infonavit_gravado_d04: {
                    title: "INFONAVIT GRAVADO D04"
                },
                infonavit_exento_d04: {
                    title: "INFONAVIT EXENTO D04"
                },
                infonavit_monto_d04: {
                    title: "INFONAVIT MONTO D04"
                },
                deducción_vales_gravado_d111: {
                    title: "Deducción Vales GRAVADO D111"
                },
                deducción_vales_exento_d111: {
                    title: "Deducción Vales EXENTO D111"
                },
                deducción_vales_monto_d111: {
                    title: "Deducción Vales MONTO D111"
                },
                comedor_gravado_d84: {
                    title: "Comedor GRAVADO D84"
                },
                comedor_exento_d84: {
                    title: "Comedor EXENTO D84"
                },
                comedor_monto_d84: {
                    title: "Comedor MONTO D84"
                },
                muestras_gravado_d93: {
                    title: "Muestras GRAVADO D93"
                },
                muestras_exento_d93: {
                    title: "Muestras EXENTO D93"
                },
                muestras_monto_d93: {
                    title: "Muestras MONTO D93"
                },
                fondo_fijo__variable_gravado_d85: {
                    title: "Fondo fijo / variable GRAVADO D85"
                },
                fondo_fijo__variable_exento_d85: {
                    title: "Fondo fijo / variable EXENTO D85"
                },
                fondo_fijo__variable_monto_d85: {
                    title: "Fondo fijo / variable MONTO D85"
                },
                tarjeta_vales__varios_gravado_d95: {
                    title: "Tarjeta vales / varios GRAVADO D95"
                },
                tarjeta_vales__varios_exento_d95: {
                    title: "Tarjeta vales / varios EXENTO D95"
                },
                tarjeta_vales__varios_monto_d95: {
                    title: "Tarjeta vales / varios MONTO D95"
                },
                intereses_ssi_caja_de_ahorro_gravado_d103: {
                    title: "Intereses s/si caja de ahorro GRAVADO D103"
                },
                intereses_ssi_caja_de_ahorro_exento_d103: {
                    title: "Intereses s/si caja de ahorro EXENTO D103"
                },
                intereses_ssi_caja_de_ahorro_monto_d103: {
                    title: "Intereses s/si caja de ahorro MONTO D103"
                },
                caja_de_ahorros_cuenta_gravado_d106: {
                    title: "Caja de ahorros cuenta GRAVADO D106"
                },
                caja_de_ahorros_cuenta_exento_d106: {
                    title: "Caja de ahorros cuenta EXENTO D106"
                },
                caja_de_ahorros_cuenta_monto_d106: {
                    title: "Caja de ahorros cuenta MONTO D106"
                },
                caja_ahorros_abono_gravado_d107: {
                    title: "Caja ahorros abono GRAVADO D107"
                },
                caja_ahorros_abono_exento_d107: {
                    title: "Caja ahorros abono EXENTO D107"
                },
                caja_ahorros_abono_monto_d107: {
                    title: "Caja ahorros abono MONTO D107"
                },
                deposito_externo_gravado_d108: {
                    title: "Deposito externo GRAVADO D108"
                },
                deposito_externo_exento_d108: {
                    title: "Deposito externo EXENTO D108"
                },
                deposito_externo_monto_d108: {
                    title: "Deposito externo MONTO D108"
                },
                anticipos__ctas_banamex_gravado_d89: {
                    title: "Anticipos / Ctas. Banamex GRAVADO D89"
                },
                anticipos__ctas_banamex_exento_d89: {
                    title: "Anticipos / Ctas. Banamex EXENTO D89"
                },
                anticipos__ctas_banamex_monto_d89: {
                    title: "Anticipos / Ctas. Banamex MONTO D89"
                },
                ayuda_voluntaria_gravado_d96: {
                    title: "Ayuda voluntaria GRAVADO D96"
                },
                ayuda_voluntaria_exento_d96: {
                    title: "Ayuda voluntaria EXENTO D96"
                },
                ayuda_voluntaria_monto_d96: {
                    title: "Ayuda voluntaria MONTO D96"
                },
                intereses_caja_de_ahorro_gravado_d104: {
                    title: "Intereses caja de ahorro GRAVADO D104"
                },
                intereses_caja_de_ahorro_exento_d104: {
                    title: "Intereses caja de ahorro EXENTO D104"
                },
                intereses_caja_de_ahorro_monto_d104: {
                    title: "Intereses caja de ahorro MONTO D104"
                },
                infonavit_ajuste_gravado_d80: {
                    title: "Infonavit ajuste GRAVADO D80"
                },
                infonavit_ajuste_exento_d80: {
                    title: "Infonavit ajuste EXENTO D80"
                },
                infonavit_ajuste_monto_d80: {
                    title: "Infonavit ajuste MONTO D80"
                },
                descuentos_dh_gravado_d97: {
                    title: "Descuentos DH GRAVADO D97"
                },
                descuentos_dh_exento_d97: {
                    title: "Descuentos DH EXENTO D97"
                },
                descuentos_dh_monto_d97: {
                    title: "Descuentos DH MONTO D97"
                },
                pensión_alimenticia_gravado_d121: {
                    title: "Pensión alimenticia GRAVADO D121"
                },
                pensión_alimenticia_exento_d121: {
                    title: "Pensión alimenticia EXENTO D121"
                },
                pensión_alimenticia_monto_d121: {
                    title: "Pensión alimenticia MONTO D121"
                },
                total_deduccion: {
                    title: "TOTAL DEDUCCION"
                },
                ayuda_por_defunción_gravado_o90: {
                    title: "Ayuda por defunción GRAVADO O90"
                },
                ayuda_por_defunción_exento_o90: {
                    title: "Ayuda por defunción EXENTO O90"
                },
                ayuda_por_defunción_monto_o90: {
                    title: "Ayuda por defunción MONTO O90"
                },
                retiro_caja_de_ahorro_gravado_o83: {
                    title: "Retiro caja de ahorro GRAVADO O83"
                },
                retiro_caja_de_ahorro_exento_o83: {
                    title: "Retiro caja de ahorro EXENTO O83"
                },
                retiro_caja_de_ahorro_monto_o83: {
                    title: "Retiro caja de ahorro MONTO O83"
                },
                prestamo_caja_de_ahorro_gravado_o82: {
                    title: "Prestamo caja de ahorro GRAVADO O82"
                },
                prestamo_caja_de_ahorro_exento_o82: {
                    title: "Prestamo caja de ahorro EXENTO O82"
                },
                prestamo_caja_de_ahorro_monto_o82: {
                    title: "Prestamo caja de ahorro MONTO O82"
                },
                deposito_externo_gravado_p124: {
                    title: "Deposito externo GRAVADO P124"
                },
                deposito_externo_exento_p124: {
                    title: "Deposito externo EXENTO P124"
                },
                deposito_externo_monto_p124: {
                    title: "Deposito externo MONTO P124"
                },
                ayuda_por_nacimiento_gravado_o88: {
                    title: "Ayuda por nacimiento GRAVADO O88"
                },
                ayuda_por_nacimiento_exento_o88: {
                    title: "Ayuda por nacimiento EXENTO O88"
                },
                ayuda_por_nacimiento_monto_o88: {
                    title: "Ayuda por nacimiento MONTO O88"
                },
                prestamo_fondo_de_ahorro_gravado_o85: {
                    title: "Prestamo fondo de ahorro GRAVADO O85"
                },
                prestamo_fondo_de_ahorro_exento_o85: {
                    title: "Prestamo fondo de ahorro EXENTO O85"
                },
                prestamo_fondo_de_ahorro_monto_o85: {
                    title: "Prestamo fondo de ahorro MONTO O85"
                },
                prestamo_sin_intereses_gravado_o84: {
                    title: "Prestamo sin intereses GRAVADO O84"
                },
                prestamo_sin_intereses_exento_o84: {
                    title: "Prestamo sin intereses EXENTO O84"
                },
                prestamo_sin_intereses_monto_o84: {
                    title: "Prestamo sin intereses MONTO O84"
                },
                ayuda_por_matrimonio_gravado_o89: {
                    title: "Ayuda por matrimonio GRAVADO O89"
                },
                ayuda_por_matrimonio_exento_o89: {
                    title: "Ayuda por matrimonio EXENTO O89"
                },
                ayuda_por_matrimonio_monto_o89: {
                    title: "Ayuda por matrimonio MONTO O89"
                },
                reparto_de_intereses_caja_de_ahorro_gravado_o91: {
                    title: "Reparto de intereses caja de ahorro GRAVADO O91"
                },
                reparto_de_intereses_caja_de_ahorro_exento_o91: {
                    title: "Reparto de intereses caja de ahorro EXENTO O91"
                },
                reparto_de_intereses_caja_de_ahorro_monto_o91: {
                    title: "Reparto de intereses caja de ahorro MONTO O91"
                },
                devolución_infonavit_gravado_o93: {
                    title: "Devolución infonavit GRAVADO O93"
                },
                devolución_infonavit_exento_o93: {
                    title: "Devolución infonavit EXENTO O93"
                },
                devolución_infonavit_monto_o93: {
                    title: "Devolución infonavit MONTO O93"
                },
                lentes_graduados_gravado_o94: {
                    title: "Lentes graduados GRAVADO O94"
                },
                lentes_graduados_exento_o94: {
                    title: "Lentes graduados EXENTO O94"
                },
                lentes_graduados_monto_o94: {
                    title: "Lentes graduados MONTO O94"
                },
                devolución_de_fonacot_gravado_o92: {
                    title: "Devolución de Fonacot GRAVADO O92"
                },
                devolución_de_fonacot_exento_o92: {
                    title: "Devolución de Fonacot EXENTO O92"
                },
                devolución_de_fonacot_monto_o92: {
                    title: "Devolución de Fonacot MONTO O92"
                },
                rembolsos_gravado_o95: {
                    title: "Rembolsos GRAVADO O95"
                },
                rembolsos_exento_o95: {
                    title: "Rembolsos EXENTO O95"
                },
                rembolsos_monto_o95: {
                    title: "Rembolsos MONTO O95"
                },

                total_otros_pagos: {
                    title: "TOTAL OTROS PAGOS"
                },
                prov_p_vacacional_gravado_r02: {
                    title: "Prov. P. Vacacional GRAVADO R02"
                },
                prov_p_vacacional_exento_r02: {
                    title: "Prov. P. Vacacional EXENTO R02"
                },
                prov_p_vacacional_monto_r02: {
                    title: "Prov. P. Vacacional MONTO R02"
                },
                prov_aguinaldo_gravado_r01: {
                    title: "Prov. Aguinaldo GRAVADO R01"
                },
                prov_aguinaldo_exento_r01: {
                    title: "Prov. Aguinaldo EXENTO R01"
                },
                prov_aguinaldo_monto_r01: {
                    title: "Prov. Aguinaldo MONTO R01"
                },
                isn_gravado_r04: {
                    title: "ISN GRAVADO R04"
                },
                isn_exento_r04: {
                    title: "ISN EXENTO R04"
                },
                isn_monto_r04: {
                    title: "ISN MONTO R04"
                },
                imss_patrón_gravado_r03: {
                    title: "IMSS Patrón GRAVADO R03"
                },
                imss_patrón_exento_r03: {
                    title: "IMSS Patrón EXENTO R03"
                },
                imss_patrón_monto_r03: {
                    title: "IMSS Patrón MONTO R03"
                },
                total_provisiones: {
                    title: "TOTAL PROVISIONES"
                },
                total_de_percepciones_gravado_i18: {
                    title: "Total de Percepciones GRAVADO I18"
                },
                total_de_percepciones_exento_i18: {
                    title: "Total de Percepciones EXENTO I18"
                },
                total_de_percepciones_monto_i18: {
                    title: "Total de Percepciones MONTO I18"
                },
                total_de_deducciones_gravado_i19: {
                    title: "Total de Deducciones GRAVADO I19"
                },
                total_de_deducciones_exento_i19: {
                    title: "Total de Deducciones EXENTO I19"
                },
                total_de_deducciones_monto_i19: {
                    title: "Total de Deducciones MONTO I19"
                },
                septimo_dia_vacacional_gravado_i88: {
                    title: "Septimo dia vacacional GRAVADO I88"
                },
                septimo_dia_vacacional_exento_i88: {
                    title: "Septimo dia vacacional EXENTO I88"
                },
                septimo_dia_vacacional_monto_i88: {
                    title: "Septimo dia vacacional MONTO I88"
                },
                riesgo_de_trabajo_patrón_gravado_i17: {
                    title: "Riesgo de Trabajo Patrón GRAVADO I17"
                },
                riesgo_de_trabajo_patrón_exento_i17: {
                    title: "Riesgo de Trabajo Patrón EXENTO I17"
                },
                riesgo_de_trabajo_patrón_monto_i17: {
                    title: "Riesgo de Trabajo Patrón MONTO I17"
                },
                retiro_patrón_gravado_i14: {
                    title: "Retiro Patrón GRAVADO I14"
                },
                retiro_patrón_exento_i14: {
                    title: "Retiro Patrón EXENTO I14"
                },
                retiro_patrón_monto_i14: {
                    title: "Retiro Patrón MONTO I14"
                },
                prestaciones_en_dinero_patrón_gravado_i10: {
                    title: "Prestaciones en Dinero Patrón GRAVADO I10"
                },
                prestaciones_en_dinero_patrón_exento_i10: {
                    title: "Prestaciones en Dinero Patrón EXENTO I10"
                },
                prestaciones_en_dinero_patrón_monto_i10: {
                    title: "Prestaciones en Dinero Patrón MONTO I10"
                },
                prestaciones_en_dinero_obrero_gravado_i04: {
                    title: "Prestaciones en Dinero Obrero GRAVADO I04"
                },
                prestaciones_en_dinero_obrero_exento_i04: {
                    title: "Prestaciones en Dinero Obrero EXENTO I04"
                },
                prestaciones_en_dinero_obrero_monto_i04: {
                    title: "Prestaciones en Dinero Obrero MONTO I04"
                },
                neto_a_pagar_gravado_i20: {
                    title: "Neto a Pagar GRAVADO I20"
                },
                neto_a_pagar_exento_i20: {
                    title: "Neto a Pagar EXENTO I20"
                },
                neto_a_pagar_monto_i20: {
                    title: "Neto a Pagar MONTO I20"
                },
                isr_causado_gravado_i01: {
                    title: "ISR Causado GRAVADO I01"
                },
                isr_causado_exento_i01: {
                    title: "ISR Causado EXENTO I01"
                },
                isr_causado_monto_i01: {
                    title: "ISR Causado MONTO I01"
                },
                invalidez_y_vida_patrón_gravado_i12: {
                    title: "Invalidez y Vida Patrón GRAVADO I12"
                },
                invalidez_y_vida_patrón_exento_i12: {
                    title: "Invalidez y Vida Patrón EXENTO I12"
                },
                invalidez_y_vida_patrón_monto_i12: {
                    title: "Invalidez y Vida Patrón MONTO I12"
                },
                invalidez_y_vida_obrero_gravado_i06: {
                    title: "Invalidez y Vida Obrero GRAVADO I06"
                },
                invalidez_y_vida_obrero_exento_i06: {
                    title: "Invalidez y Vida Obrero EXENTO I06"
                },
                invalidez_y_vida_obrero_monto_i06: {
                    title: "Invalidez y Vida Obrero MONTO I06"
                },
                infonavit_patrón_gravado_i16: {
                    title: "INFONAVIT Patrón GRAVADO I16"
                },
                infonavit_patrón_exento_i16: {
                    title: "INFONAVIT Patrón EXENTO I16"
                },
                infonavit_patrón_monto_i16: {
                    title: "INFONAVIT Patrón MONTO I16"
                },
                guarderías_patrón_gravado_i13: {
                    title: "Guarderías Patrón GRAVADO I13"
                },
                guarderías_patrón_exento_i13: {
                    title: "Guarderías Patrón EXENTO I13"
                },
                guarderías_patrón_monto_i13: {
                    title: "Guarderías Patrón MONTO I13"
                },
                gastos_médicos_patrón_gravado_i11: {
                    title: "Gastos Médicos Patrón GRAVADO I11"
                },
                gastos_médicos_patrón_exento_i11: {
                    title: "Gastos Médicos Patrón EXENTO I11"
                },
                gastos_médicos_patrón_monto_i11: {
                    title: "Gastos Médicos Patrón MONTO I11"
                },
                gastos_médicos_obrero_gravado_i05: {
                    title: "Gastos Médicos Obrero GRAVADO I05"
                },
                gastos_médicos_obrero_exento_i05: {
                    title: "Gastos Médicos Obrero EXENTO I05"
                },
                gastos_médicos_obrero_monto_i05: {
                    title: "Gastos Médicos Obrero MONTO I05"
                },
                cuota_fija_patrón_gravado_i08: {
                    title: "Cuota Fija Patrón GRAVADO I08"
                },
                cuota_fija_patrón_exento_i08: {
                    title: "Cuota Fija Patrón EXENTO I08"
                },
                cuota_fija_patrón_monto_i08: {
                    title: "Cuota Fija Patrón MONTO I08"
                },
                cuota_adicional_patrón_gravado_i09: {
                    title: "Cuota Adicional Patrón GRAVADO I09"
                },
                cuota_adicional_patrón_exento_i09: {
                    title: "Cuota Adicional Patrón EXENTO I09"
                },
                cuota_adicional_patrón_monto_i09: {
                    title: "Cuota Adicional Patrón MONTO I09"
                },
                cuota_adicional_obrero_gravado_i03: {
                    title: "Cuota Adicional Obrero GRAVADO I03"
                },
                cuota_adicional_obrero_exento_i03: {
                    title: "Cuota Adicional Obrero EXENTO I03"
                },
                cuota_adicional_obrero_monto_i03: {
                    title: "Cuota Adicional Obrero MONTO I03"
                },
                cesantía_y_vejez_patrón_gravado_i15: {
                    title: "Cesantía y Vejez Patrón GRAVADO I15"
                },
                cesantía_y_vejez_patrón_exento_i15: {
                    title: "Cesantía y Vejez Patrón EXENTO I15"
                },
                cesantía_y_vejez_patrón_monto_i15: {
                    title: "Cesantía y Vejez Patrón MONTO I15"
                },
                cesantía_y_vejez_obrero_gravado_i07: {
                    title: "Cesantía y Vejez Obrero GRAVADO I07"
                },
                cesantía_y_vejez_obrero_exento_i07: {
                    title: "Cesantía y Vejez Obrero EXENTO I07"
                },
                cesantía_y_vejez_obrero_monto_i07: {
                    title: "Cesantía y Vejez Obrero MONTO I07"
                },
                comisionista_temporal_gravado_i94: {
                    title: "COMISIONISTA TEMPORAL GRAVADO I94"
                },
                comisionista_temporal_exento_i94: {
                    title: "COMISIONISTA TEMPORAL EXENTO I94"
                },
                comisionista_temporal_monto_i94: {
                    title: "COMISIONISTA TEMPORAL MONTO I94"
                },
                comisionista_temporal_gravado_i98: {
                    title: "Comisionista temporal GRAVADO I98"
                },
                comisionista_temporal_exento_i98: {
                    title: "Comisionista temporal EXENTO I98"
                },
                comisionista_temporal_monto_i98: {
                    title: "Comisionista temporal MONTO I98"
                },
                administrativo_temporal_gravado_i90: {
                    title: "ADMINISTRATIVO TEMPORAL GRAVADO I90"
                },
                administrativo_temporal_exento_i90: {
                    title: "ADMINISTRATIVO TEMPORAL EXENTO I90"
                },
                administrativo_temporal_monto_i90: {
                    title: "ADMINISTRATIVO TEMPORAL MONTO I90"
                },
                incapacidad_gravado_d21: {
                    title: "Incapacidad GRAVADO D21"
                },
                incapacidad_exento_d21: {
                    title: "Incapacidad EXENTO D21"
                },
                incapacidad_monto_d21: {
                    title: "Incapacidad MONTO D21"
                },
                subsidio_causado_gravado_i02: {
                    title: "Subsidio Causado GRAVADO I02"
                },
                subsidio_causado_exento_i02: {
                    title: "Subsidio Causado EXENTO I02"
                },
                subsidio_causado_monto_i02: {
                    title: "Subsidio Causado MONTO I02"
                },
                sin_fa_gravado_i89: {
                    title: "Sin FA GRAVADO I89"
                },
                sin_fa_exento_i89: {
                    title: "Sin FA EXENTO I89"
                },
                sin_fa_monto_i89: {
                    title: "Sin FA MONTO I89"
                },
                falta_gravado_d01: {
                    title: "Falta GRAVADO D01"
                },
                falta_exento_d01: {
                    title: "Falta EXENTO D01"
                },
                falta_monto_d01: {
                    title: "Falta MONTO D01"
                },
                dias_festivos_gravado_i81: {
                    title: "Dias festivos GRAVADO I81"
                },
                dias_festivos_exento_i81: {
                    title: "Dias festivos EXENTO I81"
                },
                dias_festivos_monto_i81: {
                    title: "Dias festivos MONTO I81"
                },
                domingo_trabajado_gravado_i80: {
                    title: "Domingo trabajado GRAVADO I80"
                },
                domingo_trabajado_exento_i80: {
                    title: "Domingo trabajado EXENTO I80"
                },
                domingo_trabajado_monto_i80: {
                    title: "Domingo trabajado MONTO I80"
                },
                permiso_gravado_d02: {
                    title: "Permiso GRAVADO D02"
                },
                permiso_exento_d02: {
                    title: "Permiso EXENTO D02"
                },
                permiso_monto_d02: {
                    title: "Permiso MONTO D02"
                },
                acumulado_fondo_de_ahorro_gravado_i103: {
                    title: "Acumulado Fondo de ahorro GRAVADO I103"
                },
                acumulado_fondo_de_ahorro_exento_i103: {
                    title: "Acumulado Fondo de ahorro EXENTO I103"
                },
                acumulado_fondo_de_ahorro_monto_i103: {
                    title: "Acumulado Fondo de ahorro MONTO I103"
                },
                acumulado_caja_de_ahorros_cuenta_gravado_i105: {
                    title: "Acumulado Caja de ahorros cuenta GRAVADO I105"
                },
                acumulado_caja_de_ahorros_cuenta_exento_i105: {
                    title: "Acumulado Caja de ahorros cuenta EXENTO I105"
                },
                acumulado_caja_de_ahorros_cuenta_monto_i105: {
                    title: "Acumulado Caja de ahorros cuenta MONTO I105"
                },
                acumulado_caja_ahorros_abono_gravado_i107: {
                    title: "Acumulado Caja ahorros abono GRAVADO I107"
                },
                acumulado_caja_ahorros_abono_exento_i107: {
                    title: "Acumulado Caja ahorros abono EXENTO I107"
                },
                acumulado_caja_ahorros_abono_monto_i107: {
                    title: "Acumulado Caja ahorros abono MONTO I107"
                },
                total_otros_pagos_gravado_i21: {
                    title: "Total Otros Pagos GRAVADO I21"
                },
                total_otros_pagos_exento_i21: {
                    title: "Total Otros Pagos EXENTO I21"
                },
                total_otros_pagos_monto_i21: {
                    title: "Total Otros Pagos MONTO I21"
                },
                dia_descanso_gravado_i113: {
                    title: "Dia Descanso GRAVADO I113"
                },
                dia_descanso_exento_i113: {
                    title: "Dia Descanso EXENTO I113"
                },
                dia_descanso_monto_i113: {
                    title: "Dia Descanso MONTO I113"
                },
                total_informativos: {
                    title: "TOTAL INFORMATIVOS"
                },
                total_isr: {
                    title: "TOTAL ISR"
                },
                ajuste_subsidio_causado_gravado_d23: {
                    title: "Ajuste Subsidio Causado GRAVADO D23"
                },
                ajuste_subsidio_causado_exento_d23: {
                    title: "Ajuste Subsidio Causado EXENTO D23"
                },
                ajuste_subsidio_causado_monto_d23: {
                    title: "Ajuste Subsidio Causado MONTO D23"
                },
                ajuste_isr_gravado_d22: {
                    title: "Ajuste ISR GRAVADO D22"
                },
                ajuste_isr_exento_d22: {
                    title: "Ajuste ISR EXENTO D22"
                },
                ajuste_isr_monto_d22: {
                    title: "Ajuste ISR MONTO D22"
                },
                ajuste_isr_por_subsidio_gravado_o06: {
                    title: "Ajuste ISR por Subsidio GRAVADO O06"
                },
                ajuste_isr_por_subsidio_exento_o06: {
                    title: "Ajuste ISR por Subsidio EXENTO O06"
                },
                ajuste_isr_por_subsidio_monto_o06: {
                    title: "Ajuste ISR por Subsidio MONTO O06"
                },
                ajuste_isr_a_favor_gravado_o02: {
                    title: "Ajuste ISR a Favor GRAVADO O02"
                },
                ajuste_isr_a_favor_exento_o02: {
                    title: "Ajuste ISR a Favor EXENTO O02"
                },
                ajuste_isr_a_favor_monto_o02: {
                    title: "Ajuste ISR a Favor MONTO O02"
                },
                uuid: {
                    title: "UUID"
                },
                canceled: {
                    title: "Cancelado"
                },
                active: {
                    title: "active"
                }
            },
            headers_report_to_export23: {
                empleado: {
                    title: "EMPLEADO"
                },
                nombre: {
                    title: "NOMBRE"
                },
                año: {
                    title: "AÑO"
                },
                periodo: {
                    title: "PERIODO"
                },
                vales_de_despensa_gravado_p129: {
                    title: "Vales de despensa GRAVADO P129"
                },
                vales_de_despensa_exento_p129: {
                    title: "Vales de despensa EXENTO P129"
                },
                vales_de_despensa_monto_p129: {
                    title: "Vales de despensa MONTO P129"
                },
                sueldo_gravado_p01: {
                    title: "Sueldo GRAVADO P01"
                },
                sueldo_exento_p01: {
                    title: "Sueldo EXENTO P01"
                },
                sueldo_monto_p01: {
                    title: "Sueldo MONTO P01"
                },
                premio_de_asistencia_gravado_p19: {
                    title: "Premio de asistencia GRAVADO P19"
                },
                premio_de_asistencia_exento_p19: {
                    title: "Premio de asistencia EXENTO P19"
                },
                premio_de_asistencia_monto_p19: {
                    title: "Premio de asistencia MONTO P19"
                },
                fondo_de_ahorro_empresa_gravado_p126: {
                    title: "Fondo de ahorro Empresa GRAVADO P126"
                },
                fondo_de_ahorro_empresa_exento_p126: {
                    title: "Fondo de ahorro Empresa EXENTO P126"
                },
                fondo_de_ahorro_empresa_monto_p126: {
                    title: "Fondo de ahorro Empresa MONTO P126"
                },
                vales_de_despensa_gravado_p130: {
                    title: "Vales de despensa GRAVADO P130"
                },
                vales_de_despensa_exento_p130: {
                    title: "Vales de despensa EXENTO P130"
                },
                vales_de_despensa_monto_p130: {
                    title: "Vales de despensa MONTO P130"
                },
                compensación_por_facilitador_gravado_p109: {
                    title: "Compensación por facilitador GRAVADO P109"
                },
                compensación_por_facilitador_exento_p109: {
                    title: "Compensación por facilitador EXENTO P109"
                },
                compensación_por_facilitador_monto_p109: {
                    title: "Compensación por facilitador MONTO P109"
                },
                horas_extras_triples_gravado_p15: {
                    title: "Horas Extras Triples GRAVADO P15"
                },
                horas_extras_triples_exento_p15: {
                    title: "Horas Extras Triples EXENTO P15"
                },
                horas_extras_triples_monto_p15: {
                    title: "Horas Extras Triples MONTO P15"
                },
                horas_extras_dobles_gravado_p14: {
                    title: "Horas Extras Dobles GRAVADO P14"
                },
                horas_extras_dobles_exento_p14: {
                    title: "Horas Extras Dobles EXENTO P14"
                },
                horas_extras_dobles_monto_p14: {
                    title: "Horas Extras Dobles MONTO P14"
                },
                festivo_laborado_gravado_p84: {
                    title: "Festivo laborado GRAVADO P84"
                },
                festivo_laborado_exento_p84: {
                    title: "Festivo laborado EXENTO P84"
                },
                festivo_laborado_monto_p84: {
                    title: "Festivo laborado MONTO P84"
                },
                retroactivo_de_sueldo_gravado_p86: {
                    title: "Retroactivo de sueldo GRAVADO P86"
                },
                retroactivo_de_sueldo_exento_p86: {
                    title: "Retroactivo de sueldo EXENTO P86"
                },
                retroactivo_de_sueldo_monto_p86: {
                    title: "Retroactivo de sueldo MONTO P86"
                },
                vacaciones_gravado_p02: {
                    title: "Vacaciones GRAVADO P02"
                },
                vacaciones_exento_p02: {
                    title: "Vacaciones EXENTO P02"
                },
                vacaciones_monto_p02: {
                    title: "Vacaciones MONTO P02"
                },
                prima_vacacional_gravado_p125: {
                    title: "Prima vacacional GRAVADO P125"
                },
                prima_vacacional_exento_p125: {
                    title: "Prima vacacional EXENTO P125"
                },
                prima_vacacional_monto_p125: {
                    title: "Prima vacacional MONTO P125"
                },
                gratificación_3er_turno_gravado_p89: {
                    title: "Gratificación 3er turno GRAVADO P89"
                },
                gratificación_3er_turno_exento_p89: {
                    title: "Gratificación 3er turno EXENTO P89"
                },
                gratificación_3er_turno_monto_p89: {
                    title: "Gratificación 3er turno MONTO P89"
                },
                complemento_sdo_jornada_ord_gravado_p83: {
                    title: "COMPLEMENTO SDO. JORNADA ORD. GRAVADO P83"
                },
                complemento_sdo_jornada_ord_exento_p83: {
                    title: "COMPLEMENTO SDO. JORNADA ORD. EXENTO P83"
                },
                complemento_sdo_jornada_ord_monto_p83: {
                    title: "COMPLEMENTO SDO. JORNADA ORD. MONTO P83"
                },
                gratificación_especial_gravado_p81: {
                    title: "Gratificación especial GRAVADO P81"
                },
                gratificación_especial_exento_p81: {
                    title: "Gratificación especial EXENTO P81"
                },
                gratificación_especial_monto_p81: {
                    title: "Gratificación especial MONTO P81"
                },
                prima_dominical_gravado_p20: {
                    title: "Prima Dominical GRAVADO P20"
                },
                prima_dominical_exento_p20: {
                    title: "Prima Dominical EXENTO P20"
                },
                prima_dominical_monto_p20: {
                    title: "Prima Dominical MONTO P20"
                },
                bono_por_ingreso_gravado_p114: {
                    title: "Bono por ingreso GRAVADO P114"
                },
                bono_por_ingreso_exento_p114: {
                    title: "Bono por ingreso EXENTO P114"
                },
                bono_por_ingreso_monto_p114: {
                    title: "Bono por ingreso MONTO P114"
                },
                bono_turno_mixto_gravado_p118: {
                    title: "Bono turno mixto GRAVADO P118"
                },
                bono_turno_mixto_exento_p118: {
                    title: "Bono turno mixto EXENTO P118"
                },
                bono_turno_mixto_monto_p118: {
                    title: "Bono turno mixto MONTO P118"
                },
                devolución_de_fonacot_gravado_p117: {
                    title: "Devolución de Fonacot GRAVADO P117"
                },
                devolución_de_fonacot_exento_p117: {
                    title: "Devolución de Fonacot EXENTO P117"
                },
                devolución_de_fonacot_monto_p117: {
                    title: "Devolución de Fonacot MONTO P117"
                },
                prima_seguro_gtos_m_m_gravado_p123: {
                    title: "Prima seguro Gtos. M. M. GRAVADO P123"
                },
                prima_seguro_gtos_m_m_exento_p123: {
                    title: "Prima seguro Gtos. M. M. EXENTO P123"
                },
                prima_seguro_gtos_m_m_monto_p123: {
                    title: "Prima seguro Gtos. M. M. MONTO P123"
                },
                ayuda_por_defunción_gravado_p93: {
                    title: "Ayuda por defunción GRAVADO P93"
                },
                ayuda_por_defunción_exento_p93: {
                    title: "Ayuda por defunción EXENTO P93"
                },
                ayuda_por_defunción_monto_p93: {
                    title: "Ayuda por defunción MONTO P93"
                },
                apoyo_de_transporte_gravado_p121: {
                    title: "Apoyo de transporte GRAVADO P121"
                },
                apoyo_de_transporte_exento_p121: {
                    title: "Apoyo de transporte EXENTO P121"
                },
                apoyo_de_transporte_monto_p121: {
                    title: "Apoyo de transporte MONTO P121"
                },
                festivo_laborado_gravado_p85: {
                    title: "Festivo laborado GRAVADO P85"
                },
                festivo_laborado_exento_p85: {
                    title: "Festivo laborado EXENTO P85"
                },
                festivo_laborado_monto_p85: {
                    title: "Festivo laborado MONTO P85"
                },
                deposito_externo_gravado_p124: {
                    title: "Deposito externo GRAVADO P124"
                },
                deposito_externo_exento_p124: {
                    title: "Deposito externo EXENTO P124"
                },
                deposito_externo_monto_p124: {
                    title: "Deposito externo MONTO P124"
                },
                bono_por_planta_gravado_p115: {
                    title: "Bono por planta GRAVADO P115"
                },
                bono_por_planta_exento_p115: {
                    title: "Bono por planta EXENTO P115"
                },
                bono_por_planta_monto_p115: {
                    title: "Bono por planta MONTO P115"
                },
                lentes_graduados_gravado_p90: {
                    title: "Lentes graduados GRAVADO P90"
                },
                lentes_graduados_exento_p90: {
                    title: "Lentes graduados EXENTO P90"
                },
                lentes_graduados_monto_p90: {
                    title: "Lentes graduados MONTO P90"
                },
                festivo_laborado_gravado_p131: {
                    title: "Festivo laborado GRAVADO P131"
                },
                festivo_laborado_exento_p131: {
                    title: "Festivo laborado EXENTO P131"
                },
                festivo_laborado_monto_p131: {
                    title: "Festivo laborado MONTO P131"
                },
                bono_de_productividad_gravado_p110: {
                    title: "Bono de productividad GRAVADO P110"
                },
                bono_de_productividad_exento_p110: {
                    title: "Bono de productividad EXENTO P110"
                },
                bono_de_productividad_monto_p110: {
                    title: "Bono de productividad MONTO P110"
                },
                ayuda_por_matrimonio_gravado_p92: {
                    title: "Ayuda por matrimonio GRAVADO P92"
                },
                ayuda_por_matrimonio_exento_p92: {
                    title: "Ayuda por matrimonio EXENTO P92"
                },
                ayuda_por_matrimonio_monto_p92: {
                    title: "Ayuda por matrimonio MONTO P92"
                },
                bono_desempeño_mensual_gravado_p107: {
                    title: "Bono desempeño mensual GRAVADO P107"
                },
                bono_desempeño_mensual_exento_p107: {
                    title: "Bono desempeño mensual EXENTO P107"
                },
                bono_desempeño_mensual_monto_p107: {
                    title: "Bono desempeño mensual MONTO P107"
                },
                ayuda_por_nacimiento_gravado_p91: {
                    title: "Ayuda por nacimiento GRAVADO P91"
                },
                ayuda_por_nacimiento_exento_p91: {
                    title: "Ayuda por nacimiento EXENTO P91"
                },
                ayuda_por_nacimiento_monto_p91: {
                    title: "Ayuda por nacimiento MONTO P91"
                },
                total_percepcion: {
                    title: "TOTAL PERCEPCION"
                },
                isr_gravado_d08: {
                    title: "ISR GRAVADO D08"
                },
                isr_exento_d08: {
                    title: "ISR EXENTO D08"
                },
                isr_monto_d08: {
                    title: "ISR MONTO D08"
                },
                infonavit_gravado_d05: {
                    title: "INFONAVIT GRAVADO D05"
                },
                infonavit_exento_d05: {
                    title: "INFONAVIT EXENTO D05"
                },
                infonavit_monto_d05: {
                    title: "INFONAVIT MONTO D05"
                },
                imss_gravado_d07: {
                    title: "IMSS GRAVADO D07"
                },
                imss_exento_d07: {
                    title: "IMSS EXENTO D07"
                },
                imss_monto_d07: {
                    title: "IMSS MONTO D07"
                },
                fondo_de_ahorro_empresa_gravado_d10: {
                    title: "Fondo de Ahorro Empresa GRAVADO D10"
                },
                fondo_de_ahorro_empresa_exento_d10: {
                    title: "Fondo de Ahorro Empresa EXENTO D10"
                },
                fondo_de_ahorro_empresa_monto_d10: {
                    title: "Fondo de Ahorro Empresa MONTO D10"
                },
                fondo_de_ahorro_gravado_d09: {
                    title: "Fondo de Ahorro GRAVADO D09"
                },
                fondo_de_ahorro_exento_d09: {
                    title: "Fondo de Ahorro EXENTO D09"
                },
                fondo_de_ahorro_monto_d09: {
                    title: "Fondo de Ahorro MONTO D09"
                },
                cuota_sindical_ordinaria_gravado_d81: {
                    title: "Cuota sindical ordinaria GRAVADO D81"
                },
                cuota_sindical_ordinaria_exento_d81: {
                    title: "Cuota sindical ordinaria EXENTO D81"
                },
                cuota_sindical_ordinaria_monto_d81: {
                    title: "Cuota sindical ordinaria MONTO D81"
                },
                comedor_gravado_d84: {
                    title: "Comedor GRAVADO D84"
                },
                comedor_exento_d84: {
                    title: "Comedor EXENTO D84"
                },
                comedor_monto_d84: {
                    title: "Comedor MONTO D84"
                },
                caja_de_ahorros_cuenta_gravado_d106: {
                    title: "Caja de ahorros cuenta GRAVADO D106"
                },
                caja_de_ahorros_cuenta_exento_d106: {
                    title: "Caja de ahorros cuenta EXENTO D106"
                },
                caja_de_ahorros_cuenta_monto_d106: {
                    title: "Caja de ahorros cuenta MONTO D106"
                },
                intereses_caja_de_ahorro_gravado_d104: {
                    title: "Intereses caja de ahorro GRAVADO D104"
                },
                intereses_caja_de_ahorro_exento_d104: {
                    title: "Intereses caja de ahorro EXENTO D104"
                },
                intereses_caja_de_ahorro_monto_d104: {
                    title: "Intereses caja de ahorro MONTO D104"
                },
                cuota_sindical_extraordinaria_gravado_d82: {
                    title: "Cuota sindical extraordinaria GRAVADO D82"
                },
                cuota_sindical_extraordinaria_exento_d82: {
                    title: "Cuota sindical extraordinaria EXENTO D82"
                },
                cuota_sindical_extraordinaria_monto_d82: {
                    title: "Cuota sindical extraordinaria MONTO D82"
                },
                caja_ahorros_abono_gravado_d107: {
                    title: "Caja ahorros abono GRAVADO D107"
                },
                caja_ahorros_abono_exento_d107: {
                    title: "Caja ahorros abono EXENTO D107"
                },
                caja_ahorros_abono_monto_d107: {
                    title: "Caja ahorros abono MONTO D107"
                },
                crédito_fonacot_gravado_d15: {
                    title: "Crédito FONACOT GRAVADO D15"
                },
                crédito_fonacot_exento_d15: {
                    title: "Crédito FONACOT EXENTO D15"
                },
                crédito_fonacot_monto_d15: {
                    title: "Crédito FONACOT MONTO D15"
                },
                ayudate_gravado_d98: {
                    title: "Ayudate GRAVADO D98"
                },
                ayudate_exento_d98: {
                    title: "Ayudate EXENTO D98"
                },
                ayudate_monto_d98: {
                    title: "Ayudate MONTO D98"
                },
                infonavit_gravado_d06: {
                    title: "INFONAVIT GRAVADO D06"
                },
                infonavit_exento_d06: {
                    title: "INFONAVIT EXENTO D06"
                },
                infonavit_monto_d06: {
                    title: "INFONAVIT MONTO D06"
                },
                anticipos__ctas_banamex_gravado_d89: {
                    title: "Anticipos / Ctas. Banamex GRAVADO D89"
                },
                anticipos__ctas_banamex_exento_d89: {
                    title: "Anticipos / Ctas. Banamex EXENTO D89"
                },
                anticipos__ctas_banamex_monto_d89: {
                    title: "Anticipos / Ctas. Banamex MONTO D89"
                },
                lentes_gravado_d91: {
                    title: "Lentes GRAVADO D91"
                },
                lentes_exento_d91: {
                    title: "Lentes EXENTO D91"
                },
                lentes_monto_d91: {
                    title: "Lentes MONTO D91"
                },
                tarjeta_vales__varios_gravado_d95: {
                    title: "Tarjeta vales / varios GRAVADO D95"
                },
                tarjeta_vales__varios_exento_d95: {
                    title: "Tarjeta vales / varios EXENTO D95"
                },
                tarjeta_vales__varios_monto_d95: {
                    title: "Tarjeta vales / varios MONTO D95"
                },
                abono_de_prestamos_gravado_d88: {
                    title: "Abono de prestamos GRAVADO D88"
                },
                abono_de_prestamos_exento_d88: {
                    title: "Abono de prestamos EXENTO D88"
                },
                abono_de_prestamos_monto_d88: {
                    title: "Abono de prestamos MONTO D88"
                },
                prima_seg_gtos_m_m_gravado_d86: {
                    title: "Prima seg. Gtos. M. M. GRAVADO D86"
                },
                prima_seg_gtos_m_m_exento_d86: {
                    title: "Prima seg. Gtos. M. M. EXENTO D86"
                },
                prima_seg_gtos_m_m_monto_d86: {
                    title: "Prima seg. Gtos. M. M. MONTO D86"
                },
                prima_seg_de_gtos_med_may_gravado_d83: {
                    title: "Prima seg. de Gtos. Med. May. GRAVADO D83"
                },
                prima_seg_de_gtos_med_may_exento_d83: {
                    title: "Prima seg. de Gtos. Med. May. EXENTO D83"
                },
                prima_seg_de_gtos_med_may_monto_d83: {
                    title: "Prima seg. de Gtos. Med. May. MONTO D83"
                },
                deducción_vales_gravado_d112: {
                    title: "Deducción Vales GRAVADO D112"
                },
                deducción_vales_exento_d112: {
                    title: "Deducción Vales EXENTO D112"
                },
                deducción_vales_monto_d112: {
                    title: "Deducción Vales MONTO D112"
                },
                intereses_ssi_caja_de_ahorro_gravado_d103: {
                    title: "Intereses s/si caja de ahorro GRAVADO D103"
                },
                intereses_ssi_caja_de_ahorro_exento_d103: {
                    title: "Intereses s/si caja de ahorro EXENTO D103"
                },
                intereses_ssi_caja_de_ahorro_monto_d103: {
                    title: "Intereses s/si caja de ahorro MONTO D103"
                },
                deducción_vales_gravado_d111: {
                    title: "Deducción Vales GRAVADO D111"
                },
                deducción_vales_exento_d111: {
                    title: "Deducción Vales EXENTO D111"
                },
                deducción_vales_monto_d111: {
                    title: "Deducción Vales MONTO D111"
                },
                deposito_externo_gravado_d108: {
                    title: "Deposito externo GRAVADO D108"
                },
                deposito_externo_exento_d108: {
                    title: "Deposito externo EXENTO D108"
                },
                deposito_externo_monto_d108: {
                    title: "Deposito externo MONTO D108"
                },
                infonavit_ajuste_gravado_d80: {
                    title: "Infonavit ajuste GRAVADO D80"
                },
                infonavit_ajuste_exento_d80: {
                    title: "Infonavit ajuste EXENTO D80"
                },
                infonavit_ajuste_monto_d80: {
                    title: "Infonavit ajuste MONTO D80"
                },
                fondo_de_ahorro_empresa_gravado_d114: {
                    title: "Fondo de Ahorro Empresa GRAVADO D114"
                },
                fondo_de_ahorro_empresa_exento_d114: {
                    title: "Fondo de Ahorro Empresa EXENTO D114"
                },
                fondo_de_ahorro_empresa_monto_d114: {
                    title: "Fondo de Ahorro Empresa MONTO D114"
                },
                fondo_de_ahorro_gravado_d113: {
                    title: "Fondo de Ahorro GRAVADO D113"
                },
                fondo_de_ahorro_exento_d113: {
                    title: "Fondo de Ahorro EXENTO D113"
                },
                fondo_de_ahorro_monto_d113: {
                    title: "Fondo de Ahorro MONTO D113"
                },

                tarjeta_de_credito_gravado_d87: {
                    title: "Tarjeta de credito GRAVADO D87"
                },
                tarjeta_de_credito_exento_d87: {
                    title: "Tarjeta de credito EXENTO D87"
                },
                tarjeta_de_credito_monto_d87: {
                    title: "Tarjeta de credito MONTO D87"
                },
                seguro_automovil_gravado_d94: {
                    title: "Seguro automovil GRAVADO D94"
                },
                seguro_automovil_exento_d94: {
                    title: "Seguro automovil EXENTO D94"
                },
                seguro_automovil_monto_d94: {
                    title: "Seguro automovil MONTO D94"
                },
                total_deduccion: {
                    title: "TOTAL DEDUCCION"
                },
                retiro_caja_de_ahorro_gravado_o83: {
                    title: "Retiro caja de ahorro GRAVADO O83"
                },
                retiro_caja_de_ahorro_exento_o83: {
                    title: "Retiro caja de ahorro EXENTO O83"
                },
                retiro_caja_de_ahorro_monto_o83: {
                    title: "Retiro caja de ahorro MONTO O83"
                },
                prestamo_caja_de_ahorro_gravado_o82: {
                    title: "Prestamo caja de ahorro GRAVADO O82"
                },
                prestamo_caja_de_ahorro_exento_o82: {
                    title: "Prestamo caja de ahorro EXENTO O82"
                },
                prestamo_caja_de_ahorro_monto_o82: {
                    title: "Prestamo caja de ahorro MONTO O82"
                },
                prestamo_sin_intereses_gravado_o84: {
                    title: "Prestamo sin intereses GRAVADO O84"
                },
                prestamo_sin_intereses_exento_o84: {
                    title: "Prestamo sin intereses EXENTO O84"
                },
                prestamo_sin_intereses_monto_o84: {
                    title: "Prestamo sin intereses MONTO O84"
                },

                total_otros_pagos: {
                    title: "TOTAL OTROS PAGOS"
                },
                prov_p_vacacional_gravado_r02: {
                    title: "Prov. P. Vacacional GRAVADO R02"
                },
                prov_p_vacacional_exento_r02: {
                    title: "Prov. P. Vacacional EXENTO R02"
                },
                prov_p_vacacional_monto_r02: {
                    title: "Prov. P. Vacacional MONTO R02"
                },
                prov_aguinaldo_gravado_r01: {
                    title: "Prov. Aguinaldo GRAVADO R01"
                },
                prov_aguinaldo_exento_r01: {
                    title: "Prov. Aguinaldo EXENTO R01"
                },
                prov_aguinaldo_monto_r01: {
                    title: "Prov. Aguinaldo MONTO R01"
                },
                isn_gravado_r04: {
                    title: "ISN GRAVADO R04"
                },
                isn_exento_r04: {
                    title: "ISN EXENTO R04"
                },
                isn_monto_r04: {
                    title: "ISN MONTO R04"
                },
                imss_patrón_gravado_r03: {
                    title: "IMSS Patrón GRAVADO R03"
                },
                imss_patrón_exento_r03: {
                    title: "IMSS Patrón EXENTO R03"
                },
                imss_patrón_monto_r03: {
                    title: "IMSS Patrón MONTO R03"
                },
                total_provisiones: {
                    title: "TOTAL PROVISIONES"
                },
                total_de_percepciones_gravado_i18: {
                    title: "Total de Percepciones GRAVADO I18"
                },
                total_de_percepciones_exento_i18: {
                    title: "Total de Percepciones EXENTO I18"
                },
                total_de_percepciones_monto_i18: {
                    title: "Total de Percepciones MONTO I18"
                },
                total_de_deducciones_gravado_i19: {
                    title: "Total de Deducciones GRAVADO I19"
                },
                total_de_deducciones_exento_i19: {
                    title: "Total de Deducciones EXENTO I19"
                },
                total_de_deducciones_monto_i19: {
                    title: "Total de Deducciones MONTO I19"
                },
                riesgo_de_trabajo_patrón_gravado_i17: {
                    title: "Riesgo de Trabajo Patrón GRAVADO I17"
                },
                riesgo_de_trabajo_patrón_exento_i17: {
                    title: "Riesgo de Trabajo Patrón EXENTO I17"
                },
                riesgo_de_trabajo_patrón_monto_i17: {
                    title: "Riesgo de Trabajo Patrón MONTO I17"
                },
                retiro_patrón_gravado_i14: {
                    title: "Retiro Patrón GRAVADO I14"
                },
                retiro_patrón_exento_i14: {
                    title: "Retiro Patrón EXENTO I14"
                },
                retiro_patrón_monto_i14: {
                    title: "Retiro Patrón MONTO I14"
                },
                prestaciones_en_dinero_patrón_gravado_i10: {
                    title: "Prestaciones en Dinero Patrón GRAVADO I10"
                },
                prestaciones_en_dinero_patrón_exento_i10: {
                    title: "Prestaciones en Dinero Patrón EXENTO I10"
                },
                prestaciones_en_dinero_patrón_monto_i10: {
                    title: "Prestaciones en Dinero Patrón MONTO I10"
                },
                prestaciones_en_dinero_obrero_gravado_i04: {
                    title: "Prestaciones en Dinero Obrero GRAVADO I04"
                },
                prestaciones_en_dinero_obrero_exento_i04: {
                    title: "Prestaciones en Dinero Obrero EXENTO I04"
                },
                prestaciones_en_dinero_obrero_monto_i04: {
                    title: "Prestaciones en Dinero Obrero MONTO I04"
                },
                neto_a_pagar_gravado_i20: {
                    title: "Neto a Pagar GRAVADO I20"
                },
                neto_a_pagar_exento_i20: {
                    title: "Neto a Pagar EXENTO I20"
                },
                neto_a_pagar_monto_i20: {
                    title: "Neto a Pagar MONTO I20"
                },
                isr_causado_gravado_i01: {
                    title: "ISR Causado GRAVADO I01"
                },
                isr_causado_exento_i01: {
                    title: "ISR Causado EXENTO I01"
                },
                isr_causado_monto_i01: {
                    title: "ISR Causado MONTO I01"
                },
                invalidez_y_vida_patrón_gravado_i12: {
                    title: "Invalidez y Vida Patrón GRAVADO I12"
                },
                invalidez_y_vida_patrón_exento_i12: {
                    title: "Invalidez y Vida Patrón EXENTO I12"
                },
                invalidez_y_vida_patrón_monto_i12: {
                    title: "Invalidez y Vida Patrón MONTO I12"
                },
                invalidez_y_vida_obrero_gravado_i06: {
                    title: "Invalidez y Vida Obrero GRAVADO I06"
                },
                invalidez_y_vida_obrero_exento_i06: {
                    title: "Invalidez y Vida Obrero EXENTO I06"
                },
                invalidez_y_vida_obrero_monto_i06: {
                    title: "Invalidez y Vida Obrero MONTO I06"
                },
                infonavit_patrón_gravado_i16: {
                    title: "INFONAVIT Patrón GRAVADO I16"
                },
                infonavit_patrón_exento_i16: {
                    title: "INFONAVIT Patrón EXENTO I16"
                },
                infonavit_patrón_monto_i16: {
                    title: "INFONAVIT Patrón MONTO I16"
                },
                guarderías_patrón_gravado_i13: {
                    title: "Guarderías Patrón GRAVADO I13"
                },
                guarderías_patrón_exento_i13: {
                    title: "Guarderías Patrón EXENTO I13"
                },
                guarderías_patrón_monto_i13: {
                    title: "Guarderías Patrón MONTO I13"
                },
                gastos_médicos_patrón_gravado_i11: {
                    title: "Gastos Médicos Patrón GRAVADO I11"
                },
                gastos_médicos_patrón_exento_i11: {
                    title: "Gastos Médicos Patrón EXENTO I11"
                },
                gastos_médicos_patrón_monto_i11: {
                    title: "Gastos Médicos Patrón MONTO I11"
                },
                gastos_médicos_obrero_gravado_i05: {
                    title: "Gastos Médicos Obrero GRAVADO I05"
                },
                gastos_médicos_obrero_exento_i05: {
                    title: "Gastos Médicos Obrero EXENTO I05"
                },
                gastos_médicos_obrero_monto_i05: {
                    title: "Gastos Médicos Obrero MONTO I05"
                },
                cuota_fija_patrón_gravado_i08: {
                    title: "Cuota Fija Patrón GRAVADO I08"
                },
                cuota_fija_patrón_exento_i08: {
                    title: "Cuota Fija Patrón EXENTO I08"
                },
                cuota_fija_patrón_monto_i08: {
                    title: "Cuota Fija Patrón MONTO I08"
                },
                cuota_adicional_patrón_gravado_i09: {
                    title: "Cuota Adicional Patrón GRAVADO I09"
                },
                cuota_adicional_patrón_exento_i09: {
                    title: "Cuota Adicional Patrón EXENTO I09"
                },
                cuota_adicional_patrón_monto_i09: {
                    title: "Cuota Adicional Patrón MONTO I09"
                },
                cuota_adicional_obrero_gravado_i03: {
                    title: "Cuota Adicional Obrero GRAVADO I03"
                },
                cuota_adicional_obrero_exento_i03: {
                    title: "Cuota Adicional Obrero EXENTO I03"
                },
                cuota_adicional_obrero_monto_i03: {
                    title: "Cuota Adicional Obrero MONTO I03"
                },
                cesantía_y_vejez_patrón_gravado_i15: {
                    title: "Cesantía y Vejez Patrón GRAVADO I15"
                },
                cesantía_y_vejez_patrón_exento_i15: {
                    title: "Cesantía y Vejez Patrón EXENTO I15"
                },
                cesantía_y_vejez_patrón_monto_i15: {
                    title: "Cesantía y Vejez Patrón MONTO I15"
                },
                cesantía_y_vejez_obrero_gravado_i07: {
                    title: "Cesantía y Vejez Obrero GRAVADO I07"
                },
                cesantía_y_vejez_obrero_exento_i07: {
                    title: "Cesantía y Vejez Obrero EXENTO I07"
                },
                cesantía_y_vejez_obrero_monto_i07: {
                    title: "Cesantía y Vejez Obrero MONTO I07"
                },
                dias_festivos_gravado_i81: {
                    title: "Dias festivos GRAVADO I81"
                },
                dias_festivos_exento_i81: {
                    title: "Dias festivos EXENTO I81"
                },
                dias_festivos_monto_i81: {
                    title: "Dias festivos MONTO I81"
                },
                septimo_dia_vacacional_gravado_i88: {
                    title: "Septimo dia vacacional GRAVADO I88"
                },
                septimo_dia_vacacional_exento_i88: {
                    title: "Septimo dia vacacional EXENTO I88"
                },
                septimo_dia_vacacional_monto_i88: {
                    title: "Septimo dia vacacional MONTO I88"
                },
                subsidio_causado_gravado_i02: {
                    title: "Subsidio Causado GRAVADO I02"
                },
                subsidio_causado_exento_i02: {
                    title: "Subsidio Causado EXENTO I02"
                },
                subsidio_causado_monto_i02: {
                    title: "Subsidio Causado MONTO I02"
                },
                falta_gravado_d01: {
                    title: "Falta GRAVADO D01"
                },
                falta_exento_d01: {
                    title: "Falta EXENTO D01"
                },
                falta_monto_d01: {
                    title: "Falta MONTO D01"
                },
                permiso_gravado_d02: {
                    title: "Permiso GRAVADO D02"
                },
                permiso_exento_d02: {
                    title: "Permiso EXENTO D02"
                },
                permiso_monto_d02: {
                    title: "Permiso MONTO D02"
                },
                domingo_trabajado_gravado_i80: {
                    title: "Domingo trabajado GRAVADO I80"
                },
                domingo_trabajado_exento_i80: {
                    title: "Domingo trabajado EXENTO I80"
                },
                domingo_trabajado_monto_i80: {
                    title: "Domingo trabajado MONTO I80"
                },
                incapacidad_gravado_d21: {
                    title: "Incapacidad GRAVADO D21"
                },
                incapacidad_exento_d21: {
                    title: "Incapacidad EXENTO D21"
                },
                incapacidad_monto_d21: {
                    title: "Incapacidad MONTO D21"
                },
                total_otros_pagos_gravado_i21: {
                    title: "Total Otros Pagos GRAVADO I21"
                },
                total_otros_pagos_exento_i21: {
                    title: "Total Otros Pagos EXENTO I21"
                },
                total_otros_pagos_monto_i21: {
                    title: "Total Otros Pagos MONTO I21"
                },
                administrativo_temporal_gravado_i90: {
                    title: "ADMINISTRATIVO TEMPORAL GRAVADO I90"
                },
                administrativo_temporal_exento_i90: {
                    title: "ADMINISTRATIVO TEMPORAL EXENTO I90"
                },
                administrativo_temporal_monto_i90: {
                    title: "ADMINISTRATIVO TEMPORAL MONTO I90"
                },
                manufactura_temporal_gravado_i93: {
                    title: "MANUFACTURA TEMPORAL GRAVADO I93"
                },
                manufactura_temporal_exento_i93: {
                    title: "MANUFACTURA TEMPORAL EXENTO I93"
                },
                manufactura_temporal_monto_i93: {
                    title: "MANUFACTURA TEMPORAL MONTO I93"
                },
                manufactura_temporal_gravado_i97: {
                    title: "Manufactura temporal GRAVADO I97"
                },
                manufactura_temporal_exento_i97: {
                    title: "Manufactura temporal EXENTO I97"
                },
                manufactura_temporal_monto_i97: {
                    title: "Manufactura temporal MONTO I97"
                },
                almacenista_temporal_gravado_i92: {
                    title: "ALMACENISTA TEMPORAL GRAVADO I92"
                },
                almacenista_temporal_exento_i92: {
                    title: "ALMACENISTA TEMPORAL EXENTO I92"
                },
                almacenista_temporal_monto_i92: {
                    title: "ALMACENISTA TEMPORAL MONTO I92"
                },
                almacenista_temporal_gravado_i96: {
                    title: "Almacenista temporal GRAVADO I96"
                },
                almacenista_temporal_exento_i96: {
                    title: "Almacenista temporal EXENTO I96"
                },
                almacenista_temporal_monto_i96: {
                    title: "Almacenista temporal MONTO I96"
                },
                operativo_temporal_gravado_i91: {
                    title: "OPERATIVO TEMPORAL GRAVADO I91"
                },
                operativo_temporal_exento_i91: {
                    title: "OPERATIVO TEMPORAL EXENTO I91"
                },
                operativo_temporal_monto_i91: {
                    title: "OPERATIVO TEMPORAL MONTO I91"
                },
                acumulado_fondo_de_ahorro_gravado_i103: {
                    title: "Acumulado Fondo de ahorro GRAVADO I103"
                },
                acumulado_fondo_de_ahorro_exento_i103: {
                    title: "Acumulado Fondo de ahorro EXENTO I103"
                },
                acumulado_fondo_de_ahorro_monto_i103: {
                    title: "Acumulado Fondo de ahorro MONTO I103"
                },
                acumulado_caja_de_ahorros_cuenta_gravado_i105: {
                    title: "Acumulado Caja de ahorros cuenta GRAVADO I105"
                },
                acumulado_caja_de_ahorros_cuenta_exento_i105: {
                    title: "Acumulado Caja de ahorros cuenta EXENTO I105"
                },
                acumulado_caja_de_ahorros_cuenta_monto_i105: {
                    title: "Acumulado Caja de ahorros cuenta MONTO I105"
                },
                acumulado_caja_ahorros_abono_gravado_i107: {
                    title: "Acumulado Caja ahorros abono GRAVADO I107"
                },
                acumulado_caja_ahorros_abono_exento_i107: {
                    title: "Acumulado Caja ahorros abono EXENTO I107"
                },
                acumulado_caja_ahorros_abono_monto_i107: {
                    title: "Acumulado Caja ahorros abono MONTO I107"
                },
                total_informativos: {
                    title: "TOTAL INFORMATIVOS"
                },
                total_isr: {
                    title: "TOTAL ISR"
                },
                ajuste_subsidio_causado_gravado_d23: {
                    title: "Ajuste Subsidio Causado GRAVADO D23"
                },
                ajuste_subsidio_causado_exento_d23: {
                    title: "Ajuste Subsidio Causado EXENTO D23"
                },
                ajuste_subsidio_causado_monto_d23: {
                    title: "Ajuste Subsidio Causado MONTO D23"
                },
                ajuste_isr_gravado_d22: {
                    title: "Ajuste ISR GRAVADO D22"
                },
                ajuste_isr_exento_d22: {
                    title: "Ajuste ISR EXENTO D22"
                },
                ajuste_isr_monto_d22: {
                    title: "Ajuste ISR MONTO D22"
                },
                ajuste_isr_por_subsidio_gravado_o06: {
                    title: "Ajuste ISR por Subsidio GRAVADO O06"
                },
                ajuste_isr_por_subsidio_exento_o06: {
                    title: "Ajuste ISR por Subsidio EXENTO O06"
                },
                ajuste_isr_por_subsidio_monto_o06: {
                    title: "Ajuste ISR por Subsidio MONTO O06"
                },
                ajuste_isr_a_favor_gravado_o02: {
                    title: "Ajuste ISR a Favor GRAVADO O02"
                },
                ajuste_isr_a_favor_exento_o02: {
                    title: "Ajuste ISR a Favor EXENTO O02"
                },
                ajuste_isr_a_favor_monto_o02: {
                    title: "Ajuste ISR a Favor MONTO O02"
                },
                uuid: {
                    title: "UUID"
                },
                canceled: {
                    title: "Cancelado"
                },
                active: {
                    title: "active"
                }
            },
            headers_report_to_export11: {
                clave: { title: "Folio" },
                name: { title: "Nombre" },
                gPago: { title: "Grupo Pago" },
                numIMSS: { title: "No. IMSS" },
                employerRegistry: { title: "Registro Patronal" },
                year: { title: "Año" },
                concepto: { title: "Concepto" },
                monto: { title: "Monto" },
            },
            headers_report_to_export2: {
                id: { title: "# Zentric" },
                employee_id: { title: "# Dh" },
                emp_full_name: { title: "Colaborador" },
                payroll: { title: "Grupo Pago" },
                credit_type: { title: "Tipo de crédito" },
                credit_id: { title: "Nº Prest" },
                start_date: { title: "Fecha inicio" },
                amount: { title: "Monto" },
                discount: { title: "Descuento" },
                balance: { title: "Saldo" },
            },
            headers_report_to_export1: {},
            headers_report_to_exportMoney: {
                EMPLID: { title: "Folio" },
                NAME: { title: "Nombre" },
                NATIONAL_ID_FORMAT: { title: "RFC" },
                NATIONAL_ID_MSK: { title: "CURP" },
                DESCRSHORT: { title: "Grupo Pago" },
                RegistroPatronal: { title: "Registro Patronal" },
                BRANCHID: { title: "Sucursal" },
                DEPTID: { title: "Id Area" },
                DEPTIDName: { title: "Area" },
                EMP_KEYDEP: { title: "id Departamento" },
                DESCR1: { title: "Departamento" },
                EMP_KEYPUE: { title: "id Posicion" },
                DESCR: { title: "Posicion" },
                district: { title: "Distrito" },
                division: { title: "Division" },
                GROUP: { title: "Grupo" },
                Region: { title: "Region" },
                POSTAL: { title: "Codigo Postal" },
                STATE: { title: "Estado" },
                ADDRESS4: { title: "Municipio" },
                ADDRESS3: { title: "Colonia" },
                ADDRESS1: { title: "Domicilio" },
                EMAIL: { title: "Correo" },
                PHONE: { title: "Telefono" },
                SEX: { title: "Genero" },
                ESTUDIOS: { title: "Estudios" },
                MAR_STATUS: { title: "Estado Civil" },
                BORN: { title: "Fecha Nacimiento" },
                EMP_FECING: { title: "Fecha Contratación" },
                LAST_PAYMENT_DATE: { title: "Ultimo dia calculado" },
                contract_type_name: { title: "Tipo contrato" },
                workday_type_name: { title: "Tipo jornada" },
                tax_regime_name: { title: "Tipo regimen" },
                contractual_regime_name: { title: "Regimen fiscal" },

                employee_type_name: { title: "Tipo trabajador" },
                salary_type_name: { title: "Tipo salario" },
                week_type_name: { title: "Tipo semana" },
                benefits_table_name: { title: "Tabla prestaciones" },
                SSN: { title: "IMSS" },
                HIRE_DT: { title: "Fecha Contratación" },

                ACTION_DT: { title: "Fecha mov Nomina" },
                DAILY_RT: { title: "Sal/Día" },
                GPMX_SDI_TOT: { title: "SD Integrado" },

                fecha_mov: { title: "Fecha movimiento" },
                type_mov: { title: "Tipo movimiento af" },
                fixed_contribution_base_salary: { title: "Salario base de cotización fijo" },
                variable_contribution_base_salary: { title: "Salario base de cotización variable" },
                contribution_base_salary: { title: "salario base de cotización" },
                BANK_NAME: { title: "Banco" },
                CARD: { title: "Tarjeta" },
                ACCOUNT: { title: "Nº Cuenta" },
                CLABE: { title: "Clabe" },

                NoResponsable: { title: "NoResponsable" },
                Responsable: { title: "Responsable" },
                Estructura: { title: "Estructura" },
            },
            headers_report_to_exportGeneral: {
                EMPLID: { title: "Folio" },
                NAME: { title: "Nombre" },
                NATIONAL_ID_FORMAT: { title: "RFC" },
                NATIONAL_ID_MSK: { title: "CURP" },
                DESCRSHORT: { title: "Grupo Pago" },
                RegistroPatronal: { title: "Registro Patronal" },
                BRANCHID: { title: "Sucursal" },
                DEPTID: { title: "Id Area" },
                DEPTIDName: { title: "Area" },
                EMP_KEYDEP: { title: "id Departamento" },
                DESCR1: { title: "Departamento" },
                EMP_KEYPUE: { title: "id Posicion" },
                DESCR: { title: "Posicion" },
                district: { title: "Distrito" },
                division: { title: "Division" },
                GROUP: { title: "Grupo" },
                Region: { title: "Region" },
                POSTAL: { title: "Codigo Postal" },
                STATE: { title: "Estado" },
                ADDRESS4: { title: "Municipio" },
                ADDRESS3: { title: "Colonia" },
                ADDRESS1: { title: "Domicilio" },
                EMAIL: { title: "Correo" },
                PHONE: { title: "Telefono" },
                SEX: { title: "Genero" },
                ESTUDIOS: { title: "Estudios" },
                MAR_STATUS: { title: "Estado Civil" },
                BORN: { title: "Fecha Nacimiento" },
                NoResponsable: { title: "NoResponsable" },
                Responsable: { title: "Responsable" },
            },
            headers_report_to_export10: {
                clave: { title: "Folio" },
                name: { title: "Nombre" },
                gPago: { title: "Grupo Pago" },
                depto: { title: "Departamento" },
                year: { title: "Año" },
                periodo: { title: "Periodo" },
                concepto: { title: "Concepto" },
                monto: { title: "Monto" },
            },
            headers_report_to_export9: {
                clave: { title: "Folio" },
                name: { title: "Nombre" },
                year: { title: "Año" },
                concepto: { title: "Concepto" },
                monto: { title: "Monto" },
            },
            headers_report_to_export16: {
                type: { title: "Tipo" },
                key: { title: "Concepto" },
                descr: { title: "Descripción" },
                counts: { title: "Cantidad" },
                total: { title: "Monto" },
            },
            headers_report_to_export18: {
                type: { title: "Tipo" },
                key: { title: "Concepto" },
                descr: { title: "Descripción" },
                counts: { title: "Cantidad" },
                total: { title: "Monto" },
            },
            headers_report_to_export7: {
                folio: { title: "Folio" },
                name: { title: "Nombre" },
                gPago: { title: "Grupo Pago" },
                period: { title: "Periodo" },
                total: { title: "Monto" },
            },
            headers_report_to_export20: {
                employee_id: { title: "# Colaborador" },
                net: { title: "Neto Pagar" },
            },
            headers_report_to_export19: {
                EMPLID: { title: "Folio" },
                NAME: { title: "Nombre" },
                DESCRSHORT: { title: "Grupo Pago" },
                HIRE_DT: { title: "Ingreso" },
                DAILY_RT: { title: "Salario Diario" },
                christmas_days: { title: "Aguinaldo" },
                vacation_allowances: { title: "Prima Vacacional" },
                vacation_days: { title: "Vacaciones" },
                integrating_factor: { title: "FI" },
            },
            headers_report_to_export21: {
                EMPLID: { title: "Folio" },
                NAME: { title: "Nombre" },
                payroll_name_datos: { title: "Datos Nomimas" },
                payroll_name_imss: { title: "Nomina IMSS" },
                payroll_name_salario: { title: "Mov Nomina" },
                payroll_name_mov: { title: "Mov Afiliatrios" },
                Estructura: { title: "Estructura" },
                EMP_FECING: { title: "Ingreso Nomina" },
                join_date_imss: { title: "Ingreso IMSS" },

            },
            headers_report_to_export: {},
            title: 'Reporte de Zentric',
            //
            headersreportes: [],
            itemsreportes: [],
            //
            listaReportes: [],
            listaReportesGeneral: [
                { id: 1, nombre: 'Resumen Empleados' },
                // { id: 3, nombre: 'Bajas empleados' },
                // { id: 4, nombre: 'Cifras quincenales' },
                // { id: 5, nombre: 'Cifras semanales' },
                //{ id: 6, nombre: 'Cifras totales' },
                // { id: 8, nombre: 'Registro de timbrados' },
                { id: 9, nombre: 'Acumulado ISR' },
                { id: 10, nombre: 'Pago bonos' },
                { id: 11, nombre: 'Auditores IMSS Infonavit' },
                // { id: 12, nombre: 'Caratura resumen polizas' },
                //  { id: 13, nombre: 'Intereses caja' },
                // { id: 14, nombre: 'Movimientos caja' },
                // { id: 15, nombre: 'Revision nominas' },
                //  { id: 17, nombre: 'Resumen Empleados' },
                //{ id: 18, nombre: 'Resumen conceptos' },
            ],
            listaReportesComReclu: [
                { id: 1, nombre: 'Resumen Empleados' },
                { id: 19, nombre: 'Beneficios Colaboradores' },
            ],
            listaReportesNom: [
                { id: 16, nombre: 'Resumen conceptos' },
                { id: 1, nombre: 'Resumen Empleados' },
                { id: 2, nombre: 'Saldos prestamos' },
                { id: 7, nombre: 'Vales de despensa' },
                { id: 9, nombre: 'Acumulado ISR' },
                { id: 10, nombre: 'Pago bonos' },
                { id: 11, nombre: 'Auditores IMSS Infonavit' },
                { id: 19, nombre: 'Beneficios Colaboradores' },
                //{ id: 20, nombre: 'Pago Periodo 40' },
                { id: 21, nombre: 'Grupos de Pago' },
                { id: 23, nombre: 'Reporte Finanzas' },
                // { id: 22, nombre: 'Cierre pago Citi' },

            ],
            tipoReporte: null,
            //
            listaEstilo: [{ id: 1, nombre: 'Acomulado', },
            { id: 2, nombre: 'Individual' }],
            estiloReporte: null,
            //
            listaGP: [{ id: 5704, nombre: 'Semanal Normal' },
            { id: 5707, nombre: 'Semanal Aguinaldo' },
            { id: 5708, nombre: 'Semanal PTU' },
            { id: 11602, nombre: 'Semanal Lentes' },
            { id: 11604, nombre: 'Semanal Finiquitos' },
            { id: 11720, nombre: 'Semanal Fondo de Ahorro' },

            { id: 5709, nombre: 'Quincenal Normal' },
            { id: 5711, nombre: 'Quincenal Aguinaldo' },
            { id: 5712, nombre: 'Quincenal PTU' },
            { id: 11603, nombre: 'Quincenal Lentes' },
            { id: 11605, nombre: 'Quincenal Finiquitos' },
            { id: 11721, nombre: 'Quincenal Fondo de Ahorro' },

            { id: 8240, nombre: 'Comisionistas Normal' },
            { id: 8243, nombre: 'Comisionistas Aguinaldo' },
            { id: 8244, nombre: 'Comisionistas PTU' },
            { id: 11606, nombre: 'Comisionistas Finiquitos' },
            { id: 11722, nombre: 'Comisionistas Fondo de Ahorro' }],

            gPReporte: null,
            //
            periodosResponse: true,
            periodosResponseloading: false,
            periodos: [],
            periodoReporte: [],
            //
            yearReporte: new Date().getFullYear(), // Inicializa con el año actual
            //
            habilitarexcel: false,
            loadingAsignacion: false,
            //fecha
            menu: false,
            dateRange: [],
            maxDate: '',
            minDate: '',
            selectedMonths: [],
            months: [
                { month: 1, monthName: 'Enero' },
                { month: 2, monthName: 'Febrero' },
                { month: 3, monthName: 'Marzo' },
                { month: 4, monthName: 'Abril' },
                { month: 5, monthName: 'Mayo' },
                { month: 6, monthName: 'Junio' },
                { month: 7, monthName: 'Julio' },
                { month: 8, monthName: 'Agosto' },
                { month: 9, monthName: 'Septiembre' },
                { month: 10, monthName: 'Octubre' },
                { month: 11, monthName: 'Noviembre' },
                { month: 12, monthName: 'Diciembre' },
            ],
            selectedMonthsFormatted: [],
            // extras
            peticionesConceptos: 0,
            tokenZentric: null,
            resultArrays: [],

        };
    },
    computed: {
        yearsList() {
            const currentYear = new Date().getFullYear();
            const startYear = 2023;
            const years = [];
            for (let year = currentYear; year >= startYear; year--) {
                years.push({ id: year, year: year });
            }
            return years;
        },
    },
    async mounted() {
        const today = new Date();
        this.maxDate = today.toISOString().substr(0, 7) // Formato YYYY-MM
        this.getPuesto();
    },
    methods: {
        splitArray(array, chunkSize) {
            const chunks = [];
            for (let i = 0; i < array.length; i += chunkSize) {
                chunks.push(array.slice(i, i + chunkSize));
            }
            return chunks;
        },
        async getReportes23() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;

            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/getReporteSAT`, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });

                if (response.data.status === "OK") {

                    this.itemsreportes = response.data.dataReporte;
                    this.headersreportes = this.headers23;
                    this.headers_report_to_export = this.headers_report_to_export23;
                } else {
                    alert('No hay datos desde Zentric.');
                }

            } catch (error) {
                console.error("Error general:", error);
            } finally {
                this.habilitarexcel = true;
                this.loadingAsignacion = false;
            }

        },
        async getReportes7() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;


            params = {
                Opcion: 0,
                grupoPago: this.gPReporte,
                periodo: [this.periodoReporte],
                years: [this.yearReporte],
            };

            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });


                if (response.data.status === "OK") {
                    this.tokenZentric = response.data.token;
                    this.typePeriodo = response.data.typePeriodo;

                    let fetchPromises = [];
                    this.resultArrays = [];

                    const params = {
                        timeout: 0,
                        payrolls: [this.gPReporte],
                        years: [this.yearReporte],
                        periods: [this.periodoReporte],
                        is_calculation: this.typePeriodo
                    };

                    const promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                        headers: {
                            Authorization: this.tokenZentric
                        },
                        timeout: 0
                    })
                        .then((response) => {
                            if (response.data.status === "success" && response.data.data[0].data?.length > 0) {
                                this.resultArrays.push(response.data.data[0]);
                            }
                        })
                        .catch((error) => {
                            console.error("Error en la solicitud:", error);
                        });

                    fetchPromises.push(promise);


                    // Esperar a que todas las promesas iniciales se resuelvan
                    await Promise.all(fetchPromises);

                    // Validar si hay datos antes de continuar
                    if (this.resultArrays.length > 0) {
                        const chunkSize = 20000; // Tamaño del fragmento
                        const chunks = this.splitArray(this.resultArrays[0].data, chunkSize);

                        // Crear las promesas para enviar los fragmentos
                        const chunkPromises = chunks.map((chunk, index) => {
                            const params = {
                                Opcion: 7,
                                chunk: chunk,
                                title: this.resultArrays[0].headers,
                                index: index
                            };

                            return axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                                headers: {
                                    Authorization: localStorage.getItem("token")
                                },
                                timeout: 0
                            });
                        });

                        // Esperar a que se completen las promesas de fragmentos
                        const responses = await Promise.all(chunkPromises);

                        // Combinar todos los datos
                        const allData = responses.flatMap(response => response.data.dataReporte);

                        // Actualizar los datos para la tabla
                        this.itemsreportes = allData;
                        this.headersreportes = this.headers7;
                        this.headers_report_to_export = this.headers_report_to_export7;
                    } else {
                        alert('No hay datos desde Zentric.');
                    }
                }
            } catch (error) {
                console.error("Error general:", error);
            } finally {
                this.habilitarexcel = true;
                this.loadingAsignacion = false;
            }

        },
        async getReportes11() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;


            params = {
                Opcion: 0,
                grupoPago: this.gPReporte,
                years: [this.yearReporte],
            };

            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });

                if (response.data.status == "OK") {
                    this.tokenZentric = response.data.token;
                    this.typePeriodo = response.data.typePeriodo;


                    let fetchPromises = [];
                    this.resultArrays = [];

                    const params = {
                        timeout: 0,
                        payrolls: [this.gPReporte],
                        years: [this.yearReporte],
                        periods: this.periodoReporte,
                        is_calculation: this.typePeriodo
                    };

                    const promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                        headers: {
                            Authorization: this.tokenZentric
                        },
                        timeout: 0
                    })
                        .then((response) => {
                            if (response.data.status === "success" && response.data.data[0].data?.length > 0) {
                                this.resultArrays.push(response.data.data[0]);
                            }
                        })
                        .catch((error) => {
                            console.error("Error en la solicitud:", error);
                        });

                    fetchPromises.push(promise);



                    await Promise.all(fetchPromises);
                    // Validar si hay datos antes de continuar
                    if (this.resultArrays.length > 0) {
                        const chunkSize = 20000; // Ajusta el tamaño del fragmento según sea necesario
                        const chunks = this.splitArray(this.resultArrays[0].data, chunkSize);

                        // Crear un objeto params con múltiples propiedades para los fragmentos
                        const chunkPromises = chunks.map((chunk, index) => {
                            const params = {
                                Opcion: 11,
                                chunk: chunk,
                                title: this.resultArrays[0].headers,
                                index: index,
                                grupoPago: this.gPReporte
                            };

                            return axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                                headers: {
                                    Authorization: localStorage.getItem("token")
                                },
                                timeout: 0
                            });
                        });

                        // Esperar a que se completen las promesas de fragmentos
                        const responses = await Promise.all(chunkPromises);

                        // Combinar todos los datos
                        const allData = responses.flatMap(response => response.data.dataReporte);

                        // Actualizar los datos para la tabla
                        this.itemsreportes = allData;

                        this.headersreportes = this.headers11;
                        this.headers_report_to_export = this.headers_report_to_export11;

                    } else {
                        alert('No hay datos desde Zentric.');
                    }
                }
            } catch (error) {
                console.error("Error general:", error);
            } finally {
                this.habilitarexcel = true;
                this.loadingAsignacion = false;
            }

        },
        async getReportes10() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;


            params = {
                Opcion: 0,
                grupoPago: this.gPReporte,
                years: [this.yearReporte],
            };

            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });

                if (response.data.status == "OK") {
                    this.tokenZentric = response.data.token;
                    this.typePeriodo = response.data.typePeriodo;

                    let fetchPromises = [];
                    this.resultArrays = [];

                    const params = {
                        timeout: 0,
                        payrolls: [this.gPReporte],
                        years: [this.yearReporte],
                        periods: this.periodoReporte,
                        is_calculation: this.typePeriodo
                    };

                    const promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                        headers: {
                            Authorization: this.tokenZentric
                        },
                        timeout: 0
                    })
                        .then((response) => {
                            if (response.data.status === "success" && response.data.data[0].data?.length > 0) {
                                this.resultArrays.push(response.data.data[0]);
                            }
                        })
                        .catch((error) => {
                            console.error("Error en la solicitud:", error);
                        });

                    fetchPromises.push(promise);


                    // Esperar a que todas las promesas iniciales se resuelvan
                    await Promise.all(fetchPromises);
                    // Validar si hay datos antes de continuar
                    if (this.resultArrays.length > 0) {
                        const chunkSize = 20000; // Tamaño del fragmento
                        const chunks = this.splitArray(this.resultArrays[0].data, chunkSize);

                        // Crear las promesas para enviar los fragmentos
                        const chunkPromises = chunks.map((chunk, index) => {
                            const params = {
                                Opcion: 10,
                                chunk: chunk,
                                title: this.resultArrays[0].headers,
                                index: index
                            };

                            return axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                                headers: {
                                    Authorization: localStorage.getItem("token")
                                },
                                timeout: 0
                            });
                        });

                        // Esperar a que se completen las promesas de fragmentos
                        const responses = await Promise.all(chunkPromises);

                        // Combinar todos los datos
                        const allData = responses.flatMap(response => response.data.dataReporte);

                        // Actualizar los datos para la tabla
                        this.itemsreportes = allData;

                        this.headersreportes = this.headers10;
                        this.headers_report_to_export = this.headers_report_to_export10;
                    } else {
                        alert('No hay datos desde Zentric.');
                    }
                }
            } catch (error) {
                console.error("Error general:", error);
            } finally {
                this.habilitarexcel = true;
                this.loadingAsignacion = false;
            }

        },//
        async getReportes9() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;


            params = {
                Opcion: 0,
                grupoPago: this.gPReporte,
                years: [this.yearReporte],
            };

            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });

                if (response.data.status == "OK") {
                    this.tokenZentric = response.data.token;
                    this.typePeriodo = response.data.typePeriodo;

                    let fetchPromises = [];
                    this.resultArrays = [];

                    const params = {
                        timeout: 0,
                        payrolls: [this.gPReporte],
                        years: [this.yearReporte],
                        periods: this.periodoReporte,
                        is_calculation: this.typePeriodo
                    };

                    const promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                        headers: {
                            Authorization: this.tokenZentric
                        },
                        timeout: 0
                    })
                        .then((response) => {
                            if (response.data.status === "success" && response.data.data[0].data?.length > 0) {
                                this.resultArrays.push(response.data.data[0]);
                            }
                        })
                        .catch((error) => {
                            console.error("Error en la solicitud:", error);
                        });

                    fetchPromises.push(promise);



                    // Esperar a que todas las promesas iniciales se resuelvan
                    await Promise.all(fetchPromises);
                    // Validar si hay datos antes de continuar
                    if (this.resultArrays.length > 0) {
                        const chunkSize = 20000; // Tamaño del fragmento
                        const chunks = this.splitArray(this.resultArrays[0].data, chunkSize);

                        // Crear las promesas para enviar los fragmentos
                        const chunkPromises = chunks.map((chunk, index) => {
                            const params = {
                                Opcion: 9,
                                chunk: chunk,
                                title: this.resultArrays[0].headers,
                                index: index
                            };

                            return axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                                headers: {
                                    Authorization: localStorage.getItem("token")
                                },
                                timeout: 0
                            });
                        });

                        // Esperar a que se completen las promesas de fragmentos
                        const responses = await Promise.all(chunkPromises);

                        // Combinar todos los datos
                        const allData = responses.flatMap(response => response.data.dataReporte);

                        // Actualizar los datos para la tabla
                        this.itemsreportes = allData;


                        this.headersreportes = this.headers9;
                        this.headers_report_to_export = this.headers_report_to_export9;
                    } else {
                        alert('No hay datos desde Zentric.');
                    }
                }
            } catch (error) {
                console.error("Error general:", error);
            } finally {
                this.habilitarexcel = true;
                this.loadingAsignacion = false;
            }

        },
        async getReportes16() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;


            params = {
                Opcion: 0,
                grupoPago: this.gPReporte,
                periodo: [this.periodoReporte],
                years: [this.yearReporte],
            };

            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });

                if (response.data.status == "OK") {
                    this.tokenZentric = response.data.token;
                    this.typePeriodo = response.data.typePeriodo;

                    let fetchPromises = [];
                    this.resultArrays = [];

                    const params = {
                        timeout: 0,
                        payrolls: [this.gPReporte],
                        years: [this.yearReporte],
                        periods: [this.periodoReporte],
                        is_calculation: this.typePeriodo
                    };

                    const promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                        headers: {
                            Authorization: this.tokenZentric
                        },
                        timeout: 0
                    })
                        .then((response) => {
                            if (response.data.status === "success" && response.data.data[0].data?.length > 0) {
                                this.resultArrays.push(response.data.data[0]);
                            }
                        })
                        .catch((error) => {
                            console.error("Error en la solicitud:", error);
                        });

                    fetchPromises.push(promise);


                    // Esperar a que todas las promesas iniciales se resuelvan
                    await Promise.all(fetchPromises);
                    // Validar si hay datos antes de continuar
                    if (this.resultArrays.length > 0) {
                        const chunkSize = 20000; // Tamaño del fragmento
                        const chunks = this.splitArray(this.resultArrays[0].data, chunkSize);

                        // Crear las promesas para enviar los fragmentos
                        const chunkPromises = chunks.map((chunk, index) => {
                            const params = {
                                Opcion: 16,
                                chunk: chunk,
                                title: this.resultArrays[0].headers,
                                index: index
                            };

                            return axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                                headers: {
                                    Authorization: localStorage.getItem("token")
                                },
                                timeout: 0
                            });
                        });

                        // Esperar a que se completen las promesas de fragmentos
                        const responses = await Promise.all(chunkPromises);

                        // Combinar todos los datos
                        const allData = responses.flatMap(response => response.data.dataReporte);

                        // Actualizar los datos para la tabla
                        this.itemsreportes = allData;
                        this.headersreportes = this.headers16;
                        this.headers_report_to_export = this.headers_report_to_export16;
                    } else {
                        alert('No hay datos desde Zentric.');
                    }
                }
            } catch (error) {
                console.error("Error general:", error);
            } finally {
                this.habilitarexcel = true;
                this.loadingAsignacion = false;
            }

        },
        async getReportes2() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;

            params = {
                Opcion: 0,
                grupoPago: this.gPReporte,
            };

            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });

                if (response.data.status == "OK") {
                    this.tokenZentric = response.data.token;
                    this.typePeriodo = response.data.typePeriodo;

                    this.gPReporte = [5704, 5705, 5706, 5707, 5708, 5709, 5710, 5711, 5712, 5713, 8240, 8241, 8242, 8243, 8244, 11602, 11603, 11604, 11605, 11606];

                    let promises = [];

                    let params = {
                        timeout: 0,
                        payrolls: this.gPReporte,
                    };

                    let promise = axios.post(`https://api.zentric.mx/reports/credits/filter/`, params, {
                        headers: {
                            Authorization: this.tokenZentric
                        },
                        timeout: 0
                    })
                        .then((response) => {
                            if (response.data.status === "success") {
                                if (response.data.data.data && response.data.data.data.length > 0) {
                                    this.itemsreportes = response.data.data.data;
                                    this.headersreportes = this.headers2;
                                    this.headers_report_to_export = this.headers_report_to_export2;
                                }
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        })
                        .finally(() => {
                            this.habilitarexcel = true;
                            this.loadingAsignacion = false;
                        });
                    promises.push(promise);
                    await Promise.all(promises);
                }
            } catch (error) {
                console.error(error);
            }
        },
        async getReportes() {
            this.itemsreportes = [];
            this.headersreportes = [];
            this.headers_report_to_export = [];
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;

            if (this.tipoReporte === 10) {
                params = {
                    Opcion: this.tipoReporte,
                    fechainicial: this.dateRange[0],
                    fechafinal: this.dateRange[1],
                    grupoPago: this.gPReporte,
                    months: this.selectedMonthsFormatted,
                    years: [this.yearReporte],
                };
            } else if (this.tipoReporte === 1) {
                params = {
                    Opcion: this.tipoReporte,
                };
            } else if (this.tipoReporte === 17) {
                params = {
                    Opcion: this.tipoReporte,
                };
            } else if (this.tipoReporte === 20) {
                params = {
                    Opcion: this.tipoReporte,
                };
            } else if (this.tipoReporte === 19) {
                params = {
                    Opcion: this.tipoReporte,
                };
            } else if (this.tipoReporte === 21) {
                params = {
                    Opcion: this.tipoReporte,
                };
            } else {
                params = {
                    Opcion: this.tipoReporte,
                    fechainicial: this.dateRange[0],
                    fechafinal: this.dateRange[1],
                    grupoPago: this.gPReporte,
                    periodo: [this.periodoReporte],
                    months: this.selectedMonthsFormatted,
                    years: [this.yearReporte],
                };
            }
            axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                headers: {
                    Authorization: localStorage.getItem("token")
                },
                timeout: 0
            })
                .then((response) => {
                    if (response.data.status == "OK") {
                        this.itemsreportes = response.data.dataReporte;
                        const numero = response.data.reporte;
                        if (numero === 2) {
                            this.headersreportes = this.headers2;
                            this.headers_report_to_export = this.headers_report_to_export2;
                        } else if (numero === 16) {
                            this.headersreportes = this.headers16;
                            this.headers_report_to_export = this.headers_report_to_export16;
                        } else if (numero === 7) {
                            this.headersreportes = this.headers7;
                            this.headers_report_to_export = this.headers_report_to_export7;
                        } else if (numero === 10) {
                            this.headersreportes = this.headers10;
                            this.headers_report_to_export = this.headers_report_to_export10;
                        } else if (numero === 1) {
                            this.headersreportes = this.headers1;
                            this.headers_report_to_export = this.headers_report_to_export1;
                        } else if (numero === 11) {
                            this.headersreportes = this.headers11;
                            this.headers_report_to_export = this.headers_report_to_export11;
                        } else if (numero === 18) {
                            this.headersreportes = this.headers18;
                            this.headers_report_to_export = this.headers_report_to_export18;
                        } else if (numero === 17) {
                            this.headersreportes = this.headers17;
                            this.headers_report_to_export = this.headers_report_to_export17;
                        } else if (numero === 19) {
                            this.headersreportes = this.headers19;
                            this.headers_report_to_export = this.headers_report_to_export19;
                        } else if (numero === 20) {
                            this.headersreportes = this.headers20;
                            this.headers_report_to_export = this.headers_report_to_export20;
                        } else if (numero === 21) {
                            this.headersreportes = this.headers21;
                            this.headers_report_to_export = this.headers_report_to_export21;
                        } else {
                            // Manejar el caso cuando el número no coincide con ninguna opción
                            console.error("Número de reporte no válido:", numero);
                        }
                        if (response.data.extra.length > 0) {
                            this.extras = response.data.extra;
                            //console.log(this.extras);
                        }
                    } else {
                        this.itemsreportes = [];
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.habilitarexcel = true;
                    this.loadingAsignacion = false;
                });
        },
        Reportes() {
            this.itemsreportes = [];

            if (this.tipoReporte === 16) {
                this.getReportes16();
            } else if (this.tipoReporte === 2) {
                this.getReportes2();
            } else if (this.tipoReporte === 7) {
                this.getReportes7();
            } else if (this.tipoReporte === 10) {
                this.getReportes10();
            } else if (this.tipoReporte === 11) {
                this.getReportes11();
            } else if (this.tipoReporte === 9) {
                this.getReportes9();
            } else if (this.tipoReporte === 23) {
                this.getReportes23();
            } else {
                this.getReportes();
            }
        },

        asignarGrupoPago(gPReporte) {
            if ([5704, 5707, 5708, 11602, 11604, 11720].includes(gPReporte)) {
                return 5704; // Grupo Semanal
            } else if ([5709, 5711, 5712, 11603, 11605, 11721].includes(gPReporte)) {
                return 5709; // Grupo Quincenal
            } else if ([8240, 8243, 8244, 11606, 11722].includes(gPReporte)) {
                return 8240; // Grupo Comisionistas
            } else {
                return null; // Valor no válido
            }
        },

        consultarDatos() {
            if (this.gPReporte !== null) {
                // Realizar la consulta con this.gPReporte como parámetro
                this.periodosResponse = true;
                this.periodosResponseloading = true;
                this.periodoReporte = null;

                let params = {
                    grupoPago: this.asignarGrupoPago(this.gPReporte),
                    Opcion: this.tipoReporte,

                };
                axios.post(`${this.$store.getters.getHostNode}/api/getPeriodosGp`, params)
                    .then((response) => {
                        if (response.data.status == "OK") {
                            this.periodos = response.data.Periodos;

                        } else {
                            this.periodos = ['Periodos no disponibles'];
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.periodosResponse = false;
                        this.periodosResponseloading = false;
                    });
            }
        },
        exportToCsv() {
            // Exportar datos a CSV
            if (this.itemsreportes.length > 0) {
                this.$refs.exporter.exportCSV();
            } else {
                alert('No hay datos para exportar');
            }
        },
        formatMXN(cadena) {
            return cadena.toLocaleString("es-MX", {
                style: "currency",
                currency: "MXN",
            });
        },
        shouldFormat(item) {
            return item.type !== "Total conceptos";
        },
        clearSelectedMonths() {
            this.selectedMonths = [];
            this.selectedMonthsFormatted = [];
        },
        updateSelectedMonths() {
            this.selectedMonthsFormatted = this.selectedMonths.map(month => {
                return { year: this.yearReporte, month: month };
            });
        },
        async getPuesto() {

            axios.get(`${this.$store.getters.getHostNode}/api/get-puesto`, {
                headers: {
                    Authorization: localStorage.getItem("token")
                },
                timeout: 0
            })
                .then((response) => {
                    if (response.data.status == "OK") {
                        let JOBCODE = parseInt(response.data.data.JOBCODE, 10);
                        const jobcodesNominas = [9394, 9698, 9700, 9702, 9703, 12713, 9721, 12717]; //
                        const jobcodeComReclu = [9268, 9273, 9686, 9687, 9688];
                        if (jobcodesNominas.includes(JOBCODE)) {
                            this.listaReportes = this.listaReportesNom;
                            this.headers_report_to_export1 = this.headers_report_to_exportMoney;
                            this.headers1 = this.headersMoney;
                        } else if (jobcodeComReclu.includes(JOBCODE)) {
                            this.listaReportes = this.listaReportesComReclu;
                            this.headers_report_to_export1 = this.headers_report_to_exportMoney;
                            this.headers1 = this.headersMoney;
                        } else {
                            this.listaReportes = this.listaReportesGeneral;
                            this.headers_report_to_export1 = this.headers_report_to_exportGeneral;
                            this.headers1 = this.headersGeneral;
                        }
                    } else {
                        this.listaReportes = [];
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.habilitarexcel = true;
                    this.loadingAsignacion = false;
                });
        },

    },
};
</script>
