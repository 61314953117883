<template>
  <v-dialog
    v-model="dialog"
    max-width="700px"
    transition="dialog-bottom-transition"
    persistent
    scrollable
    content-class="fix-border-dialog"
  >
    <v-card class="rounded-xl">
      <v-card-title class="text-subtitle-2 primary white--text">
        Curriculum
        <v-spacer></v-spacer>
        <v-icon small dark @click="$emit('cierraForm', 'Cancel')">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-img
          :src="require('@/assets/img/membrete_header_urrea.png')"
          width="100%"
        >
        </v-img>
        <p class="d-flex justify-center">
          {{ colaborador.NAME }}
        </p>
        <v-divider style="border-color: #0800f0"></v-divider>
        <v-row v-if="loadingSkeleton">
          <v-col cols="6" v-for="(item, index) in 6" :key="index">
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-form v-else ref="form" v-model="valid" lazy-validation>
          <v-row class="d-flex">
            <v-card style="width: 50%" class="pa-2" elevation="0">
              <v-expansion-panels accordion flat>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="blue-grey--text text--lighten-1"
                  >
                    <div class="font-weight-bold">
                      Experiencia laboral ({{ cv.length }})
                    </div>
                    <v-icon
                      color="teal darken-2"
                      @click="addExperiencia"
                      v-if="cv.length == 0"
                      >mdi-plus-circle</v-icon
                    >
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="(item, index) in cv" :key="index">
                      <p class="font-weight-medium">
                        {{ index + 1 }} Experiencia laboral
                        <v-icon color="primary"
                          >mdi-information-variant-circle</v-icon
                        >
                        <v-divider vertical class="px-3"></v-divider>

                        <v-icon color="teal darken-2" @click="addExperiencia"
                          >mdi-plus-circle</v-icon
                        >
                        <v-icon
                          color="pink darken-2"
                          @click="popExperiencia(index)"
                          >mdi-minus-circle</v-icon
                        >
                      </p>
                      <v-text-field
                        v-model="item.nombreEmpresa"
                        label="Nombre Empresa"
                        dense
                        counter="50"
                        :rules="rulesRequiredTextField"
                      ></v-text-field>
                      <v-text-field
                        v-model="item.posicionEmpresa"
                        label="Posición"
                        counter="50"
                        dense
                        :rules="rulesRequiredTextField"
                      ></v-text-field>
                      <p class="font-italic">Explicación de actividades</p>
                      <v-textarea
                        v-model="item.explicaciónActividades"
                        label="Mencione algunas de sus actividades"
                        rows="3"
                        dense
                        counter="200"
                        :rules="rulesRequiredText"
                      ></v-textarea>
                      <v-text-field
                        v-model="item.lblFechaExperiencia"
                        placeholder="Selecciona las fechas de inicio y fin laborados."
                        label="Periodo laborado."
                        dense
                        @click="
                          dialogFechas = true;
                          tipoInput = 'Experiencia';
                          indexValue = index;
                        "
                        :rules="rulesRequired"
                      ></v-text-field>
                      <v-divider class="pb-5"></v-divider>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
            <v-card style="width: 50%" class="pa-2" elevation="0">
              <v-expansion-panels accordion flat>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="blue-grey--text text--lighten-1"
                  >
                    <div class="font-weight-bold">
                      Conocimientos ({{ itemConocimiento.length }})
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <v-combobox
                        v-model="itemConocimiento"
                        :items="conocimientosSelect"
                        :loading="loadingForm"
                        :menu-props="{ top: true, offsetY: true }"
                        label="Seleccionar conocimiento"
                        hide-selected
                        outlined
                        dense
                        small-chips
                        item-value="id"
                        item-text="descr"
                        multiple
                        deletable-chips
                        background-color="grey lighten-2"
                        color="cyan"
                        :rules="rulesRequired"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0">
                            <span>{{ item.descr }}</span>
                          </v-chip>
                          <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                          >
                            (+{{ itemConocimiento.length - 1 }} más)
                          </span>
                        </template>
                      </v-combobox>
                      <p
                        v-for="(item, index) in itemConocimiento"
                        :key="index"
                        class="text-truncate ma-0"
                      >
                        <v-tooltip
                          top
                          color="blue lighten-1"
                          v-if="idCurriculum"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="openDialogDocumentos(item, 1)"
                              color="primary"
                              >mdi-upload</v-icon
                            >
                          </template>
                          <label
                            class="text-uppercase font-weight-bold white--text"
                          >
                            Subir documento
                          </label>
                        </v-tooltip>
                        <v-tooltip
                          top
                          color="blue lighten-1"
                          v-if="item.nombre"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="viewFile(item)"
                              color="primary"
                              >mdi-download</v-icon
                            >
                          </template>
                          <label
                            class="text-uppercase font-weight-bold white--text"
                          >
                            Descargar documento
                          </label>
                        </v-tooltip>

                        <v-icon
                          color="pink darken-2"
                          @click="popConocimientos(index)"
                          >mdi-minus</v-icon
                        >
                        {{ item.descr }}
                      </p>
                      <v-divider class="pb-5"></v-divider>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
            <v-card style="width: 50%" class="pa-2" elevation="0">
              <v-expansion-panels accordion flat>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="blue-grey--text text--lighten-1"
                  >
                    <div class="font-weight-bold">
                      Competencias ({{ competencias.length }})
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <v-combobox
                        v-model="competencias"
                        :items="competenciasSelect"
                        :loading="loadingForm"
                        :menu-props="{ top: true, offsetY: true }"
                        label="Seleccionar competencia"
                        hide-selected
                        outlined
                        dense
                        small-chips
                        item-value="id"
                        item-text="competencia"
                        multiple
                        deletable-chips
                        background-color="grey lighten-2"
                        color="cyan"
                        :rules="rulesRequired"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0">
                            <span>{{ item.competencia }}</span>
                          </v-chip>
                          <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                          >
                            (+{{ competencias.length - 1 }} más)
                          </span>
                        </template>
                      </v-combobox>
                      <p
                        v-for="(item, index) in competencias"
                        :key="index"
                        dense
                        class="ma-0"
                      >
                        {{ item.competencia }}
                        <v-icon
                          color="pink darken-2"
                          @click="popCompetencias(index)"
                          >mdi-minus</v-icon
                        >
                      </p>
                      <v-divider class="pb-5"></v-divider>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
            <v-card style="width: 50%" class="pa-2" elevation="0">
              <v-expansion-panels accordion flat>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="blue-grey--text text--lighten-1"
                  >
                    <div class="font-weight-bold">
                      Educación ({{ educacion.length }})
                      <v-icon
                        color="teal darken-2"
                        @click="addEducacion"
                        v-if="educacion.length == 0"
                        >mdi-plus-circle</v-icon
                      >
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="(item, index) in educacion" :key="index">
                      <p class="font-weight-medium">
                        {{ index + 1 }} Educación

                        <v-divider vertical class="px-3"></v-divider>

                        <v-icon color="teal darken-2" @click="addEducacion"
                          >mdi-plus-circle</v-icon
                        >
                        <v-icon
                          color="pink darken-2"
                          @click="popEducacion(index)"
                          >mdi-minus-circle</v-icon
                        >
                        <v-tooltip
                          top
                          color="blue lighten-1"
                          v-if="idCurriculum"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="openDialogDocumentos(item, 2)"
                              color="primary"
                              >mdi-upload</v-icon
                            >
                          </template>
                          <label
                            class="text-uppercase font-weight-bold white--text"
                          >
                            Subir documento
                          </label>
                        </v-tooltip>
                        <v-tooltip
                          top
                          color="blue lighten-1"
                          v-if="item.nombre"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="viewFile(item)"
                              color="primary"
                              >mdi-download</v-icon
                            >
                          </template>
                          <label
                            class="text-uppercase font-weight-bold white--text"
                          >
                            Descargar documento
                          </label>
                        </v-tooltip>
                      </p>
                      <v-text-field
                        v-model="item.nombreInstitucion"
                        label="Institución"
                        dense
                        counter="50"
                        :rules="rulesRequiredTextField"
                      ></v-text-field>
                      <v-text-field
                        v-model="item.lblFechaEscolaridad"
                        placeholder="Selecciona un periodo."
                        label="Periodo escolar."
                        dense
                        @click="
                          dialogFechas = true;
                          tipoInput = 'Escolaridad';
                          indexValue = index;
                        "
                        :rules="rulesRequired"
                      ></v-text-field>
                      <v-divider class="pb-5"></v-divider>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
            <v-card style="width: 50%" class="pa-2" elevation="0">
              <v-expansion-panels accordion flat>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="blue-grey--text text--lighten-1"
                  >
                    <div class="font-weight-bold">
                      Reconocimientos ({{ reconocimientos.length }})
                    </div>
                    <v-icon
                      color="teal darken-2"
                      @click="addReconocimientos"
                      v-if="reconocimientos.length == 0"
                      >mdi-plus-circle</v-icon
                    >
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="(item, index) in reconocimientos" :key="index">
                      <p class="font-weight-medium">
                        {{ index + 1 }} Reconocimiento
                        <v-icon color="primary"
                          >mdi-information-variant-circle</v-icon
                        >
                        <v-divider vertical class="px-3"></v-divider>

                        <v-icon
                          color="teal darken-2"
                          @click="addReconocimientos"
                          >mdi-plus-circle</v-icon
                        >
                        <v-icon
                          color="pink darken-2"
                          @click="popReconocimientos(index)"
                          >mdi-minus-circle</v-icon
                        >
                        <v-tooltip
                          top
                          color="blue lighten-1"
                          v-if="idCurriculum"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="openDialogDocumentos(item, 3)"
                              color="primary"
                              >mdi-upload</v-icon
                            >
                          </template>
                          <label
                            class="text-uppercase font-weight-bold white--text"
                          >
                            Subir documento
                          </label>
                        </v-tooltip>
                        <v-tooltip
                          top
                          color="blue lighten-1"
                          v-if="item.nombre"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="viewFile(item)"
                              color="primary"
                              >mdi-download</v-icon
                            >
                          </template>
                          <label
                            class="text-uppercase font-weight-bold white--text"
                          >
                            Descargar documento
                          </label>
                        </v-tooltip>
                      </p>
                      <v-text-field
                        v-model="item.nombreReconocimiento"
                        label="Reconocimientos"
                        dense
                        :rules="rulesRequiredTextField"
                        counter="50"
                      ></v-text-field>
                      <v-text-field
                        v-model="item.lblFechaReconocimiento"
                        placeholder="Selecciona un periodo."
                        label="Periodo."
                        dense
                        @click="
                          dialogFechas = true;
                          tipoInput = 'Reconocimiento';
                          indexValue = index;
                        "
                        :rules="rulesRequired"
                      ></v-text-field>
                      <v-divider class="pb-5"></v-divider>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
            <v-card style="width: 50%" class="pa-2" elevation="0">
              <v-expansion-panels accordion flat>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="blue-grey--text text--lighten-1"
                  >
                    <div class="font-weight-bold">
                      Congresos, cursos y actividades adicionales ({{
                        actividadAdicional.length
                      }})
                      <v-icon
                        color="teal darken-2"
                        @click="addActAdicional"
                        v-if="actividadAdicional.length == 0"
                        >mdi-plus-circle</v-icon
                      >
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-for="(item, index) in actividadAdicional"
                      :key="index"
                    >
                      <p class="font-weight-medium">
                        {{ index + 1 }} Actividad adicional
                        <v-icon color="primary"
                          >mdi-information-variant-circle</v-icon
                        >
                        <v-divider vertical class="px-3"></v-divider>

                        <v-icon color="teal darken-2" @click="addActAdicional"
                          >mdi-plus-circle</v-icon
                        >
                        <v-icon
                          color="pink darken-2"
                          @click="popActAdicional(index)"
                          >mdi-minus-circle</v-icon
                        >
                        <v-tooltip
                          top
                          color="blue lighten-1"
                          v-if="idCurriculum"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="openDialogDocumentos(item, 4)"
                              color="primary"
                              >mdi-upload</v-icon
                            >
                          </template>
                          <label
                            class="text-uppercase font-weight-bold white--text"
                          >
                            Subir documento
                          </label>
                        </v-tooltip>
                        <v-tooltip
                          top
                          color="blue lighten-1"
                          v-if="item.nombre"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="viewFile(item)"
                              color="primary"
                              >mdi-download</v-icon
                            >
                          </template>
                          <label
                            class="text-uppercase font-weight-bold white--text"
                          >
                            Descargar documento
                          </label>
                        </v-tooltip>
                      </p>
                      <v-text-field
                        v-model="item.nombreActividad"
                        label="Actividad adicional"
                        dense
                        counter="50"
                        :rules="rulesRequiredTextField"
                      ></v-text-field>
                      <v-text-field
                        v-model="item.lblFechaActividad"
                        placeholder="Selecciona un periodo."
                        label="Periodo de actividad adicional."
                        dense
                        @click="
                          dialogFechas = true;
                          tipoInput = 'ActAdicional';
                          indexValue = index;
                        "
                        :rules="rulesRequired"
                      ></v-text-field>
                      <v-divider class="pb-5"></v-divider>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-0">
        <v-btn
          class="font-weight-bold rounded-xl rounded-t-0"
          color="teal darken-2"
          block
          x-large
          text
          :loading="loading"
          @click="guardar"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialogFechas" max-width="350px">
      <v-card class="rounded-lg">
        <v-card-title class="primary white--text subtitle-2 py-1">
          <v-row dense>
            <v-col dense cols="6">
              <p class="mb-0 font-weight-bold blue--text text--lighten-4">
                Inicio
              </p>
              <p
                v-if="dates.length >= 1"
                class="mb-0"
                style="font-size: 1.05rem"
              >
                {{ fixFormatDate(dates[0]) }}
              </p>
            </v-col>
            <v-divider></v-divider>
            <v-col dense cols="6">
              <p class="mb-0 font-weight-bold blue--text text--lighten-4">
                Fin
              </p>
              <p
                v-if="dates.length == 2"
                class="mb-0"
                style="font-size: 1.05rem"
              >
                {{ fixFormatDate(dates[1]) }}
              </p>
            </v-col>
          </v-row>
        </v-card-title>
        <div class="text-center">
          <v-date-picker
            v-model="dates"
            max-width="350px"
            v-on:change="cierraDialog(tipoInput)"
            no-title
            range
            elevation="0"
            locale="es-mx"
          >
            <template #title>
              <div class="v-picker__title">
                <div class="v-date-picker-header__value">Hola</div>
              </div>
            </template>
          </v-date-picker>
        </div>
      </v-card>
    </v-dialog>
    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
    <carga-archivo-documentos
      v-if="dialogDocumentos"
      :dialog="dialogDocumentos"
      :item="item"
      :tipoDocumento="tipoDocumento"
      :emplid="colaborador.EMPLID"
      @cierraUpload="closeCarga"
    ></carga-archivo-documentos>
    <ConfirmDialogReclutamiento
      :showConfirm="muestraConfirm"
      :question="question"
      @cierraConfirm="cierraConfirm"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import { formatDate, deleteToken } from "../../store/Funciones/funciones";
import SnackBarErrorIncidencias from "../../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import CargaArchivoDocumentos from "./CargaArchivoDocumentos.vue";
import ConfirmDialogReclutamiento from "../../views/generated/AdmnVacantes/components/ConfirmDialogReclutamiento.vue";
export default {
  name: "curriculum-colaborador",
  props: ["dialog", "colaborador"],
  components: {
    SnackBarErrorIncidencias,
    CargaArchivoDocumentos,
    ConfirmDialogReclutamiento,
  },
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      loadingSkeleton: true,
      dialogFechas: false,
      dates: [],
      valid: true,
      rulesRequired: [
        (value) => !!value || "Este campo es requerido", // Validación de campo requerido
      ],

      rulesRequiredText: [
        (value) => !!value || "Este campo es requerido", // Validación de campo requerido
        (v) => (v && v.length <= 200) || "Debe tener menos de 200 caracteres.",
      ],
      
      rulesRequiredTextField: [
        (value) => !!value || "Este campo es requerido", // Validación de campo requerido
        (v) => (v && v.length <= 50) || "Debe tener menos de 50 caracteres.",
      ],
      tipoInput: "",
      indexValue: 0,
      loading: false,
      cv: [
        {
          nombreEmpresa: "",
          posicionEmpresa: "",
          explicaciónActividades: "",
          periodo: "",
          fInicio: "",
          fFinal: "",
          lblFechaExperiencia: "",
        },
      ],
      conocimientos: [
        {
          conocimiento: "",
        },
      ],

      competencias: [],

      educacion: [
        {
          nombreInstitucion: "",
          fInicio: "",
          fFinal: "",
          lblFechaEscolaridad: "",
        },
      ],

      reconocimientos: [
        {
          nombreReconocimiento: "",
          fInicio: "",
          fFinal: "",
          lblFechaEscolaridad: "",
        },
      ],

      actividadAdicional: [
        {
          nombreActividad: "",
          fInicio: "",
          fFinal: "",
          lblFechaActividad: "",
        },
      ],

      conocimientosSelect: [],
      competenciasSelect: [],
      itemConocimiento: [],
      loadingForm: true,

      dialogDocumentos: false,
      item: [],
      tipoDocumento: 0,
      idCurriculum: "",

      muestraConfirm: false,
      question: "",
      idAux: 0,

      attrs: {
        elevation: 0,
      },
    };
  },
  computed: {},
  async mounted() {
    this.getConocimientos();
    this.getCompetencias();
    this.getCurriculumColaborador();
  },
  methods: {
    guardar() {
      const validar = this.$refs.form.validate();
      console.log(this.idCurriculum);
      if (validar) {
        if (this.idCurriculum) {
          this.openConfirm(this.idCurriculum, this.colaborador.EMPLID);
        } else {
          this.loading = true;
          const headers = { Authorization: localStorage.getItem("token") };
          const params = {
            idCurriculum: this.idCurriculum,
            folioColaborador: this.colaborador.EMPLID,
            jobcode: this.colaborador.JOBCODE,
            experienciaLaboral: this.cv,
            conocimientos: this.itemConocimiento,
            competencias: this.competencias,
            educacion: this.educacion,
            reconocimientos: this.reconocimientos,
            actividadAdicional: this.actividadAdicional,
          };
          return new Promise((res) => {
            axios
              .post(
                `${this.$store.getters.getHostNode}/api/set-curriculum-colaborador`,
                params,
                { headers }
              )
              .then((response) => {
                if (response.data.status == "OK") {
                  res(response.data.data);
                  this.textoBarra = response.data.message;
                  this.colorBarra = "success";
                  this.muestraBarra = true;
                  return new Promise((resolve) => {
                    setTimeout(() => {
                      resolve(this.$emit("cierraForm", "OK"));
                    }, 1500);
                  });
                } else if (response.data.status == "EXPRESSION") {
                  deleteToken();
                } else {
                  res([]);
                }
              })
              .finally(() => {
                this.loading = false;
              });
          });
        }
      } else {
        this.textoBarra =
          "Por favor, completa tu CV para continuar, o no sobrepase el limite de caracteres en los campos";
        this.colorBarra = "error";
        this.muestraBarra = true;
      }
    },

    editar() {
      console.log("editar");
      this.loading = true;
      const headers = { Authorization: localStorage.getItem("token") };
      const params = {
        idCurriculum: this.idCurriculum,
        folioColaborador: this.colaborador.EMPLID,
        jobcode: this.colaborador.JOBCODE,
        experienciaLaboral: this.cv,
        conocimientos: this.itemConocimiento,
        competencias: this.competencias,
        educacion: this.educacion,
        reconocimientos: this.reconocimientos,
        actividadAdicional: this.actividadAdicional,
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/update-curriculum-colaborador`,
            params,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve(this.$emit("cierraForm", "OK"));
                }, 1500);
              });
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },

    dateRangeText(tipoInput, index) {
      let inicio = "";
      let fin = "";
      if (this.dates[0] != null) {
        if (tipoInput == "Experiencia") {
          this.cv[index].fInicio = this.dates[0];
        } else if (tipoInput == "Escolaridad") {
          this.educacion[index].fInicio = this.dates[0];
        } else if (tipoInput == "Reconocimiento") {
          this.reconocimientos[index].fInicio = this.dates[0];
        } else if (tipoInput == "ActAdicional") {
          this.actividadAdicional[index].fInicio = this.dates[0];
        }

        inicio = formatDate(this.dates[0]);
      }
      if (this.dates[1] != null) {
        if (tipoInput == "Experiencia") {
          this.cv[index].fFinal = this.dates[1];
        } else if (tipoInput == "Escolaridad") {
          this.educacion[index].fFinal = this.dates[1];
        } else if (tipoInput == "Reconocimiento") {
          this.reconocimientos[index].fFinal = this.dates[1];
        } else if (tipoInput == "ActAdicional") {
          this.actividadAdicional[index].fFinal = this.dates[1];
        }
        fin = formatDate(this.dates[1]);
      }

      if (this.dates.length == 0) {
        if (tipoInput == "Experiencia") {
          this.cv[index].fInicio = "";
          this.cv[index].fFinal = "";
        } else if (tipoInput == "Escolaridad") {
          this.educacion[index].fInicio = "";
          this.educacion[index].fFinal = "";
        } else if (tipoInput == "Reconocimiento") {
          this.reconocimientos[index].fInicio = "";
          this.reconocimientos[index].fFinal = "";
        } else if (tipoInput == "ActAdicional") {
          this.actividadAdicional[index].fInicio = "";
          this.actividadAdicional[index].fFinal = "";
        }
      }
      if (tipoInput == "Experiencia") {
        this.cv[index].lblFechaExperiencia = inicio + " al " + fin;
      } else if (tipoInput == "Escolaridad") {
        this.educacion[index].lblFechaEscolaridad = inicio + " al " + fin;
      } else if (tipoInput == "Reconocimiento") {
        this.reconocimientos[index].lblFechaReconocimiento =
          inicio + " al " + fin;
      } else if (tipoInput == "ActAdicional") {
        this.actividadAdicional[index].lblFechaActividad =
          inicio + " al " + fin;
      }
    },

    cierraDialog() {
      if (this.dates.length == 2) {
        if (this.dates[0] > this.dates[1]) {
          this.dates.pop();
          this.colorBarra = "error";
          this.textoBarra =
            "La fecha final no puede ser menor a la inicial. :c";
          this.muestraBarra = true;
        } else {
          this.dialogFechas = false;
          this.dateRangeText(this.tipoInput, this.indexValue);
        }
      }
    },

    fixFormatDate(date) {
      return formatDate(date);
    },

    getCurriculumColaborador() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-curriculum-colaborador/${this.colaborador.EMPLID}`,
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.idCurriculum = response.data.data.id;
              if (this.idCurriculum) {
                console.log(this.idCurriculum);
                this.cv = response.data.data.experienciaLaboral;
                this.itemConocimiento = response.data.data.conocimientos;
                this.competencias = response.data.data.competencias;
                this.educacion = response.data.data.educacion;
                this.reconocimientos = response.data.data.reconocimientos;
                this.actividadAdicional = response.data.data.actividadAdicional;
              }
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingSkeleton = false;
            this.loadingForm = false;
          });
      });
    },

    getConocimientos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-conocimientos`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.conocimientosSelect = response.data.data;
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },

    getCompetencias() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-competencias`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.competenciasSelect = response.data.data;
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },

    //EXPERIENCIA LABORAL
    addExperiencia() {
      this.cv.push({
        nombreEmpresa: "",
        posicionEmpresa: "",
        explicaciónActividades: "",
        periodo: "",
        fInicio: "",
        fFinal: "",
        lblFechaEscolaridad: "",
      });
    },

    popExperiencia(index) {
      this.cv.splice(index, 1);

      this.textoBarra = "Registro removido.";
      this.colorBarra = "primary";
      this.muestraBarra = true;
    },

    //EDUCACION
    addEducacion() {
      this.educacion.push({
        nombreInstitucion: "",
        fInicio: "",
        fFinal: "",
        lblFechaEscolaridad: "",
      });
    },

    popEducacion(index) {
      this.educacion.splice(index, 1);

      this.textoBarra = "Registro removido.";
      this.colorBarra = "primary";
      this.muestraBarra = true;
    },

    //RECONOCIMIENTOS
    addReconocimientos() {
      this.reconocimientos.push({
        nombreReconocimiento: "",
        fInicio: "",
        fFinal: "",
        lblFechaReconocimiento: "",
      });
    },

    popReconocimientos(index) {
      this.reconocimientos.splice(index, 1);

      this.textoBarra = "Reconocimiento removido.";
      this.colorBarra = "primary";
      this.muestraBarra = true;
    },

    //ACTIVIDAD ADICIONAL
    addActAdicional() {
      this.actividadAdicional.push({
        nombreActividad: "",
        fInicio: "",
        fFinal: "",
        lblFechaActividad: "",
      });
    },

    popActAdicional(index) {
      this.actividadAdicional.splice(index, 1);

      this.textoBarra = "Actividad removida.";
      this.colorBarra = "primary";
      this.muestraBarra = true;
    },

    openDialogDocumentos(item, tipoDocumento) {
      this.tipoDocumento = tipoDocumento;
      this.dialogDocumentos = true;
      this.item = item;
    },

    //COMPETENCIAS
    popConocimientos(index) {
      this.itemConocimiento.splice(index, 1);

      this.textoBarra = "Conocimiento removido.";
      this.colorBarra = "primary";
      this.muestraBarra = true;
    },

    //COMPETENCIAS
    popCompetencias(index) {
      this.competencias.splice(index, 1);

      this.textoBarra = "Competencia removida.";
      this.colorBarra = "primary";
      this.muestraBarra = true;
    },

    closeCarga(response) {
      this.dialogDocumentos = false;
      console.log(response);
      if (response == true) {
        this.cv = [];
        this.itemConocimiento = [];
        this.competencias = [];
        this.educacion = [];
        this.reconocimientos = [];
        this.actividadAdicional = [];
        this.loadingSkeleton = true;
        this.getCurriculumColaborador();
      }
    },

    async viewFile(item) {
      console.log(item.nombre);
      console.log(this.colaborador.EMPLID);
      try {
        const response = await axios.get(
          `${this.$store.getters.getHostNode}/api/download-archivo-curriculum/${item.nombre}/${this.colaborador.EMPLID}`,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        const { metadata, file: base64File } = response.data;
        const iv = new Uint8Array(JSON.parse(metadata.iv));
        const key = await this.importKey(JSON.parse(metadata.key));
        const encryptedData = Uint8Array.from(atob(base64File), (c) =>
          c.charCodeAt(0)
        ).buffer;
        const decryptedArrayBuffer = await crypto.subtle.decrypt(
          {
            name: "AES-GCM",
            iv: iv,
          },
          key,
          encryptedData
        );
        const decryptedBlob = new Blob([new Uint8Array(decryptedArrayBuffer)], {
          type: "application/pdf",
        });
        const decryptedUrl = URL.createObjectURL(decryptedBlob);
        window.open(decryptedUrl);
      } catch (error) {
        this.showSnackbar("Primero carga un archivo");
      }
    },

    async importKey(jwk) {
      const key = await crypto.subtle.importKey(
        "jwk",
        jwk,
        {
          name: "AES-GCM",
        },
        true,
        ["encrypt", "decrypt"]
      );
      return key;
    },

    showSnackbar(message) {
      this.text = message;
      this.snackbar = true;
    },

    openConfirm(id, emplid) {
      this.muestraConfirm = true;
      (this.idAux = id),
        (this.question = `[${emplid}], Para cuidar tu información, removeremos sus archivos cada vez que actualice sus archivos.`);
    },

    cierraConfirm(respuesta) {
      if (!respuesta) {
        this.muestraConfirm = false;
      } else {
        this.muestraConfirm = false;
        this.loading = true;
        this.editar();
      }
    },
  },
};
</script>