<template>
  <v-dialog
    v-model="abrirModalTicket"
    persistent
    max-width="600px"
    content-class="fix-border-dialog"
  >
    <v-card class="rounded-xl">
      <v-card-title style="background: #4682b4;color: white;">
        <span class="font-weight-bold subtitle-1">TICKET DE EQUIPO</span>
        <v-spacer></v-spacer>
        <v-icon
          dark
          @click="
            reset();
            resetValidation();
            $emit('cerrarTicket');
          "
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row dense>
            <v-col cols="12">
              <label class="overline">
                Tipo de equipo
              </label>  
              <v-select
                v-model="tipoEquipo"
                class="rounded-lg"
                outlined
                dense
                :items="items"
                label="Selecciona el tipo de equipo"
                required
                :rules="rules"
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <label class="overline">
                Asunto
              </label>
              <v-text-field
                v-model="asuntoProblema"
                class="pa-0 rounded-lg"
                placeholder="Escribe un asunto..."
                required
                outlined
                dense
                :rules="rules"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <label class="overline">
                Descripción
              </label>
              <v-textarea
                v-model="descProblema"
                class="pa-0 rounded-lg"
                clearable
                outlined
                required
                rows="3"
                clear-icon="fa-solid fa-xmark"
                :rules="rules"
                placeholder="Escribe tus comentarios..."
              ></v-textarea>
            </v-col> </v-row
        ></v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-0">
        <v-btn
          class="rounded-xl rounded-t-0 font-weight-bold"
          color="blue darken-1"
          text
          x-large
          block
          :loading="loadingButton"
          @click="
            loadingButton = true;
            enviarTicket();
          "
        >
          <v-icon>mdi-ticket-confirmation</v-icon>
          Enviar ticket
        </v-btn>
      </v-card-actions>
    </v-card>

    <SnackBar
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import SnackBar from "../../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
export default {
  name: "levanta-ticket-et",
  components: { SnackBar },
  props: ["abrirModalTicket"],

  data() {
    return {
      asuntoProblema: "",
      tipoEquipo: "",
      descProblema: "",
      colorBarra: "",
      textoBarra: "",
      valid: true,
      muestraBarra: false,
      loadingButton: false,
      items: ["Laptop", "PC Escritorio", "Celular"],
      rules: [(v) => !!v || "Este campo es requerido"],
    };
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    enviarTicket() {
      if (this.$refs.form.validate()) {
        let params = {
          Opcion: 18,
          token: localStorage.getItem("token"),
          tipo: this.tipoEquipo,
          asunto: this.asuntoProblema,
          comentario: this.descProblema,
        };
        axios
          .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
          .then((response) => {
            
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              this.textoBarra = "¡Ticket enviado! :)";
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.resetValidation();
              this.reset();
            } else if (response.data.status == "ERROR") {
              this.textoBarra = "Error. No se envió el ticket. :(";
              this.colorBarra = "error";
              this.muestraBarra = true;
            }
          }).finally(() => {
            this.loadingButton = false;
          });
      }
    },
  },

  mounted() {},
};
</script>
