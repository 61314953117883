<template>
  <v-card class="rounded-lg elevation-0" width="100%">

    <!-- Tabs -->
    <v-tabs v-model="tabMercancia" background-color="primary" color="white" grow dark>
      <v-tab>RECEPCIÓN DE MERCANCÍA</v-tab>
      <v-tab v-if="pAltaReceptor">USUARIOS PARA RECEPCIÓN DE MERCANCÍA</v-tab>
      <v-tab v-if="pAltaMaterial">MERCANCÍA - ITEMS</v-tab>
    </v-tabs>

    <!-- Tab content -->
    <v-tabs-items v-model="tabMercancia">
      <v-tab-item>
        <v-data-table
          :headers="headers"
          :items="mercanciaSolicitudes"
          :search="search"
          no-data-text="No hay registros."
          loading-text="Cargando información..."
          :loading="loadingPasses"
          :footer-props="{
            'items-per-page-text': 'Elementos por página',
            'pageText': '{0}-{1} de {2}',
          }"
          style="background-color:#ECEFF1"
        >
          <template v-slot:top>
            <div style="display: flex; justify-content: space-between;">
              <v-text-field
                v-model="search"
                outlined dense clearable
                class="rounded-xl"
                style="max-width: 300px;"
                prepend-inner-icon="mdi-table-search"
                placeholder="Buscar..."
              ></v-text-field>
              <div class="text-center">
                <v-btn color="teal darken-1" class="rounded-lg" x-large depressed dark @click="generaEntrega = true">
                  <v-icon left>mdi-plus</v-icon>Crear solicitud de entrega de mercanc&iacute;a
                </v-btn>
              </div>
            </div>
          </template>
          <template v-slot:[`item.UsuarioEntrega`]="{ item }">
            <div class="text-left">{{ item.UsuarioEntrega }}</div>
          </template>
          <template v-slot:[`item.Descripcion`]="{ item }">
            <ul class="text-left">
              <li v-for="(itemlist, itemIndex) in item.items" :key="itemIndex">
                {{ itemlist.Cantidad + ' ' + itemlist.UnidadMedida + ' ' + itemlist.NombreMaterial }}
              </li>
            </ul>
          </template>
          <template v-slot:[`item.Recibe`]="{ item }">
            <ul class="text-left">
              <li v-for="(itemlist, itemIndex) in item.items" :key="itemIndex">
                {{ itemlist.UsuarioRecibe }}
              </li>
            </ul>
          </template>
          <template v-slot:item.Estatus="{ item }">
            <v-chip small :class="getClass(item.Estatus)">
              {{ item.Estatus === 1 ? "Pendiente" : item.Estatus === 2 ? "En proceso" : item.Estatus === 3 ? "Cerrado" : "No definido" }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab depressed
                  x-small
                  dark
                  color="blue accent-2"
                  class="mr-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="abrirDetalle(item.IdSolicitud)"
                  style="max-height: 25px !important; max-width: 25px !important;"
                >
                  <v-icon small v-if="pAutorizar && item.Estatus <= 2">mdi-check</v-icon>
                  <v-icon small v-else>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span v-if="pAutorizar && item.Estatus <= 2">Aprobar</span>
              <span v-else>Ver detalle</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.Estatus <= 2">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab depressed
                  x-small
                  dark
                  color="blue accent-2"
                  class="mr-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="editarSolicitud(item.IdSolicitud);"
                  style="max-height: 25px !important; max-width: 25px !important;"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.Estatus <= 1">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab depressed
                  x-small
                  dark
                  color="pink darken-3"
                  class="mr-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="cancelarSolicitud(item.IdSolicitud)"
                  style="max-height: 25px !important; max-width: 25px !important;"
                >
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <span>Borrar</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <GeneracionDeMercancia v-if="generaEntrega" :generaEntrega="generaEntrega" :IdSolicitud="IdSolicitud" @cierraEntregaMercancia="cierraEntregaMercancia" />

        <AprobarMercancia
          v-if="verDetalle"
          :verDetalle="verDetalle"
          :IdSolicitud="IdSolicitud"
          :pAutorizar="pAutorizar"
          @cierraDetalleMercancia="cierraDetalleMercancia"
        />
      </v-tab-item>

      <v-tab-item v-if="pAltaReceptor">
        <rolUsuariosListado/>
      </v-tab-item>

      <v-tab-item v-if="pAltaMaterial">
        <altaMaterialItems/>
      </v-tab-item>

    </v-tabs-items>

    <v-dialog v-model="modalDeshabilitar" persistent max-width="450">
      <v-card class="rounded-lg">
        <v-card-text class="pt-6">
          <p class="font-weight-bold subtitle-1 text-center text-uppercase">
            ¿Realmente deseas borrar la solicitud #{{ IdSolicitud }}?
            <br/>
            Ya no se podra recuperar.
          </p>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: space-around;">
          <v-btn style="width: 46%;" class="rounded-lg elevation-0" dark color="pink darken-3" @click="modalDeshabilitar = false" :disabled="loadingEliminando">cancelar</v-btn>
          <v-btn style="width: 46%;" class="rounded-lg elevation-0" dark color="teal darken-1" @click="loadingEliminando = true; confirmaCancelar();" :loading="loadingEliminando">eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar v-if="muestraBarra" :muestraBarra="muestraBarra" :texto="textoBarra" :color="colorBarra" @cierraBarra="muestraBarra = false" />

  </v-card>
</template>

<script>
import axios from "axios";
import SnackBar from '../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue';
// import { deleteToken, formatDate } from "../../../../../../store/Funciones/funciones";

import GeneracionDeMercancia from "./GeneracionDeMercancia.vue";
import AprobarMercancia from "./AprobarMercancia.vue";
import altaMaterialItems from "./MaterialItemsListado.vue";
import rolUsuariosListado from "./RolUsuariosMercancia.vue";

export default {
  name: "lista-entrega-mercancia",
  components: { GeneracionDeMercancia, AprobarMercancia, altaMaterialItems, rolUsuariosListado, SnackBar },
  data() {
    return {
      headers: [
        {
          text: "Solicitud",
          value: "IdSolicitud",
          class: "overline font-weight-bold primary white--text rounded-left-top-table-lg",
          align: 'center'
        },
        {
          text: "Entrega",
          value: "UsuarioEntrega",
          class: "overline font-weight-bold primary white--text",
          align: 'center'
        },
        {
          text: "Descripción",
          value: "Descripcion",
          class: "overline font-weight-bold primary white--text",
          align: 'center'
        },
        {
          text: "Recibe",
          value: "Recibe",
          class: "overline font-weight-bold primary white--text",
          align: 'center'
        },
        {
          text: "Estatus",
          value: "Estatus",
          class: "overline font-weight-bold primary white--text",
          align: 'center'
        },
        {
          text: "Acciones",
          value: "actions",
          class: "overline font-weight-bold primary white--text rounded-right-top-table-lg",
          align: 'center'
        },
      ],

      tabMercancia: null,
      pAutorizar: false,
      pAltaMaterial: false,
      pAltaReceptor: false,

      mercanciaSolicitudes: [],
      loadingPasses: true,
      muestraBarra: false,
      IdSolicitud: 0,
      search: null,

      generaEntrega: false,
      verDetalle: false,

      modalDeshabilitar: false,
      loadingEliminando: false,

    }; // return
  }, // data

  methods: {
    getMercanciaCreados() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      console.log(localStorage);
      const params = {
        usuarioMovimiento:  localStorage.getItem("folio")
      };
      return new Promise((res) => {
        axios
        .get(`${this.$store.getters.getHostNode}/api/read-solicitud_mercancia`,
        {
          headers: headers,
          params
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "EXPRESSION") {
            console.log("falta deleteToken()");
          } else if (response.data.status == "OK") {
            res(response.data.data);
            this.mercanciaSolicitudes = response.data.data;
            this.pAltaReceptor        = response.data.altaReceptor;
            this.pAltaMaterial        = response.data.altaMaterial;
            this.pAutorizar           = response.data.permisoAprobar;
          } else {
            res([]);
          }
        })
        .finally(() => {
          this.loadingPasses = false;
        });
      });
    }, // getMercanciaCreados

    cierraEntregaMercancia(respuesta) {
      console.log("Alta o Edit solicitud:",respuesta);
      this.IdSolicitud = respuesta;
      this.generaEntrega = false;
      this.loadingPasses = true;
      this.getMercanciaCreados();
    },
    editarSolicitud(params) {
      console.log("editarSolicitud:",params);
      this.IdSolicitud = params;
      this.generaEntrega = true;
    }, // editarSolicitud

    abrirDetalle(params) {
      console.log("abrirDetalle",params);
      this.IdSolicitud = params;
      this.verDetalle = true;
    }, // abrirDetalle
    cierraDetalleMercancia(response) {
      console.log("cierraDetalleMercancia",response);
      this.IdSolicitud = 0;
      this.verDetalle = false;
      this.loadingPasses = true;
      this.getMercanciaCreados();
    }, // cierraDetalleMercancia

    cancelarSolicitud(params) {
      console.log("cancelarSolicitud",params);
      this.IdSolicitud = params;
      this.modalDeshabilitar = true;
    }, // cancelarSolicitud
    confirmaCancelar() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      console.log(localStorage);
      const params = {
        usuarioMovimiento: localStorage.getItem("folio"),
        idSolicitud: this.IdSolicitud,
      };
      return new Promise((res) => {
        axios.post(
          `${this.$store.getters.getHostNode}/api/delete-solicitud_mercancia`,
          params,
          {
            headers: headers,
          }
        ).then((response) => {
          console.log("confirmaCancelar =>", response);
          this.loadingEliminando = false;
          this.modalDeshabilitar = false;
          if (response.data.status == true) {
            res(response.data);
            this.textoBarra = "Se elimino de manera exitosa";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.loadingPasses = true;
            this.getMercanciaCreados();
          } else if (response.data.status == false) {
            res(response.data);
            this.textoBarra = response.data.message;
            this.colorBarra = "error";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            console.log("falta deleteToken()");
          }
        });
      }); // return
    }, // confirmaCancelar

    getClass(estatus) {
      if (estatus === 1) {
        return "yellow accent-4 black--text";
      } else if (estatus === 2) {
        return "blue darken-3 white--text";
      } else if (estatus === 3) {
        return "teal darken-1 white--text";
      } else {
        return "grey";
      }
    }, // getClass
  }, // methods

  async mounted() {
    this.getMercanciaCreados();
  },

};
</script>

<style>
.rounded-left-top-table-lg {
  border-top-left-radius: 8px;
}
.rounded-right-top-table-lg {
  border-top-right-radius: 8px;
}
.header-material-table {
  background-color: #A8A8A7;
}
.text-left {
  text-align: left;
}
</style>