import { render, staticRenderFns } from "./PersonaValoresPreview.vue?vue&type=template&id=609ab0d7&scoped=true&"
import script from "./PersonaValoresPreview.vue?vue&type=script&lang=js&"
export * from "./PersonaValoresPreview.vue?vue&type=script&lang=js&"
import style0 from "./PersonaValoresPreview.vue?vue&type=style&index=0&id=609ab0d7&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "609ab0d7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VRow,VSheet,VTooltip})


/* vuetify-loader */
import installDirectives from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
