<template>
  <v-dialog v-model="salariosMercadoDialog" persistent max-width="600">
    <v-card>
      <v-card-title class="text-h5">
        Equidad salarial
      </v-card-title>
      <v-card-text>
        <v-combobox
          v-model="salarioMercado"
          :items="salariosMercado"
          label="Selecciona los puestos con lo que se realizara la comparacion salarial."
          multiple
          hide-selected
          clearable
          small-chips
          item-text="descr"
          item-value="jobcode"
        ></v-combobox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          @click="$emit('hideDialogVerSalarios', null)"
        >
          Cancelar
        </v-btn>
        <v-btn :disabled="!salarioMercado ? true : false" color="green darken-1" text @click="$emit('hideDialogVerSalarios', salarioMercado)">
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  name: "dialog-ver-salarios-de-mercado",
  props: ["salariosMercadoDialog"],
  data() {
    return {
      salariosMercado:[],
      salarioMercado: []
    };
  },
  mounted(){
    this.getSalariosMercado();
  },
  methods: {
    getSalariosMercado(){
      let params = {
          Opcion:5,
          token: localStorage.getItem("token"),
      }
      axios.post(`${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`, params).then((response) => {
        if (response.data.status == 'OK') {
            this.salariosMercado = response.data.data;
        }
      })
    },
  }
};
</script>
