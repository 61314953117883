<template>
  <!-- Tabla para mostrar las solicitudes -->
  <v-dialog
    v-model="dCargaCumplimientoMotivo"
    persistent
    scrollable
    max-width="auto"
  >
    <!-- Dialog para registrar el cumplimiento del motivo -->
    <v-dialog
      v-model="dformcumplimientomotivo"
      persistent
      max-width="550"
      content-class="fix-border-dialog"
    >
      <v-card class="border-xl">
        <v-toolbar dark flat color="primary">
          <label class="overline font-weight-bold">
            Registro de Cumplimiento a la Justificación.
          </label>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dformcumplimientomotivo = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-5">
          <!-- Muestra la Justificacion de la solicitud seleccionada -->
          <div class="text-center mb-4">
            <label class="subtitle-2">
              <strong>JUSTIFICACION:</strong> {{ FJUSTIFICACION }}
            </label>
          </div>

          <!-- Muestra lo registrado por el motivo de Cumplimiento al plan de produccion-->
          <div v-show="vistacampos == 1">
            <div
              style="display: flex; justify-content: space-around; align-content: center;"
            >
              <div>
                <label class="lab">
                  <p class="subtitle-2">
                    <b>Figuras maximas:</b> {{ FMAXIMAS }}
                  </p>
                </label>
              </div>

              <div>
                <label class="lab">
                  <p class="subtitle-2">
                    <b>Figuras minimas:</b> {{ FMINIMAS }}
                  </p>
                </label>
              </div>
            </div>
            <div>
              <label class="overline">Figuras Totales creadas :</label>
              <v-text-field
                type="number"
                dense
                class="rounded-lg"
                outlined
                v-model="FigTotales"
                placeholder="Ingrese el número de figuras creadas..."
              >
              </v-text-field>
            </div>
          </div>
          <!-- Muestra lo registrado por el motivo de Pedidos extraordinarios-->
          <div cols="12" sm="12" md="12" v-show="vistacampos == 2">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-col cols="12" sm="10" md="10">
                  <label class="lab">
                    <h3><b>Monto:</b> {{ pedido }}</h3>
                  </label>
                </v-col>
              </v-col>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <center>
                      <h3><b>Registro del cumplimiento</b></h3>
                    </center>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <label class="lab">Monto total :</label>
                    <v-text-field
                      type="number"
                      v-model="PedidoTotal"
                      label="Monto total"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-row>
          </div>
          <!-- Muestra lo registrado por el motivo de Mantenimento y/o limpieza-->
          <div cols="12" sm="12" md="12" v-show="vistacampos == 3">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-col cols="12" sm="10" md="10">
                  <label class="lab">
                    <h3><b>Area de mantenimiento:</b> {{ areaml }}</h3>
                  </label>
                </v-col>
              </v-col>
              <v-container fluid>
                <v-col cols="12" sm="12" md="12">
                  <center>
                    <h3><b>Registro del cumplimiento</b></h3>
                  </center>
                </v-col>
                <v-textarea
                  name="input-5-1"
                  filled
                  label="Registro del mantenimiento"
                  :rules="rules"
                  counter
                  auto-grow
                  v-model="mantenimientocoment"
                  maxlength="250"
                ></v-textarea>
              </v-container>
            </v-row>
          </div>
          <!-- Muestra lo registrado por el motivo de Impacto en equipo caido-->
          <div cols="12" sm="12" md="12" v-show="vistacampos == 4">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label class="lab">
                  <h3><b>Comentario:</b> {{ comentarioiec }}</h3>
                </label>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <center>
                  <h3><b>Registro del cumplimiento</b></h3>
                </center>
              </v-col>
              <v-container fluid>
                <v-textarea
                  name="input-5-1"
                  filled
                  label="Resultado"
                  :rules="rules"
                  counter
                  auto-grow
                  v-model="comentariosoli"
                  maxlength="250"
                ></v-textarea>
              </v-container>
            </v-row>
          </div>

          <!--Mostrar switch para aceptar o rechazar el cumplimiento -->
          <div
            cols="12"
            sm="12"
            md="12"
            v-show="FJUSTIFICACION != 'Cumplimiento al plan de produccion'"
          >
            <v-radio-group v-model="EstadoCumplimiento" row>
              <v-radio label="Cumplido" value="Cumplido"></v-radio>
              <v-radio label="No Cumplido" value="No Cumplido"></v-radio>
            </v-radio-group>
            <!-- {{ EstadoCumplimiento }} -->
          </div>

          <v-snackbar
            v-model="muestraBarraerror"
            :color="colorBarraerror"
            rounded="lg"
            :timeout="timeouterror"
            elevation="24"
          >
            <div class="caption font-weight-bold mb-2">
              {{ textoBarraerror }}
            </div>
          </v-snackbar>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
          <v-btn
            class="rounded-xl rounded-t-0 font-weight-bold"
            color="teal darken-2"
            x-large
            text
            block
            @click="validarcampos()"
            dark
          >
            <v-icon>mdi-content-save-check</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="muestraBarra"
      :timeout="timeout"
      rounded="lg"
      :color="colorBarra"
    >
      <div class="caption font-weight-bold mb-2">{{ textoBarra }}</div>
    </v-snackbar>
    <!-- Muestra las solicitudes al responsable de su motivo -->
    <v-card class="rounded-lg">
      <v-toolbar dark color="primary">
        <v-toolbar-title class="overline font-weight-bold subtitle-1"
          >Carga del cuplimiento al motivo de desacnso laborado y tiempo
          extra.</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dCargaCumplimientoMotivo = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="headerMotivosTE"
          :items="infoMotivosTE"
          :search="searchMotivosTE"
          :expanded.sync="expandedgeneral"
          :loading="loading"
          :single-expand="true"
          item-key="ID"
          show-expand
          dense
          class="elevation-0"
          no-data-text="No hay solicitudes."
          :footer-props="{
            'items-per-page-text': 'Elementos por página',
            pageText: '{0}-{1} de {2}',
          }"
        >
          <template v-slot:top>
            <v-toolbar
              flat
              color="primary"
              class="rounded-lg rounded-b-0 rounded-t-0"
            >
              <v-text-field
                v-model="searchMotivosTE"
                :label="$t('DH360.findTextBox')"
                class="mt-7 rounded-lg"
                dense
                clearable
                flat
                solo-inverted
                dark
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>

          <template v-slot:item.emp_nomemp="{ item }">
            <p class="ma-0 caption text-uppercase font-weight-bold">
              {{ item.emp_nomemp }}
            </p>
          </template>

          <template v-slot:item.FECHA_CREACION="{ item }">
            {{ item.FECHA_CREACION.date.substring(0, 19) }}
          </template>

          <template v-slot:item.HORAS_TOTALES="{ item }">
            {{
              item.TIPO === "DESCANSO LABORADO"
                ? formatMXN(item.HORAS_TOTALES)
                : item.HORAS_TOTALES + " horas"
            }}
          </template>

          <template v-slot:item.TIPO="{ item }">
            <v-chip
              small
              class="font-weight-bold font-italic"
              :color="
                item.TIPO === 'DESCANSO LABORADO'
                  ? 'indigo lighten-3'
                  : 'deep-purple lighten-4'
              "
            >
              {{ item.TIPO }}
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              x-small
              rounded
              color="teal darken-1"
              dark
              @click="
                tipoIncidencia = item.TIPO === 'TIEMPO EXTRA' ? 8 : 12;
                setCumplimiento(item);
              "
            >
              <v-icon small>mdi-checkbox-marked-circle-plus-outline</v-icon>
            </v-btn>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-card
                v-if="item.GRUPOS === null"
                class="rounded-xl ma-4 pa-4 elevation-0"
                color="blue-grey lighten-5"
              ></v-card>
              <v-card
                v-else-if="item.TIPO === 'DESCANSO LABORADO'"
                class="rounded-xl ma-4 pa-4 elevation-0"
                color="blue-grey lighten-5"
              >
                <v-row dense>
                  <v-col :cols="getSizeColsPrincipalLarge">
                    <v-row dense>
                      <v-col :cols="getSizeCols">
                        <label
                          class="overline blue-grey--text text--darken-3 font-weight-bold"
                          >Solicitante</label
                        >
                        <p>
                          {{
                            "(" +
                              item.GRUPOS[0].solicitante +
                              ") " +
                              item.GRUPOS[0].EMP_NOMEMP
                          }}
                        </p>
                      </v-col>
                      <v-col :cols="getSizeCols">
                        <label
                          class="overline blue-grey--text text--darken-3 font-weight-bold"
                          >Departamento</label
                        >
                        <p>{{ item.GRUPOS[0].departamento }}</p>
                      </v-col>
                      <v-col :cols="getSizeCols">
                        <label
                          class="overline blue-grey--text text--darken-3 font-weight-bold"
                          >Fecha de solicitud</label
                        >
                        <p>
                          {{
                            item.GRUPOS[0].fecha_registro === null
                              ? "--"
                              : item.GRUPOS[0].fecha_registro.date.substring(
                                  0,
                                  19
                                )
                          }}
                        </p>
                      </v-col>
                      <v-col :cols="getSizeCols">
                        <label
                          class="overline blue-grey--text text--darken-3 font-weight-bold"
                          >Fecha de incidencia</label
                        >
                        <p>
                          {{
                            item.GRUPOS[0].fecha === null
                              ? "--"
                              : item.GRUPOS[0].fecha.date.substring(0, 10)
                          }}
                        </p>
                      </v-col>
                      <v-col :cols="getSizeCols">
                        <label
                          class="overline blue-grey--text text--darken-3 font-weight-bold"
                          >Fecha de autorización</label
                        >
                        <p>
                          {{
                            item.GRUPOS[0].fecha_autorizacion === null
                              ? "--"
                              : item.GRUPOS[0].fecha_autorizacion.date.substring(
                                  0,
                                  19
                                )
                          }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col :cols="getSizeColsPrincipalSmall">
                    <v-card
                      class="rounded-lg pa-4"
                      color="white"
                      height="100%"
                      width="100%"
                    >
                      <label
                        class="overline blue--text text--darken-3 font-weight-bold"
                        >Justificación</label
                      >
                      <p>{{ item.GRUPOS[0].justificacion }}</p>
                      <div v-if="[1, 2].includes(item.GRUPOS[0].motivo)">
                        <label
                          class="overline blue--text text--darken-3 font-weight-bold"
                          >Figuras a realizar</label
                        >
                        <p>
                          <strong>Mínimo de figuras:</strong>
                          {{ item.GRUPOS[0].FIG_MIN + ", " }}
                          <strong>Máximo de figuras:</strong>
                          {{ item.GRUPOS[0].FIG_MAX + "." }}
                        </p>
                      </div>
                      <div v-if="[3].includes(item.GRUPOS[0].motivo)">
                        <label
                          class="overline blue--text text--darken-3 font-weight-bold"
                          >Monto</label
                        >
                        <p>
                          {{
                            item.GRUPOS[0].MONTO != null
                              ? "-"
                              : formatMXN(item.GRUPOS[0].MONTO)
                          }}
                        </p>
                      </div>
                      <div v-if="[4].includes(item.GRUPOS[0].motivo)">
                        <label
                          class="overline blue--text text--darken-3 font-weight-bold"
                          >área</label
                        >
                        <p>
                          {{ item.GRUPOS[0].areaDesc }}
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                v-else
                class="rounded-lg ma-4 pa-4 elevation-0"
                color="blue-grey lighten-5"
              >
                <!-- {{ item.GRUPOS[0] }} -->
                <v-row dense>
                  <v-row dense>
                    <v-col :cols="getSizeColsPrincipalLarge">
                      <v-row dense>
                        <v-col :cols="getSizeCols">
                          <label
                            class="overline blue-grey--text text--darken-3 font-weight-bold"
                            >Colaborador(a)</label
                          >
                          <p>
                            {{
                              "(" +
                                item.GRUPOS[0].ID_EMPLEADO +
                                ") " +
                                item.GRUPOS[0].NOMBRE
                            }}
                          </p>
                        </v-col>
                        <v-col :cols="getSizeCols">
                          <label
                            class="overline blue-grey--text text--darken-3 font-weight-bold"
                            >Checadas</label
                          >
                          <p>
                            {{
                              item.GRUPOS[0].INGRESO +
                                " - " +
                                item.GRUPOS[0].SALIDA
                            }}
                          </p>
                        </v-col>
                        <v-col :cols="getSizeCols">
                          <label
                            class="overline blue-grey--text text--darken-3 font-weight-bold"
                            >Horas solicitadas/reales</label
                          >
                          <p>
                            {{
                              item.GRUPOS[0].HORAS +
                                " - " +
                                item.GRUPOS[0].HORAS_REALES
                            }}
                          </p>
                        </v-col>
                        <v-col :cols="getSizeCols">
                          <label
                            class="overline blue-grey--text text--darken-3 font-weight-bold"
                            >Estatus</label
                          >
                          <p>{{ item.GRUPOS[0].ESTADO }}</p>
                        </v-col>
                        <v-col :cols="getSizeCols">
                          <label
                            class="overline blue-grey--text text--darken-3 font-weight-bold"
                            >Fecha</label
                          >
                          <p>{{ item.GRUPOS[0].FECHA_SOLICITUD }}</p>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col :cols="getSizeColsPrincipalSmall">
                      <v-card
                        class="rounded-lg pa-4"
                        color="white"
                        height="100%"
                        width="100%"
                      >
                        <label
                          class="overline blue--text text--darken-3 font-weight-bold"
                          >Justificación</label
                        >
                        <p>{{ item.GRUPOS[0].justificacion }}</p>
                        <div v-if="[1, 2].includes(item.GRUPOS[0].motivo)">
                          <label
                            class="overline blue--text text--darken-3 font-weight-bold"
                            >Figuras a realizar</label
                          >
                          <p>
                            <strong>Mínimo de figuras:</strong>
                            {{ item.GRUPOS[0].FIG_MIN + ", " }}
                            <strong>Máximo de figuras:</strong>
                            {{ item.GRUPOS[0].FIG_MAX + "." }}
                          </p>
                        </div>
                        <div v-if="[3].includes(item.GRUPOS[0].motivo)">
                          <label
                            class="overline blue--text text--darken-3 font-weight-bold"
                            >Monto</label
                          >
                          <p>
                            {{
                              [null, undefined, ".00"].includes(
                                item.GRUPOS[0].MONTO
                              )
                                ? formatMXN(0)
                                : formatMXN(item.GRUPOS[0].MONTO)
                            }}
                          </p>
                        </div>
                        <div v-if="[4].includes(item.GRUPOS[0].motivo)">
                          <label
                            class="overline blue--text text--darken-3 font-weight-bold"
                            >área</label
                          >
                          <p>
                            {{ item.GRUPOS[0].areaDesc }}
                          </p>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-row>
              </v-card>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
//import SnackBarErrorIncidencias from "../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
export default {
  name: "persona-motivo-te",
  props: [],
  components: {
    //SnackBarErrorIncidencias
  },
  data() {
    return {
      infoMotivosTE: [],
      searchMotivosTE: "",
      expandedgeneral: [],
      loading: false,
      dformcumplimientomotivo: false,
      dCargaCumplimientoMotivo: false,
      FJUSTIFICACION: "",
      FMAXIMAS: "",
      FMINIMAS: "",
      FigTotales: "",
      PedidoTotal: "",
      areaml: "",
      pedido: "",
      comentarioiec: "",
      comentariosoli: "",
      mantenimientocoment: "",
      tipoIncidencia: null,
      estadodialog: 0,
      solicitud: 0,
      vistacampos: 0,
      rules: [(v) => v.length <= 249 || "Maximo 250 caracteres"],
      EstadoCumplimiento: null,

      // ----------------------- SNACKBAR------------------------
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,
      timeout: 2000,

      textoBarraerror: "",
      colorBarraerror: "",
      muestraBarraerror: false,
      timeouterror: 2000,

      headerMotivosTE: [
        {
          text: "",
          value: "data-table-expand",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "ID",
          value: "ID",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "MOTIVO",
          value: "JUSTIFICACION",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "SOLICITANTE",
          value: "emp_nomemp",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "FECHA DE REGISTRO",
          value: "FECHA_CREACION",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "HORAS/COSTO",
          value: "HORAS_TOTALES",
          align: "center",
          sortable: false,
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "TIPO",
          value: "TIPO",
          align: "center",
          class: "primary white--text overline font-weight-bold",
        },
        {
          text: "ACCIONES",
          value: "actions",
          align: "center",
          sortable: false,
          class: "primary white--text overline font-weight-bold",
        },
      ],
    };
  },
  mounted() {
    this.getCumplimientoMotivo();
  },
  computed: {
    getSizeCols() {
      return ["lg", "xl", "md"].includes(this.$vuetify.breakpoint.name)
        ? 4
        : this.$vuetify.breakpoint.name === "sm"
        ? 6
        : 12;
    },
    getSizeColsPrincipalLarge() {
      return ["lg", "xl", "md"].includes(this.$vuetify.breakpoint.name)
        ? 9
        : this.$vuetify.breakpoint.name === "sm"
        ? 6
        : 12;
    },
    getSizeColsPrincipalSmall() {
      return ["lg", "xl", "md"].includes(this.$vuetify.breakpoint.name)
        ? 3
        : this.$vuetify.breakpoint.name === "sm"
        ? 6
        : 12;
    },
  },
  methods: {
    validarcampos() {
      this.estadodialog = 0;
      if (this.FJUSTIFICACION == "Cumplimiento al plan de produccion" || this.FJUSTIFICACION == "Cumplimiento al plan de prduccion") {
        if (this.FigTotales == "") {
          this.textoBarraerror = "Debes registrar las Figuras totales.";
          this.colorBarraerror = "error";
          this.muestraBarraerror = true;
        } else {
          (this.PedidoTotal = ""),
            (this.mantenimientocoment = ""),
            (this.comentariosoli = ""),
            this.saveCumplimiento();
          if (this.dformcumplimientomotivo == 1) {
            this.dformcumplimientomotivo = false;
          }
        }
      }
      else if (this.FJUSTIFICACION == "Pedidos extraordinarios") {
        if (this.PedidoTotal == "") {
          this.textoBarraerror = "Debes registrar el monto total.";
          this.colorBarraerror = "error";
          this.muestraBarraerror = true;
        } else {
          (this.FigTotales = ""),
            (this.mantenimientocoment = ""),
            (this.comentariosoli = ""),
            this.saveCumplimiento();
          if (this.dformcumplimientomotivo == 1) {
            this.dformcumplimientomotivo = false;
          }
        }
      }
      else if (this.FJUSTIFICACION == "Mantenimento y/o limpieza" || this.FJUSTIFICACION == "Mantenimiento y/o limpieza") {
        if (this.mantenimientocoment == "" || this.EstadoCumplimiento == "") {
          this.textoBarraerror =
            "Debes registrar el comentario o No has seleccionado el estado del cumplimiento";
          this.colorBarraerror = "error";
          this.muestraBarraerror = true;
        } else {
          (this.PedidoTotal = ""),
            (this.FigTotales = ""),
            (this.comentariosoli = ""),
            this.saveCumplimiento();
          if (this.dformcumplimientomotivo == 1) {
            this.dformcumplimientomotivo = false;
          }
        }
      }
      else if (this.FJUSTIFICACION == "Impacto en equipo caido") {
        if (this.comentariosoli == "" || this.EstadoCumplimiento == "") {
          this.textoBarraerror =
            "Debes registrar el comentario o No has seleccionado el estado del cumplimiento";
          this.colorBarraerror = "error";
          this.muestraBarraerror = true;
        } else {
          (this.PedidoTotal = ""),
            (this.mantenimientocoment = ""),
            (this.FigTotales = ""),
            this.saveCumplimiento();
          if (this.dformcumplimientomotivo == 1) {
            this.dformcumplimientomotivo = false;
          }
        }
      }
      else {
        alert("ERROR. Existe un error en los datos ingresados. Intente de nuevo más tarde.")
      }
    },
    saveCumplimiento() {
      let params = {
        Opcion: 16,
        ID_SOLICITUD: this.solicitud,
        FIG_TOTALES: this.FigTotales,
        MONTO_TOTAL: this.PedidoTotal,
        COM_MANT: this.mantenimientocoment,
        COM_IMPEC: this.comentariosoli,
        RFIG_MAX: this.FMAXIMAS,
        RMONTO: this.pedido,
        MOTIVO: this.FJUSTIFICACION,
        ESTADO: this.EstadoCumplimiento,
        token: localStorage.getItem("token"),
        TIPO: this.tipoIncidencia,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/general/ws-TiempoExtra.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "ERROR") {
            this.textoBarra = "No se registro el cumplimiento.";
            this.colorBarra = "error";
            this.muestraBarra = true;
          } else if (response.data.status == "OK") {
            this.estadodialog = 1;
            this.textoBarra = "Cumplimiento registrado correctamente";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.getCumplimientoMotivo();
          }
        })
        .catch(function(e) {
          alert("Ocurrió un error: " + e);
        });
    },
    setCumplimiento(item) {
      this.PedidoTotal = "";
      this.mantenimientocoment = "";
      this.comentariosoli = "";
      this.FigTotales = "";
      this.solicitud = item.ID_SOLICITUD;
      this.vistacampos = 0;
      if (
        item.JUSTIFICACION == "Cumplimiento al plan de produccion" ||
        item.JUSTIFICACION == "Cumplimiento al plan de prduccion"
      ) {
        this.EstadoCumplimiento = "";
        this.vistacampos = 1;
        this.FJUSTIFICACION = item.JUSTIFICACION;
        this.FMAXIMAS = item.GRUPOS[0].FIG_MAX;
        this.FMINIMAS = item.GRUPOS[0].FIG_MIN;
        this.dformcumplimientomotivo = true;
      }

      if (item.JUSTIFICACION == "Pedidos extraordinarios") {
        this.EstadoCumplimiento = "";
        this.vistacampos = 2;
        this.FJUSTIFICACION = item.JUSTIFICACION;
        this.pedido = item.GRUPOS[0].MONTO;
        this.dformcumplimientomotivo = true;
      }
      if (
        item.JUSTIFICACION == "Mantenimento y/o limpieza" ||
        item.JUSTIFICACION == "Mantenimiento y/o limpieza"
      ) {
        this.EstadoCumplimiento = "";
        this.vistacampos = 3;
        this.FJUSTIFICACION = item.JUSTIFICACION;
        switch (item.GRUPOS[0].AREA_MANTENIMIENTO) {
          case 0:
            this.areaml = "GUDH";
            break;
          case 1:
            this.areaml = "Desarrollo Humano";
            break;
          case 2:
            this.areaml = "Administracion y Finanzas";
            break;
          case 6:
            this.areaml = "Logistica";
            break;
          case 7:
            this.areaml = "Operaciones";
            break;
          case 9:
            this.areaml = "Ventas Nacionales";
            break;
          case 10:
            this.areaml = "Mercadotecnia";
            break;
          case 12:
            this.areaml = "Ventas Internacionales";
            break;
          case 11:
            this.areaml = "Innovacion y competitividad";
            break;
          default:
            // Código a ejecutar si ninguno de los casos coincide
            break;
        }
        this.dformcumplimientomotivo = true;
      }
      if (item.JUSTIFICACION == "Impacto en equipo caido") {
        this.EstadoCumplimiento = "";
        this.vistacampos = 4;
        this.FJUSTIFICACION = item.JUSTIFICACION;
        this.comentarioiec = item.GRUPOS[0].COMENTARIO;
        this.dformcumplimientomotivo = true;
      }
    },
    getCumplimientoMotivo() {
      this.infoMotivosTE = [];
      this.loading = true;

      let params = {
        Opcion: 12,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/general/ws-TiempoExtra.php`,
          params
        )
        .then((response) => {
          var data = response.data.infoCumplimientoMotivo;
          if (![null, undefined].includes(data)) {
            this.dCargaCumplimientoMotivo = true;
            this.infoMotivosTE = data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch(function(e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
  },
};
</script>
