<template>
  <!-- cumpleañeros del mes -->
  <div>
    <div
      v-if="
        $vuetify.breakpoint.name != 'xs' &&
          $vuetify.breakpoint.name != 'sm' &&
          birthDay.length > 0
      "
    >
      <p class="subtitle-1 font-weight-bold ml-3 mb-1">
        Cumpleañeros del mes
      </p>
      <v-row dense>
        <v-col
          v-for="(item, index) in displayedBirthDay"
          :key="index"
          cols="12"
          md="4"
        >
          <v-card class="rounded-lg mx-1" color="white" elevation="0">
            <v-img
              height="100%"
              src="https://static.vecteezy.com/system/resources/previews/007/467/530/original/graphics-festival-flag-multicolor-for-decorative-happy-for-backdrop-background-illustration-free-vector.jpg"
            >
              <v-tooltip top color="blue accent-4">
                <template v-slot:activator="{ on, attrs }">
                  <v-card class="glass-effect elevation-0 rounded-lg ma-2" v-bind="attrs" v-on="on">
                    <v-avatar
                      class="profile rounded-circle ml-2 mt-1"
                      color="grey"
                      size="80"
                      tile
                    >
                      <!-- {{ person.foto }} -->
                      <v-img
                        v-if="item.photoName != null"
                        :lazy-src="require('@/assets/icons/boy-front-clay.png')"
                        :src="item.rutaFoto"
                      ></v-img>
                      <v-img
                        v-else
                        :src="require('@/assets/icons/boy-front-clay.png')"
                      ></v-img>
                    </v-avatar>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="subtitle-2">
                          {{ item.NAME }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.fecha
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </template>
                <span >
                  {{ item.FULL_NAME }}
                  <br>
                  {{ item.DESCR }}
                </span>
              </v-tooltip>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
      <div class="text-right" v-if="birthDay.length > 3">
        <v-btn
          class="text-decoration-underline font-weight-bold"
          text
          small
          color="blue darken-1"
          @click="showAll = !showAll"
        >
          {{ nameButtonShowBirthdays }}
        </v-btn>
      </div>
      <!-- <v-sheet class="elevation-0 rounded-lg mx-2 pa-2"> -->
      <!-- <persona-mi-nomina-zentric @cargandopdf="cargandopdf" @numNominas="numNominas" /> -->
    <!-- </v-sheet> -->
    </div>

    <div
      v-else-if="
        $vuetify.breakpoint.name == 'xs' &&
          $vuetify.breakpoint.name == 'sm' &&
          birthDay.length > 0
      "
    >
      <v-card class="ma-3 rounded-lg">
        <v-card-title>
          <v-icon large left>
            mdi-cake-variant
          </v-icon>
          <span class="text-h6 font-weight-light">¡Cumpleañeros del mes!</span>
        </v-card-title>

        <v-card-text class="text-h5 font-weight-bold">
          Conoce a los cumpleañeros de este mes
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small @click="validaRespuesta">
            <v-icon left>mdi-eye</v-icon>
            ver
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <div v-else-if="birthDay.length == 0"></div>

    <v-sheet>
      <data-table-generic
        v-show="muestraCumpleaneros"
        :headers="headers"
        :items="items"
        :titulo="titulo"
        :verDataTable="muestraCumpleaneros"
        @cierraTabla="muestraCumpleaneros = false"
      />
    </v-sheet>

    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-sheet>
  </div>
</template>
<script>
import axios from "axios";
import DataTableGeneric from "../../../../../components/componentsExtra/DataTableGeneric.vue";
import SnackBarErrorIncidencias from "../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
// import PersonaMiNominaZentric from "../../../../../components/PersonaMiNominaZentric.vue";


export default {
  name: "persona-noticias-preview",
  data: () => ({
    muestraCumpleaneros: false,
    model: null,
    numNominasZentric: null,
    muestraLoading: false,
    headers: [
      {
        text: "# empleado",
        sortable: false,
        value: "EMPLID",
      },
      {
        text: "Nombre",
        sortable: false,
        value: "NAME",
      },
      {
        text: "Puesto",
        sortable: false,
        value: "DESCR",
      },
      {
        text: "Fecha",
        sortable: false,
        value: "fecha",
      },
    ],
    items: [],
    titulo: "",
    textBtn: "Ver más...",
    birthDay: [],
    limiteCumpleanieros: 3,
    loadingBoton: false,
    muestraBarra: false,
    showAll: false,
    textoBarra: "",
    colorBarra: "",
  }),
  components: { DataTableGeneric, SnackBarErrorIncidencias },
  props: ["userId"],
  mounted() {
    this.getBirthDays();
  },
  computed: {
    displayedBirthDay() {
      return this.showAll ? this.birthDay : this.birthDay.slice(0, 3);
    },
    nameButtonShowBirthdays() {
      return this.showAll ? "Ver menos..." : "Ver más...";
    },
  },
  methods: {
    changeLimitPersonsShowed() {
      // this.limiteCumpleanieros = !this.limiteCumpleanieros

      if (this.limiteCumpleanieros > 3) {
        this.limiteCumpleanieros = 3;
        this.textBtn = "Ver menos...";
      } else {
        this.limiteCumpleanieros = this.birthDay.length;
        this.textBtn = "Ver más...";
      }
    },
    getBirthDays() {
      this.loadingBoton = true;
      let params = {
        Opcion: 7,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/general/ws-general.php`, params)
        .then((response) => {
          if (response.data.status != "Vacio") {
            var data = response.data.dataCumple;
            this.birthDay = data;
          }
          // this.validaRespuesta();
        })
        .catch(function(e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    validaRespuesta() {
      if (this.birthDay.length > 0) {
        this.items = this.birthDay;
        this.titulo = "Cumpleañeros del mes";
        this.muestraCumpleaneros = true;
        this.loadingBoton = false;
      }
    },
    cargandopdf(valor) {
      this.muestraLoading = valor;
      console.log("llega:", valor)
    },
    numNominas(valor) {
      this.numNominasZentric = valor;
    },
  },
};
</script>
<style lang="sass" scoped>
.v-card__title
  text-shadow: 0 0 6px #000000
  .v-card[name="reconocimiento"]
    .v-card__text
      height: calc(100% - 136px)
  table
    border: 1px solid #eeeeee
    margin: auto
    td, th
      padding: 0 6px
      min-width: 100px
      font-size: 1rem
      box-shadow: 0px 0px 2px #dddddd
      text-align: center
      input
        max-width: 100px
.glass-effect
  background: rgba(180, 180, 180, 0.2)
  border-radius: 16px
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1)
  backdrop-filter: blur(5px)
  -webkit-backdrop-filter: blur(5px)
  // border: 1px solid rgba(180, 180, 180, 0.3)
</style>
