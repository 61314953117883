<template>
  <v-card class="pa-3 my-2 elevation-0 rounded-xl">
    <v-card class="py-0 px-2 my-1 rounded-xl elevation-0" color="blue-grey lighten-5">
      <div class="center-item-in-div" v-if="loadingArticulosPersonales">
        <v-progress-circular
          :size="70"
          :width="7"
          color="purple"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-else>
        <!-- {{articulosPersonales}} -->
        <v-data-table dense class="mb-2" hide-default-footer :headers="headers" :items="articulosPersonales" style="background-color: transparent;">
        </v-data-table>
      </div>
    </v-card>
    <v-card class="py-0 px-2 my-1 rounded-xl elevation-0" color="blue-grey lighten-5">
      <div class="center-item-in-div" v-if="loadingConsumibles">
        <v-progress-circular
          :size="70"
          :width="7"
          color="purple"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-else>
        <!-- {{consumibles}} -->
        <v-data-table dense class="mb-2" hide-default-footer :headers="headers" :items="consumibles" style="background-color: transparent;">
        </v-data-table>
      </div>
    </v-card>
    <v-card class="py-0 px-2 my-1 rounded-xl elevation-0" color="blue-grey lighten-5">
      <div class="center-item-in-div" v-if="loadingHerramientas">
        <v-progress-circular
          :size="70"
          :width="7"
          color="purple"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-else>
        <!-- {{herramientas}} -->
        <v-data-table dense class="mb-2" hide-default-footer :headers="headers" :items="herramientas" style="background-color: transparent;">
        </v-data-table>
      </div>
    </v-card>
    <v-card class="py-0 px-2 my-1 rounded-xl elevation-0" color="blue-grey lighten-5">
      <div class="center-item-in-div" v-if="loadingVehiculos">
        <v-progress-circular
          :size="70"
          :width="7"
          color="purple"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-else>
        <!-- {{vehiculos}} -->
        <v-data-table dense class="mb-1" hide-default-footer :headers="headersVehiculos" :items="vehiculos" style="background-color: transparent;">
        </v-data-table>
      </div>
    </v-card>
  </v-card>
</template>

<script>
import { deleteToken } from "../../../../../store/Funciones/funciones";
import axios from "axios";
export default {
  name: "muestra-materiales-por-visitante",
  props: ["idVisitante"],
  data() {
    return {
      articulosPersonales: [],
      consumibles: [],
      herramientas: [],
      vehiculos: [],
      headers:[
        {value: "ID", text: "ID"},
        {value: "NOMBRE", text: "NOMBRE"},
        {value: "MARCA", text: "MARCA"},
        {value: "NO_SERIE", text: "NO. SERIE"},
        {value: "CANTIDAD", text: "CANTIDAD"},
        {value: "DESCRIPCION", text: "DESCRIPCION"},
      ],
      headersVehiculos:[
        {value: "ID", text: "ID"},
        {value: "TIPO_LICENCIA", text: "TIPO  DE LICENCIA"},
        {value: "NUMERO_LICENCIA", text: "# LICENCIA"},
        {value: "TIPO_VEHICULO", text: "VEHÍCULO"},
        {value: "PLACAS", text: "PLACAS"},
        {value: "NUMERO_ECONOMICO", text: "# ECONÓMICO"},
      ],
      loadingArticulosPersonales: true,
      loadingConsumibles: true,
      loadingHerramientas: true,
      loadingVehiculos: true,
    };
  },
  async mounted() {
    if (typeof this.idVisitante === "number") {
      this.articulosPersonales = await this.getArticulosPersonales().catch(
        (e) => {
          console.log(e);
        }
      );
      this.consumibles = await this.getConsumibles().catch((e) => {
        console.log(e);
      });
      this.herramientas = await this.getHerramientas().catch((e) => {
        console.log(e);
      });
      this.vehiculos = await this.getVehiculos().catch((e) => {
        console.log(e);
      });
    }
  },
  methods: {
    async getArticulosPersonales() {
      const params = {
        Opcion: 15,
        token: localStorage.getItem("token"),
        idVisitante: this.idVisitante,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.materiales);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingArticulosPersonales = false;
          });
      });
    },
    async getConsumibles() {
      const params = {
        Opcion: 16,
        token: localStorage.getItem("token"),
        idVisitante: this.idVisitante,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.materiales);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingConsumibles = false;
          });
      });
    },
    async getHerramientas() {
      const params = {
        Opcion: 17,
        token: localStorage.getItem("token"),
        idVisitante: this.idVisitante,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.materiales);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingHerramientas = false;
          });
      });
    },
    async getVehiculos() {
      const params = {
        Opcion: 18,
        token: localStorage.getItem("token"),
        idVisitante: this.idVisitante,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.materiales);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingVehiculos = false;
          });
      });
    },
  },
};
</script>
