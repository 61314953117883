<template>
  <div >
    <v-sheet v-if="loadingData">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col class="subtitle-1 text-center" cols="12">
          Obteninendo información...
        </v-col>
        <v-col cols="6" class="center-item-in-div">
          <v-progress-circular
            :size="70"
            :width="8"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-sheet>
    <div v-else-if="!loadingData && apego && apego.candidato">
      <div class="text-center mb-2">
        <div class="subtitle-1">
          <strong>Candidato: </strong> {{ apego.candidato.nombre }}
        </div>
        <div class="subtitle-2">
          <strong>Vacante: </strong> {{ apego.candidato.vacante }}
        </div>
      </div>
      <v-form v-show="!tokenInvalido" ref="formCargaPerfil" v-model="valid" >
        <v-card class="rounded-lg">
          <v-simple-table>
            <thead>
              <tr>
                <th
                  colspan="4"
                  class="text-center rounded-lg rounded-b-0 text-uppercase font-weight-bold white--text subtitle-1 primary"
                >
                  Escolaridad
                </th>
              </tr>
              <tr>
                <th
                  class="text-center text-uppercase font-weight-bold white--text light-blue lighten-1"
                >
                  Grado
                </th>
                <th
                  class="text-center text-uppercase font-weight-bold white--text light-blue lighten-1"
                >
                  Carrera
                </th>
                <th
                  class="text-center text-uppercase font-weight-bold white--text light-blue lighten-1"
                >
                  Ponderación
                </th>
                <th
                  class="text-center text-uppercase font-weight-bold white--text light-blue lighten-1"
                >
                  Resultado
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">{{ apego.escolaridad.esc }}</td>
                <td class="text-center">{{ apego.escolaridad.carrera }}</td>
                <td class="text-center">{{ apego.escolaridad.Ponderacion == 0 ? 'N/A' : apego.escolaridad.Ponderacion }}</td>
                <td class="center-item-in-div">
                  <v-text-field
                    v-model="apego.escolaridad.calificaciones"
                    class="rounded-lg my-1 fix-height-text-save-app"
                    :disabled="apego.escolaridad.Ponderacion == 0 ? true : false"
                    v-on:change="
                      validaCalificacion(
                        apego.escolaridad.Ponderacion,
                        apego.escolaridad.calificaciones
                      )
                    "
                    style="max-height: 40px; max-width: 120px;"
                    dense
                    outlined
                    type="number"
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-simple-table>
            <thead>
              <tr>
                <th
                  colspan="3"
                  class="text-center text-uppercase font-weight-bold white--text subtitle-1 primary"
                >
                  Conocimientos
                </th>
              </tr>
              <tr>
                <th
                  class="text-uppercase font-weight-bold white--text light-blue lighten-1"
                >
                  Conocimiento
                </th>
                <th
                  class="text-center text-uppercase font-weight-bold white--text light-blue lighten-1"
                >
                  Ponderación
                </th>
                <th
                  class="text-center text-uppercase font-weight-bold white--text light-blue lighten-1"
                >
                  Resultado
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in apego.conocimientos" :key="index">
                <td>{{ item.descr }}</td>
                <td class="text-center">{{ item.Ponderacion }}</td>
                <td class="center-item-in-div">
                  <v-text-field
                    v-model="item.calificaciones"
                    class="rounded-lg my-1"
                    v-on:change="
                      validaCalificacion(item.Ponderacion, item.calificaciones)
                    "
                    style="max-height: 40px; max-width: 120px;"
                    dense
                    required
                    :rules="rules"
                    outlined
                    type="number"
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td colspan="2" class="font-weight-bold">Total</td>
                <td class="center-item-in-div">
                  <div
                    :class="
                      getColor(
                        getCalificacionFinalConocimientos(
                          apego.escolaridad.calificaciones,
                          apego.conocimientos
                        )
                      )
                        ? 'teal--text text--darken-1'
                        : 'pink--text text--darken-3'
                    "
                    class="font-weight-bold subtitle-1"
                  >
                  {{
                    getCalificacionFinalConocimientos(
                      apego.escolaridad.calificaciones,
                      apego.conocimientos
                    )
                  }}
                  </div>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
        <v-row v-show="!esInterno" class="py-4" dense>
          <v-col :cols="numberOfColumns">
            <label class="text-uppercase">fortalezas</label>
            <v-textarea
              v-model="fortalezas"
              :rows="$vuetify.breakpoint.name === 'xs' ? 1 : 3"
              dense
              outlined
              :required="!esInterno"
              :rules="rulesComentarios"
              class="rounded-lg"
            ></v-textarea>
          </v-col>
          <v-col :cols="numberOfColumns">
            <label class="text-uppercase">oportunidades</label>
            <v-textarea
              v-model="oportunidades"
              :rows="$vuetify.breakpoint.name === 'xs' ? 1 : 3"
              dense
              outlined
              :required="!esInterno"
              :rules="rulesComentarios"
              class="rounded-lg"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
      <div v-show="!tokenInvalido" class="text-center mt-2">
        <v-btn
          class="rounded-pill mb-5"
          depressed
          dark
          color="light-blue lighten-1"
          :loading="loadingCarga"
          :block="$vuetify.breakpoint.name === 'xs' ? true : false"
          @click="loadingCarga = true; saveApego();"
        >
          enviar
          <v-icon right>mdi-send</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-if="tokenInvalido || (!loadingData && !apego)" class="py-6">
      <div class="text-center pink--text text--darken-3 font-weight-bold">
        Tu token ha expirado ó el candidato ya fue evaluado.
      </div>
    </div>
    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import { deleteToken } from "../../../../../store/Funciones/funciones";

export default {
  name: "carga-apego-perfi-de-candidato",
  props: ["idCandidato", "esInterno", "token"],
  components: { SnackBar },
  data() {
    return {
      fortalezas: "",
      oportunidades: "",
      colorBarra: "",
      textoBarra: "",
      muestraBarra: false,
      tokenInvalido: false,
      loadingData: true,
      loadingCarga: false,
      valid: true,
      apego: {},
      rules: [
        (v) => !!v || "", 
        (v) => (v && v > 0) || "",
        (v) => (v != []) || ""
      ],

      rulesComentarios: [(v) => (!!v || this.esInterno) || 'Este campo es obligatorio.'], 
    };
  },
  mounted() {
    this.getApegoCandidato();
  },
  computed: {
    numberOfColumns() {
      if (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      ) {
        return 12;
      } else {
        return 6;
      }
    },
    tokenVal() {
      if (this.esInterno) {
        return localStorage.getItem("token");
      }
      return this.token;
    },
  },
  methods: {
    muestraSnackBar(text, color) {
      this.colorBarra = color;
      this.textoBarra = text;
      this.muestraBarra = true;
    },
    validaCalificacion(valorReal, valorIngresado) {
      let valor = parseFloat(valorIngresado);
      if (isNaN(valor)) {
        this.muestraSnackBar("Ingresa solo números", "pink darken-3");
      } else if (typeof valor == "number" && valor > valorReal) {
        this.muestraSnackBar(
          "No puedes ingresar valores mayores al valor real.",
          "pink darken-3"
        );
      }
    },
    getColor(valor) {
      if (valor >= 80 && valor <= 100) {
        return true;
      }
      return false;
    },
    getCalificacionFinalConocimientos(escolaridad, conocimientos) {
      let valorEscolaridad = 0;
      if (isNaN(parseFloat(escolaridad))) {
        valorEscolaridad = 0;
      } else {
        valorEscolaridad = parseFloat(escolaridad);
      }
      var suma = conocimientos.reduce(function(total, objeto) {
        let valor = 0;
        if (isNaN(parseFloat(objeto.calificaciones))) {
          valor = 0;
        } else {
          valor = parseFloat(objeto.calificaciones);
        }
        return total + valor;
      }, 0);
      return valorEscolaridad + suma;
    },
    getApegoCandidato() {
      let params = {
        Opcion: 5,
        token: this.tokenVal,
        idCandidato: this.idCandidato,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status != "EXPSESSION") {
            this.apego = response.data.candidato;
          } else {
            if(this.esInterno){
              deleteToken();
            } else {
              this.tokenInvalido = true
            }
          }
        })
        .catch((e) => {
          this.colorBarra = "error";
          this.textoBarra = e;
          this.muestaBarra = true;
        });
    },
    saveApego() {
      if (this.$refs.formCargaPerfil.validate()) {
        const params = {
          Opcion: 9,
          token: this.tokenVal,
          apego: this.apego,
          fortalezas: this.fortalezas,
          oportunidades: this.oportunidades,
          idCandidato: this.idCandidato,
          esInterno: this.esInterno
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
            params
          )
          .then((response) => {
            this.loadingCarga = false;
            if (response.data.status != "EXPSESSION") {
              if(response.data.candidato.status_tran == 'OK'){
                if(this.esInterno){
                  this.$emit('cambiaEstatus', this.idCandidato, 6);
                } else {
                  this.loadingData = true;
                  this.getApegoCandidato()
                }
              } else {
                this.muestraSnackBar(response.data.candidato.message, 'pink darken-3')
              }
            } else {
              deleteToken();
            }
          })
          .catch((e) => {
            this.colorBarra = "error";
            this.textoBarra = e;
            this.muestaBarra = true;
          });
      } else {
        this.loadingCarga = false;
        this.muestraSnackBar(
          "Es necesario que llenes todos los campos",
          "pink darken-3"
        );
      }
    },
  },
};
</script>

<style scoped>
.fix-height-text-save-app
  .v-text-field--filled.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--single-line
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  min-height: 30px !important;
}
</style>
