
<template>
  <v-container class="py-1">
    <v-btn class="ma-2" x-small>
      {{$t('Ver Políticas')}}
      <v-icon right color="primary" dark>
        mdi-book-open-variant
      </v-icon>
    </v-btn>
    <v-btn class="ma-2" x-small>
      {{$t('Ver Cupones')}}
      <v-icon right color="primary" dark>
        mdi-cards
      </v-icon>
    </v-btn>
    <v-btn class="ma-2" x-small>
      {{$t('Tutorial ALU')}}
      <v-icon right color="primary" dark>
        mdi-chat-question
      </v-icon>
    </v-btn>
  </v-container>
  <!-- <v-item-group>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-item>
            <v-card>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline">
                    Ver Políticas
                  </div>
                  <v-list-item-subtitle class="mb-3">Actualización 31/08/2021</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                  tile
                  size="40"
                  color="grey"
                ></v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-item>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-item>
            <v-card>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline">
                    Ver Cupones
                  </div>
                  <v-list-item-subtitle class="mb-3">Actualización 31/08/2021</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                  tile
                  size="40"
                  color="grey"
                ></v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-item>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-item>
            <v-card>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline">
                    Tutorial ALU
                  </div>
                  <v-list-item-subtitle class="mb-3">Actualización 31/08/2021</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                  tile
                  size="40"
                  color="grey"
                ></v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group> -->
</template>
<script>
  export default {
    name: 'persona-mejoras-preview',
    components: {
    },
    props: [
      'userId'
    ],
    methods: {
    }
  }
</script>
<style lang="sass" scoped>
</style>