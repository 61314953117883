<template>
    <div v-if="!banderaPropios22 && !banderaArea2">
        <div v-show="loadingTable" class="center-item-in-div-column">
            <v-progress-circular :size="150" :width="15" color="primary" indeterminate></v-progress-circular>
            <label class="overline font-weight-bold">Cargando información...</label>
        </div>

        <div class="centrado">
            <v-btn text @click="toggleTabla">
                <v-icon v-if="mostrarTabla">mdi-close-box-multiple-outline</v-icon>
                <v-icon v-else> mdi-open-in-app</v-icon>
                <b>Objetivos Propios:</b>
            </v-btn>
        </div>
        <p></p>

        <div class="rounded-lg pb-4 elevation-0" v-show="!loadingTable">
            <v-simple-table id="miTabla" v-if="mostrarTabla" dense style="background-color: transparent;">
                <thead>
                    <meta charset="utf-8">
                    <tr>
                        <th class="primary rounded-simple-table-head-l" colspan="7"></th>
                        <th class="primary white--text text-uppercase font-weight-bold text-center" colspan="4">Valor
                            real &uacute;ltimo trimestre</th>
                        <th class="primary rounded-simple-table-head-r" colspan="2"></th>
                    </tr>
                    <tr>
                        <th class="primary white--text text-uppercase font-weight-bold text-center"
                            v-for="item in formattedTablaDepartamento" :key="item.id">{{ item.text }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in combinedData" :key="index">
                        <td style="text-align: center;">{{ item.ID_OP }}</td>
                        <td>{{ item.ObjetivoPropio }}</td>
                        <td style="text-align: center;">{{ item.VO }}</td>
                        <td style="text-align: center;">{{ item.LE }}</td>
                        <td style="text-align: center;">{{ item.Ponderacion }}</td>
                        <td style="text-align: center;">{{ item.TipoIndicador == "BALANCE" ? 'BSC' : item.TipoIndicador
                            }}</td>
                        <td style="text-align: center;">{{ item.TipoCalculo }}</td>
                        <td style="text-align: center;">
                            <input class="small-input" :disabled="inputsDisabled" v-model="ValorPrimerValorBase[index]"
                                @input="updateValue(index, 'ValorPrimer', $event)" type="number"
                                placeholder="Ingrese valor" />
                        </td>
                        <td style="text-align: center;">
                            <input class="small-input" :disabled="inputsDisabled" v-model="ValorSegundoValorBase[index]"
                                @input="updateValue(index, 'ValorSegundo', $event)" type="number"
                                placeholder="Ingrese valor" />
                        </td>
                        <td style="text-align: center;">
                            <input class="small-input" :disabled="inputsDisabled" v-model="ValorTerceroValorBase[index]"
                                @input="updateValue(index, 'ValorTercero', $event)" type="number"
                                placeholder="Ingrese valor" />
                        </td>
                        <td :style="getCellStyle(item.Desemp)">{{ item.dReal.toFixed(2) }}</td>
                        <td style="text-align: center;">{{ item.Desemp.toFixed(2) }}</td>
                        <td style="text-align: center;">{{ item.Puntos.toFixed(2) }}</td>
                    </tr>
                    <!-- Fila para Ponderación Total -->
                    <tr>
                        <td colspan="4"></td>
                        <td class="font-weight-bold text-center">Ponderación Total: {{ this.ponderacionSuma }}</td>

                    </tr>
                    <tr>
                        <td colspan="13">
                            <!-- <div class="text-right pt-4">
                                <v-btn class="primary white--text text-uppercase font-weight-bold text-center"
                                    :disabled="buttonDisabled || gTrabajo === '0'" @click="showConfirmDialog = true">Asignar</v-btn>
                            </div> -->
                            <div class="text-right pt-4">
                                <v-btn class="primary white--text text-uppercase font-weight-bold text-center"
                                    :disabled="buttonDisabled || gTrabajo === '0'" @click="showConfirmDialog = true">Guardar</v-btn>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
            <div class="text-right pt-4">
                <label class="font-weight-bold mr-3">Calificación del trimestre: {{ dSumaPuntosDepto.toFixed(2)
                    }}</label>
            </div>
        </div>

        <!-- Dialogo de confirmación -->
        <v-dialog v-model="showConfirmDialog" max-width="500">
            <v-card>
                <v-card-title class="headline">Confirmar Asignación</v-card-title>
                <v-card-text>¿Está seguro que desea insertar los valores reales para este trimestre?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="showConfirmDialog = false">Cancelar</v-btn>
                    <v-btn color="blue darken-1" text @click="confirmAssignment">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Snackbar de éxito -->
        <v-snackbar v-model="showSuccessSnackbar" :timeout="3000" bottom right>
            Valores asignados correctamente.
        </v-snackbar>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "verObjetivosNew",
    props: [
        "Mes1", "Mes2", "Mes3", "iMes", "numEmpleado", "trimestre", "anio",
        "PuestoColabValorBase", "PrimerMesNumValorBase", "SegundoMesNumValorBase",
        "TercerMesNumValorBase", "iNumDeptoValorBase", "currentYear", "currentMonth", "mesTabla", "TrimestreValorBase", "trimestreFinal",
        "anioTabla", "bandera3", "banderaPropios22", "banderaArea2", "gTrabajo"
    ],
    data() {
        return {
            descTest: ['valor1', 'valor2', 'valor3', 'valor4', 'valor5', 'valor6'],
            combinedData: [],
            ValorPrimerValorBase: [],
            ValorSegundoValorBase: [],
            ValorTerceroValorBase: [],
            dSumaPuntosDepto: 0,
            loadingTable: true,
            mostrarTabla: true,
            inputsDisabled: false,
            buttonDisabled: false,
            tablaDepartamentos: [
                { id: 1, text: 'ID' },
                { id: 2, text: 'Objetivo Específico' },
                { id: 3, text: 'VO' },
                { id: 4, text: 'LE' },
                { id: 5, text: 'Ponderación' },
                { id: 6, text: 'Tipo' },
                { id: 7, text: 'Tipo de Cálculo' },
                { id: 8, text: 'Mes 1' },
                { id: 9, text: 'Mes 2' },
                { id: 10, text: 'Mes 3' },
                { id: 11, text: 'Resultado' },
                { id: 12, text: 'Desempeño' },
                { id: 13, text: 'Puntos' }
            ],
            showConfirmDialog: false,
            showSuccessSnackbar: false
        };
    },
    async mounted() {
        await this.getCantidades();
        await this.getTotalBonoAsig();
        await this.validacion1();
        await this.getDatos();
        //console.log('VALOR DE MES Y AÑO DE OBJ NEW:', this.currentMonth, '/año', this.currentYear, 'triemestr:', this.TrimestreValorBase)
    },
    computed: {
        formattedTablaDepartamento() {
            return this.tablaDepartamentos.map(item => {
                if (item.id === 8) item.text = ` ${this.Mes1}`;
                if (item.id === 9) item.text = ` ${this.Mes2}`;
                if (item.id === 10) item.text = ` ${this.Mes3}`;
                return item;
            });
        }
    },
    methods: {
        getCellStyle(desemp) {
            if (desemp === 0) {
                return { textAlign: 'center', background: '#F78181', color: 'white', borderRadius: '25px', padding: '1px' }; // rojo
            } else if (desemp < 100 && desemp > 0) {
                return { textAlign: 'center', background: '#F4FA58', color: 'black', borderRadius: '25px', padding: '1px' }; // amarillo
            } else if (desemp === 100) {
                return { textAlign: 'center', background: '#81F781 ', color: 'white', borderRadius: '25px', padding: '1px' }; // verde
            }
            return { textAlign: 'center', borderRadius: '5px', padding: '5px' };
        },
        toggleTabla() {
            this.mostrarTabla = !this.mostrarTabla;
        },

        async enviar() {
            //console.log('Valor de Puntos summa °°°°°°°°°°°', this.dSumaPuntosDepto);
            this.$emit('valoresObjetivosEvent', this.dSumaPuntosDepto.toFixed(2));
        },
        async getDatos() {
            try {
                this.loadingTable = true;
                if (this.totalAsignaciones != 0) {
                    await this.getVerBonoAsig();
                    await this.getBonoPropio();
                    await this.verificarMeses();
                    await this.validacionesTest();
                    this.mergeData();
                    this.enviar();
                } else {
                    this.dSumaPuntosDepto = 0;
                    this.enviar();
                }
                this.loadingTable = false;
            } catch (error) {
                this.dSumaPuntosDepto = 0;
                this.enviar();
                console.error('Error al cargar los datos:', error);
                this.loadingTable = false;
            }
        },
        async getTotalBonoAsig() {
            //console.log('Puesto de colaborador base:', this.PuestoColabValorBase);
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/ver-totalAsig/${this.PuestoColabValorBase}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                this.totalAsignaciones = response.data.data.map(item => item.total);
                console.log('valor de total de asignaciones: ', this.totalAsignaciones);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async getCantidades() {
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/ver-cantidades/${this.numEmpleado}/${this.trimestreFinal}/${this.iMes}/${this.anioTabla}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                this.Cantidades = response.data.data.map(item => item.Cantidad);
                console.log('Cantidades $$$$$4', this.Cantidades, this.numEmpleado, this.trimestre, this.iMes);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async getIdsMeses() {
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/ver-ids-Meses/${this.numEmpleado}/${this.trimestreFinal}/${this.anioTabla}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                this.Ids_Indicador = response.data.data.map(item => item.Ids_Indicador);
                console.log('ids indicadores porpios: ', this.Ids_Indicador)
                this.ValorPrimerNew = response.data.data.map(item => item.ValorPrimer);
                this.ValorSegundoNew = response.data.data.map(item => item.ValorSegundo);
                this.ValorTerceroNew = response.data.data.map(item => item.ValorTercero);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async getBonoPropio() {
            //console.log('###########################################')
            const idsString = this.Ids_Obj.join(',');
            //console.log('LOS IDS EN getBonoPropio', idsString);
            const idsArray = idsString.split(',').map(id => parseInt(id, 10));
            //console.log('LOS IDS arras EN getBonoPropio', idsArray);
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/ver-bonoPropios?ids=${idsString}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                if (response && response.data && Array.isArray(response.data)) {
                    const sortedData = response.data.sort((a, b) => {
                        return idsArray.indexOf(a.ID_OP) - idsArray.indexOf(b.ID_OP);
                    });
                    this.VOs = sortedData.map(item => item.VO);
                    this.LEs = sortedData.map(item => item.LE);
                    this.ObjetivoPropioNEW = sortedData.map(item => item.ObjetivoPropio);
                    this.Unidades = sortedData.map(item => item.Unidad);
                    this.sortedData = sortedData.map(item => ({
                        ...item,
                        ObjetivoPropio: item.ObjetivoPropio // Se ha eliminado decodeURIComponent
                    }));

                    // console.log('valor de VO en propios:', this.VOs);
                    // console.log('valor de LE en propios:', this.LEs);
                } else {
                    console.log('Formato inesperado de datos:', response.data);
                }
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        },

        // async getBonoPropio() {
        //     console.log('###########################################')
        //     const idsString = this.Ids_Obj.join(',');
        //      console.log('LOS IDS EN getBonoPropio', idsString);
        //     const idsArray = idsString.split(',').map(id => parseInt(id, 10));
        //      console.log('LOS IDS arras EN getBonoPropio', idsArray);
        //     try {
        //         const response = await axios.get(`${this.$store.getters.getHostNode}/api/ver-bonoPropios?ids=${idsString}`, {
        //             headers: {
        //                 Authorization: localStorage.getItem("token"),
        //             },
        //         });
        //         if (response && response.data && Array.isArray(response.data)) {
        //             const sortedData = response.data.sort((a, b) => {

        //                 return idsArray.indexOf(a.ID_OP) - idsArray.indexOf(b.ID_OP);
        //             });
        //             this.VOs = sortedData.map(item => item.VO);
        //             this.LEs = sortedData.map(item => item.LE);
        //             this.ObjetivoPropioNEW = sortedData.map(item => item.ObjetivoPropio);
        //             this.Unidades = sortedData.map(item => item.Unidad);
        //             this.sortedData = sortedData.map(item => ({
        //                 ...item,
        //                 ObjetivoPropio: decodeURIComponent(escape(item.ObjetivoPropio))

        //             }));


        //         } else {
        //             console.log('Formato inesperado de datos:', response.data);
        //         }
        //     } catch (error) {
        //         console.error('Error al cargar los datos:', error);
        //     }
        // },
        async getVerBonoAsig() {
            console.log('valor del puesto del colaborar:' ,this.PuestoColabValorBase);
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/ver-bonoAsign/${this.PuestoColabValorBase}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                this.Ids_Obj = response.data.data.map(item => item.ID_Obj);
                //this.Ponderaciones = response.data.data.map(item => item.Ponderacion);
                this.Ponderaciones = response.data.data.map(item => parseFloat(item.Ponderacion) || 0); // Convertir a número y manejar valores no numéricos

                // Sumar los valores de Ponderacion
                this.ponderacionSuma = this.Ponderaciones.reduce((acc, val) => acc + val, 0);
                this.TipoCalculos = response.data.data.map(item => item.TipoCalculo);
                this.TipoIndicadores = response.data.data.map(item => item.Tipo);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async verificarMeses() {
            console.log('valor del mes de tabla:', this.currentMonth);
            if (
                this.Cantidades.includes(0) &&  // Verifica si el array 'Cantidades' contiene al menos un '0'
                this.totalAsignaciones.some(asignacion => asignacion != 0) &&  // Verifica si al menos un valor de 'totalAsignaciones' es diferente de '0'
                (this.currentMonth === 1 || this.currentMonth === 4 || this.currentMonth === 7 || this.currentMonth === 10)  // Verifica si el mes actual es 1, 4, 7 o 10
            ) {
                //console.log('ENTRO AL PRIMERO');
                // Si todas las condiciones son verdaderas
                this.ValorPrimerValorBase = this.Ids_Obj.map(() => 0);
                this.ValorSegundoValorBase = this.Ids_Obj.map(() => 0);
                this.ValorTerceroValorBase = this.Ids_Obj.map(() => 0);

                this.inputsDisabled = false;
                this.buttonDisabled = false;
            } else {
                //console.log('entro al segundo')
                // Si alguna de las condiciones no se cumple
                await this.getObjDepa3();
                this.inputsDisabled = true;
                this.buttonDisabled = true;
            }

            //-----------------

            //Esta es la validacion para que solo deje agregar en los meses designados 
            // if (
            //     this.Cantidades == 0 &&
            //     this.totalAsignaciones != 0 &&
            //     (this.currentMonth === 1 || this.currentMonth === 4 || this.currentMonth === 7 || this.currentMonth === 10)
            // ) {
            //     // Si todas las condiciones son verdaderas
            //     this.ValorPrimerValorBase = this.Ids_Obj.map(() => 0);
            //     this.ValorSegundoValorBase = this.Ids_Obj.map(() => 0);
            //     this.ValorTerceroValorBase = this.Ids_Obj.map(() => 0);
            //     console.log('ENTRO AL PRIMERO');

            //     this.inputsDisabled = false;
            //     this.buttonDisabled = false;
            // } else {
            //     console.log('entro al segundo')
            //     // Si alguna de las condiciones no se cumple
            //     await this.getObjDepa3();
            //     this.inputsDisabled = true;
            //     this.buttonDisabled = true;
            // }

        },
        async getObjDepa3() {
    const idsString = this.Ids_Obj.join(',');
    // console.log('IDS DE VALORES DEPA 3 indicadores $$', idsString);
    // console.log('Valor del num Empleado:', this.numEmpleado);
    
    try {
        // Realizamos la solicitud GET a la API
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/ver-valMeses?ids=${idsString}&trimestre=${this.trimestreFinal}&anio=${this.anioTabla}&iemp_Keyemp=${this.numEmpleado}&iMes=${this.iMes}`, {
            headers: {
                Authorization: localStorage.getItem("token"),
            },
        });

        // Verificamos si la respuesta contiene datos y es un array
        if (response && response.data && Array.isArray(response.data)) {
            // Asignamos valores, verificando si alguno de los valores es undefined, en cuyo caso se le asigna 0
            this.ValorPrimerValorBase = response.data.map(item => item.ValorPrimer !== undefined ? item.ValorPrimer : 0);
            this.ValorSegundoValorBase = response.data.map(item => item.ValorSegundo !== undefined ? item.ValorSegundo : 0);
            this.ValorTerceroValorBase = response.data.map(item => item.ValorTercero !== undefined ? item.ValorTercero : 0);
            this.mes = response.data.map(item => item.Mes !== undefined ? item.Mes : 0);  // Asegurarse de que Mes esté presente

            // Logging para verificar los valores
             console.log('IF VALOR DE MES 1 %%%%%%%%%%%%', this.ValorPrimerValorBase);
            // console.log('IF MES:', this.mes);
        } else {
            // Si no hay datos en la respuesta, asignar 0 para todos los valores
            this.ValorPrimerValorBase = this.Ids_Obj.map(() => 0);
            this.ValorSegundoValorBase = this.Ids_Obj.map(() => 0);
            this.ValorTerceroValorBase = this.Ids_Obj.map(() => 0);
            // console.log('ELSE DE DEPA3:', this.ValorPrimerValorBase);
        }
    } catch (error) {
        // Si hay un error, asignar 0 para todos los valores
        this.ValorPrimerValorBase = this.Ids_Obj.map(() => 0);
        this.ValorSegundoValorBase = this.Ids_Obj.map(() => 0);
        this.ValorTerceroValorBase = this.Ids_Obj.map(() => 0);
        // console.log('CATCH DE DEPA 3', this.ValorPrimerValorBase);
    }
},

        // async getObjDepa3() {
        //     const idsString = this.Ids_Obj.join(',');
        //     console.log('IDS DE VALORES DEPA 3', idsString);
        //     console.log('valor del num Empleado:', this.numEmpleado);
        //     try {
        //         const response = await axios.get(`${this.$store.getters.getHostNode}/api/ver-valMeses?ids=${idsString}&trimestre=${this.trimestre}&anio=${this.anioTabla}&iemp_Keyemp=${this.numEmpleado}&iMes=${this.currentMonth}`, {
        //             headers: {
        //                 Authorization: localStorage.getItem("token"),
        //             },
        //         });
        //         if (response && response.data && Array.isArray(response.data)) {
        //             this.ValorPrimerValorBase = response.data.map(item => item.ValorPrimer !== undefined ? item.ValorPrimer : 0);
        //             this.ValorSegundoValorBase = response.data.map(item => item.ValorSegundo !== undefined ? item.ValorSegundo : 0);
        //             this.ValorTerceroValorBase = response.data.map(item => item.ValorTercero !== undefined ? item.ValorTercero : 0);
        //             this.mes = response.data.map(item => item.mes);
        //             console.log('IF VALOR DE MES 1 %%%%%%%%%%%%', this.ValorPrimerValorBase);
        //             console.log('IF MEEEEEESSS', this.mes);
        //         } else {
        //             this.ValorPrimerValorBase = this.Ids_Obj.map(() => 0);
        //             this.ValorSegundoValorBase = this.Ids_Obj.map(() => 0);
        //             this.ValorTerceroValorBase = this.Ids_Obj.map(() => 0);
        //             console.log('ELSE DE DEPA3:', this.ValorPrimerValorBase);
        //         }
        //     } catch (error) {
        //         this.ValorPrimerValorBase = this.Ids_Obj.map(() => 0);
        //         this.ValorSegundoValorBase = this.Ids_Obj.map(() => 0);
        //         this.ValorTerceroValorBase = this.Ids_Obj.map(() => 0);
        //         console.log('CATCH DE DEPA 3', this.ValorPrimerValorBase);
        //     }
        // },
        updateValue(index, type, event) {
            const value = parseFloat(event.target.value) || 0;
            if (type === 'ValorPrimer') {
                this.$set(this.ValorPrimerValorBase, index, value);
            } else if (type === 'ValorSegundo') {
                this.$set(this.ValorSegundoValorBase, index, value);
            } else if (type === 'ValorTercero') {
                this.$set(this.ValorTerceroValorBase, index, value);
            }
            this.recalcularValores();
        },
        recalcularValores() {
            // console.log('VALORES DE LOS TIPOS CALCULOS', this.TipoCalculos);

            if (this.ValorPrimerValorBase.length === this.ValorSegundoValorBase.length && this.ValorPrimerValorBase.length === this.ValorTerceroValorBase.length) {
                this.dReal = this.Ids_Obj.map((_, index) => {
                    const valor1 = this.ValorPrimerValorBase[index] || 0;
                    const valor2 = this.ValorSegundoValorBase[index] || 0;
                    const valor3 = this.ValorTerceroValorBase[index] || 0;
                    const tipoCalculo = this.TipoCalculos[index];

                    if (this.iMes === this.PrimerMesNumValorBase) {
                        return valor1; // Solo el primer mes
                    } else if (this.iMes === this.SegundoMesNumValorBase) {
                        if (tipoCalculo === "Promedio") {
                            return (valor1 + valor2) / 2; // Promedio de los dos primeros meses
                        } else {
                            return valor2 !== 0 ? valor2 : valor1; // Segundo mes o primero si el segundo es 0
                        }
                    } else if (this.iMes === this.TercerMesNumValorBase) {
                        if (tipoCalculo === "Promedio") {
                            return (valor1 + valor2 + valor3) / 3; // Promedio de los tres meses
                        } else {
                            if (valor3 !== 0) {
                                return valor3;
                            } else if (valor2 !== 0) {
                                return valor2;
                            } else {
                                return valor1;
                            }
                        }
                    }
                });

                this.mergeData();
                this.calcularDesempenoYPuntos();
            } else {
                console.error('Las longitudes de los arrays no coinciden');
            }
        },



        calcularDesempenoYPuntos() {
            //console.log('VALORES DE LOS TIPOS CALUCLUOS 333333333333333333333', this.TipoCalculos);
            if (this.LEs.length === this.VOs.length && this.LEs.length === this.dReal.length && this.LEs.length === this.Ponderaciones.length) {
                this.Desemp = this.LEs.map((le, index) => {
                    const vo = this.VOs[index];
                    const dr = this.dReal[index];
                    if ((le - vo) !== 0) {
                        const desemp = ((le - dr) / (le - vo)) * 100;
                        return Math.min(Math.max(desemp, 0), 100);
                    } else {
                        return 0;
                    }
                });
                this.Puntos = this.Desemp.map((desemp, index) => (desemp * this.Ponderaciones[index]) / 100);
                this.dSumaPuntosDepto = this.Puntos.reduce((acc, curr) => acc + curr, 0);
                this.combinedData = this.sortedData.map((item, index) => ({
                    ...item,
                    dReal: this.dReal[index],
                    Desemp: this.Desemp[index],
                    Puntos: this.Puntos[index]
                }));
            } else {
                console.error('Las longitudes de los arrays no coinciden 2');
            }
            this.mergeData();
        },
        mergeData() {
            if (!this.sortedData || this.sortedData.length === 0) {
                console.error('No hay datos para combinar');
                return;
            }
            const mergedData = this.sortedData.map((item, index) => ({
                ...item,
                Ponderacion: this.Ponderaciones[index],
                TipoCalculo: this.TipoCalculos[index],
                TipoIndicador: this.TipoIndicadores[index],
                ValorPrimer: this.ValorPrimerValorBase[index],
                ValorSegundo: this.ValorSegundoValorBase[index],
                ValorTercero: this.ValorTerceroValorBase[index],
                dReal: this.dReal[index] || 0,
                Desemp: this.Desemp[index] || 0,
                Puntos: this.Puntos[index] || 0
            }));
            this.combinedData = mergedData;
            //console.log('VALORES DE LOS TIPOS CALUCLUOS 4444444444444444444', this.TipoCalculos);
        },
        async validacion1() {
            if (this.totalAsignaciones == 0) {
                try {
                    const response = await axios.get(`${this.$store.getters.getHostNode}/api/ver-ps_Puestos/${this.PuestoColabValorBase}`, {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    });
                    this.Descripciones = response.data.data.map(item => item.DESCR);
                    this.IdsPuesto = response.data.data.map(item => item.JOBCODE);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        },
        async validacionesTest() {
             // Mostrar todas las variables importantes al inicio del método
    // console.log("Variables iniciales recibidas:");
    // console.log("iMes:", this.iMes);
    // console.log("PrimerMesNumValorBase:", this.PrimerMesNumValorBase);
    // console.log("SegundoMesNumValorBase:", this.SegundoMesNumValorBase);
    // console.log("TercerMesNumValorBase:", this.TercerMesNumValorBase);
    // console.log("TipoCalculos:", this.TipoCalculos);
    // console.log("ValorPrimerValorBase:", this.ValorPrimerValorBase);
    // console.log("ValorSegundoValorBase:", this.ValorSegundoValorBase);
    // console.log("ValorTerceroValorBase:", this.ValorTerceroValorBase);
    // console.log("LEs:", this.LEs);
    // console.log("VOs:", this.VOs);
    // console.log("Ponderaciones:", this.Ponderaciones);
    function segundoIF(valor) {
        return valor === null || valor === "Promedio" || valor === "";
    }

    // Convertir valores vacíos a 0
    this.ValorPrimerValorBase = this.ValorPrimerValorBase.map(val => val === "" ? 0 : val);
    this.ValorSegundoValorBase = this.ValorSegundoValorBase.map(val => val === "" ? 0 : val);
    this.ValorTerceroValorBase = this.ValorTerceroValorBase.map(val => val === "" ? 0 : val);

    // Inicializar dReal como un array vacío para almacenar los resultados por índice
    this.dReal = [];

    // Calcular dReal para cada índice individualmente, basándose en TipoCalculos
    if (this.iMes === this.PrimerMesNumValorBase) {
        // console.log('ENTRO EN EL PRIMERO Propios');
        this.dReal = [...this.ValorPrimerValorBase];
    } else if (this.iMes === this.SegundoMesNumValorBase) {
        // console.log('ENTRO EN EL SEGUNDO Propio');
        
        this.dReal = this.ValorPrimerValorBase.map((valor, index) => {
            // Si el valor de TipoCalculos en ese índice cumple con segundoIF
            if (segundoIF(this.TipoCalculos[index])) {
                // Calcular promedio entre ValorPrimerValorBase y ValorSegundoValorBase
                return (valor + this.ValorSegundoValorBase[index]) / 2;
            } else {
                // Asignar valor directo de ValorSegundoValorBase si no es cero, o de ValorPrimerValorBase si lo es
                return this.ValorSegundoValorBase[index] !== 0 ? this.ValorSegundoValorBase[index] : valor;
            }
        });
        // console.log("dReal calculado en segundo mes, índice por índice:", this.dReal);

    } else if (this.iMes === this.TercerMesNumValorBase) {
        // console.log('ENTRO EN EL TERCERO propios');

        this.dReal = this.ValorPrimerValorBase.map((valor, index) => {
            // Verificar cada índice en TipoCalculos
            if (segundoIF(this.TipoCalculos[index])) {
                // Calcular promedio entre los tres valores bases
                return (valor + this.ValorSegundoValorBase[index] + this.ValorTerceroValorBase[index]) / 3;
            } else {
                  // Asignar valor del mes correspondiente basado en la disponibilidad
                  if (this.ValorTerceroValorBase[index] !== 0) {
                    return this.ValorTerceroValorBase[index];
                } else if (this.ValorSegundoValorBase[index] !== 0) {
                    return this.ValorSegundoValorBase[index];
                } else {
                    return valor; // Si ambos son 0, usa ValorPrimerValorBase explícito
                }
            }
        });
        // console.log("dReal calculado en tercer mes, índice por índice:", this.dReal);
    }

    // console.log("Valor final de dReal:", this.dReal);

    // Verificar longitud de arrays antes de calcular desempeño y puntos
    if (this.LEs.length === this.VOs.length && this.LEs.length === this.dReal.length && this.LEs.length === this.Ponderaciones.length) {
        this.Desemp = this.LEs.map((le, index) => {
            const vo = this.VOs[index];
            const dr = this.dReal[index];
            if ((le - vo) !== 0) {
                const desemp = ((le - dr) / (le - vo)) * 100;
                return Math.min(Math.max(desemp, 0), 100);
            } else {
                return 0;
            }
        });
        // console.log("Desempeño calculado (Desemp):", this.Desemp);

        this.Puntos = this.Desemp.map((desemp, index) => (desemp * this.Ponderaciones[index]) / 100);
        // console.log("Puntos calculados:", this.Puntos);

        this.dSumaPuntosDepto = this.Puntos.reduce((acc, curr) => acc + curr, 0);
        // console.log("Suma total de puntos de departamento (dSumaPuntosDepto):", this.dSumaPuntosDepto);

        this.combinedData = this.sortedData.map((item, index) => ({
            ...item,
            dReal: this.dReal[index],
            Desemp: this.Desemp[index],
            Puntos: this.Puntos[index]
        }));
        // console.log("Datos combinados (combinedData):", this.combinedData);
    } else {
        console.error('Las longitudes de los arrays no coinciden');
    }
},

        // async validacionesTest() {
        //     function segundoIF(valor) {
        //         return valor === null || valor === "Promedio" || valor === "";
        //     }
        //     this.ValorPrimerValorBase = this.ValorPrimerValorBase.map(val => val === "" ? 0 : val);
        //     this.ValorSegundoValorBase = this.ValorSegundoValorBase.map(val => val === "" ? 0 : val);
        //     this.ValorTerceroValorBase = this.ValorTerceroValorBase.map(val => val === "" ? 0 : val);

        //     if (this.iMes === this.PrimerMesNumValorBase) {
        //         console.log('ENTRO EN EL PRIMERO Propios');
        //         this.dReal = [...this.ValorPrimerValorBase];
        //     } else if (this.iMes === this.SegundoMesNumValorBase) {
        //          console.log('ENTRO EN EL SEGUNDO Propio');
        //         if (this.TipoCalculos.some(segundoIF)) {
        //             this.dReal = this.ValorPrimerValorBase.map((valor, index) => (valor + this.ValorSegundoValorBase[index]) / 2);
        //         } else {
        //             this.dReal = this.ValorSegundoValorBase.some(value => value != 0) ? [...this.ValorSegundoValorBase] : [...this.ValorPrimerValorBase];
        //         }
        //     } else if (this.iMes === this.TercerMesNumValorBase) {
        //          console.log('ENTRO EN EL TERCERO propios');
        //         // console.log('mes1:', this.ValorPrimerValorBase);
        //         // console.log('mes2:', this.ValorSegundoValorBase);
        //         // console.log('mes3:', this.ValorTerceroValorBase);
        //         // console.log('tipos de calculos:', this.TipoCalculos);
        //         if (this.TipoCalculos.some(segundoIF)) {
        //             this.dReal = this.ValorPrimerValorBase.map((valor, index) => (valor + this.ValorSegundoValorBase[index] + this.ValorTerceroValorBase[index]) / 3);
        //         } else {
        //             if (Array.isArray(this.ValorTerceroValorBase) && this.ValorTerceroValorBase.length > 0) {
        //                 this.dReal = this.ValorTerceroValorBase;
        //             } else if (Array.isArray(this.ValorSegundoValorBase) && this.ValorSegundoValorBase.length > 0) {
        //                 this.dReal = this.ValorSegundoValorBase;
        //             } else if (Array.isArray(this.ValorPrimerValorBase) && this.ValorPrimerValorBase.length > 0) {
        //                 this.dReal = this.ValorPrimerValorBase;
        //             }
        //         }
        //     }
        //     // console.log('rREAL DE OBJETIVOS NEW:', this.dReal);
        //     // console.log('Valores de dReal:', this.dReal);
        //     // console.log('VO:', this.VOs);
        //     // console.log('LE:', this.LEs);
        //     // console.log('POnderaciones:', this.Ponderaciones);

        //     if (this.LEs.length === this.VOs.length && this.LEs.length === this.dReal.length && this.LEs.length === this.Ponderaciones.length) {
        //         this.Desemp = this.LEs.map((le, index) => {
        //             const vo = this.VOs[index];
        //             const dr = this.dReal[index];
        //             if ((le - vo) !== 0) {
        //                 const desemp = ((le - dr) / (le - vo)) * 100;
        //                 return Math.min(Math.max(desemp, 0), 100);
        //             } else {
        //                 return 0;
        //             }
        //         });
        //         this.Puntos = this.Desemp.map((desemp, index) => (desemp * this.Ponderaciones[index]) / 100);
        //         this.dSumaPuntosDepto = this.Puntos.reduce((acc, curr) => acc + curr, 0);
        //         this.combinedData = this.sortedData.map((item, index) => ({
        //             ...item,
        //             dReal: this.dReal[index],
        //             Desemp: this.Desemp[index],
        //             Puntos: this.Puntos[index]
        //         }));
        //     } else {
        //         console.error('Las longitudes de los arrays no coinciden 3');
        //     }
        // },
       
        async confirmAssignment() {
            this.showConfirmDialog = false;
            this.inputsDisabled = true;
                this.buttonDisabled = true;

            // Crear una lista de objetos para ordenar por Id_Indicador
            const combinedData = this.Ids_Obj.map((id, index) => ({
                Id_Indicador: id,
                TipoCalculo: this.TipoCalculos[index],
                ValorPrimer: this.ValorPrimerValorBase[index],
                ValorSegundo: this.ValorSegundoValorBase[index],
                ValorTercero: this.ValorTerceroValorBase[index],
                Ponderacion: this.Ponderaciones[index],
                VO: this.VOs[index],
                LE: this.LEs[index],
                DescObjetivo: this.ObjetivoPropioNEW[index],
                Unidad: this.Unidades[index],
                Anio: this.anioTabla,
                Trimestre: this.trimestreFinal,
                Mes: this.mesTabla,
                NumeroPersonal: this.numEmpleado,
                Depto: this.PuestoColabValorBase,
                Id_Puesto: this.iNumDeptoValorBase
            }));

            // Enviar los datos sin envolverlos en una clave 'data'
            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/insert-bono-asig`, combinedData, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                console.log('Todos los registros se insertaron correctamente:', response.data);
                this.showSuccessSnackbar = true;
                this.inputsDisabled = true;
                this.buttonDisabled = true;
                this.enviar();
            } catch (error) {
                console.error('Error al insertar los datos:', error);
                this.showSuccessSnackbar = false;
                this.inputsDisabled = false;
                this.buttonDisabled = false;
            }
        }






    }
};
//Mensaje
</script>

<style>
.rounded-simple-table-head-l {
    border-top-left-radius: 22px !important;
}

.rounded-simple-table-head-r {
    border-top-right-radius: 22px !important;
}

.back-card-objectives {
    background: rgb(255, 255, 255) !important;
    background: linear-gradient(301deg,
            rgba(255, 255, 255, 1) 15%,
            rgba(201, 78, 148, 0.7344071417629552) 45%,
            rgba(255, 255, 255, 1) 100%) !important;
}

.centrado {
    text-align: center;
    font-size: 18px;
}

.small-input {
    width: 60px;
    padding: 2px;
    font-size: 12px;
}
</style>
