
<template>
    
  <v-timeline
    align-top
    dense
  >
    <v-timeline-item
      icon="mdi-gift"
      color="grey"
      fill-dot
      disabled
    >
      <v-row class="pt-1">
        <v-col cols="4">
          <strong>22/06/2022</strong>
        </v-col>
        <v-col>
          <strong>Cumpliste 2 años en la empresa</strong>
          <div class="text-caption">
            Tienes próxima una recompensa de <i>"cupón de 600 pesos en productos urrea"</i>
          </div>
        </v-col>
      </v-row>
    </v-timeline-item>

    <v-timeline-item
      icon="mdi-brain"
      color="green"
      fill-dot
    >
      <v-row class="pt-1">
        <v-col cols="4">
          <strong>05/06/2021 - 2pm</strong>
        </v-col>
        <v-col>
          <strong>Registraste una nueva mejora</strong>
          <div class="text-caption">
            Entérate dividido en comunicado / noticia
          </div>
        </v-col>
      </v-row>
    </v-timeline-item>

    <v-timeline-item
      icon="mdi-cake"
      color="primary"
      fill-dot
    >
      <v-row class="pt-1">
        <v-col cols="4">
          <strong>22/06/2021</strong>
        </v-col>
        <v-col>
          <strong>Cumpliste 1 año en la empresa</strong>
          <div class="text-caption mb-2">
            Un día como hoy te volviste mas <strong>poderoso</strong>. *momentos urrea*
          </div>
          <div class="d-inline-flex">
            <v-img height="40px" width="60px" src="https://picsum.photos/60/40"></v-img>
            <v-img height="40px" width="60px" src="https://picsum.photos/60/40"></v-img>
            <v-img height="40px" width="60px" src="https://picsum.photos/60/40"></v-img>
          </div>
        </v-col>
      </v-row>
    </v-timeline-item>

    <v-timeline-item
      icon="mdi-charity"
      color="pink"
      fill-dot
    >
      <v-row class="pt-1">
        <v-col cols="4">
          <strong>02/07/2021 - 5pm</strong>
        </v-col>
        <v-col>
          <strong>Recibiste reconocimiento de un compañero</strong>
          <div class="text-caption mb-2">
            Tu <strong>pasión</strong> se siente. De: Diana Gonzalez.
          </div>
        </v-col>
      </v-row>
    </v-timeline-item>

    <v-timeline-item
      icon="mdi-road-variant"
      color="secondary"
      fill-dot
    >
      <v-row class="pt-1">
        <v-col cols="4">
          <strong>12/06/2021 - 9am</strong>
        </v-col>
        <v-col>
          <strong>Tu camino comienza aquí</strong>
          <div class="text-caption">
            Esperamos poder compartir numerosos momentos contigo
          </div>
        </v-col>
      </v-row>
    </v-timeline-item>
  </v-timeline>
  
</template>
<script>
  export default {
    name: 'persona-timeline',
    components: {
    },
    props: [
      'userId'
    ],
    methods: {
    }
  }
</script>
<style lang="sass" scoped>
  strong
    font-size: 14px
  ::v-deep
    .v-timeline::before
      // left: calc(30px - 1px) !important
    .v-timeline-item__body
      max-width: 100% !important
    // .v-timeline-item__divider
    //   margin-left: -20px
</style>