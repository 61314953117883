<template>
  <v-dialog v-model="muestraUsuariosPerfil" scrollable persistent>
    <v-card class="rounded-lg">
      <v-card-title class="primary white--text subtitle-1">
        Usuarios con acceso. [{{ sistemaDesc }}:
        <strong>{{ perfilDesc }}</strong> ]
        <v-spacer></v-spacer>
        <v-btn text class="font-weight-bold" dark @click="showFindNewMembers = true">
          <v-icon left>mdi-plus</v-icon>
          añadir colabolarador
        </v-btn>
        <v-btn text class="font-weight-bold" dark @click="$emit('cierraAdmPerfilUsr')">
          cerrar
        </v-btn>
      </v-card-title>
      <v-tabs v-model="tab" background-color="primary" color="white" grow dark>
        <v-tab>Usuarios con acceso</v-tab>
        <v-tab>Pantallas a las que tiene acceso</v-tab>
      </v-tabs>
      <v-card-text class="pa-4">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <div style="display: flex; justify-content: end">
              <v-text-field
                v-model="searchUser"
                class="rounded-lg"
                dense
                outlined
                prepend-inner-icon="mdi-account-search"
                placeholder="Buscar..."
                style="max-width: 350px"
              >
              </v-text-field>
            </div>
            <v-data-table
              :items="usuarios"
              :headers="headersUsuarios"
              dense
              :loading="loadingUsuarios"
              class="rounded-lg"
              :search="searchUser"
              style="border: 0.8px solid #cfd8dc !important"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn text small class="font-weight-bold" color="pink darken-3" @click="
                  showConfirmDialog = true;
                openConfirm(item);
                ">quitar</v-btn>
              </template>
              <template v-slot:item.EMP_STATUS="{ item }">
                <v-chip v-if="item.EMP_STATUS == 'A'" color="teal darken-1" dark small>Activo</v-chip>
                <v-chip v-else color="pink darken-3" dark small>Baja</v-chip>
              </template>
              <template v-slot:item.correo="{ item }">
                <p v-if="item.correo">{{ item.correo }}</p>
                <p v-else>
                  <v-tooltip top color="pink lighten-2">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        color="pink darken-3"
                        dark
                        small
                        v-bind="attrs"
                        v-on="on"
                        >Sin correo registrado</v-chip
                      >
                    </template>
                    <label class="text-uppercase font-weight-bold ehite--text"
                      >Registre su correo en <br />
                      Administración de usuarios</label
                    >
                  </v-tooltip>
                  <v-tooltip top color="pink lighten-2">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="pink darken-3"
                        dark
                        small
                        v-bind="attrs"
                        v-on="on"
                        >mdi-help</v-icon
                      >
                    </template>
                    <label class="text-uppercase font-weight-bold ehite--text"
                      >Si ya esta registrado su correo <br />
                      favor de intentar guardar nuevamente el usuario con su
                      correo <br />
                      para actualizar valores.</label
                    >
                  </v-tooltip>
                </p>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item>
            <div style="display: flex; justify-content: end">
              <v-text-field
                v-model="searchView"
                class="rounded-lg"
                dense
                outlined
                prepend-inner-icon="mdi-account-search"
                placeholder="Buscar..."
                style="max-width: 350px"
              >
              </v-text-field>
            </div>
            <v-data-table
              :items="modulos"
              :headers="headersModulos"
              dense
              :loading="loadingModulos"
              :search="searchView"
              class="rounded-lg"
              style="border: 0.8px solid #cfd8dc !important"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn text small class="font-weight-bold" color="pink darken-3"
                  @click="openConfirmView(item)">quitar</v-btn>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <BuscaColaboradoresActivos v-if="showFindNewMembers" :showFindNewMembers="showFindNewMembers"
        @returnColaborrador="closeFindEmployee" />
      <SnackBar v-if="muestraBarra" :muestraBarra="muestraBarra" :texto="textoBarra" :color="colorBarra"
        @cierraBarra="muestraBarra = false" />
      <ConfirmDialog v-if="showConfirmDialog" :showConfirm="showConfirmDialog" :nombre="nombreSelected"
        @cierraConfirm="cierraConfirm" />
    </v-card>
  </v-dialog>


</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import BuscaColaboradoresActivos from "../../../../../components/BuscaColaboradoresActivos.vue";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import ConfirmDialog from "./ConfirmDialog.vue";
// import LoadigDialog from "../../DesarrolloOrganizacional/components/loadingBar.vue";
export default {
  name: "admon-accesos-sistemas",
  components: {
    BuscaColaboradoresActivos,
    SnackBar,
    ConfirmDialog,
    // LoadigDialog,
  },
  props: [
    "muestraUsuariosPerfil",
    "sistema",
    "perfil",
    "sistemaDesc",
    "perfilDesc",
  ],
  data() {
    return {
      usuarios: [],
      vistas: [],
      modulos: [],
      headersUsuarios: [
        { value: "uId", text: "ID", align: "center" },
        { value: "EMP_KEYEMP", text: "Folio", align: "center" },
        { value: "FULLNAME", text: "NOMBRE" },
        { value: "correo", text: "CORREO" },
        { value: "PUESTO", text: "PUESTO" },
        { value: "EMP_STATUS", text: "ESTATUS", align: "center" },
        { value: "actions", text: "ACCIONES", align: "center" },
      ],
      headersModulos: [
        { value: "idMenu", text: "ID", align: "center" },
        { value: "Etiqueta", text: "Etiqueta" },
        { value: "modulo", text: "modulo" },
        { value: "actions", text: "ACCIONES", align: "center" },
      ],
      tab: null,
      loadingUsuarios: true,
      loadingModulos: true,
      showFindNewMembers: false,
      muestraBarra: false,
      showConfirmDialog: false,
      uidSelected: null,
      idmenuSelected: null,
      nombreSelected: "",
      textoBarra: "",
      colorBarra: "",
      searchUser: "",
      searchView: "",
    };
  },
  computed: {},
  mounted() {
    this.getUsuariosPerfil();
  },
  methods: {
    getUsuariosPerfil() {
      this.usuarios.length = 0;
      let params = {
        Opcion: 3,
        token: localStorage.getItem("token"),
        sistema: this.sistema,
        perfil: this.perfil,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/General/AministracionSistema/ws-perfiles.php`,
          params
        )
        .then((response) => {
          this.loadingUsuarios = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.usuarios = response.data.usuarios;
            this.getModulosPerfil();
          }
        });
    },
    getModulosPerfil() {
      this.modulos.length = 0;
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
        sistema: this.sistema,
        perfil: this.perfil,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/General/AministracionSistema/ws-perfiles.php`,
          params
        )
        .then((response) => {
          this.loadingModulos = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.modulos = response.data.modulos;
          }
        });
    },
    closeFindEmployee(empleado) {
      this.showFindNewMembers = false;
      if (empleado != null) {
        this.loadingUsuarios = true;
        let params = {
          Opcion: 5,
          token: localStorage.getItem("token"),
          sistema: this.sistema,
          perfil: this.perfil,
          empleado: empleado.EMPLID,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/General/AministracionSistema/ws-perfiles.php`,
            params
          )
          .then((response) => {
            this.loadingModulos = false;
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              this.textoBarra = "Agregado con éxito.";
              this.colorBarra = "teal darken-1";
              this.muestraBarra = true;

              this.getUsuariosPerfil();
            } else {
              this.textoBarra = "Ocurrió un error.";
              this.colorBarra = "pink darken-3";
              this.muestraBarra = true;
            }
          });
      }
    },
    deleteUserAccess() {
      let params = {
        Opcion: 6,
        token: localStorage.getItem("token"),
        sistema: this.sistema,
        uid: this.uidSelected,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/General/AministracionSistema/ws-perfiles.php`,
          params
        )
        .then((response) => {
          this.loadingModulos = false;
          this.uidSelected = null;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (
            response.data.status == "OK" &&
            response.data.usuario != 0
          ) {
            this.textoBarra = "Eliminado con éxito.";
            this.colorBarra = "teal darken-1";
            this.muestraBarra = true;
            // this.loadingUsuarios = true
            this.getUsuariosPerfil();
          } else {
            this.textoBarra = "Ocurrió un error.";
            this.colorBarra = "pink darken-3";
            this.muestraBarra = true;
          }
        });
    },
    deleteModuleMenu() {
      let params = {
        Opcion: 7,
        token: localStorage.getItem("token"),
        sistema: this.sistema,
        idmenu: this.idmenuSelected,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/General/AministracionSistema/ws-perfiles.php`,
          params
        )
        .then((response) => {
          this.loadingModulos = false;
          this.idmenuSelected = null;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (
            response.data.status == "OK" &&
            response.data.usuario != 0
          ) {
            this.textoBarra = "Eliminado con éxito.";
            this.colorBarra = "teal darken-1";
            this.muestraBarra = true;
            // this.loadingUsuarios = true
            this.getModulosPerfil();
          } else {
            this.textoBarra = "Ocurrió un error.";
            this.colorBarra = "pink darken-3";
            this.muestraBarra = true;
          }
        });
    },
    openConfirm(user) {
      this.uidSelected = user.uId;
      this.nombreSelected = user.FULLNAME;
      this.showConfirmDialog = true;
    },
    openConfirmView(view) {
      this.idmenuSelected = view.idMenu;
      this.nombreSelected = view.Etiqueta;
      this.showConfirmDialog = true;
    },
    cierraConfirm(tipo) {
      if (this.uidSelected != null) {
        if (tipo) {
          this.showConfirmDialog = false;
          this.loadingUsuarios = true;
          this.deleteUserAccess();
        } else {
          this.uidSelected = null;
          this.showConfirmDialog = false;
        }
      } else if (this.idmenuSelected != null) {
        if (tipo) {
          this.showConfirmDialog = false;
          this.loadingModulos = true;
          this.deleteModuleMenu();
        } else {
          this.idmenuSelected = null;
          this.showConfirmDialog = false;
        }
      }
    },
  },
};
</script>
