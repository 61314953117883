<template>
  <v-sheet color="white" height="100%" class="px-1 py-2 fondo-card rounded-lg">
    <v-container class="main-container">
      <div class="avatar-container">
        <!-- <div >
          <v-img src="@/assets/icons/tool-front-color.png" height="100" width="100" style="position: absolute;
            margin-left: -58px;
            transform: rotate(-52deg);
        }"></v-img>
        </div> -->
        <!-- <div class="avatar-background avatar-background-right"></div> -->
        <v-hover v-slot="{ hover }">
          <v-img cover margin="auto" class="rounded-circle" lazy-src="@/assets/img/placeholder_user.svg"
            :src="fotoPerfil" :aspect-ratio="1 / 1">
            <v-expand-transition bottom>
              <div v-if="hover" class="fondo-imagen d-flex transition-fast-in-fast-out v-card--reveal white--text"
                style="display: flex; justify-content: center; height: 100%">
                <v-chip class="fondo-chip" style="margin-top: 45%" @click="abrirModal = true">
                  <v-icon>mdi-camera</v-icon> Editar foto</v-chip>
              </div>
            </v-expand-transition>
          </v-img>
        </v-hover>

        <div class="avatar-effect" v-if="valores"></div>
      </div>

      <v-card-text class="text-center">
        <div>{{ $store.getters.getUser["Nombre"] }}</div>
        <div>{{ $store.getters.getUser["Puesto"] }}</div>
        <div>{{ $store.getters.getUser["Departamento"] }}</div>
        <div v-if="$store.getters.getUser['compania'] == '00100'">
          Urrea Herramientas Profesionales
        </div>
        <div v-else-if="$store.getters.getUser['compania'] == '01000'">
          Urrea Herramientas Colombia
        </div>
        <div v-else-if="$store.getters.getUser['compania'] == '00700'">
          Urrea Professional Tools
        </div>
        <br />
        <v-row class="ma-0 pa-0">
          <v-col cols="4" class="ma-0 pa-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark small outlined fab color="light-blue darken-3" v-bind="attrs" v-on="on"
                  @click="expand = !expand">
                  <v-icon>{{ expand ? 'mdi-close' : 'mdi-card-account-details-star-outline' }}</v-icon>
                </v-btn>
              </template>
              <span>Principios</span>
            </v-tooltip>
          </v-col>
          <v-col cols="4" class="ma-0 pa-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-show="$store.getters.getUser['compania'] == '00100'" dark small outlined fab
                  color="light-blue darken-3" v-bind="attrs" v-on="on" @click="muestraEquipoAsignado = true">
                  <v-icon dark>mdi-laptop</v-icon>
                </v-btn>
              </template>
              <span>Equipo</span>
            </v-tooltip>
          </v-col>
          <v-col cols="4" class="ma-0 pa-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-show="$store.getters.getUser['compania'] == '00100'" dark small outlined fab
                  color="light-blue darken-3" v-bind="attrs" v-on="on" @click="muestraFormInconformidades = true">
                  <v-icon>mdi-chat-processing-outline</v-icon>
                </v-btn>
              </template>
              <span>Crear solicitud</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-expand-transition>
          <v-sheet id="valores-preview" color="transparent" v-show="expand">
            <persona-valores-preview :userId="1" />
          </v-sheet>
        </v-expand-transition>
      </v-card-text>

      <CargarFotoPerfil v-if="abrirModal" :openDialogSubirImg="abrirModal" @cerrarModal="cierraModalPerfil"
        :folio="folio" :ruta="fotoPerfil">
      </CargarFotoPerfil>

      <PersonaEquipoAsignado :verInfoEquipo="muestraEquipoAsignado"
        @cerrarEquipoAsignado="muestraEquipoAsignado = false" />

      <mi-nomina-form-inconformidades v-if="muestraFormInconformidades" :dialog="muestraFormInconformidades"
        @cierraFormInconformidades="closeFormInconformidades"></mi-nomina-form-inconformidades>
    </v-container>
  </v-sheet>
</template>
<script>
import axios from "axios";
import CargarFotoPerfil from "../../../../../components/CargaFotoPerfil.vue";
import PersonaValoresPreview from "./PersonaValoresPreview.vue";
import PersonaEquipoAsignado from "./PersonaEquipoAsignado.vue";
import MiNominaFormInconformidades from "../../../../../components/componentsNomina/MiNominaFormInconformidades.vue";

export default {
  name: "persona-perfil-preview",
  data: () => ({
    valores: false,
    hover: false,
    abrirModal: false,
    muestraEquipoAsignado: false,
    muestraFormInconformidades: false,
    fotoPerfil: "",
    expand: false,
  }),
  components: {
    CargarFotoPerfil,
    PersonaValoresPreview,
    PersonaEquipoAsignado,
    MiNominaFormInconformidades,
  },
  props: ["userId"],
  mounted() {
    this.getPerfilColaborador();
  },
  methods: {
    getPerfilColaborador() {
      let params = {
        Opcion: 19,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          if (response.data.status == "OK") {
            this.fotoPerfil =
              this.$store.getters.getHost +
              "/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/" +
              response.data.data["foto"];
            this.folio = response.data.data["EMPLID"];
            //console.log(this.fotoPerfil);
            this.loading = false;
          }
        });
    },

    cierraModalPerfil(response) {
      this.abrirModal = false;
      if (response == "OK") {
        this.fotoPerfil = "";
        this.getPerfilColaborador();
      }
    },
    closeFormInconformidades(response) {
      this.muestraFormInconformidades = false;
      console.log(response);
    },
  },
};
</script>
<style lang="sass" scoped>
@mixin afterBg
  content: ''
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0
  border-radius: 50%

@keyframes shockwave
  0%
    transform: scale(1)
    box-shadow: 0 0 2px rgba(#005180,0.4), inset 0 0 1px rgba(#005180,0.4)
  95%
    box-shadow: 0 0 50px rgba(#005180,0.1), inset 0 0 30px rgba(#005180,0.1)
  100%
    transform: scale(1.1)

  // background-image: url('../../../../../assets/icons/tool-front-color.png'), url('../../../../../assets/icons/tool-front-color.png')

.avatar-container
  max-width: 160px//200px
  max-height: 160px//200px
  margin: auto
  border-radius: 50%
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff
  .avatar-effect
    top: 0
    left: 0
    right: 0
    bottom: 0
    &:after
      @include afterBg
      animation: shockwave 1.5s 1.15s ease-out infinite
    &:before
      @include afterBg
      animation: shockwave 1.5s 1.0s ease-out infinite

.fondo-imagen
  background: rgb(232,239,237)
  background: radial-gradient(circle, rgba(232,239,237,1) 0%, rgba(113,142,187,0.7203081916360294) 100%)
.fondo-chip
  background: rgb(240,244,246)
  background: radial-gradient(circle, rgba(240,244,246,1) 4%, rgba(255,255,255,1) 100%)

.fondo-card
  background: linear-gradient(-45deg, #FFFFFF 0%, rgba(255,253,253,1) 45%, #E1F5FE 100%) !important
</style>
