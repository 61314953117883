<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      persistent
      scrollable
      content-class="fix-border-dialog"
    >
      <v-card class="rounded-xl">
        <v-card-title class="primary white--text subtitle-1">
          <span>Registro de Llegada Tarde</span>
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('cierra', 0)">mdi-close</v-icon>
        </v-card-title>
        <!-- <template v-slot:activator="{ on, attrs }"> -->
        <v-divider></v-divider>
        <v-card-text>
          <v-alert
            :value="alert"
            :color="colorAlert"
            dark
            border="top"
            :icon="iconAlert"
            transition="scale-transition"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ respuestaTurno.mensaje }}
              </v-col>
              <v-col class="shrink">
                <v-row align="center">
                  <v-col cols="12" sm="6">
                    <v-btn small dark @click="respuestaApiValidacion()">
                      {{ textoBotonAlert }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-alert>
          <v-form ref="formLlegadaTarde" v-model="valid">
            <p style="font-weight: bolder" class="mt-1 mb-0">Motivo:</p>
            <v-radio-group
              v-model="motivos"
              class="pt-0 mt-1"
              mandatory
              row
              :disabled="loadingBotonGuardar"
            >
              <v-radio label="Personal" value="Personal"></v-radio>
              <v-radio label="Laboral" value="Laboral"></v-radio>
            </v-radio-group>
            <v-menu
              ref="fecha"
              v-model="fecha"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datesS"
                  placeholder="Selecciona una fecha"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  dense
                  outlined
                  class="rounded-lg"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="loadingBotonGuardar"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" scrollable @input="guardaFecha()">
              </v-date-picker>
            </v-menu>
            <label class="font-weight-bold blue-grey--text text--darken-2">
              "Recuerda que el formato es de 24 hrs."
            </label>
            <v-dialog
              ref="dialog"
              v-model="modalHoraLlegada"
              :return-value.sync="horaLlegada"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="horaLlegada"
                  placeholder="Selecciona la hora"
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  class="rounded-lg"
                  readonly
                  outlined
                  dense
                  :rules="rules.llegada"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="loadingBotonGuardar"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="modalHoraLlegada"
                v-model="horaLlegada"
                format="24hr"
                full-width
                @input="$refs.dialog.save(horaLlegada)"
              >
              </v-time-picker>
            </v-dialog>
            <label class="text-uppercase caption"
              >Describe el motivo de la solicitud</label
            >
            <v-text-field
              v-model="comentario"
              class="rounded-lg"
              outlined
              required
              dense
              name="input-7-4"
              counter="250"
              placeholder="Escribe un comentario"
              :rules="rules.comentario"
              :disabled="loadingBotonGuardar"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
          <v-btn
            color="teal darken-1"
            class="rounded-xl rounded-t-0"
            large
            dark
            text
            block
            elevation="2"
            @click="validaDatos()"
            :loading="loadingBotonGuardar"
          >
            <v-icon left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-sheet id="muestra-incidencias-cargadas">
      <muestra-incidencias-cargadas
        v-if="muestraDetalles"
        :muestraDetalles="muestraDetalles"
        :datosTabla="respuestaTurno.data"
        :alertErrorDosDias="alertErrorDosDias"
        @cierraTabla="muestraDetalles = false"
      />
    </v-sheet>

    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias
        v-if="muestraBarra"
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-sheet>
  </div>
</template>

<script>
import axios from "axios";
import MuestraIncidenciasCargadas from "./componentsExtra/MuestraIncidenciasCargadas.vue";
import SnackBarErrorIncidencias from "./componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "incidencia-alta-llegada-tarde",
  data: () => ({
    muestraDetalles: false,
    alertErrorDosDias: false,
    muestraBarra: false,
    valid: true,
    textoBarra: "",
    colorBarra: "error",
    alert: false,
    colorAlert: "pink",
    iconAlert: "mdi-alert-circle",
    tiposIncidenciasArray: [],
    setDataALateArrival: "",
    motivos: "Personal",
    picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    fecha: false,
    showMessages: false,
    dates: "",
    datesS: "",
    horaLlegada: null,
    modalHoraLlegada: false,
    respuestaTurno: [],
    textoBotonAlert: "",
    comentario: "",
    rules: {
      comentario: [
        (val) =>
          (val || "").length > 0 || "Es necesario que escribas un comentario.",
        (val) =>
          (val && val.length >= 12) ||
          "El comentario debe tener al menos 12 caracteres.",
        (val) =>
          (val && val.length < 250) ||
          "El comentario no debe tener más de 250 caracteres.",
      ],
      llegada: [
        (val) =>
          (val || "").length > 0 ||
          "Es necesario que agregues una hora de llegada.",
      ],
    },
    loadingBotonGuardar: false,
  }),
  components: {
    MuestraIncidenciasCargadas,
    SnackBarErrorIncidencias,
  },
  props: ["dialog", "esAdmin", "empleado"],
  mounted() {
    this.datesS = this.setFecha();
  },
  computed: {
    messages() {
      return this.showMessages ? ["Message"] : undefined;
    },
  },
  methods: {
    setFecha() {
      let fechaHoy = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      return this.formatDate(fechaHoy);
    },
    guardaFecha() {
      this.datesS = this.formatDate(this.dates);
      this.fecha = false;
    },
    formatDate(date) {
      if (!date) return null;

      let [year, month, day] = date.split("-");
      switch (month) {
        case "01":
          month = "ENE";
          break;
        case "02":
          month = "FEB";
          break;
        case "03":
          month = "MAR";
          break;
        case "04":
          month = "ABR";
          break;
        case "05":
          month = "MAY";
          break;
        case "06":
          month = "JUN";
          break;
        case "07":
          month = "JUL";
          break;
        case "08":
          month = "AGO";
          break;
        case "09":
          month = "SEP";
          break;
        case "10":
          month = "OCT";
          break;
        case "11":
          month = "NOV";
          break;
        case "12":
          month = "DIC";
          break;
      }
      return `${day}/${month}/${year}`;
    },
    formatDateISO(date) {
      if (!date) return null;

      let [day, month, year] = date.split("/");
      switch (month) {
        case "ENE":
          month = "01";
          break;
        case "FEB":
          month = "02";
          break;
        case "MAR":
          month = "03";
          break;
        case "ABR":
          month = "04";
          break;
        case "MAY":
          month = "05";
          break;
        case "JUN":
          month = "06";
          break;
        case "JUL":
          month = "07";
          break;
        case "AGO":
          month = "08";
          break;
        case "SEP":
          month = "09";
          break;
        case "OCT":
          month = "10";
          break;
        case "NOV":
          month = "11";
          break;
        case "DIC":
          month = "12";
          break;
      }
      return `${year}-${month}-${day}`;
    },
    validaDatos() {
      if (this.$refs.formLlegadaTarde.validate()) {
        if (
          this.datesS == "" ||
          this.horaLlegada == "" ||
          this.comentario == ""
        ) {
          this.textoBarra = "Tienes que llenar todos los campos";
          this.colorBarra = "error";
          this.muestraBarra = true;
        } else {
          this.loadingBotonGuardar = true;

          let empleado = undefined;

          if (this.esAdmin === true) {
            empleado = this.empleado;
          }

          let params = {
            Opcion: 1,
            token: localStorage.getItem("token"),
            llegada: this.horaLlegada,
            fecha: this.formatDateISO(this.datesS),
            comentario: this.comentario,
            tipoMotivo: this.motivos,
            esAdmin: this.esAdmin,
            empleado: empleado,
          };

          // console.table(params)

          axios
            .post(
              `${this.$store.getters.getHost}/Incidencias/ws-incidencias-llegada-tarde.php`,
              params
            )
            .then((response) => {
              var data = response.data.llegadaTarde;
              this.respuestaTurno = data;
              this.validaRespuesta();
            })
            .catch(function (e) {
              let result = e.toString().includes("401");
              if (result) {
                window.location.reload();
              } else {
                alert(e);
              }
            });
        }
      } else {
          this.textoBarra = "Tienes que llenar todos los campos";
          this.colorBarra = "error";
          this.muestraBarra = true;
      }
    },
    validaRespuesta() {
      //(this.respuestaTurno["data"]);
      if (this.respuestaTurno["opc"] != 5) {
        if (this.respuestaTurno["opc"] == 2) {
          this.textoBotonAlert = "Ver más";
        } else {
          this.textoBotonAlert = "Cerrar";
        }
        // this.textoBotonAlert = "";
        this.loadingBotonGuardar = false;
        this.colorAlert = "pink";
        this.alert = true;
      } else {
        this.datesS = this.setFecha();
        this.horaLegada = "";
        this.comentario = "";
        this.textoBotonAlert = "Ver más";
        this.loadingBotonGuardar = false;
        // this.textoBarra = this.respuestaTurno["mensaje"];
        this.colorAlert = "success";
        this.alert = true;
        this.textoBarra = "Incidencia registrada con éxito";
        this.colorBarra = "primary";
        this.muestraBarra = true;
        this.$emit("cierra", 1);
      }
    },
    respuestaApiValidacion() {
      if (this.textoBotonAlert == "Ver más") {
        this.muestraDetalles = true;
        this.alert = false;
      } else {
        this.alert = false;
      }
    },
    cleanAndClose() {
      this.datesS = this.setFecha();
      this.horaLegada = "";
      this.comentario = "";
      this.$emit("cierra", 0);
    },
  },
};
</script>
