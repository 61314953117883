<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="650px"
      content-class="fix-border-dialog"
    >
      <v-card class="rounded-xl">
        <v-card-title class="primary white--text subtitle-2">
          <span>Registro de Permiso Especial</span>
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('cierra', 0)">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px">
          <v-alert
            :value="alert"
            :color="colorAlert"
            dark
            border="top"
            :icon="iconAlert"
            transition="scale-transition"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ respuestaApiPermisos.mensaje }}
              </v-col>
              <v-col class="shrink">
                <v-row align="center">
                  <v-col cols="12" sm="6">
                    <v-btn small @click="funcionamientoBotonAlert()">
                      {{ textoBotonAlert }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-alert>
          <p class="font-weight-bold my-2">Selecciona fecha de inicio y fin</p>

          <v-row no-gutters dense>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-dialog
                ref="dialog"
                v-model="fechaInicio"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="textFechaInicioS"
                    class="mx-1"
                    label="Día a Inicio"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    :disabled="cargandoGuardar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  class="rounded-lg"
                  v-model="textFechaInicio"
                  @input="formatoFechaInicio()"
                ></v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-dialog
                ref="dialog"
                v-model="fechaFinal"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="textFechaFinalS"
                    class="mx-1"
                    label="Día Final"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    :disabled="cargandoGuardar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  class="rounded-lg"
                  v-model="textFechaFinal"
                  @input="formatoFechaFinal()"
                ></v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>

          <p
            class="text-subtitle-2 text-center font-weight-bold primary--text mb-0"
          >
            Tipo de permiso
          </p>

          <v-card class="rounded-xl">
            <v-tabs
              v-model="tipoPermiso"
              background-color="primary"
              fixed-tabs
              dense
              dark
            >
              <v-tab>Sin goce de sueldo</v-tab>
              <v-tab>Con goce de sueldo</v-tab>
            </v-tabs>
            <!-- <v-radio-group v-model="tipoPermiso" mandatory row>
            <v-radio label="Con goce de sueldo" value="1"></v-radio>
            <v-radio label="Sin goce de sueldo" value="0"></v-radio>
          </v-radio-group> -->

            <div
              no-gutters
              v-if="tipoPermiso == 1"
              style="display: flex; justify-content: center"
            >
              <v-radio-group
                v-model="permisoSeleccionado"
                style="
                  display: flex;
                  justify-content: center;
                  margin-top: inherit;
                  margin-bottom: inherit;
                "
              >
                <v-radio
                  v-for="(typeInc, indice) in permisosConGoce"
                  :key="indice"
                  :label="typeInc.PermDescripcion"
                  :value="typeInc.id"
                ></v-radio>
              </v-radio-group>
            </div>
            <v-row
              no-gutters
              v-else
              style="display: flex; justify-content: center"
            >
              <v-radio-group v-model="permisoSeleccionado">
                <v-radio
                  v-for="(typeInc, indice) in permisosSinGoce"
                  :key="indice"
                  :label="typeInc.PermDescripcion"
                  :value="typeInc.id"
                ></v-radio>
              </v-radio-group>
            </v-row>
          </v-card>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-alert
                v-bind="attrs"
                v-on="on"
                class="mt-3"
                v-model="olvidoGafete"
                color="blue"
                dismissible
                type="info"
              >
                Los permisos de olvido de gafete deben solicitarse en caseta si
                estas en el salto o recepción si estás en américas, si es
                requerido en el mismo día.
              </v-alert>
            </template>
            <span>Cerrar para guardar incidencia.</span>
          </v-tooltip>

          <p
            v-if="
              permisoSeleccionado > 0 &&
              tiposDePermiso[permisoSeleccionado - 1].Nota != ''
            "
            class="my-1 text-center"
          >
            <strong> NOTA: </strong>
            Este permiso se puede solicitar hasta por
            <strong class="primary--text">{{
              tiposDePermiso[permisoSeleccionado - 1].Nota
            }}</strong>
          </p>
          <div
            v-if="permisoSeleccionado > 0"
            style="display: flex; justify-content: center"
          >
            <v-text-field
              v-model="comentario"
              :rules="rules"
              counter="250"
              label="Agregar un comentario"
            >
            </v-text-field>
          </div>
          <div
            v-if="
              permisoSeleccionado > 0 &&
              tiposDePermiso[permisoSeleccionado - 1].condicionantes != ''
            "
            style="display: flex; justify-content: center; margin-bottom: 26px"
          >
            <v-btn color="primary rounded-pill" @click="muestraReglasPermiso()">
              <v-icon left dark> mdi-information-outline </v-icon>
              Detalles del permiso
            </v-btn>
          </div>
          <div
            v-if="
              permisoSeleccionado > 0 &&
              tiposDePermiso[permisoSeleccionado - 1].documento_presentar == 1
            "
            style="display: flex; justify-content: center"
          >
            <v-row class="mb-12" no-gutters>
              <v-col sm="8" style="display: flex; justify-content: center">
                <input
                  label="Agregar archivo"
                  type="file"
                  id="file"
                  ref="file"
                  accept=".jpeg,.png,.jpg,.pdf"
                  outlined
                  dense
                  show-size
                  :autofocus="focusFile"
                  :disabled="archivoCargadoConExito"
                  v-on:change="handleFileUpload()"
                />
              </v-col>
              <v-col sm="4" style="display: flex; justify-content: center">
                <v-btn
                  small
                  style="background-color: #16a5b8; color: white"
                  elevation="1"
                  :loading="loadingButtonUploadFile"
                  @click="subirArchivo()"
                >
                  subir archivo
                  <v-icon right dark> mdi-cloud-upload </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
          <v-btn
            color="teal darken-1"
            text
            :disabled="olvidoGafete"
            class="rounded-xl rounded-t-0 font-weight-bold"
            x-large
            block
            elevation="2"
            :loading="cargandoGuardar"
            @click="validarInformacion()"
          >
            <v-icon left> mdi-content-save-all </v-icon>
            guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-sheet id="muestra-incidencias-cargadas">
      <muestra-incidencias-cargadas
        v-if="muestraDetalles"
        :muestraDetalles="muestraDetalles"
        :datosTabla="respuestaApiPermisos.datos"
        :alertErrorDosDias="alertErrorDosDias"
        @cierraTabla="muestraDetalles = false"
      />
    </v-sheet>

    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias
        v-if="muestraBarra"
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-sheet>

    <v-sheet id="muestra-detalle-permiso">
      <muestra-detalle-permiso
        :muestraDetallesPermiso="muestraDetallesPermiso"
        :messagge="messagge"
        @cierraVentanaDetallePermiso="muestraDetallesPermiso = false"
      />
    </v-sheet>

    <v-sheet id="muestra-calendario-dias-seleccionados">
      <muestra-calendario-dias-seleccionados
        :muestraCalendario="muestraCalendario"
        :fechas="respuestaApiPermisos.datos"
        @cierraCalendario="muestraCalendario = false"
      />
    </v-sheet>
  </div>
</template>

<script>
import axios from "axios";
import MuestraDetallePermiso from "./componentsExtra/MuestraDetallePermiso.vue";
import SnackBarErrorIncidencias from "./componentsExtra/SnackBarErrorIncidencias.vue";
import MuestraIncidenciasCargadas from "./componentsExtra/MuestraIncidenciasCargadas.vue";
import MuestraCalendarioDiasSeleccionados from "./componentsExtra/MuestraCalendarioDiasSeleccionados.vue";

export default {
  name: "incidencia-permiso-especial",
  data: () => ({
    loadingButtonUploadFile: false,
    cargandoPermisos: true,
    cargandoGuardar: false,
    focusFile: false,
    textoBotonAlert: "",
    alert: false,
    colorAlert: "pink",
    iconAlert: "mdi-alert-circle",
    tiposDePermiso: [],
    permisosConGoce: [],
    permisosSinGoce: [],
    respuestaApiPermisos: [],
    muestraBarra: false,
    archivoCargadoConExito: false,
    alertErrorDosDias: false,
    muestraCalendario: false,
    colorBarra: "red accent-2",
    textoBarra: "",
    file: "",
    comentario: "",
    tipoPermiso: null,
    messagge: "",
    muestraDetallesPermiso: false,
    muestraDetalles: false,
    permisoSeleccionado: 0,
    archivoCargado: false,
    rules: [(v) => v.length <= 250 || "Max 250 characters"],
    textFechaInicioS: "",
    textFechaInicio: "",
    fechaInicio: false,
    textFechaFinal: "",
    textFechaFinalS: "",
    fechaFinal: false,
    olvidoGafete: false,
    tooltip: false,
  }),
  components: {
    MuestraDetallePermiso,
    SnackBarErrorIncidencias,
    MuestraIncidenciasCargadas,
    MuestraCalendarioDiasSeleccionados,
  },
  mounted() {
    this.getTiposPermisos();
    this.textFechaInicioS = this.setFechaManana();
    this.textFechaFinalS = this.setFechaManana();
    // this.getIncioFinSemana();
  },
  props: ["dialog", "esAdmin", "empleado"],
  watch: {
    permisoSeleccionado: function () {
      if (this.permisoSeleccionado == 8) {
        this.olvidoGafete = true;
      } else {
        this.olvidoGafete = false;
      }
    },
  },
  methods: {
    formatoFechaInicio() {
      this.textFechaInicioS = this.formatDate(this.textFechaInicio);
      this.fechaInicio = false;
    },
    formatoFechaFinal() {
      this.textFechaFinalS = this.formatDate(this.textFechaFinal);
      this.fechaFinal = false;
    },
    formatDate(date) {
      if (!date) return null;

      let [year, month, day] = date.split("-");
      switch (month) {
        case "01":
          month = "ENE";
          break;
        case "02":
          month = "FEB";
          break;
        case "03":
          month = "MAR";
          break;
        case "04":
          month = "ABR";
          break;
        case "05":
          month = "MAY";
          break;
        case "06":
          month = "JUN";
          break;
        case "07":
          month = "JUL";
          break;
        case "08":
          month = "AGO";
          break;
        case "09":
          month = "SEP";
          break;
        case "10":
          month = "OCT";
          break;
        case "11":
          month = "NOV";
          break;
        case "12":
          month = "DIC";
          break;
      }
      return `${day}/${month}/${year}`;
    },
    formatDateISO(date) {
      if (!date) return null;

      let [day, month, year] = date.split("/");
      switch (month) {
        case "ENE":
          month = "01";
          break;
        case "FEB":
          month = "02";
          break;
        case "MAR":
          month = "03";
          break;
        case "ABR":
          month = "04";
          break;
        case "MAY":
          month = "05";
          break;
        case "JUN":
          month = "06";
          break;
        case "JUL":
          month = "07";
          break;
        case "AGO":
          month = "08";
          break;
        case "SEP":
          month = "09";
          break;
        case "OCT":
          month = "10";
          break;
        case "NOV":
          month = "11";
          break;
        case "DIC":
          month = "12";
          break;
      }
      return `${year}-${month}-${day}`;
    },
    setFechaManana() {
      const today = new Date();
      let tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      return this.formatDate(tomorrow.toISOString().substring(0, 10));
    },
    funcionamientoBotonAlert() {
      if (this.textoBotonAlert == "Ver más") {
        this.alert = false;
        this.muestraDetalles = true;
      } else if (this.textoBotonAlert == "Ver detalles") {
        this.alert = false;
        // //(1)
        // //(this.respuestaApiPermisos["data"])
        // //(this.respuestaApiPermisos)
        this.muestraCalendario = true;
      } else if (this.textoBotonAlert == "Aceptar") {
        this.cargandoGuardar = true;
        this.alert = false;
        this.respuestaApiPermisos = [];
        this.saveDirecto();
      } else {
        this.alert = false;
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    muestraReglasPermiso() {
      this.messagge =
        this.tiposDePermiso[this.permisoSeleccionado - 1]["condicionantes"];
      this.muestraDetallesPermiso = true;
    },
    getTiposPermisos() {
      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-permiso-especial.php`,
          params
        )
        .then((response) => {
          var data = response.data.tiposPermisos;
          this.tiposDePermiso = data;
          this.llenaArreglosPermiso();
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    llenaArreglosPermiso() {
      // //(this.tiposDePermiso)
      for (var i = 0; i < this.tiposDePermiso.length; i++) {
        if (this.tiposDePermiso[i]["conGoce"] == 1) {
          this.permisosConGoce.push(this.tiposDePermiso[i]);
        } else {
          if (this.tiposDePermiso[i]["PermDescripcion"] != "Otro") {
            this.permisosSinGoce.push(this.tiposDePermiso[i]);
          }
        }
      }
      this.cargandoPermisos = false;
      // //(this.permisosSinGoce);
    },
    subirArchivo() {
      this.loadingButtonUploadFile = true;
      this.cargarArchivo();
    },
    cargarArchivo() {
      var reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        var params = {
          Opcion: 4,
          filename: this.file.name,
          base64: reader.result,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Incidencias/ws-incidencias-permiso-especial.php`,
            params
          )
          .then((response) => {
            if (response.data.file == this.file.name) {
              this.archivoCargadoConExito = true;
              this.textoBarra = "Archivo cargado con éxito.";
              this.colorBarra = "blue darken-4";
              this.muestraBarra = true;
              this.loadingButtonUploadFile = false;
            } else {
              this.textoBarra = "No se logró cargar el archivo.";
              this.colorBarra = "red accent-2";
              this.muestraBarra = true;
              this.loadingButtonUploadFile = false;
            }
            // this.loadingButtonUploadFile = false;
          })
          .catch(function (e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      };
      // var aux = params[0]["base64"]
    },
    validarInformacion() {
      this.cargandoGuardar = true;
      if (this.permisoSeleccionado > 0 && this.comentario != "") {
        if (
          this.tiposDePermiso[this.permisoSeleccionado - 1][
            "documento_presentar"
          ] == 1 &&
          !this.archivoCargadoConExito
        ) {
          this.colorBarra = "error";
          this.textoBarra =
            "Este permiso requiere subas un archivo de comprobante";
          this.focusFile = true;
          this.muestraBarra = true;
        } else {
          var params = {
            Opcion: 2,
            token: localStorage.getItem("token"),
            fechaInicio: this.formatDateISO(this.textFechaInicioS),
            fechaFin: this.formatDateISO(this.textFechaFinalS),
            diasMaximos:
              this.tiposDePermiso[this.permisoSeleccionado - 1][
                "duracion_dias"
              ],
            semanasMaximas:
              this.tiposDePermiso[this.permisoSeleccionado - 1][
                "duracion_semanas"
              ],
            tipoPermiso:
              this.tiposDePermiso[this.permisoSeleccionado - 1]["id"],
            comentario: this.comentario,
            esAdmin: this.esAdmin,
            empleado: this.empleado,
          };
          axios
            .post(
              `${this.$store.getters.getHost}/Incidencias/ws-incidencias-permiso-especial.php`,
              params
            )
            .then((response) => {
              var data = response.data.permisoEspecial;
              this.respuestaApiPermisos = data;
              this.validaRespuesta();
            })
            .catch(function (e) {
              let result = e.toString().includes("401");
              if (result) {
                window.location.reload();
              } else {
                alert(e);
              }
            });
        }
      } else {
        this.colorBarra = "error";
        this.textoBarra =
          "Es necesario que selecciones el permiso y agregues una justificación.";
        this.muestraBarra = true;
        this.cargandoGuardar = false;
      }
    },
    saveDirecto() {
      var params = {
        Opcion: 3,
        token: localStorage.getItem("token"),
        fechaInicio: this.formatDateISO(this.textFechaInicioS),
        fechaFin: this.formatDateISO(this.textFechaFinalS),
        tipoPermiso: this.tiposDePermiso[this.permisoSeleccionado - 1]["id"],
        comentario: this.comentario,
        esAdmin: this.esAdmin,
        empleado: this.empleado,
      };

      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-permiso-especial.php`,
          params
        )
        .then((response) => {
          var data = response.data.permisoEspecial;
          this.respuestaApiPermisos = data;
          this.validaRespuesta();
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    validaRespuesta() {
      //(this.respuestaApiPermisos["opc"]);
      this.cargandoGuardar = false;
      if (
        this.respuestaApiPermisos["opc"] != 5 &&
        this.respuestaApiPermisos["opc"] != 2 &&
        this.respuestaApiPermisos["opc"] != 6
      ) {
        this.textoBotonAlert = "Cerrar";
        // this.cargandoGuardar = false;
        this.colorAlert = "pink";
        this.alert = true;
        this.textoBarra = this.respuestaApiPermisos["mensaje"];
        this.colorBarra = "error";
        this.muestraBarra = true;
      } else if (this.respuestaApiPermisos["opc"] == 6) {
        this.textoBotonAlert = "Aceptar";
        this.colorAlert = "primary";
        this.alert = true;
        this.textoBarra = this.respuestaApiPermisos["mensaje"];
        this.colorBarra = "primary";
        this.muestraBarra = true;
      } else if (this.respuestaApiPermisos["opc"] == 2) {
        this.textoBotonAlert = "Ver más";
        this.colorAlert = "pink";
        this.alert = true;
        this.textoBarra = this.respuestaApiPermisos["mensaje"];
        this.colorBarra = "error";
        this.muestraBarra = true;
      } else if (this.respuestaApiPermisos["opc"] == 5) {
        // //(Object.values(this.respuestaApiPermisos))
        this.textoBotonAlert = "OK";
        this.colorAlert = "success";
        this.iconAlert = "mdi-content-save-check-outline";
        this.textoBarra = this.respuestaApiPermisos["mensaje"];
        this.colorBarra = "success";
        this.muestraBarra = true;
        this.permisoSeleccionado = 0;
        this.textFechaInicioS = this.setFechaManana();
        this.textFechaFinalS = this.setFechaManana();
        this.file = "";
        this.archivoCargadoConExito = false;
        this.loadingButtonUploadFile = false;
        this.alert = true;
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(this.$emit("cierra", 1));
          }, 2000);
        });
      }
    },
  },
};
</script>
