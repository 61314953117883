<template>
    <div>
        

        <v-btn class="primary white--text text-uppercase font-weight-bold text-center" @click="Regresar">
            <v-icon>{{ 'mdi-arrow-left' }}</v-icon>&nbsp;Regresar
        </v-btn>

        <div class="centrado">
            <v-btn text>
                <b>Relación Empleado Responsable</b>
            </v-btn>
          </div>
        <p></p>

        <div class="rounded-lg pb-4 elevation-0">

            <div v-if="loadingTable" class="center-item-in-div-column">
                <v-progress-circular :size="150" :width="15" color="primary" indeterminate></v-progress-circular>
                <label class="overline font-weight-bold">Cargando información...</label>
            </div>
    
            <template v-else>
                <div>
                  
              
                  <!-- Controles de búsqueda y ordenamiento -->
                  <div class="input-with-icon">
                 
                       <!-- Campo de búsqueda general -->
                       <v-text-field
                       v-model="searchQuery"
                       placeholder="Buscar en todas las columnas"
                       outlined
                       dense
                       clearable
                       hide-details
                       class="search-bar"
                       @click:clear="clearAllFilters"
                       style="flex: 1; max-width: 300px; margin: 0;"
                     ></v-text-field>

                     <v-row dense justify="end" class="d-flex">

                        <!-- Botón Agregar con Tooltip -->
                      
                      
                          <v-col  cols="auto" >
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        class="excel-green text-uppercase font-weight-bold text-center"
                                        :loading="btnAct2"
                                        :disabled="btnAct2"
                                        @click="funcionExcel()"
                                    >
                                        <v-icon v-if="!btnAct2" left>mdi-microsoft-excel</v-icon>
                                        <span v-if="!btnAct2">Exportar</span>
                                        <v-progress-circular
                                            v-else
                                            indeterminate
                                            color="white"
                                            size="20"
                                        ></v-progress-circular>
                                    </v-btn>
                                </template>
                                <span>Exportar el reporte a Excel</span>
                            </v-tooltip>
                        </v-col>
                        
                          
                      </v-row>                  

                  </div>

                  <v-row>

                    <v-col cols="auto">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="primary white--text text-uppercase font-weight-bold text-center"
                            @click="openAgregar()"
                          >
                            <v-icon left>mdi-plus</v-icon>
                            Agregar Empleado
                          </v-btn>
                        </template>
                        <span>Agregar un nuevo registro</span>
                      </v-tooltip>
                    </v-col>

                  </v-row>
                  <p></p>

                  
              
                  <!-- Tabla Aqui -->
                

             
                <v-data-table
                :headers="columns"
                :items="filteredData"
                :items-per-page="8"
                class="elevation-1 text-center"
                  :footer-props="footerProps"
              >
                <!-- Encabezados solo con barras de búsqueda -->
                <template v-slot:top>
                  <thead>
                    <tr>
                      <th
                        v-for="column in columns"
                        :key="column.value"
                        style="padding: 0; text-align: center; vertical-align: middle;"
                      >
                        <v-text-field
                          v-if="column.value !== 'opc'" 
                          v-model="filters[column.value]"
                          :placeholder="`Buscar en ${column.text}`"
                          outlined
                          dense
                          hide-details
                          class="search-bar-tabla"
                          :style="['empleado', 'resp_name'].includes(column.value) ? 'width: 265px; margin: 0 auto;' : 'width: 300px; margin: 0 auto;'"
                        ></v-text-field>
                      </th>
                    </tr>
                  </thead>
                </template>
              
                <!-- Contenido de la columna de acciones -->
                <template v-slot:[`item.opc`]="{ item }">
                  <div style="text-align: center;">
                    <v-icon small class="mr-2" @click="editRow(item)">mdi-pencil</v-icon>
                    <v-icon small class="mr-2" @click="eliminar(item)">mdi-delete</v-icon>
                    <v-icon small @click="viewRow(item)">mdi-eye</v-icon>
                  </div>
                </template>
              
                <!-- Centrando el contenido de las celdas -->
                <template v-slot:[`item.default`]="{ item, column }">
                  <div style="text-align: center; vertical-align: middle;">
                    {{ item[column.value] }}
                  </div>
                </template>
         
              </v-data-table>
              
                </div>
              </template>

              

              <!-- Este es para la ventanada de ver empleado -->

              <template>
                <div>
                 
                  <!-- Diálogo -->
                  <v-dialog v-model="dialogVer" max-width="600px">
                    <v-card class="rounded-xl p-0">


                        <v-toolbar class="primary white--text text-uppercase  sticky-toolbar no-padding" flat dense>
                            <v-toolbar-title>VER registro de RelacionEmpleadoResponsable</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialogVer = false">
                                <v-icon color="white">mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-divider></v-divider>


                     
                        <v-card-text>
                            <v-container class="compact-container">
                           
                              <v-row dense>
                                <v-col cols="4">
                                  <label class="font-weight-bold">EMPLEADO:</label>
                                </v-col>
                                <v-col cols="3">
                                  <v-text-field v-model="registro.empleado" outlined dense readonly></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col cols="4">
                                  <label class="font-weight-bold">RESPONSABLE:</label>
                                </v-col>
                                <v-col cols="3">
                                  <v-text-field v-model="registro.responsable" outlined dense readonly></v-text-field>
                                </v-col>
                              </v-row>
                              
                            </v-container>
                          </v-card-text>
            
                    </v-card>
                  </v-dialog>
                </div>
              </template>

              <!-- ESTO ES PARA ELIMINAR AL EMP -->
              <template>
                <div>
                 
                  <!-- Diálogo -->
                  <v-dialog v-model="dialogEliminar" max-width="600px">
                    <v-card class="rounded-xl p-0">


                        <v-toolbar class="primary white--text text-uppercase  sticky-toolbar no-padding" flat dense>
                            <v-toolbar-title>BORRAR registro de RelacionEmpleadoResponsable</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialogEliminar = false">
                                <v-icon color="white">mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-divider></v-divider>
                        <v-card v-if="loadingTableEditar" class="center-item-in-div-column">
                            <v-progress-circular :size="150" :width="15" color="primary"
                                indeterminate></v-progress-circular>
                            <label class="overline font-weight-bold">Cargando información...</label>
                        </v-card>
                     
                        <v-card-text v-else>
                            <v-container class="compact-container">
                             
                              <v-row dense>
                                <v-col cols="4">
                                  <label class="font-weight-bold">EMPLEADO:</label>
                                </v-col>
                                <v-col cols="3">
                                  <v-text-field v-model="registro.empleado" outlined dense readonly></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col cols="4">
                                  <label class="font-weight-bold">RESPONSABLE:</label>
                                </v-col>
                                <v-col cols="3">
                                  <v-text-field v-model="registro.responsable" outlined dense readonly></v-text-field>
                                </v-col>
                              </v-row>
                              
                              <v-row dense justify="center">
                                <v-col cols="auto">
                                  <v-btn class="primary white--text text-uppercase font-weight-bold text-center"
                                   @click="deleteRow()">
                                    Aceptar
                                  </v-btn>
                                </v-col>
                                <v-col cols="auto">
                                  <v-btn class="primary white--text text-uppercase font-weight-bold text-center"
                                   @click="dialogEliminar = false">
                                    Cancelar
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
            
                    </v-card>
                  </v-dialog>
                </div>
              </template>

              <!-- Este es para editar al empleado -->
              <template>
                <div>

                   
                 
                  <!-- Diálogo -->
                  <v-dialog  v-model="dialogEditar" max-width="650px">
                    <v-card class="rounded-xl p-0">
                        


                        <v-toolbar class="primary white--text text-uppercase  sticky-toolbar no-padding" flat dense>
                            <v-toolbar-title>MODIFICAR  registro de RelacionEmpleadoResponsable</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialogEditar = false">
                                <v-icon color="white">mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-divider></v-divider>

                        <v-card v-if="loadingTableEditar" class="center-item-in-div-column">
                            <v-progress-circular :size="150" :width="15" color="primary"
                                indeterminate></v-progress-circular>
                            <label class="overline font-weight-bold">Cargando información...</label>
                        </v-card>

                     
                        <v-card-text v-else>
                            <v-container class="compact-container">
                            
                              <v-row dense>
                                <v-col cols="4">
                                  <label class="font-weight-bold">EMPLEADO:</label>
                                </v-col>
                                <v-col cols="3">
                                  <v-text-field 
                                    v-model="registroEdit.empleado" 
                                    outlined 
                                    dense 
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                          
                              <!-- RESPONSABLE -->
                              <v-row dense>
                                <v-col cols="4">
                                  <label class="font-weight-bold">RESPONSABLE:</label>
                                </v-col>
                                <v-col cols="3">
                                  <v-text-field 
                                    v-model="registroEdit.responsable" 
                                    outlined 
                                    dense 
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                          
                             
                              <v-row dense justify="center">
                                <v-col cols="auto">
                                  <v-btn class="primary white--text text-uppercase font-weight-bold text-center"
                                   @click="valoresFinalesEdit">
                                    Aceptar
                                  </v-btn>
                                </v-col>
                                <v-col cols="auto">
                                  <v-btn class="primary white--text text-uppercase font-weight-bold text-center"
                                   @click="dialogEditar = false">
                                    Cancelar
                                  </v-btn>
                                </v-col>
                              </v-row>
                              
                            </v-container>
                          </v-card-text>
            
                    </v-card>
                  </v-dialog>
                </div>
              </template>

              <!-- Este es para Agregar un empleado -->

              <template>
                <div>

                   
                 
                  <!-- Diálogo -->
                  <v-dialog  v-model="dialogAgregar" max-width="650px">
                    <v-card class="rounded-xl p-0">
                        


                        <v-toolbar class="primary white--text text-uppercase  sticky-toolbar no-padding" flat dense>
                            <v-toolbar-title>AGREGAR registro de RelacionEmpleadoResponsable</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialogAgregar = false">
                                <v-icon color="white">mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-divider></v-divider>

                        <v-card v-if="loadingTableEditar" class="center-item-in-div-column">
                            <v-progress-circular :size="150" :width="15" color="primary"
                                indeterminate></v-progress-circular>
                            <label class="overline font-weight-bold">Cargando información...</label>
                        </v-card>

                     
                        <v-card-text v-else>
                            <v-container class="compact-container">
                           
                              <v-row dense>
                                <v-col cols="4">
                                  <label class="font-weight-bold">EMPLEADO:</label>
                                </v-col>
                                <v-col cols="3">
                                  <v-text-field 
                                    v-model="registroEdit.empleado" 
                                    outlined 
                                    dense 
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                          
                              <!-- RESPONSABLE -->
                              <v-row dense>
                                <v-col cols="4">
                                  <label class="font-weight-bold">RESPONSABLE:</label>
                                </v-col>
                                <v-col cols="3">
                                  <v-text-field 
                                    v-model="registroEdit.responsable" 
                                    outlined 
                                    dense 
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                          
    
                              <v-row dense justify="center">
                                <v-col cols="auto">
                                  <v-btn class="primary white--text text-uppercase font-weight-bold text-center"
                                   @click="metodoAgregar()">
                                    Aceptar
                                  </v-btn>
                                </v-col>
                                <v-col cols="auto">
                                  <v-btn class="primary white--text text-uppercase font-weight-bold text-center"
                                   @click="dialogAgregar = false">
                                    Cancelar
                                  </v-btn>
                                </v-col>
                              </v-row>
                              
                            </v-container>
                          </v-card-text>
            
                    </v-card>
                  </v-dialog>
                </div>
              </template>

              
              
              
              
              
                       
              
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ExcelJS from 'exceljs';
//import fs from 'fs'; // Si necesitas trabajar con rutas absolutas
import { saveAs } from 'file-saver';


export default {
    name: "empResponsable",
    props: [
        "Mes1", "Mes2", "Mes3", "iMes", "anio", "iAreaValorBase", "PrimerMesNumValorBase",
        "SegundoMesNumValorBase", "TercerMesNumValorBase", "banderaArea2", "banderaEmpResp",
        "numEmpleado","trimestre","anioEMP_Resp","trimestreEMP_Resp"
        
    ],
    data() {
        return {
          filters: {}, // Filtros individuales
            loadingTable: false, // aquí va true
            mostrarTabla: false, // Estado inicial: tabla oculta
            dialogVer: false,
            dialogEliminar: false,
            dialogEditar: false,
            dialogAgregar: false,
            searchColumn: "",
            registro: {
        id: null,
        empleado: null,
        responsable: null,
        anio: null,
        trimestre: null,
        fecha_actualizacion: null,
        usuario_modifica: null,
      
      },

      btnAct: false,
      btnAct2: false,


            searchQuery: "", // Filtro general
      sortColumn: "", // Columna seleccionada para ordenar
      sortOrder: "asc", // Orden seleccionado (ascendente por defecto)
      currentPage: 1, // Página actual
      rowsPerPage: 9, // Cantidad de filas por página
           // Personalización del pie de página
           footerProps: {
        itemsPerPageText: "Filas por página", // Traducción para "Rows per page"
        prevIcon: "mdi-chevron-left", // Ícono de anterior
        nextIcon: "mdi-chevron-right", // Ícono de siguiente
      },

      columns: [
        { text: "Acciones", value: "opc", sortable: false, class: "primary white--text text-uppercase font-weight-bold text-center rounded-simple-table-head-l" },
        { text: "NP Empleado", value: "empleado", class: "primary white--text text-uppercase font-weight-bold text-center" },
        { text: "Empleado Nombre", value: "emp_name", class: "primary white--text text-uppercase font-weight-bold text-center" },
        { text: "NP Responsable", value: "responsable", class: "primary white--text text-uppercase font-weight-bold text-center"},
        { text: "Responsable Nombre", value: "resp_name", class: "primary white--text text-uppercase font-weight-bold text-center rounded-simple-table-head-r" },
      ],

      placeholders: {
        empleado: "Buscar NP Empleado:",
        emp_name: "Buscar por nombre de empleado:",
        responsable: "Buscar NP Responsable",
        resp_name: "Buscar por nombre del responsable:",
      },
      tableData: [],
      loadingTableEditar: false,
      registroEdit :{},
      

      
      
        };
    },

    async mounted() {
         this.getEmpleados();
    },
    created() {
  this.searchQuery = ""; // Asegurar la inicialización
},

    methods: {
        // Limpiar todos los filtros
  
    clearAllFilters() {


    // Validar `searchQuery`
    if (this.searchQuery !== null && this.searchQuery !== undefined) {
      this.searchQuery = "";
    } else {
      console.warn("searchQuery no está definido.");
    }

    // Validar `filters`
    if (this.filters && typeof this.filters === "object") {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = ""; // Limpiar todos los filtros individuales
      });
    } else {
      console.warn("filters no está definido o no es un objeto.");
    }
  },

       // Función para ordenar la tabla
  sortTable(column) {
    if (this.sortColumn === column) {
      // Alternar orden si se hace clic en la misma columna
      this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
    } else {
      // Orden ascendente al seleccionar una nueva columna
      this.sortColumn = column;
      this.sortOrder = "asc";
    }
  },
      
       

  async valoresFinalesEdit(){
    this.loadingTableEditar = true;

      const datosEdit = {
       
        empleado: this.registroEdit.empleado,
        responsable: this.registroEdit.responsable,
      
        numEmpleado: this.numEmpleado
      }

           try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/editar-empAdmin`, datosEdit, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                console.log('Todos los registros se editaron correctamente:', response.data);
                this.loadingTableEditar = false;
                this.dialogEditar = false;
                this.getEmpleados();
                
            } catch (error) {
                console.error('Error al editar los datos:', error);
                this.loadingTableEditar = false;
                this.dialogEditar = false;
               
            }

  },

 


  openAgregar(){
    this.dialogAgregar = true;
    this.registroEdit = {};

  },
  async metodoAgregar(){
    this.loadingTableEditar = true;

      const datosEdit = {
        empleado: this.registroEdit.empleado,
        responsable: this.registroEdit.responsable,
        
        
      }
      

           try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/agregar-emp-Admin`, datosEdit, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                console.log('Todos los registros se agregaron correctamente:', response.data);
                this.loadingTableEditar = false;
                this.dialogAgregar = false;
                this.getEmpleados();
                this.registroEdit = {};
                
            } catch (error) {
                console.error('Error al agregar los datos:', error);
                this.loadingTableEditar = false;
                this.dialogAgregar = false;
                this.registroEdit = {};
               
            }

  },

 

    async editRow(row){
            this.dialogEditar = true;

            this.registroEdit = {
          
            empleado: row.empleado,
            responsable: row.responsable,
         
            };
        },
        eliminar(row){
            this.dialogEliminar = true;
            this.registro = {
          
            empleado: row.empleado,
            responsable: row.responsable,
        
            };
        },

    async deleteRow() {
        this.loadingTableEditar = true;
   
      
      // Lógica para eliminar la fila
      try {
                const response = await axios.delete(`${this.$store.getters.getHostNode}/api/eliminarEmpAdmin/${this.registro.empleado}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                console.log('Todos los registros se eliminaron correctamente:', response.data);
                this.loadingTableEditar = false;
                this.dialogEliminar = false;
                this.getEmpleados();
                
            } catch (error) {
                console.error('Error al editar los datos:', error);
                this.loadingTableEditar = false;
                this.dialogEliminar = false;
               
            }
    },

    viewRow(row) {
      
      this.dialogVer = true;
      this.registro = {
     
      empleado: row.empleado,
      responsable: row.responsable,
    
    };
      // Lógica para ver detalles de la fila
    },
        Regresar() {
            
            this.$emit('valorRegresar2Resp', false);
        
        },
        changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },


    //Esto es para traer a los empleados
    async getEmpleados() {
        this.loadingTable = true;
    

    try {
        // Realizar la solicitud para obtener los datos
   
           const response = await axios.get(`${this.$store.getters.getHostNode}/api/getEmpRespAdmin`, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            });
      
        // Mapear los datos y asignarlos a la tablaObjAsignados
        this.tableData = response.data.data.map((row) => {
            return {
              
                empleado: row.numEmpleado,
                emp_name: row.nombreEmpleado || 'EMPLEADO BAJA',
                responsable: row.numResponsable,
                resp_name: row.nombreResponsable || 'EMPLEADO BAJA',
              
                
            };
        });

      
        this.loadingTable = false;

       
    } catch (error) {
        console.error('Error fetching data:', error);
        this.loadingTable = false;
        
    }
},



 // Función para exportar los datos a Excel

loadImageAsBase64(imgPath) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imgPath;
    img.crossOrigin = "Anonymous"; // Asegúrate de que no haya problemas de CORS
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      resolve(dataURL.split(",")[1]); // Base64 sin el prefijo
    };
    img.onerror = (err) => reject(err);
  });
},

exportToExcel(datosReporte) {
  const logoPath = require("@/assets/img/grupo_urrea_azul_gris.png"); // Ajusta esta ruta según tu estructura

  // Crear un libro de trabajo
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Reporte Relación Empleado Responsable");

  // Convertir la imagen a Base64
  this.loadImageAsBase64(logoPath)
    .then((base64Image) => {
      // Cargar la imagen en el workbook
      const imageId = workbook.addImage({
        base64: base64Image,
        extension: "png",
      });

      // Insertar la imagen en la hoja
      worksheet.addImage(imageId, {
        tl: { col: 1.5, row: 0.5 }, // Top-left: columna y fila
        ext: { width: 450, height: 100 }, // Ancho y alto de la imagen
      });

      // Agregar encabezados personalizados
      worksheet.mergeCells("B2:D2");
      worksheet.mergeCells("B5:D5");
      worksheet.getCell("C7").value = "Reporte";
      worksheet.getCell("C7").font = { bold: true, size: 14 };
      worksheet.getCell("C8").value =
        new Date().toLocaleDateString() + " - " + new Date().toLocaleTimeString();

      // Agregar datos al Excel desde la fila 9
      const headerRow = worksheet.addRow([
        "NP Empleado",
        "Nombre Empleado",
        "NP Responsable",
        "Nombre Responsable",
      ]);
      headerRow.font = { bold: true };

      datosReporte.forEach((dato) => {
        worksheet.addRow([dato.empleado, dato.emp_name, dato.responsable, dato.resp_name]);
      });

      // Ajustar el ancho de las columnas
      worksheet.columns = [
        { width: 20 }, // NP Empleado
        { width: 50 }, // Nombre Empleado
        { width: 20 }, // NP Responsable
        { width: 50 }, // Nombre Responsable
      ];

      // Guardar el archivo como un blob
      return workbook.xlsx.writeBuffer();
    })
    .then((buffer) => {
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      saveAs(blob, "Reporte Relación Empleado Responsable.xlsx");
    })
    .catch((err) => {
      console.error("Error al generar el archivo Excel:", err);
    });
},

async funcionExcel() {
  this.btnAct2 = true;
  try {
    // Usa los datos filtrados directamente desde la tabla
    const datosReporte = this.filteredData.map((row) => {
      return {
        empleado: row.empleado,
        emp_name: row.emp_name || "EMPLEADO BAJA",
        responsable: row.responsable,
        resp_name: row.resp_name || "EMPLEADO BAJA",
      };
    });

    // Llama al método para exportar el Excel con los datos filtrados
    this.exportToExcel(datosReporte);
  } catch (error) {
    console.error("Error al generar el reporte:", error);
  } finally {
    this.btnAct2 = false;
  }
},


        
    },

    watch:{
        searchQuery() {
    // Si cambia la búsqueda, regresa a la página 1
    this.currentPage = 1;
  },

    },
    computed: {
     

  // filteredData() {
  //     // Filtro general (buscar en todas las columnas)
  //     const searchQueryLower = this.searchQuery.toLowerCase();

  //     if (!this.searchQuery) {
  //     return this.tableData; // Si no hay búsqueda, muestra todos los datos
  //   }

  //     return this.tableData.filter((row) => {
  //       // Filtrar por el campo general
  //       const matchesSearchQuery = Object.values(row).some((value) => {
  //         if (!value) return false;
  //         return value.toString().toLowerCase().includes(searchQueryLower);
  //       });

  //       // Filtrar por los campos individuales
  //       const matchesColumnFilters = Object.keys(this.filters).every((key) => {
  //         if (!this.filters[key]) return true; // Si no hay filtro, incluir todas las filas
  //         const value = row[key] ? row[key].toString().toLowerCase() : "";
  //         return value.includes(this.filters[key].toLowerCase());
  //       });

  //       return matchesSearchQuery && matchesColumnFilters;
  //     });
  //   },
  filteredData() {
    if (!this.searchQuery && !Object.values(this.filters).some((filter) => filter)) {
      // Si no hay búsqueda ni filtros aplicados, devuelve todos los datos
      return this.tableData;
    }

    const searchQueryLower = this.searchQuery ? this.searchQuery.toLowerCase() : "";

    return this.tableData.filter((row) => {
      // Filtrar por el campo general
      const matchesSearchQuery = !this.searchQuery || Object.values(row).some((value) => {
        if (!value) return false;
        return value.toString().toLowerCase().includes(searchQueryLower);
      });

      // Filtrar por los campos individuales
      const matchesColumnFilters = Object.keys(this.filters).every((key) => {
        if (!this.filters[key]) return true; // Si no hay filtro en esa columna, incluir todas las filas
        const value = row[key] ? row[key].toString().toLowerCase() : "";
        return value.includes(this.filters[key].toLowerCase());
      });

      return matchesSearchQuery && matchesColumnFilters;
    });
  },
  sortedData() {
    if (!this.sortColumn) return this.filteredData;
    return [...this.filteredData].sort((a, b) => {
      const valA = a[this.sortColumn];
      const valB = b[this.sortColumn];
      return this.sortOrder === "asc"
        ? valA > valB ? 1 : -1
        : valA < valB ? 1 : -1;
    });
  },
  paginatedData() {
    const start = (this.currentPage - 1) * this.rowsPerPage;
    const end = start + this.rowsPerPage;
    return this.sortedData.slice(start, end);
  },
  totalPages() {
    return Math.ceil(this.sortedData.length / this.rowsPerPage);
  },
      
   
  
  
  },

};
</script>

<style>
.rounded-simple-table-head-l {
    border-top-left-radius: 22px !important;
}

.rounded-simple-table-head-r {
    border-top-right-radius: 22px !important;
}

.centrado {
    text-align: center;
    font-size: 18px;
}


  
.input-with-icon {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .search-bar {
    flex: 1;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .select-order,
  .page-select {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center; /* Centrar todo horizontalmente */
    gap: 20px; /* Espaciado entre elementos */
    margin-bottom: 10px;
  }
  .pagination-text {
    font-size: 16px; /* Tamaño de texto */
    text-align: center;
  }
  
  .pagination-icon {
    font-size: 24px; /* Tamaño de íconos */
    color: gray;
    cursor: pointer;
    user-select: none;
  }
  
  .pagination-icon:disabled {
    color: lightgray;
    cursor: not-allowed;
  }


  .rounded-top-left {
    border-top-left-radius: 22px; /* Esquina superior izquierda */
  }
  
  .rounded-top-right {
    border-top-right-radius: 22px; /* Esquina superior derecha */
  }
  
  .compact-container {
    margin-top: -10px; /* Reduce espacio superior */
    margin-bottom: -10px; /* Reduce espacio inferior */
  }
  
  .v-row {
    margin-top: 4px; /* Ajusta el espacio entre filas */
    margin-bottom: 4px; /* Ajusta el espacio entre filas */
  }
  
  .font-weight-bold {
    font-weight: bold;
  }

  .excel-green {
    background-color: #217346 !important;
    /* Color verde de Excel */
    color: white !important;
    /* Texto en blanco */
}


.search-bar {
  width: 100%;
  padding: 5px;
  font-size: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.search-bar-tabla {
  width: 100%; /* Mantiene el ancho completo del contenedor */
  padding: 5px; /* Espaciado interno */
  font-size: 15px; /* Tamaño de fuente */
  border: 1px solid #ddd; /* Borde */
  border-radius: 4px; /* Bordes redondeados */
  box-sizing: border-box; /* Asegura que el tamaño incluya bordes y padding */
  margin-left: auto; /* Mueve el campo de búsqueda hacia la derecha */
  margin-right: 10px; /* Espaciado adicional desde el borde derecho */
  text-align: left; /* Mantiene el texto alineado dentro del campo */
}

/* Asegura que los encabezados no interfieran con la alineación */
th {
  text-align: right; /* Alinea el contenido del encabezado a la derecha */
  padding-right: 10px; /* Espaciado adicional desde el borde derecho */
}



  
</style>

