<template>
  <div>
    <v-dialog
      v-model="marcaIngreso"
      min-width="80%"
      max-width="85%"
      content-class="fix-border-dialog"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title class="primary">
          <div class="text-uppercase white--text subtitle-1 font-weight-bold">
            Ingreso [{{ datosVisitante.int }}]
          </div>
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('cierraGeneraIngreso', false)">mdi-close</v-icon>
        </v-card-title>
        <v-card-subtitle>
          <div class="center-item-in-div pt-5">
            <v-card
              class="rounded-xl mx-1"
              color="primary center-item-in-div-column"
              width="250"
              height="170"
              @click="
                capturarImagen = true;
                startCamera();
              "
            >
              <v-icon dark x-large>mdi-camera-plus-outline</v-icon>
              <div class="overline white--text">
                Tomar foto
              </div>
            </v-card>
            <v-card
              class="rounded-xl mx-1"
              color="primary center-item-in-div-column"
              width="250"
              height="170"
              @click="gafeteImpreso = true; printGaffete()"
            >
              <v-icon dark x-large>mdi-card-account-details-outline</v-icon>
              <div class="overline white--text">
                Imprimir gafete
              </div>
            </v-card>
          </div>
        </v-card-subtitle>
        <v-card-text>
          <div id="impresion" style="display: none;">
            <center>
              <img
                alt=""
                :src="require('@/assets/img/grupo_urrea_negro.png')"
                aling="absmiddle"
                
                style="margin-bottom: 4px;width:800px; margin-top: 12px;"
              />
            </center>
            <div
              id="results"
              style="float: left;width: 30%;margin-top: 36px;"
            >
              <img alt="" :src="photoData" aling="absmiddle" width="205px" />
            </div>
            <div style="float: left;width: 70%;">
              <div
                style="text-transform: uppercase; font-family: Arial, sans-serif; font-size: 3.2rem;"
              >
                {{
                  datosVisitante.nombre +
                    " " +
                    datosVisitante.apellido_paterno +
                    " " +
                    datosVisitante.apellido_materno
                }}
              </div>
              <div style="font-family: Arial, sans-serif;  font-size: 3.2rem;">
                {{ datosVisitante.correo }}
              </div>
              <div style="font-family: Arial, sans-serif;  font-size: 3.2rem;">
                <strong>ID Visitante: </strong>{{ datosVisitante.int }}
              </div>
              <div style="font-family: Arial, sans-serif;  font-size: 3.2rem;">
                <strong>ID Visita: </strong>{{ datosVisitante.id_visitante }}
              </div>
            </div>
            <br />
          </div>

          <label class="overline">Articulos personales</label>
          <v-card
            class="pa-5 rounded-lg elevation-0"
            color="blue-grey lighten-5"
            width="100%"
          >
            <div
              class="text-center body-2"
              v-if="
                datosVisitante.registroArtPersonales === 0 ||
                  articulosPersonales.length == 0
              "
            >
              El usuario no registro articulos personales.
            </div>
            <div v-else>
              <v-simple-table dense style="background-color: transparent;" >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>MATERIAL</th>
                    <th class="text-center">EVIDENCIA</th>
                    <th class="text-center">DETALLES</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in articulosPersonales" :key="index">
                    <td>{{ item.ID }}</td>
                    <td>{{ item.NOMBRE }}</td>
                    <td class="text-center">
                      <v-btn
                        class="rounded-lg"
                        color="cyan accent-4"
                        depressed
                        dark
                        small
                        @click="
                          openFile(item.ID, 'arts_personales', item.FILE_TITLE)
                        "
                      >
                        <v-icon left>mdi-image</v-icon>
                        ver
                      </v-btn>
                    </td>
                    <td class="text-center">
                      <v-btn
                        class="rounded-lg"
                        color="teal accent-4"
                        depressed
                        dark
                        small
                        @click="
                          itemSeleccionado = item;
                          verDetalles = true;
                        "
                      >
                        <v-icon left>mdi-information-variant-box</v-icon>
                        ver
                      </v-btn>
                    </td>
                    <td class="text-center">
                      <v-checkbox
                        v-model="item.VALIDO_CASETA"
                        style="max-height: 22px;"
                        class="py-0 my-0"
                        @change="cambiaEstatusValidacion(item.ID,item.VALIDO_CASETA,26)"
                      ></v-checkbox>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-card>

          <label class="overline">Consumibles</label>
          <v-card
            class="pa-5 rounded-lg elevation-0"
            color="blue-grey lighten-5"
            width="100%"
          >
            <div
              class="text-center body-2"
              v-if="
                datosVisitante.registroConsumibles === 0 ||
                  consumibles.length == 0
              "
            >
              El usuario no registro consumibles.
            </div>
            <div v-else>
              <v-simple-table dense style="background-color: transparent;">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>MATERIAL</th>
                    <th class="text-center">EVIDENCIA</th>
                    <th class="text-center">DETALLES</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in consumibles" :key="index">
                    <td>{{ item.ID }}</td>
                    <td>{{ item.NOMBRE }}</td>
                    <td class="text-center">
                      <v-btn
                        class="rounded-lg"
                        color="cyan accent-4"
                        depressed
                        dark
                        small
                        @click="
                          openFile(item.ID, 'consumibles', item.FILE_TITLE)
                        "
                      >
                        <v-icon left>mdi-image</v-icon>
                        ver
                      </v-btn>
                    </td>
                    <td class="text-center">
                      <v-btn
                        class="rounded-lg"
                        color="teal accent-4"
                        depressed
                        dark
                        small
                        @click="
                          itemSeleccionado = item;
                          verDetalles = true;
                        "
                      >
                        <v-icon left>mdi-information-variant-box</v-icon>
                        ver
                      </v-btn>
                    </td>
                    <td class="text-center">
                      <v-checkbox
                        v-model="item.VALIDO_CASETA"
                        style="max-height: 22px;"
                        class="py-0 my-0"
                        @change="cambiaEstatusValidacion(item.ID,item.VALIDO_CASETA, 27)"
                      ></v-checkbox>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-card>

          <label class="overline">Herramientas</label>
          <v-card
            class="pa-5 rounded-lg elevation-0"
            color="blue-grey lighten-5"
            width="100%"
          >
            <div
              class="text-center body-2"
              v-if="
                datosVisitante.registroHerramientas === 0 ||
                  herramientas.length == 0
              "
            >
              El usuario no registro herramientas.
            </div>
            <div v-else>
              <v-simple-table dense style="background-color: transparent;">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>MATERIAL</th>
                    <th class="text-center">EVIDENCIA</th>
                    <th class="text-center">DETALLES</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in herramientas" :key="index">
                    <td>{{ item.ID }}</td>
                    <td>{{ item.NOMBRE }}</td>
                    <td class="text-center">
                      <v-btn
                        class="rounded-lg"
                        color="cyan accent-4"
                        depressed
                        dark
                        small
                        @click="
                          openFile(item.ID, 'herramientas', item.FILE_TITLE)
                        "
                      >
                        <v-icon left>mdi-image</v-icon>
                        ver
                      </v-btn>
                    </td>
                    <td class="text-center">
                      <v-btn
                        class="rounded-lg"
                        color="teal accent-4"
                        depressed
                        dark
                        small
                        @click="
                          itemSeleccionado = item;
                          verDetalles = true;
                        "
                      >
                        <v-icon left>mdi-information-variant-box</v-icon>
                        ver
                      </v-btn>
                    </td>
                    <td class="text-center">
                      <v-checkbox
                        v-model="item.VALIDO_CASETA"
                        style="max-height: 22px;"
                        class="py-0 my-0"
                        @change="cambiaEstatusValidacion(item.ID,item.VALIDO_CASETA,28)"
                      ></v-checkbox>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-card>

          <label class="overline">Vehículo</label>
          <v-card
            class="pa-5 rounded-lg elevation-0"
            color="blue-grey lighten-5"
            width="100%"
          >
            <div
              class="text-center body-2"
              v-if="
                datosVisitante.registroVehiculo === 0 || vehiculos.length == 0
              "
            >
              El usuario no registro vehículo.
            </div>
            <div v-else>
              <v-row>
                <v-col cols="3" v-show="imageVehiculo !== null">
                  <v-img
                    :src="imageVehiculo"
                    width="100%"
                    class="rounded-lg"
                  ></v-img>
                </v-col>
                <v-col cols="8">
                  <div class="body-2">
                    <strong class="text-uppercase">Tipo de vehículo: </strong
                    >{{ vehiculos[0].TIPO_VEHICULO }}
                  </div>
                  <div class="body-2">
                    <strong class="text-uppercase">Tipo de licencia: </strong
                    >{{ vehiculos[0].TIPO_LICENCIA }}
                  </div>
                  <div class="body-2">
                    <strong class="text-uppercase">Número de licencia: </strong
                    >{{ vehiculos[0].NUMERO_LICENCIA }}
                  </div>
                  <div class="body-2">
                    <strong class="text-uppercase">Número económico: </strong
                    >{{ vehiculos[0].NUMERO_ECONOMICO }}
                  </div>
                  <div class="body-2">
                    <strong class="text-uppercase">Placas: </strong
                    >{{ vehiculos[0].PLACAS }}
                  </div>
                </v-col>
                <v-col cols="1" class="center-item-in-div">
                  <!-- <input type="checkbox" :value="vehiculos[0].VALIDO_CASETA" /> -->
                      <v-checkbox
                        v-model="vehiculos[0].VALIDO_CASETA"
                        style="max-height: 22px;"
                        class="py-0 my-0"
                        @change="cambiaEstatusValidacion(vehiculos[0].ID,vehiculos[0].VALIDO_CASETA,29)"
                      ></v-checkbox>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <SnackBar
            v-if="muestraBarra"
            :muestraBarra="muestraBarra"
            :color="colorBarra"
            :texto="textoBarra"
            @cierraBarra="muestraBarra = false"
          />
        </v-card-text>
        <!-- <v-divider></v-divider> -->
        <v-card-actions class="pa-3 center-item-in-div">
          <v-btn
            x-large
            style="min-width: 200px;"
            color="teal darken-1"
            class="rounded-lg font-weight-bold"
            :dark="photoData === null && !gafeteImpreso ? false : true"
            :disabled="photoData === null && !gafeteImpreso ? true : false"
            :loading="loadingButtonSave"
            @click="loadingButtonSave = true; RegistraIngreso()"
          >
            <v-icon left>mdi-content-save-move-outline</v-icon>
            Guardar ingreso
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-dialog v-model="verImagen" max-width="500" persistent>
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-icon
              @click="
                verImagen = false;
                imageSelected = null;
              "
              >mdi-close</v-icon
            >
          </v-card-title>
          <v-card-text>
            <v-img
              :src="imageSelected"
              class="evidencia-material-visitante"
            ></v-img>
          </v-card-text>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="capturarImagen"
        max-width="500"
        persistent
        content-class="fix-border-dialog"
      >
        <v-card class="rounded-xl">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-icon
              @click="
                capturarImagen = false;
                stopCamera();
              "
              >mdi-close</v-icon
            >
          </v-card-title>
          <v-card-text class="pt-4">
            <div>
              <video
                ref="video"
                autoplay
                style="transform: scaleX(-1); max-width: 100%; border-radius: 8px;"
              ></video>
              <!-- <button @click="startCamera">Activar Cámara</button>
                    <button @click="takePhoto">Tomar Foto</button> -->
              <canvas ref="canvas" style="display: none;"></canvas>
              <!-- <img :src="photoData" alt="Foto" v-if="photoData" /> -->
            </div>
          </v-card-text>
          <v-card-actions class="center-item-in-div-column">
            <v-btn color="teal darken-1" dark class="elevation-1" fab>
              <v-icon style="font-size: 52px;" @click="takePhoto"
                >mdi-circle-slice-8</v-icon
              >
            </v-btn>
            <label class="overline teal--text text--darken-1">Tomar foto</label>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="verDetalles"
        width="500"
        content-class="fix-border-dialog"
      >
        <v-card>
          <v-card-title
            class="subtitle-2 text-uppercase font-weight-bold grey lighten-2"
          >
            Detalles
          </v-card-title>

          <v-card-text class="pt-4">
            <v-row dense v-if="itemSeleccionado">
              <v-col cols="6">
                <v-card
                  class="rounded-lg pa-4 elevation-0"
                  color="blue-grey lighten-5"
                >
                  <label class="overline">marca</label>
                  <div class="body-2">
                    {{
                      itemSeleccionado.MARCA !== undefined
                        ? itemSeleccionado.MARCA
                        : ""
                    }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card
                  class="rounded-lg pa-4 elevation-0"
                  color="blue-grey lighten-5"
                >
                  <label class="overline">cantidad</label>
                  <div class="body-2">
                    {{
                      itemSeleccionado.CANTIDAD !== undefined
                        ? itemSeleccionado.CANTIDAD
                        : ""
                    }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card
                  class="rounded-lg pa-4 elevation-0"
                  color="blue-grey lighten-5"
                >
                  <label class="overline">descripcion</label>
                  <div class="body-2">
                    {{
                      itemSeleccionado.DESCRIPCION !== undefined
                        ? itemSeleccionado.DESCRIPCION
                        : ""
                    }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card
                  class="rounded-lg pa-4 elevation-0"
                  color="blue-grey lighten-5"
                >
                  <label class="overline">Número de serie</label>
                  <div class="body-2">
                    {{
                      itemSeleccionado.NO_SERIE !== undefined
                        ? itemSeleccionado.NO_SERIE
                        : ""
                    }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card
                  class="rounded-lg pa-4 elevation-0"
                  color="blue-grey lighten-5"
                >
                  <label class="overline">fecha</label>
                  <div class="body-2">
                    {{
                      itemSeleccionado.FECHA_MODIFICA !== undefined
                        ? itemSeleccionado.FECHA_MODIFICA.date.substring(0, 10)
                        : ""
                    }}
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="pa-0">
            <v-btn
              color="primary"
              class="rounded-xl rounded-t-0"
              x-large
              block
              text
              @click="verDetalles = false"
            >
              cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
  </div>
</template>

<script>
import { deleteToken } from "../../../../../store/Funciones/funciones.js";
import axios from "axios";

import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "genera-ingreso-visitante",
  props: ["marcaIngreso", "datosVisitante"],
  components: { SnackBar },
  data() {
    return {
      articulosPersonales: [],
      consumibles: [],
      herramientas: [],
      vehiculos: [],
      itemSeleccionado: {},
      imageSelected: null,
      videoStream: null,
      photoData: null,
      imageVehiculo: null,
      verImagen: false,
      capturarImagen: false,
      verDetalles: false,
      muestraBarra: false,
      gafeteImpreso: false,
      loadingButtonSave: false,
      textoBarra: "",
      colorBarra: "",
    };
  },
  async mounted() {
    if (this.datosVisitante.registroArtPersonales !== 0) {
      this.articulosPersonales = await this.getArticulosPersonales().catch(
        (e) => {
          console.log(e);
        }
      );
    }
    if (this.datosVisitante.registroConsumibles !== 0) {
      this.consumibles = await this.getConsumibles().catch((e) => {
        console.log(e);
      });
    }
    if (this.datosVisitante.registroHerramientas !== 0) {
      this.herramientas = await this.getHerramientas().catch((e) => {
        console.log(e);
      });
    }
    if (this.datosVisitante.registroVehiculo !== 0) {
      this.vehiculos = await this.getVehiculos().catch((e) => {
        console.log(e);
      });
      this.openFileCar(
        this.vehiculos[0].ID,
        "vehiculo",
        this.vehiculos[0].FILE_TITLE
      );
    }
  },
  methods: {
    muestraSnackBar(color, texto) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    async getArticulosPersonales() {
      const params = {
        Opcion: 15,
        token: localStorage.getItem("token"),
        idVisitante: this.datosVisitante.int,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.materiales);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingArticulosPersonales = false;
          });
      });
    },
    async getConsumibles() {
      const params = {
        Opcion: 16,
        token: localStorage.getItem("token"),
        idVisitante: this.datosVisitante.int,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.materiales);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingConsumibles = false;
          });
      });
    },
    async getHerramientas() {
      const params = {
        Opcion: 17,
        token: localStorage.getItem("token"),
        idVisitante: this.datosVisitante.int,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.materiales);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingHerramientas = false;
          });
      });
    },
    async getVehiculos() {
      const params = {
        Opcion: 18,
        token: localStorage.getItem("token"),
        idVisitante: this.datosVisitante.int,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.materiales);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingVehiculos = false;
          });
      });
    },
    async openFile(idMaterial, tipo, file) {
      this.imageSelected = null;
      let params = {
        Opcion: 20,
        token: localStorage.getItem("token"),
        idVisitante: this.datosVisitante.int,
        filename: idMaterial + "_" + file,
        tipo: tipo,
      };

      try {
        const response = await axios.post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        );

        if (response.data.status === "OK") {
          this.imageSelected = response.data.file;
          this.verImagen = true;
        } else {
          alert(
            "Parece que el archivo está dañado o no existe. Se recomienda reemplazar."
          );
        }
      } catch (error) {
        console.error("Ocurrió un error:", error);
        alert("Ocurrió un error al descargar el archivo.");
      }
    },
    async openFileCar(idMaterial, tipo, file) {
      this.imageSelected = null;
      let params = {
        Opcion: 20,
        token: localStorage.getItem("token"),
        idVisitante: this.datosVisitante.int,
        filename: idMaterial + "_" + file,
        tipo: tipo,
      };

      try {
        const response = await axios.post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        );

        if (response.data.status === "OK") {
          this.imageVehiculo = response.data.file;
        } else {
          alert(
            "Parece que el archivo está dañado o no existe. Se recomienda reemplazar."
          );
        }
      } catch (error) {
        console.error("Ocurrió un error:", error);
        alert("Ocurrió un error al descargar el archivo.");
      }
    },
    async startCamera() {
      try {
        this.videoStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        this.$refs.video.srcObject = this.videoStream;
      } catch (error) {
        console.error("Error al acceder a la cámara:", error);
      }
    },
    stopCamera() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => track.stop());
        this.$refs.video.srcObject = null;
        this.videoStream = null;
      }
    },
    takePhoto() {
      if (this.videoStream) {
        const canvas = this.$refs.canvas;
        const video = this.$refs.video;
        canvas.width = video.offsetWidth;
        canvas.height = video.offsetHeight;
        const context = canvas.getContext("2d");
        context.scale(-1, 1);
        context.drawImage(video, 0, 0, canvas.width * -1, canvas.height);
        context.scale(-1, 1);
        this.photoData = canvas.toDataURL("image/png");
        this.videoStream.getTracks().forEach((track) => track.stop());
        this.capturarImagen = false;
        this.stopCamera();
      }
    },
    printGaffete() {
      let ficha = document.getElementById("impresion");
      var ventanaImpresion = window.open(" ", "popUp");
      ventanaImpresion.document.write(
        "<html><head><link href='estilos/bootstrap.min.css' rel='stylesheet'><style>.col-sm-2 {width: 16.66666667%;}.col-sm-6 {width: 50%;max-width: 50% }.col{float:left; position: relative;min-height: 1px; padding-right: 15px;padding-left: 15px;}.imgfoto{width: 300px!important;height: 325px!important;}.foto{margin-left: 15px;width: 270px;} center {display: block;text-align: -webkit-center;}img{width:300Px;text-align: -webkit-center;}body{width:1100px;font-size: 46px}</style></head>"
      );
      ventanaImpresion.document.write("<body>");
      ventanaImpresion.document.write(ficha.innerHTML);
      ventanaImpresion.document.write("</body>");
      ventanaImpresion.document.write("</html>");
      ventanaImpresion.document.close();
      ventanaImpresion.print();
      ventanaImpresion.close();
      
    },
    cambiaEstatusValidacion(id, status, opcion) {
      // console.log(status, id, opcion)
      let estatusAux = status ? 1 : 0;
      const params = {
        Opcion: opcion,
        token: localStorage.getItem("token"),
        idMaterial: id,
        status: estatusAux,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status === "OK") {
            if (response.data.respuesta.status_tran == "OK") {
              this.muestraSnackBar(
                "teal darken-1",
                response.data.respuesta.message
              );
            } else {
              this.muestraSnackBar(
                "pink darken-3",
                response.data.respuesta.message
              );
            }
          } else {
            this.muestraSnackBar(
              "pink darken-3",
              "No se pudo registrar el item. " + this.articuloName
            );
          }
        })
        .catch((e) => {
          this.muestraSnackBar("pink darken-3", e);
        });
    },
    RegistraIngreso(){
      if(!this.gafeteImpreso){
        this.muestraSnackBar("pink darken-3", "Aun no se imprime el gafete.");
        this.loadingButtonSave = false;
      } else {
        const params = {
        Opcion: 30,
        token: localStorage.getItem("token"),
        idVisitante: this.datosVisitante.int,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status === "OK") {
            if (response.data.respuesta.status_tran == "OK") {
              this.muestraSnackBar(
                "teal darken-1",
                response.data.respuesta.message
              );
              this.$emit('cierraGeneraIngreso', true);
            } else {
              this.muestraSnackBar(
                "pink darken-3",
                response.data.respuesta.message
              );
            }
          } else {
            this.muestraSnackBar(
              "pink darken-3",
              "No se pudo registrar el item. " + this.articuloName
            );
          }
        })
        .catch((e) => {
          this.muestraSnackBar("pink darken-3", e);
        })
        .finally(() => {
          this.loadingButtonSave = false;
        });
      }
    }
  },
};
</script>

<style>
.evidencia-material-visitante {
  width: 100%;
  height: auto;
}
</style>
