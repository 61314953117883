<template>
  <v-card class="rounded-lg elevation-0" width="100%">
    <v-data-table
      :headers="headers"
      :items="materialesItems"
      :search="search"
      no-data-text="No hay registros."
      loading-text="Cargando información..."
      :loading="loadingMateriales"
      :footer-props="{
        'items-per-page-text': 'Elementos por página',
        'pageText': '{0}-{1} de {2}',
      }"
      style="background-color:#ECEFF1"
    >
      <template v-slot:[`item.items`]="{ item }">
        <label v-for="(itemlist, itemIndex) in item.items" :key="itemIndex">
          <input type="checkbox" disabled/> {{ itemlist.nombreItem }}
        </label>
      </template>
      <template v-slot:top>
        <div style="display: flex; justify-content: space-between;">
          <v-text-field
            v-model="search"
            outlined dense clearable
            class="rounded-xl"
            style="max-width: 300px;"
            prepend-inner-icon="mdi-table-search"
            placeholder="Buscar..."
          ></v-text-field>
          <div class="text-center">
            <v-btn color="teal darken-1" class="rounded-lg" x-large depressed dark @click="modalCrearMaterial();">
              <v-icon left>mdi-plus</v-icon>crear material
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:item.Estatus="{ item }">
        <v-chip small :class="getClass(item.Estatus)">
          {{ item.Estatus === 1 ? "Activo" : item.Estatus === 2 ? "Deshabilitado" : "No definido" }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          fab depressed
          x-small
          dark
          color="blue accent-2"
          class="mr-1"
          @click="editarItems(item);"
          style="max-height: 25px !important; max-width: 25px !important;"
          v-if="item.Estatus <= 1"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          fab depressed
          x-small
          dark
          color="pink darken-3"
          class="mr-1"
          @click="deshabilitarMaterial(item)"
          style="max-height: 25px !important; max-width: 25px !important;"
          v-if="item.Estatus <= 1"
        >
          <v-icon small>mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <crearMAterial v-if="verCrearMaterial" :idMaterial="eliminarItem" :verCrearMaterial="verCrearMaterial" @cierraCrearMaterial="cierraCrearMaterial"/>

    <v-dialog v-model="modalDeshabilitar" persistent max-width="450">
      <v-card class="rounded-lg">
        <v-card-text class="pt-6">
          <p class="font-weight-bold subtitle-1 text-center text-uppercase">
            ¿Realmente deseas deshabilitar <strong>{{nameSelected}}</strong>?
            <br/>
            Ya no se podra usar en las solicitudes
          </p>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: space-around;">
          <v-btn style="width: 46%;" class="rounded-lg elevation-0" dark color="pink darken-3" @click="modalDeshabilitar = false" :disabled="loadingEliminando">cancelar</v-btn>
          <v-btn style="width: 46%;" class="rounded-lg elevation-0" dark color="teal darken-1" @click="loadingEliminando = true; deleteMaterial();" :loading="loadingEliminando">deshabilitar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar v-if="muestraBarra" :muestraBarra="muestraBarra" :texto="textoBarra" :color="colorBarra" @cierraBarra="muestraBarra = false" />

  </v-card>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones.js";
import SnackBar from '../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue'

import crearMAterial from "./MaterialItemsAlta.vue";

export default {
  name: "listado-mercancia-items",
  props: [""],
  components: { crearMAterial, SnackBar },
  data() {
    return {
      headers: [
        {
          text: "Material",
          value: "NombreMaterial",
          class: "overline font-weight-bold primary white--text rounded-left-top-table-lg",
        },
        {
          text: "Items",
          value: "items",
          class: "overline font-weight-bold primary white--text",
        },
        {
          text: "Estatus",
          value: "Estatus",
          align: "center",
          class: "overline font-weight-bold primary white--text",
        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          class: "overline font-weight-bold primary white--text rounded-right-top-table-lg",
        },
      ],
      verCrearMaterial: false,
      loadingMateriales: true,
      search: null,
      materialesItems: [],

      modalDeshabilitar: false,
      nameSelected: "",
      loadingEliminando: false,
      eliminarItem: 0,

      muestraBarra: false,
      textoBarra: "",
      colorBarra: "",

    }; // return
  }, // data

  methods: {
    getMaterialCreados() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      console.log(localStorage);
      const params = {
        usuarioMovimiento:  localStorage.getItem("folio"),
        verTodo: true
      };
      return new Promise((res) => {
        axios
        .get(`${this.$store.getters.getHostNode}/api/read-material-items`,
        {
          headers: headers,
          params
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            res(response.data.materialListado);
            this.materialesItems = response.data.materialListado;
          } else {
            res([]);
          }
        })
        .finally(() => {
          this.loadingMateriales = false;
        });
      });
    }, // getMercanciaCreados

    modalCrearMaterial() {
      this.eliminarItem = 0;
      this.verCrearMaterial = true;
    }, // modalCrearMaterial

    cierraCrearMaterial(respuesta) {
      console.log("cierraCrearMaterial =>", respuesta);
      this.verCrearMaterial = false;
      this.loadingMateriales = true;
      this.getMaterialCreados();
    }, // cierraCrearMaterial

    editarItems(item) {
      console.log("editarItems =>", item);
      this.eliminarItem = item.IdMaterial;
      this.verCrearMaterial = true;
    }, // editarItems

    deshabilitarMaterial(item) {
      console.log("deshabilitarMaterial =>", item);
      this.nameSelected = item.NombreMaterial;
      this.eliminarItem = item.IdMaterial;
      this.modalDeshabilitar = true;
    }, // deshabilitarMaterial
    deleteMaterial() {
      console.log("deleteMaterial");
      const headers = {
          Authorization: localStorage.getItem("token"),
        };
        console.log(localStorage);
        const params = {
          usuarioMovimiento:  localStorage.getItem("folio"),
          IdMaterial:         this.eliminarItem,
        };
        return new Promise((res) => {
          axios
          .post(
            `${this.$store.getters.getHostNode}/api/delete-material-items`,
            params,
            {
              headers: headers,
            }
          )
          .then((response) => {
            this.loadingEliminando = false
            if (response.data.status == true) {
              res(response.data);
              this.textoBarra = "Registro exitoso";
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.modalDeshabilitar = false;
              this.getMaterialCreados();
            } else if (response.data.status == false) {
              res(response.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "error";
              this.muestraBarra = true;
            } else if (response.data.status == "EXPRESSION") {
              console.log("deleteToken");
              deleteToken();
            }
          });
        });
    }, // deleteMaterial

    getClass(estatus) {
      if (estatus === 3) {
        return "yellow accent-4 black--text";
      } else if (estatus === 1) {
        return "teal darken-1 white--text";
      } else if (estatus === 2) {
        return "pink darken-3 white--text";
      } else {
        return "grey";
      }
    }, // getClass
  }, // methods

  async mounted() {
    this.getMaterialCreados();
  },
};
</script>

<style>
.rounded-left-top-table-lg {
  border-top-left-radius: 8px;
}
.rounded-right-top-table-lg {
  border-top-right-radius: 8px;
}
.header-material-table {
  background-color: #A8A8A7;
}
</style>