var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","persistent":""},model:{value:(_vm.muestraUsuariosPerfil),callback:function ($$v) {_vm.muestraUsuariosPerfil=$$v},expression:"muestraUsuariosPerfil"}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-card-title',{staticClass:"primary white--text subtitle-1"},[_vm._v(" Usuarios con acceso. ["+_vm._s(_vm.sistemaDesc)+": "),_c('strong',[_vm._v(_vm._s(_vm.perfilDesc))]),_vm._v(" ] "),_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"text":"","dark":""},on:{"click":function($event){_vm.showFindNewMembers = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" añadir colabolarador ")],1),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"text":"","dark":""},on:{"click":function($event){return _vm.$emit('cierraAdmPerfilUsr')}}},[_vm._v(" cerrar ")])],1),_c('v-tabs',{attrs:{"background-color":"primary","color":"white","grow":"","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Usuarios con acceso")]),_c('v-tab',[_vm._v("Pantallas a las que tiene acceso")])],1),_c('v-card-text',{staticClass:"pa-4"},[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('div',{staticStyle:{"display":"flex","justify-content":"end"}},[_c('v-text-field',{staticClass:"rounded-lg",staticStyle:{"max-width":"350px"},attrs:{"dense":"","outlined":"","prepend-inner-icon":"mdi-account-search","placeholder":"Buscar..."},model:{value:(_vm.searchUser),callback:function ($$v) {_vm.searchUser=$$v},expression:"searchUser"}})],1),_c('v-data-table',{staticClass:"rounded-lg",staticStyle:{"border":"0.8px solid #cfd8dc !important"},attrs:{"items":_vm.usuarios,"headers":_vm.headersUsuarios,"dense":"","loading":_vm.loadingUsuarios,"search":_vm.searchUser},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"font-weight-bold",attrs:{"text":"","small":"","color":"pink darken-3"},on:{"click":function($event){_vm.showConfirmDialog = true;
              _vm.openConfirm(item);}}},[_vm._v("quitar")])]}},{key:"item.EMP_STATUS",fn:function(ref){
              var item = ref.item;
return [(item.EMP_STATUS == 'A')?_c('v-chip',{attrs:{"color":"teal darken-1","dark":"","small":""}},[_vm._v("Activo")]):_c('v-chip',{attrs:{"color":"pink darken-3","dark":"","small":""}},[_vm._v("Baja")])]}},{key:"item.correo",fn:function(ref){
              var item = ref.item;
return [(item.correo)?_c('p',[_vm._v(_vm._s(item.correo))]):_c('p',[_c('v-tooltip',{attrs:{"top":"","color":"pink lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":"pink darken-3","dark":"","small":""}},'v-chip',attrs,false),on),[_vm._v("Sin correo registrado")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold ehite--text"},[_vm._v("Registre su correo en "),_c('br'),_vm._v(" Administración de usuarios")])]),_c('v-tooltip',{attrs:{"top":"","color":"pink lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"pink darken-3","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold ehite--text"},[_vm._v("Si ya esta registrado su correo "),_c('br'),_vm._v(" favor de intentar guardar nuevamente el usuario con su correo "),_c('br'),_vm._v(" para actualizar valores.")])])],1)]}}])})],1),_c('v-tab-item',[_c('div',{staticStyle:{"display":"flex","justify-content":"end"}},[_c('v-text-field',{staticClass:"rounded-lg",staticStyle:{"max-width":"350px"},attrs:{"dense":"","outlined":"","prepend-inner-icon":"mdi-account-search","placeholder":"Buscar..."},model:{value:(_vm.searchView),callback:function ($$v) {_vm.searchView=$$v},expression:"searchView"}})],1),_c('v-data-table',{staticClass:"rounded-lg",staticStyle:{"border":"0.8px solid #cfd8dc !important"},attrs:{"items":_vm.modulos,"headers":_vm.headersModulos,"dense":"","loading":_vm.loadingModulos,"search":_vm.searchView},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{staticClass:"font-weight-bold",attrs:{"text":"","small":"","color":"pink darken-3"},on:{"click":function($event){return _vm.openConfirmView(item)}}},[_vm._v("quitar")])]}}])})],1)],1)],1),(_vm.showFindNewMembers)?_c('BuscaColaboradoresActivos',{attrs:{"showFindNewMembers":_vm.showFindNewMembers},on:{"returnColaborrador":_vm.closeFindEmployee}}):_vm._e(),(_vm.muestraBarra)?_c('SnackBar',{attrs:{"muestraBarra":_vm.muestraBarra,"texto":_vm.textoBarra,"color":_vm.colorBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e(),(_vm.showConfirmDialog)?_c('ConfirmDialog',{attrs:{"showConfirm":_vm.showConfirmDialog,"nombre":_vm.nombreSelected},on:{"cierraConfirm":_vm.cierraConfirm}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }