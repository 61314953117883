<template>
  <v-dialog
    v-model="muestraFlujoAutorizaciones"
    max-width="500"
    persistent
    scrollable
    content-class="fix-border-dialog"
  >
    <v-card class="rounded-xl">
      <v-card-title class="primary">
        <div class="white--text text-uppercase subtitle-2 font-weight-bold">
          Flujo de autorización propuesto
        </div>
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('ocultaFlujoAutorizaciones', null)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="pt-4 center-item-in-div-column">
        <div class="text-center pb-2">
          <v-btn
            small
            class="rounded-pill"
            depressed
            dark
            color="teal darken-1"
            @click="
              position = 0;
              muestraEmpleados = true;
            "
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            añadir al inicio
          </v-btn>
        </div>
        <v-simple-table dense class="elevation-2 rounded-lg">
          <thead>
            <tr>
              <th class="text-uppercase font-weight-bold">Paso</th>
              <th class="text-uppercase font-weight-bold">Autorizador</th>
              <th class="text-uppercase font-weight-bold"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(paso, index) in flujoAux" :key="index">
              <td>{{ paso.step }}</td>
              <td v-if="!loadingNombres">{{ getNombre(paso.aut) }}</td>
              <td v-else class="center-item-in-div">
                <v-progress-circular
                  indeterminate
                  :size="15"
                  color="primary"
                ></v-progress-circular>
              </td>
              <td>
                <v-icon color="pink darken-3" @click="position = index + 1; removeAutorizador()">
                  mdi-minus-circle
                </v-icon>
                <v-icon
                  color="cyan"
                  @click="
                    position = index + 1;
                    muestraEmpleados = true;
                  "
                >
                  mdi-plus-circle
                </v-icon>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <div class="text-center pt-2">
          <v-btn
            small
            class="rounded-pill"
            depressed
            dark
            color="teal darken-1"
            @click="
              position = flujoAux.length;
              muestraEmpleados = true;
            "
          >
            añadir al final
            <v-icon right>
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-0">
        <v-btn
          block
          color="success"
          text
          class="rounded-xl rounded-t-0 font-weight-bold"
          :disabled="flujoAux.length === 0 ? true : false" 
          @click="$emit('ocultaFlujoAutorizaciones', flujoAux)"
        >
          enviar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="muestraEmpleados"
      max-width="400"
      content-class="fix-border-dialog"
      persistent
    >
      <v-card class="rouded-lg">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon color="pink darken-3" right @click="muestraEmpleados = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-5 center-item-in-div pb-1">
          <v-autocomplete
            v-model="selected"
            class="rounded-lg"
            outlined
            dense
            :items="personalActivos"
            item-text="Nombre"
            placeholder="Busca por nombre..."
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg"
            color="teal darken-1"
            dark
            depressed
            @click="addAutorizador()"
          >
            añadir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  name: "dialog-flujo-autorizaciones",
  props: ["muestraFlujoAutorizaciones", "flujo"],
  data() {
    return {
      flujoAux: [],
      personalActivos: [],
      loadingNombres: true,
      muestraEmpleados: false,
      selected: null,
      position: null,
    };
  },
  mounted() {
    this.flujoAux = this.flujo;
    this.getCandidatosActivosEnOferta();
  },
  methods: {
    getCandidatosActivosEnOferta() {
      if (this.personalActivos.length > 0) {
        this.loadingTipo = false;
        return this.personalActivos;
      } else {
        const params = {
          Opcion: 11,
          token: localStorage.getItem("token"),
        };
        axios
          .post(
            `${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`,
            params
          )
          .then((response) => {
            this.loadingNombres = false;
            this.personalActivos = response.data.personal;
          })
          .catch((error) => {
            this.mensajeError = error;
          });
      }
    },
    getNombre(emplid) {
      let findName = this.personalActivos.filter((n) => {
        return n.EMPLID == emplid;
      });
      if(findName.length > 0){
        return findName[0].Nombre + " [ " + emplid + " ]";
      }
      return "NOMBRE NO ENCONTRADO: " + emplid;
    },
    addAutorizador() {
      console.log(this.selected);
      let findEmplid = this.personalActivos.filter((e) => {
        return e.Nombre == this.selected;
      });
      this.flujoAux.splice(this.position, 0, {
        step: this.position,
        aut: findEmplid[0].EMPLID,
      });
      let indice = 1;
      this.flujoAux.map((f) => {
        f.step = indice++;
      });
      this.muestraEmpleados = false;
    },
    removeAutorizador() {
        console.log(this.position)
      this.flujoAux.splice(this.position - 1, 1);
      let indice = 1;
      this.flujoAux.map((f) => {
        f.step = indice++;
      });
    },
    // setFlujo() {
    //     const params = {
    //       Opcion: 16,
    //       token: localStorage.getItem("token"),
    //       flujo: this.flujoAux
    //     };
    //     axios
    //       .post(
    //         `${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`,
    //         params
    //       )
    //       .then((response) => {
    //         console.log(response.data)
    //       })
    //       .catch((error) => {
    //         this.mensajeError = error;
    //       });
      
    // },
  },
};
</script>
