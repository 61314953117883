<template>
  <v-card
    class="elevation-0 rounded-lg py-2"
    width="100%"
    min-height="120"
    color="transparent"
    to="Incidencias"
  >
    <label
      class="subtitle-2 text-uppercase pl-3 blue-grey--text text--darken-2"
    >
      Próximas incidencias
    </label>
    <div>
      <label class="caption blue-grey--text overline">
        <strong v-show="textoCargando != ''">Cargando </strong>
        {{ textoCargando }}
      </label>
    </div>

    <v-list two-line color="transparent pl-4 pr-3">
      <v-card
        v-for="(item, index) in incidencias"
        class="elevation-0 rounded-xl mb-2"
        :key="index"
        width="100%"
      >
        <v-list-item>
          <v-list-item-avatar>
            <v-icon
              class="grey lighten-2"
              color="primary"
              dark
            >{{ findIcon(item.TipoIncidencia) }}</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ item.IncDescripcion  }}</v-list-item-title>

            <v-list-item-subtitle
              v-show="!['Tienes procesos por autorizar'].includes(item.title)"
              class="text-left"
            >
              <b class="overline font-weight-bold" :class="getColor(item.Estatus)"> {{ item.EstDescripcion }}</b>
              - {{ ![2,5].includes(item.TipoIncidencia) ? setFormatDate(item.FechaInicialDes) +' al '+ setFormatDate(item.FechaFinalDes) : setFormatDateTime(item.FechaInicialDes)}}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="py-2">
            <v-list-item-action-text
              v-show="!['Tienes procesos por autorizar'].includes(item.title)"
              
            >
          <label class="caption text-uppercase center-item-in-div">
            folio<br>{{item.ID}}
          </label>
          </v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-list>
  </v-card>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
export default {
  data() {
    return {
      value: "",
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        {
          text: "Dom - Sab",
          value: [0, 1, 2, 3, 4, 5, 6],
        },
        {
          text: "Lun - Dom",
          value: [1, 2, 3, 4, 5, 6, 0],
        },
        {
          text: "Lun - Vie",
          value: [1, 2, 3, 4, 5],
        },
        {
          text: "Lun, Mie, Vie",
          value: [1, 3, 5],
        },
      ],
      type: "month",
      types: ["mes", "semana", "día", "4día"],
      mode: "stack",
      modes: ["stack", "column"],
      events: [],
      secondarray: [],
      secondarray2: [],
      festivos: [],
      incidencias: [],
      estatusIncidencias: [],
      respuestaIncidenciaCancelada: [],
      respuestaDetalles: [],
      colors: [
        "yellow lighten-3",
        "green",
        "red",
        "red darken-4",
        "green darken-4",
        "grey darken-1",
        // 'yellow accent-4',
        "deep-purple lighten-1",
        "blue",
        "blue darken-4",
        "blue darken-2",
        "blue-grey lighten-3",
      ],
      colorsText: [
        "yellow--text text--darken-3",
        "green--text text--",
        "red--text text--",
        "red--text text--darken-4",
        "green--text text--darken-4",
        "grey--text text--darken-1",
        "yellow--text text--accent-4",
        "blue--text text--",
        "blue--text text--darken-4",
        "blue--text text--darken-2",
        "blue-grey--text text--lighten-3",
        "black--text",
        "white--text",
      ],
      
    TiposIncidencias: [
      {
        idTipo: 1,
        desc: "Vacaciones",
        semanal: true,
        icono: "mdi-beach",
      },
      {
        idTipo: 3,
        desc: "Tiempo por tiempo",
        semanal: true,
        icono: "mdi-clipboard-text-clock",
      },
      {
        idTipo: 2,
        desc: "Salida anticipada",
        semanal: true,
        icono: "mdi-door-open",
      },
      {
        idTipo: 5,
        desc: "Llegada tarde",
        semanal: true,
        icono: "mdi-timer-alert-outline",
      },
      {
        idTipo: 4,
        desc: "Permiso especial",
        semanal: true,
        icono: "mdi-account-child-circle",
      },
      {
        idTipo: 6,
        desc: "Flex time",
        semanal: false,
        icono: "mdi-arrow-decision",
      },
      {
        idTipo: 7,
        desc: "Home Office",
        semanal: false,
        icono: "mdi-home",
      },
      {
        idTipo: 11,
        desc: "Teletrabajo",
        semanal: false,
        icono: "mdi-shield-home",
      },
      {
        idTipo: 12,
        desc: "Viernes Corto",
        semanal: false,
        icono: "mdi-alpha-v-circle",
      },
      {
        idTipo: 101,
        desc: "Calendario",
        semanal: false,
        icono: "mdi-calendar-month-outline",
      },
    ],
      selectedOpen: false,
      selectedElement: null,
      selectedEvent: {},
      textoCargando: "",

      // imprimeDia({ date }) {
      //     console.log(date)
      //     this.dialogCargaIncidencia = true;
      // },
    };
  },
  computed: {
    dialogCargandoInf: function() {
      return this.textoCargando != "Cargando ";
    },
  },
  created() {
    this.events = [];
  },
  async mounted() {
    // this.getStatusIncedence();
    this.incidencias = await this.getIncidenciasProx();
  },
  methods: {
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        this.respuestaDetalles.length = 0;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        this.respuestaDetalles.length = 0;
        // console.log("prueba")
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    async getStatusIncedence() {
      let paramsEstatusInc = {
        Opcion: 4,
        token: localStorage.getItem("token"),
      };
      //(paramsEstatusInc)
      this.textoCargando += "estatus...";
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`,
          paramsEstatusInc
        )
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.estatusIncidencias;
            this.estatusIncidencias = data;
            this.textoCargando = this.textoCargando.replace("estatus...", "");
            // this.tiposIncidencias();
            this.getEvents();
          }
        })
        .catch(function(e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    getEvents() {
      // const headers = {
      //     headers: {
      //         'X-AUTH-TOKEN': localStorage.getItem('token')
      //     }
      // };
      var params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
      };
      var paramsIncidencias = {
        Opcion: 2,
        token: localStorage.getItem("token"),
      };
      var paramsHoliday = {
        Opcion: 3,
        token: localStorage.getItem("token"),
      };
      this.textoCargando += "checadas...";
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`,
          params
        )
        .then((response) => {
          // console.log(response.data)
          if (response.data.checadas != null) {
            var data = response.data.checadas.map((checada) => {
              this.checkIn = new Date(checada["ENTRADA"]);
              this.checkOut = new Date(checada["SALIDA"]);
              this.colorCheck = this.getColor(11);
              this.secondarray.push({
                name: "Llegada",
                start: this.checkIn,
                formatoHora:
                  this.modifyHours(this.checkIn.getHours()) +
                  ":" +
                  this.modifyMinutes(this.checkIn.getMinutes()),
                color: this.colorCheck,
                timed: true,
                "event-text-color": this.colorsText[8],
              });
              if (this.checkIn.getHours() != this.checkOut.getHours()) {
                this.secondarray.push({
                  name: "Salida",
                  start: this.checkOut,
                  formatoHora:
                    this.modifyHours(this.checkOut.getHours()) +
                    ":" +
                    this.modifyMinutes(this.checkOut.getMinutes()),
                  color: this.colorCheck,
                  timed: true,
                  "event-text-color": this.colorsText[8],
                });
              }
              return checada;
            });
            this.secondarray2 = data;
          }
          this.textoCargando = this.textoCargando.replace("checadas...", "");
        })
        .catch(function(e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
      this.textoCargando += "incidencias...";
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`,
          paramsIncidencias
        )
        .then((response) => {
          // //(response.data.incidencias)
          if (
            response.data.incidencias != null &&
            response.data.incidencias.length > 0
          ) {
            var data = response.data.incidencias.map((incidencia) => {
              var opc = incidencia["Estatus"];

              var textColor = this.colorsText[12];
              var descripocionIncidencia = "";
              var icono = "";
              var detalleEstatus =
                "Estatus: " +
                this.estatusIncidencias[opc - 1]["EstDescripcion"];

              if (incidencia["TipoIncidencia"] == 2) {
                icono = "mdi-door-open";
              } else if (incidencia["TipoIncidencia"] == 6) {
                icono = "mdi-arrow-decision";
              } else if (incidencia["TipoIncidencia"] == 4) {
                icono = "mdi-account-child-circle";
              } else if (incidencia["TipoIncidencia"] == 1) {
                icono = "mdi-beach";
              } else if (
                incidencia["TipoIncidencia"] == 7 ||
                incidencia["TipoIncidencia"] == 11
              ) {
                icono = "mdi-home";
              }

              if (incidencia["TipoIncidencia"] == 4) {
                descripocionIncidencia =
                  incidencia["IncDescripcion"] +
                  ": " +
                  incidencia["PermDescripcion"];
              } else {
                descripocionIncidencia = incidencia["IncDescripcion"];
              }

              this.colorIncidences = this.getColor(opc);
              if (opc == 1 || opc == 2 || opc == 7) {
                textColor = this.colorsText[11];
              }

              if (
                incidencia["FechaInicialDes"] == incidencia["FechaFinalDes"] ||
                incidencia["FechaFinalDes"] == null
              ) {
                var fecha_i = new Date(incidencia["FechaInicialDes"]);

                if (incidencia["TipoIncidencia"] == 2) {
                  this.secondarray.push({
                    icon: icono,
                    name: descripocionIncidencia,
                    formatoHora:
                      this.modifyHours(fecha_i.getHours()) +
                      ":" +
                      this.modifyMinutes(fecha_i.getMinutes()),
                    start: fecha_i,
                    color: this.colorIncidences,
                    "event-text-color": textColor,
                    details: detalleEstatus,
                    estatus: incidencia["Estatus"],
                    idIncidencia: incidencia["ID"],
                  });
                } else {
                  this.secondarray.push({
                    icon: icono,
                    name: descripocionIncidencia,
                    start: fecha_i,
                    color: this.colorIncidences,
                    "event-text-color": textColor,
                    details: detalleEstatus,
                    estatus: incidencia["Estatus"],
                    idIncidencia: incidencia["ID"],
                  });
                }
              } else {
                var i = new Date(incidencia["FechaInicialDes"]);
                var f = new Date(incidencia["FechaFinalDes"]);
                this.secondarray.push({
                  icon: icono,
                  name: descripocionIncidencia,
                  start: i,
                  end: f,
                  color: this.colorIncidences,
                  "event-text-color": textColor,
                  details: detalleEstatus,
                  estatus: incidencia["Estatus"],
                  idIncidencia: incidencia["ID"],
                });
              }
              return incidencia;
            });
            this.incidencias = data;
          }
          this.textoCargando = this.textoCargando.replace("incidencias...", "");
        })
        .catch(function(e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
      this.textoCargando += "días festivos...";
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`,
          paramsHoliday
        )
        .then((response) => {
          var data = response.data.festivos.map((incidencia) => {
            var i = new Date(incidencia["dca_fecdia"]);

            this.colorHoliday = this.getColor(3);
            this.secondarray.push({
              name: incidencia["DESCR"],
              start: i,
              color: this.colorHoliday,
              timed: false,
              "event-text-color": "",
            });
            return incidencia;
          });
          this.festivos = data;
          this.$nextTick(function() {
            this.textoCargando = this.textoCargando.replace(
              "días festivos...",
              ""
            );
          });
        })
        .catch(function(e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });

      this.events = this.secondarray;
    },
    getColor(opc) {
      let color = "";

      switch (opc) {
        case 1:
          color = this.colorsText[opc - 1];
          break;
        case 2:
          color = this.colorsText[opc - 1];
          break;
        case 3:
          color = this.colorsText[opc - 1];
          break;
        case 4:
          color = this.colorsText[opc - 1];
          break;
        case 5:
          color = this.colorsText[opc - 1];
          break;
        case 6:
          color = this.colorsText[opc - 1];
          break;
        case 7:
          color = this.colorsText[opc - 1];
          break;
        case 8:
          color = this.colorsText[opc - 1];
          break;
        case 9:
          color = this.colorsText[opc - 1];
          break;
        case 10:
          color = this.colorsText[opc - 1];
          break;
        case 11:
          color = this.colorsText[opc - 1];
          break;
        default:
          color = "white";
          break;
      }

      return color;
    },
    findIcon(tipo){
      return this.TiposIncidencias[this.TiposIncidencias.findIndex(objeto => objeto.idTipo === tipo)].icono
    },
    modifyHours(hours) {
      if (hours < 10) {
        return "0" + hours.toString();
      }

      return hours.toString();
    },
    modifyMinutes(minutes) {
      if (minutes < 10) {
        return "0" + minutes.toString();
      }

      return minutes.toString();
    },
    getIncidenciasProx() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-incidencias-home`, {
            headers,
          })
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    setFormatDate(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      return diaUTC + "/" + mesUTC + "/" + anioUTC;
    },
    setFormatDateTime(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      let hora = fecha.getUTCHours();
      let minutos = fecha.getUTCMinutes();
      let segundos = fecha.getUTCSeconds();

      let horaFormateada = (hora < 10 ? "0" : "") + hora;
      let minutosFormateados = (minutos < 10 ? "0" : "") + minutos;
      let segundosFormateados = (segundos < 10 ? "0" : "") + segundos;

      return (
        diaUTC +
        "/" +
        mesUTC +
        "/" +
        anioUTC +
        " " +
        horaFormateada +
        ":" +
        minutosFormateados +
        ":" +
        segundosFormateados
      );
    },
  },
};
</script>
