<template>
  <v-row justify="center">
    <v-dialog v-model="openDialogSubirImg" persistent max-width="600px">
      <v-card class="rounded-lg">
        <v-card-title class="text-subtitle-2 primary white--text">
          Foto de perfil
          <v-spacer></v-spacer>
          <v-icon small dark @click="$emit('cerrarModal', 'cancelar')"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-card class="my-5 rounded-xl degradado-card" elevation="8">
            <v-row class="pb-5">
              <v-col
                align="center"
                cols="12"
                xs="12"
                sm="12"
                md="5"
                lg="5"
                xl="5"
              >
                <v-avatar class="rounded-circle mb-5" size="150" rounded>
                  <img :src="vistapreviaimg" />
                </v-avatar>
                <br />
                <div v-if="foto">
                  Vista previa
                  <v-icon color="teal darken-4" dark> mdi-check </v-icon>
                </div>
              </v-col>
              <v-col
                align="center"
                class="d-flex justify-center align-center px-0"
                cols="12"
                xs="12"
                sm="12"
                md="7"
                lg="7"
                xl="7"
              >
                <center>
                  <v-btn
                    color="primary"
                    rounded
                    dark
                    :loading="isSelecting"
                    @click="handleFileImport"
                  >
                    <v-icon small>mdi-camera</v-icon> Cargar foto
                  </v-btn>
                  <div class="mt-2 justify-center" justify="center">
                    <input
                      ref="uploader"
                      class="d-done"
                      accept="image/*"
                      type="file"
                      @change="onFileChanged"
                    />
                  </div>
                </center>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="foto"
            class="rounded-lg elevation-0"
            color="teal darken-1"
            dark
            small
            :loading="loading"
            @click="subirFoto()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-sheet>
  </v-row>
</template>
<script>
import axios from "axios";
import SnackBarErrorIncidencias from "../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "cargar-foto-perfil",
  components: { SnackBarErrorIncidencias },
  props: ["openDialogSubirImg", "ruta", "folio"],
  data() {
    return {
      foto: null,
      link: "",
      imagen: "",
      vistapreviaimg: "",
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,
      isSelecting: false,
      loading: false,
      url: "",
    };
  },
  computed: {},
  mounted() {
    this.vistapreviaimg = this.ruta
      ? this.ruta
      : "https://community.f5.com/legacyfs/online/avatars/0051T000008uOPOQA2.png";
  },
  methods: {
    handleFileImport() {
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.foto = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(this.foto);
      reader.onload = () => {
        this.vistapreviaimg = reader.result;
      };
      // Do whatever you need with the file, liek reading it with FileReader
    },
    vistaPrevia() {
      let reader = new FileReader();
      reader.readAsDataURL(this.foto);
      reader.onload = () => {
        this.vistapreviaimg = reader.result;
      };
    },
    subirFoto() {
      this.loading = true;
      let reader = new FileReader();
      reader.readAsDataURL(this.foto);
      reader.onload = () => {
        let params = {
          Opcion: 2,
          nombreArchivo: this.foto.name,
          folio: this.folio,
          base64: reader.result,
          token: localStorage.getItem("token"),
        };
        axios
          .post(
            `${this.$store.getters.getHost}/General/ws-miPersona.php`,
            params
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status == "OK") {
              this.textoBarra = "Perfil modificado";
              this.colorBarra = "success";
              this.muestraBarra = true;
              return new Promise((resolve) => {
                setTimeout(() => {
                  this.foto = null;
                  this.vistapreviaimg = "";
                  resolve(this.$emit("cerrarModal", "OK"));
                }, 1500);
              });
            } else if (response.data.status == "error") {
              this.textoBarra = "Error al guardar cambios";
              this.colorBarra = "error";
              this.muestraBarra = true;
            }
          });
      };
    },
  },
};
</script>
<style scoped>
.degradado-card {
  background: rgb(227, 227, 227);
  background: radial-gradient(
    circle,
    rgba(227, 227, 227, 1) 0%,
    rgba(234, 242, 245, 1) 100%
  );
}
</style>