<template>
    <div v-if="!bandera3 && !banderaPropios22 && !banderaArea2">

        <div>
            <div v-show="loadingTable" class="center-item-in-div-column">
                <v-progress-circular :size="150" :width="15" color="primary" indeterminate></v-progress-circular>
                <label class="overline font-weight-bold">Cargando información...</label>
            </div>

            <div class="centrado">
                <v-btn text @click="toggleTabla">
                    <v-icon v-if="mostrarTabla">mdi-close-box-multiple-outline</v-icon>
                    <v-icon v-else> mdi-open-in-app</v-icon>
                    <b>Objetivos de Departamento:</b>
                </v-btn>
            </div>
            <p></p>

            <div class="rounded-lg pb-4 elevation-0" v-show="!loadingTable">
                <v-simple-table id="miTabla" v-if="mostrarTabla" dense style="background-color: transparent;">
                    <thead>
                        <meta charset="utf-8">
                        <tr>
                            <th class="primary rounded-simple-table-head-l" colspan="7"></th>
                            <th class="primary white--text text-uppercase font-weight-bold text-center" colspan="4">
                                Valor real &uacute;ltimo trimestre
                            </th>
                            <th class="primary rounded-simple-table-head-r" colspan="2"></th>
                        </tr>
                        <tr>
                            <th class="primary white--text text-uppercase font-weight-bold text-center"
                                v-for="item in formattedTablaDepartamento" :key="item.id">
                                {{ item.text }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="dato in datosCombinados" :key="dato.idOp">
                            <td style="text-align: center;">{{ dato.idOp }}</td>
                            <td>{{ dato.objetivoPropio }}</td>
                            <td style="text-align: center;">{{ dato.VO }}</td>
                            <td style="text-align: center;">{{ dato.LE }}</td>
                            <td style="text-align: center;">{{ dato.ponderacion }}</td>
                            <td style="text-align: center;">{{ dato.Tipo == "BALANCE" ? 'BSC' : dato.Tipo }}</td>
                            <td style="text-align: center;">{{ dato.ValorPrimer }}</td>
                            <td style="text-align: center;">{{ dato.ValorSegundo }}</td>
                            <td style="text-align: center;">{{ dato.ValorTercero }}</td>
                            <td style="text-align: center;">{{ dato.dReal ? dato.dReal.toFixed(2) : '0.00' }}</td>
                            <td style="text-align: center;">{{ dato.desemp ? dato.desemp.toFixed(2) : '0.00' }}</td>
                            <td style="text-align: center;">{{ dato.puntos ? dato.puntos.toFixed(2) : '0.00' }}</td>
                        </tr>
                        <!-- Fila para Ponderación Total -->
                        <tr>
                            <td colspan="4"></td>
                            <td class="font-weight-bold text-center">Ponderación Total: {{ this.ponderacionSuma }}</td>

                        </tr>
                    </tbody>
                </v-simple-table>
                <div class="text-right pt-4">
                    <label class="font-weight-bold mr-3">Calificación del trimestre: {{ dSumaPuntosDepto.toFixed(2)
                        }}</label>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from "axios";

export default {
    name: "ver-objetivosDepartamento",
    props: [
        "Mes1", "Mes2", "Mes3", "iMes", "numEmpleado", "trimestre", "TrimestreValorBase", "anio",
        "PrimerMesNumValorBase", "SegundoMesNumValorBase", "TercerMesNumValorBase",
        "PuestoCoordValorBase", "bandera3", "banderaPropios22", "banderaArea2", "ResponsableDepa",
        "anioTabla", "trimestreFinal"
    ],
    data() {
        return {
            sortedData:[],
            valoresTest: 123,
            mes: null,
            iNumDeptoValorBase: null,
            iAreaValorBase: null,
            Id_ObjValorBase: null,
            Id_ObjValorBase2: null,
            Ids_ObjValorBase: [],
            Ids_ObjValorBase2: [],
            PonderacionValorBase: null,
            TipoCalculoValorBase: null,
            TipoValorBase: null,
            dSumaPuntosDeptoValorBase: null,
            dSumaPonderacionDeptoValorBase: null,
            Id_O2ValorBase: [],
            DescObjValorBase: [],
            VOValorBase: [],
            LEValorBase: [],
            UnidadValorBase: [],
            TipoIndicadorValorBase: [],
            Id_OESValorBase: [],
            Objetivos: [],
            ValorPrimerValorBase: [],
            ValorSegundoValorBase: [],
            ValorTerceroValorBase: [],
            ValoresObjetos3: [],
            dReal: [],
            Desemp: [],
            Puntos: [],
            dSumaPuntosDepto: 0,
            valorPrimer1: null,
            loadingTable: true,
            mostrarTabla: false,
            tablaDepartamentos: [
                { id: 1, text: 'ID' },
                { id: 2, text: 'Objetivo Específico' },
                { id: 3, text: 'VO' },
                { id: 4, text: 'LE' },
                { id: 5, text: 'Ponderación' },
                { id: 6, text: 'Tipo' },
                { id: 7, text: 'Mes 1' },
                { id: 8, text: 'Mes 2' },
                { id: 9, text: 'Mes 3' },
                { id: 10, text: 'Resultado' },
                { id: 11, text: 'Desempeño' },
                { id: 12, text: 'Puntos' }
            ]
        };
    },
    async mounted() {
        this.getDatos();
    },
    computed: {
        formattedTablaDepartamento() {
            return this.tablaDepartamentos.map(item => {
                if (item.id === 7) item.text = ` ${this.Mes1}`;
                if (item.id === 8) item.text = ` ${this.Mes2}`;
                if (item.id === 9) item.text = ` ${this.Mes3}`;
                return item;
            });
        },
        sortedObjetivos() {
            return [...this.Objetivos].sort((a, b) => a.idOp - b.idOp);
        },
        datosCombinados() {
            return this.sortedObjetivos.map((objetivo, index) => ({
                ...objetivo,
                ponderacion: this.PonderacionesValorBase[index] || 0,
                ValorPrimer: this.ValorPrimerValorBase[index] || 0,
                ValorSegundo: this.ValorSegundoValorBase[index] || 0,
                ValorTercero: this.ValorTerceroValorBase[index] || 0,
                dReal: this.dReal[index] || 0,
                desemp: this.Desemp[index] || 0,
                puntos: this.Puntos[index] || 0
            }));
        }
    },
    methods: {
        async enviar() {
            this.$emit('valoresTestEvent', this.dSumaPuntosDepto.toFixed(2));
        },
        toggleTabla() {
            this.mostrarTabla = !this.mostrarTabla;
        },
        async getDatos() {
            try {
                this.loadingTable = true;
                if (this.PuestoCoordValorBase !== '') {
                    await this.getObjDepa1();
                    await this.getObjDepa2();
                    await this.getObjDepa3();
                    this.validacionesTest();
                    this.enviar();
                }
                this.loadingTable = false;
            } catch (error) {
                this.dSumaPuntosDepto = 0;
                this.enviar();
                console.error('Error al cargar los datos:', error);
                this.loadingTable = false;
            }
        },

        async getObjDepa1() {
            console.log('valor de puesto corrd:', this.PuestoCoordValorBase);
    try {
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/ver-objDepa1/${this.PuestoCoordValorBase}`, {
            headers: {
                Authorization: localStorage.getItem("token"),
            },
        });
        
        //console.log('Response:', response); // Verifica la estructura de la respuesta

        // Verificar si la respuesta no es nula y tiene los datos esperados
        if (response && response.data && Array.isArray(response.data.data)) {
            this.Ids_ObjValorBase = response.data.data.map(item => item.ID_Obj);
            this.TiposValorBase = response.data.data.map(item => item.Tipo);
            this.PonderacionesValorBase = response.data.data.map(item => parseFloat(item.Ponderacion) || 0);
            this.ponderacionSuma = this.PonderacionesValorBase.reduce((acc, val) => acc + val, 0);
            this.TiposCalculoValorBase = response.data.data.map(item => item.TipoCalculo);
            console.log('valores de tipos de caloculo: ', this.TiposCalculoValorBase);
        } else {
            //console.error('Los datos de la respuesta son nulos o no son un array.');
            // Aquí puedes manejar el caso donde no se obtienen datos, por ejemplo, mostrando un mensaje al usuario.
            this.Ids_ObjValorBase = [];
            this.TiposValorBase = [];
            this.PonderacionesValorBase = [];
            this.TiposCalculoValorBase = [];
            this.ponderacionSuma = 0;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
},

        
        // async getObjDepa1() {
        //     try {
        //         const response = await axios.get(`${this.$store.getters.getHostNode}/api/ver-objDepa1/${this.PuestoCoordValorBase}`, {
        //             headers: {
        //                 Authorization: localStorage.getItem("token"),
        //             },
        //         });
        //         this.Ids_ObjValorBase = response.data.data.map(item => item.ID_Obj);
        //         this.TiposValorBase = response.data.data.map(item => item.Tipo);
        //         // this.PonderacionesValorBase = response.data.data.map(item => item.Ponderacion);

        //         this.PonderacionesValorBase = response.data.data.map(item => parseFloat(item.Ponderacion) || 0); // Convertir a número y manejar valores no numéricos

        //         // Sumar los valores de Ponderacion
        //         this.ponderacionSuma = this.PonderacionesValorBase.reduce((acc, val) => acc + val, 0);

        //         this.TiposCalculoValorBase = response.data.data.map(item => item.TipoCalculo);
        //         // console.log('Datos de ObjDepa1:', this.Ids_ObjValorBase, this.TiposValorBase, this.PonderacionesValorBase, this.TiposCalculoValorBase);
        //     } catch (error) {
        //         console.error('Error fetching data:', error);
        //     }
        // },
        async getObjDepa2() {
            const idsString = this.Ids_ObjValorBase.join(',');
            console.log('ids: ', idsString);
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/ver-objDepa2?ids=${idsString}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                if (response && response.data && Array.isArray(response.data)) {
                    this.Id_O2ValorBase = response.data.map(item => item.ID_OP);
                    this.Id_OESValorBase = response.data.map(item => item.ID_OES); //Este creo que no lo utilizo
                     this.DescObjValorBase = response.data.map(item => item.ObjetivoPropio);
                    //this.DescObjValorBase = response.data.map(item => decodeURIComponent(escape(item.ObjetivoPropio)));
                    this.UnidadValorBase = response.data.map(item => item.Unidad);
                    this.Objetivos = response.data.map(item => ({
                        idOp: item.ID_OP,
                        idOes: item.ID_OES,
                         objetivoPropio: item.ObjetivoPropio,
                        //objetivoPropio: decodeURIComponent(escape(item.ObjetivoPropio)),
                        VO: Number(item.VO),
                        LE: Number(item.LE),
                        Unidad: item.Unidad,
                        Tipo: item.Tipo
                    })).sort((a, b) => a.idOp - b.idOp);
                    this.VOValorBase = this.Objetivos.map(obj => obj.VO);
                    this.LEValorBase = this.Objetivos.map(obj => obj.LE);

                    // console.log('valor de vo:', this.VOValorBase);
                    // console.log('valro de le:', this.LEValorBase);
                    //  console.log('Datos de ObjDepa2:', this.Objetivos);
                } else {
                    console.log('Formato inesperado de datos:', response.data);
                }
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        },
        async getObjDepa3() {
            // Ordenar los IDs de menor a mayor
            const idsOrdenados = this.Id_O2ValorBase.sort((a, b) => a - b);

           // console.log('Valores de IDS ordenados:', idsOrdenados, 'Trimestre:', this.trimestre, 'Anio:', this.anio, 'Resp',this.ResponsableDepa);

            try {
                // Inicializar los arrays para los valores
                this.ValorPrimerValorBase = [];
                this.ValorSegundoValorBase = [];
                this.ValorTerceroValorBase = [];

                // Iterar sobre los IDs ordenados y realizar una solicitud para cada uno
                for (const id of idsOrdenados) {
                    const response = await axios.get(`${this.$store.getters.getHostNode}/api/departamento-data?ids=${id}&trimestre=${this.trimestreFinal}&anio=${this.anioTabla}&Responsable=${this.ResponsableDepa}`, {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    });
                    if (response && response.data && Array.isArray(response.data) && response.data.length > 0) {
                        // Tomar solo el primer elemento (posición 0) de la respuesta
                        const item = response.data[0];
                        this.ValorPrimerValorBase.push(item.ValorPrimer !== null && item.ValorPrimer !== "" ? item.ValorPrimer : 0);
                        this.ValorSegundoValorBase.push(item.ValorSegundo !== null && item.ValorSegundo !== "" ? item.ValorSegundo : 0);
                        this.ValorTerceroValorBase.push(item.ValorTercero !== null && item.ValorTercero !== "" ? item.ValorTercero : 0);

                        // console.log(`Valores para ID ${id}:`, item);
                    } else {
                        // console.log(`No se encontraron datos para el ID ${id}`);
                        this.ValorPrimerValorBase.push(0);
                        this.ValorSegundoValorBase.push(0);
                        this.ValorTerceroValorBase.push(0);
                    }
                }

                // console.log('Valor de mes1:', this.ValorPrimerValorBase);
                // console.log('Valor de mes2:', this.ValorSegundoValorBase);
                // console.log('Valor de mes3:', this.ValorTerceroValorBase);
            } catch (error) {
                console.error('Error al cargar los datos 3:', error);
                this.inicializarValores();
            }
        },

        inicializarValores() {
            this.ValorPrimerValorBase = this.Id_O2ValorBase.map(() => 0);
            this.ValorSegundoValorBase = this.Id_O2ValorBase.map(() => 0);
            this.ValorTerceroValorBase = this.Id_O2ValorBase.map(() => 0);
            // console.log('Valores inicializados a 0');
        },

        //Este ya muestra el acumulado el ultimo valor

        // async validacionesTest() {
        //     console.log('Entrando a validacionesTest...');

        //     function segundoIF(valor) {
        //         return valor === null || valor === "Promedio" || valor === "";
        //     }

        //     // Asegúrate de que todos los valores estén definidos y válidos
        //     this.ValorPrimerValorBase = this.ValorPrimerValorBase.map(val => val === "" ? 0 : val);
        //     this.ValorSegundoValorBase = this.ValorSegundoValorBase.map(val => val === "" ? 0 : val);
        //     this.ValorTerceroValorBase = this.ValorTerceroValorBase.map(val => val === "" ? 0 : val);

        //     // console.log('Después de mapear valores vacíos a 0:');
        //     // console.log('ValObjPrimerMes:', this.ValObjPrimerMes);
        //     // console.log('ValObjSegundoMes:', this.ValObjSegundoMes);
        //     // console.log('ValObjTercero:', this.ValObjTercero);

        //     // Determinar el valor real (dReal) basado en las fórmulas proporcionadas
        //     this.dReal = this.ValorPrimerValorBase.map((_, index) => {
        //         const tipoCalculo = this.TiposValorBase[index];
        //         if (segundoIF(tipoCalculo)) {
        //             return (this.ValorPrimerValorBase[index] + this.ValorSegundoValorBase[index] + this.ValorTerceroValorBase[index]) / 3;
        //         } else if (this.ValorTerceroValorBase[index] !== 0) {
        //             return this.ValorTerceroValorBase[index];
        //         } else if (this.ValorSegundoValorBase[index] !== 0) {
        //             return this.ValorSegundoValorBase[index];
        //         } else {
        //             return this.ValorPrimerValorBase[index];
        //         }
        //     });

        //     // console.log('dReal:', this.dReal);

        //     // Calcular desempeño y puntos
        //     if (
        //         this.LEValorBase.length === this.VOValorBase.length &&
        //         this.LEValorBase.length === this.dReal.length &&
        //         this.LEValorBase.length === this.PonderacionesValorBase.length
                
        //     ) {
        //         this.Desemp = this.LEValorBase.map((le, index) => {
        //             const vo = this.VOValorBase[index];
        //             const dr = this.dReal[index];
        //             let desemp = ((le - dr) / (le - vo)) * 100;
        //             return Math.min(Math.max(desemp, 0), 100); // Limitar entre 0 y 100
        //         });

        //         this.Puntos = this.Desemp.map((desemp, index) =>
        //             (desemp * this.PonderacionesValorBase[index]) / 100
        //         );

        //         // console.log('Desemp:', this.Desemp);
        //         // console.log('Puntos:', this.Puntos);

        //         // Calcular las sumas y almacenarlas en las variables correspondientes
        //         this.totalPonderacion = this.PonderacionesValorBase.reduce((acc, curr) => acc + parseFloat(curr || 0), 0);
        //         this.totalPuntos = this.Puntos.reduce((acc, curr) => acc + parseFloat(curr || 0), 0);

        //         console.log('Total Ponderación:', this.totalPonderacion);
        //         console.log('Total Puntos:', this.totalPuntos);

        //         // Asignar los puntos al departamento
        //         this.dSumaPuntosDepto = this.totalPuntos;

        //         console.log('valor total de los puntos en depa:',this.dSumaPuntosDepto);

        //         // Crear el conjunto de datos combinado para mostrar
        //         // this.combinedData = this.sortedData.map((item, index) => ({
        //         //     ...item,
        //         //     dReal: this.dReal[index],
        //         //     Desemp: this.Desemp[index],
        //         //     Puntos: this.Puntos[index]
        //         // }));

        //         // console.log('combinedData:', this.combinedData);
        //     } else {
        //         console.error('Las longitudes de los arrays no coinciden en validacionesTest.');
        //     }
        // },

        //ESTE DE AQUI EN EL ACUMULADO HACE EL PROMEDIO
//         async validacionesTest() {
//             // console.log('---------------------------------------')
//     function segundoIF(valor) {
//         return valor === null || valor === "Promedio" || valor === "";
//     }

//     // Imprimir valores iniciales antes de la conversión de valores vacíos a 0
//     // console.log("Valores iniciales:");
//     // console.log("ValorPrimerValorBase:", this.ValorPrimerValorBase);
//     // console.log("ValorSegundoValorBase:", this.ValorSegundoValorBase);
//     // console.log("ValorTerceroValorBase:", this.ValorTerceroValorBase);
    
//     // Convertir valores vacíos a 0
//     this.ValorPrimerValorBase = this.ValorPrimerValorBase.map(val => val === "" ? 0 : val);
//     this.ValorSegundoValorBase = this.ValorSegundoValorBase.map(val => val === "" ? 0 : val);
//     this.ValorTerceroValorBase = this.ValorTerceroValorBase.map(val => val === "" ? 0 : val);

//     // console.log("Valores después de convertir vacíos a 0:");
//     // console.log("ValorPrimerValorBase:", this.ValorPrimerValorBase);
//     // console.log("ValorSegundoValorBase:", this.ValorSegundoValorBase);
//     // console.log("ValorTerceroValorBase:", this.ValorTerceroValorBase);

//     // Calcular dReal según el mes
//     if (this.iMes === this.PrimerMesNumValorBase) {
//         this.dReal = [...this.ValorPrimerValorBase];
//         // console.log("Mes coincide con PrimerMesNumValorBase, dReal:", this.dReal);
//     } else if (this.iMes === this.SegundoMesNumValorBase) {
//         if (this.TiposCalculoValorBase.some(segundoIF)) {
//             this.dReal = this.ValorPrimerValorBase.map((valor, index) => (valor + this.ValorSegundoValorBase[index]) / 2);
//             // console.log("Mes coincide con SegundoMesNumValorBase, promedio calculado dReal:", this.dReal);
//         } else {
//             this.dReal = this.ValorSegundoValorBase.some(value => value !== 0) ? [...this.ValorSegundoValorBase] : [...this.ValorPrimerValorBase];
//             // console.log("Mes coincide con SegundoMesNumValorBase, asignación directa dReal:", this.dReal);
//         }
//     } else if (this.iMes === this.TercerMesNumValorBase) {
//         if (this.TiposCalculoValorBase.some(segundoIF)) {
//             this.dReal = this.ValorPrimerValorBase.map((valor, index) => 
//                 (valor + this.ValorSegundoValorBase[index] + this.ValorTerceroValorBase[index]) / 3
//             );
//             // console.log("Mes coincide con TercerMesNumValorBase, promedio de tres meses calculado dReal:", this.dReal);
//         } else {
//             if (Array.isArray(this.ValorTerceroValorBase) && this.ValorTerceroValorBase.length > 0) {
//                 this.dReal = this.ValorTerceroValorBase;
//             } else if (Array.isArray(this.ValorSegundoValorBase) && this.ValorSegundoValorBase.length > 0) {
//                 this.dReal = this.ValorSegundoValorBase;
//             } else if (Array.isArray(this.ValorPrimerValorBase) && this.ValorPrimerValorBase.length > 0) {
//                 this.dReal = this.ValorPrimerValorBase;
//             }
//             // console.log("Mes coincide con TercerMesNumValorBase, asignación directa dReal:", this.dReal);
//         }
//     }

//     // console.log("Valores finales de dReal:", this.dReal);

//     // Validación de longitud de arrays antes del cálculo de desempeño y puntos
//     if (this.LEValorBase.length === this.VOValorBase.length && this.LEValorBase.length === this.dReal.length && this.LEValorBase.length === this.PonderacionesValorBase.length) {
//         this.Desemp = this.LEValorBase.map((le, index) => {
//             const vo = this.VOValorBase[index];
//             const dr = this.dReal[index];
//             if ((le - vo) !== 0) {
//                 const desemp = ((le - dr) / (le - vo)) * 100;
//                 return Math.min(Math.max(desemp, 0), 100);
//             } else {
//                 return 0;
//             }
//         });
//         // console.log("Desempeño calculado (Desemp):", this.Desemp);

//         this.Puntos = this.Desemp.map((desemp, index) => (desemp * this.PonderacionesValorBase[index]) / 100);
//         //console.log("Puntos calculados:", this.Puntos);

//         this.dSumaPuntosDepto = this.Puntos.reduce((acc, curr) => acc + curr, 0);
//         //console.log("Suma total de puntos de departamento (dSumaPuntosDepto):", this.dSumaPuntosDepto);

//         this.combinedData = this.sortedData.map((item, index) => ({
//             ...item,
//             dReal: this.dReal[index],
//             Desemp: this.Desemp[index],
//             Puntos: this.Puntos[index]
//         }));
//         // console.log("Datos combinados (combinedData):", this.combinedData);
//     } else {
//         console.error('Las longitudes de los arrays no coinciden');
//     }
// },

     

//EL BUENO ES EL QUE ESTA ARRIBA

        validacionesTest() {
            function segundoIF(valor) {
                return valor === null || valor === "Promedio" || valor === "";
            }

            this.ValorPrimerValorBase = this.ValorPrimerValorBase.map(val => val === "" ? 0 : val);
            this.ValorSegundoValorBase = this.ValorSegundoValorBase.map(val => val === "" ? 0 : val);
            this.ValorTerceroValorBase = this.ValorTerceroValorBase.map(val => val === "" ? 0 : val);

            // console.log('Valores después de inicializar vacíos:', this.ValorPrimerValorBase, this.ValorSegundoValorBase, this.ValorTerceroValorBase);

            if (this.iMes == this.PrimerMesNumValorBase) {
                // console.log('ENTRO EN EL PRIMERO');
                this.dReal = this.ValorPrimerValorBase;
            } else if (this.iMes == this.SegundoMesNumValorBase) {
                // console.log('ENTRO EN EL SEGUNDO');
                if (this.TiposCalculoValorBase.some(segundoIF)) {
                    if (Array.isArray(this.ValorPrimerValorBase) && Array.isArray(this.ValorSegundoValorBase) &&
                        this.ValorPrimerValorBase.length === this.ValorSegundoValorBase.length) {
                        this.dReal = this.ValorPrimerValorBase.map((valor, index) => (valor + this.ValorSegundoValorBase[index]) / 2);
                    } else {
                        console.error('Los arrays no tienen la misma longitud o uno de los valores no es un array');
                    }
                } else {
                    if (this.ValorSegundoValorBase.some(value => value != 0)) {
                        this.dReal = this.ValorSegundoValorBase;
                    } else if (this.ValorPrimerValorBase.some(value => value != 0)) {
                        this.dReal = this.ValorPrimerValorBase;
                    }
                }
            } else if (this.iMes == this.TercerMesNumValorBase) {
                // console.log('ENTRO EN EL TERCERO');
                if (this.TiposCalculoValorBase.some(segundoIF)) {
                    if (Array.isArray(this.ValorPrimerValorBase) && Array.isArray(this.ValorSegundoValorBase) && Array.isArray(this.ValorTerceroValorBase) &&
                        this.ValorPrimerValorBase.length === this.ValorSegundoValorBase.length && this.ValorPrimerValorBase.length === this.ValorTerceroValorBase.length) {
                        this.dReal = this.ValorPrimerValorBase.map((valor, index) => (valor + this.ValorSegundoValorBase[index] + this.ValorTerceroValorBase[index]) / 3);
                    }
                } else {
                    if (this.ValorTerceroValorBase.some(value => value != 0)) {
                        this.dReal = this.ValorTerceroValorBase;
                    } else if (this.ValorSegundoValorBase.some(value => value != 0)) {
                        this.dReal = this.ValorSegundoValorBase;
                    } else if (this.ValorPrimerValorBase.some(value => value != 0)) {
                        this.dReal = this.ValorPrimerValorBase;
                    }
                }
            }

            // console.log('Valores de dReal:', this.dReal);
            // console.log('VO:', this.VOValorBase);
            // console.log('LE:', this.LEValorBase);
            // console.log('POnderaciones:', this.PonderacionesValorBase);

            if (Array.isArray(this.LEValorBase) && Array.isArray(this.VOValorBase) &&
                Array.isArray(this.dReal) && Array.isArray(this.PonderacionesValorBase) &&
                this.LEValorBase.length === this.VOValorBase.length &&
                this.LEValorBase.length === this.dReal.length &&
                this.LEValorBase.length === this.PonderacionesValorBase.length) {

                this.Desemp = this.LEValorBase.map((le, index) => {
                    const vo = this.VOValorBase[index];
                    const dr = this.dReal[index];
                    if ((le - vo) !== 0) {
                        const desemp = ((le - dr) / (le - vo)) * 100;
                        if (desemp < 0) return 0;
                        if (desemp > 100) return 100;
                        return desemp;
                    } else {
                        return 0;
                    }
                });

                this.Puntos = this.Desemp.map((desemp, index) => (desemp * this.PonderacionesValorBase[index]) / 100);

                // console.log('Valores de Desemp:', this.Desemp);
                // console.log('Valores de Puntos:', this.Puntos);

                this.dSumaPuntosDepto = this.Puntos.reduce((acc, curr) => acc + curr, 0);
                if (Array.isArray(this.Puntos)) {
                    this.dSumaPuntosDepto = this.Puntos.reduce((acc, curr) => acc + curr, 0);
                } else {
                    console.error('this.Puntos no es un array');
                }
            } else {
                console.log('NO ENTRO AL IF!!!!')
            }
        }
    }
};
//Mensaje
</script>

<style>
.rounded-simple-table-head-l {
    border-top-left-radius: 22px !important;
}

.rounded-simple-table-head-r {
    border-top-right-radius: 22px !important;
}

.back-card-objectives {
    background: rgb(255, 255, 255) !important;
    background: linear-gradient(301deg,
            rgba(255, 255, 255, 1) 15%,
            rgba(201, 78, 148, 0.7344071417629552) 45%,
            rgba(255, 255, 255, 1) 100%) !important;
}

.centrado {
    text-align: center;
    font-size: 18px;
}
</style>
