<template>
  <v-card name="reconocimiento" class="mx-auto rounded-xl" :color="content['color']" dark max-height="220px" outlined>
    <v-card-text class="py-0" style="color:white !important;">
      <div style="color:white;">{{ $t('De') }}: {{ content['Nombre'] }}</div>
      <div class="font-weight-bold" style="color:white;">- {{ content['Comentarios'] }}</div>
      <!-- {{ content['Comentarios'] }} -->
    </v-card-text>
    <v-card-actions>
      <v-list-item class="grow">
        <!-- <v-list-item-avatar color="grey darken-3">
           <v-img
            class="elevation-6"
            alt=""
            src="@/assets/img/placeholder_user.svg"
          ></v-img> 
        </v-list-item-avatar> -->
        <v-list-item-content>
          <v-list-item-title>{{ $t('Para') }}: {{ content['Valorado'] }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'persona-valoracion-card',
  data: () => ({
    dialog: false,
    activeSlideIdx: 0
  }),
  props: {
    'dialogVisible': Boolean,
    'content': { type: Object },
  },
  computed: {

  },
  components: {

  },
  mounted() {
    //(this.content);
  },
  methods: {

  }
}

</script>
<style lang="sass">

  .v-dialog--scrollable

    .text-overline.ms-4.my-4
      margin-bottom: 0 !important

</style>
