<template>
    <div>
        <snack-bar-error-incidencias v-if="muestraBarra" :muestraBarra="muestraBarra" :texto="textoBarra"
            :color="colorBarra" @cierraBarra="muestraBarra = false"></snack-bar-error-incidencias>

        <SeccionDialog v-model="mostrarDialogCrearSeccion" @seccionAgregada="agregarSeccion">
        </SeccionDialog>

        <v-card>
            <v-row>
                <v-col cols="12" sm="3" md="3">
                    <v-card class="text-center">
                        <v-toolbar color="primary" dark dense elevation="19" flat outlined rounded shaped>
                            <v-spacer></v-spacer>
                            <v-toolbar-title>¡Crea tu encuesta!</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>

                        <v-card color="grey lighten-5" class="pa-2">
                            <v-col cols="12">
                                <v-btn color="primary" small class="font-weight-bold rounded-lg" @click="saveEncuesta">
                                    <v-icon left small>mdi-content-save</v-icon> Guardar Encuesta
                                </v-btn>
                            </v-col>
                            <v-col cols="12">
                                <v-btn color="primary" small class="font-weight-bold rounded-lg" @click="Clean">
                                    <v-icon left small>mdi-monitor-shimmer</v-icon> Limpiar encuesta
                                </v-btn>
                            </v-col>
                        </v-card>

                        <v-divider></v-divider>

                        <v-card color="grey lighten-5">
                            <v-toolbar color="primary" dark dense outlined rounded>


                                <v-spacer></v-spacer>
                                <v-toolbar-title>Preguntas</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-col cols="12">
                                <v-btn color="primary" class="font-weight-bold rounded-lg" small @click="dialog = true">
                                    <v-icon left small>mdi-chat-question</v-icon> Agregar Preguntas
                                </v-btn>
                            </v-col>

                            <v-col cols="12">
                                <v-btn color="primary" class="font-weight-bold rounded-lg" small
                                    @click="mostrarDialogCrearSeccion = true">
                                    <v-icon left small>mdi-pen-plus</v-icon> Crear Sección
                                </v-btn>
                            </v-col>
                        </v-card>

                        <v-dialog v-model="dialog" max-width="600px">
                            <v-card>
                                <v-toolbar color="primary" dark>
                                    Agregar Pregunta
                                    <v-spacer></v-spacer>
                                    <v-btn icon dark @click="dialog = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-card-text>
                                    <v-select v-model="preguntaSeleccionada" :items="tiposDePregunta"
                                        label="Tipo de pregunta" outlined dense class="mt-4"></v-select>

                                    <v-text-field v-model="preguntaTexto" label="Escribe tu pregunta" outlined
                                        dense></v-text-field>
                                    <v-row
                                        v-if="preguntaSeleccionada === 'Cerrada' || preguntaSeleccionada === 'Opcion Multiple'">
                                        <v-col v-for="(opcion, index) in opciones" :key="index" cols="12" sm="6" md="4">
                                            <v-text-field v-model="opciones[index]" :label="'Opcion ' + (index + 1)"
                                                outlined dense></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-btn small @click="agregarOpcion" class="mt-4" color="primary">Agregar
                                        Opcion</v-btn>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="teal" dark elevation="2" @click="agregarPregunta">
                                        Agregar Pregunta
                                    </v-btn>
                                    <v-btn color="error" elevation="2" dark @click="dialog = false">
                                        Cerrar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="9" md="9">
                    <v-row justify="center">
                        <v-col cols="12">
                            <v-card color="grey lighten-5" class="ma-2">
                                <v-toolbar color="primary" dark dense flat outlined rounded shaped>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-title>Encuesta</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                                <v-row justify="center">
                                    <v-col cols="12" sm="8" md="18" class="d-flex align-center mt-4">
                                        <v-text-field v-model="tituloencuesta" label="Nombre de la encuesta" outlined
                                            dense></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" sm="8" md="8" class="d-flex align-center">
                                        <v-text-field v-model="descencuesta" label="Descripción de la encuesta" outlined
                                            dense></v-text-field>
                                    </v-col>
                                </v-row>


                                <div v-for="(seccion, indexSeccion) in secciones" :key="indexSeccion">
                                    <v-col cols="12" sm="9" md="9" class="d-flex align-center">
                                        <h3>Sección {{ indexSeccion + 1 }}</h3>
                                    </v-col>
                                    <div v-for="(pregunta) in seccion.preguntas" :key="pregunta.id">
                                        <v-col cols="12" sm="9" md="9" class="d-flex align-center">
                                            <h3>{{ pregunta.id }}. {{ pregunta.texto }} ({{ pregunta.tipo }})</h3>
                                        </v-col>

                                        <v-col v-if="pregunta.opciones.length > 0" cols="12">
                                            <v-row justify="center">
                                                <v-col v-for="(opcion, indexOpcion) in pregunta.opciones"
                                                    :key="indexOpcion" cols="auto">
                                                    <v-radio v-if="pregunta.tipo === 'Cerrada'" :label="opcion"
                                                        disabled></v-radio>
                                                    <v-checkbox v-else :label="opcion" disabled></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </div>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-dialog v-model="mostrarCargarUsuarios" max-width="700" scrollable content-class="fix-border-dialog">
                <v-card class="rounded-xl">
                    <v-card-title class="primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold">
                        Cargar Usuarios
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="mostrarCargarUsuarios = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-radio-group v-model="tipoCarga" row>
                                    <v-radio label="Cargar por Excel" value="excel"></v-radio>
                                    <v-radio label="Cargar por Criterio" value="criterio"
                                        @click="loadCriterios()"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col v-if="tipoCarga === 'excel'" cols="12" sm="8" md="8">
                                <v-file-input accept=".xlsx, .xls, .csv" placeholder="Selecciona el archivo"
                                    prepend-icon="mdi-microsoft-excel" v-model="file"></v-file-input>

                                <v-btn color="primary" class="mt-2" @click="iniciarCargaArchivo" :disabled="!file">
                                    Cargar Archivo
                                </v-btn>
                            </v-col>
                            <v-col v-if="tipoCarga === 'criterio'" cols="12">
                                <v-autocomplete v-model="opcionesCriterio[0].valorSeleccionado"
                                    :items="opcionesCriterio[0].datos" label="Seleccionar Área" item-text="text"
                                    item-value="value" outlined dense @change="loadDepartamentosPorArea" />
                                <v-autocomplete v-model="opcionesCriterio[1].valorSeleccionado"
                                    :items="opcionesCriterio[1].datos" label="Seleccionar Departamento" item-text="text"
                                    item-value="value" outlined dense @change="loadGruposTrabajo" />
                                <v-autocomplete v-model="opcionesCriterio[2].valorSeleccionado"
                                    :items="opcionesCriterio[2].datos" label="Seleccionar Grupo de Trabajo"
                                    item-text="text" item-value="value" outlined dense />
                                <v-btn color="primary" class="mt-2 mr-2" @click="cargarPorCriterio">
                                    Cargar Usuarios
                                </v-btn>
                                <v-btn color="success" class="mt-2" @click="verUsuarios">
                                    Ver Usuarios
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="mostrarUsuariosDialog" max-width="800px" scrollable>
                <v-card>
                    <v-card-title>
                        <span class="headline">Lista de Usuarios</span>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="mostrarUsuariosDialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="userHeaders" :items="listaUsuarios" dense no-data-text="No hay usuarios"
                            :footer-props="{ 'items-per-page-text': 'Elementos por página', 'pageText': '{0}-{1} de {2}' }"
                            :header-class="['primary', 'white--text', 'elevation-1']" class="rounded-lg">
                            <template v-slot:top>
                                <div style="display: flex; justify-content: space-between; align-items: center;"
                                    class="primary pt-4 rounded-l-t-header-pases-admin rounded-r-t-header-pases-admin rounded-lg rounded-b-0">
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="mostrarUsuariosDialog = false">Cerrar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import axios from 'axios';
import SnackBarErrorIncidencias from '../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue';
import SeccionDialog from './dialogSeccion.vue';

export default {
    components: {
        SnackBarErrorIncidencias,
        SeccionDialog
    },
    data() {
        return {
            mostrarUsuariosDialog: false,
            mostrarCargarUsuarios: false,
            userHeaders: [
                { text: '#', value: 'index' },
                { text: 'Folio', value: 'folio' },
                { text: 'Nombre', value: 'nombre' },
            ],
            mostrarDialogCrearSeccion: false,
            listaUsuarios: [],
            file: null,
            tipoCarga: 'excel',
            opcionesCriterio: [
                {
                    valorSeleccionado: null,
                    descripcion: 'Área',
                    datos: [],
                },
                {
                    valorSeleccionado: null,
                    descripcion: 'Departamento',
                    datos: [],
                },
                {
                    valorSeleccionado: null,
                    descripcion: 'Grupo de Trabajo',
                    datos: [],
                },
            ],
            dialog: false,
            secciones: [],
            tituloencuesta: '',
            descencuesta: '',
            preguntaSeleccionada: null,
            preguntaTexto: '',
            opciones: [],
            tiposDePregunta: ['Abierta', 'Cerrada', 'Opcion Multiple'],
            textoBarra: '',
            colorBarra: '',
            muestraBarra: false,
            seccionSeleccionada: null,
            contadorPreguntas: 0,
        };
    },
    methods: {
        agregarSeccion(nuevaSeccion) {
            nuevaSeccion.preguntas = [];
            nuevaSeccion.seccion = this.secciones.length + 1;
            this.secciones.push(nuevaSeccion);
            this.seccionSeleccionada = nuevaSeccion;
        },
        agregarOpcion() {
            this.opciones.push('');
        },
        agregarPregunta() {
            if (!this.seccionSeleccionada) {

                this.mostrarSnackBar('Por favor, primero cree sección antes de agregar una pregunta', 'red accent-2');
                return;
            }

            if (this.validarPregunta()) {
                const nuevaPregunta = {
                    id: this.contadorPreguntas + 1,
                    tipo: this.preguntaSeleccionada,
                    texto: this.preguntaTexto,
                    opciones: this.preguntaSeleccionada === 'Cerrada' || this.preguntaSeleccionada === 'Opcion Multiple' ? [...this.opciones] : [],
                };
                this.seccionSeleccionada.preguntas.push(nuevaPregunta);
                this.contadorPreguntas++;
                this.limpiarFormulario();
                this.mostrarSnackBar('Pregunta agregada correctamente', 'green');
            } else {
                this.mostrarSnackBar('Campos incompletos para agregar la pregunta', 'red accent-2');
            }
        },
        limpiarFormulario() {
            this.preguntaSeleccionada = null;
            this.preguntaTexto = '';
            this.opciones = [];
        },
        validarPregunta() {
            if (!this.preguntaTexto.trim()) {
                return false;
            }
            if (this.preguntaSeleccionada === 'Cerrada' || this.preguntaSeleccionada === 'Opcion Multiple') {
                if (this.opciones.length < 2) {
                    return false;
                }
                for (const opcion of this.opciones) {
                    if (!opcion.trim()) {
                        return false;
                    }
                }
            }
            return true;
        },
        mostrarSnackBar(mensaje, color) {
            this.textoBarra = mensaje;
            this.colorBarra = color;
            this.muestraBarra = true;
        },
        Clean() {
            this.tituloencuesta = '';
            this.descencuesta = '';
            this.secciones = [];
            this.contadorPreguntas = 0;
        },
        async saveEncuesta() {
            if (!this.tituloencuesta.trim()) {
                this.mostrarSnackBar('El título no puede estar vacío', 'red accent-2');
                return;
            }
            if (!this.descencuesta.trim()) {
                this.mostrarSnackBar('La descripción no puede estar vacía', 'red accent-2');
                return;
            }

            if (!this.secciones.length) {
                this.mostrarSnackBar('Debe agregar al menos una sección', 'red accent-2');
                return;
            }

            try {
                const data = {
                    titulo: this.tituloencuesta,
                    descripcion: this.descencuesta,
                    secciones: this.secciones,
                    usuario: this.$store.getters.getUser.Folio,
                };

                const response = await axios.post(
                    `${this.$store.getters.getHostNode}/api/guardarEncuesta`,
                    data,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );

                if (response.data.status) {
                    console.log(JSON.stringify(response.data, null, 2));
                    this.mostrarSnackBar('Encuesta guardada correctamente', 'green');
                    this.idEncuesta = response.data.data; // Guarda el ID de la encuesta guardada, que está en response.data.data
                    this.Clean();
                    this.mostrarCargarUsuarios = true;
                } else {
                    this.mostrarSnackBar('Error al guardar la encuesta', 'red accent-2');
                }
            } catch (error) {
                console.error('Error al guardar la encuesta:', error);
                this.mostrarSnackBar('Error de servidor al guardar la encuesta', 'red accent-2');
            }
        },
        async loadCriterios() {
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/getEmpleadosArea`, {
                    headers: { Authorization: localStorage.getItem("token") }
                });
                this.opcionesCriterio[0].datos = response.data.data.map(area => ({
                    value: area.UR_AREA_DEPT,
                    text: area.DESCR
                }));
            } catch (error) {
                console.error('Error al cargar criterios:', error);
                this.mostrarSnackBar('Error al cargar criterios', 'red accent-2');
            }
        },
        async loadDepartamentosPorArea() {
            const areaSeleccionada = this.opcionesCriterio[0].valorSeleccionado;
            if (!areaSeleccionada) return;
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/getEmpleadosDepartamento/${areaSeleccionada}`, {
                    headers: { Authorization: localStorage.getItem("token") },
                });
                this.opcionesCriterio[1].datos = response.data.data.map(dept => ({
                    value: dept.DEPTID,
                    text: dept.DESCR
                }));
            } catch (error) {
                console.error('Error al cargar departamentos:', error);
                this.mostrarSnackBar('Error al cargar departamentos', 'red accent-2');
            }
        }, async loadGruposTrabajo() {
            const areaSeleccionada = this.opcionesCriterio[0].valorSeleccionado;
            const deptSeleccionado = this.opcionesCriterio[1].valorSeleccionado;

            if (!areaSeleccionada || !deptSeleccionado) return;

            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/getEmpleadosGruposTrabajo/${areaSeleccionada}/${deptSeleccionado}`, {
                    headers: { Authorization: localStorage.getItem("token") },
                });
                this.opcionesCriterio[2].datos = response.data.data.map(grupo => ({
                    value: grupo.grupo_Trabajo,
                    text: grupo.grupo_Trabajo
                }));
            } catch (error) {
                console.error('Error al cargar grupos de trabajo:', error);
                this.mostrarSnackBar('Error al cargar grupos de trabajo', 'red accent-2');
            }
        }, iniciarCargaArchivo() {
            if (!this.file) {
                this.mostrarSnackBar('Por favor, selecciona un archivo', 'red accent-2');
                return;
            }
            this.handleFileUpload(this.file);
            this.mostrarSnackBar('Archivo cargado correctamente', 'green');
        },
        async cargarPorCriterio() {
            if (!this.idEncuesta) {
                this.mostrarSnackBar('Por favor, guarde la encuesta antes de cargar los usuarios por criterio', 'red accent-2');
                return;
            }

            try {
                const area = this.opcionesCriterio[0].valorSeleccionado;
                const departamento = this.opcionesCriterio[1].valorSeleccionado;
                const grupoTrabajo = this.opcionesCriterio[2].valorSeleccionado;

                let tipo = '';
                if (area && !departamento && !grupoTrabajo) {
                    tipo = 'area';
                } else if (area && departamento && !grupoTrabajo) {
                    tipo = 'departamento';
                } else if (area && departamento && grupoTrabajo) {
                    tipo = 'grupotrabajo';
                }

                const data = {
                    area: area || '',
                    departamento: departamento || '',
                    grupoTrabajo: grupoTrabajo || '',
                    tipo: tipo,
                    idEncuesta: this.idEncuesta // Usar el ID de la encuesta guardada
                };

                const response = await axios.post(
                    `${this.$store.getters.getHostNode}/api/cargarUsuariosCriterio`,
                    data,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );
                if (response.data && response.data.status) {
                    this.mostrarSnackBar('Usuarios cargados correctamente', 'green');
                } else {
                    throw new Error('La carga de usuarios falló');
                }
            } catch (error) {
                console.error('Error al cargar usuarios:', error);
                this.mostrarSnackBar('Error al cargar usuarios', 'red accent-2');
            }
        },
        async verUsuarios() {
            try {
                const area = this.opcionesCriterio[0].valorSeleccionado;
                const departamento = this.opcionesCriterio[1].valorSeleccionado;
                const grupoTrabajo = this.opcionesCriterio[2].valorSeleccionado;

                let tipo = '';
                if (area && !departamento && !grupoTrabajo) {
                    tipo = 'area';
                } else if (area && departamento && !grupoTrabajo) {
                    tipo = 'departamento';
                } else if (area && departamento && grupoTrabajo) {
                    tipo = 'grupotrabajo';
                }
                const response = await axios.get(
                    `${this.$store.getters.getHostNode}/api/getUsuariosPorCriterio/${area}/${departamento}/${grupoTrabajo}/${tipo}`,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );
                if (response.data && Array.isArray(response.data.data)) {
                    this.listaUsuarios = response.data.data.map((usuario, index) => ({
                        index: index + 1,
                        folio: usuario.EMPLID,
                        nombre: usuario.EMP_NOMEMP,
                    }));
                } else {
                    throw new Error('La respuesta de la API no es válida o no contiene datos');
                }

                this.mostrarUsuariosDialog = true;
            } catch (error) {
                console.error('Error al obtener usuarios:', error);
                this.mostrarSnackBar('Error al obtener usuarios', 'red accent-2');
            }
        },
        async handleFileUpload(event) {
            this.file = event;
            if (!this.file) return;
            console.log(this.idEncuesta)
            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('idEncuesta', this.idEncuesta);

            try {
                const response = await axios.post(
                    `${this.$store.getters.getHostNode}/api/cargarUsuariosCsv`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );

                if (response.data.status === 'OK') {
                    this.mostrarSnackBar('Archivo procesado correctamente', 'green');
                    this.mostrarCargarUsuarios = false;
                } else {
                    this.mostrarSnackBar('Error al procesar el archivo', 'red accent-2');
                }
            } catch (error) {
                console.error('Error de servidor al cargar el archivo:', error);
                this.mostrarSnackBar('Error de servidor: ' + error.message, 'red accent-2');
            } finally {
                this.file = null;
            }
        },

    }
};
</script>