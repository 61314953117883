<template>
  <div>
    <v-sheet
      v-if="loadingData"
      max-width="700px"
      height="300"
      class="center-item-in-div"
    >
      <v-progress-circular
        :size="70"
        :width="12"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-sheet>
    <v-sheet v-else max-width="700px" class="elevation-2 mt-8">
      <!-- {{ evidencias }} -->
      <v-data-table
        :items="evidencias"
        :headers="headers"
        no-data-text="No se registró evidencia."
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
          pageText: '{0}-{1} de {2}',
        }"
      >
      <template v-slot:item.nombre_archivo="{item}">
        {{ item.nombre_archivo.includes(".txt") ? item.nombre_archivo.slice(0, -4) : item.nombre_archivo }}
    </template>
      <template v-slot:item.accion="{item}">
        <v-btn small depressed class="rounded-lg" color="cyan" dark @click="openFile(item)">
        <v-icon left>mdi-eye</v-icon>
        ver
        </v-btn>
      </template>
      </v-data-table>
    </v-sheet>
  </div>
</template>

<script>
import { formatDate } from "../../../../../store/Funciones/funciones";
import axios from "axios";
export default {
  name: "muestra-pase-de-salida",
  props: ["idVisita"],
  data() {
    return {
      evidencias: [],
      headers: [
        {
          value: "id_visita",
          text: "ID",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-3",
        },
        {
          value: "nombre_archivo",
          text: "ARCHIVO",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-3",
        },
        {
          value: "accion",
          text: "ACCIONES",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-3",
        },
      ],
      loadingData: true,
    };
  },
  async mounted() {
    this.evidencias = await this.getInfoEvidencias();
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.name === "xs" ? true : false;
    },
  },
  methods: {
    async getInfoEvidencias() {
      let params = {
        Opcion: 45,
        token: localStorage.getItem("token"),
        idVisita: this.idVisita,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            }
          })
          .catch((error) => {
            reject([{ nombre: error }]);
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    fixDate(date) {
      return formatDate(date);
    },
    async openFile(evidencia) {
      let params = {
        Opcion: 46,
        token: localStorage.getItem('token'),
        idVisita: this.idVisita,
        filename: evidencia.nombre_archivo
      };

      try {
        const response = await axios.post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        );

        if (response.data.status === "OK") {
          let arrayDataFile = response.data.respuesta.split(",");
        //   console.log(arrayDataFile[0].includes("image"));
          const blob = await this.base64ToBlob(
            arrayDataFile[1],
            arrayDataFile[0]
          );
          const fileUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          if (!arrayDataFile[0].includes("image")) {
            link.href = fileUrl;
            link.download = evidencia.nombre_archivo.slice(0, -4) + ".pdf";
            link.click();
          } else {
            console.log("llega")
            link.href = fileUrl;
            link.download = evidencia.nombre_archivo.slice(0, -4) + ".jpg";
            link.click();
          }
        } else {
          alert(
            "Parece que el archivo está dañado o no existe. Se recomienda reemplazar."
          );
        }
      } catch (error) {
        console.error("Ocurrió un error:", error);
        alert("Ocurrió un error al descargar el archivo.");
      }
    },
    async base64ToBlob(base64, contentType = "") {
      // let arrayBase64 = base64.split(",")
      const byteCharacters = atob(base64);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, {
        type: contentType,
      });
    },
  },
};
</script>
