<template>
    <v-dialog v-model="verCrearRolUsuario" persistent max-width="90%" scrollable>
        <v-card class="rounded-lg">
            <v-card-title class="primary">
                <div class="overline font-weight-bold white--text">
                    asignar rol usuario
                </div>
                <v-spacer></v-spacer>
                <v-icon dark @click="$emit('cierraCrearRolUsuario', cambios)">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="pt-5 blue-grey lighten-5">
                <v-sheet class="rounded-0 elevation-1" min-height="400" width="100%">
                    <div class="pa-5">
                        <v-img
                            :src="require('@/assets/img/grupo_urrea_azul_gris.png')"
                            max-height="70"
                            width="250"
                        ></v-img>
                    </div>
                    <v-form ref="formRolUsuario">
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="6" v-if="!esEdicion">
                                    <v-autocomplete
                                        v-model="usuarioSeleccionado"
                                        class="rounded-lg"
                                        counter="180"
                                        dense
                                        outlined
                                        label="Nombre"
                                        item-text="nombreMostrar"
                                        :items="colaboradores"
                                        :loading="loadingColaboradores"
                                        :rules="rulesTextField"
                                        :readonly="soloLecturaUsuario"
                                        v-on:change="llenaCampos"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="6" v-else>
                                    <v-text-field
                                        v-model="usuarioSeleccionado"
                                        class="rounded-lg"
                                        dense
                                        outlined
                                        label="Nombre"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-select
                                        v-model="rolDUsuario"
                                        style="max-height: 42px !important;"
                                        class="rounded-lg"
                                        dense
                                        outlined
                                        placeholder="Tipo de permiso"
                                        :items="tipoRol"
                                        item-text="descripcion"
                                        item-value="codigo"
                                        :rules="rulesTextField"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-sheet>
            </v-card-text>
            <v-card-actions class="center-item-in-div">
                <v-btn
                    class="rounded-lg"
                    x-large
                    color="teal darken-1"
                    :loading="loadingBtnSavePasse"
                    dark
                    @click="loadingBtnSavePasse = true; guardar();"
                >
                    <v-icon left>mdi-text-box-plus</v-icon>
                    guardar rol de usuario
                </v-btn>
            </v-card-actions>
            <SnackBarErrorIncidencias
                v-if="muestraBarra"
                :muestraBarra="muestraBarra"
                :color="colorBarra"
                :texto="textoBarra"
                @cierraBarra="muestraBarra = false"
            />
        </v-card>
        <v-overlay
            v-model="dialogCargando"
            contained
            class="align-center justify-center"
            width="auto"
        >
            <v-card title="Cargando...">
                <v-card-text>
                    <v-label><v-progress-circular indeterminate></v-progress-circular></v-label>
                </v-card-text>
            </v-card>
        </v-overlay>
    </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones.js";
import SnackBarErrorIncidencias from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
    name: "material-items-alta",
    props: ["verCrearRolUsuario", "idUsuario"],
    components: { SnackBarErrorIncidencias },
    data() {
        return {
            cambios: 0,
            usuarioFolio: 0,
            usuarioCorreo: "",
            usuarioSeleccionado: "",
            rolDUsuario: "",
            colaboradores: [],
            tipoRol: [
                {codigo: "REMERCANCIA", descripcion: "APROBADOR MERCANCÍA"},
                {codigo: "CRMERCANCIA", descripcion: "APROBADOR MERCANCÍA, CREAR MATERIAL E ITEMS"},
                {codigo: "ADMERCANCIA", descripcion: "APROBADOR MERCANCÍA, CREAR MATERIAL E ITEMS, ASIGNAR PERMISOS APROBADOR"}
            ],

            soloLecturaUsuario: false,
            loadingColaboradores: true,
            dialogCargando: false,
            loadingBtnSavePasse: false,
            muestraBarra: false,
            esEdicion: false,

            rulesTextField: [
                (v) => !!v || "Este campo es requiredo.",
                (v) => (v && v.length <= 180) || "Este campo no debe tener más de 180 caracteres",
            ],
        };
    }, // data

    async mounted() {
        if (this.idUsuario > 0) {
            this.soloLecturaUsuario = true;
            this.rolDUsuario = "";
            this.esEdicion = true;
            this.editarRolUsuario();
        } else {
            this.colaboradores = await this.getColaboradores();
        }
    }, // mounted

    methods: {
        editarRolUsuario() {
            const headers = {
                Authorization: localStorage.getItem("token")
            };
            console.log(localStorage);
            const params = {
                usuarioMovimiento:  localStorage.getItem("folio"),
                IdUsuario: this.idUsuario
            };
            return new Promise((res) => {
                axios
                .get(`${this.$store.getters.getHostNode}/api/read-rol-usuario`,
                {
                    headers: headers,
                    params
                })
                .then((response) => {
                    console.log("editarRolUsuario =>",response);
                    if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else if (response.data.status == "OK") {
                        res(response.data.rolUsuarios);
                        this.usuarioFolio = this.idUsuario;
                        this.rolDUsuario = response.data.rolUsuarios[0].rol;
                        this.usuarioSeleccionado = response.data.rolUsuarios[0].Nombre;
                        this.usuarioCorreo = response.data.rolUsuarios[0].correo;
                    } else {
                        res([]);
                    }
                })
                .finally(() => {
                    this.loadingMateriales = false;
                });
            });
        }, // editarRolUsuario

        getColaboradores() {
            const headers = {
                Authorization: localStorage.getItem("token"),
            };
            return new Promise((res) => {
                axios
                .get(`${this.$store.getters.getHostNode}/api/get-all-colaboradores`, {
                    headers: headers,
                })
                .then((response) => {
                    console.log("usuarios=>", response);
                    if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else if (response.data.status == "OK") {
                        res(response.data.colaboradores);
                    }
                })
                .finally(() => {
                    this.loadingColaboradores = false;
                });
            });
        },
        llenaCampos() {
            let colaboradorSeleccionado = this.colaboradores.filter((e) => {
                return e.nombreMostrar === this.usuarioSeleccionado;
            });
            if (colaboradorSeleccionado.length > 0) {
                this.usuarioFolio = colaboradorSeleccionado[0].EMPLID;
                this.usuarioCorreo = colaboradorSeleccionado[0].correo;
            }
        }, // llenaCampos

        guardar(){
            if (this.$refs.formRolUsuario.validate()) {
                const headers = {
                    Authorization: localStorage.getItem("token"),
                };
                console.log("localStorage =>", localStorage);
                const params = {
                    usuarioMovimiento:  localStorage.getItem("folio"),
                    IdUsuario: this.usuarioFolio,
                    usuarioCorreo: this.usuarioCorreo,
                    rolDUsuario: this.rolDUsuario
                };
                console.log("params guardar usuarios =>", params);
                let cadenaUri = `${this.$store.getters.getHostNode}/api/create-rol-usuario`;
                if (this.idUsuario > 0) {
                    cadenaUri = `${this.$store.getters.getHostNode}/api/update-rol-usuario`;
                } // editar
                return new Promise((res) => {
                    axios.post(
                        cadenaUri,
                        params,
                        {
                            headers: headers,
                        }
                    ).then((response) => {
                        console.log("RolUguardar =>", response);
                        if (response.data.status == true) {
                            res(response.data);
                            this.textoBarra = "Registro exitoso";
                            this.colorBarra = "success";
                            this.muestraBarra = true;
                            return new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve(this.$emit('cierraCrearRolUsuario', "OK"));
                                }, 1500);
                            });
                        } else if (response.data.status == false) {
                            res(response.data);
                            this.textoBarra = response.data.message;
                            this.colorBarra = "error";
                            this.muestraBarra = true;
                        } else if (response.data.status == "EXPRESSION") {
                            deleteToken();
                            console.log("deleteToken");
                        }
                    });
                });
            } else {
                console.log("campos requeridos");
            }
            this.loadingBtnSavePasse = false;
        }, // guardar

    }, // methods
  }; // data
</script>

<style scoped>
.autocomplete {
    position: relative;
}

.autocomplete-list {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    z-index: 1;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
}

.autocomplete-list li {
    padding: 8px;
    cursor: pointer;
}

.autocomplete-list li.highlighted {
    background-color: #f2f2f2;
}
</style>