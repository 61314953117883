<template>
  <div>
    <v-dialog
      v-model="marcaSalida"
      min-width="80%"
      max-width="85%"
      content-class="fix-border-dialog"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title class="primary">
          <div class="text-uppercase white--text subtitle-1 font-weight-bold">
            Registrar salida [{{ datosVisita.id }}]
          </div>
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('cierraMarcaSalida')">mdi-close</v-icon>
        </v-card-title>

        <v-card-text>
          <v-sheet
            v-if="loadingVisitantes"
            class="center-item-in-div-column"
            min-height="200px"
          >
            <v-progress-circular
              :size="90"
              :width="10"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <label class="overline mt-2">Cargando información...</label>
          </v-sheet>
          <v-sheet v-else style="display: flex; flex-wrap: wrap;" >
            <v-card
              v-for="visitante in visitantes"
              :key="visitante.int"
              style="flex: 0 0 calc(31.00% - 1px);"
              class="rounded-xl ma-2 cente-item-in-div-column elevation-5"
              :color="getColorCard(visitante.en_plante)"
              :width="widthCard"
            >
              <v-card-text>
                <div
                  class="text-center text-uppercase text-h6 font-weight-bold"
                >
                  {{ visitante.nombre }}
                </div>
                <div class="text-center text-uppercase body-1">
                  {{
                    visitante.apellido_paterno +
                      " " +
                      visitante.apellido_materno
                  }}
                </div>
                <div
                  class="text-center overline primary--text font-weight-bold"
                >
                  {{ visitante.int }}
                </div>
                <div class="text-center body-1">{{ visitante.correo }}</div>
              </v-card-text>
              <v-card-actions class="pa-0">
                <v-btn
                  v-if="visitante.en_plante === 1"
                  block
                  depressed
                  class="rounded-xl rounded-t-0"
                  color="blue darken-1"
                  dark
                  :loading="loadingMarcaSalida"
                  @click="
                    muestraMaterial = true;
                    muestraMaterialRegistrado(visitante);
                  "
                >
                  <v-icon left>mdi-account-check-outline</v-icon>
                  Iniciar registro de salida
                </v-btn>
                <v-btn
                  v-else-if="visitante.en_plante === 0"
                  block
                  depressed
                  class="rounded-xl rounded-t-0"
                  disabled
                >
                  <v-icon left>mdi-account-check-outline</v-icon>
                  Ingreso no registrado
                </v-btn>
                <v-btn
                  v-else
                  block
                  depressed
                  class="rounded-xl rounded-t-0"
                  disabled
                >
                  <v-icon left>mdi-account-check-outline</v-icon>
                  Visita concluida
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-sheet>
          <v-sheet v-if="muestraMaterial" width="100%">
            <v-card
              class="px-10 py-3 my-2 elevation-0 rounded-lg"
              color="blue-grey lighten-5"
            >
              <div
                class="center-item-in-div-column"
                v-if="loadingArticulosPersonales"
              >
                <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="6"
                  style="max-width: 350px;"
                ></v-progress-linear>
                <label class="overline">Cargando artículos personales...</label>
              </div>
              <div v-else>
                <div
                  v-if="![undefined, null, []].includes(articulosPersonales)"
                >
                  <v-data-table
                    :headers="headers"
                    :items="articulosPersonales"
                    dense
                    style="background-color:transparent"
                    hide-default-footer
                  >
                    <template v-slot:item.FILE_TITLE="{ item }">
                      <v-btn
                        small
                        dark
                        depressed
                        color="cyan darken-1"
                        class="rounded-pill"
                        @click="openFile(item.ID, 'arts_personales', item.FILE_TITLE)"
                      >
                        <v-icon left>mdi-image-area</v-icon>
                        abrir
                      </v-btn>
                    </template>
                  </v-data-table>
                </div>
                <div v-else class="text-center">
                  <label class="overline"
                    >No se registraron artículos personales</label
                  >
                </div>
              </div>
            </v-card>

            <v-card
              class="px-10 py-3 my-2 elevation-0 rounded-lg"
              color="blue-grey lighten-5"
            >
              <div class="center-item-in-div-column" v-if="loadingConsumibles">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="6"
                  style="max-width: 350px;"
                ></v-progress-linear>
                <label class="overline"
                  >Cargando artículos consumibles...</label
                >
              </div>
              <div v-else>
                <div v-if="![undefined, null, []].includes(consumibles)">
                  <v-data-table
                    :headers="headers"
                    :items="consumibles"
                    dense
                    style="background-color:transparent"
                    hide-default-footer
                  >
                    <template v-slot:item.FILE_TITLE="{ item }">
                      <v-btn
                        small
                        dark
                        depressed
                        color="cyan darken-1"
                        class="rounded-pill"
                        @click="openFile(item.ID, 'consumibles', item.FILE_TITLE)"
                      >
                        <v-icon left>mdi-image-area</v-icon>
                        abrir
                      </v-btn>
                    </template>
                  </v-data-table>
                </div>
                <div v-else class="text-center">
                  <label class="overline"
                    >No se registraron artículos consumibles</label
                  >
                </div>
              </div>
            </v-card>

            <v-card
              class="px-10 py-3 my-2 elevation-0 rounded-lg"
              color="blue-grey lighten-5"
            >
              <div class="center-item-in-div-column" v-if="loadingHerramientas">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="6"
                  style="max-width: 350px;"
                ></v-progress-linear>
                <label class="overline">Cargando herramientas...</label>
              </div>
              <div v-else>
                <div v-if="![undefined, null, []].includes(herramientas)">
                  <v-data-table
                    :headers="headers"
                    :items="herramientas"
                    dense
                    style="background-color:transparent"
                    hide-default-footer
                  >
                    <template v-slot:item.FILE_TITLE="{ item }">
                      <v-btn
                        small
                        dark
                        depressed
                        color="cyan darken-1"
                        class="rounded-pill"
                        @click="openFile(item.ID, 'herramientas', item.FILE_TITLE)"
                      >
                        <v-icon left>mdi-image-area</v-icon>
                        abrir
                      </v-btn>
                    </template>
                  </v-data-table>
                </div>
                <div v-else class="text-center">
                  <label class="overline">No se registraron herramientas</label>
                </div>
              </div>
            </v-card>

            <v-card
              class="px-10 py-3 my-2 elevation-0 rounded-lg"
              color="blue-grey lighten-5"
            >
              <div class="center-item-in-div-column" v-if="loadingVehiculos">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="6"
                  style="max-width: 350px;"
                ></v-progress-linear>
                <label class="overline">Cargando datos del vehículo...</label>
              </div>
              <div v-else>
                <div v-if="![undefined, null, []].includes(vehiculos)">
                  <!-- {{ vehiculos }} -->
                  <v-data-table
                    :headers="headersVehiculos"
                    :items="vehiculos"
                    dense
                    style="background-color:transparent"
                    hide-default-footer
                  >
                    <template v-slot:item.FILE_TITLE="{ item }">
                      <v-btn
                        small
                        dark
                        depressed
                        color="cyan darken-1"
                        class="rounded-pill"
                        @click="openFile(item.ID, 'vehiculo', item.FILE_TITLE)"
                      >
                        <v-icon left>mdi-image-area</v-icon>
                        abrir
                      </v-btn>
                    </template>
                  </v-data-table>
                </div>
                <div v-else class="text-center">
                  <label class="overline">No se registraró vehículo</label>
                </div>
              </div>
            </v-card>
          </v-sheet>
        </v-card-text>
        <v-card-actions v-if="muestraMaterial" class="center-item-in-div">
          <v-btn
            x-large
            color="teal darken-1"
            class="rounded-lg"
            dark
            :loading="loadingMarcaSalida"
            @click="
              loadingMarcaSalida = true;
              setSalida();
            "
          >
            <v-icon left> mdi-check </v-icon>
            Registrar salida
          </v-btn>
        </v-card-actions>

        <v-dialog v-model="verImagen" persistent max-width="500" content-class="fix-border-dialog">
          <v-card class="rounded-xl">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-icon @click="verImagen = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="pt-5">
              <v-img :src="imageSelected" max-width="100%" class="rounded-lg"></v-img>
            </v-card-text>
          </v-card>
        </v-dialog>

      </v-card>
    </v-dialog>



    <SnackBarError
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";

import SnackBarError from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "genera-salida-visitante",
  props: ["marcaSalida", "datosVisita"],
  components: { SnackBarError },
  data() {
    return {
      loadingVisitantes: true,
      loadingMarcaSalida: false,
      loadingArticulosPersonales: true,
      loadingConsumibles: true,
      loadingHerramientas: true,
      loadingVehiculos: true,
      muestraBarra: false,
      muestraMaterial: false,
      colorBarra: "",
      textoBarra: "",
      imageSelected: null,
      verImagen: false,
      idVisitanteAux: 0,
      visitantes: [],
      articulosPersonales: [],
      consumibles: [],
      herramientas: [],
      vehiculos: [],
      headers: [
        { value: "ID", text: "ID" },
        { value: "NOMBRE", text: "NOMBRE" },
        { value: "MARCA", text: "MARCA" },
        { value: "NO_SERIE", text: "NO. SERIE" },
        { value: "CANTIDAD", text: "CANTIDAD" },
        { value: "DESCRIPCION", text: "DESCRIPCION" },
        { value: "FILE_TITLE", text: "EVIDENCIA" },
      ],
      headersVehiculos:[
        {value: "ID", text: "ID"},
        {value: "TIPO_LICENCIA", text: "TIPO  DE LICENCIA"},
        {value: "NUMERO_LICENCIA", text: "# LICENCIA"},
        {value: "TIPO_VEHICULO", text: "VEHÍCULO"},
        {value: "PLACAS", text: "PLACAS"},
        {value: "NUMERO_ECONOMICO", text: "# ECONÓMICO"},
        { value: "FILE_TITLE", text: "EVIDENCIA" },
      ],
    };
  },
  computed: {
    widthCard() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "50%";
        case "md":
          return "33%";
        case "lg":
          return "25%";
        case "xl":
          return "25%";
        default:
          return "100%";
      }
    },
  },
  async mounted() {
    this.visitantes = await this.getDatosVisitantesPorVisita().catch((e) => {
      alert(e);
    });
  },
  methods: {
    muestraSnackBar(color, texto) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    async getDatosVisitantesPorVisita() {
      const params = {
        Opcion: 14,
        token: localStorage.getItem("token"),
        idVisita: this.datosVisita.id,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.visitantes);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingVisitantes = false;
          });
      });
    },
    getColorCard(estatus) {
      switch (estatus) {
        case 0:
          return "pink lighten-5";
        case 1:
          return "blue lighten-5";
        case 2:
          return "teal lighten-5";
        default:
          return "blue-grey";
      }
    },
    async openFile(idMaterial, tipo, file) {
      this.imageSelected = null;
      let params = {
        Opcion: 20,
        token: localStorage.getItem("token"),
        idVisitante: this.idVisitanteAux,
        filename: idMaterial + "_" + file,
        tipo: tipo,
      };

      try {
        const response = await axios.post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        );

        if (response.data.status === "OK") {
          this.imageSelected = response.data.file;
          this.verImagen = true;
        } else {
          alert(
            "Parece que el archivo está dañado o no existe. Se recomienda reemplazar."
          );
        }
      } catch (error) {
        console.error("Ocurrió un error:", error);
        alert("Ocurrió un error al descargar el archivo.");
      }
    },
    setSalida() {
      const params = {
        Opcion: 33,
        token: localStorage.getItem("token"),
        idVisita: this.datosVisita.id,
        idVisitante: this.idVisitanteAux,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status === "OK") {
            if (response.data.respuesta.status_tran == "OK") {
              this.muestraSnackBar(
                "teal darken-1",
                response.data.respuesta.message
              );
              this.loadingVisitantes = true;
              this.visitantes.length = 0;
              this.visitantes = await this.getDatosVisitantesPorVisita().catch(
                (e) => {
                  alert(e);
                }
              );
            } else {
              this.muestraSnackBar(
                "pink darken-3",
                response.data.respuesta.message
              );
            }
          } else {
            this.muestraSnackBar(
              "pink darken-3",
              "No se pudo registrar el item. " + this.articuloName
            );
          }
        })
        .catch((e) => {
          this.muestraSnackBar("pink darken-3", e);
        })
        .finally(() => {
          this.loadingMarcaSalida = false;
        });
    },
    async muestraMaterialRegistrado(visitante) {
      this.idVisitanteAux = visitante.int
      this.articulosPersonales = await this.getArticulosPersonales(
        visitante.int
      ).catch((e) => {
        alert(e);
      });
      this.consumibles = await this.getConsumibles(visitante.int).catch((e) => {
        alert(e);
      });
      this.herramientas = await this.getHerramientas(visitante.int).catch(
        (e) => {
          alert(e);
        }
      );
      this.vehiculos = await this.getVehiculos(visitante.int).catch((e) => {
        alert(e);
      });
    },
    async getArticulosPersonales(id) {
      const params = {
        Opcion: 15,
        token: localStorage.getItem("token"),
        idVisitante: id,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.materiales);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingArticulosPersonales = false;
          });
      });
    },
    async getConsumibles(id) {
      const params = {
        Opcion: 16,
        token: localStorage.getItem("token"),
        idVisitante: id,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.materiales);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingConsumibles = false;
          });
      });
    },
    async getHerramientas(id) {
      const params = {
        Opcion: 17,
        token: localStorage.getItem("token"),
        idVisitante: id,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.materiales);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingHerramientas = false;
          });
      });
    },
    async getVehiculos(id) {
      const params = {
        Opcion: 18,
        token: localStorage.getItem("token"),
        idVisitante: id,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.materiales);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingVehiculos = false;
          });
      });
    },
  },
};
</script>
