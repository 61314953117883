<template>
    <v-dialog v-model="verCrearMaterial" persistent max-width="90%" scrollable>
        <v-card class="rounded-lg">
            <v-card-title class="primary">
                <div class="overline font-weight-bold white--text">
                    crear material
                </div>
                <v-spacer></v-spacer>
                <v-icon dark @click="$emit('cierraCrearMaterial', cambios)">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="pt-5 blue-grey lighten-5">
                <v-sheet class="rounded-0 elevation-1" min-height="400" width="100%">
                    <div class="pa-5">
                        <v-img
                            :src="require('@/assets/img/grupo_urrea_azul_gris.png')"
                            max-height="70"
                            width="250"
                        ></v-img>
                    </div>
                    <v-form ref="formCrearMaterial">
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        v-model="nombreMaterial"
                                        label="Material"
                                        class="rounded-lg"
                                        dense
                                        outlined
                                        :rules="[v => !!v || 'Este campo es obligatorio']"
                                        style="font-weight: bold;"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" v-for="(item, index) in itemsMaterial" :key="index">
                                    <v-text-field
                                        v-model="itemsMaterial[index]"
                                        :label="'Accesorio ' + (index + 1)"
                                        :rules="[v => !!v || 'Este campo es obligatorio']"
                                        class="rounded-lg"
                                        dense
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                        <div class="text-right px-6 mb-6">
                            <v-btn
                                v-show="itemsMaterial.length > 1"
                                fab
                                x-small
                                class="mx-1 elevation-1"
                                dark
                                color="pink darken-3"
                                @click="itemsMaterial.pop()"
                            >
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>
                            <v-btn
                                v-show="itemsMaterial.length < 10"
                                fab
                                x-small
                                class="mx-1 elevation-1"
                                dark
                                color="teal darken-1"
                                @click="addItemsMaterial"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </div>
                        <v-row class="px-5"></v-row>
                    </v-form>
                </v-sheet>
            </v-card-text>
            <v-card-actions class="center-item-in-div">
                <v-btn
                    class="rounded-lg"
                    x-large
                    color="teal darken-1"
                    :loading="loadingBtnSavePasse"
                    dark
                    @click="loadingBtnSavePasse = true; guardar();"
                >
                    <v-icon left>mdi-text-box-plus</v-icon>
                    guardar material y accesorios
                </v-btn>
            </v-card-actions>
            <SnackBarErrorIncidencias
                v-if="muestraBarra"
                :muestraBarra="muestraBarra"
                :color="colorBarra"
                :texto="textoBarra"
                @cierraBarra="muestraBarra = false"
            />
        </v-card>
        <v-overlay
            v-model="dialogCargando"
            contained
            class="align-center justify-center"
            width="auto"
        >
            <v-card title="Cargando...">
                <v-card-text>
                    <v-label><v-progress-circular indeterminate></v-progress-circular></v-label>
                </v-card-text>
            </v-card>
        </v-overlay>
    </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones.js";
import SnackBarErrorIncidencias from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
    name: "material-items-alta",
    props: ["verCrearMaterial", "idMaterial"],
    components: { SnackBarErrorIncidencias },
    data() {
        return {
            cambios: 0,
            nombreMaterial: "",
            itemsMaterial: [""],
            dialogCargando: false,
            loadingBtnSavePasse: false,
            muestraBarra: false,
        };
    }, // data

    async mounted() {
        this.nombreMaterial = "";
        this.itemsMaterial  = [""];
        if (this.idMaterial > 0) { this.editarItems(); }
    },

    methods: {
        editarItems() {
            const headers = {
                Authorization: localStorage.getItem("token")
            };
            console.log(localStorage);
            const params = {
                usuarioMovimiento:  localStorage.getItem("folio"),
                IdMaterial: this.idMaterial
            };
            return new Promise((res) => {
                axios
                .get(`${this.$store.getters.getHostNode}/api/read-material-items`,
                {
                    headers: headers,
                    params
                })
                .then((response) => {
                    console.log(response);
                    if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else if (response.data.status == "OK") {
                        res(response.data.itemsMaterial);
                        this.nombreMaterial = response.data.nombreMaterial;
                        this.itemsMaterial  = response.data.itemsMaterial.map(item => item.NombreItem);
                    } else {
                        res([]);
                    }
                })
                .finally(() => {
                    this.loadingMateriales = false;
                });
            });
        }, // editarItems

        addItemsMaterial() {
            this.itemsMaterial.push("");
        }, // addItemsMaterial

        guardar(){
            if (this.$refs.formCrearMaterial.validate()) {
                const headers = {
                    Authorization: localStorage.getItem("token"),
                };
                console.log(localStorage);
                const params = {
                    usuarioRegistro:  localStorage.getItem("folio"),
                    nombreMaterial: this.nombreMaterial,
                    itemsMaterial: this.itemsMaterial,
                    IdMaterial: this.idMaterial
                };
                if (this.idMaterial > 0) {
                    console.log("if (this.idMaterial > 0) {", params);
                    return new Promise((res) => {
                        axios.post(
                            `${this.$store.getters.getHostNode}/api/update-material-items`,
                            params,
                            {
                                headers: headers,
                            }
                        ).then((response) => {
                            if (response.data.status == true) {
                                res(response.data);
                                this.textoBarra = "Registro exitoso";
                                this.colorBarra = "success";
                                this.muestraBarra = true;
                            } else if (response.data.status == false) {
                                res(response.data);
                                this.textoBarra = response.data.message;
                                this.colorBarra = "error";
                                this.muestraBarra = true;
                            } else if (response.data.status == "EXPRESSION") {
                                deleteToken();
                            }
                        }).finally(() => {
                            return new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve(this.$emit('cierraCrearMaterial', "OK"));
                                }, 2500);
                            }); // return-Promise
                        }); // finally
                    }); // return
                } else {
                    console.log("else (this.idMaterial > 0) {", params);
                    return new Promise((res) => {
                        axios.post(
                            `${this.$store.getters.getHostNode}/api/create-material-items`,
                            params,
                            {
                                headers: headers,
                            }
                        ).then((response) => {
                            if (response.data.status == true) {
                                res(response.data);
                                this.textoBarra = "Registro exitoso";
                                this.colorBarra = "success";
                                this.muestraBarra = true;
                            } else if (response.data.status == false) {
                                res(response.data);
                                this.textoBarra = response.data.message;
                                this.colorBarra = "error";
                                this.muestraBarra = true;
                            } else if (response.data.status == "EXPRESSION") {
                                deleteToken();
                            }
                        }).finally(() => {
                            return new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve(this.$emit('cierraCrearMaterial', "OK"));
                                }, 2500);
                            }); // return-Promise
                        }); // finally
                    }); // return
                }
            } else {
                console.log("campos requeridos");
            }
            this.loadingBtnSavePasse = false;
        }, // guardar

    }, // methods
  }; // data
</script>

<style scoped>
.autocomplete {
    position: relative;
}

.autocomplete-list {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    z-index: 1;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
}

.autocomplete-list li {
    padding: 8px;
    cursor: pointer;
}

.autocomplete-list li.highlighted {
    background-color: #f2f2f2;
}
</style>