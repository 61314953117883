<template>
    <v-dialog v-model="dEncuesta" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
        <v-card tile>
            <v-toolbar flat dark color="primary">
                <img class="mr-6" style="width: 50px; height: 50px;" src="@/assets/icons/chat_urrea.png"
                    alt="Chat Urrea">
                <v-toolbar-title>¡Hola! <br> Contesta la siguiente encuesta de <b>{{ Nombreencuesta
                        }}</b></v-toolbar-title>
            </v-toolbar>

            <v-card-text>
                <div  class="py-3">
                    <label class="my-3 subtitle-1">{{ Descripcion }}</label>
                </div>
                <div>
                    <v-row>
                        <v-col v-for="pregunta in datospregunta" :key="pregunta.ID" cols="12">
                            <v-card>
                                <v-card-title>{{ pregunta.NUM_PREGUNTA }}.- {{ pregunta.PREGUNTA }}</v-card-title>
                                <v-card-text>
                                    <template v-if="pregunta.TIPO_PREGUNTA === 'Cerrada'">
                                        <v-radio-group v-model="respuestas[pregunta.ID].respuesta">
                                            <v-radio
                                                v-for="opcion in datosopciones.filter(opcion => opcion.ID_PREGUNTA === pregunta.NUM_PREGUNTA)"
                                                :key="opcion.ID" :label="opcion.OPCION" :value="opcion.ID"
                                                @change="autoGuardar(pregunta.ID)" />
                                        </v-radio-group>
                                    </template>

                                    <template v-else-if="pregunta.TIPO_PREGUNTA === 'Opción Múltiple'">
                                        <v-checkbox
                                            v-for="opcion in datosopciones.filter(opcion => opcion.ID_PREGUNTA === pregunta.NUM_PREGUNTA)"
                                            :key="opcion.ID" :label="opcion.OPCION" :value="opcion.ID"
                                            v-model="respuestas[pregunta.ID].respuesta"
                                            @change="autoGuardar(pregunta.ID)" />
                                    </template>

                                    <template v-else>
                                        <v-textarea outlined label="Respuesta" counter auto-grow maxlength="250"
                                            v-model="respuestas[pregunta.ID].respuesta"
                                            @change="autoGuardar(pregunta.ID)" />
                                    </template>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>

            <div class="pa-6">
                <v-btn class="rounded-lg font-weight-bold" dark color="primary" @click="saveEncuesta">
                    <v-icon dark left>mdi-content-save-all-outline</v-icon> Guardar
                </v-btn>
                <v-snackbar v-model="muestraBarra" :timeout="timeout" rounded="lg" :color="colorBarra">
                    <div class="caption font-weight-bold mb-2">{{ textoBarra }}</div>
                </v-snackbar>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";

export default {
    name: "persona-Encuesta-Asignada",
    data() {
        return {
            respuestas: {},
            encuestas: [],
            datospregunta: [],
            datosopciones: [],
            Nombreencuesta: "",
            Descripcion: "",
            IdEncuesta: 0,
            dEncuesta: false,
            rules: [v => v.length <= 249 || 'Máximo 250 caracteres'],
            textoBarra: '',
            colorBarra: '',
            muestraBarra: false,
            timeout: 5000,
        };
    },
    mounted() {
        this.getEncuestas();
    },
    methods: {
        getEncuestas() {
            axios.get(`${this.$store.getters.getHostNode}/api/getDatosEncuestas`, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            }).then((response) => {
                if (response.data.status) {

                    if (!response.data.data || response.data.data.length === 0) {
                        console.warn("No hay  encuestas.");
                        return;
                    }

                    this.encuestas = response.data.data;
                    this.Nombreencuesta = this.encuestas[0].NOMBRE;
                    this.Descripcion = this.encuestas[0].DESCRIPCION;
                    this.IdEncuesta = this.encuestas[0].ID;
                    this.getEncuestasDatos();
                    this.getEstadoUser();
                }
            }).catch((e) => {
                if (e.toString().includes("401")) {
                    window.location.reload();
                } else {
                    alert(e);
                }
            });
        },
        getEstadoUser() {
            axios.get(`${this.$store.getters.getHostNode}/api/getEstadoUser/${this.$store.getters.getUser.Folio}/${this.IdEncuesta}`, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            }).then((response) => {
                if (!response.data.data[0]) {

                    return;
                }

                if (response.data.data[0].TodasPreguntasRespondidas === 1) {

                    this.dEncuesta = false;
                } else {
                    this.dEncuesta = true;
                    this.cargarRespuestas();
                }
            }).catch((e) => {
                if (e.toString().includes("401")) {
                    window.location.reload();
                } else {
                    alert(e);
                }
            });
        },
        getEncuestasDatos() {
            axios.get(`${this.$store.getters.getHostNode}/api/getPreguntasEncuesta/${this.IdEncuesta}`, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            }).then((response) => {
                if (response.data.status) {
                    this.datospregunta = response.data.data;
                    this.datospregunta.forEach(pregunta => {
                        this.$set(this.respuestas, pregunta.ID, {
                            respuesta: pregunta.TIPO_PREGUNTA === 'Opción Múltiple' ? [] : ''
                        });
                    });

                    axios.get(`${this.$store.getters.getHostNode}/api/getOpcionesPreguntas/${this.IdEncuesta}`, {
                        headers: {
                            Authorization: localStorage.getItem("token")
                        }
                    }).then((response) => {
                        if (response.data.status) {
                            this.datosopciones = response.data.data;
                        }
                    }).catch((e) => {
                        if (e.toString().includes("401")) {
                            window.location.reload();
                        } else {
                            alert(e);
                        }
                    });
                }
            }).catch((e) => {
                if (e.toString().includes("401")) {
                    window.location.reload();
                } else {
                    alert(e);
                }
            });
        },
        async cargarRespuestas() {
            const folio = this.$store.getters.getUser.Folio;
            axios.get(`${this.$store.getters.getHostNode}/api/obtenerRespuestasPersona/${this.IdEncuesta}/${folio}`, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            }).then((response) => {
                if (response.data.status) {
                    const respuestasCargadas = response.data.data;

                    // Filtrar preguntas no respondidas
                    this.datospregunta = this.datospregunta.filter(pregunta => {
                        const respuestaData = respuestasCargadas[pregunta.ID];
                        if (!respuestaData) {
                            // Pregunta no respondida
                            this.$set(this.respuestas, pregunta.ID, {
                                tipoPregunta: pregunta.TIPO_PREGUNTA,
                                respuesta: pregunta.TIPO_PREGUNTA === 'Opción Múltiple' ? [] : ''
                            });
                            return true;
                        }
                        return false; // Filtra preguntas respondidas
                    });
                }
            }).catch((e) => {
                if (e.toString().includes("401")) {
                    window.location.reload();
                } else {
                    alert(e);
                }
            });
        },

        saveEncuesta() {
            this.dEncuesta = false;
        },
        autoGuardar(idPregunta) {
            const folioUsuario = this.$store.getters.getUser.Folio;
            const respuesta = this.respuestas[idPregunta];

            const data = {
                id: this.IdEncuesta,
                folioUsuario: folioUsuario,
                preguntaId: idPregunta,
                respuesta: respuesta
            };

            axios.post(`${this.$store.getters.getHostNode}/api/guardarRespuestaParcial`, data, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            }).then(response => {
                if (!response.data.status) {
                    this.textoBarra = 'Error al guardar la respuesta automáticamente';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                }
            }).catch(error => {
                console.error('Error al guardar respuesta:', error);
            });
        }
    }
};
</script>
