<template>


  <!-- <div class="rounded-xl pa-4 elevation-15" min-height="250"> -->
    <div>
   
    <div v-show="loadingTable" class="center-item-in-div-column">
      <v-progress-circular :size="150" :width="15" color="primary" indeterminate></v-progress-circular>
      <label class="overline font-weight-bold">Cargando información...</label>
    </div>

    <div class="centrado">
      <v-btn text @click="toggleTabla">
        <v-icon v-if="mostrarTabla">mdi-close-box-multiple-outline</v-icon>
        <v-icon v-else> mdi-open-in-app</v-icon>
        <b>Objetivos Propios:</b>

      </v-btn>
    </div>
    <p></p>


    <div class="rounded-lg pb-4 elevation-0" v-show="!loadingTable">
      <v-simple-table id="miTabla" v-if="mostrarTabla" dense style="background-color: transparent;">
        <thead>
          <tr>
            <th class="primary rounded-simple-table-head-l" colspan="7"></th>
            <th class="primary white--text text-uppercase font-weight-bold text-center" colspan="4">
              Valor real &uacute;ltimo trimestre
            </th>
            <th class="primary rounded-simple-table-head-r" colspan="2"></th>
          </tr>
          <tr>
            <th class="primary white--text text-uppercase font-weight-bold text-center"
                            v-for="item in formattedTablaObjetivos" :key="item.id">
                            {{ item.text }}
                        </th>
            <!-- <th class="primary white--text text-uppercase font-weight-bold text-center" v-for="item in tablaObjetivos"
              :key="item.id">{{ item.text }}</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in objetivos" :key="index">
            <td class="text-center">{{ item.Id_Indicador }}</td>
            <td class="text-center">{{ item.DescObjetivo }}</td>
            <td class="text-center">{{ item.VO }}</td>
            <td class="text-center">{{ item.LE }}</td>
            <td class="text-center">{{ item.Ponderacion }}</td>
            <td class="text-center">{{ item.Tipo }}</td>
            <td class="text-center">{{ item.TipoCalculo }}</td>
            <td class="text-center">{{ item.ValorPrimer }}</td>
            <td class="text-center">{{ item.ValorSegundo }}</td>
            <td class="text-center">{{ item.ValorTercero }}</td>
            <td class="text-center">
              <v-chip small :class="getColorClass(
                getResultado(
                  2,
                  item.TipoCalculo,
                  item.ValorPrimer,
                  item.ValorSegundo,
                  item.ValorTercero,
                  item.VO,
                  item.LE
                )
              )
                ">
                {{
                  getResultado(
                    1,
                    item.TipoCalculo,
                    item.ValorPrimer,
                    item.ValorSegundo,
                    item.ValorTercero,
                    item.VO,
                    item.LE
                  )
                }}
              </v-chip>
            </td>
            <td class="text-center">
              {{
                getResultado(
                  2,
                  item.TipoCalculo,
                  item.ValorPrimer,
                  item.ValorSegundo,
                  item.ValorTercero,
                  item.VO,
                  item.LE
                )
              }}
            </td>
            <td class="text-center">
              {{
                getPuntos(
                  item.TipoCalculo,
                  item.ValorPrimer,
                  item.ValorSegundo,
                  item.ValorTercero,
                  item.VO,
                  item.LE,
                  item.Ponderacion
                )
              }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div v-show="sumatoria > 0" class="text-right pt-4">
        <label class="font-weight-bold mr-3">
          Calificación del trimestre: {{ sumatoria.toFixed(2) }}</label>
      </div>
    </div>


  </div>



</template>

<script>
import axios from "axios";


export default {
  components: {  },
  name: "ver-objetivos",
  props:[
    "Mes1","Mes2","Mes3","iMes","numEmpleado","trimestre","anio","anioTabla"
],
  data() {
    return {
      objetivos: [],
      sumatoria: 0,
      loadingTable: true,
      mostrarTabla: false, // Estado inicial: tabla oculta
      //--------------
    
      tablaObjetivos: [
        { id: 1, text: 'ID' },
        { id: 2, text: 'Objetivo Específico' },
        { id: 3, text: 'VO' },
        { id: 4, text: 'LE' },
        { id: 5, text: 'Ponderación' },
        { id: 6, text: 'Tipo' },
        { id: 7, text: 'Tipo de Cálculo' },
        { id: 8, text: 'Mes 1' },
        { id: 9, text: 'Mes 2' },
        { id: 10, text: 'Mes 3' },
        { id: 11, text: 'Resultado' },
        { id: 12, text: 'Desempeño' },
        { id: 13, text: 'Puntos' }
      ]
    };
  },
  async mounted() {
    // this.objetivos = await this.getObjectives();
    // this.calculateSumatoria();
    
  },
  computed: {
    formattedTablaObjetivos() {
    return this.tablaObjetivos.map(item => {
      if (item.id === 8) item.text = ` ${this.Mes1}`;
      if (item.id === 9) item.text = ` ${this.Mes2}`;
      if (item.id === 10) item.text = ` ${this.Mes3}`;
      return item;
    });
  }
  },

  methods: {
    toggleTabla() {
      this.mostrarTabla = !this.mostrarTabla; // Alternar el estado de mostrarTabla
    },

    

    getObjectives() {
      return new Promise((resolve) => {

        // .get("http://localhost:3080/api/ver-objetivos/2518/1/2024")
        axios.get(`${this.$store.getters.getHostNode}/api/ver-objetivos/${this.numEmpleado}/${this.trimestre}/${this.anioTabla}`)

          .then((response) => {
            resolve(response.data.data);
            // console.log('Data de Objetivos Propios!!11:', response.data.data);
          })
          .finally(() => {
            this.loadingTable = false
          });
      });
    },
    getResultado(
      opc,
      tipoCalculo,
      valorPrimer,
      valorSegundo,
      valorTercero,
      valorObjetivo,
      limiteEspecifico
    ) {
      let real = 0;
      if (["Promedio", "", null].includes(tipoCalculo)) {
        real = (valorPrimer + valorSegundo + valorTercero) / 3;
      } else {
        if (valorPrimer != 0) real = valorPrimer;
        else if (valorSegundo != 0) real = valorSegundo;
        else if (valorTercero != 0) real = valorTercero;
      }
      if (opc === 1) {
        return real.toFixed(2);
      }

      const desempenio =
        ((limiteEspecifico - real) / (limiteEspecifico - valorObjetivo)) * 100;
      if (opc === 2) {
        return desempenio > 100
          ? 100
          : desempenio > 100 < 0
            ? 0
            : desempenio.toFixed(2);
      }
      return 0;
    },
    getPuntos(
      tipoCalculo,
      valorPrimer,
      valorSegundo,
      valorTercero,
      valorObjetivo,
      limiteEspecifico,
      ponderacion
    ) {
      let real = 0;
      if (["Promedio", "", null].includes(tipoCalculo)) {
        real = (valorPrimer + valorSegundo + valorTercero) / 3;
      } else {
        if (valorPrimer != 0) real = valorPrimer;
        else if (valorSegundo != 0) real = valorSegundo;
        else if (valorTercero != 0) real = valorTercero;
      }
      let desempenio =
        ((limiteEspecifico - real) / (limiteEspecifico - valorObjetivo)) * 100;
      desempenio = desempenio > 100 ? 100 : desempenio < 0 ? 0 : desempenio;
      let puntos = (desempenio * ponderacion) / 100;

      return puntos.toFixed(2);
    },
    calculateSumatoria() {
      this.sumatoria = 0;
      for (const item of this.objetivos) {
        this.sumatoria += parseFloat(
          this.getPuntos(
            item.TipoCalculo,
            item.ValorPrimer,
            item.ValorSegundo,
            item.ValorTercero,
            item.VO,
            item.LE,
            item.Ponderacion
          )
        );
      }
    },
    getColorClass(puntos) {
      return puntos === 100
        ? "teal darken-1 white--text"
        : puntos === 0
          ? "pink darken-3 white--text"
          : "amber black--text";
    },
  },
};
//Mensaje
</script>

<style>
.rounded-simple-table-head-l {
  border-top-left-radius: 22px !important;
}

.rounded-simple-table-head-r {
  border-top-right-radius: 22px !important;
}

.back-card-objectives {
  background: rgb(255, 255, 255) !important;
  background: linear-gradient(301deg,
      rgba(255, 255, 255, 1) 15%,
      rgba(201, 78, 148, 0.7344071417629552) 45%,
      rgba(255, 255, 255, 1) 100%) !important;
}

.centrado {
  text-align: center;
  /* Alinea el texto al centro */
  font-size: 18px;

}
</style>
