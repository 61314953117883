<template>
  <v-card elevation="3" :loading = "loadingCardExamen">
    <v-card-title style="color: rgba(0, 120, 180, 1)">
      <strong> Examen técnico </strong>
    </v-card-title>
    <v-card-text>
      <v-btn v-if="tieneExamen" color="teal" dark>
        Ver examen en universidad urrea
      </v-btn>
      <p v-else-if="!tieneExamen">Sin registros encotrados.</p>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn elevation="2" dark color="teal" v-show="!estPuesto">
        Asignar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'
import axios from "axios";

export default {
  name: "puesto-escolaridad",
  components: {},
  props: ["idPuesto", "descPuesto", "nivelPuesto", "estPuesto"],
  // props: {
  //   idPuesto: {
  //     type: String,
  //   },
  //   descPuesto: {
  //     type: String,
  //   },
  //   nivelPuesto: {
  //     type: Number,
  //   },
  //   estPuesto: {
  //     type: Number,
  //   },
  // },

  data: function () {
    return {
      idExamen: 0,
      tieneExamen: false,
      loadingCardExamen: true
    };
  },
  mounted() {
    // //("hijo mounted " + this.idPuesto);
    this.getExamen(this.idPuesto);
  },
  created() {
    // //("hijo created " + this.idPuesto);
  },
  methods: {
    getExamen(id) {
      var params = {
        jobcode: id,
      };
      //(params);
      axios
        .post(`${this.$store.getters.getHost}/api/getExamenId`, params)
        .then((response) => {
          this.loadingCardExamen = false;
          if (Number.isInteger(response.data)) {
            this.tieneExamen = true;
            this.idExamen = response.data;
          } else {
            this.tieneExamen = false;
          }
        });
    },
  },
};
</script>
