<template>
  <v-dialog v-model="verDataTable" max-width="1000px" persistent scrollable>
    <v-card class="rounded-lg">
      <v-card-title class="primary">
        <span class="text-h6 white--text text-uppercase">{{ titulo }}</span>
        <v-spacer></v-spacer>
          <v-icon dark @click="$emit('cierraTabla')">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-0 pb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar..."
            outlined
            dense
            class="rounded-lg mt-3 mx-5"
          ></v-text-field>
          <v-data-table
            dense
            :headers="headers"
            :items="items"
            :items-per-page="itemsPerPage"
            :search="search"
            :footer-props="{
              'items-per-page-text': 'Elementos por página',
              'pageText': '{0}-{1} de {2}'
            }"
            no-data-text="No hay registros"
            class="elevation-0"
          ></v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "data-table-generic",
  data: () => ({
    itemsPerPage: 10,
    search: "",
  }),
  props: ["verDataTable", "items", "headers", "titulo"],
  mounted() {
    // console.log("hola");
  },
  methods: {},
};
</script>
