<template>
  <div>
    <v-row v-if="numerosVisitasRegistradas.length > 0">
      <v-col
        :cols="12 / numerosVisitasRegistradas.length"
        v-for="(item, index) in numerosVisitasRegistradas"
        :key="index"
      >
        <v-card
          class="rounded-xl elevation-0"
          color="blue-grey lighten-4"
          style="width: 100%; min-height: 120px;"
        >
          <v-card-title>
            <v-icon large left>
              {{ item.icon }}
            </v-icon>
          </v-card-title >
          <v-card-subtitle class="subtitle-1 font-weight-bold text-uppercase blue-grey--text text--darken-3">
            visitas {{ item.descVisita }}
          </v-card-subtitle>
          <v-card-text class="center-item-in-div">
            <div class="text-h4 font-weight-bold">
              {{ formatearNumeroConComas(item.total) }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div style="display: flex; justify-content: space-between;">
      <div>
        <v-btn
          class="rounded-xl mt-2 mr-1"
          depressed
          large
          :color="pestania == 1 ? 'primary' : 'white'"
          @click="
            pestania = 1;
            updateTable();
          "
        >
          visitas del día
        </v-btn>
        <v-btn
          class="rounded-xl mt-2 mr-1"
          depressed
          large
          :color="pestania == 2 ? 'primary' : 'white'"
          @click="
            pestania = 2;
            updateTable();
          "
        >
          visitas pendientes
        </v-btn>
        <v-btn
          class="rounded-xl mt-2 mr-1"
          depressed
          large
          :color="pestania == 3 ? 'primary' : 'white'"
          @click="
            pestania = 3;
            updateTable();
          "
        >
          visitas rechazadas
        </v-btn>
        <v-btn
          class="rounded-xl mt-2 mr-1"
          depressed
          large
          :color="pestania == 4 ? 'primary' : 'white'"
          @click="
            pestania = 4;
            updateTable();
          "
        >
          visitas finalizadas
        </v-btn>
      </div>
      <v-btn
          class="rounded-xl mt-2 mr-1"
          depressed
          large
          color="new_primary"
          dark
          @click="muestraGeneraVisita = true;"
        >
          <v-icon left>mdi-receipt-text-plus-outline</v-icon>
          genera visita
        </v-btn>
    </div>
    <v-card class="rounded-xl mt-2 pa-2 elevation-0">
      <div style="display:flex; justify-content: space-between;">
        <div
          class="text-h6 ml-2 text-uppercase blue-grey--text text--darken-1 font-weight-black"
        >
          {{
            pestania === 1
              ? "visitas del día"
              : pestania === 2
              ? "visitas pendientes"
              : pestania === 3
              ? "visitas rechazadas"
              : "visitas finalizadas"
          }}
        </div>
        <div
          style="display: flex; justify-content: space-between; align-items: top;"
        >
          <v-text-field
            v-model="search"
            class="rounded-pill"
            dense
            outlined
            prepend-inner-icon="mdi-magnify"
            placeholder="Búsqueda general..."
          ></v-text-field>
          <v-btn
            fab
            outlined
            class="rounded-pill ml-1"
            small
            depressed
            @click="updateTable()"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
      </div>
      <v-data-table
        dense
        :headers="headers"
        :items="visitas"
        :search="search"
        :loading="loadingVisitas"
        :single-expand="true"
        :expanded.sync="expanded"
        :fixed-header="true"
        :fixed-layout="true"
        no-data-text="No se encontraron visitas registradas."
        :no-results-text="'No se encontraron resultados para: ' + search"
        loading-text="Cargando información..."
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
          pageText: '{0}-{1} de {2}',
        }"
        item-key="id"
        show-expand
      >
        <template v-slot:top> </template>
        <template v-slot:item.Nombre="{ item }">
          {{ item.Paterno + " " + item.Materno + " " + item.Nombre }}
        </template>
        <template v-slot:item.Dia="{ item }">
          {{ fixFormatDate(item.Dia.date.substring(0, 10)) }}
        </template>
        <template v-slot:item.DiaFin="{ item }">
          {{
            item.DiaFin != null
              ? fixFormatDate(item.DiaFin.date.substring(0, 10))
              : ""
          }}
        </template>
        <template v-slot:item.Entrada="{ item }">
          {{ item.Entrada.date.substring(11, 16) }}
        </template>
        <template v-slot:item.Salida="{ item }">
          {{ item.Salida.date.substring(11, 16) }}
        </template>
        <template v-slot:item.grupo="{ item }">
          <v-chip x-small dark :color="item.grupo === 1 ? 'cyan' : 'green'">
            {{ item.grupo === 1 ? "Individual" : "Grupal" }}
          </v-chip>
        </template>
        <template v-slot:item.estatus="{ item }">
          <v-chip
            v-if="item.estatus === 0"
            x-small
            dark
            color="blue-grey darken-1"
          >
            Pendiente de autorización
          </v-chip>
          <v-chip
            v-else-if="item.estatus === 1"
            x-small
            dark
            color="teal darken-1"
          >
            Autorizada
          </v-chip>
          <v-chip
            v-else-if="item.estatus === 2"
            x-small
            dark
            color="red darken-3"
          >
            Rechazada
          </v-chip>
          <v-chip
            v-else-if="item.estatus === 3"
            x-small
            dark
            color="pink darken-3"
          >
            Salida pendiente
          </v-chip>
          <v-chip
            v-else-if="item.estatus === 4"
            x-small
            dark
            color="blue-grey darken-4"
          >
            Visita concluida
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="center-item-in-div-column">
            <v-btn
              x-small
              class="rounded-pill mx-1 mt-1"
              depressed
              dark
              v-show="item.estatus === 3 && item.visitantesPendientes > 0"
              :color="getColor(1)"
              @click="openAction(item, 1)"
            >
              <v-icon left small>{{ getIconBtn(1) }}</v-icon>
              <div class="overline">{{ getTitleBtn(1) }}</div>
            </v-btn>
            <v-btn
              x-small
              class="rounded-pill my-1"
              depressed
              dark
              :color="getColor(item.estatus)"
              @click="openAction(item, item.estatus)"
            >
              <v-icon left small>{{ getIconBtn(item.estatus) }}</v-icon>
              <div class="overline">{{ getTitleBtn(item.estatus) }}</div>
            </v-btn>
          </div>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pt-2">
            <v-row dense class="mt-2">
              <v-col :cols="4">
                <v-card
                  class="rounded-lg elevation-0 py-3 px-6"
                  color="blue-grey lighten-5"
                >
                  <div
                    class="text-uppercase font-weight-bold blue-grey--text text--darken-2"
                  >
                    Motivo
                  </div>
                  {{ item.Razon }}
                </v-card>
              </v-col>
              <v-col :cols="4">
                <v-card
                  class="rounded-lg elevation-0 py-3 px-6"
                  color="blue-grey lighten-5"
                >
                  <div
                    class="text-uppercase font-weight-bold blue-grey--text text--darken-2"
                  >
                    Ubicación
                  </div>
                  {{ item.Ubicacion }}
                </v-card>
              </v-col>
              <v-col :cols="4">
                <v-card
                  class="rounded-lg elevation-0 py-3 px-6"
                  color="blue-grey lighten-5"
                >
                  <div
                    class="text-uppercase font-weight-bold blue-grey--text text--darken-2"
                  >
                    Compañía
                  </div>
                  {{ item.Compania }}
                </v-card>
              </v-col>
            </v-row>
            <div v-if="[0,1].includes(item.AccionVisitante)" class="center-item-in-div pt-2">
              <v-card class="rounded-lg elevation-1 py-1 px-6" color="blue-grey lighten-5" max-width="250" @click="getEvidencia(item)">
                <div class="text-center overline font-weight-bold blue-grey--text text--darken-3">{{ item.descrAccion }}</div>
                <div class="text-center caption text-uppercase">ver evidencia</div>
              </v-card>
            </div>
            <!-- {{ item.grupo }} -->
            <div v-if="expanded.includes(item)">
              <MuestraVisitantesPorGrupo :idVisita="item.id" />
            </div>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <PersonaDialogSendMail
      v-if="showSendMail"
      :showSendMail="showSendMail"
      :defaultMsg="msgMail"
      :receptor="receptor"
      :title="title"
      @hideSendMail="hideSendMail"
    />

    <RegistraIngresoVisitante
      v-if="muestraDialogRegistraIngreso"
      :registraVisita="muestraDialogRegistraIngreso"
      :datosVisita="visitaSeleccionada"
      :diferencia="diferencia"
      @cierraVistaGeneraIngreso="muestraDialogRegistraIngreso = false"
    />

    <GeneraSalidaVisitante
      v-if="mustraDialogGeneraSalida"
      :marcaSalida="mustraDialogGeneraSalida"
      :datosVisita="visitaSeleccionada"
      @cierraMarcaSalida="mustraDialogGeneraSalida = false"
    />

    <SnackBarError
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />

    <GeneraVisitaExpress v-if="muestraGeneraVisita" :generaVisitaExpress="muestraGeneraVisita" @cierraGeneraVisitaExpress="muestraGeneraVisita = false"/>

    <v-dialog v-model="muestraInfoPase" max-width="750">
      <v-card class="rounded-lg">
        <v-card-title class="primary">
          <label class="overline font-weight-bold white--text">
            Información de pase de salida [{{ idPaseSeleccionado }}]
          </label>
          <v-spacer></v-spacer>
          <v-icon dark @click="muestraInfoPase = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pt-4">
          <MuestraPaseDeSalida v-if="muestraInfoPase" :idPase="idPaseSeleccionado" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="muestraEvidenciaFacturas" max-width="750">
      <v-card class="rounded-lg">
        <v-card-title class="primary">
          <label class="overline font-weight-bold white--text">
            Información de ingreso de material [{{ idVisitaSeleccionada }}]
          </label>
          <v-spacer></v-spacer>
          <v-icon dark @click="muestraEvidenciaFacturas = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pt-4">
          <MuestraEvidenciaEntregaDeMaterial v-if="muestraEvidenciaFacturas" :idVisita="idVisitaSeleccionada" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="alertVisitaVencida"
      max-width="700"
      persistent
      content-class="fix-border-dialog"
    >
      <v-card class="neumorphism-card-reclutamiento">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon @click="alertVisitaVencida = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pt-5">
          <div style="display: flex; justify-content: center;">
            <v-img
              class="text-center"
              :src="require('@/assets/icons/advertencia.png')"
              max-width="100"
            ></v-img>
          </div>
          <p
            class="subtitle-1 text-uppercase font-weight-bold text-center mb-1"
          >
            ¡Advertencia!
          </p>
          <div
            class="text-center body-1 font-weight-bold text-uppercase pink--text text--darken-4"
          >
            hay visitas vencidas
          </div>
          <div v-if="visitasVencidas != undefined">
            <div v-if="visitasVencidas.length > 0">
              <v-card class="rounded-lg px-3 py-1 mt-5 elevation-15">
                <v-data-table
                  dense
                  :items="visitasVencidas"
                  :headers="headersVisitasVencidas"
                  hide-default-footer
                >
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      depressed
                      class="rounded-lg"
                      color="pink"
                      :loading="loadingAddMinutes"
                      small
                      @click="
                        loadingAddMinutes = true;
                        updateHoraSalida(item.id);
                      "
                      dark
                    >
                      <v-icon left small>mdi-clock-plus-outline</v-icon>
                      aplazar
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="alertMessage"
      max-width="450"
      content-class="fix-border-dialog"
    >
      <v-card class="neumorphism-card-reclutamiento">
        <v-card-text class="pt-5">
          <div style="display: flex; justify-content: center;">
            <v-img
              class="text-center"
              :src="require('@/assets/icons/advertencia.png')"
              max-width="100"
            ></v-img>
          </div>
          <p
            class="subtitle-1 text-uppercase font-weight-bold text-center mb-1"
          >
            ¡Advertencia!
          </p>
          <div
            class="text-center body-1 font-weight-bold text-uppercase blue-grey--text text--darken-1"
          >
            {{ msgAlert }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
          <v-btn
            block
            depressed
            text
            x-large
            class="rounded-xl rounded-t-0"
            @click="alertMessage = false"
            color="teal darken-1"
          >
            aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="noticeDialog"
      max-width="450"
      content-class="fix-border-dialog"
    >
      <v-card class="neumorphism-card-reclutamiento">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon @click="noticeDialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pt-5">
          <div style="display: flex; justify-content: center;">
            <v-img
              class="text-center"
              :src="require('@/assets/icons/advertencia.png')"
              max-width="100"
            ></v-img>
          </div>
          <p
            class="subtitle-1 text-uppercase font-weight-bold text-center mb-1"
          >
            ¡Advertencia!
          </p>
          <div
            class="text-center body-1 font-weight-bold text-uppercase blue-grey--text text--darken-1"
          >
            {{ msgNotice }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
          <v-btn
            block
            depressed
            text
            x-large
            class="rounded-xl rounded-t-0"
            @click="
              noticeDialog = false;
              muestraDialogRegistraIngreso = true;
            "
            color="teal darken-1"
          >
            aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import {
  deleteToken,
  formatDate,
} from "../../../../../store/Funciones/funciones";
import MuestraVisitantesPorGrupo from "./MuestraVisitantesPorGrupo.vue";
import PersonaDialogSendMail from "../../../../../components/PersonaDialogSendMail.vue";
import RegistraIngresoVisitante from "./RegistraIngresoVisitante";
import SnackBarError from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import GeneraSalidaVisitante from "./GeneraSalidaVisitante.vue";
import MuestraPaseDeSalida from "./MuestraPaseDeSalida"
import MuestraEvidenciaEntregaDeMaterial from "./MuestraEvidenciaEntregaDeMaterial.vue"
import GeneraVisitaExpress from "./GeneraVisitaExpress.vue";

export default {
  name: "muestra-visitas-registradas",
  components: {
    MuestraVisitantesPorGrupo,
    PersonaDialogSendMail,
    RegistraIngresoVisitante,
    SnackBarError,
    GeneraSalidaVisitante,
    MuestraPaseDeSalida,
    MuestraEvidenciaEntregaDeMaterial,
    GeneraVisitaExpress
  },
  data() {
    return {
      headers: [
        {
          value: "id",
          text: "ID",
          fixed: true,
          class:
            "font-weight-bold text-uppercase blue-grey--text text--darken-1",
        },
        {
          value: "Dia",
          text: "Inicio",
          fixed: true,
          class:
            "font-weight-bold text-uppercase blue-grey--text text--darken-1",
        },
        {
          value: "DiaFin",
          text: "Fin",
          fixed: true,
          class:
            "font-weight-bold text-uppercase blue-grey--text text--darken-1",
        },
        {
          value: "Entrada",
          text: "Entrada",
          class:
            "font-weight-bold text-uppercase blue-grey--text text--darken-1",
        },
        {
          value: "Salida",
          text: "Salida",
          class:
            "font-weight-bold text-uppercase blue-grey--text text--darken-1",
        },
        {
          value: "Nombre",
          text: "Nombre",
          class:
            "font-weight-bold text-uppercase blue-grey--text text--darken-1",
        },
        {
          value: "descVisita",
          text: "Tipo",
          align: "center",
          class:
            "font-weight-bold text-uppercase blue-grey--text text--darken-1",
        },
        {
          value: "grupo",
          text: "visitante",
          align: "center",
          class:
            "font-weight-bold text-uppercase blue-grey--text text--darken-1",
        },
        {
          value: "estatus",
          text: "Estatus",
          align: "center",
          class:
            "font-weight-bold text-uppercase blue-grey--text text--darken-1",
        },
        {
          value: "actions",
          text: "Acción",
          align: "center",
          sortable: false,
          class:
            "font-weight-bold text-uppercase blue-grey--text text--darken-1",
        },
      ],
      headersVisitasVencidas: [
        {
          value: "id",
          text: "ID",
          class:
            "font-weight-bold text-uppercase blue-grey--text text--darken-1",
        },
        {
          value: "Nombre",
          text: "Nombre",
          class:
            "font-weight-bold text-uppercase blue-grey--text text--darken-1",
        },
        {
          value: "Correo",
          text: "Correo",
          class:
            "font-weight-bold text-uppercase blue-grey--text text--darken-1",
        },
        {
          value: "Telefono_resp",
          text: "Télefono",
          class:
            "font-weight-bold text-uppercase blue-grey--text text--darken-1",
        },
        {
          value: "actions",
          text: "",
          align: "center",
          class:
            "font-weight-bold text-uppercase blue-grey--text text--darken-1",
        },
      ],
      visitas: [],
      expanded: [],
      numerosVisitasRegistradas: [],
      visitasVencidas: [],
      visitaSeleccionada: {},
      loadingVisitas: true,
      showSendMail: false,
      alertVisitaVencida: false,
      alertMessage: false,
      noticeDialog: false,
      muestraDialogRegistraIngreso: false,
      hiloActivo: false,
      loadingAddMinutes: false,
      muestraBarra: false,
      mustraDialogGeneraSalida: false,
      muestraInfoPase: false,
      muestraEvidenciaFacturas: false,
      muestraGeneraVisita: false,
      idPaseSeleccionado: 0,
      idVisitaSeleccionada: 0,
      diferencia: 0,
      counter: 0,
      pestania: 1,
      colorBarra: "",
      textoBarra: "",
      msgNotice: "",
      msgAlert: "",
      msgMail: "",
      receptor: "",
      title: "",
      search: "",
      hiloInterval: null,
    };
  },
  beforeRouteLeave(to, from, next) {
    console.log("Se ejecuto hook.");
    this.detenerHilo();
    next();
  },
  async mounted() {
    this.visitas = await this.getDatosVisitas().catch((error) => {
      alert("Error al cargar la información: " + error);
    });
    this.iniciarHilo();
    this.numerosVisitasRegistradas = await this.getNumerosVisitas().catch(
      (error) => {
        alert("Error al cargar la información: " + error);
      }
    );
  },
  methods: {
    muestraSnackBar(color, texto) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    async updateTable() {
      this.loadingVisitas = true;
      if (this.pestania === 1 && this.headers.length === 9) {
        this.headers.push({
          value: "actions",
          text: "Acción",
          align: "center",
          sortable: false,
          class:
            "font-weight-bold text-uppercase blue-grey--text text--darken-1",
        });
      } else if (this.pestania != 1 && this.headers.length === 10) {
        this.headers.pop();
      }
      this.visitas = await this.getDatosVisitas().catch((error) => {
        this.muestraSnackBar(
          "pink darken-3",
          "Error al cargar la información de visitas: " + error
        );
      });
    },
    async getDatosVisitas() {
      const params = {
        Opcion: 12,
        token: localStorage.getItem("token"),
        tipo: this.pestania,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.visitas);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingVisitas = false;
          });
      });
    },
    async getNumerosVisitas() {
      const params = {
        Opcion: 13,
        token: localStorage.getItem("token"),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.visitas);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingVisitas = false;
          });
      });
    },
    fixFormatDate(date) {
      return formatDate(date);
    },
    formatearNumeroConComas(numero) {
      return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getColor(estatus) {
      if (estatus === 0) {
        return "blue accent-3";
      } else if (estatus === 1) {
        return "teal darken-1";
      } else if (estatus === 3) {
        return "deep-purple accent-3";
      }

      return "grey darken-1";
    },
    getTitleBtn(estatus) {
      if (estatus === 0) {
        return "Notificar";
      } else if (estatus === 1) {
        return "Ingresar";
      } else if (estatus === 3) {
        return "Registrar salida";
      }
      return "not defined";
    },
    getIconBtn(estatus) {
      if (estatus === 0) {
        return "mdi-email-fast";
      } else if (estatus === 1) {
        return "mdi-door-open";
      } else if (estatus === 3) {
        return "mdi-office-building";
      }
      return "mdi-note-alert-outline";
    },
    openAction(item, estatus) {
      if (estatus === 0) {
        this.openSendMail(item);
      } else if (estatus === 1) {
        let respuesta = this.validarFecha(item.Dia, item.DiaFin);
        console.log(respuesta);
        if (!respuesta.estatus) {
          console.log("entró");
          if (respuesta.tipo == 2) {
            this.msgAlert =
              "No se puede registrar el ingreso porque la visita esta vencida.";
            this.alertMessage = true;
          } else if (respuesta.tipo == 1) {
            this.msgAlert =
              "No se puede registrar el ingreso porque la visita no está programada para hoy.";
            this.alertMessage = true;
          } else {
            this.msgAlert = "No se puede registrar el ingreso.";
            this.alertMessage = true;
          }
        } else {
          this.visitaSeleccionada = item;
          let diferenciaAux = this.getDiferenciaEntrada(
            item.Entrada.date.substring(11, 16)
          );
          this.diferencia = diferenciaAux;
          if (Math.abs(this.diferencia) >= 30) {
            this.msgNotice =
              "El horario de la visita esta fuera de rango. ¿Deseas continuar de todas maneras?";
            this.noticeDialog = true;
          } else {
            this.muestraDialogRegistraIngreso = true;
          }
        }
      } else if (estatus === 3) {
        this.visitaSeleccionada = item;
        this.mustraDialogGeneraSalida = true;
      }
    },
    async openSendMail(item) {
      this.receptor = "mromero@urrea.net";
      const tokenVisita = await this.getTokenAutorizacion(item.id);

      if (tokenVisita.status_tran == "OK") {
        this.title = "Autorización de visita requerida.";
        this.msgMail =
          "El empleado " +
          item.Emplid +
          " a solicitado una visita para el dia " +
          item.Dia.date.substring(0, 10) +
          "<br><br>" +
          "<center>" +
          "<br>" +
          "<a style='" +
          "    background-color: rgb(0,120,195); " +
          "    color: white; " +
          "    border: none; " +
          "    padding: 10px 15px 10px 15px; " +
          "    border-radius: 10px;" +
          "    text-transform: uppercase;" +
          "    text-decoration: none;" +
          "    '" +
          "    href='https://desarrollohumano.urrea.com/#/AutorizaVisita/" +
          tokenVisita.message +
          "'>" +
          "    enlace  a  autorización  de  visita" +
          "</a>" +
          "</center>" +
          "<br><span><b>Información de la visita</b></span>" +
          "<br><b>Nombre</b>:" +
          item.Nombre +
          " " +
          " " +
          item.Paterno +
          " " +
          item.Materno +
          "<br><b>Día</b>: " +
          item.Dia.date.substring(0, 10) +
          "<br><b>Correo</b>: " +
          item.Correo +
          "<br><b>Ubicación</b>: " +
          item.Ubicacion +
          "<br><b>Motivo</b>:" +
          item.Razon;
        this.showSendMail = true;
      } else {
        this.muestraSnackBar("pink darken-3", "No se pudo obtener el token.");
      }
    },
    hideSendMail(respuesta) {
      this.showSendMail = false;
      if (respuesta == 1) {
        alert("Correo enviado");
      } else if (respuesta == -1) {
        alert("Correo no enviado");
      }
    },
    validarFecha(Dia, DiaFin) {
      let fechaActual = new Date();
      let fechaInicio = new Date(Dia.date.substring(0, 10) + "T00:00:00");
      // console.log(DiaFin)
      let fechaFin = null;
      fechaFin =
        DiaFin !== null
          ? new Date(DiaFin.date.substring(0, 10) + "T00:00:00")
          : null;

      fechaActual.setHours(0, 0, 0, 0);

      if (fechaFin === null) {
        if (+fechaActual === +fechaInicio) {
          return { estatus: true, tipo: 0 };
        } else if (+fechaActual > +fechaInicio) {
          console.log(fechaActual)
          console.log(fechaInicio)
          return { estatus: false, tipo: 2 };
        } else {
          return { estatus: false, tipo: 1 };
        }
      } else {
        fechaInicio.setHours(0, 0, 0, 0);
        fechaFin.setHours(0, 0, 0, 0);

        if (fechaInicio <= fechaActual && fechaActual <= fechaFin) {
          return { estatus: true, tipo: 0 };
        } else if (fechaInicio > fechaActual) {
          return { estatus: false, tipo: 1 };
        } else if (fechaFin < fechaActual) {
          return { estatus: false, tipo: 2 };
        } else {
          return { estatus: false, tipo: 2 };
        }
      }
    },
    getDiferenciaEntrada(horaString) {
      const horaActual = new Date();

      const horaPartes = horaString.split(":");
      const horaDestino = new Date(
        horaActual.getFullYear(),
        horaActual.getMonth(),
        horaActual.getDate(),
        parseInt(horaPartes[0]),
        parseInt(horaPartes[1])
      );

      const diferenciaEnMilisegundos = horaDestino - horaActual;
      const diferenciaEnMinutos = diferenciaEnMilisegundos / (1000 * 60);

      return diferenciaEnMinutos;
    },
    iniciarHilo() {
      if (!this.hiloActivo) {
        this.hiloActivo = true;
        this.ejecutarHilo(); // Ejecutamos inmediatamente al inicio
        this.hiloInterval = setInterval(this.ejecutarHilo, 600000); // 300000 ms = 5 minutos
      }
    },
    async ejecutarHilo() {
      this.counter++;
      console.log("Se ejecuto hilo: " + this.counter);
      if (this.$route.path != "/SeguridadPatrimonial/AdmonVisitas") {
        this.detenerHilo();
      } else {
        this.visitasVencidas = await this.getDatosVisitasVencidas().catch(
          (e) => {
            this.muestraSnackBar(
              "blue-grey darken-4",
              "Error al cargar visitas vencidas: " + e
            );
          }
        );
        if (this.visitasVencidas != undefined) {
          if (this.visitasVencidas.length > 0) {
            this.alertVisitaVencida = true;
          }
        }
      }
    },
    detenerHilo() {
      if (this.hiloActivo) {
        this.hiloActivo = false;
        console.log("Se cerró hilo");
        clearInterval(this.hiloInterval);
      }
    },
    async getDatosVisitasVencidas() {
      this.visitasVencidas.length = 0;
      const params = {
        Opcion: 31,
        token: localStorage.getItem("token"),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.visitas);
            }
          })
          .catch((error) => {
            this.detenerHilo();
            reject(error);
          })
          .finally(() => {
            this.loadingVisitas = false;
          });
      });
    },
    beforeRouteLeave(to, from, next) {
      console.log("Se ejecuto hook.");
      this.detenerHilo();
      next();
    },
    updateHoraSalida(id) {
      const params = {
        Opcion: 32,
        token: localStorage.getItem("token"),
        idVisita: id,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status === "OK") {
            if (response.data.respuesta.status_tran == "OK") {
              this.muestraSnackBar(
                "teal darken-1",
                response.data.respuesta.message
              );
              this.alertVisitaVencida = false;
              this.ejecutarHilo();
            } else {
              this.muestraSnackBar(
                "pink darken-3",
                response.data.respuesta.message
              );
            }
          } else {
            this.muestraSnackBar(
              "pink darken-3",
              "No se pudo registrar el item. " + this.articuloName
            );
          }
        })
        .catch((e) => {
          this.muestraSnackBar("pink darken-3", e);
        })
        .finally(() => {
          this.loadingAddMinutes = false;
        });
    },
    async getTokenAutorizacion(id) {
      const params = {
        Opcion: 40,
        token: localStorage.getItem("token"),
        idVisita: id,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.respuesta);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getEvidencia(visita){
      if(visita.AccionVisitante === 0){
        if(![0, null].includes(visita.id_pase_de_salida)){
          this.idPaseSeleccionado = visita.id_pase_de_salida
          this.muestraInfoPase = true;
        }
      } else if (visita.AccionVisitante === 1){
        this.idVisitaSeleccionada = visita.id
        this.muestraEvidenciaFacturas = true
      }
    }
  },
};
</script>
