var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingData)?_c('v-sheet',{staticClass:"center-item-in-div",attrs:{"max-width":"700px","height":"300"}},[_c('v-progress-circular',{attrs:{"size":70,"width":12,"color":"primary","indeterminate":""}})],1):_c('v-sheet',{staticClass:"elevation-2 mt-8",attrs:{"max-width":"700px"}},[_c('v-data-table',{attrs:{"items":_vm.evidencias,"headers":_vm.headers,"no-data-text":"No se registró evidencia.","footer-props":{
        'items-per-page-text': 'Elementos por página',
        pageText: '{0}-{1} de {2}',
      }},scopedSlots:_vm._u([{key:"item.nombre_archivo",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre_archivo.includes(".txt") ? item.nombre_archivo.slice(0, -4) : item.nombre_archivo)+" ")]}},{key:"item.accion",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"rounded-lg",attrs:{"small":"","depressed":"","color":"cyan","dark":""},on:{"click":function($event){return _vm.openFile(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-eye")]),_vm._v(" ver ")],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }