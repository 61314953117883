<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px" persistent scrollable>
      <v-card class="rounded-lg" :loading="loadingCard">
        <v-card-title class="primary white--text subtitle-2">
          <span>Registro de Viernes Corto</span>
          <v-spacer></v-spacer>
          <v-icon dark @click="$emit('cierra', 0)">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container fluid>
            <div v-if="!tienePermiso">
              {{ mensajePermiso }}
            </div>
            <div v-else>
              <v-alert
                :value="alert"
                :color="colorAlert"
                dark
                border="top"
                :icon="iconAlert"
                transition="scale-transition"
              >
                <v-row align="center">
                  <v-col class="grow">
                    {{ respuestaTurno.mensaje }}
                  </v-col>
                  <v-col class="shrink">
                    <v-row align="center">
                      <v-col cols="12" sm="6">
                        <v-btn small @click="getAccionAlert()">
                          {{ textoBotonAlert }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-alert>
              <v-row>
                <v-col sm="3">
                  <p class="font-weight-bold">Turno:</p>
                </v-col>
                <v-col sm="9">
                  <!-- <p>{{ turno_desc }}</p> -->
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col sm="12">
                  <v-select
                    :items="horariosFlexTime"
                    :loading="loadingWindow"
                    label="Selecciona un turno"
                    item-text="descripcion"
                    item-value="IdNuevoTurno"
                    dense
                    outlined
                    v-model="valSelect"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col sm="12">
                  <p>Registro necesariamente semanal</p>
                  <v-row no-gutters v-if="esAdmin === true">
                    <v-col
                      cols="12"
                      sm="12"
                      style="display: flex; justify-content: center"
                    >
                      <p style="text-align: center; color: darkred">
                        ¡Importante! esta es una incidencia de registro semanal.
                        Carga toda la semana laboral del empleado (L - V).
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      style="display: flex; justify-content: center"
                    >
                      <v-text-field
                        v-model="dateRangeText"
                        placeholder="Selecciona el rango de fechas"
                        style="max-width: 350px"
                        label="Selecciona el rango de fechas"
                        prepend-icon="mdi-calendar"
                        readonly
                        @click="dialogFechas = true"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-else>
                    <v-col sm="12">
                      <v-select
                        :items="limitesSemanas"
                        label="Selecciona la semana en la que se registrará la incidencia."
                        dense
                        outlined
                        v-model="semanaSelect"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
        <v-divider v-show="tienePermiso"></v-divider>
        <v-card-actions v-show="tienePermiso">
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg"
            color="teal darken-1"
            dark
            elevation="2"
            :loading="loadingBotonGuardar"
            @click="validaDatos()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFechas" max-width="450px">
      <v-date-picker
        v-model="dates"
        max-width="450px"
        v-on:change="cierraDialog()"
        selected-items-text="Seleccionados"
        range
        elevation="1"
        locale="es-mx"
      ></v-date-picker>
    </v-dialog>

    <v-sheet id="muestra-incidencias-cargadas">
      <muestra-incidencias-cargadas
        v-if="muestraDetalles"
        :muestraDetalles="muestraDetalles"
        :datosTabla="respuestaTurno.data"
        :alertErrorDosDias="alertErrorDosDias"
        @cierraTabla="muestraDetalles = false"
      />
    </v-sheet>

    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias
        v-if="muestraBarra"
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-sheet>

    <v-sheet id="muestra-calendario-dias-seleccionados">
      <muestra-calendario-dias-seleccionados
        :muestraCalendario="muestraCalendario"
        :fechas="respuestaTurno.data"
        @cierraCalendario="muestraCalendario = false"
      />
    </v-sheet>
  </div>
</template>

<script>
import axios from "axios";
import MuestraIncidenciasCargadas from "./componentsExtra/MuestraIncidenciasCargadas.vue";
import SnackBarErrorIncidencias from "./componentsExtra/SnackBarErrorIncidencias.vue";
import MuestraCalendarioDiasSeleccionados from "./componentsExtra/MuestraCalendarioDiasSeleccionados.vue";
import { formatDate } from "./../../../../../store/Funciones/funciones";
// import { formatDateISO } from "./../../../../../store/Funciones/funciones";

export default {
  name: "incidencia-viernes-corto",
  data: () => ({
    descTurno: [],
    horariosFlexTime: [],
    turno_desc: "",
    loadingBotonGuardar: false,
    fechaInicio: "",
    fechaInicioS: "",
    fechaFin: "",
    fechaFinS: "",
    modalFechaInicio: false,
    modalFechaFin: false,
    dialogFechas: false,
    valSelect: 0,
    semanaSelect: "",
    proxSemanas: [],
    limitesSemanas: [],
    loadingWindow: true,
    fechaEnviar: "",
    respuestaTurno: [],
    dates: [],
    respuestaValidaPermiso: "",
    mensajePermiso: "",
    menu: false,
    alert: false,
    iconAlert: "mdi-alert-circle",
    colorAlert: "pink",
    textoBotonAlert: "",
    muestraDetalles: false,
    muestraBarra: false,
    textoBarra: "",
    colorBarra: "error",
    alertErrorDosDias: false,
    muestraCalendario: false,
    tienePermiso: false,
    loadingCard: true,
  }),
  computed: {
    dateRangeText() {
      let inicio = "";
      let fin = "";
      if (this.dates[0] != null) {
        inicio = formatDate(this.dates[0]);
      }
      if (this.dates[1] != null) {
        fin = formatDate(this.dates[1]);
      }

      if (this.dates.length == 0) {
        return "";
      }
      return "Del " + inicio + " al " + fin; //this.dates.join(' al ')
    },
  },
  components: {
    MuestraIncidenciasCargadas,
    MuestraCalendarioDiasSeleccionados,
    SnackBarErrorIncidencias,
  },
  props: ["dialog", "esAdmin", "empleado"],
  created() {
    this.getPermisoFlexTime();
  },
  methods: {
    cierraDialog() {
      if (this.dates.length == 2) {
        this.dialogFechas = false;
      }
    },

    cierraVentanaIngresaHoraInicio() {
      this.fechaInicioS = formatDate(this.fechaInicio);
      this.modalFechaInicio = false;
    },
    cierraVentanaIngresaHoraFin() {
      this.fechaFinS = formatDate(this.fechaFin);
      this.modalFechaFin = false;
    },

    getPermisoFlexTime() {
      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
        esAdmin: this.esAdmin,
        empleado: this.empleado,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-viernes-corto.php`,
          params
        )
        .then((response) => {
          var data = response.data.permiso;
          this.respuestaValidaPermiso = data;
          this.validaRespuestaPermiso();
          // this.loadingWindow = false;
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    validaRespuestaPermiso() {
      if (this.respuestaValidaPermiso == 0) {
        this.mensajePermiso =
          "Para poder solicitar esta incidencia debes tener la" +
          " autorización por dirección y gerencia de tu área " +
          "y enviar un correo a tu generalista. ";
        this.tienePermiso = false;
        this.loadingCard = false;
      } else {
        this.tienePermiso = true;
        this.loadingCard = false;
        this.getHorariosFlexTimeEspeciales();
      }
    },
    getHorariosFlexTimeEspeciales() {
      let params = {
        Opcion: 2,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-viernes-corto.php`,
          params
        )
        .then((response) => {
          var data = response.data.horarios;
          this.horariosFlexTime = data;
          this.loadingWindow = false;
          this.getNextThreeWeek();
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },

    getNextThreeWeek() {
      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-home-office.php`,
          params
        )
        .then((response) => {
          var data = response.data.proxTresSemanas;
          this.proxSemanas = data;
          this.concatenaFechas();
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },

    concatenaFechas() {
      this.proxSemanas.forEach((element) => {
        this.limitesSemanas.push(element.fli + " - " + element.flf);
      });
    },

    setFechaManana() {
      const today = new Date();
      let tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.toISOString().substring(0, 10);
    },

    validaDatos() {
      var error = 0;
      if (
        ((this.valSelect != 0 || this.valSelect != "") &&
          (this.semanaSelect != 0 || this.semanaSelect != "") &&
          this.esAdmin != true) ||
        ((this.valSelect != 0 || this.valSelect != "") &&
          this.dates.length == 2 &&
          this.esAdmin)
      ) {
        error = 0;
      } else {
        error = 1;
      }
      if (error == 0) {
        this.loadingBotonGuardar = true;
        if (this.esAdmin !== true) {
          this.fechaEnviar =
            this.proxSemanas[0].inicio + "," + this.proxSemanas[0].fin;
        } else {
          this.fechaEnviar = this.dates[0] + "," + this.dates[1];
        }

        if (!this.esAdmin) {
          let buscaFechas = this.proxSemanas.filter((s) => {
            return this.semanaSelect === s.fli + " - " + s.flf;
          });

          this.fechaEnviar = buscaFechas[0].inicio + "," + buscaFechas[0].fin;
        }

        let id = this.horariosFlexTime.filter(
          (horario) => horario["descripcion"] == this.valSelect
        );
        const params = {
          Opcion: 1,
          token: localStorage.getItem("token"),
          fecha: this.fechaEnviar,
          idTurno: id[0].idNuevoTurno,
          esAdmin: this.esAdmin,
          empleado: this.empleado,
        };
        
        axios
          .post(
            `${this.$store.getters.getHost}/Incidencias/ws-incidencias-flex-time.php`,
            params
          )
          .then((response) => {
            var data = response.data.flexTime;
            this.respuestaTurno = data;
            this.validaRespuesta();
          })
          .catch(function (e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      } else {
        this.textoBarra = "Tienes que llenar todos los campos";
        this.colorBarra = "error";
        this.muestraBarra = true;
      }
    },
    validaRespuesta() {
      this.loadingBotonGuardar = false;
      if (this.respuestaTurno["opc"] != 5) {
        if (this.respuestaTurno["opc"] == 2) {
          this.colorAlert = "pink";
          this.iconAlert = "mdi-alert-circle";
          this.textoBotonAlert = "Ver más";
          this.alert = true;
          this.textoBarra = this.respuestaTurno["mensaje"];
          this.colorBarra = "success";
          this.muestraBarra = true;
          this.$emit("cierra", 1);
        } else {
          this.colorAlert = "pink";
          this.iconAlert = "mdi-alert-circle";
          this.textoBotonAlert = "Cerrar";
          this.alert = true;
          this.textoBarra = this.respuestaTurno["mensaje"];
          this.colorBarra = "primary";
          this.muestraBarra = true;
        }
      } else {
        this.textoBotonAlert = "Ver detalles";
        this.colorAlert = "success";
        this.iconAlert = "mdi-content-save-check-outline";
        this.valSelect = 0;
        this.fechaInicioS = "";
        this.fechaFinS = "";
        this.alert = true;
        this.textoBarra = this.respuestaTurno["mensaje"];
        this.colorBarra = "error";
        this.muestraBarra = true;
      }
    },

    getAccionAlert() {
      if (this.textoBotonAlert == "Ver más") {
        this.alert = false;
        this.muestraDetalles = true;
      } else if (this.textoBotonAlert == "Ver detalles") {
        this.alert = false;
        this.muestraCalendario = true;
      } else {
        this.alert = false;
      }
    },
  },
};
</script>
