import axios from 'axios'
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
import DialogVerSalariosDeMercado from "./components/DialogVerSalariosDeMercado.vue"
import DialogFlujoAutorizaciones from "./components/DialogFlujoAutorizaciones.vue"
import DialogAcualizaSalarioPorPosicion from "./components/DialogActualizaSalarioPorPosicion.vue"
import LoadingBar from "../../../DesarrolloOrganizacional/components/loadingBar.vue"
import {
    deleteToken
} from "../../../../../store/Funciones/funciones"

export default {
    name: 'ofertas-economicas',
    components: {
        SnackBarErrorIncidencias,
        DialogVerSalariosDeMercado,
        DialogFlujoAutorizaciones,
        DialogAcualizaSalarioPorPosicion,
        LoadingBar
    },
    props: [],
    data() {
        return {
            tipoOE: '',
            area: '',
            departamento: '',
            empleado: '',
            apegoPerfil: '',
            puestoUrrea: '',
            puestoMercer: '',
            salario: '',
            salarioPlanta: '',
            numReajustes: '',
            salarioMercado: '',
            nomPuesto: '',
            salMin: '',
            salProm: '',
            salMax: '',
            nomPuestoMercer: '',
            salMinMercer: '',
            salPromMercer: '',
            salMaxMercer: '',
            promMinimo: '',
            promMedio: '',
            promMaximo: '',
            experiencia: '',
            experienciaMercer: '',
            asignados: '',
            eqMinimo: '',
            eqProm: '',
            eqMaximo: '',
            hsalariomin: '',
            hsalariomax: '',
            aclaracion: '',
            jobcodeAnt: '',
            prestacionesPlanta: false,
            openOfertaEconomicaDialog: false,
            showTable: false,
            planDesarrollo: false,
            salariosMercadoDialog: false,
            reajustes: false,
            loadingTipo: false,
            muestraFlujoAutorizaciones: false,
            loadingFlujo: false,
            muestraRangos: false,
            formulario: true,
            loadingGeneraOferta: false,
            loadingDialog: false,
            loadingDepartamentos: false,
            tieneAclaracion: false,
            muestraTablaReglasPromotores: false,
            muestraComentariosPlanDeDesarrollo: false,
            esCalzada: false,
            noEsCalzada: false,
            areas: [{
                'id': '',
                'area': ''
            }],
            comentariosPlanDeDesarrollo: [{ text: "" }],
            num_reajustes: [1, 2],
            numMeses: [3, 4, 5, 6, 7, 8, 9],
            reajustesInputs: [],
            ofertaEconomica: [],
            ofertaEconomicaAjustes: [],
            ofertaEconomicaAlaPlanta: [],
            puestosUrrea: [],
            puestosMercer: [],
            personalOfertas: [],
            personalExternos: [],
            personalInternos: [],
            personalActivos: [],
            ofertaEconomicaResultados: [],
            headers: [],
            rangos: [],
            rangosDisponibles: [],
            rangoSelected: [],
            flujo: [],
            reglasPromotores: [],
            departamentos: [{
                'id': '',
                'departamento': ''
            }],
            headers_rangos: [{
                    value: "id",
                    text: "id",
                    sortable: false,
                    align: "center"
                },
                {
                    value: "Minimo",
                    text: "Mínimo",
                    sortable: false,
                    align: "center"
                },
                {
                    value: "Maximo",
                    text: "Máximo",
                    sortable: false,
                    align: "center"
                },
                {
                    value: "Rango",
                    text: "Rango",
                    sortable: false,
                    align: "center"
                },
                {
                    value: "Aguinaldo",
                    text: "Aguinaldo",
                    sortable: false,
                    align: "center"
                },
                {
                    value: "Prima_vacacional",
                    text: "Prima",
                    sortable: false,
                    align: "center"
                },
                {
                    value: "Bono_resultados",
                    text: "Bono",
                    sortable: false,
                    align: "center"
                },
                {
                    value: "Seguro_vida",
                    text: "Seguro de vida",
                    sortable: false,
                    align: "center"
                },
                {
                    value: "Gastos_medicos",
                    text: "GMM",
                    sortable: false,
                    align: "center"
                },
                {
                    value: "Automovil",
                    text: "Automóvil",
                    sortable: false,
                    align: "center"
                },
                {
                    value: "Revision_medica",
                    text: "Revision médica",
                    sortable: false,
                    align: "center"
                },

            ],
            tipoOfertaEconomica: [{
                    'id': 1,
                    'ofertaEconomica': 'Nuevo ingreso'
                },
                {
                    'id': 2,
                    'ofertaEconomica': 'Incremento de responsabilidades'
                },
                {
                    'id': 3,
                    'ofertaEconomica': 'Contra oferta'
                },
                {
                    'id': 4,
                    'ofertaEconomica': 'Nivelación interna'
                },
                {
                    'id': 5,
                    'ofertaEconomica': 'Ajuste de mercado'
                },
                {
                    'id': 6,
                    'ofertaEconomica': 'Promoción interna'
                },
            ],
            tiemposParaComision: [
                { valor: 1, descripcion: "1 mes" },
                { valor: 2, descripcion: "2 meses" },
                { valor: 3, descripcion: "3 meses" },
                { valor: 4, descripcion: "4 meses" },
                { valor: 5, descripcion: "5 meses" },
                { valor: 6, descripcion: "6 meses" },
                { valor: 7, descripcion: "7 meses" },
                { valor: 8, descripcion: "8 meses" },
                { valor: 9, descripcion: "9 meses" },
                { valor: 10, descripcion: "10 meses" },
                { valor: 11, descripcion: "11 meses" },
                { valor: 12, descripcion: "12 meses" },
            ],
            porcentajeIncremento1: 0,
            porcentajeIncremento2: 0,
            comisionNueva: 0,
            comisionActual: 0,
            montoEdited: 0,
            muestraDialogActualizaSalario: 0,
            uma: 0,
            incremento1: 0,
            incremento2: 0,
            tiempoParaComision: 0,
            salarioMensualActual: 0,
            tipoComision: 1,
            positionEditing: {},
            ofertaEnEdicion: {},
            textoBarra: '',
            colorBarra: '',
            muestraBarra: false,
            colorMsgSalario: '',
            textoMsgSalario: '',

            // form rules
            typeRules: [
                v => !!v || 'El tipo de oferta es obligatorio.',
            ],
            areaDeptoRules: [
                v => !!v || 'Este campo es necesario.',
            ],
            empleadoRules: [
                v => !!v || 'Asignar un nombre es obligatorio.',
            ],
            apegoRules: [
                v => !!v || 'Este campo es necesario.',
                v => v > 0 || 'El apego debe ser mayor a 0.',
            ],
            fieldRules: [
                v => !!v || 'Este campo es necesario.',
            ],

        }
    },
    computed: {

    },
    async created() {
        if (!isNaN(parseInt(this.$route.params.idOferta))) {
            this.loadingDialog = true;
            this.ofertaEnEdicion = await this.getOfertaById();
            this.tipoOE = parseInt(this.ofertaEnEdicion.Tipo);
            if ([1, 6].includes(parseInt(this.ofertaEnEdicion.Tipo))) {
                this.areas = await this.getAreas();
                let areaAux = this.areas.filter((a) => {
                    return a.id == parseInt(this.ofertaEnEdicion.idArea)
                })
                this.area = areaAux[0].id
                this.departamentos = await this.getDepartamentos();
                let departamentoAux = this.departamentos.filter((d) => {
                    return d.id == this.ofertaEnEdicion.idDepartamento
                })
                this.departamento = departamentoAux[0].id
            }
            this.getDataEmpleado();
            if ([1].includes(parseInt(this.ofertaEnEdicion.Tipo))) {
                this.empleado = parseInt(this.ofertaEnEdicion.id_candidato)
            } else {
                this.empleado = this.ofertaEnEdicion.id_empleado.toString()
            }
            this.buscaApegoPlanDeDesarrolloPorTipoDeOferta();
            this.puestosUrrea = await this.getPuestosUrrea();
            let puesto = this.puestosUrrea.filter((p) => {
                return p.id == this.ofertaEnEdicion.jobcode
            })
            this.puestoUrrea = puesto[0].id

            this.puestosMercer = await this.getPuestosMercer();
            let mercer = this.puestosMercer.filter((p) => {
                return p.id == this.ofertaEnEdicion.mercer
            })
            this.puestoMercer = mercer[0].id
            this.salario = parseFloat(this.ofertaEnEdicion.Salario)
            if (this.ofertaEnEdicion.Reajuste > 0) {
                this.reajustes = true;
                this.numReajustes = parseInt(this.ofertaEnEdicion.Reajuste);
                this.porcentajeIncremento1 = (parseFloat(this.ofertaEnEdicion.SalariosArray[0]) * 100) / parseFloat(this.ofertaEnEdicion.Salario)
                if (this.ofertaEnEdicion.Reajuste == 2) {
                    this.porcentajeIncremento2 = (parseFloat(this.ofertaEnEdicion.SalariosArray[1]) * 100) / parseFloat(this.ofertaEnEdicion.Salario)
                }
            }
            this.apegoPerfil = this.ofertaEnEdicion.ApegoP
            if (this.ofertaEnEdicion.Rango_asignado > 0) {
                this.muestraRangos = true;
                this.rangos = await this.getRangos();
                this.rangoSelected = this.rangos.filter((r) => { return r.Rango == this.ofertaEnEdicion.Rango_asignado })
            }
            this.loadingDialog = false;
        } else {
            this.areas = await this.getAreas();
            this.puestosUrrea = await this.getPuestosUrrea();
            this.puestosMercer = await this.getPuestosMercer();
            this.getUma();
            this.rangos = await this.getRangos();
            this.reglasPromotores = await this.getReglasPromotores();
        }

    },
    methods: {
        muestraSnackBar(texto, color) {
            this.colorBarra = color
            this.textoBarra = texto
            this.muestraBarra = true
        },
        tipoOEEs1o6(tipoOE) {
            return [1, 6].includes(tipoOE);
        },

        async getAreas() {
            try {
                let params = {
                    Opcion: 1,
                    token: localStorage.getItem("token"),
                };
                const response = await axios.post(`${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`, params);
                if (response.data.status === 'OK') {
                    return response.data.data;
                } else {
                    return null;
                }
            } catch (error) {
                console.error(error);
                return null;
            }
        },

        async getReglasPromotores() {
            try {
                let params = {
                    Opcion: 27,
                    token: localStorage.getItem("token"),
                };
                const response = await axios.post(`${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`, params);
                if (response.data.status === 'OK') {
                    return response.data.respuesta;
                } else {
                    return [];
                }
            } catch (error) {
                console.error(error);
                return [];
            }
        },


        async getOfertaById() {
            let params = {
                Opcion: 23,
                token: localStorage.getItem("token"),
                idOferta: parseInt(this.$route.params.idOferta)
            }
            return new Promise((resolve, reject) => {
                axios.post(`${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`, params).then((response) => {
                    if (response.data.status == 'OK') {
                        resolve(response.data.oferta);
                    }
                }).catch((error) => {
                    reject({
                        error: error
                    })
                });
            });

        },

        getUma() {
            let params = {
                Opcion: 22,
                token: localStorage.getItem("token"),
            }
            axios.post(`${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`, params).then((response) => {
                if (response.data.status == 'OK') {
                    this.uma = response.data.uma;

                }
            })

        },

        async getRangos() {
            const params = {
                Opcion: 14,
                token: localStorage.getItem("token"),
            }

            return new Promise((resolve) => {
                axios.post(`${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`, params).then((response) => {
                    if (response.data.status == 'OK') {
                        resolve(response.data.rangos);
                    }
                })
            })


        },

        async getDepartamentos() {
            try {
                let params = {
                    Opcion: 2,
                    area: this.area,
                    token: localStorage.getItem("token"),
                };
                const response = await axios.post(`${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`, params);
                this.loadingDepartamentos = false;
                if (response.data.status === 'OK') {
                    return response.data.data;
                } else {
                    return null;
                }
            } catch (error) {
                // Manejo de errores generales, como errores de red, etc.
                console.error(error);
                return null;
            }
        },

        async handleDepartamentosChange() {
            this.departamentos = await this.getDepartamentos();
        },

        async getPuestosUrrea() {
            try {
                let params = {
                    Opcion: 3,
                    token: localStorage.getItem("token"),
                };
                const response = await axios.post(`${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`, params);
                if (response.data.status === 'OK') {
                    return response.data.data;
                } else {
                    return null;
                }
            } catch (error) {
                console.error(error);
                return null;
            }
        },

        async getPuestosMercer() {
            try {
                let params = {
                    Opcion: 4,
                    token: localStorage.getItem("token"),
                };
                const response = await axios.post(`${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`, params);
                if (response.data.status === 'OK') {
                    return response.data.data;
                } else {
                    return null;
                }
            } catch (error) {
                console.error(error);
                return null;
            }
        },

        addInputs() {
            if (this.reajustesInputs.length < this.numReajustes) {
                for (let a = 0; a < this.numReajustes; a++) {
                    this.reajustesInputs.push();
                }
            }
            if (this.reajustesInputs.length > this.numReajustes) {
                let diferencia = this.reajustesInputs.length - this.numReajustes;
                for (let b = 0; b < diferencia; b++) {
                    this.reajustesInputs.pop();
                }
            }
            if (this.reajustes == false) {
                this.reajustesInputs = [];
                this.numReajustes = '';
            }
        },

        hideVerSalarios(salarioMercado) {
            if (salarioMercado == null) {
                this.salariosMercadoDialog = false;
            } else {
                this.verSalarios(salarioMercado)
            }
        },

        verSalarios(salarioMercado) {
            if (this.puestoUrrea != '' && this.puestoMercer != '') {
                let params = {
                    Opcion: 6,
                    token: localStorage.getItem("token"),
                    puestoUrrea: this.puestoUrrea,
                    puestoMercer: this.puestoMercer,
                    salarioMercado: salarioMercado,
                }
                axios.post(`${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`, params).then((response) => {
                    if (response.data.status == 'OK') {
                        this.nomPuesto = response.data.data[0]['nomPuesto'];
                        this.salMin = response.data.data[0]['salMin'];
                        this.salProm = response.data.data[0]['salProm'];
                        this.salMax = response.data.data[0]['salMax'];
                        this.experiencia = response.data.data[0]['experiencia'];

                        this.nomPuestoMercer = response.data.data[1]['nomPuestoMercer'];
                        this.salMinMercer = response.data.data[1]['salMinMercer'];
                        this.salPromMercer = response.data.data[1]['salPromMercer'];
                        this.salMaxMercer = response.data.data[1]['salMaxMercer'];
                        this.experienciaMercer = response.data.data[1]['experienciaMercer'];

                        this.promMinimo = response.data.data[1]['salPromMin'];
                        this.promMedio = response.data.data[1]['salPromMedio'];
                        this.promMaximo = response.data.data[1]['salPromMax'];

                        this.asignados = response.data.data[2]['asignados'];
                        this.eqMinimo = response.data.data[2]['minimo'];
                        this.eqProm = response.data.data[2]['mensual'];
                        this.eqMaximo = response.data.data[2]['maximo'];

                        this.salariosMercadoDialog = false;
                        this.showTable = true;
                    }
                })

            } else {
                this.textoBarra = 'Seleccione un puesto valido';
                this.colorBarra = 'error';
                this.muestraBarra = true;

            }
        },

        checkSalario() {
            let salarios = 0;
            if ([1, 6].includes(this.tipoOE)) {
                if (this.reajustes != 0) {
                    salarios = this.reajustesInputs[this.reajustesInputs.length - 1];
                } else {
                    salarios = this.salario;
                }
            } else {
                salarios = this.salario;
            }

            // this.rangosDisponibles = this.rangos.filter((rango) => {
            //     return parseFloat(this.salario) >= rango.Minimo && parseFloat(this.salario) <= rango.Maximo
            // })

            this.hsalariomin = this.eqMinimo - (this.eqMinimo * .001);
            this.hsalariomax = this.eqMaximo * 1.001;
            if (this.hsalariomin != 0 || this.hsalariomax != 0) {
                if (salarios < this.hsalariomin) {
                    this.textoMsgSalario = 'El salario es inferior al salario aprobado';
                    this.colorMsgSalario = 'error--text';
                } else if (salarios > this.hsalariomax) {
                    this.textoMsgSalario = 'El salario es superior al salario aprobado';
                    this.colorMsgSalario = 'error--text';

                } else {
                    this.textoMsgSalario = 'Salario aprobado';
                    this.colorMsgSalario = 'success--text';

                }
            } else {
                this.textoMsgSalario = 'No se encontraron puestos con equidad salarial';
                this.colorMsgSalario = 'primary--text';
            }
        },

        async getDataEmpleado() {
            switch (this.tipoOE) {
                case 1:
                    {
                        this.personalOfertas = await this.getCandidatosExternosEnOferta();
                        break;
                    }
                case 2:
                    {
                        this.personalOfertas = await this.getCandidatosActivosEnOferta();
                        break;
                    }
                case 3:
                    {
                        this.personalOfertas = await this.getCandidatosActivosEnOferta();
                        break;
                    }
                case 4:
                    {
                        this.personalOfertas = await this.getCandidatosActivosEnOferta();
                        break;
                    }
                case 5:
                    {
                        this.personalOfertas = await this.getCandidatosActivosEnOferta();
                        break;
                    }
                case 6:
                    {
                        this.personalOfertas = await this.getCandidatosInternosEnOferta();
                        break;
                    }
            }
        },

        async getCandidatosExternosEnOferta() {
            if (this.personalExternos.length > 0) {
                this.loadingTipo = false
                return this.personalExternos;
            } else {
                const params = {
                    Opcion: 9,
                    token: localStorage.getItem('token')
                }
                return new Promise((resolve, reject) => {
                    axios.post(`${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`, params)
                        .then(response => {
                            if (response.data.status == "EXPRESSION") {
                                deleteToken()
                            } else {
                                this.loadingTipo = false
                                resolve(response.data.personal);
                            }
                        }).catch(error => {
                            reject(error)
                        })
                })
            }
        },
        async getCandidatosInternosEnOferta() {
            if (this.personalInternos.length > 0) {
                this.loadingTipo = false;
                return this.personalInternos;
            } else {
                const params = {
                    Opcion: 10,
                    token: localStorage.getItem('token')
                }
                return new Promise((resolve, reject) => {
                    axios.post(`${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`, params)
                        .then(response => {
                            if (response.data.status == "EXPRESSION") {
                                deleteToken()
                            } else {
                                this.loadingTipo = false;
                                resolve(response.data.personal);
                            }
                        }).catch(error => {
                            reject(error)
                        })
                })
            }
        },
        async getCandidatosActivosEnOferta() {
            if (this.personalActivos.length > 0) {
                this.loadingTipo = false;
                return this.personalActivos;
            } else {
                const params = {
                    Opcion: 11,
                    token: localStorage.getItem('token')
                }

                return new Promise((resolve, reject) => {
                    axios.post(`${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`, params)
                        .then(response => {
                            if (response.data.status == "EXPRESSION") {
                                deleteToken()
                            } else {
                                this.loadingTipo = false;
                                resolve(response.data.personal);
                            }
                        }).catch(error => {
                            reject(error);
                        });
                })
            }
        },

        buscaApegoPlanDeDesarrolloPorTipoDeOferta() {
            let findEmpleado = this.personalOfertas.filter((e) => {
                return e.EMPLID == this.empleado
            })
            if (findEmpleado.length > 0) {
                if ('Apegoperfil' in findEmpleado[0]) {
                    if (findEmpleado[0].Apegoperfil != null) {
                        this.apegoPerfil = findEmpleado[0].Apegoperfil
                        if (findEmpleado[0].Apegoperfil < 80 && findEmpleado[0].Tipo == 2) {
                            this.planDesarrollo = true;
                            this.reajustes = true;
                        } else {
                            this.planDesarrollo = false;
                            this.reajustes = false;
                        }
                    } else {
                        this.planDesarrollo = false;
                        this.reajustes = false;
                    }
                } else {
                    this.planDesarrollo = false;
                    this.reajustes = false;
                }
                if ('sMensual' in findEmpleado[0]) {
                    // console.log(JSON.stringify(findEmpleado[0], null, 2))

                    this.salario = parseFloat(findEmpleado[0].sMensual)
                    this.salarioMensualActual = parseFloat(findEmpleado[0].sMensual)
                    this.jobcodeAnt = parseFloat(findEmpleado[0].EMP_KEYPUE)
                }
            } else {
                this.planDesarrollo = false;
                this.reajustes = false;
            }
        },
        formatMXN(cadena) {
            return cadena.toLocaleString("es-MX", {
                style: "currency",
                currency: "MXN",
            });
        },

        async generaOferta() {

            if (this.$refs.formOfertaEconomica.validate()) {
                let oferta = [];
                oferta = await this.getTablasDeOfertaDeSueldos();
                const filas = oferta.length;
                const columnas = oferta[0].length;
                this.ofertaEconomicaResultados.length = 0;
                if (!this.reajustes) {
                    this.numReajustes = 0;
                }
                let matriz = [];
                let sumatorias = [];
                sumatorias[0] = [];
                for (let i = 0; i < columnas; i++) {
                    matriz[i] = [];
                    for (let j = 0; j < filas; j++) {
                        matriz[i][j] = {
                            data: oferta[j][i],
                            position: {
                                x: i,
                                y: j
                            }
                        };
                        // Inicializar sumatorias[0][j] como objeto si aún no existe
                        if (!sumatorias[0][j]) {
                            sumatorias[0][j] = {
                                mensual: 0,
                                anual: 0
                            };
                            console.log("entra.");
                        }

                        // Solo sumar si es un número flotante
                        if (typeof matriz[i][j].data.mensual === 'number') {
                            sumatorias[0][j].mensual += matriz[i][j].data.mensual;
                        }

                        if (typeof matriz[i][j].data.anual === 'number') {
                            sumatorias[0][j].anual += matriz[i][j].data.anual;
                        }
                    }

                }
                // let ultimaFila = matriz.length
                let listaAux = [];

                for (let j = 0; j < filas; j++) {
                    listaAux.push({
                        data: {
                            desc: "Totales",
                            monto: 0,
                            mensual: sumatorias[0][j].mensual,
                            anual: sumatorias[0][j].anual
                        },
                        position: {
                            x: matriz.length,
                            y: j
                        }
                    })
                }
                matriz.push(listaAux)
                this.loadingGeneraOferta = false;
                this.ofertaEconomicaResultados = matriz;
            } else {
                this.muestraSnackBar("Hay errores", "pink darken-3");
                this.loadingGeneraOferta = false;
            }
        },
        async getTablasDeOfertaDeSueldos() {
            let tabla = []
            let salarioDiarioAnterior = 0;
            if (this.tipoOE != 1) {
                salarioDiarioAnterior = await this.getSalarioDiario();
            }
            let salarioDiario = (this.salario * 12) / 365
            if ([1].includes(this.tipoOE)) {
                // console.log(" reajustes: " + this.reajustes + " porcentajeIncremento1: " + this.porcentajeIncremento1 + " porcentajeIncremento2: " + this.porcentajeIncremento2)
                if (!this.reajustes) {
                    let actual = await this.generaTabla(salarioDiario, this.tipoOE)
                    let inicio = await this.generaTabla(salarioDiario, null)
                    tabla.push(actual)
                    tabla.push(inicio)
                } else {
                    if (this.porcentajeIncremento1 != 0 && this.porcentajeIncremento2 != 0) {
                        let ingreso = await this.generaTabla(salarioDiario * (this.porcentajeIncremento1 / 100), this.tipoOE)
                        let planta = await this.generaTabla(salarioDiario * (this.porcentajeIncremento2 / 100), null)
                        let seisMeses = await this.generaTabla(salarioDiario, null)
                        tabla.push(ingreso)
                        tabla.push(planta)
                        tabla.push(seisMeses)
                    } else if (this.porcentajeIncremento1 != 0 && this.porcentajeIncremento2 == 0) {
                        let ingreso = await this.generaTabla(salarioDiario * (this.porcentajeIncremento1 / 100), this.tipoOE)
                        let planta = await this.generaTabla(salarioDiario, null)
                        tabla.push(ingreso)
                        tabla.push(planta)
                    } else {
                        let ingreso = await this.generaTabla(salarioDiario, this.tipoOE)
                        let planta = await this.generaTabla(salarioDiario, null)
                        tabla.push(ingreso)
                        tabla.push(planta)
                    }
                }
            } else {
                if (!this.reajustes) {
                    let actual = await this.generaTabla(salarioDiarioAnterior, null)
                    let inicio = await this.generaTabla(salarioDiario, null)
                    tabla.push(actual)
                    tabla.push(inicio)
                } else {
                    if (this.porcentajeIncremento1 != 0 && this.porcentajeIncremento2 != 0) {
                        let actual = await this.generaTabla(salarioDiarioAnterior, null)
                        let inicio = await this.generaTabla(salarioDiario * (this.porcentajeIncremento1 / 100), null)
                        let tresMeses = await this.generaTabla(salarioDiario * (this.porcentajeIncremento2 / 100), null)
                        let seisMeses = await this.generaTabla(salarioDiario, null)
                        tabla.push(actual)
                        tabla.push(inicio)
                        tabla.push(tresMeses)
                        tabla.push(seisMeses)
                    } else if (this.porcentajeIncremento1 != 0 && this.porcentajeIncremento2 == 0) {
                        let actual = await this.generaTabla(salarioDiarioAnterior, null)
                        let inicio = await this.generaTabla(salarioDiario * (this.porcentajeIncremento1 / 100), null)
                        let tresMeses = await this.generaTabla(salarioDiario, null)
                        tabla.push(actual)
                        tabla.push(inicio)
                        tabla.push(tresMeses)
                    } else {
                        let actual = await this.generaTabla(salarioDiarioAnterior, null)
                        let inicio = await this.generaTabla(salarioDiario, null)
                        tabla.push(actual)
                        tabla.push(inicio)
                    }
                }
            }
            return tabla;
        },
        async generaTabla(sueldo, tipo) {

            let listaPrestaciones = []
            const porcentajePrestacion = 0.065;
            let diasAguinaldo = 20;
            let vacaciones = 12
            let primaVacacional = 0.9;
            // let seguroVida = 365;

            if (tipo == 1) {
                primaVacacional = 0.25;
                diasAguinaldo = 15;
            }

            if (this.rangoSelected.length > 0 && tipo != 1) {
                diasAguinaldo = this.rangoSelected[0].Aguinaldo;
                primaVacacional = this.rangoSelected[0].Prima_vacacional / 100;
                // seguroVida = this.rangoSelected[0].Seguro_vida;
            }

            if (tipo != 1)
                vacaciones = await this.getDiasVacaciones();


            if (vacaciones == undefined) {
                vacaciones = 12
            }

            listaPrestaciones.push({
                desc: "Sueldo diario",
                monto: sueldo,
                mensual: (sueldo * 365) / 12,
                anual: (sueldo * 365)
            })
            if (tipo == 1) {
                listaPrestaciones.push({
                    desc: "Fondo de ahorro",
                    monto: 0,
                    mensual: 0,
                    anual: 0
                })
            } else {
                listaPrestaciones.push({
                    desc: "Fondo de ahorro",
                    monto: porcentajePrestacion * 100,
                    mensual: ((sueldo * porcentajePrestacion) * 365) / 12,
                    anual: (sueldo * porcentajePrestacion) * 365
                })
            }
            listaPrestaciones.push({
                desc: "Vales de despensa",
                monto: porcentajePrestacion * 100,
                mensual: ((sueldo * porcentajePrestacion) * 365) / 12,
                anual: (sueldo * porcentajePrestacion) * 365
            })
            listaPrestaciones.push({
                desc: "Aguinaldo",
                monto: diasAguinaldo,
                mensual: (sueldo * diasAguinaldo) / 12,
                anual: sueldo * diasAguinaldo
            })
            listaPrestaciones.push({
                desc: "Vacaciones",
                monto: vacaciones,
                mensual: sueldo * (vacaciones / 12),
                anual: (sueldo * (vacaciones / 12)) * 12
            })
            listaPrestaciones.push({
                desc: "Prima vacacional",
                monto: (primaVacacional * 100).toString() + '%',
                mensual: (sueldo * (vacaciones / 12)) * primaVacacional,
                anual: ((sueldo * (vacaciones / 12)) * 12) * primaVacacional
            })

            if (this.rangoSelected.length > 0 && this.rangoSelected[0].Automovil != "N/A" && tipo != 1) {
                listaPrestaciones.push({
                    desc: "Automóvil",
                    monto: true,
                    mensual: true,
                    anual: true
                })
            } else {
                listaPrestaciones.push({
                    desc: "Automóvil",
                    monto: false,
                    mensual: false,
                    anual: false
                })
            }


            if (tipo == 1) {
                listaPrestaciones.push({
                    desc: "Bono de desempeño",
                    monto: false,
                    mensual: false,
                    anual: false
                })
            } else {
                listaPrestaciones.push({
                    desc: "Bono de desempeño",
                    monto: true,
                    mensual: true,
                    anual: true
                })
            }

            if (this.rangoSelected.length > 0 && this.rangoSelected[0].Revision_medica != "N/A" && tipo != 1) {
                listaPrestaciones.push({
                    desc: "Revisión médica",
                    monto: true,
                    mensual: true,
                    anual: true
                })
            } else {
                listaPrestaciones.push({
                    desc: "Revisión médica",
                    monto: false,
                    mensual: false,
                    anual: false
                })
            }

            listaPrestaciones.push({
                desc: "Seguro de vida",
                monto: true,
                mensual: true,
                anual: true
            })

            if (this.rangoSelected.length > 0 && this.rangoSelected[0].Gastos_medicos > 0 && tipo != 1) {
                console.log(this.rangoSelected[0].Gastos_medicos)
                listaPrestaciones.push({
                    desc: "Seguro GMM",
                    monto: this.rangoSelected[0].Gastos_medicos + "%",
                    mensual: this.rangoSelected[0].Gastos_medicos + "%",
                    anual: this.rangoSelected[0].Gastos_medicos + "%"
                })
            } else {
                console.log(this.rangoSelected.length > 0 && this.rangoSelected[0].Gastos_medicos > 0 && tipo != 1)
                listaPrestaciones.push({
                    desc: "Seguro GMM",
                    monto: false,
                    mensual: false,
                    anual: false
                })
            }

            return listaPrestaciones;
        },

        cambiaValorBoolPrestacion(coordenada) {
            this.ofertaEconomicaResultados[coordenada.x][coordenada.y].data.monto = !this.ofertaEconomicaResultados[coordenada.x][coordenada.y].data.monto
            this.ofertaEconomicaResultados[coordenada.x][coordenada.y].data.mensual = !this.ofertaEconomicaResultados[coordenada.x][coordenada.y].data.mensual
            this.ofertaEconomicaResultados[coordenada.x][coordenada.y].data.anual = !this.ofertaEconomicaResultados[coordenada.x][coordenada.y].data.anual
        },

        async getDiasVacaciones() {
            if (this.tipoOE != 1) {
                // let empleadoAux = await this.findEmployee();
                const params = {
                    Opcion: 12,
                    token: localStorage.getItem('token'),
                    empleado: this.empleado
                }
                return new Promise((resolve, reject) => {
                    axios.post(`${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`, params)
                        .then(response => {
                            if (response.data.status == "EXPRESSION") {
                                deleteToken()
                            } else {
                                if (response.data.status == "OK") {
                                    resolve(response.data.resultado);
                                } else {
                                    reject(12)
                                }
                            }
                        }).catch(error => {
                            reject(error)
                        })
                })

            }
        },

        async getSalarioDiario() {
            if (this.tipoOE != 1) {
                // let empleadoAux = await this.findEmployee();

                const params = {
                    Opcion: 13,
                    token: localStorage.getItem('token'),
                    empleado: this.empleado
                }
                return new Promise((resolve, reject) => {
                    axios.post(`${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`, params)
                        .then(response => {
                            if (response.data.status == "EXPRESSION") {
                                deleteToken()
                            } else {
                                if (response.data.status == "OK") {
                                    resolve(response.data.resultado);
                                } else {
                                    reject(1)
                                }
                            }
                        }).catch(error => {
                            reject(error)
                        })
                })

            }
        },

        async findEmployee() {
            let findColaborador = []
            return new Promise((resolve, reject) => {
                findColaborador = this.personalOfertas.filter(p => {
                    return p.Nombre == this.empleado;
                })
                if (findColaborador.length > 0) {
                    resolve(findColaborador[0].EMPLID)
                } else {
                    reject(-1)
                }
            })

        },

        esBoolean(variable) {
            return typeof variable === 'boolean';
        },

        getFlujoAutorizacion() {

            let empeladoAux = this.personalOfertas.filter(e => {
                return e.EMPLID === this.empleado
            });
            empeladoAux.sort((a, b) => b.id - a.id)

            const params = {
                Opcion: 15,
                token: localStorage.getItem('token'),
                jobcode: this.puestoUrrea,
                empleado: empeladoAux[0],
                tipoOE: this.tipoOE
            }
            axios.post(`${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`, params)
                .then(response => {
                    this.loadingFlujo = false;
                    if (response.data.status == "EXPRESSION") {
                        deleteToken()
                    } else {
                        if (response.data.status == "OK") {
                            this.flujo = response.data.flujo;
                            this.muestraFlujoAutorizaciones = true;
                        }
                    }
                }).catch(error => {
                    this.muestraSnackBar(error, "pink darken-3");
                })
        },

        async setOferta(flujo) {
            if (flujo == null) {
                this.muestraFlujoAutorizaciones = false;
            } else {
                this.loadingDialog = true;
                let opcion = !isNaN(parseInt(this.$route.params.idOferta)) ? 24 : 16;
                let empleadoFind = await new Promise((resolve) => {
                    resolve(this.personalOfertas.filter(p => {
                        return p.EMPLID == this.empleado;
                    }))
                })
                let rangoSeleccionado = {};
                if (this.rangos.length > 0) {
                    rangoSeleccionado = this.rangoSelected;
                }
                this.rangosDisponibles = this.rangos.filter((rango) => {
                    return parseFloat(this.salario) >= rango.Minimo && parseFloat(this.salario) <= rango.Maximo
                })
                if (!this.reajustes) {
                    this.numReajustes = 0;
                }

                const params = {
                    Opcion: opcion,
                    token: localStorage.getItem('token'),
                    flujo: flujo,
                    empleado: empleadoFind,
                    reajuste: this.numReajustes,
                    salario: this.salario,
                    porcentajeIncremento1: this.porcentajeIncremento1,
                    porcentajeIncremento2: this.porcentajeIncremento2,
                    rangoSeleccionado: rangoSeleccionado,
                    rangoCalculado: this.rangosDisponibles[0],
                    automovil: this.ofertaEconomicaResultados[6],
                    gastosMedicosMayores: this.ofertaEconomicaResultados[10],
                    jobcode: this.puestoUrrea,
                    mercer: this.puestoMercer,
                    tipoOE: this.tipoOE,
                    area: this.area,
                    departamento: this.departamento,
                    apego: this.apegoPerfil,
                    comisionNueva: this.comisionNueva,
                    comisionActual: this.comisionActual,
                    planDesarrollo: this.planDesarrollo,
                    idOferta: this.$route.params.idOferta,
                    tieneAclaracion: this.tieneAclaracion,
                    aclaracion: this.aclaracion,
                    tieneComentariosPlanDesarrollo: this.muestraComentariosPlanDeDesarrollo,
                    comentariosPlanDeDesarrollo: this.comentariosPlanDeDesarrollo,
                    esCalzada: this.esCalzada,
                    noEsCalzada: this.noEsCalzada,
                    salarioMensualActual: opcion === 16 ? this.salarioMensualActual : 0.00,
                    jobcodeAnt: this.jobcodeAnt
                }

                axios.post(`${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`, params).then((response) => {
                    if (response.data.status == 'EXPRESSION') {
                        deleteToken();
                    } else {
                        this.ofertaEconomicaResultados.length = 0;
                        this.comentariosPlanDeDesarrollo.length = 0;
                        this.muestraComentariosPlanDeDesarrollo = false;
                        this.rangos.length = 0;
                        this.muestraRangos = false;
                        this.showTable = false;
                        this.reajuste = false;
                        this.numReajustes = '';
                        this.esCalzada = false;
                        this.noEsCalzada = false;
                        this.muestraFlujoAutorizaciones = false;
                        this.$refs.formOfertaEconomica.reset();
                        this.$refs.formOfertaEconomica.resetValidation();
                        if (response.data.status == "OK") {
                            if (response.data.respuesta.estatus_tran == "OK") {
                                this.muestraSnackBar(response.data.respuesta.desc, "teal darken-1")
                            } else {
                                this.muestraSnackBar(response.data.respuesta.desc, "pink darken-2")
                            }
                        } else {
                            this.muestraSnackBar("Ocurrió un error.", "pink darken-2")
                        }
                    }
                }).catch(error => {
                    this.muestraSnackBar(error, "pink darken-2")
                    console.log(error)
                }).finally(() => {
                    this.loadingDialog = false;
                })
            }
        },

        closeLoading() {
            this.loadingDialog = false;
        },

        openDialogEditSalary(ofertaColumna) {
            this.montoEdited = ofertaColumna.data.mensual;
            this.positionEditing = ofertaColumna.position;
            this.muestraDialogActualizaSalario = true;
        },

        async closeDialogEditSalary(salary) {
            if (salary == null) {
                this.muestraDialogActualizaSalario = false;
            } else {
                console.log(this.positionEditing.y)
                const porcentajeFondo = 0.065;
                let faDiario = ((salary * 12) / 365) * porcentajeFondo;
                let salarioDiario = (salary * 12) / 365;
                let faMensual = 0;
                let faAnual = 0;
                let valesMensual = 0;
                let valesAnual = 0;
                let diasAguinaldo = 20;
                let vacaciones = 12;
                let primaVacacional = 0.9;

                if (this.tipoOE != 1)
                    vacaciones = await this.getDiasVacaciones();
                if (vacaciones == undefined) {
                    vacaciones = 12
                }

                if (this.rangoSelected.length > 0 && this.tipoOE != 1) {
                    diasAguinaldo = this.rangoSelected[0].Aguinaldo;
                    primaVacacional = this.rangoSelected[0].Prima_vacacional / 100;
                    // seguroVida = this.rangoSelected[0].Seguro_vida;
                }


                if (faDiario > this.uma * 1.3) {
                    faDiario = this.uma * 1.3;
                    faMensual = ((this.uma * 1.3) * 365) / 12;
                    faAnual = ((this.uma * 1.3) * 365);
                } else {
                    faMensual = (faDiario * 365) / 12;
                    faAnual = (faDiario * 365);
                }

                if (faDiario > this.uma) {
                    valesMensual = this.uma * 30.4
                    valesAnual = (this.uma * 30.4) * 12
                } else {
                    valesAnual = (salarioDiario * porcentajeFondo) * 365
                    valesMensual = ((salarioDiario * porcentajeFondo) * 365) / 12
                }


                this.ofertaEconomicaResultados[this.positionEditing.x][this.positionEditing.y].data.monto = (salary * 12) / 365;
                this.ofertaEconomicaResultados[this.positionEditing.x][this.positionEditing.y].data.mensual = parseFloat(salary.trim());
                this.ofertaEconomicaResultados[this.positionEditing.x][this.positionEditing.y].data.anual = salary * 12;
                if (this.ofertaEconomicaResultados[this.positionEditing.x + 1][this.positionEditing.y].data.monto > 0) {
                    this.ofertaEconomicaResultados[this.positionEditing.x + 1][this.positionEditing.y].data.mensual = faMensual;
                    this.ofertaEconomicaResultados[this.positionEditing.x + 1][this.positionEditing.y].data.anual = faAnual;
                }
                this.ofertaEconomicaResultados[this.positionEditing.x + 2][this.positionEditing.y].data.mensual = valesMensual;
                this.ofertaEconomicaResultados[this.positionEditing.x + 2][this.positionEditing.y].data.anual = valesAnual;
                // aguinaldo
                this.ofertaEconomicaResultados[this.positionEditing.x + 3][this.positionEditing.y].data.mensual = (salarioDiario * diasAguinaldo) / 12;
                this.ofertaEconomicaResultados[this.positionEditing.x + 3][this.positionEditing.y].data.anual = salarioDiario * diasAguinaldo;
                // vacaciones
                this.ofertaEconomicaResultados[this.positionEditing.x + 4][this.positionEditing.y].data.mensual = salarioDiario * (vacaciones / 12);
                this.ofertaEconomicaResultados[this.positionEditing.x + 4][this.positionEditing.y].data.anual = (salarioDiario * (vacaciones / 12)) * 12;
                // prima vacacional
                this.ofertaEconomicaResultados[this.positionEditing.x + 5][this.positionEditing.y].data.mensual = (salarioDiario * (vacaciones / 12)) * primaVacacional;
                this.ofertaEconomicaResultados[this.positionEditing.x + 5][this.positionEditing.y].data.anual = ((salarioDiario * (vacaciones / 12)) * 12) * primaVacacional;

                // recalculo de salarios
                if (this.positionEditing.y == 0 && this.porcentajeIncremento1 == 0 && this.porcentajeIncremento2 == 0) {
                    this.salario = salary;
                } else if (this.positionEditing.y == 1 && this.porcentajeIncremento1 == 0 && this.porcentajeIncremento2 == 0 && this.tipoOE == 1) {
                    this.porcentajeIncremento1 = (salary * 100) / this.salario
                    this.salario = salary;
                    this.reajustes = true;
                } else if (this.positionEditing.y == 0 && this.porcentajeIncremento1 != 0 && this.porcentajeIncremento2 == 0 && this.tipoOE == 1) {
                    this.porcentajeIncremento1 = (salary * 100) / this.salario
                } else if (this.positionEditing.y == 1 && this.porcentajeIncremento1 != 0 && this.porcentajeIncremento2 == 0 && this.tipoOE != 1) {
                    this.porcentajeIncremento1 = (salary * 100) / this.salario
                } else if (this.positionEditing.y == 2 && this.porcentajeIncremento1 != 0 && this.porcentajeIncremento2 != 0 && this.tipoOE != 1) {
                    this.porcentajeIncremento2 = (salary * 100) / this.salario
                } else if (this.positionEditing.y == 3 && this.porcentajeIncremento1 != 0 && this.porcentajeIncremento2 != 0) {
                    this.salario = salary
                }


                if (this.positionEditing.y == this.ofertaEconomicaResultados[this.positionEditing.x].length) {
                    this.salario = salary;
                }
                this.muestraDialogActualizaSalario = false;
            }
        },

        getColorSD(position) {
            if (position.y > 0 && (this.ofertaEconomicaResultados[position.x][position.y].data.monto - 1 <= this.ofertaEconomicaResultados[position.x][position.y - 1].data.monto)) {
                return 'pink darken-4'
            } else {
                return 'blue darken-4'
            }
        }
    }
}