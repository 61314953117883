<template>
  <v-dialog v-model="generaEntrega" persistent max-width="90%" scrollable>
    <v-card class="rounded-lg">

      <v-card-title class="primary">
        <div class="overline font-weight-bold white--text">
          {{ tituloModal }}
        </div>
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraEntregaMercancia', cambios)"
          >mdi-close</v-icon
        >
      </v-card-title>

      <v-card-text class="pt-5 blue-grey lighten-5">
        <v-sheet class="rounded-0 elevation-1" min-height="400" width="100%">
          <div class="pa-5">
            <v-img
              :src="require('@/assets/img/grupo_urrea_azul_gris.png')"
              max-height="70"
              width="250"
            ></v-img>
          </div>
          <v-form ref="formEntregaMercancia">
            <v-row dense class="px-5">
              <v-col cols="12" md="6" v-if="!esEdicion">
                <v-autocomplete
                  v-model="usuarioSeleccionado"
                  class="rounded-lg"
                  counter="180"
                  dense
                  outlined
                  label="Nombre empleado"
                  :items="colaboradores"
                  item-text="nombreMostrar"
                  :loading="loadingColaboradores"
                  v-on:change="llenaCampos"
                  :rules="rulesTextField"
                  :readonly="soloLecturaUsuario"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" v-else>
                <v-text-field
                  v-model="usuarioSeleccionado"
                  class="rounded-lg"
                  dense
                  outlined
                  label="Nombre"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>

            <div class="px-5 mb-3">
              <div style="display:flex; justify-content: start; align-items: center;" class="py-2">
                <label class="overline"><strong>Material a entregar:</strong></label>
              </div>
              <v-simple-table dense>
                <thead>
                  <tr class="primary">
                    <th class="overline white--text text-center">Material</th>
                    <th class="overline white--text text-center">Cantidad</th>
                    <th class="overline white--text text-center">Unidad de medida</th>
                    <th class="overline white--text text-center">Descripción</th>
                    <th class="overline white--text text-center">Checklist</th>
                    <th class="overline white--text text-center">Comentarios adicionales</th>
                    <th class="overline white--text text-center">Entregar a</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(material, index) in materiales" :key="index">
                    <td style="border: 0.7px solid #A8A8A7; max-width: 150px;">
                      <v-autocomplete
                        v-model="material.material"
                        class="rounded-lg"
                        dense
                        outlined
                        :items="materialesEntregar"
                        item-text="NombreMaterial"
                        placeholder="Selecciona..."
                        :rules="rulesTextField"
                        style="max-height: 42px !important;"
                        v-on:change="getItemsXMaterial(material);"
                      ></v-autocomplete>
                    </td>
                    <td style="border: 0.7px solid #A8A8A7; max-width: 7px;">
                      <input
                        type="number"
                        :min="1"
                        v-model="material.cantidad"
                        style="width: 100%; text-align: center;"
                        @input="validarCantidad(material)"
                      />
                    </td>
                    <td style="border: 0.7px solid #A8A8A7; max-width: 150px;">
                      <v-select
                        v-model="material.unidadMedida"
                        class="rounded-lg"
                        dense
                        outlined
                        :items="unidadesDeMedida"
                        item-text="descripcion"
                        placeholder="Selecciona..."
                        value="id"
                        :loading="loadingUnidadesDeMedida"
                        :rules="rulesTextField"
                        style="max-height: 42px !important;"
                      ></v-select>
                    </td>
                    <td style="border: 0.7px solid #A8A8A7;">
                      <textarea v-model="material.descripcion" rows="3" style="width: 100%;"></textarea>
                    </td>
                    <td style="border: 0.7px solid #A8A8A7;">
                      <div v-if="material.checklistItems">
                        <ul class="text-left">
                          <li v-for="(item, itemIndex) in material.checklistItems" :key="itemIndex">
                            {{ item.nombreItem }}
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td style="border: 0.7px solid #A8A8A7;">
                      <textarea v-model="material.mascomentarios" rows="3" style="width: 100%;"></textarea>
                    </td>
                    <td style="border: 0.7px solid #A8A8A7; max-width: 150px;">
                      <v-autocomplete
                        v-model="material.quienRecibe"
                        class="rounded-lg"
                        dense
                        outlined
                        :items="recibenMaterial"
                        item-text="Nombre"
                        item-value="emplid"
                        placeholder="Selecciona..."
                        :rules="rulesRecibeField"
                        style="max-height: 42px !important;"
                      ></v-autocomplete>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
            <div class="text-right px-6 mb-6">
              <v-btn
                v-show="materiales.length > 1"
                fab
                x-small
                class="mx-1 elevation-1"
                dark
                color="pink darken-3"
                @click="materiales.pop()"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <v-btn
                v-show="materiales.length < 10"
                fab
                x-small
                class="mx-1 elevation-1"
                dark
                color="teal darken-1"
                @click="addMaterial"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
            <v-row class="px-5"></v-row>
          </v-form>
        </v-sheet>
      </v-card-text>
      <v-card-actions class="center-item-in-div">
        <v-btn
          class="rounded-lg"
          x-large
          color="teal darken-1"
          :loading="loadingBtnSavePasse"
          dark
          @click="loadingBtnSavePasse = true; guardar();"
        >
          <v-icon left>mdi-text-box-plus</v-icon>
          {{ tituloBoton }}
        </v-btn>
      </v-card-actions>
      <SnackBarErrorIncidencias v-if="muestraBarra" :muestraBarra="muestraBarra" :color="colorBarra" :texto="textoBarra" @cierraBarra="muestraBarra = false"/>
    </v-card>
    <v-overlay
      v-model="dialogCargando"
      contained
      class="align-center justify-center"
      width="auto"
    >
      <v-card title="Cargando...">
        <v-card-text>
          <v-label><v-progress-circular indeterminate></v-progress-circular></v-label>
        </v-card-text>
      </v-card>
    </v-overlay>

  </v-dialog>

</template>

<script>
import { deleteToken } from "../../../../../../store/Funciones/funciones.js";
import axios from "axios";
import SnackBarErrorIncidencias from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "generacion-de-entrega-mercancia",
  props: ["generaEntrega","IdSolicitud"],
  components: { SnackBarErrorIncidencias },
  data() {
    return {
      tituloModal: "solicitud de entrega de mercancía",
      tituloBoton: "crear solicitud de entrega de mercancía",
      cambios: 0,
      materiales: [],
      itemsDMateriales: [],
      colaboradores: [],
      unidadesDeMedida: [],
      loadingUnidadesDeMedida: true,

      dialogCargando: true,
      materialesEntregar: [],
      recibenMaterial: [],

      rulesTextField: [
        (v) => !!v || "Este campo es requiredo.",
        (v) =>
          (v && v.length <= 180) ||
          "Este campo no debe tener más de 180 caracteres",
      ],
      rulesRecibeField: [
        (v) => !!v || "Este campo es requerido.",
      ],
      unidadSeleccionada: null,

      loadingColaboradores: true,
      soloLecturaUsuario: false,
      usuarioSeleccionado: "",
      usuarioFolio: "",
      usuarioNombre: "",

      loadingBtnSavePasse: false,
      muestraBarra: false,
      esEdicion: false,
    };
  },

  methods: {

    editarSolicitud() {
      const headers = {
        Authorization: localStorage.getItem("token")
      };
      console.log(localStorage);
      const params = {
        usuarioMovimiento: localStorage.getItem("folio"),
        IdSolicitud: this.IdSolicitud
      };
      return new Promise((res) => {
        axios
        .get(`${this.$store.getters.getHostNode}/api/detalle-solicitud_mercancia`,
        {
          headers: headers,
          params
        }).then((response) => {
          console.log("editarRolUsuario =>",response);
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            res(response.data.respuesta);
            // Limpia materiales para llenar con los datos recibidos
            this.materiales = [];

            response.data.respuesta.forEach((item) => {
              this.materiales.push({
                material        : item.material,
                idMaterial      : item.idMaterial,
                cantidad        : item.cantidad,
                unidadMedida    : item.unidadMedida,
                descripcion     : item.descripcion,
                checklistItems  : item.checklistItems,
                mascomentarios  : item.mascomentarios,
                quienRecibe     : item.quienRecibe,
                selectedChecklistItems: []
              });
            }); // forEachMAterial

            this.usuarioSeleccionado = response.data.nombreMostrar;
          } else {
            res([]);
          }
        }).finally(() => {
          this.loadingMateriales = false;
        });
      });
    }, // editarSolicitud

    getColaboradores() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-all-colaboradores`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.colaboradores);
            }
          })
          .finally(() => {
            this.loadingColaboradores = false;
          });
      });
    },
    llenaCampos() {
      let colaboradorSeleccionado = this.colaboradores.filter((e) => {
        return e.nombreMostrar === this.usuarioSeleccionado;
      });
      if (colaboradorSeleccionado.length > 0) {
        this.usuarioFolio = colaboradorSeleccionado[0].EMPLID;
        this.usuarioNombre = colaboradorSeleccionado[0].NAME;
      }
    },

    getMaterialesItems() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/read-material-items`, {
            headers: headers,
          })
          .then((response) => {
            console.log("getMaterialesItems", response);
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.materialListado);
            }
          })
          .finally(() => {
            // this.loadingColaboradores = false;
          });
      });
    }, // getMaterialesItems
    getListadoDItems() {
      const objetoItems = {};
      for (const sacarItems of this.materialesEntregar) {
        // objetoItems[sacarItems.NombreMaterial] = sacarItems.items;
        objetoItems[sacarItems.NombreMaterial] = {
          IdMaterial: sacarItems.IdMaterial,
          items: sacarItems.items
        }
      } // for...of
      return objetoItems;
    }, // getListadoDItems

    getUnidadesDeMedida() {
      const params = {
        Opcion: 17,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
            params
          )
          .then((response) => {
            this.loadingData = false;
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              reject([]);
            }
          })
          .catch((e) => {
            this.openSnackBar(e);
          })
          .finally(() => {
            this.loadingUnidadesDeMedida = false;
          });
      });
    }, // getUnidadesDeMedida

    getRecibenMaterial() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-reciben-material`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.receptores);
            }
          })
          .finally(() => {
            // this.loadingColaboradores = false;
          });
      });
    }, //getRecibenMaterial() {

    guardar() {
      if (this.$refs.formEntregaMercancia.validate()) {
        console.log("si lleno los campos requeridos");
        console.log(localStorage);
        let cadenaUri = `${this.$store.getters.getHostNode}/api/create-solicitud_mercancia`;
        if (this.IdSolicitud > 0) {
          cadenaUri = `${this.$store.getters.getHostNode}/api/update-solicitud_mercancia`;
        } // editar
        const headers = {
          Authorization: localStorage.getItem("token"),
        };
        const params = {
          usuarioMovimiento:  localStorage.getItem("folio"),
          idSolicitud:        this.IdSolicitud,
          usuarioFolio:       this.usuarioFolio,
          materiales:         this.materiales,
        };
        console.log("params =>", params);
        return new Promise((res) => {
          axios
          .post(
            cadenaUri,
            params,
            {
              headers: headers,
            }
          )
          .then((response) => {
            // this.loadingBtnSavePasse = false;
            console.log(response);
            if (response.data.status == true) {
              res(response.data);
              this.textoBarra = "Registro exitoso";
              this.colorBarra = "success";
              this.muestraBarra = true;
              // this.clean();
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve(this.$emit('cierraEntregaMercancia', 0));
                }, 1500);
              });
            } else if (response.data.status == false) {
              res(response.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "error";
              this.muestraBarra = true;
            } else if (response.data.status == "EXPRESSION") {
              // deleteToken();
              console.log("deleteToken");
            }
          });
        });
      } else {
        console.log("campos requeridos");
      }
      this.loadingBtnSavePasse = false;
    }, // guardar

    addMaterial() {
      this.materiales.push({
        material: "",
        idMaterial: 0,
        cantidad: 1,
        unidadMedida: "",
        descripcion: "",
        checklistItems: [],
        mascomentarios: "",
        quienRecibe: 0,
        selectedChecklistItems: [],
      });
    },

    getItemsXMaterial_Old(material) {
      if (this.itemsDMateriales[material.material]) {
        material.checklistItems = [...this.itemsDMateriales[material.material]];
        material.idMaterial = 0;
        material.selectedChecklistItems = []; // Reiniciar la selección
      }
    }, // getItemsXMaterial ESTE ES COMO EL METODO DE: llenaCampos()

    getItemsXMaterial(material) {
      const selectedMaterial = this.itemsDMateriales[material.material];
      if (selectedMaterial) {
        material.checklistItems = [...selectedMaterial.items]; // Extrae los items
        material.idMaterial = selectedMaterial.IdMaterial; // Extrae el IdMaterial
        material.selectedChecklistItems = []; // Reinicia la selección
      }
    }, // getItemsXMaterial ESTE ES COMO EL METODO DE: llenaCampos()

    validarCantidad(material) {
      if (material.cantidad < 0) {
        material.cantidad = 1;
      }
    }, //validarCantidad

    openSnackBar(texto, color = "pink darken-3") {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    }, // openSnackBar
  }, // methods

  async mounted() {
    console.log("generaEntrega: ", this.generaEntrega);
    console.log("IdSolicitud: ", this.IdSolicitud);
    if (this.materiales.length === 0) {
      this.addMaterial();
    }
    this.colaboradores      = await this.getColaboradores();
    this.materialesEntregar = await this.getMaterialesItems();
    this.unidadesDeMedida   = await this.getUnidadesDeMedida();
    this.recibenMaterial    = await this.getRecibenMaterial();
    this.itemsDMateriales   = await this.getListadoDItems();

    if (this.IdSolicitud > 0) {
      this.esEdicion    = true;
      this.tituloModal  = "detalle de entrega de mercancía";
      this.tituloBoton  = "editar solicitud de entrega de mercancía";
      this.editarSolicitud();
    } // editar

    this.dialogCargando     = false;
  }, // mounted

};
</script>

<style scoped>
.autocomplete {
  position: relative;
}

.autocomplete-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
}

.autocomplete-list li {
  padding: 8px;
  cursor: pointer;
}

.autocomplete-list li.highlighted {
  background-color: #f2f2f2;
}
</style>