<template>
    <v-card class="rounded-lg elevation-0" width="100%">
      <v-data-table
        :headers="headers"
        :items="rolUsuariosArray"
        :search="search"
        no-data-text="No hay registros."
        loading-text="Cargando información..."
        :loading="loadingRolUsuarios"
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
          'pageText': '{0}-{1} de {2}',
        }"
        style="background-color:#ECEFF1"
      >
        <template v-slot:top>
          <div style="display: flex; justify-content: space-between;">
            <v-text-field
              v-model="search"
              outlined dense clearable
              class="rounded-xl"
              style="max-width: 300px;"
              prepend-inner-icon="mdi-table-search"
              placeholder="Buscar..."
            ></v-text-field>
            <div class="text-center">
              <v-btn color="teal darken-1" class="rounded-lg" x-large depressed dark @click="modalCreateRolUsuario();">
                <v-icon left>mdi-plus</v-icon>agregar rol
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:item.rol="{ item }">
  <label>
    {{ getRolDescripcion(item.rol) }}
  </label>
</template>

        <template v-slot:item.estatus="{ item }">
          <v-chip small :class="getClass(item.estatus)">
            {{ item.estatus === 1 ? "Activo" : item.estatus === 2 ? "Deshabilitado" : "No definido" }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            fab depressed
            x-small
            dark
            color="blue accent-2"
            class="mr-1"
            @click="editarRolUsuario(item);"
            style="max-height: 25px !important; max-width: 25px !important;"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            fab depressed
            x-small
            dark
            color="pink darken-3"
            class="mr-1"
            @click="deshabilitarRolUsuario(item)"
            style="max-height: 25px !important; max-width: 25px !important;"
          >
            <v-icon small>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <crearRolUsuario v-if="verCrearRolUsuario" :idUsuario="eliminarUsuario" :verCrearRolUsuario="verCrearRolUsuario" @cierraCrearRolUsuario="cierraCrearRolUsuario"/>

      <v-dialog v-model="modalDeshabilitar" persistent max-width="450">
        <v-card class="rounded-lg">
          <v-card-text class="pt-6">
            <p class="font-weight-bold subtitle-1 text-center text-uppercase">
              ¿Realmente deseas borrar al usuario <strong>{{nameSelected}}</strong>?
              <br/>
              Ya no se podra usar en las solicitudes
            </p>
          </v-card-text>
          <v-card-actions style="display: flex; justify-content: space-around;">
            <v-btn style="width: 46%;" class="rounded-lg elevation-0" dark color="pink darken-3" @click="modalDeshabilitar = false" :disabled="loadingEliminando">cancelar</v-btn>
            <v-btn style="width: 46%;" class="rounded-lg elevation-0" dark color="teal darken-1" @click="loadingEliminando = true; deleteRolUsuario();" :loading="loadingEliminando">eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <SnackBar v-if="muestraBarra" :muestraBarra="muestraBarra" :texto="textoBarra" :color="colorBarra" @cierraBarra="muestraBarra = false" />
  
    </v-card>
  </template>
  
  <script>
  import axios from "axios";
  import { deleteToken } from "../../../../../../store/Funciones/funciones.js";
  import SnackBar from '../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue'
  
  import crearRolUsuario from "./RolUsuariosMercanciaAlta.vue";
  
  export default {
    name: "listado-mercancia-items",
    props: [""],
    components: { crearRolUsuario, SnackBar },
    data() {
      return {
        headers: [
          {
            text: "Nombre",
            value: "Nombre",
            class: "overline font-weight-bold primary white--text rounded-left-top-table-lg",
          },
          {
            text: "Permisos (rol)",
            value: "rol",
            class: "overline font-weight-bold primary white--text",
          },
          {
            text: "Estatus",
            value: "estatus",
            align: "center",
            class: "overline font-weight-bold primary white--text",
          },
          {
            text: "Acciones",
            value: "actions",
            align: "center",
            class: "overline font-weight-bold primary white--text",
          },
        ],
        tipoRol: [
          {codigo: "REMERCANCIA", descripcion: "APROBADOR MERCANCÍA"},
          {codigo: "CRMERCANCIA", descripcion: "APROBADOR MERCANCÍA, CREAR MATERIAL E ITEMS"},
          {codigo: "ADMERCANCIA", descripcion: "APROBADOR MERCANCÍA, CREAR MATERIAL E ITEMS, ASIGNAR PERMISOS APROBADOR"}
        ],
        verCrearRolUsuario: false,
        loadingRolUsuarios: true,
        search: null,
        rolUsuariosArray: [],
  
        modalDeshabilitar: false,
        nameSelected: "",
        loadingEliminando: false,
        eliminarUsuario: 0,
        emplid: 0,
  
        muestraBarra: false,
        textoBarra: "",
        colorBarra: "",
  
      }; // return
    }, // data
  
    methods: {
      getRolUsuarios() {
        const headers = {
          Authorization: localStorage.getItem("token"),
        };
        console.log(localStorage);
        const params = {
          usuarioMovimiento:  localStorage.getItem("folio")
        };
        return new Promise((res) => {
          axios
          .get(`${this.$store.getters.getHostNode}/api/read-rol-usuario`,
          {
            headers: headers,
            params
          })
          .then((response) => {
            console.log("getRolUsuarios",response);
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.rolUsuarios);
              this.rolUsuariosArray = response.data.rolUsuarios;
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingRolUsuarios = false;
          });
        });
      }, // getRolUsuarios

      getRolDescripcion(codigo) {
        const rol = this.tipoRol.find(rol => rol.codigo === codigo);
        return rol ? rol.descripcion : 'Rol no encontrado';
      }, // getRolDescripcion

      modalCreateRolUsuario() {
        this.eliminarUsuario = 0;
        this.verCrearRolUsuario = true;
      }, // modalCreateRolUsuario
  
      cierraCrearRolUsuario(respuesta) {
        console.log("cierraCrearRolUsuario =>", respuesta);
        this.verCrearRolUsuario = false;
        this.loadingRolUsuarios = true;
        this.getRolUsuarios();
      }, // cierraCrearRolUsuario
  
      editarRolUsuario(item) {
        console.log("editarRolUsuario =>", item);
        this.eliminarUsuario = item.id;
        this.verCrearRolUsuario = true;
      }, // editarRolUsuario
  
      deshabilitarRolUsuario(item) {
        console.log("deshabilitarRolUsuario =>", item);
        this.nameSelected = item.Nombre;
        this.eliminarUsuario = item.id;
        this.emplid = item.emplid;
        this.modalDeshabilitar = true;
      }, // deshabilitarRolUsuario
      deleteRolUsuario() {
        const headers = {
            Authorization: localStorage.getItem("token"),
          };
          const params = {
            usuarioMovimiento: localStorage.getItem("folio"),
            IdUsuario: this.eliminarUsuario,
            emplid: this.emplid
          };
          return new Promise((res) => {
            axios
            .post(
              `${this.$store.getters.getHostNode}/api/delete-rol-usuario`,
              params,
              {
                headers: headers,
              }
            )
            .then((response) => {
              console.log("deleteRolUsuario =>", response);
              this.loadingEliminando = false;
              if (response.data.status == true) {
                res(response.data);
                this.textoBarra = "Registro exitoso";
                this.colorBarra = "success";
                this.muestraBarra = true;
                this.modalDeshabilitar = false;
                this.getRolUsuarios();
              } else if (response.data.status == false) {
                res(response.data);
                this.textoBarra = response.data.message;
                this.colorBarra = "error";
                this.muestraBarra = true;
              } else if (response.data.status == "EXPRESSION") {
                console.log("deleteToken");
                deleteToken();
              }
            });
          });
      }, // deleteRolUsuario
  
      getClass(estatus) {
        if (estatus === 3) {
          return "yellow accent-4 black--text";
        } else if (estatus === 1) {
          return "teal darken-1 white--text";
        } else if (estatus === 2) {
          return "pink darken-3 white--text";
        } else {
          return "grey";
        }
      }, // getClass
    }, // methods
  
    async mounted() {
      this.getRolUsuarios();
    },
  };
  </script>
  
  <style>
  .rounded-left-top-table-lg {
    border-top-left-radius: 8px;
  }
  .rounded-right-top-table-lg {
    border-top-right-radius: 8px;
  }
  .header-material-table {
    background-color: #A8A8A7;
  }
  </style>