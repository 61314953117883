import axios from 'axios'

export default {
    name: 'puestos-lider',
    components: {},
    props: [],

    data() {
        return {
            puestos: [],
            search: '',
            accion: '',
            elementoSeleccionado: 0,
            loadingTable: true,
            headersTable: [{
                    text: 'ID',
                    align: 'start',
                    value: 'ID',
                },
                {
                    text: 'Puesto',
                    value: 'DESCR'
                },
                {
                    text: 'ID Puesto',
                    value: 'PUESTO'
                },
                {
                    text: 'Nivel',
                    value: 'NIVEL'
                },
                {
                    text: 'Acciones',
                    value: 'actions',
                    sortable: false
                },
            ],
            editedItem: {
                id: '',
                descr: '',
                puesto: '',
                nivel: '',
                estatus: ''
            },
        }
    },
    computed: {

    },
    mounted() {

        // this.enviaJobcode('00827');

    },
    created() {
        // this.enviaJobcode(['00827', '1', '2']);
        this.initialize()
    },
    methods: {
        initialize() {
            // this.loading = true;

            const params = { emplid: 8631 }

            axios.post("http://localhost:8000/api/getPuestosPorLider", params)
                .then((response) => {
                    var data = response.data.map(conocimiento => {
                        // conocimiento['Estatus_desc'] = (conocimiento['ESTATUS']) ? 'CP' : 'SP';
                        return conocimiento;
                    })
                    this.puestos = data;
                    this.loadingTable = false;
                    // //(this.puestos)
                });
        },

        enviaJobcode(puesto) {
            this.editedItem = puesto
            localStorage.setItem('puesto', JSON.stringify(this.editedItem))
            this.$router.push('/VerPuestoLider');
        }

    }
}