import PersonaObjetivosPreview from './components/PersonaObjetivosPreview.vue'
import PersonaPerfilPreview from './components/PersonaPerfilPreview.vue'
import PersonaTimeline from './components/PersonaTimeline.vue'
import PersonaNoticiasPreview from './components/PersonaNoticiasPreview.vue'
import PersonaMejorasPreview from './components/PersonaMejorasPreview.vue'
import PersonaNotificaciones from './components/PersonaNotificaciones.vue'
import PersonaMejorasProgreso from './components/PersonaMejorasProgreso.vue'
import PersonaAdministracionAutorizaciones from '../../../../components/PersonaAdministracionAutorizaciones.vue'
// import PersonaValoresPreview from './components/PersonaValoresPreview.vue'
import PersonaBotonesFinales from './components/PersonaBotonesFinales.vue'
// import PersonaAutorizarIncidencias from './components/PersonaAutorizarIncidencias.vue'
// import PersonaAutorizaVacantes from './components/PersonaAutorizaVacantes.vue'
import axios from "axios"
import SnackBar from "../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
import MiNominaAdmonAclaraciones from "../../../../components/componentsNomina/MiNominaAdmonAclaraciones.vue";
import PersonaMotivoTE from "./components/PersonaMotivoTE.vue";
import PersonaEncuestaAsignada from "./components/PersonaEncuestaAsignada.vue";
import ComunicadosInternos from "./components/ComunicadosInternos.vue";
import ComunicadosInternosMFA from "./components/ComunicadosInternosMFA.vue";
import PersonaCalendarioIncidencias from "./components/PersonaCalendarioIncidencias.vue"
import {
    deleteToken
} from '../../../../store/Funciones/funciones'
import {
    saveMonitoreo
} from '../../../../store/Funciones/funciones'

export default {
    name: 'inicio',
    components: {
        PersonaObjetivosPreview,
        PersonaPerfilPreview,
        PersonaTimeline,
        PersonaNoticiasPreview,
        PersonaMejorasPreview,
        PersonaNotificaciones,
        PersonaMejorasProgreso,
        // PersonaAutorizaVacantes,
        PersonaBotonesFinales,
        // PersonaAutorizarIncidencias,
        MiNominaAdmonAclaraciones,
        SnackBar,
        PersonaAdministracionAutorizaciones,
        PersonaMotivoTE,
        PersonaEncuestaAsignada,
        ComunicadosInternos,
        ComunicadosInternosMFA,
        PersonaCalendarioIncidencias

    },
    data: () => ({
        today: {},
        valoresDialogVisible: false,
        autorizarIncidenciasVisible: false,
        autorizarVacantesVisible: false,
        dialogCupon: false,
        loadingButtonGenCupon: false,
        muestraBarra: false,
        valoresRecibidosList: [],
        respuestaApiIncidenciasPorAutorizar: [],
        openAdmonAclaraciones: false,
        openAdmonAutorizaciones: false,
        perfil: 2,
        score: {
            retardos: 0,
            faltas: 0
        },
        mensaje: "",
        cupon: "",
        textoBarra: "",
        colorBarra: "",
        items: [{
            header: "Notificaciones"
        }],
        abrirNotificaciones: false,
        abrirAutorizaciones: true,
        componentKey: 0,
        key: 0,
        numVacantes: 0,
        numVisitas: 0,
        itteamComunicados: [15689, 19193, 19592, 19771, 11378],
        openCarousel: false,
        openCarouselMFA: false,
        imagenes: [],
        imagenesMFA: [],
    }),
    props: [],
    computed: {
        columnaLogo() {
            if (this.$vuetify.breakpoint.name == 'sm' || this.$vuetify.breakpoint.name == 'xs') {
                return 0
            } else {
                return 5
            }
        },
        columnaScore() {
            if (this.$vuetify.breakpoint.name == 'sm' || this.$vuetify.breakpoint.name == 'xs') {
                return 12
            } else {
                return 7
            }
        },
    },
    async mounted() {
        this.getCurrentDate();
        this.findAclaracionesPendientesEvaluar();
        saveMonitoreo();
        this.items = await this.findNotifications();
        this.getScore();
        this.getCompania();
    },
    methods: {
        findAclaracionesPendientesEvaluar() {
            let params = {
                Opcion: 17,
                token: localStorage.getItem('token')
            }
            axios.post(`${this.$store.getters.getHost}/nominas/ws-nominas.php`, params).then(async (response) => {
                if (response.data.status == "EXPSESSION") {
                    deleteToken();
                } else {
                    if (response.data.aclaraciones) {
                        this.openAdmonAclaraciones = true;
                    }
                }
            }).catch(function (e) {
                console.error(e)
            });

        },

        getCurrentDate() {
            const today = new Date();
            const lang = localStorage.getItem('lang');
            this.today = {
                dayLiteral: today.toLocaleDateString(lang, {
                    weekday: 'long'
                }),
                dayNumber: today.toLocaleDateString(lang, {
                    day: 'numeric'
                }),
                monthYearLiteral: today.toLocaleDateString(lang, {
                    month: 'long',
                    year: 'numeric'
                })
            }
        },

        async findIncidenciasPorAutorizar() {
            const params = {
                Opcion: 5,
                token: localStorage.getItem('token')
            }

            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `${this.$store.getters.getHost}/Incidencias/ws-incidencias-autorizacion.php`,
                        params
                    )
                    .then(async (response) => {
                        if (response.data.status == "EXPSESSION") {
                            deleteToken();
                        } else {
                            resolve(response.data.incidencias)
                        }
                    }).catch(function (e) {
                        reject(e)
                    });
            })
        },
        async findNotifications() {
            let notificaciones = []
            if (this.items.length === 0) {
                notificaciones = [{
                    header: "Notificaciones",
                }];
            }
            this.respuestaApiIncidenciasPorAutorizar = await this.findIncidenciasPorAutorizar();
            let vacantes = await this.getVacantesPendientesPorAutorizar().catch((e) => {
                console.error(e)
            })

            let visitas = await this.getVisitasPendientesPorAutorizar().catch((e) => {
                console.error(e)
            })

            if (vacantes > 0 || this.respuestaApiIncidenciasPorAutorizar > 0 || visitas > 0) {

                notificaciones.push({
                    "id": 0,
                    "color": "primary",
                    "icon": "mdi-bell",
                    "title": "Tienes procesos por autorizar",
                    "type": "",
                    "subtitle": "",
                    "action": ""
                })
                this.numVacantes = ![null, undefined, ''].includes(vacantes) ? vacantes : 0;
                this.numVisitas = ![null, undefined, ''].includes(visitas) ? visitas : 0;
                this.openAdmonAutorizaciones = true;
            }
            let notificacionesDH = await this.getNotificaciones().catch((e) => {
                console.error(e)
            });

            if (notificacionesDH.length > 0) {
                notificacionesDH.map((n) => {
                    notificaciones.push(n)
                })
            }

            this.componentKey++;

            return notificaciones
        },
        async cerrarVentanaAutorizacion() {
            this.abrirAutorizaciones = false;
            this.autorizarIncidenciasVisible = false;
            this.items.length = 0;
            this.items = await this.findNotifications()
        },
        async getVacantesPendientesPorAutorizar() {

            const params = {
                Opcion: 40,
                token: localStorage.getItem('token')
            }

            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
                        params
                    )
                    .then((response) => {
                        if (response.data.status == "EXPSESSION") {
                            deleteToken();
                        } else {
                            resolve(response.data.respuesta)
                        }
                    }).catch(error => {
                        reject(error)
                    });
            })
        },
        async getVisitasPendientesPorAutorizar() {

            const params = {
                Opcion: 55,
                token: localStorage.getItem('token')
            }

            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
                        params
                    )
                    .then((response) => {
                        if (response.data.status == "EXPSESSION") {
                            deleteToken();
                        } else if (response.data.status == "OK") {
                            resolve(response.data.respuesta)
                        } else {
                            resolve(0);
                        }
                    }).catch(error => {
                        reject(error)
                    });
            })
        },
        getCurrentCupon() {
            let params = {
                Opcion: 23,
                token: localStorage.getItem('token')
            }
            axios
                .post(
                    `${this.$store.getters.getHost}/General/ws-general.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else {
                        this.cupon = response.data.cupon
                        this.dialogCupon = true
                    }
                }).catch(function (e) {
                    console.error(e)
                    deleteToken();
                });
        },
        generaCupon() {
            let params = {
                Opcion: 24,
                token: localStorage.getItem('token')
            }
            axios
                .post(
                    `${this.$store.getters.getHost}/General/ws-general.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else if (response.data.status == "OK") {
                        this.loadingButtonGenCupon = false;
                        this.dialogCupon = false
                        this.textoBarra = "Cupón creado con éxito."
                        this.colorBarra = "success"
                        this.muestraBarra = true
                    } else {
                        this.textoBarra = "Ocurrio un error."
                        this.colorBarra = "error"
                        this.muestraBarra = true
                    }
                }).catch(function (e) {
                    console.error(e)
                    deleteToken();
                });
        },
        getScore() {
            let params = {
                Opcion: 22,
                token: localStorage.getItem('token')
            }
            axios
                .post(
                    `${this.$store.getters.getHost}/General/ws-general.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else {
                        this.score = response.data.response
                    }
                }).catch(function (e) {
                    console.error(e)
                    deleteToken();
                });
        },
        async getNotificaciones() {
            let params = {
                Opcion: 15,
                token: localStorage.getItem('token')
            }
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `${this.$store.getters.getHost}/General/ws-general.php`,
                        params
                    )
                    .then((response) => {
                        if (response.data.status == "EXPSESSION") {
                            deleteToken();
                        } else {
                            if (response.data.status != "Vacio") {
                                resolve(response.data.notificaciones)
                            } else {
                                resolve([])
                            }
                        }
                    }).catch(e => {
                        reject(e)
                    })
            })

        },
        getCompania() {
            let paramsEstatusInc = {
                Opcion: 28,
                token: localStorage.getItem("token"),
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/General/ws-general.php`,
                    paramsEstatusInc
                )
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        deleteToken();
                    } else {
                        this.compania = response.data.compania;
                        if (this.compania === '00100') {
                            this.getComunicadosInternos();
                            this.getComunicadosInternosMFA();
                        }
                    }
                })
                .catch(function (e) {
                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });
        },
        getComunicadosInternos() {
            axios
                .get(`${this.$store.getters.getHostNode}/api/getComunicadosActuales`, {
                    headers: {
                        authorization: `${localStorage.getItem("token")}`,
                        responseType: 'blob'
                    },
                })
                .then((response) => {
                    if (response.status === 204) {
                        console.log('sin comunicados');
                    } else if (response.data.status == 'EXPSESSION') {
                        deleteToken();
                    } else if (response.data && response.data.length > 0) {
                        this.imagenes = response.data.map(image => ({
                            url: `data:${image.mimeType};base64,${image.base64Image}`,
                            titulo: image.titulo,
                            link: image.link,
                            folio: image.id
                        }));
                        let folios = localStorage.getItem("folio");
                        folios = parseInt(folios, 10);
                        if (this.itteamComunicados.includes(folios)) {
                            console.log('Dentro del team comunicados internos');
                            this.openCarousel = true;
                        } else {
                            this.openCarousel = true;
                            console.log('Fuera del team comunicados internos');
                        }
                    } else {
                        console.error(response.data.message);
                    }
                })
                .catch(function (e) {
                    console.log(e);
                });
        },

        getComunicadosInternosMFA() {
            axios
                .get(`${this.$store.getters.getHostNode}/api/getComunicadosActualesMFA`, {
                    headers: {
                        authorization: `${localStorage.getItem("token")}`,
                        responseType: 'blob'
                    },
                })
                .then((response) => {
                    if (response.status === 204) {
                        console.log('sin comunicados');
                    } else if (response.data.status == 'EXPSESSION') {
                        deleteToken();
                    } else if (response.data && response.data.length > 0) {
                        this.imagenesMFA = response.data.map(image => ({
                            url: `data:${image.mimeType};base64,${image.base64Image}`,
                            titulo: image.titulo,
                            link: image.link,
                            folio: image.id
                        }));
                        let folios = localStorage.getItem("folio");
                        folios = parseInt(folios, 10);
                        if (this.itteamComunicados.includes(folios)) {
                            this.openCarouselMFA = true;
                        } else {
                            this.openCarouselMFA = true;
                        }
                    } else {
                        console.error(response.data.message);
                    }
                })
                .catch(function (e) {
                    console.log(e);
                });
        },
    }
}