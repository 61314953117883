<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      persistent
      scrollable
      content-class="fix-border-dialog"
    >
      <v-card class="rounded-xl">
        <v-card-title class="primary white--text subtitle-2">
          Registro de Salida Anticipada
          <v-spacer></v-spacer>
          <v-icon dark @click="cleanAndClose">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-alert
            :value="alert"
            :color="colorAlert"
            dark
            border="top"
            :icon="iconAlert"
            transition="scale-transition"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ respuestaTurno.mensaje }}
              </v-col>
              <v-col class="shrink">
                <v-row align="center">
                  <v-col cols="12" sm="6">
                    <v-btn small @click="funcionamientoBotonAlert()">
                      {{ textoBotonAlert }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-alert>
          <v-form ref="formSalidaAnticipada" v-model="valid">
            <label class="font-weight-bold">Motivo:</label>
            <v-radio-group
              v-model="motivos"
              required
              class="mt-0 mb-2"
              row
              :disabled="loadingBotonGuardar"
              :rules="rules.motivo"
            >
              <v-radio label="Personal" value="Personal"></v-radio>
              <v-radio label="Laboral" value="Laboral"></v-radio>
            </v-radio-group>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datesS"
                  label="Selecciona una fecha"
                  class="rounded-lg"
                  dense
                  outlined
                  required
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                scrollable
                :disabled="loadingBotonGuardar"
                @input="guardaFecha"
              >
              </v-date-picker>
            </v-menu>
            <label class="font-weight-bold blue-grey--text text--darken-2">
              "Recuerda que el formato es de 24 hrs."
            </label>
            <v-dialog
              ref="dialog"
              v-model="modalHoraSalida"
              :return-value.sync="horaSalida"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="horaSalidaS"
                  placeholder="Ingresa la hora de salida"
                  class="rounded-lg"
                  dense
                  outlined
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  readonly
                  required
                  :rules="rules.salida"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="modalHoraSalida"
                v-model="horaSalida"
                format="24hr"
                full-width
                @input="cierraVentanaIngresaHoraSalida()"
              >
              </v-time-picker>
            </v-dialog>

            <v-switch
              label="¿Regresarás?"
              v-model="showMessages"
              :disabled="loadingBotonGuardar"
            ></v-switch>
            <div v-show="showMessages">
              <label class="font-weight-bold blue-grey--text text--darken-2">
                "Recuerda que el formato es de 24 hrs."
              </label>
              <v-dialog
                ref="dialog"
                v-model="modalHoraRegreso"
                :return-value.sync="horaRegreso"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    class="rounded-lg"
                    v-model="horaRegreso"
                    placeholder="Ingresa la hora a la que regresas"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="modalHoraRegreso"
                  v-model="horaRegreso"
                  format="24hr"
                  full-width
                  @input="$refs.dialog.save(horaRegreso)"
                >
                </v-time-picker>
              </v-dialog>
            </div>
            <label class="text-uppercase caption"
              >Describe el motivo de la solicitud</label
            >
            <v-text-field
              outlined
              dense
              class="rounded-lg"
              name="input-7-4"
              counter="250"
              placeholder="Agrega un comentario:"
              :rules="rules.comentario"
              :disabled="loadingBotonGuardar"
              v-model="comentario"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions class="pa-0">
          <v-btn
            color="teal darken-1"
            class="rounded-xl rounded-t-0"
            large
            dark
            block
            text
            elevation="0"
            depressed
            :loading="loadingBotonGuardar"
            @click="guardaIncidencias()"
          >
            <v-icon left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias
        v-if="muestraBarra"
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-sheet>
    <v-sheet id="muestra-incidencias-cargadas">
      <muestra-incidencias-cargadas
        v-if="respuestaTurno.data"
        :muestraDetalles="muestraDetalles"
        :datosTabla="respuestaTurno.data"
        :alertErrorDosDias="alertErrorDosDias"
        @cierraTabla="muestraDetalles = false"
      />
    </v-sheet>
  </div>
</template>

<script>
import axios from "axios";
import SnackBarErrorIncidencias from "./componentsExtra/SnackBarErrorIncidencias.vue";
import MuestraIncidenciasCargadas from "./componentsExtra/MuestraIncidenciasCargadas.vue";

export default {
  name: "incidencia-alta-salida-anticipada",
  data: () => ({
    valid: true,
    modalHoraSalida: false,
    modalHoraRegreso: false,
    alert: false,
    motivos: "",
    colorAlert: "pink",
    iconAlert: "mdi-alert-circle",
    comentario: "",
    muestraBarra: false,
    muestraDetalles: false,
    alertErrorDosDias: false,
    textoBarra: "",
    horaSalidaS: "",
    loadingBotonGuardar: false,
    textoBotonAlert: "",
    colorBarra: "red accent-2",
    respuestaTurno: [],
    picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    relojRegreso: false,
    showMessages: false,
    dates: "",
    datesS: "",
    horaSalida: null,
    horaRegreso: null,
    time2: null,
    horaPues: "",
    menu2: false,
    menu3: false,
    menur: false,
    modal2: false,
    rules: {
      comentario: [
        (val) =>
          (val || "").length > 0 || "Es necesario que escribas un comentario.",
        (val) =>
          (val && val.length >= 12) ||
          "El comentario debe tener al menos 12 caracteres.",
        (val) =>
          (val && val.length < 250) ||
          "El comentario no debe tener más de 250 caracteres.",
      ],
      motivo: [
        (val) =>
          (val || "").length > 0 || "Es necesario que selecciones un motivo.",
      ],
      salida: [
        (val) =>
          (val || "").length > 0 ||
          "Es necesario que agregues una hora de salida.",
      ],
    },
  }),
  props: ["dialog", "empleado", "esAdmin"],
  components: {
    SnackBarErrorIncidencias,
    MuestraIncidenciasCargadas,
  },
  mounted() {
    this.datesS = this.setToday();
  },
  computed: {
    messages() {
      return this.showMessages ? ["Message"] : undefined;
    },
  },
  methods: {
    setToday() {
      let hoy = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      return this.formatDate(hoy);
    },
    formatDate(date) {
      if (!date) return null;

      let [year, month, day] = date.split("-");
      switch (month) {
        case "01":
          month = "ENE";
          break;
        case "02":
          month = "FEB";
          break;
        case "03":
          month = "MAR";
          break;
        case "04":
          month = "ABR";
          break;
        case "05":
          month = "MAY";
          break;
        case "06":
          month = "JUN";
          break;
        case "07":
          month = "JUL";
          break;
        case "08":
          month = "AGO";
          break;
        case "09":
          month = "SEP";
          break;
        case "10":
          month = "OCT";
          break;
        case "11":
          month = "NOV";
          break;
        case "12":
          month = "DIC";
          break;
      }
      return `${day}/${month}/${year}`;
    },
    formatDateISO(date) {
      if (!date) return null;

      let [day, month, year] = date.split("/");
      switch (month) {
        case "ENE":
          month = "01";
          break;
        case "FEB":
          month = "02";
          break;
        case "MAR":
          month = "03";
          break;
        case "ABR":
          month = "04";
          break;
        case "MAY":
          month = "05";
          break;
        case "JUN":
          month = "06";
          break;
        case "JUL":
          month = "07";
          break;
        case "AGO":
          month = "08";
          break;
        case "SEP":
          month = "09";
          break;
        case "OCT":
          month = "10";
          break;
        case "NOV":
          month = "11";
          break;
        case "DIC":
          month = "12";
          break;
      }
      return `${year}-${month}-${day}`;
    },
    guardaFecha() {
      this.menu = false;
      this.datesS = this.formatDate(this.dates);
    },
    funcionamientoBotonAlert() {
      if (this.textoBotonAlert == "Cerrar") {
        this.alert = false;
      } else {
        this.muestraDetalles = true;
        this.alert = false;
      }
    },
    cierraVentanaIngresaHoraSalida() {
      this.horaSalidaS = this.horaSalida;
      this.modalHoraSalida = false;
    },
    guardaIncidencias() {
      // formSalidaAnticipada
      if (this.$refs.formSalidaAnticipada.validate()) {
        if (
          this.motivos == "" ||
          this.datesS == "" ||
          this.horaSalidaS == "" ||
          (this.horaRegreso == "" && this.showMessages) ||
          this.comentario == ""
        ) {
          this.textoBarra = "Tienes que llenar todos los campos";
          this.muestraBarra = true;
        } else {
          this.loadingBotonGuardar = true;
          let empleado = null;

          if (this.esAdmin === true) {
            empleado = this.empleado;
          } else {
            this.isAdmin = false;
          }

          if (this.horaRegreso == null) {
            this.horaRegreso = "";
          }
          const params = {
            Opcion: 1,
            token: localStorage.getItem("token"),
            salida: this.horaSalidaS,
            regreso: this.horaRegreso,
            fecha: this.formatDateISO(this.datesS),
            banderaRegreso: this.showMessages,
            comentario: this.comentario,
            tipoMotivo: this.motivos,
            esAdmin: this.esAdmin,
            empleado: empleado,
          };

          axios
            .post(
              `${this.$store.getters.getHost}/Incidencias/ws-incidencias-salida-anticipada.php`,
              params
            )
            .then((response) => {
              if (response.data.status == "OK") {
                var data = response.data.salidaAnticipada;
                this.respuestaTurno = data;
                //(this.respuestaTurno);
                this.validaRespuesta();
              } else {
                this.loadingBotonGuardar = false;
                alert(response.data);
              }
            })
            .catch(function (e) {
              let result = e.toString().includes("401");
              if (result) {
                window.location.reload();
              } else {
                alert(e);
              }
            });
        }
      } else {
        this.colorBarra = "error";
        this.textoBarra = "Tienes que llenar todos los campos";
        this.muestraBarra = true;
      }
    },
    validaRespuesta() {
      if (this.respuestaTurno["opc"] != 5) {
        if (this.respuestaTurno["opc"] == 2) {
          this.textoBotonAlert = "Ver más";
        } else {
          this.textoBotonAlert = "Cerrar";
        }
        this.colorBarra = "error";
        this.textoBarra = this.respuestaTurno.mensaje;
        this.muestraBarra = true;
        // this.textoBotonAlert = "";
        this.loadingBotonGuardar = false;
        this.colorAlert = "pink";
        this.alert = true;
      } else {
        this.dates = "";
        this.horaSalidaS = "";
        this.horaRegreso = "";
        this.comentario = "";
        this.textoBotonAlert = "Cerrar";
        this.loadingBotonGuardar = false;
        this.colorAlert = "success";
        this.alert = true;
        this.textoBarra = this.respuestaTurno.mensaje;
        this.colorBarra = "success";
        this.muestraBarra = true;
        this.$emit("cierra", 1);
      }
    },
    cleanAndClose() {
      this.dates = "";
      this.horaSalidaS = "";
      this.horaRegreso = "";
      this.comentario = "";
      this.$emit("cierra", 2);
    },
  },
};
</script>
