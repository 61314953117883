import listaEntregaMercancia from "./components/GestionMercanciaCreados.vue"

export default {
    name: 'entrega-de-mercancia',
    components: { listaEntregaMercancia },
    props: [],
    data() {
        return {}
    },
    computed: {},
    mounted() {},
    methods: {},
}