<template>
  <v-dialog v-model="verDetalle" persistent max-width="90%" scrollable>
    <v-card class="rounded-lg">
      <v-card-title class="primary">
        <div class="overline font-weight-bold white--text">
          resumen de recepci&oacute;n de mater&iacute;al
        </div>
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraDetalleMercancia', 'closed')"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="pt-5 blue-grey lighten-5">
        <v-sheet class="rounded-0 elevation-1" min-height="400" width="100%">
          <v-container fluid style="width: 98%;">
            <v-row>
              <v-col cols="12" md="4" class="pl-0">
                <div class="pa-5">
                  <v-img
                    :src="require('@/assets/img/grupo_urrea_azul_gris.png')"
                    max-height="70"
                    width="250"
                  ></v-img>
                </div>
              </v-col>
              <v-col cols="12" md="8" align="right" class="pa-5">
                <div style="color: #0078be; font-size: 22px;">Solicitud: #{{ IdSolicitud }}</div>
                <div style="color: #0078be; font-size: 22px;">{{ empleado }}</div>
              </v-col>
            </v-row>
          </v-container>
          <!--div class="mx-1" v-if="!this.pAutorizar"-->
          <div class="mx-1">
            <v-data-table
              :headers="headers"
              :items="materialesTodaSolicitud"
              no-data-text="Materiales sin aprobar."
              loading-text="Cargando información..."
              :loading="loadingMateriales"
              :footer-props="{
                'items-per-page-text': 'Elementos por página',
                'pageText': '{0}-{1} de {2}',
              }"
              style="background-color:#ECEFF1"
            >
              <template v-slot:[`item.material`] = "{ item }">
                {{ item.material+' '+item.cantidad+' '+item.unidadMedida }}
                <div>{{ item.descripcion }}</div>
              </template>
              <template v-slot:[`item.checklistItems`]="{ item }">
                <ul class="text-left">
                  <li v-for="(itemlist, itemIndex) in item.checklistItems" :key="itemIndex">
                    {{ itemlist.nombreItem }}
                  </li>
                </ul>
              </template>
              <template v-slot:[`item.checkedListItems`] ="{ item }">
                <div v-if="item.sMEstatus === 2">
                  <ul class="text-left">
                    <li v-for="(itemlist, itemIndex) in item.selectedChecklistItems" :key="itemIndex">
                      <b>{{ itemlist }}</b>
                    </li>
                  </ul>
                </div>
                <div v-else><b>En proceso...</b></div>
              </template>
              <template v-slot:[`item.comentariosEntrega`] ="{ item }">
                <div v-if="item.sMEstatus === 2">
                  <b>{{ item.ComentariosAprobador }}</b>
                </div>
                <div v-else><b>En proceso...</b></div>
              </template>
            </v-data-table>
          </div>
          <div class="px-5"></div>
          <!--div v-else-->
          <div v-if="pAutorizar && autorizaAutorizada">
            <v-form ref="formRecibeMercancia">
              <v-container fluid style="width: 98%;">
                <v-row>
                  <v-col cols="12" md="6">
                    <div style="font-size: 17px;">Recibe: <strong>{{ usuarioRecibe }}</strong></div>
                  </v-col>
                  <v-col cols="12" md="6" align="right">
                    <div style="font-size: 17px;">selecciona los items entregados</div>
                    <!--label class="overline">selecciona los items entregados</label-->
                  </v-col>
                </v-row>
              </v-container>
              <div class="px-5 mb-3">
                <v-simple-table dense>
                  <thead>
                    <tr class="primary">
                      <th class="overline white--text text-center">Material</th>
                      <th class="overline white--text text-center">Descripción</th>
                      <th class="overline white--text text-center">Comentarios</th>
                      <th class="overline white--text text-center">Items a recibir</th>
                      <th class="overline white--text text-center">Comentarios de la entrega</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(material, index) in materialesEntregar" :key="index">
                      <td style="border: 0.7px solid #A8A8A7; max-width: 150px; padding: 8px; font-size: 16px;">
                        <label>{{ material.material }}</label>
                      </td>
                      <td style="border: 0.7px solid #A8A8A7; max-width: 15px; padding: 8px; font-size: 16px;">
                        {{ material.descripcion }}
                      </td>
                      <td style="border: 0.7px solid #A8A8A7; max-width: 150px; padding: 8px; font-size: 16px;">
                        {{ material.mascomentarios }}
                      </td>
                      <td style="border: 0.7px solid #A8A8A7; padding: 8px; font-size: 16px;">
                        <div v-if="material.checklistItems">
                          <label v-for="(item, itemIndex) in material.checklistItems" :key="itemIndex">
                            <input
                              type="checkbox"
                              :value="item.idItem"
                              v-model="material.selectedChecklistItems"
                            /> {{ item.nombreItem }}
                          </label>
                          <br/>
                        </div>
                      </td>
                      <td style="border: 0.7px solid #A8A8A7;">
                        <v-textarea
                          v-model="material.comentariosAprobador"
                          rows="1"
                          style="width: 100%;"
                          :rules="rulesTextField"
                        ></v-textarea>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
              <v-row class="px-5"></v-row>
            </v-form>
          </div><!--div v-else FORM-->
        </v-sheet>
      </v-card-text>
      <v-card-actions class="center-item-in-div" v-if="pAutorizar && autorizaAutorizada">
      <!--v-card-actions class="center-item-in-div"-->
        <v-btn
          class="rounded-lg"
          x-large
          color="teal darken-1"
          :loading="loadingBtnSavePasse"
          dark
          @click="loadingBtnSavePasse = true; guardar();"
        >
          <v-icon left>mdi-text-box-plus</v-icon>
          confirmo que recibí la mercancía descrita
        </v-btn>
      </v-card-actions>
      <SnackBar v-if="muestraBarra" :muestraBarra="muestraBarra" :texto="textoBarra" :color="colorBarra" @cierraBarra="muestraBarra = false" />
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones.js";
import SnackBar from '../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue';

export default {
  name: "detalle-de-entrega-mercancia",
  props: ["verDetalle", "IdSolicitud", "pAutorizar"],
  components: { SnackBar },
  data() {
    return {
      headers: [
        {
          text: "material",
          value: "material",
          class: "overline font-weight-bold primary white--text rounded-left-top-table-lg"
        },
        {
          text: "items a entregar",
          value: "checklistItems",
          align: "center",
          class: "overline font-weight-bold primary white--text"
        },
        {
          text: "comentarios adicionales",
          value: "mascomentarios",
          class: "overline font-weight-bold primary white--text"
        },
        {
          text: "recibe",
          value: "UsuarioRecibe",
          class: "overline font-weight-bold primary white--text"
        },
        {
          text: "items entregados",
          value: "checkedListItems",
          align: "center",
          class: "overline font-weight-bold primary white--text"
        },
        {
          text: "comentarios de la entrega",
          value: "comentariosEntrega",
          class: "overline font-weight-bold primary white--text rounded-right-top-table-lg"
        },
      ],
      rulesTextField: [
        (v) => !!v || "Este campo es requiredo.",
        (v) => (v && v.length <= 180) || "Este campo no debe tener más de 180 caracteres",
        (v) => /\S{3,}/.test(v) || "El campo debe contener al menos tres letras",
      ],

      loadingMateriales: true,
      materialesTodaSolicitud: [],
      materialesEntregar: [],
      checklistItems: [],
      usuarioRecibe: "",
      empleado: "",
      estatus: "",

      loadingBtnSavePasse: false,
      muestraBarra: false,

      autorizaAutorizada: false,
    }; // return
  }, // data

  async mounted() {
    console.log("mounted aprobar =>", this.pAutorizar);
    this.getTodaSolicitud();
  }, // mounted

  methods: {
    getTodaSolicitud() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      const params = {
        usuarioMovimiento:  localStorage.getItem("folio"),
        IdSolicitud: this.IdSolicitud,
        pAutorizar: this.pAutorizar
      };
      console.log("paramsTodaSolicitud =>", params);

      return new Promise((res) => {
        axios
        .get(`${this.$store.getters.getHostNode}/api/get-solicitud-general`,
        {
          headers: headers,
          params
        }).then((response) => {
          console.log("getTodaSolicitud =>",response);
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {

            res(response.data);

            this.empleado       = response.data.nombreMostrar;
            // this.usuarioRecibe  = response.data.result.respuesta[0].UsuarioRecibe;
            this.materialesTodaSolicitud = response.data.datosParaDTable;

            response.data.datosParaAprobar.forEach((item) => {
              if (item.quienRecibe === Number( localStorage.getItem("folio") )) {
                this.autorizaAutorizada = true;
                this.materialesEntregar.push({
                  idMaterial        : item.idMaterial,
                  material          : item.material+' '+item.cantidad+''+item.unidadMedida,
                  descripcion       : item.descripcion,
                  mascomentarios    : item.mascomentarios,
                  checklistItems    : item.checklistItems,
                  idSoliMateriales  : item.IdMateriales,
                  selectedChecklistItems: [],
                  comentariosAprobador: ""
                }); // push array
              } // el aprobador
            }); // forEachMAterial
            console.log("materialesEntregar =>",this.materialesEntregar);
          } else {
            res([]);
          }
        }).finally(() => {
          this.loadingMateriales = false;
        });
      });
    }, // getTodaSolicitud

    guardar() {
      if (this.$refs.formRecibeMercancia.validate()) {
        console.log("si lleno los campos requeridos");
        const headers = {
          Authorization: localStorage.getItem("token"),
        };
        const params = {
          usuarioMovimiento: localStorage.getItem("folio"),
          IdSolicitud: this.IdSolicitud,
          materiales: this.materialesEntregar,
        };
        console.log("aprobar materiales", params);
        return new Promise((res) => {
          axios
            .post(
              `${this.$store.getters.getHostNode}/api/update-solicitud-general`,
              params,
              {
                headers: headers,
              }
            ).then((response) => {
              console.log(response);
              if (response.data.status == "OK") {
                res(response.data);
                this.textoBarra = "Registro exitoso";
                this.colorBarra = "success";
                this.muestraBarra = true;
              } else if (response.data.status == false) {
                res(response.data);
                this.textoBarra = response.data.message;
                this.colorBarra = "error";
                this.muestraBarra = true;
              } else if (response.data.status == "EXPRESSION") {
                deleteToken();
              }
            }).finally(() => {
              this.loadingMateriales = true;
              this.autorizaAutorizada = false;
              this.loadingBtnSavePasse = false;
              this.materialesTodaSolicitud = [];
              this.materialesEntregar = [];
              this.getTodaSolicitud();
            });
        }); // return
      } else {
        console.log("campos requeridos");
        this.loadingBtnSavePasse = false;
      }
    }, // guardar
  }, // methods
};
</script>

<style scoped>
  .autocomplete {
    position: relative;
  }

.autocomplete-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
}

.autocomplete-list li {
  padding: 8px;
  cursor: pointer;
}

.autocomplete-list li.highlighted {
  background-color: #f2f2f2;
}
</style>