import InfoGeneralO from "./components/infoGeneralO.vue";
import ObjArea from "./components/ObjArea.vue";
import ObjCompania from "./components/ObjCompania.vue";
import ObjDepartamento from "./components/ObjDepartamento.vue";
import VerObjetivos from "./components/VerObjetivos.vue"
import VerObjetivosNew from "./components/VerObjetivosNew.vue";
import ReporteObjetivos from "./components/ReporteObjetivos.vue";
import EmpRespObj from "./components/EmpRespObj.vue";
import NoAutorizado from "./components/NoAutorizado.vue";
import EmpResponsable from "./components/EmpResponsable.vue";

import axios from "axios";


export default {
  name: 'mis-objetivos',
  components: {
    InfoGeneralO,
    VerObjetivos,
    VerObjetivosNew,
    ObjDepartamento,
    ObjArea,
    ObjCompania,
    ReporteObjetivos,
    EmpRespObj,
    NoAutorizado,
    EmpResponsable,



  },
  props: [],
  data() {
    return {
      currentMonth: '',
      currentYear: '',
      mesTabla: '',
      noEmpleado: null,
      tokenLogin: null,


      varEviado: null,
      varCompany: null,
      varArea: null,
      varObjetivos: null,
      //Esta la necesito del Login
      iMes: null, // el mes tiene que ir en 3, 6, 9, 12
      numEmpleado: null,//	'9284',//9363//3962,20153      ,	//BRENDA OBJ 10316      ,//10720,//omar:3962,// para las otras opc utiliza este: 8704,//Es brenda 10720,// 20153, //este lo tengo que ver 15556,//14460,//Bryan: 18910//Marco: 4300,//Cesar: 12026,//Jared:  14460,
      trimestre: null, // trimestre es igual a 1, 2, 3, 4
      trimestreFinal: null,
      anio: null,
      anioTabla: null,
      //-----------------------------
      Mes1: null,
      Mes2: null,
      Mes3: null,

      gTrabajo: null,

      PrimerMesNumValorBase: null,
      SegundoMesNumValorBase: null,
      TercerMesNumValorBase: null,
      TrimestreValorBase: null,

      Ids_EmpleadosSinPropiosYDepa: [3107, 8631, 8704, 4251, 3692, 8567],
      Id_GeneralA: [0],
      rhPerfil: ['ADMING', 'COMITE'],
      idIndPonderacionObj1: 'COLABORADOR',
      idIndPonderacionObj2: 'COMITE',
      idIndPonderacionObj3: 'RESPONSABLE',
      rhUsuario: null,
      AreaUser: null,

      bandera: false,
      banderaFecha: false,
      bandera3: false,
      banderaArea2: false,
      banderaPropios22: false,
      banderaReporte: null,
      banderaRESPONSABLE: null,
      banderaEmpResp: null,
      banderaR: null,
      banderaBotones: false,
      banderaChampion: false,
      banderaBtnExportar: false,
      bnadera2Btn: false,
      banderaADMING: false,
      banderaBTNResponsable: false,


      //Varibles para el SP dhReporteBonoEmpResp
      //numPersonal: 14460, //Recuerda que este varibl tiene que salir del login
      PuestoColabValorBase: null,
      iNumDeptoValorBase: null,
      PuestoCoordValorBase: null,
      iAreaValorBase: null,
      banderaSP: false,
      //--------------------------------
      //Variables de los porcentajes
      poCIAColab: 0,
      poAreaColab: 0,
      poDepartamentoColab: 0,
      poIndividualesColab: 0,

      poCIADir: 0,
      poAreaDir: 0,
      poDepartamentoDir: 0,
      poIndividualesDir: 0,

      poCIACoord: 0,
      poAreaCoord: 0,
      poDepartamentoCoord: 0,
      poIndividualesCoord: 0,
      ResponsableDepa: null,
      banderaDenegado: false,

      anioEMP_Resp: null,
      trimestreEMP_Resp: null,
     
      banderaDireccion:'',

    }
  },
  computed: {

  },
  async mounted() {

    this.getColaborador();
    this.getComite();
    this.getResponsable();

    this.getCurrentMonthAndYear();
    this.getFechaEMP_Resp();
    await this.getEmpleadosIds();
    this.validarEmpleado();
    this.validarEmpleadoGerentesD();
    this.validarEmpleadoAlf();

    this.getMeseseTabla();
    this.getSPReporteBono();
    await this.getChampions();
    await this.getValosU();
    this.validarChampUser();
    this.Champions();

    this.validaDirectorGeneral();  
    // this.getmarco();

    // console.log('Valor de CIA 1 COLABORADOR', this.poCIAColab);
    // console.log('Valor de CIA 1 COMITE', this.poCIADir);
    // console.log('Valor de CIA 1 RESPONSABLE', this.poCIACoord);

    //console.log(localStorage.getItem("token"), 'Este es el token $$$$$$$$$$$$$$$4');
    // this.noEmpleado = localStorage.getItem("folio");




  },
  methods: {
    validaDirectorGeneral(){
      this.banderaDireccion = this.$store.getters.getUser['Folio'];
      if (this.$store.getters.getUser["Folio"] == "0") {
        this.banderaReporte = true;
        this.banderaDenegado = false;
        this.banderaRESPONSABLE = false;
      }
    },
 


    async getEmpleadosIds() {
      try {
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/empleados_sinDepa/${this.anioTabla}/${this.trimestreFinal}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          }
        });
        // Almacenar ID del empleado 
        this.Ids_Empleado = response.data.data.map(item => item.Empleado);

        // console.log('Ids de los empleados: ', this.Ids_Empleado);
        // console.log('TRIEMSTEE FINAL:', this.trimestreFinal);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },

    validarEmpleado() {
      // Verificar si el numEmpleado está en el array de Ids_Empleado
      // if (Array.isArray(this.Ids_Empleado) && this.Ids_Empleado.includes(Number(this.numEmpleado))) {
        if (Array.isArray(this.Ids_Empleado) && this.Ids_Empleado.includes(Number(this.numEmpleado))) {
        this.bandera3 = true;
         console.log('El empleado es correcto:', this.numEmpleado, 'BANDERA3', this.bandera3);
      }
      // Verificar si el numEmpleado es igual a 10720
      else if (this.numEmpleado === '10720') {
        this.bandera3 = true;
        // console.log('El numEmpleado es 10720:', this.numEmpleado, 'BANDERA3', this.bandera3);
      }
      // En cualquier otro caso, se pone bandera3 en false
      else {
        this.bandera3 = false;
        // console.log('El empleado es incorrecto', this.bandera3);
      }
    },


    // validarEmpleado() {
    //   if (Array.isArray(this.Ids_Empleado) && this.Ids_Empleado.includes(this.numEmpleado)) {
    //     this.bandera3 = true;
    //      console.log('El empleado es correcto:', this.numEmpleado, 'BANDERA3', this.bandera3);
    //   } else {
    //     this.bandera3 = false;
    //      console.log('El empleado es incorrecto', this.bandera3);
    //   }
    // },

    validarEmpleadoGerentesD() {
      if (Array.isArray(this.Ids_EmpleadosSinPropiosYDepa) && this.Ids_EmpleadosSinPropiosYDepa.includes(Number(this.numEmpleado))) {
        //   this.banderaDepa = true;
        this.banderaPropios22 = true;

         console.log('El empleado es correcto:', this.numEmpleado, 'BANDERA Propios', this.banderaPropios22);
      } else {
        this.banderaPropios22 = false;
         console.log('El empleado es incorrecto', this.banderaPropios22);
      }
    },

    validarEmpleadoAlf() {
      if (Array.isArray(this.Id_GeneralA) && this.Id_GeneralA.includes(Number(this.numEmpleado))) {

        this.banderaArea2 = true;
        // console.log('El empleado es correcto:', this.numEmpleado, 'BANDERA Area', this.banderaArea2);
      } else {
        this.banderaArea2 = false;
        // console.log('El empleado es incorrecto', this.banderaArea2);
      }
    },

    getFechaEMP_Resp(){
      const date = new Date();
      this.currentMonth2 = date.getMonth() + 1; // getMonth() devuelve 0-11, así que sumamos 1
      this.currentYear2 = date.getFullYear();

      if (this.currentMonth2 === 1 || this.currentMonth2 === 2 || this.currentMonth2 === 3) {
        this.trimestreEMP_Resp = 1;
        this.anioEMP_Resp = this.currentYear2
      } else if (this.currentMonth2 === 4 || this.currentMonth2 === 5 || this.currentMonth2 === 6) {
        this.trimestreEMP_Resp = 2
        this.anioEMP_Resp = this.currentYear2
      } else if (this.currentMonth2 === 7 || this.currentMonth2 === 8 || this.currentMonth2 === 9) {
        this.trimestreEMP_Resp = 3
        this.anioEMP_Resp = this.currentYear2
      } else if (   this.currentMonth2 === 10 || this.currentMonth2 === 11 || this.currentMonth2 === 12 ) {
        this.trimestreEMP_Resp = 4
        this.anioEMP_Resp = this.currentYear2
      }
      
     
    },

    getCurrentMonthAndYear() {
      const date = new Date();
      //Aqui le mueves para hacer la prueba
       this.currentMonth = date.getMonth() + 1; // getMonth() devuelve 0-11, así que sumamos 1
       this.currentYear = date.getFullYear();
      // this.currentMonth = 1;
      // this.currentYear = 2025;
      if (this.currentMonth === 2 || this.currentMonth === 3 || this.currentMonth === 4) {
        this.mesTabla = 3;
        this.trimestreFinal = 1
        this.anioTabla = this.currentYear
      } else if (this.currentMonth === 5 || this.currentMonth === 6 || this.currentMonth === 7) {
        this.mesTabla = 6;
        this.trimestreFinal = 2
        this.anioTabla = this.currentYear
      } else if (this.currentMonth === 8 || this.currentMonth === 9 || this.currentMonth === 10) {
        this.mesTabla = 9;
        this.trimestreFinal = 3
        this.anioTabla = this.currentYear
      } else if (   this.currentMonth === 11 || this.currentMonth === 12 ) {
        this.mesTabla = 12;
        this.trimestreFinal = 4
        this.anioTabla = this.currentYear
      
      }else if (  this.currentMonth === 1) {
        this.mesTabla = 12;
        this.trimestreFinal = 4
        this.anioTabla = this.currentYear - 1
      }
      this.noEmpleado = localStorage.getItem("folio");
      this.tokenLogin = localStorage.getItem("token");
      this.gTrabajo = localStorage.getItem("gTrabajo");

      this.banderaFecha = true;
      this.iMes = this.mesTabla;
      this.trimestre = this.trimestreFinal;
      this.anio = this.anioTabla;
      this.banderaReporte = false;
      // this.banderaBotones = true;
      this.numEmpleado = this.noEmpleado; // aqui lo comentas para por el noEmpleado directo
       console.log('valor de gTrabajo:', this.gTrabajo);
      // this.numEmpleado = Array.isArray(this.noEmpleado);

      // console.log('Mes tabla:', this.mesTabla);
      // console.log('Mes actual:', this.currentMonth);
      // console.log('Año actual:', this.currentYear);
      // console.log('Año 12:', this.anioTabla);
      // console.log('Numero de empleado:', this.numEmpleado);
    },

    valoresAreaEvent(response) {
      this.varArea = response;
    },

    valoresTestEvent(response) {
      this.varEviado = response;
    },

    valoresCompanyEvent(response) {
      this.varCompany = response;
    },

    valoresObjetivosEvent(response) {
      this.varObjetivos = response;
    },

    valorBandera1(response) {
      this.banderaReporte = response
    },

    valorBanderaRESP(response) {
      this.banderaRESPONSABLE = response
    },

    valorBandera2(response) {
      this.banderaEmpResp = response
      console.log('valor de la bandera en el js 111:', this.banderaEmpResp)
    },

    enviarVarResp(response) {
      this.banderaR = response
      console.log('valor de la bandera en el js:', this.banderaR)
    },

    valorRegresar(response) {
      this.banderaReporte = response
    },

    valorRegresar2(response) {
      this.banderaEmpResp = response
    },

    valorRegresar2Resp(response) {
      this.banderaR = response
    },



    getMeseseTabla() {

      return new Promise((resolve) => {
        axios.get(`${this.$store.getters.getHostNode}/api/ver-Mes-Trimestre/${this.iMes}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })

          .then((response) => {

            this.bandera = true;
            resolve(response.data.data);
            // console.log(response.data.data);
            this.Mes1 = response.data.data[0].dmes;
            this.PrimerMesNumValorBase = response.data.data[0].mes;
            this.Mes2 = response.data.data[1].dmes;
            this.SegundoMesNumValorBase = response.data.data[1].mes;
            this.Mes3 = response.data.data[2].dmes;
            this.TercerMesNumValorBase = response.data.data[2].mes;
            this.TrimestreValorBase = response.data.data[0].TRIMESTRE;


            // console.log('Meses num:', this.PrimerMesNumValorBase, this.SegundoMesNumValorBase, this.TercerMesNumValorBase);
          })
          .finally(() => {
            this.loadingTable = false
          });
      });
    },

    // getSPReporteBono() {
    //   return new Promise((resolve) => {
    //     axios.get(`${this.$store.getters.getHostNode}/api/ver-SP-dhReporteBono/${this.numEmpleado}`, {
    //       headers: {
    //         Authorization: localStorage.getItem("token"),
    //       },
    //     })

    //       .then((response) => {
    //         this.banderaSP = true;
    //         resolve(response.data.data);

    //         // console.log(response.data.data);
    //         this.iNumDeptoValorBase = response.data.data[0].EMP_KEYDEP;
    //         this.PuestoColabValorBase = response.data.data[0].EMP_KEYPUE;
    //         this.PuestoCoordValorBase = response.data.data[0].PUESTOCOORD;
    //         this.iAreaValorBase = response.data.data[0].Area;
    //         console.log('valor de EMP_KEYDEP', this.iNumDeptoValorBase);
    //         console.log('valor de EMP_KEYPUE', this.PuestoColabValorBase);
    //         console.log('valor de PUESTOCOORD', this.PuestoCoordValorBase);
    //         console.log('valores iAreaValorBase', this.iAreaValorBase);
    //       })

    //   });
    // },

    getSPReporteBono() {
      return new Promise((resolve, reject) => {
        const isEmpleadoInArray = this.Ids_EmpleadosSinPropiosYDepa.includes(this.numEmpleado);
    
        const url = isEmpleadoInArray
          ? `${this.$store.getters.getHostNode}/api/ver-SP-dhReporteBonoDirectores/${this.numEmpleado}`
          : `${this.$store.getters.getHostNode}/api/ver-SP-dhReporteBono/${this.numEmpleado}`;

          // Log para identificar cuál URL se está utilizando
    // console.log(
    //   `Ejecutando URL: ${isEmpleadoInArray ? 'BonoDirectores' : 'Bono'} - ${url}`
    // );
    
        axios
          .get(url, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.banderaSP = true;
            resolve(response.data.data);
    
            // Asignación de valores solo si se recibe respuesta
            if (response.data.data && response.data.data.length > 0) {
              this.iNumDeptoValorBase = response.data.data[0].EMP_KEYDEP;
              this.PuestoColabValorBase = response.data.data[0].EMP_KEYPUE;
              this.PuestoCoordValorBase = response.data.data[0].PUESTOCOORD;
              this.iAreaValorBase = response.data.data[0].Area;
              this.ResponsableDepa = response.data.data[0].responsable;
            
    
              // console.log('valor de EMP_KEYDEP', this.iNumDeptoValorBase);
              // console.log('valor de EMP_KEYPUE', this.PuestoColabValorBase);
              // console.log('valor de PUESTOCOORD', this.PuestoCoordValorBase);
              // console.log('valores iAreaValorBase', this.iAreaValorBase);
              // console.log('responsable: ', this.ResponsableDepa);

            }
          })
          .catch((error) => {
            console.error('Error al obtener el reporte del bono:', error);
            reject(error);
          });
      });
    },
    

  //   getSPReporteBono() {
  //     return new Promise((resolve) => {
  //         axios.get(`${this.$store.getters.getHostNode}/api/ver-SP-dhReporteBono/${this.numEmpleado}`, {
  //             headers: {
  //                 Authorization: localStorage.getItem("token"),
  //             },
  //         })
  //         .then((response) => {
  //             this.banderaSP = true;
  //             resolve(response.data.data);
  
  //             // Verifica si la respuesta tiene datos y muestra en consola
  //             console.log('Respuesta completa del SP:', response.data);
  
  //             if (response.data && response.data.data && response.data.data.length > 0) {
  //                 const datos = response.data.data[0]; // Extraer los primeros datos
  
  //                 // Asignar los valores recibidos a las variables correspondientes
  //                 this.iNumDeptoValorBase = datos.EMP_KEYDEP;
  //                 this.PuestoColabValorBase = datos.EMP_KEYPUE;
  //                 this.PuestoCoordValorBase = datos.PUESTOCOORD;
  //                 this.iAreaValorBase = datos.Area;
  //                 this.ResponsableDepa = datos.responsable;
  
  //                 // Mostrar los valores asignados en consola
  //                 console.log('Valores asignados:');
  //                 console.log('iNumDeptoValorBase:', this.iNumDeptoValorBase);
  //                 console.log('PuestoColabValorBase:', this.PuestoColabValorBase);
  //                 console.log('PuestoCoordValorBase:', this.PuestoCoordValorBase);
  //                 console.log('iAreaValorBase:', this.iAreaValorBase);
  //                 console.log('Valor del responsable:', this.ResponsableDepa);
  //             } else {
  //                 // Asignar los valores predeterminados si la respuesta es un array vacío
  //                 console.log('Respuesta vacía, asignando valores predeterminados.');
  
  //                 this.iNumDeptoValorBase = 900;
  //                 // this.PuestoColabValorBase = '01276';
  //                 // this.PuestoCoordValorBase = '01262';
  //                 this.PuestoColabValorBase = '01276';
  //                 this.PuestoCoordValorBase = '01276';
  //                 this.iAreaValorBase = 11;
  
  //                 // Mostrar los valores predeterminados asignados en consola
  //                 console.log('Valores asignados por defecto:');
  //                 console.log('iNumDeptoValorBase:', this.iNumDeptoValorBase);
  //                 console.log('PuestoColabValorBase:', this.PuestoColabValorBase);
  //                 console.log('PuestoCoordValorBase:', this.PuestoCoordValorBase);
  //                 console.log('iAreaValorBase:', this.iAreaValorBase);
  //             }
  //         })
  //         .catch((error) => {
  //             console.error('Error al llamar al SP:', error);
  //         });
  //     });
  // },
  
    async getChampions() {
      try {
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/verChampios/${this.numEmpleado}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });

        // Obtener los valores de Id_Area y concatenarlos en una cadena separada por comas
        this.CadAreas = response.data.data.map(item => item.Id_Area).join(',');
        // console.log('Cad-areas', this.CadAreas);

        // Asignar la cadena resultante a this.CadAreas si es necesario
        ///this.CadAreas = CadAreas;

        // Asignar otros valores si es necesario
        this.AreaChamp = response.data.data.map(item => item.Area);
        this.FolioChampion = response.data.data.map(item => item.FolioChampion);
        // console.log('Cadenas de las areas', this.CadAreas);
        // console.log('Areas de la champ:', this.AreaChamp);
        console.log('Folios champ:', this.FolioChampion);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },

    // async getChampions() {
    //   try {
    //     const response = await axios.get(`${this.$store.getters.getHostNode}/api/verChampios/${this.numEmpleado}`, {
    //       headers: {
    //         Authorization: localStorage.getItem("token"),
    //       },
    //     });
    //     this.Area = response.data.data.map(item => item.Area);
    //     this.Id_Area = response.data.data.map(item => item.Id_Area);
    //     this.FolioChampion = response.data.data.map(item => item.FolioChampion);



    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // },

    async getValosU() {
      try {
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/verValorU/${this.tokenLogin}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        this.AreaUser = response.data.data.map(item => item.nomcort);
        this.rhUsuario = response.data.data.map(item => item.Nickname);

        //this.AreaUser = "RESP";


        console.log('Valor de Area user:', this.AreaUser);
        if(this.AreaUser.includes('ADMING') || this.AreaUser.includes('COMITE') || this.AreaUser.includes('RESP') ||
        this.AreaUser.includes('FRN') || this.AreaUser.includes('RESP1') || this.AreaUser.includes('EMP') ||
        this.AreaUser.includes('ADML2') ){

          this.banderaDenegado = false;
        }else{
          this.banderaDenegado = true;
        }



        if (this.AreaUser.includes('ADMING') || this.numEmpleado === '0'){
          this.banderaBtnExportar = true;
        } else {
          this.banderaBtnExportar = false;
        }

        // Esto es para que se vean los 2 botones
      // Esto es para que se vean los 2 botones
      if (this.AreaUser.includes('ADMING') || this.numEmpleado === '0') {
        this.banderaADMING = true;
      } else {
        this.banderaADMING = false;
      }

      if (this.AreaUser.includes('COMITE')) {
        this.bnadera2Btn = true;
      } else {
        this.bnadera2Btn = false;
      }

      //Esto es para que se vea el btn de Reporte de bono en Responsables
      if (this.AreaUser.includes('RESP') || this.AreaUser.includes('RESP1') || this.numEmpleado === '8631') {
        this.banderaBTNResponsable = true;
      } else {
        this.banderaBTNResponsable = false;
      }



      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },

    validarChampUser() {
      // Verifica si noEmpleado está en el array FolioChampion
      // if (this.FolioChampion.map(String).includes(this.numEmpleado) || this.AreaUser.some(areaUser => this.rhPerfil.includes(areaUser))) {
        // if (this.FolioChampion.includes(this.numEmpleado) || this.AreaUser.some(areaUser => this.rhPerfil.includes(areaUser)) || this.numEmpleado === '8631') {
          if (
            this.FolioChampion.includes(Number(this.numEmpleado)) || // Convertir numEmpleado a número
            this.AreaUser.some(areaUser => this.rhPerfil.includes(areaUser)) || // Validar en rhPerfil
            this.numEmpleado === '8631' || this.numEmpleado === '9363' // Comparar directamente como string
        ) {
        this.banderaBotones = true;

         console.log('El ID ES IGUAL validarChamp', this.FolioChampion, 'noempleado:', this.numEmpleado);
      } else {
        this.banderaBotones = false;
         console.log('El ID NO ES IGUAL validarChamp', this.FolioChampion, 'noempleado:', this.numEmpleado);
      }


    },

    Champions() {
      // Verifica si noEmpleado está en el array FolioChampion
      console.log('valor de champions: ', this.FolioChampion, 'valor de noEmpleado: ', this.numEmpleado);
      // if (this.FolioChampion.includes(this.numEmpleado)) {
      //   this.banderaChampion = true;

      //   console.log('SI ES CHAMPION El ID ES IGUAL', this.FolioChampion, 'noempleado:', this.numEmpleado);
      // } else {
      //   this.banderaChampion = false;
      //   console.log('NO ES CHAPION El ID NO ES IGUAL', this.FolioChampion, 'noempleado:', this.numEmpleado);
      // }

      // if (this.FolioChampion.map(String).includes(this.numEmpleado)) {
        // if (this.FolioChampion.includes(this.numEmpleado) || this.numEmpleado === '8631') {
        if (
            this.FolioChampion.includes(Number(this.numEmpleado)) || // Convertir numEmpleado a número
            this.numEmpleado === '8631' || this.numEmpleado === '9363' // Comparar directamente como string
        ) {
        this.banderaChampion = true;
        console.log('SI ES CHAMPION El ID ES IGUAL', this.FolioChampion, 'noempleado:', this.numEmpleado);
      } else {
        this.banderaChampion = false;
        console.log('NO ES CHAMPION El ID NO ES IGUAL', this.FolioChampion, 'noempleado:', this.numEmpleado);
      }
      


      if ( this.banderaChampion === true) {
        this.bnadera2Btn = true;
      } else {
        this.bnadera2Btn = false;
      }


    },



    getComite() {
      return new Promise((resolve) => {
        axios.get(`${this.$store.getters.getHostNode}/api/infoGeneralObj/${this.idIndPonderacionObj2}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            resolve(response.data.data);
            this.poCIADir = response.data.data[0].ponderacionObjetivosCIA / 100;
            this.poAreaDir = response.data.data[0].ponderacionObjetivosArea / 100;
            this.poDepartamentoDir = response.data.data[0].ponderacionObjetivosDepartamento / 100;
            this.poIndividualesDir = response.data.data[0].ponderacionObjetivosIndividuales / 100;

            // console.log('Valor de poAreaDIr',this.poAreaDir);
            // console.log('Valor de poCIADir',this.poCIADir);

          })

      });
    },

    getResponsable() {
      return new Promise((resolve) => {
        axios.get(`${this.$store.getters.getHostNode}/api/infoGeneralObj/${this.idIndPonderacionObj3}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            resolve(response.data.data);
            this.poCIACoord = response.data.data[0].ponderacionObjetivosCIA / 100;
            this.poAreaCoord = response.data.data[0].ponderacionObjetivosArea / 100;
            this.poDepartamentoCoord = response.data.data[0].ponderacionObjetivosDepartamento / 100;
            this.poIndividualesCoord = response.data.data[0].ponderacionObjetivosIndividuales / 100;
            // console.log('---------------------------------')
            // console.log('Valor de poCIACoord',this.poCIACoord);
            // console.log('Valor de poAreaCoord',this.poAreaCoord);
            // console.log('Valor de poDepartamentoCoord',this.poDepartamentoCoord);
            // console.log('Valor de poIndividualesCoord',this.poIndividualesCoord);
            // console.log('**********************************')
            


          })

      });
    },

    getColaborador() {
      return new Promise((resolve) => {
        axios.get(`${this.$store.getters.getHostNode}/api/infoGeneralObj/${this.idIndPonderacionObj1}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            resolve(response.data.data);
            // this.poCIAColab = response.data.data[0].ponderacionObjetivosCIA / 100;

            // this.poCIAColab = response.data.map(item => item.ponderacionObjetivosCIA);
            this.poCIAColab = response.data.data[0].ponderacionObjetivosCIA / 100;
            //console.log("poCIAColab:", this.poCIAColab);

            this.poAreaColab = response.data.data[0].ponderacionObjetivosArea / 100;
            //console.log("poAreaColab:", this.poAreaColab);

            this.poDepartamentoColab = response.data.data[0].ponderacionObjetivosDepartamento / 100;
            //console.log("poDepartamentoColab:", this.poDepartamentoColab);

            this.poIndividualesColab = response.data.data[0].ponderacionObjetivosIndividuales / 100;
            //console.log("poIndividualesColab:", this.poIndividualesColab);

            // this.poCIAColab = response.data.data[0].ponderacionObjetivosCIA / 100;
            // this.poAreaColab = response.data.data[0].ponderacionObjetivosArea / 100;
            // this.poDepartamentoColab = response.data.data[0].ponderacionObjetivosDepartamento / 100;
            // this.poIndividualesColab = response.data.data[0].ponderacionObjetivosIndividuales / 100;
//Mensaje
          })

      });
    },

  }
}