<template>
    <div class="d-flex justify-center align-center fill-height">
      <v-card class="pa-5" color="red lighten-4" outlined>
        <v-card-title class="text-center">
          <v-icon color="red darken-1" class="mr-2">mdi-lock</v-icon>
          Acceso Restringido
        </v-card-title>
        <v-card-text class="text-center">
          <p>No tienes permiso para entrar a este módulo del portal.</p>
          <p>Por favor, contacta al administrador del sistema si necesitas acceso.</p>
        </v-card-text>
        <v-card-actions class="justify-center">
         
        </v-card-actions>
      </v-card>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "accesoDenegado",
    props: [
  
    ],
    data() {
        return {
          
        };
    },
    async mounted() {
    },
  
    computed: {
      
    },
    methods: {
  
  
  
    }
  };
  //Mensaje
  </script>
  
  <style>
  .rounded-simple-table-head-l {
    border-top-left-radius: 22px !important;
  }
  
  .rounded-simple-table-head-r {
    border-top-right-radius: 22px !important;
  }
  
  .back-card-objectives {
    background: rgb(255, 255, 255) !important;
    background: linear-gradient(301deg,
            rgba(255, 255, 255, 1) 15%,
            rgba(201, 78, 148, 0.7344071417629552) 45%,
            rgba(255, 255, 255, 1) 100%) !important;
  }
  
  .centrado {
    text-align: center;
    font-size: 18px;
  }
  
  .small-input {
    width: 60px;
    padding: 2px;
    font-size: 12px;
  }
  </style>
  