
<template>
  <v-container class="objetives d-flex flex-column justify-end">

    <p>Mi bono actual trimestral es de (XXX MXN)</p>

    <div class="goal-progress" v-for="(obj, index) in objetivesList" :key=index>
      <div class="goal-progress-title">{{obj.title}}</div>
      <div class="goal-progress-content">
        <div>
          <v-slider
            v-model="obj.percentProgress"
            :thumb-size="26"
            :hint="obj.title"
            thumb-label="always"
            step="10"
            ticks="always"
            tick-size="3"
            readonly
          >
            <template v-slot:thumb-label="{ value }">
              {{ satisfactionEmojis[Math.min(Math.floor(0.07 * value), 6)] }}
            </template>
          </v-slider>
        </div>
        <div class="goal-progress-linear">
          <v-progress-linear
            stream
            :value="(100 - obj.percentLe)"
            :buffer-value="(100 - obj.percentProgress)"
            height="6"
            color="accent"
            reverse
          >
          </v-progress-linear>
          
          <div class="progress" :style="{'left': obj.percentProgress+'%'}">
            <p>{{obj.progress}}</p>
          </div>
          <div class="le" :style="{ 'left': obj.percentLe+'%' }">
            <v-btn color="primary" fab x-small>
              <v-icon>mdi-flag-outline</v-icon>
            </v-btn>
            <p>{{obj.le}}</p>
          </div>
          <div class="vo">
            <v-btn color="primary" fab x-small>
              <v-icon>mdi-flag-checkered</v-icon>
            </v-btn>
            <p>{{obj.vo}}</p>
          </div>
        </div>
      </div>
    </div>

  </v-container>
</template>
<script>
  import axios from 'axios';
  export default {
    name: 'persona-objetivos-preview',
    data: () => ({
      objetivesList: [],
      satisfactionEmojis: ['☹️', '🙁', '😐', '🙂', '😊', '😁', '😎'],
    }),
    mounted() {
      this.retrieveObjetives()
    },
    components: {
    },
    props: [
      'userId'
    ],
    methods: {
      retrieveObjetives() {
        axios.get(`./datasets/objetives.json`).then((response) => {
          if(response.data.length > 0) {
            this.objetivesList = response.data.map(objetive => {
              objetive['percentLe'] = calculePercent(objetive.vo, objetive.le)
              objetive['percentProgress'] = calculePercent(objetive.vo, objetive.progress)
              return objetive
            }).slice(0,2)
          }
        })
      }
    }
  }
  const calculePercent = (total, rel) => (100 / total * rel)
</script>
<style lang="sass">

  .objetives
    background: rgba(255,255,255, 0.9)

  .goal-progress
    .goal-progress-title
      font-size: 13px
    .goal-progress-content
      height: 78px
      padding: 30px 1rem 0 1rem
      margin-bottom: 1rem
      border: 1px solid #ffffff
      background-color: rgba(#ffffff, 0.5)
      border-radius: 8px

  .v-slider--horizontal
    margin-left: 0 !important
    margin-right: 0 !important
  .v-slider__track-container
    opacity: 0 !important

  .goal-progress-linear
    position: relative
    margin-top: -41px
    .le, .vo, .progress
      position: absolute
      width: 40px
      height: 10px
      top: -10px
      text-align: center
      p
        font-size: 11px
        color: #000000
      .v-btn
        height: 26px
        width: 26px
        transform: scale(0.7)
    .le
      right: 0
      margin-left: -20px
    .vo
      right: -20px
    .progress
      top: -22px
      font-weight: 600

</style>