<template>
    <div>
        <!-- <div v-show="loadingTable" class="center-item-in-div-column">
            <v-progress-circular :size="150" :width="15" color="primary" indeterminate></v-progress-circular>
            <label class="overline font-weight-bold">Cargando información...</label>
        </div> -->

        <v-btn class="primary white--text text-uppercase font-weight-bold text-center" @click="Regresar">
            <v-icon>{{ 'mdi-arrow-left' }}</v-icon>&nbsp;Regresar
        </v-btn>

        <div class="centrado">
            <v-btn text>
                <b v-if="banderaRESPONSABLE">Reporte de Bono Responsable</b>
                <b v-else>Reporte de Bono</b>
            </v-btn>
        </div>
        <p></p>

        <v-row class="d-flex align-center">
            <v-col cols="auto">
                <v-icon color="yellow">{{ 'mdi-flag' }}</v-icon>No Autorizado&nbsp;
                <v-icon color="green">{{ 'mdi-flag' }}</v-icon>Autorizado&nbsp;
                <v-icon color="red">{{ 'mdi-flag' }}</v-icon>No tiene Objetivos Capturados&nbsp;
                <v-icon color="gray">{{ 'mdi-flag' }}</v-icon>No aplica al bono&nbsp;
            </v-col>
            <v-col v-if="localBandera" class="d-flex justify-end">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="green darken-4" class="text-uppercase font-weight-bold text-center" :loading="isLoading"
                            @click="handleExportarClick" v-bind="attrs" v-on="on">
                            <v-icon v-if="!isLoading" left>mdi-microsoft-excel</v-icon>
                            <v-icon v-else left>mdi-loading</v-icon>
                            {{ isLoading ? 'Cargando...' : 'Exportar' }}
                        </v-btn>
                       

                    </template>
                    <span>Exportar el reporte de bono a Excel</span>
                </v-tooltip>
            </v-col>
          

        </v-row>

        <p></p>

        <div class="rounded-lg pb-4 elevation-0">
            <v-simple-table id="miTabla" dense style="background-color: transparent;">
                <thead v-if="!loadingTable">
                    <tr>
                        <th class="primary white--text text-uppercase font-weight-bold text-center rounded-simple-table-head-l"
                            colspan="5">
                        </th>
                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                            colspan="3">
                        </th>
                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                            colspan="3">
                        </th>
                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                            colspan="3">
                        </th>
                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                            colspan="3">
                        </th>
                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                            colspan="3">
                        </th>
                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                            colspan="3">
                        </th>
                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                            colspan="3">
                        </th>
                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                            colspan="1">
                            <!-- Evaluación -->
                        </th>
                        <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-r"
                            colspan="1">
                            Trimestres</th>

                    </tr>


                </thead>


            </v-simple-table>

            <!-- Esta es la tabla para los Responsables -->

            <!-- <template v-if="banderaBTNResponsable"> -->

                <template v-if="banderaRESPONSABLE">
                    <div>
                        
    
                        <!-- Indicador de carga -->
                        <div v-if="loadingTable" class="center-item-in-div-column">
                            <v-progress-circular :size="150" :width="15" color="primary"
                                indeterminate></v-progress-circular>
                            <label class="overline font-weight-bold">Cargando información...</label>
                        </div>
                        <!-- Tabla Principal -->
                                       
    
                                            <!-- Segunda tabla con los empleados dependientes -->
                                            <v-data-table v-else :headers="headers" :items="employees" item-key="folio"
                                                class="elevation-1" show-expand :expanded.sync="expanded2"
                                                :single-expand="singleExpandEmployees" hide-default-footer
                                                @update:expanded2="onExpandEmployee">
                                                <!-- Slot para personalizar el ícono de expansión en tabla 2 -->
                                                <!-- Slot para cada fila expandible -->
                                                <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
                                                    <v-tooltip bottom v-if="item.CantColab > 0">
                                                        <template #activator="{ on, attrs }">
                                                            <v-btn v-bind="attrs" v-on="on" icon
                                                                @click="toggleExpand2_Resp(item, isExpanded, expand)"
                                                                :class="getButtonColorClass(item.CantColab)">
                                                                <v-icon>
                                                                    {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                                                                </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>
                                                            {{ isExpanded ? 'Haz click para cerrar a los colaboradores' :
                                                                'Haz click para ver a los colaboradores' }}
                                                        </span>
                                                    </v-tooltip>
    
                                                    <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                    <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                    <v-tooltip bottom v-else>
                                                        <template #activator="{ on, attrs }">
                                                            <div v-bind="attrs" v-on="on"
                                                                class="text-center red--text d-flex align-center justify-center">
                                                                <v-icon color="red">mdi-chevron-down</v-icon>
                                                            </div>
                                                        </template>
                                                        <span>No tiene colaboradores</span>
                                                    </v-tooltip>
    
    
                                                </template>
    
                                                <!-- Mostrar los detalles del empleado dependiente -->
                                                <!-- Mostrar los detalles del empleado dependiente -->
                                                <template v-slot:[`item.cal`]="{ item }">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                                @click="handleModalClick(item)">
                                                                {{ item.estatus === 1 || item.estatus === null ||
                                                                    item.estatus === 0 ? 'mdi-pencil-box-multiple-outline' :
                                                                    'mdi-clipboard-text-search-outline' }}
                                                            </v-icon>
                                                        </template>
                                                        <span
                                                            v-if="item.estatus === 1 || item.estatus === null || item.estatus === 0">
                                                            {{ item.estatus === null || item.estatus === 0 ? 'Editar valores para ' : 'Editar valores para' }} {{ item.Nombre
                                                            }}
                                                        </span>
                                                        <span v-else>Ver Calificación de Objetivos para {{ item.Nombre
                                                            }}</span>
                                                    </v-tooltip>
                                                </template>
    
    
    
                                                <template v-slot:[`item.dBonoDIR`]="{ item }">
                                                    <div class="text-center">
                                                        {{ item.dBonoDIR !== undefined ? item.dBonoDIR : '0.0' }}
                                                    </div>
                                                </template>
    
                                                <template v-slot:[`item.Nombre`]="{ item }">
                                                    <div
                                                        style="display: flex; align-items: center; gap: 8px; justify-content: flex-start;">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon v-bind="attrs" v-on="on" class="icon-size"
                                                                    :color="getFlagColor(item.estatus, item.grupo_Trabajo)">
                                                                    mdi-flag
                                                                </v-icon>
                                                            </template>
                                                            <span v-if="item.estatus === 2">Autorizado</span>
                                                            <span v-else-if="item.estatus === 0 || item.estatus === '' || item.estatus === null">
                                                                No tiene Objetivos
                                                            </span>
                                                            <span v-else-if="item.estatus === 1">No Autorizado</span>
                                                            <span v-if="item.grupo_Trabajo === 1">
                                                                No aplica al bono {{ item.grupo_Trabajo_Desc ? '(' + item.grupo_Trabajo_Desc + ')' : '' }}
                                                            </span>

    
                                                        </v-tooltip>
                                                        <div>
                                                            <strong>{{ item.Nombre }}</strong> <br />
                                                            <span style="color: gray;">{{ item.descPuesto }}</span>
                                                        </div>
                                                    </div>
                                                </template>
    
    
    
                                                <!-- Mostrar trimestres -->
                                                <template v-slot:[`item.Trim1`]="{ item }">
                                                    <div @click="openModal(item, 1)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 1 }">
                                                        {{ item.Trim1 }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Trim2`]="{ item }">
                                                    <div @click="openModal(item, 2)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 2 }">
                                                        {{ item.Trim2 }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Trim3`]="{ item }">
                                                    <div @click="openModal(item, 3)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 3 }">
                                                        {{ item.Trim3 }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Trim4`]="{ item }">
                                                    <div @click="openModal(item, 4)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 4 }">
                                                        {{ item.Trim4 }}
                                                    </div>
                                                </template>
    
    
                                                <!-- Personalizar columnas con colores -->
                                                <template v-slot:[`item.PuntosObjetivos`]="{ item }">
                                                    <div class="obj-text-blue">
                                                        {{ item.PuntosObjetivos }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.PuntosValores`]="{ item }">
                                                    <div class="cut-text-gray">
                                                        {{ item.PuntosValores }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Extras`]="{ item }">
                                                    <div class="ext-text-red">
                                                        {{ item.Extras }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Total`]="{ item }">
                                                    <div class="total">
                                                        {{ item.Total }}
                                                    </div>
                                                </template>
    
                                                <!-- Slot personalizado para la columna 'DET2' -->
                                                <template v-slot:[`item.det`]="{ item }">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                                @click="() => openVentanaEvaluacionTabla2(item)">
                                                                mdi-badge-account-outline
                                                            </v-icon>
                                                        </template>
                                                        <span>Haz clic aquí para ver los Detalles de evaluación de desempeño
                                                            para {{ item.Nombre }}</span>
                                                    </v-tooltip>
                                                </template>
    
    
                                                <!-- Aquí empieza la tercera tabla anidada tabla 3 -->
                                                <template v-slot:expanded-item="{ item, index }">
                                <td v-if="index !== 0" :colspan="headers.length">
                                    <v-card flat>
    
                                        <!-- Tercera tabla anidada Nivel 3 -->
                                        <v-card-title class="text-uppercase">Colaboradores de: {{ item.Nombre }}</v-card-title>
    
                                        <!-- Aqui irea el de cargar -->
                                        <!-- Indicador de carga -->
                                        <div v-if="loadingTable3" class="center-item-in-div-column">
                                            <v-progress-circular :size="150" :width="15" color="primary"
                                                indeterminate></v-progress-circular>
                                            <label class="overline font-weight-bold">Cargando información...</label>
                                        </div>
                                        <v-card-text v-else>
                                            <v-simple-table id="miTabla" dense style="background-color: transparent;">
                                                <thead v-if="!loadingTable">
                                                    <tr>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center rounded-simple-table-head-l"
                                                            colspan="5">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="1">
                                                            Evaluación</th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-r"
                                                            colspan="1">
                                                            Trimestres</th>
    
                                                    </tr>
    
    
                                                </thead>
    
    
                                            </v-simple-table>
    
                                            <!-- Tercera tabla con los empleados dependientes -->
                                            <v-data-table :headers="headers" :items="employeesNivel3" item-key="folio"
                                                class="elevation-1" show-expand :expanded.sync="expanded4"
                                                :single-expand="singleExpandEmployees" hide-default-footer
                                                @update:expanded4="onExpandEmployee">
                                                <!-- Slot para personalizar el ícono de expansión en tabla 3 -->
                                                <!-- Slot para cada fila expandible -->
                                                <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
                                                    <v-tooltip bottom v-if="item.CantColab > 0">
                                                        <template #activator="{ on, attrs }">
                                                            <v-btn v-bind="attrs" v-on="on" icon
                                                                @click="toggleExpand4_Resp(item, isExpanded, expand)"
                                                                :class="getButtonColorClass(item.CantColab)">
                                                                <v-icon>
                                                                    {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                                                                </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>
                                                            {{ isExpanded ? 'Haz click para cerrar a los colaboradores' :
                                                                'Haz click para ver a los colaboradores' }}
                                                        </span>
                                                    </v-tooltip>
    
                                                    <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                    <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                    <v-tooltip bottom v-else>
                                                        <template #activator="{ on, attrs }">
                                                            <div v-bind="attrs" v-on="on"
                                                                class="text-center red--text d-flex align-center justify-center">
                                                                <v-icon color="red">mdi-chevron-down</v-icon>
                                                            </div>
                                                        </template>
                                                        <span>No tiene colaboradores</span>
                                                    </v-tooltip>
    
    
                                                </template>
    
                                                <!-- Mostrar los detalles del empleado dependiente -->
                                                <!-- Mostrar los detalles del empleado dependiente -->
                                                <template v-slot:[`item.cal`]="{ item }">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                                @click="handleModalClick(item)">
                                                                {{ item.estatus === 1 || item.estatus === null ||
                                                                    item.estatus === 0 ? 'mdi-pencil-box-multiple-outline' :
                                                                    'mdi-clipboard-text-search-outline' }}
                                                            </v-icon>
                                                        </template>
                                                        <span
                                                            v-if="item.estatus === 1 || item.estatus === null || item.estatus === 0">
                                                            {{ item.estatus === null || item.estatus === 0 ? 'Editar valores para ' : 'Editar valores para' }} {{ item.Nombre
                                                            }}
                                                        </span>
                                                        <span v-else>Ver Calificación de Objetivos para {{ item.Nombre
                                                            }}</span>
                                                    </v-tooltip>
                                                </template>
    
    
    
                                                <template v-slot:[`item.dBonoDIR`]="{ item }">
                                                    <div class="text-center">
                                                        {{ item.dBonoDIR !== undefined ? item.dBonoDIR : '0.0' }}
                                                    </div>
                                                </template>
    
                                                <template v-slot:[`item.Nombre`]="{ item }">
                                                    <div
                                                        style="display: flex; align-items: center; gap: 8px; justify-content: flex-start;">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon v-bind="attrs" v-on="on" class="icon-size"
                                                                    :color="getFlagColor(item.estatus, item.grupo_Trabajo)">
                                                                    mdi-flag
                                                                </v-icon>
                                                            </template>
                                                            <span v-if="item.estatus === 2">Autorizado</span>
                                                            <span
                                                                v-else-if="item.estatus === 0 || item.estatus === '' || item.estatus === null">No
                                                                tiene Objetivos </span>
                                                            <span v-else-if="item.estatus === 1">No Autorizado</span>
                                                            <!-- <span v-if="item.grupo_Trabajo === 1">No aplica al bono</span> -->
                                                            <span v-if="item.grupo_Trabajo === 1">
                                                                No aplica al bono {{ item.grupo_Trabajo_Desc ? '(' +
                                                                item.grupo_Trabajo_Desc + ')' : '' }}
                                                            </span>
    
                                                        </v-tooltip>
                                                        <div>
                                                            <strong>{{ item.Nombre }}</strong> <br />
                                                            <span style="color: gray;">{{ item.descPuesto }}</span>
                                                        </div>
                                                    </div>
                                                </template>
    
    
    
                                                <!-- Mostrar trimestres -->
                                                <template v-slot:[`item.Trim1`]="{ item }">
                                                    <div @click="openModal(item, 1)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 1 }">
                                                        {{ item.Trim1 }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Trim2`]="{ item }">
                                                    <div @click="openModal(item, 2)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 2 }">
                                                        {{ item.Trim2 }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Trim3`]="{ item }">
                                                    <div @click="openModal(item, 3)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 3 }">
                                                        {{ item.Trim3 }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Trim4`]="{ item }">
                                                    <div @click="openModal(item, 4)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 4 }">
                                                        {{ item.Trim4 }}
                                                    </div>
                                                </template>
    
    
                                                <!-- Personalizar columnas con colores -->
                                                <template v-slot:[`item.PuntosObjetivos`]="{ item }">
                                                    <div class="obj-text-blue">
                                                        {{ item.PuntosObjetivos }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.PuntosValores`]="{ item }">
                                                    <div class="cut-text-gray">
                                                        {{ item.PuntosValores }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Extras`]="{ item }">
                                                    <div class="ext-text-red">
                                                        {{ item.Extras }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Total`]="{ item }">
                                                    <div class="total">
                                                        {{ item.Total }}
                                                    </div>
                                                </template>
    
                                                <!-- Slot personalizado para la columna 'DET2' -->
                                                <template v-slot:[`item.det`]="{ item }">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                                @click="() => openVentanaEvaluacionTabla2(item)">
                                                                mdi-badge-account-outline
                                                            </v-icon>
                                                        </template>
                                                        <span>Haz clic aquí para ver los Detalles de evaluación de desempeño
                                                            para {{ item.Nombre }}</span>
                                                    </v-tooltip>
                                                </template>
                                                <!-- Aqui ta tienes que pegar TABLA 4 -------------------------->
    
                                                <template v-slot:expanded-item="{ item, index }">
                                <td v-if="index !== 0" :colspan="headers.length">
                                    <v-card flat>
    
                                        <!-- Tercera tabla anidada Nivel 4 -->
                                        <v-card-title class="text-uppercase">Colaboradores de: {{ item.Nombre }}</v-card-title>
    
                                        <!-- Aqui irea el de cargar -->
                                        <!-- Indicador de carga -->
                                        <div v-if="loadingTable4" class="center-item-in-div-column">
                                            <v-progress-circular :size="150" :width="15" color="primary"
                                                indeterminate></v-progress-circular>
                                            <label class="overline font-weight-bold">Cargando información...</label>
                                        </div>
                                        <v-card-text v-else>
                                            <v-simple-table id="miTabla" dense style="background-color: transparent;">
                                                <thead v-if="!loadingTable">
                                                    <tr>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center rounded-simple-table-head-l"
                                                            colspan="5">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="1">
                                                            Evaluación</th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-r"
                                                            colspan="1">
                                                            Trimestres</th>
    
                                                    </tr>
    
    
                                                </thead>
    
    
                                            </v-simple-table>
    
                                            <!-- Cuarta tabla con los empleados dependientes -->
                                            <v-data-table :headers="headers" :items="employeesNivel4" item-key="folio"
                                                class="elevation-1" show-expand :expanded.sync="expanded5"
                                                :single-expand="singleExpandEmployees" hide-default-footer
                                                @update:expanded5="onExpandEmployee">
                                                <!-- Slot para personalizar el ícono de expansión en tabla 4 -->
                                                <!-- Slot para cada fila expandible -->
                                                <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
                                                    <v-tooltip bottom v-if="item.CantColab > 0">
                                                        <template #activator="{ on, attrs }">
                                                            <v-btn v-bind="attrs" v-on="on" icon
                                                                @click="toggleExpand5_Resp(item, isExpanded, expand)"
                                                                :class="getButtonColorClass(item.CantColab)">
                                                                <v-icon>
                                                                    {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                                                                </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>
                                                            {{ isExpanded ? 'Haz click para cerrar a los colaboradores' :
                                                                'Haz click para ver a los colaboradores' }}
                                                        </span>
                                                    </v-tooltip>
    
                                                    <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                    <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                    <v-tooltip bottom v-else>
                                                        <template #activator="{ on, attrs }">
                                                            <div v-bind="attrs" v-on="on"
                                                                class="text-center red--text d-flex align-center justify-center">
                                                                <v-icon color="red">mdi-chevron-down</v-icon>
                                                            </div>
                                                        </template>
                                                        <span>No tiene colaboradores</span>
                                                    </v-tooltip>
    
    
                                                </template>
    
                                                <!-- Mostrar los detalles del empleado dependiente -->
                                                <!-- Mostrar los detalles del empleado dependiente -->
                                                <template v-slot:[`item.cal`]="{ item }">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                                @click="handleModalClick(item)">
                                                                {{ item.estatus === 1 || item.estatus === null ||
                                                                    item.estatus === 0 ? 'mdi-pencil-box-multiple-outline' :
                                                                    'mdi-clipboard-text-search-outline' }}
                                                            </v-icon>
                                                        </template>
                                                        <span
                                                            v-if="item.estatus === 1 || item.estatus === null || item.estatus === 0">
                                                            {{ item.estatus === null || item.estatus === 0 ? 'Editar valores para ' : 'Editar valores para' }} {{ item.Nombre
                                                            }}
                                                        </span>
                                                        <span v-else>Ver Calificación de Objetivos para {{ item.Nombre
                                                            }}</span>
                                                    </v-tooltip>
                                                </template>
    
    
    
                                                <template v-slot:[`item.dBonoDIR`]="{ item }">
                                                    <div class="text-center">
                                                        {{ item.dBonoDIR !== undefined ? item.dBonoDIR : '0.0' }}
                                                    </div>
                                                </template>
    
                                                <template v-slot:[`item.Nombre`]="{ item }">
                                                    <div
                                                        style="display: flex; align-items: center; gap: 8px; justify-content: flex-start;">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon v-bind="attrs" v-on="on" class="icon-size"
                                                                    :color="getFlagColor(item.estatus, item.grupo_Trabajo)">
                                                                    mdi-flag
                                                                </v-icon>
                                                            </template>
                                                            <span v-if="item.estatus === 2">Autorizado</span>
                                                            <span
                                                                v-else-if="item.estatus === 0 || item.estatus === '' || item.estatus === null">No
                                                                tiene Objetivos </span>
                                                            <span v-else-if="item.estatus === 1">No Autorizado</span>
                                                            <!-- <span v-if="item.grupo_Trabajo === 1">No aplica al bono</span> -->
                                                            <span v-if="item.grupo_Trabajo === 1">
                                                                No aplica al bono {{ item.grupo_Trabajo_Desc ? '(' +
                                                                item.grupo_Trabajo_Desc + ')' : '' }}
                                                            </span>
    
                                                        </v-tooltip>
                                                        <div>
                                                            <strong>{{ item.Nombre }}</strong> <br />
                                                            <span style="color: gray;">{{ item.descPuesto }}</span>
                                                        </div>
                                                    </div>
                                                </template>
    
    
    
                                                <!-- Mostrar trimestres -->
                                                <template v-slot:[`item.Trim1`]="{ item }">
                                                    <div @click="openModal(item, 1)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 1 }">
                                                        {{ item.Trim1 }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Trim2`]="{ item }">
                                                    <div @click="openModal(item, 2)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 2 }">
                                                        {{ item.Trim2 }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Trim3`]="{ item }">
                                                    <div @click="openModal(item, 3)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 3 }">
                                                        {{ item.Trim3 }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Trim4`]="{ item }">
                                                    <div @click="openModal(item, 4)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 4 }">
                                                        {{ item.Trim4 }}
                                                    </div>
                                                </template>
    
    
                                                <!-- Personalizar columnas con colores -->
                                                <template v-slot:[`item.PuntosObjetivos`]="{ item }">
                                                    <div class="obj-text-blue">
                                                        {{ item.PuntosObjetivos }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.PuntosValores`]="{ item }">
                                                    <div class="cut-text-gray">
                                                        {{ item.PuntosValores }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Extras`]="{ item }">
                                                    <div class="ext-text-red">
                                                        {{ item.Extras }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Total`]="{ item }">
                                                    <div class="total">
                                                        {{ item.Total }}
                                                    </div>
                                                </template>
    
                                                <!-- Slot personalizado para la columna 'DET2' -->
                                                <template v-slot:[`item.det`]="{ item }">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                                @click="() => openVentanaEvaluacionTabla2(item)">
                                                                mdi-badge-account-outline
                                                            </v-icon>
                                                        </template>
                                                        <span>Haz clic aquí para ver los Detalles de evaluación de desempeño
                                                            para {{ item.Nombre }}</span>
                                                    </v-tooltip>
                                                </template>
    
                                                <!-- AQUI VA LA TABLA NIVEL 5 -->
                                                <template v-slot:expanded-item="{ item, index }">
                                <td v-if="index !== 0" :colspan="headers.length">
                                    <v-card flat>
    
                                        <!-- Tercera tabla anidada Nivel 5 -->
                                        <v-card-title class="text-uppercase">Colaboradores de: {{ item.Nombre }}</v-card-title>
    
                                        <!-- Aqui irea el de cargar -->
                                        <!-- Indicador de carga -->
                                        <div v-if="loadingTable5" class="center-item-in-div-column">
                                            <v-progress-circular :size="150" :width="15" color="primary"
                                                indeterminate></v-progress-circular>
                                            <label class="overline font-weight-bold">Cargando información...</label>
                                        </div>
                                        <v-card-text v-else>
                                            <v-simple-table id="miTabla" dense style="background-color: transparent;">
                                                <thead v-if="!loadingTable">
                                                    <tr>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center rounded-simple-table-head-l"
                                                            colspan="5">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="1">
                                                            Evaluación</th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-r"
                                                            colspan="1">
                                                            Trimestres</th>
    
                                                    </tr>
    
    
                                                </thead>
    
    
                                            </v-simple-table>
    
                                            <!-- Quinta tabla con los empleados dependientes -->
                                            <v-data-table :headers="headers" :items="employeesNivel5" item-key="folio"
                                                class="elevation-1" show-expand :expanded.sync="expanded6"
                                                :single-expand="singleExpandEmployees" hide-default-footer
                                                @update:expanded6="onExpandEmployee">
                                                <!-- Slot para personalizar el ícono de expansión en tabla 5 -->
                                                <!-- Slot para cada fila expandible -->
                                                <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
                                                    <v-tooltip bottom v-if="item.CantColab > 0">
                                                        <template #activator="{ on, attrs }">
                                                            <v-btn v-bind="attrs" v-on="on" icon
                                                                @click="toggleExpand6(item, isExpanded, expand)"
                                                                :class="getButtonColorClass(item.CantColab)">
                                                                <v-icon>
                                                                    {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                                                                </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>
                                                            {{ isExpanded ? 'Haz click para cerrar a los colaboradores' :
                                                                'Haz click para ver a los colaboradores' }}
                                                        </span>
                                                    </v-tooltip>
    
                                                    <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                    <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                    <v-tooltip bottom v-else>
                                                        <template #activator="{ on, attrs }">
                                                            <div v-bind="attrs" v-on="on"
                                                                class="text-center red--text d-flex align-center justify-center">
                                                                <v-icon color="red">mdi-chevron-down</v-icon>
                                                            </div>
                                                        </template>
                                                        <span>No tiene colaboradores</span>
                                                    </v-tooltip>
    
    
                                                </template>
    
                                                <!-- Mostrar los detalles del empleado dependiente -->
                                                <!-- Mostrar los detalles del empleado dependiente -->
                                                <template v-slot:[`item.cal`]="{ item }">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                                @click="handleModalClick(item)">
                                                                {{ item.estatus === 1 || item.estatus === null ||
                                                                    item.estatus === 0 ? 'mdi-pencil-box-multiple-outline' :
                                                                    'mdi-clipboard-text-search-outline' }}
                                                            </v-icon>
                                                        </template>
                                                        <span
                                                            v-if="item.estatus === 1 || item.estatus === null || item.estatus === 0">
                                                            {{ item.estatus === null || item.estatus === 0 ? 'Editar valores para ' : 'Editar valores para' }} {{ item.Nombre
                                                            }}
                                                        </span>
                                                        <span v-else>Ver Calificación de Objetivos para {{ item.Nombre
                                                            }}</span>
                                                    </v-tooltip>
                                                </template>
    
    
    
                                                <template v-slot:[`item.dBonoDIR`]="{ item }">
                                                    <div class="text-center">
                                                        {{ item.dBonoDIR !== undefined ? item.dBonoDIR : '0.0' }}
                                                    </div>
                                                </template>
    
                                                <template v-slot:[`item.Nombre`]="{ item }">
                                                    <div
                                                        style="display: flex; align-items: center; gap: 8px; justify-content: flex-start;">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon v-bind="attrs" v-on="on" class="icon-size"
                                                                    :color="getFlagColor(item.estatus, item.grupo_Trabajo)">
                                                                    mdi-flag
                                                                </v-icon>
                                                            </template>
                                                            <span v-if="item.estatus === 2">Autorizado</span>
                                                            <span
                                                                v-else-if="item.estatus === 0 || item.estatus === '' || item.estatus === null">No
                                                                tiene Objetivos </span>
                                                            <span v-else-if="item.estatus === 1">No Autorizado</span>
                                                            <!-- <span v-if="item.grupo_Trabajo === 1">No aplica al bono</span> -->
                                                            <span v-if="item.grupo_Trabajo === 1">
                                                                No aplica al bono {{ item.grupo_Trabajo_Desc ? '(' +
                                                                item.grupo_Trabajo_Desc + ')' : '' }}
                                                            </span>
    
                                                        </v-tooltip>
                                                        <div>
                                                            <strong>{{ item.Nombre }}</strong> <br />
                                                            <span style="color: gray;">{{ item.descPuesto }}</span>
                                                        </div>
                                                    </div>
                                                </template>
    
    
    
                                                <!-- Mostrar trimestres -->
                                                <template v-slot:[`item.Trim1`]="{ item }">
                                                    <div @click="openModal(item, 1)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 1 }">
                                                        {{ item.Trim1 }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Trim2`]="{ item }">
                                                    <div @click="openModal(item, 2)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 2 }">
                                                        {{ item.Trim2 }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Trim3`]="{ item }">
                                                    <div @click="openModal(item, 3)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 3 }">
                                                        {{ item.Trim3 }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Trim4`]="{ item }">
                                                    <div @click="openModal(item, 4)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 4 }">
                                                        {{ item.Trim4 }}
                                                    </div>
                                                </template>
    
    
                                                <!-- Personalizar columnas con colores -->
                                                <template v-slot:[`item.PuntosObjetivos`]="{ item }">
                                                    <div class="obj-text-blue">
                                                        {{ item.PuntosObjetivos }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.PuntosValores`]="{ item }">
                                                    <div class="cut-text-gray">
                                                        {{ item.PuntosValores }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Extras`]="{ item }">
                                                    <div class="ext-text-red">
                                                        {{ item.Extras }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Total`]="{ item }">
                                                    <div class="total">
                                                        {{ item.Total }}
                                                    </div>
                                                </template>
    
                                                <!-- Slot personalizado para la columna 'DET2' -->
                                                <template v-slot:[`item.det`]="{ item }">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                                @click="() => openVentanaEvaluacionTabla2(item)">
                                                                mdi-badge-account-outline
                                                            </v-icon>
                                                        </template>
                                                        <span>Haz clic aquí para ver los Detalles de evaluación de desempeño
                                                            para {{ item.Nombre }}</span>
                                                    </v-tooltip>
                                                </template>
    
                                                <!-- AQUI VA LA TABLA NIVEL 6 -->
    
                                                <template v-slot:expanded-item="{ item, index }">
                                <td v-if="index !== 0" :colspan="headers.length">
                                    <v-card flat>
    
                                        <!--  tabla anidada Nivel 6 -->
                                        <v-card-title class="text-uppercase">Colaboradores de: {{ item.Nombre }}</v-card-title>
    
                                        <!-- Aqui irea el de cargar -->
                                        <!-- Indicador de carga -->
                                        <div v-if="loadingTable6" class="center-item-in-div-column">
                                            <v-progress-circular :size="150" :width="15" color="primary"
                                                indeterminate></v-progress-circular>
                                            <label class="overline font-weight-bold">Cargando información...</label>
                                        </div>
                                        <v-card-text v-else>
                                            <v-simple-table id="miTabla" dense style="background-color: transparent;">
                                                <thead v-if="!loadingTable">
                                                    <tr>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center rounded-simple-table-head-l"
                                                            colspan="5">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="3">
                                                        </th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                            colspan="1">
                                                            Evaluación</th>
                                                        <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-r"
                                                            colspan="1">
                                                            Trimestres</th>
    
                                                    </tr>
    
    
                                                </thead>
    
    
                                            </v-simple-table>
    
                                            <!-- Sexta tabla con los empleados dependientes -->
                                            <v-data-table :headers="headers" :items="employeesNivel6" item-key="folio"
                                                class="elevation-1" show-expand :expanded.sync="expanded6"
                                                :single-expand="singleExpandEmployees" hide-default-footer
                                                @update:expanded6="onExpandEmployee">
                                                <!-- Slot para personalizar el ícono de expansión en tabla 6 -->
                                                <!-- Slot para cada fila expandible -->
                                                <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
                                                    <v-tooltip bottom v-if="item.CantColab > 0">
                                                        <template #activator="{ on, attrs }">
                                                            <v-btn v-bind="attrs" v-on="on" icon
                                                                @click="No_hace_nda(item, isExpanded, expand)"
                                                                :class="getButtonColorClass(item.CantColab)">
                                                                <v-icon>
                                                                    {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                                                                </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>
                                                            {{ isExpanded ? 'Haz click para cerrar a los colaboradores' :
                                                                'Haz click para ver a los colaboradores' }}
                                                        </span>
                                                    </v-tooltip>
    
                                                    <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                    <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                    <v-tooltip bottom v-else>
                                                        <template #activator="{ on, attrs }">
                                                            <div v-bind="attrs" v-on="on"
                                                                class="text-center red--text d-flex align-center justify-center">
                                                                <v-icon color="red">mdi-chevron-down</v-icon>
                                                            </div>
                                                        </template>
                                                        <span>No tiene colaboradores</span>
                                                    </v-tooltip>
    
    
                                                </template>
    
                                                <!-- Mostrar los detalles del empleado dependiente -->
                                                <!-- Mostrar los detalles del empleado dependiente -->
                                                <template v-slot:[`item.cal`]="{ item }">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                                @click="handleModalClick(item)">
                                                                {{ item.estatus === 1 || item.estatus === null ||
                                                                    item.estatus === 0 ? 'mdi-pencil-box-multiple-outline' :
                                                                    'mdi-clipboard-text-search-outline' }}
                                                            </v-icon>
                                                        </template>
                                                        <span
                                                            v-if="item.estatus === 1 || item.estatus === null || item.estatus === 0">
                                                            {{ item.estatus === null || item.estatus === 0 ? 'Editar valores para ' : 'Editar valores para' }} {{ item.Nombre
                                                            }}
                                                        </span>
                                                        <span v-else>Ver Calificación de Objetivos para {{ item.Nombre
                                                            }}</span>
                                                    </v-tooltip>
                                                </template>
    
    
    
                                                <template v-slot:[`item.dBonoDIR`]="{ item }">
                                                    <div class="text-center">
                                                        {{ item.dBonoDIR !== undefined ? item.dBonoDIR : '0.0' }}
                                                    </div>
                                                </template>
    
                                                <template v-slot:[`item.Nombre`]="{ item }">
                                                    <div
                                                        style="display: flex; align-items: center; gap: 8px; justify-content: flex-start;">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon v-bind="attrs" v-on="on" class="icon-size"
                                                                    :color="getFlagColor(item.estatus, item.grupo_Trabajo)">
                                                                    mdi-flag
                                                                </v-icon>
                                                            </template>
                                                            <span v-if="item.estatus === 2">Autorizado</span>
                                                            <span
                                                                v-else-if="item.estatus === 0 || item.estatus === '' || item.estatus === null">No
                                                                tiene Objetivos </span>
                                                            <span v-else-if="item.estatus === 1">No Autorizado</span>
                                                            <!-- <span v-if="item.grupo_Trabajo === 1">No aplica al bono</span> -->
                                                            <span v-if="item.grupo_Trabajo === 1">
                                                                No aplica al bono {{ item.grupo_Trabajo_Desc ? '(' +
                                                                item.grupo_Trabajo_Desc + ')' : '' }}
                                                            </span>
    
                                                        </v-tooltip>
                                                        <div>
                                                            <strong>{{ item.Nombre }}</strong> <br />
                                                            <span style="color: gray;">{{ item.descPuesto }}</span>
                                                        </div>
                                                    </div>
                                                </template>
    
    
    
                                                <!-- Mostrar trimestres -->
                                                <template v-slot:[`item.Trim1`]="{ item }">
                                                    <div @click="openModal(item, 1)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 1 }">
                                                        {{ item.Trim1 }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Trim2`]="{ item }">
                                                    <div @click="openModal(item, 2)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 2 }">
                                                        {{ item.Trim2 }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Trim3`]="{ item }">
                                                    <div @click="openModal(item, 3)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 3 }">
                                                        {{ item.Trim3 }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Trim4`]="{ item }">
                                                    <div @click="openModal(item, 4)"
                                                        :class="{ 'trimestre-azul': trimestreFinal === 4 }">
                                                        {{ item.Trim4 }}
                                                    </div>
                                                </template>
    
    
                                                <!-- Personalizar columnas con colores -->
                                                <template v-slot:[`item.PuntosObjetivos`]="{ item }">
                                                    <div class="obj-text-blue">
                                                        {{ item.PuntosObjetivos }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.PuntosValores`]="{ item }">
                                                    <div class="cut-text-gray">
                                                        {{ item.PuntosValores }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Extras`]="{ item }">
                                                    <div class="ext-text-red">
                                                        {{ item.Extras }}
                                                    </div>
                                                </template>
                                                <template v-slot:[`item.Total`]="{ item }">
                                                    <div class="total">
                                                        {{ item.Total }}
                                                    </div>
                                                </template>
    
                                                <!-- Slot personalizado para la columna 'DET2' -->
                                                <template v-slot:[`item.det`]="{ item }">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                                @click="() => openVentanaEvaluacionTabla2(item)">
                                                                mdi-badge-account-outline
                                                            </v-icon>
                                                        </template>
                                                        <span>Haz clic aquí para ver los Detalles de evaluación de desempeño
                                                            para {{ item.Nombre }}</span>
                                                    </v-tooltip>
                                                </template>
    
    
    
    
                                            </v-data-table>
                                        </v-card-text>
                                    </v-card>
                                </td>
                            </template>
    
    
                            </v-data-table>
                            </v-card-text>
                            </v-card>
                            </td>
                    </template>
    
    
                </v-data-table>
                </v-card-text>
                </v-card>
                </td>
    </template>
    <!-- ESTA ES LA TABLA 4 -->
    </v-data-table>
    </v-card-text>
    </v-card>
    </td>
    </template>
    <!-- Aquí termina la tercera tabla -->
    </v-data-table> <!-- Este es de la tabla 2 -->

    
    </div>
    </template>


            <!-- -------------------------------------- -->













            <!-- Aqui es la tabla final MASTER -------------------------------->

            <template v-else>
                <div>

                    <!-- Indicador de carga -->
                    <div v-if="loadingTable" class="center-item-in-div-column">
                        <v-progress-circular :size="150" :width="15" color="primary"
                            indeterminate></v-progress-circular>
                        <label class="overline font-weight-bold">Cargando información...</label>
                    </div>
                    <!-- Tabla Principal -->
                    <v-data-table v-else :headers="headers" :items="rows" :single-expand="singleExpand"
                        :expanded.sync="expanded" item-key="Nombre" show-expand class="elevation-1" hide-default-footer>
                        <!-- Slot personalizado para la columna 'DET' llevava index-->
                        <template v-slot:[`item.det`]="{ item, index }">
                            <v-tooltip bottom v-if="index !== 0">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                        @click="() => openVentanaEvaluacion(item)">
                                        mdi-badge-account-outline
                                    </v-icon>
                                </template>
                                <span>Haz clic aquí para ver los Detalles de evaluación de desempeño para {{ item.Nombre
                                    }}</span>
                            </v-tooltip>
                        </template>


                        <!-- esto es para expandir la tabla -->
                        <!-- Slot para cada fila expandible -->
                        <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand, index }">
                            <v-tooltip bottom v-if="index !== 0">
                                <template #activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon @click="toggleExpand(item, isExpanded, expand)"
                                        :class="getButtonColorClass(item.CantColab)">
                                        <v-icon>
                                            {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                    {{ isExpanded ? 'Haz click para cerrar a los colaboradores' : 'Haz click para ver a los colaboradores' }}
                                </span>
                            </v-tooltip>
                        </template>


                        <!-- Slot personalizado para la columna 'CAL' -->
                        <template v-slot:[`item.cal`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                        @click="openModalCalificacion(item)">
                                        mdi-clipboard-text-search-outline
                                    </v-icon>
                                </template>
                                <span>Ver Calificación de Objetivos para {{ item.Nombre }}</span>
                            </v-tooltip>
                        </template>

                  

                        <!-- Personalizar la columna NOMBRE -->
                        <template v-slot:[`item.Nombre`]="{ item }">
                            <div style="display: flex; align-items: center; gap: 8px; justify-content: flex-start;">
                                <!-- Autorizado (Icono con tooltip) -->
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="green" v-bind="attrs" v-on="on"
                                            class="icon-size">mdi-flag</v-icon>
                                    </template>
                                    <span>Autorizado</span>
                                </v-tooltip>

                                <!-- Nombre y Área -->
                                <div>
                                    <strong>{{ item.Nombre }}</strong> <br />
                                    <span style="color: gray;">{{ item.Area }}</span>
                                </div>
                            </div>
                        </template>

                        <!-- Mostrar trimestres -->
                        <template v-slot:[`item.Trim1`]="{ item }">
                            <div @click="openModal(item, 1)" :class="{ 'trimestre-azul': trimestreFinal === 1 }">
                                {{ item.Trim1 }}
                            </div>
                        </template>
                        <template v-slot:[`item.Trim2`]="{ item }">
                            <div @click="openModal(item, 2)" :class="{ 'trimestre-azul': trimestreFinal === 2 }">
                                {{ item.Trim2 }}
                            </div>
                        </template>
                        <template v-slot:[`item.Trim3`]="{ item }">
                            <div @click="openModal(item, 3)" :class="{ 'trimestre-azul': trimestreFinal === 3 }">
                                {{ item.Trim3 }}
                            </div>
                        </template>
                        <template v-slot:[`item.Trim4`]="{ item }">
                            <div @click="openModal(item, 4)" :class="{ 'trimestre-azul': trimestreFinal === 4 }">
                                {{ item.Trim4 }}
                            </div>
                        </template>

                        <!-- Personalizar columnas con colores -->
                        <template v-slot:[`item.PuntosObjetivos`]="{ item }">
                            <div class="obj-text-blue">{{ item.PuntosObjetivos }}</div>
                        </template>
                        <template v-slot:[`item.PuntosValores`]="{ item }">
                            <div class="cut-text-gray">{{ item.PuntosValores }}</div>
                        </template>
                        <template v-slot:[`item.Extras`]="{ item }">
                            <div class="ext-text-red">{{ item.Extras }}</div>
                        </template>
                        <template v-slot:[`item.Total`]="{ item }">
                            <div class="total">{{ item.Total }}</div>
                        </template>

                        <!-- Slot expandido para mostrar detalles adicionales del empleado  TABLA 2 ----------------------------------- -->
                        <template v-slot:expanded-item="{ headers, item, index }">
                            <td v-if="index !== 0" :colspan="headers.length">
                                <v-card flat>
                                    <v-card-title class="text-uppercase">Colaboradores de: {{ item.Nombre }}</v-card-title>

                                    <!-- Aqui irea el de cargar -->
                                    <!-- Indicador de carga -->
                                    <div v-if="loadingTable2" class="center-item-in-div-column">
                                        <v-progress-circular :size="150" :width="15" color="primary"
                                            indeterminate></v-progress-circular>
                                        <label class="overline font-weight-bold">Cargando información...</label>
                                    </div>
                                    <v-card-text v-else>
                                        <v-simple-table id="miTabla" dense style="background-color: transparent;">
                                            <thead v-if="!loadingTable">
                                                <tr>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center rounded-simple-table-head-l"
                                                        colspan="5">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="1">
                                                        Evaluación</th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-r"
                                                        colspan="1">
                                                        Trimestres</th>

                                                </tr>


                                            </thead>


                                        </v-simple-table>

                                        <!-- Segunda tabla con los empleados dependientes -->
                                        <v-data-table :headers="headers" :items="employees" item-key="folio"
                                            class="elevation-1" show-expand :expanded.sync="expanded2"
                                            :single-expand="singleExpandEmployees" hide-default-footer
                                            @update:expanded2="onExpandEmployee">
                                            <!-- Slot para personalizar el ícono de expansión en tabla 2 -->
                                            <!-- Slot para cada fila expandible -->
                                            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
                                                <v-tooltip bottom v-if="item.CantColab > 0">
                                                    <template #activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" icon
                                                            @click="toggleExpand2(item, isExpanded, expand)"
                                                            :class="getButtonColorClass(item.CantColab)">
                                                            <v-icon>
                                                                {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>
                                                        {{ isExpanded ? 'Haz click para cerrar a los colaboradores' :
                                                            'Haz click para ver a los colaboradores' }}
                                                    </span>
                                                </v-tooltip>

                                                <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                <v-tooltip bottom v-else>
                                                    <template #activator="{ on, attrs }">
                                                        <div v-bind="attrs" v-on="on"
                                                            class="text-center red--text d-flex align-center justify-center">
                                                            <v-icon color="red">mdi-chevron-down</v-icon>
                                                        </div>
                                                    </template>
                                                    <span>No tiene colaboradores</span>
                                                </v-tooltip>


                                            </template>

                                            <!-- Mostrar los detalles del empleado dependiente -->
                                            <!-- Mostrar los detalles del empleado dependiente -->
                                            <template v-slot:[`item.cal`]="{ item }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                            @click="handleModalClick(item)">
                                                            {{ item.estatus === 1 || item.estatus === null ||
                                                                item.estatus === 0 ? 'mdi-pencil-box-multiple-outline' :
                                                                'mdi-clipboard-text-search-outline' }}
                                                        </v-icon>
                                                    </template>
                                                    <span
                                                        v-if="item.estatus === 1 || item.estatus === null || item.estatus === 0">
                                                        {{ item.estatus === null || item.estatus === 0 ? 'Editar valores para ' : 'Editar valores para' }} {{ item.Nombre
                                                        }}
                                                    </span>
                                                    <span v-else>Ver Calificación de Objetivos para {{ item.Nombre
                                                        }}</span>
                                                </v-tooltip>
                                            </template>

                                                  <!-- Slot personalizado para la columna 'objetivos' -->
                                                  <template v-slot:[`item.objetivos`]="{ item }">
                                                    <template v-if="banderaChampion">
                                                      <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                          <v-icon class="icon-size" v-bind="attrs" v-on="on" @click="metodosObjPropios(item, 2)">
                                                            mdi-shield-alert-outline
                                                          </v-icon>
                                                        </template>
                                                        <span>Para ver en detalle los objetivos asignados a este puesto, haz clic aquí</span>
                                                      </v-tooltip>
                                                    </template>
                                                  </template>
                                                  




                                            <template v-slot:[`item.dBonoDIR`]="{ item }">
                                                <div class="text-center">
                                                    {{ item.dBonoDIR !== undefined ? item.dBonoDIR : '0.0' }}
                                                </div>
                                            </template>

                                            <template v-slot:[`item.Nombre`]="{ item }">
                                                <div
                                                    style="display: flex; align-items: center; gap: 8px; justify-content: flex-start;">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-bind="attrs" v-on="on" class="icon-size"
                                                                :color="getFlagColor(item.estatus, item.grupo_Trabajo)">
                                                                mdi-flag
                                                            </v-icon>
                                                        </template>
                                                        <span v-if="item.estatus === 2">Autorizado</span>
                                                        <span
                                                            v-else-if="item.estatus === 0 || item.estatus === '' || item.estatus === null">No
                                                            tiene Objetivos </span>
                                                        <span v-else-if="item.estatus === 1">No Autorizado</span>
                                                        <!-- <span v-if="item.grupo_Trabajo === 1">No aplica al bono</span> -->
                                                        <span v-if="item.grupo_Trabajo === 1">
                                                            No aplica al bono {{ item.grupo_Trabajo_Desc ? '(' +
                                                            item.grupo_Trabajo_Desc + ')' : '' }}
                                                        </span>

                                                    </v-tooltip>
                                                    <div>
                                                        <strong>{{ item.Nombre }}</strong> <br />
                                                        <span style="color: gray;">{{ item.descPuesto }}</span>
                                                    </div>
                                                </div>
                                            </template>



                                            <!-- Mostrar trimestres -->
                                            <template v-slot:[`item.Trim1`]="{ item }">
                                                <div @click="openModal(item, 1)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 1 }">
                                                    {{ item.Trim1 }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Trim2`]="{ item }">
                                                <div @click="openModal(item, 2)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 2 }">
                                                    {{ item.Trim2 }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Trim3`]="{ item }">
                                                <div @click="openModal(item, 3)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 3 }">
                                                    {{ item.Trim3 }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Trim4`]="{ item }">
                                                <div @click="openModal(item, 4)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 4 }">
                                                    {{ item.Trim4 }}
                                                </div>
                                            </template>


                                            <!-- Personalizar columnas con colores -->
                                            <template v-slot:[`item.PuntosObjetivos`]="{ item }">
                                                <div class="obj-text-blue">
                                                    {{ item.PuntosObjetivos }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.PuntosValores`]="{ item }">
                                                <div class="cut-text-gray">
                                                    {{ item.PuntosValores }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Extras`]="{ item }">
                                                <div class="ext-text-red">
                                                    {{ item.Extras }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Total`]="{ item }">
                                                <div class="total">
                                                    {{ item.Total }}
                                                </div>
                                            </template>

                                            <!-- Slot personalizado para la columna 'DET2' -->
                                            <template v-slot:[`item.det`]="{ item }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                            @click="() => openVentanaEvaluacionTabla2(item)">
                                                            mdi-badge-account-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>Haz clic aquí para ver los Detalles de evaluación de desempeño
                                                        para {{ item.Nombre }}</span>
                                                </v-tooltip>
                                            </template>


                                            <!-- Aquí empieza la tercera tabla anidada tabla 3 -->
                                            <template v-slot:expanded-item="{ item, index }">
                            <td v-if="index !== 0" :colspan="headers.length">
                                <v-card flat>

                                    <!-- Tercera tabla anidada Nivel 3 -->
                                    <v-card-title class="text-uppercase">Colaboradores de: {{ item.Nombre }}</v-card-title>

                                    <!-- Aqui irea el de cargar -->
                                    <!-- Indicador de carga -->
                                    <div v-if="loadingTable3" class="center-item-in-div-column">
                                        <v-progress-circular :size="150" :width="15" color="primary"
                                            indeterminate></v-progress-circular>
                                        <label class="overline font-weight-bold">Cargando información...</label>
                                    </div>
                                    <v-card-text v-else>
                                        <v-simple-table id="miTabla" dense style="background-color: transparent;">
                                            <thead v-if="!loadingTable">
                                                <tr>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center rounded-simple-table-head-l"
                                                        colspan="5">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="1">
                                                        Evaluación</th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-r"
                                                        colspan="1">
                                                        Trimestres</th>

                                                </tr>


                                            </thead>


                                        </v-simple-table>

                                        <!-- Tercera tabla con los empleados dependientes -->
                                        <v-data-table :headers="headers" :items="employeesNivel3" item-key="folio"
                                            class="elevation-1" show-expand :expanded.sync="expanded4"
                                            :single-expand="singleExpandEmployees" hide-default-footer
                                            @update:expanded4="onExpandEmployee">
                                            <!-- Slot para personalizar el ícono de expansión en tabla 3 -->
                                            <!-- Slot para cada fila expandible -->
                                            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
                                                <v-tooltip bottom v-if="item.CantColab > 0">
                                                    <template #activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" icon
                                                            @click="toggleExpand4(item, isExpanded, expand)"
                                                            :class="getButtonColorClass(item.CantColab)">
                                                            <v-icon>
                                                                {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>
                                                        {{ isExpanded ? 'Haz click para cerrar a los colaboradores' :
                                                            'Haz click para ver a los colaboradores' }}
                                                    </span>
                                                </v-tooltip>

                                                <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                <v-tooltip bottom v-else>
                                                    <template #activator="{ on, attrs }">
                                                        <div v-bind="attrs" v-on="on"
                                                            class="text-center red--text d-flex align-center justify-center">
                                                            <v-icon color="red">mdi-chevron-down</v-icon>
                                                        </div>
                                                    </template>
                                                    <span>No tiene colaboradores</span>
                                                </v-tooltip>


                                            </template>

                                            <!-- Mostrar los detalles del empleado dependiente -->
                                            <!-- Mostrar los detalles del empleado dependiente -->
                                            <template v-slot:[`item.cal`]="{ item }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                            @click="handleModalClick(item)">
                                                            {{ item.estatus === 1 || item.estatus === null ||
                                                                item.estatus === 0 ? 'mdi-pencil-box-multiple-outline' :
                                                                'mdi-clipboard-text-search-outline' }}
                                                        </v-icon>
                                                    </template>
                                                    <span
                                                        v-if="item.estatus === 1 || item.estatus === null || item.estatus === 0">
                                                        {{ item.estatus === null || item.estatus === 0 ? 'Editar valores para ' : 'Editar valores para' }} {{ item.Nombre
                                                        }}
                                                    </span>
                                                    <span v-else>Ver Calificación de Objetivos para {{ item.Nombre
                                                        }}</span>
                                                </v-tooltip>
                                            </template>

                                                  <!-- Slot personalizado para la columna 'objetivos' -->
                                                  <template v-slot:[`item.objetivos`]="{ item }">
                                                    <template v-if="banderaChampion">
                                                      <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                          <v-icon class="icon-size" v-bind="attrs" v-on="on" @click="metodosObjPropios(item, 3)">
                                                            mdi-shield-alert-outline
                                                          </v-icon>
                                                        </template>
                                                        <span>Para ver en detalle los objetivos asignados a este puesto, haz clic aquí</span>
                                                      </v-tooltip>
                                                    </template>
                                                  </template>
                                                  




                                            <template v-slot:[`item.dBonoDIR`]="{ item }">
                                                <div class="text-center">
                                                    {{ item.dBonoDIR !== undefined ? item.dBonoDIR : '0.0' }}
                                                </div>
                                            </template>

                                            <template v-slot:[`item.Nombre`]="{ item }">
                                                <div
                                                    style="display: flex; align-items: center; gap: 8px; justify-content: flex-start;">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-bind="attrs" v-on="on" class="icon-size"
                                                                :color="getFlagColor(item.estatus, item.grupo_Trabajo)">
                                                                mdi-flag
                                                            </v-icon>
                                                        </template>
                                                        <span v-if="item.estatus === 2">Autorizado</span>
                                                        <span
                                                            v-else-if="item.estatus === 0 || item.estatus === '' || item.estatus === null">No
                                                            tiene Objetivos </span>
                                                        <span v-else-if="item.estatus === 1">No Autorizado</span>
                                                        <!-- <span v-if="item.grupo_Trabajo === 1">No aplica al bono</span> -->
                                                        <span v-if="item.grupo_Trabajo === 1">
                                                            No aplica al bono {{ item.grupo_Trabajo_Desc ? '(' +
                                                            item.grupo_Trabajo_Desc + ')' : '' }}
                                                        </span>

                                                    </v-tooltip>
                                                    <div>
                                                        <strong>{{ item.Nombre }}</strong> <br />
                                                        <span style="color: gray;">{{ item.descPuesto }}</span>
                                                    </div>
                                                </div>
                                            </template>



                                            <!-- Mostrar trimestres -->
                                            <template v-slot:[`item.Trim1`]="{ item }">
                                                <div @click="openModal(item, 1)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 1 }">
                                                    {{ item.Trim1 }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Trim2`]="{ item }">
                                                <div @click="openModal(item, 2)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 2 }">
                                                    {{ item.Trim2 }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Trim3`]="{ item }">
                                                <div @click="openModal(item, 3)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 3 }">
                                                    {{ item.Trim3 }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Trim4`]="{ item }">
                                                <div @click="openModal(item, 4)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 4 }">
                                                    {{ item.Trim4 }}
                                                </div>
                                            </template>


                                            <!-- Personalizar columnas con colores -->
                                            <template v-slot:[`item.PuntosObjetivos`]="{ item }">
                                                <div class="obj-text-blue">
                                                    {{ item.PuntosObjetivos }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.PuntosValores`]="{ item }">
                                                <div class="cut-text-gray">
                                                    {{ item.PuntosValores }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Extras`]="{ item }">
                                                <div class="ext-text-red">
                                                    {{ item.Extras }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Total`]="{ item }">
                                                <div class="total">
                                                    {{ item.Total }}
                                                </div>
                                            </template>

                                            <!-- Slot personalizado para la columna 'DET2' -->
                                            <template v-slot:[`item.det`]="{ item }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                            @click="() => openVentanaEvaluacionTabla2(item)">
                                                            mdi-badge-account-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>Haz clic aquí para ver los Detalles de evaluación de desempeño
                                                        para {{ item.Nombre }}</span>
                                                </v-tooltip>
                                            </template>
                                            <!-- Aqui ta tienes que pegar TABLA 4 -------------------------->

                                            <template v-slot:expanded-item="{ item, index }">
                            <td v-if="index !== 0" :colspan="headers.length">
                                <v-card flat>

                                    <!-- Tercera tabla anidada Nivel 4 -->
                                    <v-card-title class="text-uppercase">Colaboradores de: {{ item.Nombre }}</v-card-title>

                                    <!-- Aqui irea el de cargar -->
                                    <!-- Indicador de carga -->
                                    <div v-if="loadingTable4" class="center-item-in-div-column">
                                        <v-progress-circular :size="150" :width="15" color="primary"
                                            indeterminate></v-progress-circular>
                                        <label class="overline font-weight-bold">Cargando información...</label>
                                    </div>
                                    <v-card-text v-else>
                                        <v-simple-table id="miTabla" dense style="background-color: transparent;">
                                            <thead v-if="!loadingTable">
                                                <tr>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center rounded-simple-table-head-l"
                                                        colspan="5">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="1">
                                                        Evaluación</th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-r"
                                                        colspan="1">
                                                        Trimestres</th>

                                                </tr>


                                            </thead>


                                        </v-simple-table>

                                        <!-- Cuarta tabla con los empleados dependientes -->
                                        <v-data-table :headers="headers" :items="employeesNivel4" item-key="folio"
                                            class="elevation-1" show-expand :expanded.sync="expanded5"
                                            :single-expand="singleExpandEmployees" hide-default-footer
                                            @update:expanded5="onExpandEmployee">
                                            <!-- Slot para personalizar el ícono de expansión en tabla 4 -->
                                            <!-- Slot para cada fila expandible -->
                                            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
                                                <v-tooltip bottom v-if="item.CantColab > 0">
                                                    <template #activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" icon
                                                            @click="toggleExpand5(item, isExpanded, expand)"
                                                            :class="getButtonColorClass(item.CantColab)">
                                                            <v-icon>
                                                                {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>
                                                        {{ isExpanded ? 'Haz click para cerrar a los colaboradores' :
                                                            'Haz click para ver a los colaboradores' }}
                                                    </span>
                                                </v-tooltip>

                                                <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                <v-tooltip bottom v-else>
                                                    <template #activator="{ on, attrs }">
                                                        <div v-bind="attrs" v-on="on"
                                                            class="text-center red--text d-flex align-center justify-center">
                                                            <v-icon color="red">mdi-chevron-down</v-icon>
                                                        </div>
                                                    </template>
                                                    <span>No tiene colaboradores</span>
                                                </v-tooltip>


                                            </template>

                                            <!-- Mostrar los detalles del empleado dependiente -->
                                            <!-- Mostrar los detalles del empleado dependiente -->
                                            <template v-slot:[`item.cal`]="{ item }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                            @click="handleModalClick(item)">
                                                            {{ item.estatus === 1 || item.estatus === null ||
                                                                item.estatus === 0 ? 'mdi-pencil-box-multiple-outline' :
                                                                'mdi-clipboard-text-search-outline' }}
                                                        </v-icon>
                                                    </template>
                                                    <span
                                                        v-if="item.estatus === 1 || item.estatus === null || item.estatus === 0">
                                                        {{ item.estatus === null || item.estatus === 0 ? 'Editar valores para ' : 'Editar valores para' }} {{ item.Nombre
                                                        }}
                                                    </span>
                                                    <span v-else>Ver Calificación de Objetivos para {{ item.Nombre
                                                        }}</span>
                                                </v-tooltip>
                                            </template>

                                            <!-- Slot personalizado para la columna 'objetivos' -->
                                            <template v-slot:[`item.objetivos`]="{ item }">
                                                <template v-if="banderaChampion">
                                                  <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                      <v-icon class="icon-size" v-bind="attrs" v-on="on" @click="metodosObjPropios(item, 3)">
                                                        mdi-shield-alert-outline
                                                      </v-icon>
                                                    </template>
                                                    <span>Para ver en detalle los objetivos asignados a este puesto, haz clic aquí</span>
                                                  </v-tooltip>
                                                </template>
                                              </template>
                                              




                                            <template v-slot:[`item.dBonoDIR`]="{ item }">
                                                <div class="text-center">
                                                    {{ item.dBonoDIR !== undefined ? item.dBonoDIR : '0.0' }}
                                                </div>
                                            </template>

                                            <template v-slot:[`item.Nombre`]="{ item }">
                                                <div
                                                    style="display: flex; align-items: center; gap: 8px; justify-content: flex-start;">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-bind="attrs" v-on="on" class="icon-size"
                                                                :color="getFlagColor(item.estatus, item.grupo_Trabajo)">
                                                                mdi-flag
                                                            </v-icon>
                                                        </template>
                                                        <span v-if="item.estatus === 2">Autorizado</span>
                                                        <span
                                                            v-else-if="item.estatus === 0 || item.estatus === '' || item.estatus === null">No
                                                            tiene Objetivos </span>
                                                        <span v-else-if="item.estatus === 1">No Autorizado</span>
                                                        <!-- <span v-if="item.grupo_Trabajo === 1">No aplica al bono</span> -->
                                                        <span v-if="item.grupo_Trabajo === 1">
                                                            No aplica al bono {{ item.grupo_Trabajo_Desc ? '(' +
                                                            item.grupo_Trabajo_Desc + ')' : '' }}
                                                        </span>

                                                    </v-tooltip>
                                                    <div>
                                                        <strong>{{ item.Nombre }}</strong> <br />
                                                        <span style="color: gray;">{{ item.descPuesto }}</span>
                                                    </div>
                                                </div>
                                            </template>



                                            <!-- Mostrar trimestres -->
                                            <template v-slot:[`item.Trim1`]="{ item }">
                                                <div @click="openModal(item, 1)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 1 }">
                                                    {{ item.Trim1 }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Trim2`]="{ item }">
                                                <div @click="openModal(item, 2)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 2 }">
                                                    {{ item.Trim2 }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Trim3`]="{ item }">
                                                <div @click="openModal(item, 3)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 3 }">
                                                    {{ item.Trim3 }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Trim4`]="{ item }">
                                                <div @click="openModal(item, 4)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 4 }">
                                                    {{ item.Trim4 }}
                                                </div>
                                            </template>


                                            <!-- Personalizar columnas con colores -->
                                            <template v-slot:[`item.PuntosObjetivos`]="{ item }">
                                                <div class="obj-text-blue">
                                                    {{ item.PuntosObjetivos }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.PuntosValores`]="{ item }">
                                                <div class="cut-text-gray">
                                                    {{ item.PuntosValores }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Extras`]="{ item }">
                                                <div class="ext-text-red">
                                                    {{ item.Extras }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Total`]="{ item }">
                                                <div class="total">
                                                    {{ item.Total }}
                                                </div>
                                            </template>

                                            <!-- Slot personalizado para la columna 'DET2' -->
                                            <template v-slot:[`item.det`]="{ item }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                            @click="() => openVentanaEvaluacionTabla2(item)">
                                                            mdi-badge-account-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>Haz clic aquí para ver los Detalles de evaluación de desempeño
                                                        para {{ item.Nombre }}</span>
                                                </v-tooltip>
                                            </template>

                                            <!-- AQUI VA LA TABLA NIVEL 5 -->
                                            <template v-slot:expanded-item="{ item, index }">
                            <td v-if="index !== 0" :colspan="headers.length">
                                <v-card flat>

                                    <!-- Tercera tabla anidada Nivel 5 -->
                                    <v-card-title class="text-uppercase">Colaboradores de: {{ item.Nombre }}</v-card-title>

                                    <!-- Aqui irea el de cargar -->
                                    <!-- Indicador de carga -->
                                    <div v-if="loadingTable5" class="center-item-in-div-column">
                                        <v-progress-circular :size="150" :width="15" color="primary"
                                            indeterminate></v-progress-circular>
                                        <label class="overline font-weight-bold">Cargando información...</label>
                                    </div>
                                    <v-card-text v-else>
                                        <v-simple-table id="miTabla" dense style="background-color: transparent;">
                                            <thead v-if="!loadingTable">
                                                <tr>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center rounded-simple-table-head-l"
                                                        colspan="5">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="1">
                                                        Evaluación</th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-r"
                                                        colspan="1">
                                                        Trimestres</th>

                                                </tr>


                                            </thead>


                                        </v-simple-table>

                                        <!-- Quinta tabla con los empleados dependientes -->
                                        <v-data-table :headers="headers" :items="employeesNivel5" item-key="folio"
                                            class="elevation-1" show-expand :expanded.sync="expanded6"
                                            :single-expand="singleExpandEmployees" hide-default-footer
                                            @update:expanded6="onExpandEmployee">
                                            <!-- Slot para personalizar el ícono de expansión en tabla 5 -->
                                            <!-- Slot para cada fila expandible -->
                                            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
                                                <v-tooltip bottom v-if="item.CantColab > 0">
                                                    <template #activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" icon
                                                            @click="toggleExpand6(item, isExpanded, expand)"
                                                            :class="getButtonColorClass(item.CantColab)">
                                                            <v-icon>
                                                                {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>
                                                        {{ isExpanded ? 'Haz click para cerrar a los colaboradores' :
                                                            'Haz click para ver a los colaboradores' }}
                                                    </span>
                                                </v-tooltip>

                                                <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                <v-tooltip bottom v-else>
                                                    <template #activator="{ on, attrs }">
                                                        <div v-bind="attrs" v-on="on"
                                                            class="text-center red--text d-flex align-center justify-center">
                                                            <v-icon color="red">mdi-chevron-down</v-icon>
                                                        </div>
                                                    </template>
                                                    <span>No tiene colaboradores</span>
                                                </v-tooltip>


                                            </template>

                                            <!-- Mostrar los detalles del empleado dependiente -->
                                            <!-- Mostrar los detalles del empleado dependiente -->
                                            <template v-slot:[`item.cal`]="{ item }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                            @click="handleModalClick(item)">
                                                            {{ item.estatus === 1 || item.estatus === null ||
                                                                item.estatus === 0 ? 'mdi-pencil-box-multiple-outline' :
                                                                'mdi-clipboard-text-search-outline' }}
                                                        </v-icon>
                                                    </template>
                                                    <span
                                                        v-if="item.estatus === 1 || item.estatus === null || item.estatus === 0">
                                                        {{ item.estatus === null || item.estatus === 0 ? 'Editar valores para ' : 'Editar valores para' }} {{ item.Nombre
                                                        }}
                                                    </span>
                                                    <span v-else>Ver Calificación de Objetivos para {{ item.Nombre
                                                        }}</span>
                                                </v-tooltip>
                                            </template>

                                            <!-- Slot personalizado para la columna 'objetivos' -->
                                            <template v-slot:[`item.objetivos`]="{ item }">
                                                <template v-if="banderaChampion">
                                                  <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                      <v-icon class="icon-size" v-bind="attrs" v-on="on" @click="metodosObjPropios(item, 3)">
                                                        mdi-shield-alert-outline
                                                      </v-icon>
                                                    </template>
                                                    <span>Para ver en detalle los objetivos asignados a este puesto, haz clic aquí</span>
                                                  </v-tooltip>
                                                </template>
                                              </template>
                                              




                                            <template v-slot:[`item.dBonoDIR`]="{ item }">
                                                <div class="text-center">
                                                    {{ item.dBonoDIR !== undefined ? item.dBonoDIR : '0.0' }}
                                                </div>
                                            </template>

                                            <template v-slot:[`item.Nombre`]="{ item }">
                                                <div
                                                    style="display: flex; align-items: center; gap: 8px; justify-content: flex-start;">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-bind="attrs" v-on="on" class="icon-size"
                                                                :color="getFlagColor(item.estatus, item.grupo_Trabajo)">
                                                                mdi-flag
                                                            </v-icon>
                                                        </template>
                                                        <span v-if="item.estatus === 2">Autorizado</span>
                                                        <span
                                                            v-else-if="item.estatus === 0 || item.estatus === '' || item.estatus === null">No
                                                            tiene Objetivos </span>
                                                        <span v-else-if="item.estatus === 1">No Autorizado</span>
                                                        <!-- <span v-if="item.grupo_Trabajo === 1">No aplica al bono</span> -->
                                                        <span v-if="item.grupo_Trabajo === 1">
                                                            No aplica al bono {{ item.grupo_Trabajo_Desc ? '(' +
                                                            item.grupo_Trabajo_Desc + ')' : '' }}
                                                        </span>

                                                    </v-tooltip>
                                                    <div>
                                                        <strong>{{ item.Nombre }}</strong> <br />
                                                        <span style="color: gray;">{{ item.descPuesto }}</span>
                                                    </div>
                                                </div>
                                            </template>



                                            <!-- Mostrar trimestres -->
                                            <template v-slot:[`item.Trim1`]="{ item }">
                                                <div @click="openModal(item, 1)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 1 }">
                                                    {{ item.Trim1 }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Trim2`]="{ item }">
                                                <div @click="openModal(item, 2)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 2 }">
                                                    {{ item.Trim2 }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Trim3`]="{ item }">
                                                <div @click="openModal(item, 3)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 3 }">
                                                    {{ item.Trim3 }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Trim4`]="{ item }">
                                                <div @click="openModal(item, 4)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 4 }">
                                                    {{ item.Trim4 }}
                                                </div>
                                            </template>


                                            <!-- Personalizar columnas con colores -->
                                            <template v-slot:[`item.PuntosObjetivos`]="{ item }">
                                                <div class="obj-text-blue">
                                                    {{ item.PuntosObjetivos }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.PuntosValores`]="{ item }">
                                                <div class="cut-text-gray">
                                                    {{ item.PuntosValores }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Extras`]="{ item }">
                                                <div class="ext-text-red">
                                                    {{ item.Extras }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Total`]="{ item }">
                                                <div class="total">
                                                    {{ item.Total }}
                                                </div>
                                            </template>

                                            <!-- Slot personalizado para la columna 'DET2' -->
                                            <template v-slot:[`item.det`]="{ item }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                            @click="() => openVentanaEvaluacionTabla2(item)">
                                                            mdi-badge-account-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>Haz clic aquí para ver los Detalles de evaluación de desempeño
                                                        para {{ item.Nombre }}</span>
                                                </v-tooltip>
                                            </template>

                                            <!-- AQUI VA LA TABLA NIVEL 6 -->

                                            <template v-slot:expanded-item="{ item, index }">
                            <td v-if="index !== 0" :colspan="headers.length">
                                <v-card flat>

                                    <!--  tabla anidada Nivel 6 -->
                                    <v-card-title class="text-uppercase">Colaboradores de: {{ item.Nombre }}</v-card-title>

                                    <!-- Aqui irea el de cargar -->
                                    <!-- Indicador de carga -->
                                    <div v-if="loadingTable6" class="center-item-in-div-column">
                                        <v-progress-circular :size="150" :width="15" color="primary"
                                            indeterminate></v-progress-circular>
                                        <label class="overline font-weight-bold">Cargando información...</label>
                                    </div>
                                    <v-card-text v-else>
                                        <v-simple-table id="miTabla" dense style="background-color: transparent;">
                                            <thead v-if="!loadingTable">
                                                <tr>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center rounded-simple-table-head-l"
                                                        colspan="5">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="3">
                                                    </th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary "
                                                        colspan="1">
                                                        Evaluación</th>
                                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-r"
                                                        colspan="1">
                                                        Trimestres</th>

                                                </tr>


                                            </thead>


                                        </v-simple-table>

                                        <!-- Sexta tabla con los empleados dependientes -->
                                        <v-data-table :headers="headers" :items="employeesNivel6" item-key="folio"
                                            class="elevation-1" show-expand :expanded.sync="expanded6"
                                            :single-expand="singleExpandEmployees" hide-default-footer
                                            @update:expanded6="onExpandEmployee">
                                            <!-- Slot para personalizar el ícono de expansión en tabla 6 -->
                                            <!-- Slot para cada fila expandible -->
                                            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
                                                <v-tooltip bottom v-if="item.CantColab > 0">
                                                    <template #activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" icon
                                                            @click="No_hace_nda(item, isExpanded, expand)"
                                                            :class="getButtonColorClass(item.CantColab)">
                                                            <v-icon>
                                                                {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>
                                                        {{ isExpanded ? 'Haz click para cerrar a los colaboradores' :
                                                            'Haz click para ver a los colaboradores' }}
                                                    </span>
                                                </v-tooltip>

                                                <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                <!-- Mensaje alternativo cuando CantColab es 0 -->
                                                <v-tooltip bottom v-else>
                                                    <template #activator="{ on, attrs }">
                                                        <div v-bind="attrs" v-on="on"
                                                            class="text-center red--text d-flex align-center justify-center">
                                                            <v-icon color="red">mdi-chevron-down</v-icon>
                                                        </div>
                                                    </template>
                                                    <span>No tiene colaboradores</span>
                                                </v-tooltip>


                                            </template>

                                            <!-- Mostrar los detalles del empleado dependiente -->
                                            <!-- Mostrar los detalles del empleado dependiente -->
                                            <template v-slot:[`item.cal`]="{ item }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                            @click="handleModalClick(item)">
                                                            {{ item.estatus === 1 || item.estatus === null ||
                                                                item.estatus === 0 ? 'mdi-pencil-box-multiple-outline' :
                                                                'mdi-clipboard-text-search-outline' }}
                                                        </v-icon>
                                                    </template>
                                                    <span
                                                        v-if="item.estatus === 1 || item.estatus === null || item.estatus === 0">
                                                        {{ item.estatus === null || item.estatus === 0 ? 'Editar valores para ' : 'Editar valores para' }} {{ item.Nombre
                                                        }}
                                                    </span>
                                                    <span v-else>Ver Calificación de Objetivos para {{ item.Nombre
                                                        }}</span>
                                                </v-tooltip>
                                            </template>

                                            <!-- Slot personalizado para la columna 'objetivos' -->
                                            <template v-slot:[`item.objetivos`]="{ item }">
                                                <template v-if="banderaChampion">
                                                  <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                      <v-icon class="icon-size" v-bind="attrs" v-on="on" @click="metodosObjPropios(item ,3)">
                                                        mdi-shield-alert-outline
                                                      </v-icon>
                                                    </template>
                                                    <span>Para ver en detalle los objetivos asignados a este puesto, haz clic aquí</span>
                                                  </v-tooltip>
                                                </template>
                                              </template>
                                              




                                            <template v-slot:[`item.dBonoDIR`]="{ item }">
                                                <div class="text-center">
                                                    {{ item.dBonoDIR !== undefined ? item.dBonoDIR : '0.0' }}
                                                </div>
                                            </template>

                                            <template v-slot:[`item.Nombre`]="{ item }">
                                                <div
                                                    style="display: flex; align-items: center; gap: 8px; justify-content: flex-start;">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-bind="attrs" v-on="on" class="icon-size"
                                                                :color="getFlagColor(item.estatus, item.grupo_Trabajo)">
                                                                mdi-flag
                                                            </v-icon>
                                                        </template>
                                                        <span v-if="item.estatus === 2">Autorizado</span>
                                                        <span
                                                            v-else-if="item.estatus === 0 || item.estatus === '' || item.estatus === null">No
                                                            tiene Objetivos </span>
                                                        <span v-else-if="item.estatus === 1">No Autorizado</span>
                                                        <!-- <span v-if="item.grupo_Trabajo === 1">No aplica al bono</span> -->
                                                        <span v-if="item.grupo_Trabajo === 1">
                                                            No aplica al bono {{ item.grupo_Trabajo_Desc ? '(' +
                                                            item.grupo_Trabajo_Desc + ')' : '' }}
                                                        </span>

                                                    </v-tooltip>
                                                    <div>
                                                        <strong>{{ item.Nombre }}</strong> <br />
                                                        <span style="color: gray;">{{ item.descPuesto }}</span>
                                                    </div>
                                                </div>
                                            </template>



                                            <!-- Mostrar trimestres -->
                                            <template v-slot:[`item.Trim1`]="{ item }">
                                                <div @click="openModal(item, 1)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 1 }">
                                                    {{ item.Trim1 }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Trim2`]="{ item }">
                                                <div @click="openModal(item, 2)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 2 }">
                                                    {{ item.Trim2 }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Trim3`]="{ item }">
                                                <div @click="openModal(item, 3)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 3 }">
                                                    {{ item.Trim3 }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Trim4`]="{ item }">
                                                <div @click="openModal(item, 4)"
                                                    :class="{ 'trimestre-azul': trimestreFinal === 4 }">
                                                    {{ item.Trim4 }}
                                                </div>
                                            </template>


                                            <!-- Personalizar columnas con colores -->
                                            <template v-slot:[`item.PuntosObjetivos`]="{ item }">
                                                <div class="obj-text-blue">
                                                    {{ item.PuntosObjetivos }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.PuntosValores`]="{ item }">
                                                <div class="cut-text-gray">
                                                    {{ item.PuntosValores }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Extras`]="{ item }">
                                                <div class="ext-text-red">
                                                    {{ item.Extras }}
                                                </div>
                                            </template>
                                            <template v-slot:[`item.Total`]="{ item }">
                                                <div class="total">
                                                    {{ item.Total }}
                                                </div>
                                            </template>

                                            <!-- Slot personalizado para la columna 'DET2' -->
                                            <template v-slot:[`item.det`]="{ item }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon class="icon-size" v-bind="attrs" v-on="on"
                                                            @click="() => openVentanaEvaluacionTabla2(item)">
                                                            mdi-badge-account-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>Haz clic aquí para ver los Detalles de evaluación de desempeño
                                                        para {{ item.Nombre }}</span>
                                                </v-tooltip>
                                            </template>




                                        </v-data-table>
                                    </v-card-text>
                                </v-card>
                            </td>
                        </template>


                        </v-data-table>
                        </v-card-text>
                        </v-card>
                        </td>
                </template>


            </v-data-table>
            </v-card-text>
            </v-card>
            </td>
</template>
<!-- ESTA ES LA TABLA 4 -->
</v-data-table>
</v-card-text>
</v-card>
</td>
</template>
<!-- Aquí termina la tercera tabla -->
</v-data-table> <!-- Este es de la tabla 2 -->
</v-card-text>
</v-card>
</td>
</template> <!-- Este es de la tabla 2 AQUI TERMINA-->
</v-data-table>


</div>
</template>

<!-- AQUI TERMINA LA TABLA FINAL -->
 <!-- Esto es para lo de autorizar objetivos -->
 
 <v-dialog v-model="dialogBTNAutorizar" max-width="500" content-class="fix-border-dialog">

    <v-card v-if="loadingTableEditar" class="center-item-in-div-column">
        <v-progress-circular :size="150" :width="15" color="primary"
            indeterminate></v-progress-circular>
        <label class="overline font-weight-bold">Cargando información...</label>
    </v-card>

    <v-card v-else class="rounded-xl">
        <v-card-title class="primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold">Confirmar Autorización</v-card-title>
        <v-card-text>¿Está seguro que desea autorizar los valores para este trimestre?</v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="dialogBTNAutorizar = false">Cancelar</v-btn>
            <v-btn color="success" outlined @click="autorizarValoresMeses">Confirmar</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>

<!-- Dialogo de confirmación de editar -->
<v-dialog v-model="dialogBTNEditar" max-width="500">

    <v-card v-if="loadingTableEditar" class="center-item-in-div-column">
        <v-progress-circular :size="150" :width="15" color="primary"
            indeterminate></v-progress-circular>
        <label class="overline font-weight-bold">Cargando información...</label>
    </v-card>

    <v-card v-else>
        <v-card-title class="headline">Confirmar Modificaciones</v-card-title>
        <v-card-text>¿Está seguro que desea guardar valores editados para este trimestre?</v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogBTNEditar = false">Cancelar</v-btn>
            <v-btn color="blue darken-1" text @click="editarValoresMeses">Confirmar</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>


<!-- Dialogo de confirmación de eliminar -->
<v-dialog v-model="dialogBTNEeliminar" max-width="600">
    <v-card v-if="loadingTableEditar" class="center-item-in-div-column">
        <v-progress-circular :size="150" :width="15" color="primary"
            indeterminate></v-progress-circular>
        <label class="overline font-weight-bold">Cargando información...</label>
    </v-card>
    <v-card v-else>
        <v-card-title class="headline">Confirmar Modificaciones</v-card-title>
        <v-card-text>¿Está seguro que desea eliminar los valores de todos los objetivos para este trimestre?</v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogBTNEeliminar = false">Cancelar</v-btn>
            <v-btn color="blue darken-1" text @click="eliminarValoresMeses">Confirmar</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>

<v-dialog v-model="banderaEliminarObj" max-width="600">
    <v-card v-if="loadingTableEliminarObj" class="center-item-in-div-column">
        <v-progress-circular :size="150" :width="15" color="primary"
            indeterminate></v-progress-circular>
        <label class="overline font-weight-bold">Cargando información...</label>
    </v-card>
    <v-card v-else >
        
        <v-card-title class="headline">Confirmar Eliminación</v-card-title>
        <v-card-text>¿Está seguro que desea eliminar el objetivo seleccionado?</v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="banderaEliminarObj = false">Cancelar</v-btn>
            <v-btn color="blue darken-1" text @click="eliminarObjetivo">Confirmar</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>

<template>
        <!-- Modal flotante para la evaluación del trimestre -->
        
        <v-dialog v-if="dialog === true" v-model="dialog" persistent max-width="1200px" scrollable>

            <v-card class="rounded-xl p-0">

                <!-- Iterar sobre las áreas dinámicamente y generar una tabla para cada una -->

                <v-toolbar class="primary white--text text-uppercase  sticky-toolbar no-padding" flat dense>
                    <v-toolbar-title>OBJETIVOS ASIGNADOS A: {{ NamePerson }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="cerrarDialog">
                        <v-icon color="white">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-divider></v-divider>

                <v-card-text>

                        <div>
                            <p><strong>Folio:</strong> {{ selectedItem }}</p>
                            <p><strong>Trimestre Seleccionado:</strong> {{ trimestreSeleccionado }}</p>
                        </div>
                        <v-spacer></v-spacer>


                    <!-- Mostrar logo de carga mientras loadingTable sea true -->
                    <div v-show="loadingTabledialog23" class="center-item-in-div-column">
                        <v-progress-circular :size="150" :width="15" color="primary"
                            indeterminate></v-progress-circular>
                        <label class="overline font-weight-bold">Cargando información...</label>
                    </div>

                    <!-- Tabla de evaluación, oculta mientras loadingTabledialog23 sea true -->
                    <v-simple-table v-show="!loadingTabledialog23">
                        <thead>

                            <tr>
                                <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-l"
                                    colspan="5" style="text-align: center;"></th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-r"
                                    colspan="6" style="text-align: center;">Valor Real</th>
                            </tr>
                            <tr>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">Objetivo</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">VO</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">LE</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">Ponderación</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">Tipo de Cálculo</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">{{ trimestreMeses[0] }}</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">{{ trimestreMeses[1] }}</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">{{ trimestreMeses[2] }}</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">Resultado</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">Desempeño</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">Puntos</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(objetivo, index) in Id_IndicadorMes" :key="index">
                                <td>{{ DescObjetivoMes[index] }}</td>
                                <td style="text-align: center;">{{ VOMes[index] }}</td>
                                <td style="text-align: center;">{{ LEMes[index] }}</td>
                                <td style="text-align: center;">{{ PonderacionMes[index] }}</td>
                                <td style="text-align: center;">{{ TipoCalculoMes[index] }}</td>
                                <td style="text-align: center;">{{ ValObjPrimerMes[index] }}</td>
                                <td style="text-align: center;">{{ ValObjSegundoMes[index] }}</td>
                                <td style="text-align: center;">{{ ValObjTercero[index] }}</td>
                                <td style="text-align: center;">{{ calculoReal[index].toFixed(2) }}</td>
                                <td style="text-align: center;">{{ desempeno[index].toFixed(2) }}</td>
                                <td style="text-align: center;">{{ puntosNew[index].toFixed(2) }}</td>
                            </tr>
                           
                            <tr>
                                <td style="text-align: center;" colspan="3" class="text-right font-weight-bold">
                                    Ponderación Total:</td>
                                <td style="text-align: center;" class="font-weight-bold">{{ totalPonderacion ?
                                    totalPonderacion.toFixed(2) : '0.00' }}</td>
                                <td style="text-align: center;" colspan="6" class="text-right font-weight-bold">Puntos
                                    Totales:</td>
                                <td style="text-align: center;" class="font-weight-bold">{{ totalPuntos ?
                                    totalPuntos.toFixed(2) : '0.00' }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>

                    <v-row v-show="!loadingTabledialog23" justify="center">
                     <v-spacer></v-spacer>
                        <v-col cols="auto" v-if="estatusGlobal[this.folioFinal] === 1">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                
                                  class="white--text text-uppercase font-weight-bold text-center"
                                  v-bind="attrs"
                                  v-on="on"
                                  outlined
                                  @click="autorizarObjetivos()"
                                  color="success"
                                >
                                  <v-icon>{{ 'mdi-check' }}</v-icon>&nbsp; Autorizar Objetivos
                                </v-btn>
                              </template>
                              <span>Autorizar los valores de los objetivos del trimestre</span>
                            </v-tooltip>
                          </v-col>
                          
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- ------------------------------------------- -->

         <!-- Estte es el que se habre cuando edito -->
         <!-- <v-dialog v-if="dialogEditarMeses === true" v-model="dialogEditarMeses" persistent max-width="1200px"> -->
            <v-dialog v-if="dialogEditarMeses === true" v-model="dialogEditarMeses" persistent class="custom-dialog" scrollable>

            <v-card class="rounded-xl p-0">


                <v-toolbar class="primary white--text text-uppercase  sticky-toolbar no-padding" flat dense>
                    <v-toolbar-title>EDITAR OBJETIVOS ASIGNADOS A: {{ selectedItem }} {{ NamePerson }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="cerrarDialog">
                        <v-icon color="white">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-divider></v-divider>

                <v-card-text>

                    <v-card-actions>
                        <!-- <div>
                            <p><strong>Folio:</strong> {{ selectedItem }}</p>
                            <p><strong>Trimestre Seleccionado:</strong> {{ trimestreSeleccionado }}</p>
                        </div> -->
                        <v-spacer></v-spacer>

                    </v-card-actions>

                    <!-- Mostrar logo de carga mientras loadingTable sea true -->
                    <div v-show="loadingTabledialog23" class="center-item-in-div-column">
                        <v-progress-circular :size="150" :width="15" color="primary"
                            indeterminate></v-progress-circular>
                        <label class="overline font-weight-bold">Cargando información...</label>
                    </div>

                    <!-- Tabla de evaluación, oculta mientras loadingTabledialog23 sea true -->
                    <v-simple-table v-show="!loadingTabledialog23">
                        <thead>

                            <!-- <tr>
                                <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-l"
                                    colspan="5" style="text-align: center;"></th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-r"
                                    colspan="9" style="text-align: center;">Valor Real</th>
                            </tr> -->
                            <tr>
                                <th class="primary white--text text-uppercase font-weight-bold text-center rounded-simple-table-head-l"
                                    style="text-align: center;" rowspan="2">ID</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">Objetivo</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">VO</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">LE</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">Ponderación</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">Tipo de Cálculo</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">{{ trimestreMeses[0] }}</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">{{ trimestreMeses[1] }}</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">{{ trimestreMeses[2] }}</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">Resultado</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">Desempeño</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center rounded-simple-table-head-r"
                                    style="text-align: center;">Puntos</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(objetivo, index) in Id_IndicadorMes" :key="index">
                                <td style="text-aling: center;"> {{Id_IndicadorMes[index]}}</td>
                                <td>{{ DescObjetivoMes[index] }}</td>
                                <td style="text-align: center;">{{ VOMes[index] }}</td>
                                <td style="text-align: center;">{{ LEMes[index] }}</td>
                                <td style="text-align: center;">{{ PonderacionMes[index] }}</td>
                                <td style="text-align: center;">{{ TipoCalculoMes[index] }}</td>
                                <!-- <td style="text-align: center;">{{ ValObjPrimerMes[index] }}</td>
                                <td style="text-align: center;">{{ ValObjSegundoMes[index] }}</td>
                                <td style="text-align: center;">{{ ValObjTercero[index] }}</td> -->
                                <td style="text-align: center;">
                                    <input class="small-input"  v-model="ValObjPrimerMes[index]"
                                        @input="updateValue(index, 'ValorPrimer', $event)" type="number"
                                        placeholder="Ingrese valor" />
                                </td>
                                <td style="text-align: center;">
                                    <input class="small-input"  v-model="ValObjSegundoMes[index]"
                                        @input="updateValue(index, 'ValorSegundo', $event)" type="number"
                                        placeholder="Ingrese valor" />
                                </td>
                                <td style="text-align: center;">
                                    <input class="small-input"  v-model="ValObjTercero[index]"
                                        @input="updateValue(index, 'ValorTercero', $event)" type="number"
                                        placeholder="Ingrese valor" />
                                </td>
                                <td style="text-align: center;">{{ calculoReal[index].toFixed(2) }}</td>
                                <td style="text-align: center;">{{ desempeno[index].toFixed(2) }}</td>
                                <td style="text-align: center;">{{ puntosNew[index].toFixed(2) }}</td>
                            </tr>
                           
                            <tr>
                                <td style="text-align: center;" colspan="4" class="text-right font-weight-bold">
                                    Ponderación Total:</td>
                                <td style="text-align: center;" class="font-weight-bold">{{ totalPonderacion ?
                                    totalPonderacion.toFixed(2) : '0.00' }}</td>
                                <td style="text-align: center;" colspan="6" class="text-right font-weight-bold">Puntos
                                    Totales:</td>
                                <td style="text-align: center;" class="font-weight-bold">{{ totalPuntos ?
                                    totalPuntos.toFixed(2) : '0.00' }}</td>
                            </tr>
                        </tbody>

                        
                    </v-simple-table>

                
                    <v-row v-show="!loadingTabledialog23" justify="center">
                        <v-col cols="auto">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                outlined
                                color="primary"
                                class="text-uppercase font-weight-bold text-center"
                                v-bind="attrs"
                                v-on="on"
                                @click="elimiarTodosMeses()"
                              >
                                <v-icon>{{ 'mdi-delete-sweep-outline' }}</v-icon>&nbsp; Eliminar valores
                              </v-btn>
                            </template>
                            <span>Eliminar todos los valores del trimestre</span>
                          </v-tooltip>
                        </v-col>
                      
                        <v-col cols="auto">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="primary white--text text-uppercase font-weight-bold text-center"
                                v-bind="attrs"
                                v-on="on"
                                @click="guardarCambiosEditados()"
                              >
                                <v-icon>{{ 'mdi-content-save' }}</v-icon>&nbsp; Guardar Cambios
                              </v-btn>
                            </template>
                            <span>Guardar los cambios editados</span>
                          </v-tooltip>
                        </v-col>

                        <v-col cols="auto" v-if="estatusGlobal[this.folioFinal] === 1">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  class="primary white--text text-uppercase font-weight-bold text-center"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="autorizarObjetivos()"
                                >
                                  <v-icon>{{ 'mdi-check' }}</v-icon>&nbsp; Autorizar Objetivos
                                </v-btn>
                              </template>
                              <span>Autorizar los valores de los objetivos del trimestre</span>
                            </v-tooltip>
                          </v-col>
                    </v-row>
                      


                </v-card-text>


            </v-card>
        </v-dialog>

        
        

        <!-- Este es para mostrar los valores del trimestre 1,2,3,4 -->
        <v-dialog v-else-if="dialogTrimestres1234 === true" v-model="dialogTrimestres1234" persistent max-width="1200px" scrollable>

            <v-card class="rounded-xl p-0">

                <!-- Iterar sobre las áreas dinámicamente y generar una tabla para cada una -->

                <v-toolbar class="primary white--text text-uppercase  sticky-toolbar no-padding" flat dense>
                    <v-toolbar-title>OBJETIVOS ASIGNADOS A: {{ NamePerson }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="cerrarDialog">
                        <v-icon color="white">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-divider></v-divider>

                <v-card-text>
                        <div>
                            <p><strong>Folio:</strong> {{ selectedItem }}</p>
                            <p><strong>Trimestre Seleccionado:</strong> {{ trimestreSeleccionado }}</p>
                        </div>
                        <v-spacer></v-spacer>
                    <!-- Mostrar logo de carga mientras loadingTable sea true -->
                    <div v-show="loadingTabledialog23" class="center-item-in-div-column">
                        <v-progress-circular :size="150" :width="15" color="primary"
                            indeterminate></v-progress-circular>
                        <label class="overline font-weight-bold">Cargando información...</label>
                    </div>

                    <!-- Tabla de evaluación, oculta mientras loadingTable sea true -->
                    <v-simple-table v-show="!loadingTabledialog23">
                        <thead>

                            <tr>
                                <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-l"
                                    colspan="5" style="text-align: center;"></th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-r"
                                    colspan="6" style="text-align: center;">Valor Real</th>
                            </tr>
                            <tr>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">Objetivo</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">VO</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">LE</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">Ponderación</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">Tipo de Cálculo</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">{{ trimestreMeses[0] }}</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">{{ trimestreMeses[1] }}</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">{{ trimestreMeses[2] }}</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">Resultado</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">Desempeño</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">Puntos</th>
                            </tr>
                        </thead>

                        <tbody>
                            
                            <tr v-for="(objetivo, index) in Id_IndicadorMes" :key="index">
                                <td>{{ DescObjetivoMes[index] }}</td>
                                <td style="text-align: center;">{{ VOMes[index] }}</td>
                                <td style="text-align: center;">{{ LEMes[index] }}</td>
                                <td style="text-align: center;">{{ PonderacionMes[index] }}</td>
                                <td style="text-align: center;">{{ TipoCalculoMes[index] }}</td>
                                <td style="text-align: center;">{{ ValObjPrimerMes[index] }}</td>
                                <td style="text-align: center;">{{ ValObjSegundoMes[index] }}</td>
                                <td style="text-align: center;">{{ ValObjTercero[index] }}</td>
                                <td style="text-align: center;">{{ dReal[index].toFixed(2) }}</td>
                                <td style="text-align: center;">{{ Desemp[index].toFixed(2) }}</td>
                                <td style="text-align: center;">{{ Puntos[index].toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <td style="text-align: center;" colspan="3" class="text-right font-weight-bold">
                                    Ponderación Total:</td>
                                <td style="text-align: center;" class="font-weight-bold">{{ totalPonderacion ?
                                    totalPonderacion.toFixed(2) : '0.00' }}</td>
                                <td style="text-align: center;" colspan="6" class="text-right font-weight-bold">Puntos
                                    Totales:</td>
                                <td style="text-align: center;" class="font-weight-bold">{{ totalPuntos ?
                                    totalPuntos.toFixed(2) : '0.00' }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>


                </v-card-text>


            </v-card>
        </v-dialog>

        <!-- Esta es para editar los valores  -->
        <v-dialog v-else-if="dialogEditar" v-model="dialogEditar" persistent max-width="1200px" scrollable>
            <v-card class="rounded-xl p-0">

                <!-- Iterar sobre las áreas dinámicamente y generar una tabla para cada una -->

                <v-toolbar class="primary white--text text-uppercase  sticky-toolbar no-padding" flat dense>
                    <v-toolbar-title>OBJETIVOS ASIGNADOS A: {{ NamePerson }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="cerrarDialog">
                        <v-icon color="white">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-divider></v-divider>

                <v-card-text>

                        <div>
                            <p><strong>Folio:</strong> {{ selectedItem }}</p>
                            <p><strong>Trimestre Seleccionado:</strong> {{ trimestreSeleccionado }}</p>
                        </div>
                        <v-spacer></v-spacer>

                    <!-- Mostrar logo de carga mientras loadingTable sea true -->
                    <div v-show="loadingTabledialog23" class="center-item-in-div-column">
                        <v-progress-circular :size="150" :width="15" color="primary"
                            indeterminate></v-progress-circular>
                        <label class="overline font-weight-bold">Cargando información...</label>
                    </div>

                    <!-- Tabla de evaluación, oculta mientras loadingTable sea true -->
                    <v-simple-table v-show="!loadingTabledialog23">
                        <thead>

                            <tr>
                                <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-l"
                                    colspan="5" style="text-align: center;"></th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-r"
                                    colspan="6" style="text-align: center;">Valor Real</th>
                            </tr>
                            <tr>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">Objetivo</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">VO</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">LE</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">Ponderación</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">Tipo de Cálculo</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">{{ trimestreMeses[0] }}</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">{{ trimestreMeses[1] }}</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">{{ trimestreMeses[2] }}</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">Resultado</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">Desempeño</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">Puntos</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(objetivo, index) in Id_IndicadorMes" :key="index">
                                <td>{{ DescObjetivoMes[index] }}</td>
                                <td style="text-align: center;">{{ VOMes[index] }}</td>
                                <td style="text-align: center;">{{ LEMes[index] }}</td>
                                <td style="text-align: center;">{{ PonderacionMes[index] }}</td>
                                <td style="text-align: center;">{{ TipoCalculoMes[index] }}</td>
                                <td style="text-align: center;">{{ ValObjPrimerMes[index] }}</td>
                                <td style="text-align: center;">{{ ValObjSegundoMes[index] }}</td>
                                <td style="text-align: center;">{{ ValObjTercero[index] }}</td>
                                <td style="text-align: center;">{{ dReal[index].toFixed(2) }}</td>
                                <td style="text-align: center;">{{ Desemp[index].toFixed(2) }}</td>
                                <td style="text-align: center;">{{ Puntos[index].toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <td style="text-align: center;" colspan="3" class="text-right font-weight-bold">
                                    Ponderación Total:</td>
                                <td style="text-align: center;" class="font-weight-bold">{{ totalPonderacion ?
                                    totalPonderacion.toFixed(2) : '0.00' }}</td>
                                <td style="text-align: center;" colspan="6" class="text-right font-weight-bold">Puntos
                                    Totales:</td>
                                <td style="text-align: center;" class="font-weight-bold">{{ totalPuntos ?
                                    totalPuntos.toFixed(2) : '0.00' }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>

                    <v-row justify="center">
                        <v-col cols="auto">
                            <v-btn class="primary white--text text-uppercase font-weight-bold text-center"
                                @click="cancelar(item)">
                                <v-icon>{{ 'mdi-close' }}</v-icon>&nbsp; Rechazar 1
                            </v-btn>
                        </v-col>

                        <v-col cols="auto">
                            <v-btn class="primary white--text text-uppercase font-weight-bold text-center"
                                @click="aceptar(item)">
                                <v-icon>{{ 'mdi-check' }}</v-icon>&nbsp; Aceptar 1
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-card-text>


            </v-card>
        </v-dialog>



        <!-- Aqui es la tabla 2 por si la persona tiene 2 areas diferentes: -->

        <v-dialog v-else-if="dialog2" v-model="dialog2" persistent max-width="1200px" scrollable>

            <template>
                <v-card class="rounded-xl p-0">

                    <!-- Iterar sobre las áreas dinámicamente y generar una tabla para cada una -->


                    <v-toolbar class="primary white--text text-uppercase  sticky-toolbar no-padding" flat dense>
                        <v-toolbar-title>OBJETIVOS ASIGNADOS A: {{ nombreTabla1 }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="cerrarDialog2">
                            <v-icon color="white">mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-divider></v-divider>

                    <v-card-text>
                            <v-row class="align-center" style="width: 100%;">
                                <v-col>
                                    <p><strong>Trimestre Seleccionado:</strong> {{ trimestreSeleccionado }}</p>
                                    <p><strong>Área:</strong> {{ area1 }}</p>
                                </v-col>
                                <v-spacer></v-spacer> <!-- Empuja el botón hacia la derecha -->
                            </v-row>

                        <!-- Mostrar logo de carga mientras loadingTable sea true -->
                        <div v-show="loadingTabledialog23" class="center-item-in-div-column">
                            <v-progress-circular :size="150" :width="15" color="primary"
                                indeterminate></v-progress-circular>
                            <label class="overline font-weight-bold">Cargando información...</label>
                        </div>

                        <!-- Tabla de evaluación, oculta mientras loadingTable sea true -->
                        <!-- tabla 1 -->
                        <v-simple-table v-show="!loadingTabledialog23">
                            <thead>
                                <tr>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-l"
                                        colspan="6" style="text-align: center;"></th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-r"
                                        colspan="6" style="text-align: center;">Valor Real</th>
                                </tr>
                                <tr>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" rowspan="2">ID Objetivo</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" rowspan="2">Objetivo EspecÍfico</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" rowspan="2">VO</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" rowspan="2">LE</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" rowspan="2">Ponderación</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" rowspan="2">Unidad</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">{{ trimestreMeses[0] }}</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">{{ trimestreMeses[1] }}</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">{{ trimestreMeses[2] }}</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">Resultado</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">Desempeño</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">Puntos</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(id, index) in Id_Indicador1" :key="id">
                                    <td style="text-align: center;">{{ id }}</td>
                                    <td>{{ ObjetivoEspecifico1[index] }}</td>
                                    <td style="text-align: center;">{{ VO1[index] }}</td>
                                    <td style="text-align: center;">{{ LE1[index] }}</td>
                                    <td style="text-align: center;">{{ Ponderacion1[index] }}</td>
                                    <td style="text-align: center;">{{ Unidad1[index] }}</td>
                                    <td style="text-align: center;">{{ mes1t1[index] }}</td>
                                    <td style="text-align: center;">{{ mes2t1[index] }}</td>
                                    <td style="text-align: center;">{{ mes3t1[index] }}</td>
                                    <!-- Verifica que real, desempenio y puntos estén definidos y tengan el índice correspondiente -->
                                    <!-- <td :style="getCellStyle(id.desempenio)">{{ id.dReal1.toFixed(1) }}</td> -->
                                    <td v-if="dReal1 && dReal1.length > index"
                                        :style="getCellStyle(desempenio[index], real[index])">
                                        {{ dReal1[index].toFixed(1) }}
                                    </td>
                                    <td v-else>-</td>
                                    <!-- Mostrar un valor por defecto si no hay datos disponibles -->



                                    <td style="text-align: center;" v-if="desempenio && desempenio.length > index">
                                        {{
                                            desempenio[index].toFixed(1) }}</td>
                                    <td v-else>-</td>

                                    <td style="text-align: center;" v-if="puntos && puntos.length > index">{{
                                        puntos[index].toFixed(1) }}</td>
                                    <td v-else>-</td>
                                </tr>
                                <tr>
                                    <td colspan="4"></td>
                                    <td class="font-weight-bold text-center">Ponderación Total: {{
                                        this.ponderacionSuma1 }}</td>

                                    <td colspan="6"></td>
                                    <td class="font-weight-bold text-center">Puntos Totales: {{
                                        this.totalPuntos.toFixed(1) }}</td>

                                </tr>



                            </tbody>


                        </v-simple-table>


                        <v-card-actions>
                            <div>

                                <p><strong>Trimestre Seleccionado:</strong> {{ trimestreSeleccionado }}</p>
                                <p><strong>Área:</strong> {{ area2 }}</p>
                            </div>
                        </v-card-actions>

                        <!-- Mostrar logo de carga mientras loadingTable sea true -->
                        <div v-show="loadingTabledialog23" class="center-item-in-div-column">
                            <v-progress-circular :size="150" :width="15" color="primary"
                                indeterminate></v-progress-circular>
                            <label class="overline font-weight-bold">Cargando información...</label>
                        </div>


                        <!-- tabla 2 -->
                        <v-simple-table v-show="!loadingTabledialog23">
                            <thead>
                                <tr>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-l"
                                        colspan="6" style="text-align: center;"></th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-r"
                                        colspan="6" style="text-align: center;">Valor Real</th>
                                </tr>
                                <tr>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" rowspan="2">ID Objetivo</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" rowspan="2">Objetivo EspecÍfico</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" rowspan="2">VO</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" rowspan="2">LE</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" rowspan="2">Ponderación</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" rowspan="2">Unidad</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">{{ trimestreMeses[0] }}</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">{{ trimestreMeses[1] }}</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">{{ trimestreMeses[2] }}</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">Resultado</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">Desempeño</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">Puntos</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(id, index) in Id_Indicador2" :key="id">
                                    <td style="text-align: center;">{{ id }}</td>
                                    <td>{{ ObjetivoEspecifico2[index] }}</td>
                                    <td style="text-align: center;">{{ VO2[index] }}</td>
                                    <td style="text-align: center;">{{ LE2[index] }}</td>
                                    <td style="text-align: center;">{{ Ponderacion2[index] }}</td>
                                    <td style="text-align: center;">{{ Unidad2[index] }}</td>
                                    <td style="text-align: center;">{{ mes1t2[index] }}</td>
                                    <td style="text-align: center;">{{ mes2t2[index] }}</td>
                                    <td style="text-align: center;">{{ mes3t2[index] }}</td>
                                    <!-- Verifica que real, desempenio y puntos estén definidos y tengan el índice correspondiente -->
                                    <!-- <td :style="getCellStyle(id.desempenio)">{{ id.dReal1.toFixed(1) }}</td> -->
                                    <td v-if="dReal2 && dReal2.length > index"
                                        :style="getCellStyle2(desempenio2[index], real2[index])">
                                        {{ dReal2[index].toFixed(1) }}
                                    </td>
                                    <td v-else>-</td>
                                    <!-- Mostrar un valor por defecto si no hay datos disponibles -->



                                    <td style="text-align: center;" v-if="desempenio2 && desempenio2.length > index">{{
                                        desempenio2[index].toFixed(1) }}</td>
                                    <td v-else>-</td>

                                    <td style="text-align: center;" v-if="puntos2 && puntos2.length > index">{{
                                        puntos2[index].toFixed(1) }}</td>
                                    <td v-else>-</td>
                                </tr>
                                <tr>
                                    <td colspan="4"></td>
                                    <td class="font-weight-bold text-center">Ponderación Total: {{
                                        this.ponderacionSuma2 }}</td>

                                    <td colspan="6"></td>
                                    <td class="font-weight-bold text-center">Puntos Totales: {{
                                        this.totalPuntos.toFixed(1) }}</td>

                                </tr>



                            </tbody>
                        </v-simple-table>



                    </v-card-text>

                </v-card>


            </template>





        </v-dialog>


        <!-- Aqui es la tabla 2 por si la persona tiene 1 areas  -->

        <v-dialog v-else-if="dialog23" v-model="dialog23" persistent max-width="1200px" scrollable>

            <template>
                <v-card class="rounded-xl p-0">

                    <!-- Iterar sobre las áreas dinámicamente y generar una tabla para cada una -->

                    <v-toolbar class="primary white--text text-uppercase  sticky-toolbar no-padding" flat dense>
                        <v-toolbar-title>OBJETIVOS ASIGNADOS A: {{ nombreTabla1 }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="cerrarDialog2">
                            <v-icon color="white">mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-divider></v-divider>

                    <v-card-text>
                            <v-row class="align-center" style="width: 100%;">
                                <v-col>

                                    <p><strong>Trimestre Seleccionado:</strong> {{ trimestreSeleccionado }}</p>
                                    <p><strong>Área:</strong> {{ area1 }}</p>
                                </v-col>
                                <v-spacer></v-spacer> <!-- Empuja el botón hacia la derecha -->
                            </v-row>

                        <!-- Mostrar logo de carga mientras loadingTable sea true -->
                        <div v-show="loadingTabledialog23" class="center-item-in-div-column">
                            <v-progress-circular :size="150" :width="15" color="primary"
                                indeterminate></v-progress-circular>
                            <label class="overline font-weight-bold">Cargando información...</label>
                        </div>

                        <!-- Tabla de evaluación, oculta mientras loadingTable sea true -->
                        <!-- tabla 1 -->
                        <v-simple-table v-show="!loadingTabledialog23">
                            <thead>
                                <tr>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-l"
                                        colspan="6" style="text-align: center;"></th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center primary rounded-simple-table-head-r"
                                        colspan="6" style="text-align: center;">Valor Real</th>
                                </tr>
                                <tr>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" rowspan="2">ID Objetivo</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" rowspan="2">Objetivo EspecÍfico</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" rowspan="2">VO</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" rowspan="2">LE</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" rowspan="2">Ponderación</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" rowspan="2">Unidad</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">{{ trimestreMeses[0] }}</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">{{ trimestreMeses[1] }}</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">{{ trimestreMeses[2] }}</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">Resultado</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">Desempeño</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">Puntos</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(id, index) in Id_Indicador1" :key="id">
                                    <td style="text-align: center;">{{ id }}</td>
                                    <td>{{ ObjetivoEspecifico1[index] }}</td>
                                    <td style="text-align: center;">{{ VO1[index] }}</td>
                                    <td style="text-align: center;">{{ LE1[index] }}</td>
                                    <td style="text-align: center;">{{ Ponderacion1[index] }}</td>
                                    <td style="text-align: center;">{{ Unidad1[index] }}</td>
                                    <td style="text-align: center;">{{ mes1t1[index] }}</td>
                                    <td style="text-align: center;">{{ mes2t1[index] }}</td>
                                    <td style="text-align: center;">{{ mes3t1[index] }}</td>
                                    <!-- Verifica que real, desempenio y puntos estén definidos y tengan el índice correspondiente -->
                                    <!-- <td :style="getCellStyle(id.desempenio)">{{ id.dReal1.toFixed(1) }}</td> -->
                                    <td v-if="dReal1 && dReal1.length > index"
                                        :style="getCellStyle(desempenio[index], real[index])">
                                        {{ dReal1[index].toFixed(1) }}
                                    </td>
                                    <td v-else>-</td>
                                    <!-- Mostrar un valor por defecto si no hay datos disponibles -->



                                    <td style="text-align: center;" v-if="desempenio && desempenio.length > index">
                                        {{
                                            desempenio[index].toFixed(1) }}</td>
                                    <td v-else>-</td>

                                    <td style="text-align: center;" v-if="puntos && puntos.length > index">{{
                                        puntos[index].toFixed(1) }}</td>
                                    <td v-else>-</td>
                                </tr>
                                <tr>
                                    <td colspan="4"></td>
                                    <td class="font-weight-bold text-center">Ponderación Total: {{
                                        this.ponderacionSuma1 }}</td>

                                    <td colspan="6"></td>
                                    <td class="font-weight-bold text-center">Puntos Totales: {{
                                        this.totalPuntos.toFixed(1) }}</td>

                                </tr>



                            </tbody>
                        </v-simple-table>

                    </v-card-text>

                </v-card>


            </template>





        </v-dialog>
</template>


<!-- Esto es la evaluacion de desempeño -->
<template>
    <div>

        <v-dialog v-model="dialogEvaluacion" persistent max-width="1200px">
            <v-card class="rounded-xl p-0">

                <v-toolbar class="primary white--text text-uppercase  sticky-toolbar no-padding" flat dense>
                    <v-toolbar-title>DETALLE DE EVALUACIÓN DE DESEMPEÑO </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="cerrarVentanaEvaluacion">
                        <v-icon color="white">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-divider></v-divider>

                <v-card-text class="scrollable-content">
                    <!-- Indicador de carga mientras se obtienen los datos -->
                    <div v-if="loadingTableDialogEvaluacion" class="center-item-in-div-column">
                        <v-progress-circular :size="150" :width="15" color="primary"
                            indeterminate></v-progress-circular>
                        <label class="overline font-weight-bold">Cargando información...</label>
                    </div>

                    <v-card-actions v-else>
                        <div style="display: flex; align-items: center;">


                            <div>
                                <p><strong>Empleado:</strong> {{ selectEmpleado }}</p>
                                <p><strong>Nombre:</strong> {{ NombreEva }}</p> <!-- Mostrar nombre obtenido -->
                                <p><strong>Departamento:</strong> {{ DepartamentoEva }}</p>
                                <!-- Mostrar departamento obtenido -->
                                <p><strong>Puesto:</strong> {{ PuestoEva }}</p> <!-- Mostrar puesto obtenido -->

                                <!-- Trimestre 1 -->
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div>
                                            <v-icon v-bind="attrs" v-on="on"
                                                @click="openDialogTrim(1)">mdi-page-next-outline</v-icon>
                                            <strong v-bind="attrs" v-on="on" @click="openDialogTrim(1)"> Trimestre
                                                1</strong>
                                        </div>
                                    </template>
                                    <span>Ver evaluación trimestral del Trimestre 1</span>
                                </v-tooltip>

                                <!-- Trimestre 2 -->
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div>
                                            <v-icon v-bind="attrs" v-on="on"
                                                @click="openDialogTrim(2)">mdi-page-next-outline</v-icon>
                                            <strong v-bind="attrs" v-on="on" @click="openDialogTrim(2)"> Trimestre
                                                2</strong>
                                        </div>
                                    </template>
                                    <span>Ver evaluación trimestral del Trimestre 2</span>
                                </v-tooltip>

                                <!-- Trimestre 3 -->
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div>
                                            <v-icon v-bind="attrs" v-on="on"
                                                @click="openDialogTrim(3)">mdi-page-next-outline</v-icon>
                                            <strong v-bind="attrs" v-on="on" @click="openDialogTrim(3)"> Trimestre
                                                3</strong>
                                        </div>
                                    </template>
                                    <span>Ver evaluación trimestral del Trimestre 3</span>
                                </v-tooltip>

                                <!-- Trimestre 4 -->
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div>
                                            <v-icon v-bind="attrs" v-on="on"
                                                @click="openDialogTrim(4)">mdi-page-next-outline</v-icon>
                                            <strong v-bind="attrs" v-on="on" @click="openDialogTrim(4)"> Trimestre
                                                4</strong>
                                        </div>
                                    </template>
                                    <span>Ver evaluación trimestral del Trimestre 4</span>
                                </v-tooltip>
                                <p></p>

                                <p><strong>Desempeño 2011: (Promedio Desempeño de Objetivos) = </strong> {{ Desemp2011
                                    }} </p>
                                <p><strong>Los puntos obtenidos (Desempeño 2011 * 60%) = </strong> {{ PuntosObtenidos }}
                                </p>
                            </div>

                            <!-- Añadir aquí la foto de perfil -->
                            <!-- <div>
                                <img :src="fotoPerfil" alt="    Foto de Perfil" v-if="fotoPerfil"
                                    style="width: 250px; height: 250px; object-fit: cover; right: 20px; top: 25px;" />
                                <p v-else>No hay foto de perfil disponible</p>
                            </div> -->
                             <!-- Imagen de perfil -->
                             <div class="foto-perfil">
                                <img v-if="fotoPerfil" :src="fotoPerfil" alt="Foto de Perfil" />
                                <p v-else>No hay foto de perfil disponible</p>
                            </div>
                        </div>
                    </v-card-actions>

                    <!-- Indicador de carga mientras se obtienen los datos -->
                    <div v-if="loadingTableDialogEvaluacion" class="center-item-in-div-column">
                        <v-progress-circular :size="150" :width="15" color="primary"
                            indeterminate></v-progress-circular>
                        <label class="overline font-weight-bold">Cargando información...</label>
                    </div>

                    <!-- Tabla de evaluación, oculta mientras loadingTable sea true -->
                    <v-simple-table v-else v-show="!loadingTable">
                        <thead>
                            <tr>
                                <th class="primary white--text text-uppercase font-weight-bold text-center  primary rounded-simple-table-head-l primary rounded-simple-table-head-r"
                                    colspan="4" style="text-align: center;">II.- Cultura de Trabajo.</th>
                            </tr>
                            <tr>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">Valores</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">Puntos</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">Desempeño</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td><b>Respeto</b>.<br /> Es tratar a los demas sin afectar o dañar su integridad como
                                    persona..&nbsp;</td>
                                <td align="center">
                                    {{ Pregunta1V }}
                                </td>
                                <td align="center">
                                    <p> {{ Pregunta1 }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td><b>Honestidad.</b>.<br />Evitar engañar , robar y mentir.&nbsp;</td>
                                <td align="center">
                                    {{ Pregunta2V }}
                                </td>
                                <td align="center">
                                    <p> {{ Pregunta2 }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td><b>Responsabilidad.</b>.<br />Es cumplir con nuestros deberes y compromisos bien y a
                                    tiempo.&nbsp;</td>
                                <td align="center">
                                    {{ Pregunta3V }}
                                </td>
                                <td align="center">
                                    <p> {{ Pregunta3 }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td><b>Compromiso.</b>.<br />Es dar lo mejor de nosotros para sacar adelante lo que se
                                    nos ha confiado.&nbsp;</td>
                                <td align="center">
                                    {{ Pregunta4V }}
                                </td>
                                <td align="center">
                                    <p> {{ Pregunta4 }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td><b>Humildad.</b>.<br />Es ser capaz aceptar sus errores , enmendarlos y aprender de
                                    los demas .&nbsp;</td>
                                <td align="center">
                                    {{ Pregunta5V }}
                                </td>
                                <td align="center">
                                    <p> {{ Pregunta5 }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td><b>Colaboración.</b><br /> Es el apoyo mutuo e incodicional entre nosotros.&nbsp;
                                </td>
                                <td align="center">
                                    {{ Pregunta6V }}
                                </td>
                                <td align="center">
                                    <p> {{ Pregunta6 }}</p>
                                </td>

                            </tr>
                        </tbody>
                    </v-simple-table>

                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>


<!-- ESTA ES LA TABLA DE LOS TRIMESTRES EN EL DESEMPEÑO -->
<template>
    <div>
        <!-- Dialog -->
        <v-dialog v-model="dialogTrim" max-width="600px">
            <v-card>
                <v-card-title>
                    Evaluación Trimestral {{ selectedTrimestre }}
                </v-card-title>
                <v-card-text>
                    Aquí puedes mostrar los detalles o evaluación del Trimestre {{ selectedTrimestre }}.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closedialogTrim">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<template>
    <div>
        <!-- Modal flotante para la evaluación del trimestre de la evaluacion de desempeño-->
        <v-dialog v-model="dialogTrim" persistent max-width="1200px">


            <v-card class="rounded-xl p-0">

                <v-toolbar class="primary white--text text-uppercase  sticky-toolbar no-padding" flat dense>
                    <v-toolbar-title>EVALUACIÓN DE TRIMESTRE: {{ selectedTrimestre }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closedialogTrim">
                        <v-icon color="white">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                <p></p>

                <!-- Indicador de carga mientras se obtienen los datos -->
                <div v-if="loadingTable" class="center-item-in-div-column">
                    <v-progress-circular :size="150" :width="15" color="primary" indeterminate></v-progress-circular>
                    <label class="overline font-weight-bold">Cargando información...</label>
                </div>

                <v-card-text class="scrollable-content" v-else>




                    <!-- Mostrar logo de carga mientras loadingTable sea true -->
                    <div v-show="loadingTable" class="center-item-in-div-column">
                        <v-progress-circular :size="150" :width="15" color="primary"
                            indeterminate></v-progress-circular>
                        <label class="overline font-weight-bold">Cargando información...</label>
                    </div>

                    <!-- Tabla de evaluación, oculta mientras loadingTable sea true -->
                    <v-simple-table v-show="!loadingTable">
                        <thead>


                            <tr>
                                <th class="primary white--text text-uppercase font-weight-bold text-center  rounded-simple-table-head-l"
                                    style="text-align: center;" rowspan="2">Objetivo</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">VO</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">LE</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">{{ trimestreMesesDesempTabla[0] }}</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">{{ trimestreMesesDesempTabla[1] }}</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">{{ trimestreMesesDesempTabla[2] }}</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;" rowspan="2">Ponderación</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">Desempeño</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center rounded-simple-table-head-r"
                                    style="text-align: center;">Puntos</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(objetivo, index) in DescObjetivoT" :key="index">
                                <td>{{ DescObjetivoT[index] }}</td>
                                <td style="text-align: center;">{{ VOTR[index] }}</td>
                                <td style="text-align: center;">{{ LETR[index] }}</td>
                                <td style="text-align: center;">{{ Valor1TR[index] }}</td>
                                <td style="text-align: center;">{{ Valor2TR[index] }}</td>
                                <td style="text-align: center;">{{ Valor3TR[index] }}</td>
                                <td style="text-align: center;">{{ PonderacionTR[index] }}</td>
                                <td style="text-align: center;">{{ Desemp[index].toFixed(2) }}</td>
                                <td style="text-align: center;">{{ Puntos[index].toFixed(2) }}</td>

                            </tr>

                            <tr>

                                <td style="text-align: center;" colspan="8" class="text-right font-weight-bold">
                                    Puntos Totales:
                                </td>
                                <td style="text-align: center;" class="font-weight-bold">
                                    {{ totalPuntos ? totalPuntos.toFixed(2) : '0.00' }}
                                </td>
                            </tr>
                        </tbody>



                    </v-simple-table>
                </v-card-text>


            </v-card>
        </v-dialog>
    </div>
</template>

<!-- Esto es para ver los objetivos asignados -->
 
<template>
    <div>
        <!-- Modal flotante para los objetivos asignas al empleado-->
        <v-dialog v-model="dialogObjetivosEmp" persistent max-width="1200px" scrollable>


            <v-card class="rounded-xl p-0">

                <v-toolbar class="primary white--text text-uppercase  sticky-toolbar no-padding" flat dense>
                    <v-toolbar-title v-show="!loadingTablePuestos">OBJETIVOS ASIGNADOS AL PUESTO: {{ this.valorPuesto }}, {{ descPuesto }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closedialogTrim">
                        <v-icon color="white">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                <p></p>

                <!-- Indicador de carga mientras se obtienen los datos -->
                <div v-if="loadingTablePuestos" class="center-item-in-div-column">
                    <v-progress-circular :size="150" :width="15" color="primary" indeterminate></v-progress-circular>
                    <label class="overline font-weight-bold">Cargando información...</label>
                </div>

                <v-card-text v-else>
                    <!-- <v-card-text class="scrollable-content"> -->




                    <!-- Mostrar logo de carga mientras loadingTablePuestos sea true -->
                    <div v-show="loadingTablePuestos" class="center-item-in-div-column">
                        <v-progress-circular :size="150" :width="15" color="primary"
                            indeterminate></v-progress-circular>
                        <label class="overline font-weight-bold">Cargando información...</label>
                    </div>

                   
                    <v-simple-table v-show="!loadingTablePuestos">
                        <!-- <v-simple-table > -->
                            <thead>
    
                                <tr>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center  rounded-simple-table-head-l rounded-simple-table-head-r"
                                    style="text-align: center;"  colspan="3">Personas Con Este Puesto</th>
                                </tr>
    
                                <tr>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center; width: 10%;">Folio</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;" >Nombre</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">Área</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in tablaPuestos" :key="index">
                                  <td style="text-align: center;">{{ item.folio }}</td>
                                  <td style="text-align: center;">{{ item.Nombre }}</td>
                                  <td style="text-align: center;">{{ item.DEP_DESDEP }}</td>
                                </tr>
                              </tbody>
                            
    
    
    
                        </v-simple-table>
                        <v-divider></v-divider>
                        <p></p>
                    <v-simple-table v-show="!loadingTablePuestos">
                        <!-- <v-simple-table > -->
                        <thead>

                            <tr>
                                <th class="primary white--text text-uppercase font-weight-bold text-center  rounded-simple-table-head-l rounded-simple-table-head-r"
                                style="text-align: center;" colspan="9">Objetivos Asignados</th>
                            </tr>

                            <tr>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">Objetivo Alineado</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">Objetivo Propio</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center; width: 5%;">VO</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center; width: 5%;">LE</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center; width: 10%;">Ponderación</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center; width: 5%;">Unidad</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center; width: 10%;">Categoría</th>
                                <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center; width: 10%;">Tipo de Cálculo</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                    style="text-align: center;">Opción</th>
                               
                            </tr>
                        </thead>
                        
                        <tbody>
                            <tr v-for="(item, index) in tablaObjAsignados" :key="index">
                                <td >{{ item.Id_ES_Objetivo_Estrategico }}</td>
                                <td >{{ item.DescObjetivo }}</td>
                            
                                <td style="text-align: center;">
                                    <input v-model="item.VO" type="text" style="width: 30px;" maxlength="20" />
                                    <span v-if="!item.VO " class="mensaje-error">Campo vacío</span>
                                  </td>
                                
                                  <td style="text-align: center;">
                                    <input v-model="item.LE" type="text" style="width: 30px;" maxlength="20" />
                                    <span v-if="!item.LE" class="mensaje-error">Campo vacío</span>
                                  </td>
                                
                                  <td style="text-align: center;">
                                    <input
                                      v-model="PondePuestos[index]"
                                      @input="updateValuePonderacion(index, 'PondePuestos', $event)"
                                      type="number"
                                      :placeholder="PondePuestos[index]"
                                      style="width: 60px;"
                                      maxlength="20"
                                    />%
                                    <span v-if="!PondePuestos[index] || PondePuestos[index].toString().trim() === ''" class="mensaje-error">
                                      Campo vacío
                                    </span>
                                  </td>
                                
                                  <td style="text-align: center;">
                                    <input v-model="item.Unidad" type="text" style="width: 30px;" maxlength="20" />
                                    <span v-if="!item.Unidad" class="mensaje-error">Campo vacío</span>
                                  </td>
                                
                                  <td style="text-align: center;">
                                    <select v-model="item.Categoria" style="width: 50px;">
                                      <option value="" disabled>::Selecciona un tipo::</option>
                                      <option value="PROPIO">PROPIO</option>
                                      
                                    </select>
                                    <span v-if="!item.Categoria" class="mensaje-error">Campo vacío</span>
                                  </td>
                                
                                  <td style="text-align: center;">
                                    <select v-model="item.TipoCalulo" style="width: 70px;">
                                      <option value="" disabled>::Selecciona un tipo::</option>
                                      <option value="Promedio">Promedio</option>
                                      <option value="Acumulado">Acumulado</option>
                                    </select>
                                    <span v-if="!item.TipoCalulo" class="mensaje-error">Campo vacío</span>
                                  </td>

                                <!-- -------------- -->
                                
                                
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                    <div style="display: flex; justify-content: center; align-items: center; margin-top: 10px;">
                                        <!-- Ícono desplazado hacia abajo -->
                                        <v-icon v-bind="attrs" v-on="on" @click="eliminarObj(item.Id_Obj)">mdi-delete-alert-outline</v-icon>
                                    </div>
                                    </template>
                                    <span>Eliminar objetivo</span>
                                </v-tooltip>
                            </tr>

                            <tr>

                                
                                    <td 
                                    style="text-align: center;" 
                                    colspan="5" 
                                    class="text-right font-weight-bold" 
                                    :class="{'text-success': totalPonderacionTabla === 100, 'text-danger': totalPonderacionTabla !== 100}"
                                >
                                    Ponderación Total: {{ totalPonderacionTabla }}
                                </td>



                                
                            </tr>
                          </tbody>
                          

                    </v-simple-table>

                    
                    <v-row v-if="btnActualizar" justify="center">
                     
                        <v-col  cols="auto" >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  class="primary white--text text-uppercase font-weight-bold text-center"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="actualizarBtn()"
                                >
                                  <v-icon>{{ 'mdi-reload' }}</v-icon>&nbsp; Actualizar Objetivos
                                </v-btn>
                              </template>
                              <span>Haz click para actualizar los valores de los objetivos</span>
                            </v-tooltip>
                          </v-col>

                         
                          
                    </v-row>
                    <p> </p>
                    <p> </p>

                    <!-- Para agregar obj -->
                    <div v-if="loadingTableAgregarPuestos" class="center-item-in-div-column">
                        <v-progress-circular :size="150" :width="15" color="primary"
                            indeterminate></v-progress-circular>
                        <label class="overline font-weight-bold">Cargando información...</label>
                    </div>
                    
                        <v-simple-table v-else v-show="agregarObj===true">
                            <!-- <v-simple-table > -->
                            <thead>
    
                                <tr>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center  rounded-simple-table-head-l rounded-simple-table-head-r"
                                    style="text-align: center;" colspan="9">Agregar Objetivo Asignado</th>
                                </tr>
    
                                <tr>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">Objetivo Alineado</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center;">Objetivo Propio</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center; width: 5%;">VO</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center; width: 5%;">LE</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center; width: 10%;">Ponderación</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center; width: 5%;">Unidad</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center; width: 10%;">Categoría</th>
                                    <th class="primary white--text text-uppercase font-weight-bold text-center"
                                        style="text-align: center; width: 10%;">Tipo de Cálculo</th>
                                        
                                   
                                </tr>
                            </thead>
                            
                            <tbody>
                                
                                <tr>
                                    <td>
                                      <v-select
                                        v-model="formData.selectedOption"
                                        :items="estrategicos"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Selecciona un objetivo estratégico"
                                        outlined
                                        
                                      ></v-select>
                                      <span v-if="!formData.selectedOption" class="mensaje-error">Campo vacío</span>
                                    </td>
                                  
                                    <td>
                                      <input
                                        type="text"
                                        maxlength="300"
                                        placeholder="Introduce un dato"
                                        v-model="formData.input1"
                                        
                                      />
                                      <span v-if="!formData.input1" class="mensaje-error">Campo vacío</span>
                                    </td>
                                  
                                    <td style="text-align: center;">
                                      <input
                                        type="text"
                                        style="width: 30px;"
                                        maxlength="20"
                                        placeholder="Introduce un dato"
                                        v-model="formData.input2"
                                        
                                      />
                                      <span v-if="!formData.input2" class="mensaje-error">Campo vacío</span>
                                    </td>
                                  
                                    <td style="text-align: center;">
                                      <input
                                        type="text"
                                        style="width: 30px;"
                                        maxlength="20"
                                        placeholder="Introduce un dato"
                                        v-model="formData.input3"
                                        
                                      />
                                      <span v-if="!formData.input3" class="mensaje-error">Campo vacío</span>
                                    </td>
                                  
                                    <td style="text-align: center;">
                                      <input
                                        @input="actualizarValor($event.target.value)"
                                        type="number"
                                        placeholder="Introduce un dato"
                                        style="width: 60px;"
                                        maxlength="20"
                                        v-model="formData.input4"
                                        
                                      />%
                                      <span v-if="!formData.input4" class="mensaje-error">Campo vacío</span>
                                    </td>
                                  
                                    <td style="text-align: center;">
                                      <input
                                        type="text"
                                        style="width: 30px;"
                                        maxlength="20"
                                        placeholder="Introduce un dato"
                                        v-model="formData.input5"
                                        
                                      />
                                      <span v-if="!formData.input5" class="mensaje-error">Campo vacío</span>
                                    </td>
                                  
                                    <td style="text-align: center;">
                                      <select
                                        v-model="formData.select1"
                                        style="width: 50px;"
                                        
                                      >
                                        <option value="" disabled selected>::Selecciona un tipo::</option>
                                        <option value="PROPIO">PROPIO</option>
                                        
                                      </select>
                                      <span v-if="!formData.select1" class="mensaje-error">Campo vacío</span>
                                    </td>
                                  
                                    <td style="text-align: center;">
                                      <select
                                        v-model="formData.select2"
                                        style="width: 70px;"
                                        
                                      >
                                        <option value="" disabled selected>::Selecciona un tipo::</option>
                                        <option value="Promedio">Promedio</option>
                                        <option value="Acumulado">Acumulado</option>
                                      </select>
                                      <span v-if="!formData.select2" class="mensaje-error">Campo vacío</span>
                                    </td>
                                </tr>
                              <tr>
                                  
    
                                    
                                </tr>
                              </tbody>
                              
    
                        </v-simple-table>
                        <p> </p>
                
                    <v-row  v-if="agregarObj === true" justify="center">
                     
                    

                          <v-col cols="auto" >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  class="primary white--text text-uppercase font-weight-bold text-center"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="metodoAgregarObj()"
                                   :disabled="banderaBTNAgregar"
                                >
                                  <v-icon>{{ 'mdi-content-save-all-outline' }}</v-icon>&nbsp; Agregar Objetivo
                                </v-btn>
                              </template>
                              <span>Haz click para agregar el objetivo</span>
                            </v-tooltip>
                          </v-col>
                          
                    </v-row>

                    
                      
               
                </v-card-text>


            </v-card>
        </v-dialog>
    </div>
</template>

<v-dialog v-model="banderaAcualizarDialog" max-width="500">

    <v-card v-if="loadingTableEditar" class="center-item-in-div-column">
        <v-progress-circular :size="150" :width="15" color="primary"
            indeterminate></v-progress-circular>
        <label class="overline font-weight-bold">Cargando información...</label>
    </v-card>

    <v-card v-else>
        <v-card-title class="headline">Confirmar Modificaciones</v-card-title>
        <v-card-text>¿Está seguro que desea guardar valores editados para estos objetivos?</v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="banderaAcualizarDialog = false">Cancelar</v-btn>
            <v-btn color="blue darken-1" text @click="actualizarObj()">Confirmar</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>








<!-- <div class="my-4" v-if="AreaUser.includes('ADMING') || this.numEmpleado === '0' || this.numEmpleado === '9363' || this.banderaBtnExportar === true && this.AreaUser !='RESP' && this.AreaUser !='RESP1'"> -->
<div class="my-4"  v-if="(this.AreaUser.includes('ADMING') || this.numEmpleado === '0' || this.numEmpleado === '9363' || this.localBandera === true)  && this.banderaRESPONSABLE === false">


    <v-row justify="center">
        <v-col cols="auto">
            <!-- Botón que abre el diálogo -->
            <v-btn outlined color="red" class="text-uppercase font-weight-bold text-center"
                @click="dialogRechazar = true">
                <v-icon left>{{ 'mdi-close' }}</v-icon>
                Rechazar
            </v-btn>
        </v-col>

        <v-col cols="auto">
                                  <v-btn outlined color="success" class="white--text text-uppercase font-weight-bold text-center"  @click="abrirAceptar">
                <v-icon>{{ 'mdi-check' }}</v-icon>&nbsp; Aceptar
            </v-btn>
        </v-col>
    </v-row>
</div>

 <!-- Diálogo de confirmación rechazar -->
 <v-dialog v-model="dialogRechazar" max-width="400">
    <v-card>
        <v-card-title class="headline">Confirmación</v-card-title>
        <v-card-text>
            ¿Estás seguro de que quieres rechazar el reporte de bono?
        </v-card-text>
        <v-card-actions>
            <!-- Botón para cancelar la acción y cerrar el diálogo -->
            <v-btn color="grey" text @click="dialogRechazar = false">Cancelar</v-btn>
            <!-- Botón para confirmar la acción -->
            <v-btn color="primary" text @click="confirmarRechazo">
                Confirmar
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>

 <!-- Diálogo de confirmación  -->
 <v-dialog v-model="dialogAceptarB" max-width="400">
    <v-card>
        <v-card-title class="headline">Confirmación</v-card-title>
        <v-card-text>
            ¿Estás seguro de que quieres aceptar el reporte de bono?
        </v-card-text>
        <v-card-actions>
            <!-- Botón para cancelar la acción y cerrar el diálogo -->
            <v-btn color="grey" text @click="dialogAceptarB = false">Cancelar</v-btn>
            <!-- Botón para confirmar la acción -->
            <v-btn color="primary" text @click="aceptar()">
                Confirmar
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</div>
</div>
</template>

<script>
import axios from "axios";
import Vue from 'vue';
//import * as XLSX from 'xlsx';
// import XlsxPopulate from 'xlsx-populate';
import ExcelJS from 'exceljs';
//import fs from 'fs'; // Si necesitas trabajar con rutas absolutas
import { saveAs } from 'file-saver'; // Instala esta librería para manejar descargas de archivos en el navegador


function getAreaValue(area) {
    if (Array.isArray(area)) {
        if (area.length > 1) {
            // Si es un array con más de una posición, trae la posición 1
            return area[1];
        } else if (area.length === 1) {
            // Si es un array con una sola posición, trae la posición 0
            return area[0];
        } else {
            // Si es un array vacío, devuelve un valor por defecto
            return 'Array vacío';
        }
    } else {
        // Si no es un array, devuelve el valor directamente
        return area;
    }
}

export default {
    name: "reporteobjetivos",
    props: [
        "Mes1", "Mes2", "Mes3", "iMes", "anio", "iAreaValorBase",
        "banderaArea2", "banderaReporte",
        "banderaBtnExportar", "banderaChampion", "rhUsuario", "CadAreas", "AreaUser",
        "poCIAColab", "poAreaColab", "poDepartamentoColab", "poIndividualesColab",
        "poCIADir", "poAreaDir", "poDepartamentoDir", "poIndividualesDir",
        "poCIACoord", "poAreaCoord", "poDepartamentoCoord", "poIndividualesCoord",
        "trimestreFinal", "PrimerMesNumValorBase", "SegundoMesNumValorBase", "TercerMesNumValorBase",
        "numEmpleado", "banderaBTNResponsable", "banderaBotones", "banderaRESPONSABLE", "anioTabla"
    ],
    data() {
     
        return {
            formData: {
            selectedOption: null,
            input1: "",
            input2: "",
            input3: "",
            input4: "",
            input5: "",
            select1: "",
            select2: "",
        },
            archivo: null,
            loadingTableAgregarPuestos: false,
            banderaBTNAgregar: false,
            banderaBTNActualizar: false,
          
            totalPonderacionTabla: null,
            descPuesto: null,
            valorPuesto: null,
            dialogObjetivosEmp: false,
            loadingTablePuestos: false,
            loadingTableEliminarObj: false,
            isLoading: false, // Controla el estado de carga
            dialogRechazar: false, // Controla la visibilidad del diálogo de rechazo
            loadingTable2: false,
            loadingTable3: false,
            loadingTable4: false,
            loadingTable5: false,
            loadingTable6: false,
            loadingTabledialog23: false,
            singleExpand: false, // Controla si se permite expandir una sola fila a la vez
            expandedFinal: [],
            expanded: [], // Almacena las filas expandidas
            expanded2: [],
            expanded4: [],
            expanded5: [],
            expanded6: [],
            nestedExpanded: [], // Para manejar la expansión en la tabla anidada

            employees: [], // Lista de empleados de directores (tabla anidada)
            employeesNivel2: [],
            employeesNivel3: [],
            employeesNivel4: [],
            employeesNivel5: [],
            employeesNivel6: [],
            singleExpandEmployees: true,
            expandedEmployees: [],

            singleExpandThirdTable: true,
            expandedThirdTable: [],
            thirdEmployeer: [],

            dialog: false, // Controla la visibilidad del modal
            dialogTrimestres1234: false, //esto es para el modal de los trimestres
            dialogEditar: false, //este es para la tabla de editar valores 
            dialogEvaluacion: false, //ventana de evaluacion de desempeño
            loadingTableDialogEvaluacion: false, // este es para logo de carga de evaluacion de desmpeño
            selectedItem: null, // Almacena el item seleccionado
            trimestreSeleccionado: null, // Almacena el trimestre seleccionado

            selectEmpleado: null,
            nombrePersona: null,
            fotoPerfil: null, //esto es para la foto de evaluacion 
            NombreEva: null,
            DepartamentoEva: null,
            PuestoEva: null,
            Pregunta1: '',
            Pregunta2: '',
            Pregunta3: '',
            Pregunta4: '',
            Pregunta5: '',
            Pregunta6: '',

            Pregunta1V: '',
            Pregunta2V: '',
            Pregunta3V: '',
            Pregunta4V: '',
            Pregunta5V: '',
            Pregunta6V: '',

            anioAnterior: 0,
            dialogTrim: false,
            selectedTrimestre: 0,
            folioSeleccionadoTrim: null,
            folioFinal: null,

            // Inicializar todas las propiedades que son utilizadas en el template
            DescObjetivoT: [],            // Descripción del objetivo trimestral
            VOT: [],                      // Valor objetivo trimestral
            LET: [],                      // Límites esperados trimestrales
            Valor1T: [],                  // Valores del primer trimestre
            Valor2T: [],                  // Valores del segundo trimestre
            Valor3T: [],                  // Valores del tercer trimestre
            PonderacionT: [],             // Ponderación trimestral
            TipoCalculoT: [],             // Tipo de cálculo trimestral

            folioGlobal: null, //tabla 2
            folioGlobalTabla1: null, // tabla 1
            folioGlobalTabla1Meses: null, // tabla 1
            ultimoTotalPuntos: 0,  // Variable para almacenar el último valor de Total Puntos
            PuntosObtenidos: 0, // Los puntos Obtenidos (Desempeno 2011*60%) 
            Desemp2011: 0, // Desempeno 2011: (Promedio Desempeno de Objetivos) 
            NumTrim: 4, //Este es el valor base de los trimestres
            Desemp: [],
            Id_Area_DirGlobal: null,
            dialog2: null,
            dialog23: null,
            tabla1Data: [],
            tabla2Data: [],
            //Esto es para las tablas de los trimestes
            resultadosPorArea2: {}, // Inicializa como un array vacío



            //Esto es para la tabla 1 de los meses dir
            Id_Indicador1: [],
            ObjetivoEspecifico1: [],
            Unidad1: [],
            VO1: [],
            LE1: [],
            Ponderacion1: [],
            TipoCalculos1: [],
            mes1t1: [],
            mes2t1: [],
            mes3t1: [],
            real: [],
            desempenio: [],
            puntos: [],
            dReal1: [],
            ponderacionSuma1: null,
            nombreTabla1: null,
            areaTabla1: null,
            area1: null,

            //Esto es para la tabla 2 de los meses dir
            Id_Indicador2: [],
            ObjetivoEspecifico2: [],
            Unidad2: [],
            VO2: [],
            LE2: [],
            Ponderacion2: [],
            TipoCalculos2: [],
            mes1t2: [],
            mes2t2: [],
            mes3t2: [],
            real2: [],
            desempenio2: [],
            puntos2: [],
            dReal2: [],
            ponderacionSuma2: null,
            area2: null,

            dSumaPuntosAreaGlobal: 0,
            ValObjPrimerMes: [],
            ValObjSegundoMes: [],
            ValObjTercero: [],
            TipoCalculoMes: [],
            LEMes: [],
            VOMes: [],
            PonderacionMes: [],

            dSumaPuntosCord: 0,
            datosPorFolio: {}, // Aquí guardaremos los datos por folio.
            FolioD: [],

            valorAreaGlobalDIR: 0,
            valorFolioGlobalNivel2: 0,
            valorFolioGlobalNivel3: 0,
            valorFolioGlobalNivel4: 0,
            valorFolioGlobalNivel5: 0,
            valorFolioGlobalNivel6: 0,
            datosFolio: {},
            datosColaboradores: {},
            estatusGlobal: {},

            expand: false,   // Define 'expand' como parte del estado del componente
            //expand2: false,

            headers: [

                { text: '', value: 'cal', align: 'center',  class: "primary white--text text-uppercase font-weight-bold text-center"  },
                { text: '', value: 'objetivos', align: 'center',  class: "primary white--text text-uppercase font-weight-bold text-center"  },
                { text: '', value: 'data-table-expand', align: 'center',  class: "primary white--text text-uppercase font-weight-bold text-center"  }, // Columna para expandir
                { text: 'NOMBRE', value: 'Nombre', sortable: true,  class: "primary white--text text-uppercase font-weight-bold text-center" },
                { text: 'COLABORADORES', value: 'CantColab', align: 'center',  class: "primary white--text text-uppercase font-weight-bold text-center" },
                { text: 'DETALLES', value: 'det', align: 'center',  class: "primary white--text text-uppercase font-weight-bold text-center"  }, // Usamos 'det' aquí
                // { text: 'OBJ', value: 'PuntosObjetivos', align: 'center' },
                // { text: 'CUT', value: 'PuntosValores', align: 'center' },
                // { text: 'EXT', value: 'Extras', align: 'center' },
                // { text: 'TOTAL', value: 'Total', align: 'center' },
                { text: '1', value: 'Trim1', align: 'center',  class: "primary white--text text-uppercase font-weight-bold text-center"  },
                { text: '2', value: 'Trim2', align: 'center',  class: "primary white--text text-uppercase font-weight-bold text-center"  },
                { text: '3', value: 'Trim3', align: 'center',  class: "primary white--text text-uppercase font-weight-bold text-center"  },
                { text: '4', value: 'Trim4', align: 'center',  class: "primary white--text text-uppercase font-weight-bold text-center"  },
                { text: '%', value: 'dBonoDIR', align: 'center',  class: "primary white--text text-uppercase font-weight-bold text-center"  },

            ],



            Id_IndicadorMes: [],
            Id_IndicadorMesEditar: [],
            NamePerson: null,
            empleados: [], // Almacenará la lista de empleados obtenidos de la API
            rows: [],
            resultados: [],
            index: [],
            rowIndex: [],
            nombreIndex: [],
            expandedIndex: null, // Para rastrear qué fila está expandida
            sNombreD: [],
            sortedData: [],
            totalPonderacion: 0, // Almacena la suma de las ponderaciones
            totalPuntos: 0, // Almacena la suma de los puntos

            items: [],

            sNombreDE: [], // Inicializar como array vacío para la lista de nombres
            DescPuestoE: [], // Inicializar como array vacío para las descripciones de puesto
            FolioDE: [], // Inicializar como array vacío para FolioDE
            cantColaboradores3: [], // Inicializar como array vacío para colaboradores

            cantColaboradores: [],
            mostrarFilas: false, // Bandera para mostrar/ocultar las filas
            mostrarFilas2: false, // Bandera para mostrar/ocultar las filas
            expandedRows: [], // Mantendrá un arreglo de los índices de filas expandidas
            loadingTable: false,
            mostrarTabla: false,
            enviarCorreo: null,
            valorCorreo: null,
            dSumaDesemp: 0,
            dSumaPuntosArea: 0,
            dSumaPuntosCIA: 0,
            dBonoDIR: 0,
            dBonoCOORD: 0,
            dBonoColab: 0,
            Extras: 0,
            Total: 0,
            Id_Area: null,

            mes1Tabla: null, // Variable para el primer mes del trimestre
            mes2Tabla: null, // Variable para el segundo mes del trimestre
            mes3Tabla: null, // Variable para el tercer mes del trimestre

            //Estas son las variables nuevas
            calculoReal: [], // Contendrá los valores calculados como dReal
            desempeno: [], // Contendrá los porcentajes de desempeño
            puntosNew: [], // Contendrá los puntos calculados
            
            abortController: null, // Controlador para manejar la cancelación
            dialogEditarMeses: false,
            dialogBTNEditar: false,
            dialogBTNEeliminar: false,
            loadingTableEditar: false,
            dialogBTNAutorizar: false,
            tablaPuestos: [],
            tablaObjAsignados: [],
            actualizarPuesto: false,
            agregarObj: false,
            Ponderacion: [], // Asegúrate de inicializarla como un array si es una lista
            banderaEliminarObj: false,
            valorIdObj: null,
            inputValue: null,
            previousValue: 0, // Valor anterior del campo
            nivelEmp: null,
            banderaAcualizarDialog: false,

            estrategicos: [], // Aquí se guardan los datos del método
            selectedOption: null, // Almacena el valor seleccionado (id)
            selectedOptionName: "", // Almacena el texto seleccionado (nombre)
            mergedFormData: {}, // Variable para almacenar los datos combinados
            banderaFormulario: null,
            btnActualizar: null,
            dialogAceptarB: false,
            localBandera: this.banderaBtnExportar, // Copia el valor de la prop


            taskId: null, // Almacena el ID de la tarea
            taskStatus: null, // Estado de la tarea (in progress, completed, error)
            taskResult: null, // Resultados de la tarea
            loading: false, // Indicador de carga
            errorMessage: null, // Mensaje de error, si ocurre

            

        };
    },

    mounted() {
        // console.log('Valor de poAreaDIr', this.poAreaDir);
        // console.log('Valor de poCIADir', this.poCIADir);



        //console.log('VALOR DE RHUSUARIO: ##############33333', 'NUMERO DE EMPLEADO:', this.numEmpleado, this.rhUsuario, 'AreaUSER', this.AreaUser, 'areas cadenas', this.CadAreas, this.trimestreFinal)
        //this.AreaUser = "EMP";
        //this.AreaUser = "COMITE";

        //this.banderaChampion = true;
        if( this.banderaRESPONSABLE === true && this.numEmpleado === '8631'){
            this.AreaUser = 'RESP';
            this.localBandera = false;
        }

        if(this.numEmpleado === '9363' && this.banderaRESPONSABLE != true){
            this.localBandera = true;
        }

          //Validacion de directores
          if(this.numEmpleado === '8704' || this.numEmpleado === '3107' || this.numEmpleado === '8631'|| this.numEmpleado === '4251'|| 
        this.numEmpleado === '3692' || this.numEmpleado === '3692'){
            this.banderaChampion = true;
        }
       


        // console.log('VALOR DE AREA EN REROTE OBJ', this.AreaUser);
        // console.log('VALOR DE rhusuario', this.rhUsuario);
        // this.PrimerMesNumValorBase = 1;
        // this.SegundoMesNumValorBase = 2;
        // this.TercerMesNumValorBase = 3;
        //this.banderaChampion = true;
        console.log('valor de la bandera chapmpions', this.banderaChampion);
        this.getDatos();
    },

    computed: {
        // Esta propiedad computada devolverá los meses correspondientes al trimestre seleccionado
        trimestreMeses() {
            switch (this.trimestreSeleccionado) {
                case 1:
                    return ['Enero', 'Febrero', 'Marzo'];
                case 2:
                    return ['Abril', 'Mayo', 'Junio'];
                case 3:
                    return ['Julio', 'Agosto', 'Septiembre'];
                case 4:
                    return ['Octubre', 'Noviembre', 'Diciembre'];
                default:
                    return ['Mes 1', 'Mes 2', 'Mes 3']; // Valor por defecto
            }
        },

        //Esto es para la tabla de trimestres pero de evaluacion de desempeño
        trimestreMesesDesempTabla() {
            switch (this.selectedTrimestre) {
                case 1:
                    return ['Enero', 'Febrero', 'Marzo'];
                case 2:
                    return ['Abril', 'Mayo', 'Junio'];
                case 3:
                    return ['Julio', 'Agosto', 'Septiembre'];
                case 4:
                    return ['Octubre', 'Noviembre', 'Diciembre'];
                default:
                    return ['Mes 1', 'Mes 2', 'Mes 3']; // Valor por defecto
            }
        }
    },



    methods: {
        //Este es para actualizar
        async validarCampos_Actualizar() {
            console.log('entro al validar');
    let isValid2 = true;

      // Validar los campos en tablaObjAsignados
      this.tablaObjAsignados.forEach((item, index) => {
      // Validar VO
      if (!item.VO) {
        console.log(`Campo VO vacío en el índice ${index}`);
        isValid2 = false;
      }
      // Validar LE
      if (!item.LE) {
        console.log(`Campo LE vacío en el índice ${index}`);
        isValid2 = false;
      }
      // Validar Ponderación
      if (!this.PondePuestos[index] || this.PondePuestos[index].toString().trim() === "") {
        console.log(`Campo Ponderación vacío en el índice ${index}`);
        isValid2 = false;
      }
      // Validar Unidad
      if (!item.Unidad) {
        console.log(`Campo Unidad vacío en el índice ${index}`);
        isValid2 = false;
      }
      // Validar Categoría
      if (!item.Categoria) {
        console.log(`Campo Categoría vacío en el índice ${index}`);
        isValid2 = false;
      }
      // Validar TipoCálculo
      if (!item.TipoCalulo) {
        console.log(`Campo Tipo Cálculo vacío en el índice ${index}`);
        isValid2 = false;
      }
    });


    // Mostrar resultado final
    // if (isValid2) {
    //   alert("Todos los campos están completos.");
    // } else {
    //   console.log("Existen campos vacíos en el formulario.");
    // }

      // Actualizar la bandera global del formulario
      this.banderaBTNActualizar = isValid2;

    return isValid2;
  },

        async validarCampos() {
    // Inicializar una bandera para verificar si hay errores
    let isValid = true;

    // Verificar cada campo directamente desde los datos
    if (!this.formData.selectedOption) {
      isValid = false;
    }
    if (!this.formData.input1) {
      isValid = false;
    }
    if (!this.formData.input2) {
      isValid = false;
    }
    if (!this.formData.input3) {
      isValid = false;
    }
    if (!this.formData.input4) {
      isValid = false;
    }
    if (!this.formData.input5) {
      isValid = false;
    }
    if (!this.formData.select1) {
      isValid = false;
    }
    if (!this.formData.select2) {
      isValid = false;
    }

   
    // if (isValid) {
    //   alert("Todos los campos están llenos.");
    // } else {
    //   console.log("Algunos campos están vacíos.");
    // }

    // Actualizar la bandera global del formulario
    this.banderaFormulario = isValid;

    // Devolver el estado final
    return isValid;
  },

    async metodoAgregarObj(){
        await this.validarCampos();
        this.actualizarValor();
        if( this.banderaFormulario === true){
            this.agregarObjEstrategico();
            this.actualizarValor();
        }else{
            console.log('tiene espacios vacios');
            this.actualizarValor();
        }

    },
      
        //Esto es para agregar los objetivos estrategicos
        async agregarObjEstrategico(){
            this.loadingTableAgregarPuestos = true;
            this.banderaBTNAgregar = true;
            console.log("Datos del formulario btn ---------------");
            console.log("valor de nivel; ", this.nivelEmp, "Puesto: ", this.valorPuesto)
             //console.log('selectedOption cambió:', this.formData.selectedOption);
    // console.log('input1 cambió:', this.formData.input1);
    // console.log('input2 cambió:', this.formData.input2);
    // console.log('input3 cambió:', this.formData.input3);
    // console.log('input4 cambió:', this.formData.input4);
    // console.log('input5 cambió:', this.formData.input5);
    // console.log('select1 cambió:', this.formData.select1);
    // console.log('select2 cambió:', this.formData.select2);
      // Combinamos los datos de formData en mergedFormData
      this.mergedFormData = {
        ObjEsp: this.formData.selectedOption,
        ObjetivoPropio: this.formData.input1,
        Nivel: this.nivelEmp,
        VOPropio: this.formData.input2,
        LEPropio: this.formData.input3,
        Ponderacion: this.formData.input4,
        UnidadPropio: this.formData.input5,
        Puesto: this.valorPuesto,
        select1: this.formData.select1,
        TipoCalculoDesc: this.formData.select2,
        //iNumDepto:
      };


       // Enviar los datos sin envolverlos en una clave 'data'
       try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/agregar-obj-puesto`, this.mergedFormData, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                console.log('Todos los registros se insertaron correctamente:', response);
                this.objAsignados();
                this.loadingTableAgregarPuestos = false;
                this.banderaBTNAgregar = false;
                this.formData =  {
                    selectedOption: null,
                    input1: "",
                    input2: "",
                    input3: "",
                    input4: "",
                    input5: "",
                    select1: "",
                    select2: "",
                };
               
            } catch (error) {
                console.error('Error al insertar los datos:', error);
                this.loadingTableAgregarPuestos = false;
                this.banderaBTNAgregar = false;
                this.objAsignados();
              
            }

      // Imprimimos la variable combinada
      console.log("Datos combinados: ", this.mergedFormData);
        },
        //Esto es para traer el select de los obj estrategicos

//     async getObjEstrategicos() {
//   try {
//     const response = await axios.get(
//       `${this.$store.getters.getHostNode}/api/objEstrategicoN2/${this.valorAreaGlobalDIR}`,
//       {
//         headers: {
//           Authorization: localStorage.getItem("token"),
//         },
//       }
//     );
    

//     console.log("Datos completos de la respuesta:", response);

//     // Validar que response.data contiene un array
//     if (response.data && Array.isArray(response.data)) {
//       // Mapear los datos al formato esperado para el select
//       this.estrategicos = response.data.map((item) => ({
//         id: item.Id_Indicador, // Asegúrate de usar el campo correcto para el ID
//         nombre: item.Objetivo_Estrategico, // Asegúrate de usar el campo correcto para el nombre
//       }));
//     } else if (response.data && response.data.data && Array.isArray(response.data.data)) {
//       // Si los datos están en response.data.data
//       this.estrategicos = response.data.data.map((item) => ({
//         id: item.Id_Indicador,
//         nombre: item.Objetivo_Estrategico,
//       }));
//     } else {
//       console.warn("La respuesta no contiene datos válidos.");
//       this.estrategicos = [];
//     }

//     console.log("Opciones para el select:", this.estrategicos);
//   } catch (error) {
//     console.error("Error al traer los datos:", error);
//     this.estrategicos = []; // Inicializar como vacío en caso de error
//   }
// },

async getObjEstrategicos() {
  try {
    let response;

    // Condición para ejecutar el endpoint según el nivel del empleado
    if (this.nivelEmp === 2) {
        console.log('entro en 2');
      response = await axios.get(
        `${this.$store.getters.getHostNode}/api/objEstrategicoN2/${this.valorAreaGlobalDIR}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
    } else if (this.nivelEmp === 3) {
        console.log('entro en 3 valor de puesto:', this.folio_Puesto);
      response = await axios.get(
        `${this.$store.getters.getHostNode}/api/objEstrategicoN3/${this.folio_Puesto}/${this.anio}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
    } else {
      console.warn("Nivel de empleado no válido.");
      this.estrategicos = [];
      return;
    }

    console.log("Datos completos de la respuesta:", response);

    // Validar que response.data contiene un array
    if (response.data && Array.isArray(response.data)) {
      // Mapear los datos al formato esperado para el select
      this.estrategicos = response.data.map((item) => ({
        id: item.Id_Indicador, // Asegúrate de usar el campo correcto para el ID
        nombre: item.Objetivo_Estrategico, // Asegúrate de usar el campo correcto para el nombre
      }));
    } else if (response.data && response.data.data && Array.isArray(response.data.data)) {
      // Si los datos están en response.data.data
      this.estrategicos = response.data.data.map((item) => ({
        id: item.Id_Indicador,
        nombre: item.Objetivo_Estrategico,
      }));
    } else {
      console.warn("La respuesta no contiene datos válidos.");
      this.estrategicos = [];
    }

    console.log("Opciones para el select:", this.estrategicos);
  } catch (error) {
    console.error("Error al traer los datos:", error);
    this.estrategicos = []; // Inicializar como vacío en caso de error
  }
},



        async actualizarBtn(){
            this.validarCampos_Actualizar();

            console.log('vlaor de la bandera:', this.banderaBTNActualizar)
            if(this.banderaBTNActualizar === true){
                this.banderaAcualizarDialog = true;
            }else{
                console.log('algun campo esta vacio');
            }

            
        },

        async actualizarObj(){
                this.actulizarObjPuestos();
            
        },
      
        async actulizarObjPuestos(){
            this.loadingTableEditar = true;

             // Construir un objeto con la información de cada fila
             const dataToSave = this.tablaObjAsignados.map((item, index) => ({
        ID_Puesto: this.valorPuesto,
        ID_Obj: item.Id_Obj, // Usamos el ID_Obj obtenido en objAsignados
        VO: item.VO,
        LE: item.LE,
        Ponderacion: this.PondePuestos[index] || 0,
        Unidad: item.Unidad,
        Categoria: item.Categoria,
        TipoCalculo: item.TipoCalulo,
    }));
     // Enviar los datos sin envolverlos en una clave 'data'
     try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/editar-obj-puesto`, dataToSave, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                console.log('Todos los registros se editaron correctamente:', response.data);
                this.loadingTableEditar = false;
                this.banderaAcualizarDialog = false;
                this.actualizarPuesto = false;
                
            } catch (error) {
                console.error('Error al editar los datos:', error);
                this.loadingTableEditar = false;
                this.banderaAcualizarDialog = false;
               
            }

        //console.log('Información recopilada:', dataToSave);
        },

        async eliminarObj(id){
            this.idSeleccionado = id;
            this.valorIdObj = this.idSeleccionado;
            //console.log("ID seleccionado:", this.idSeleccionado);

            this.banderaEliminarObj = true; 
        },

        async eliminarObjetivo(){
            this.loadingTableEliminarObj = true;
            console.log('ID Obj:', this.valorIdObj, 'Puesto:', this.valorPuesto); // Verifica los valores
            try {
        // Realizar la solicitud para obtener los datos
         const response = await axios.delete(`${this.$store.getters.getHostNode}/api/eliminarObj/${this.valorIdObj}/${this.valorPuesto}`, {
            headers: {
                Authorization: localStorage.getItem("token"),
            },
        });
        console.log('respuesta de lserver: ', response);

        await this.eliminarObjetivo2();
        await this.objAsignados();
        await this.getObjEstrategicos();


        this.loadingTableEliminarObj = false;
        this.banderaEliminarObj = false; 
        } catch (error) {
            console.error('Error fetching data 1:', error);
            this.loadingTableEliminarObj = false;
            this.banderaEliminarObj = false; 
        }

            },

            async eliminarObjetivo2(){
                console.log('Puesto:', this.valorPuesto, 'Trimestre:', this.trimestreFinal, 'Año:', this.anioTabla); // Verifica los valores
            try {
        // Realizar la solicitud para obtener los datos
        const response =  await axios.delete(`${this.$store.getters.getHostNode}/api/eliminarObj2/${this.valorPuesto}/${this.trimestreFinal}/${this.anioTabla}`, {
            headers: {
                Authorization: localStorage.getItem("token"),
            },
        });
        console.log('respuesta del server 2: ', response);

        // this.loadingTableEliminarObj = false;
        // this.banderaEliminarObj = false; 
        } catch (error) {
            console.error('Error fetching data 2:', error);
            // this.loadingTableEliminarObj = false;
            // this.banderaEliminarObj = false; 
        }

            },

        


updateValuePonderacion(index, type, event) {
    const value = parseFloat(event.target.value) || 0;

    if (type === 'PondePuestos') {
        let totalSum = 0;  // Variable para acumular la suma total de la ponderación

        if (Array.isArray(this.PondePuestos)) {
            // Si hay un valor en el índice, restamos ese valor del total antes de actualizarlo
            const previousValue = this.PondePuestos[index] || 0;  // Valor anterior o 0 si no existe
            const difference = value - previousValue; // Diferencia entre el valor nuevo y el anterior

            // Si el valor es 0, significa que estamos borrando el valor, así que lo restamos
            if (value === 0 && previousValue !== 0) {
                this.totalPonderacionTabla -= previousValue;
            } else {
                this.$set(this.PondePuestos, index, value);  // Actualizamos el valor en el array
                this.totalPonderacionTabla += difference;  // Actualizamos el total sumando la diferencia
            }
        } else {
            // Si Ponderacion no es un array, lo inicializamos con el primer valor
            this.PondePuestos = [value];
            this.totalPonderacionTabla = value; // Inicializamos el total con el primer valor
        }

        // Sumamos todos los valores del array de ponderación
        totalSum = this.PondePuestos.reduce((acc, val) => acc + val, 0);

        // Actualizamos el totalPonderacionTabla con la suma calculada
        this.totalPonderacionTabla = totalSum;

        // Verificamos si la suma total es igual a 100
        this.actualizarPuesto = this.totalPonderacionTabla === 100;
        //Agregar objetivo
        if(this.totalPonderacionTabla <= 100){
            this.agregarObj = true;
            this.banderaBTNAgregar = true;
            this.getObjEstrategicos();

        }else{
            this.agregarObj = false;
            this.banderaBTNAgregar = false;
            
        }
      
        // if(this.totalPonderacionTabla > 101){
        //     this.banderaBTNAgregar = false;
        // }
        console.log('bandera', this.agregarObj);
        //this.agregarObj = this.totalPonderacionTabla === !100;
        if(this.totalPonderacionTabla > 100){
            this.btnActualizar = false;
        }else{
            this.btnActualizar = true;
        }


        // Log para verificar la ponderación actualizada
        this.getObjEstrategicos();
        console.log("Ponderación actualizada:", this.PondePuestos);
        console.log("Suma de la Ponderación:", totalSum);
        console.log("Total Ponderación Tabla:", this.totalPonderacionTabla);
    }
},

// actualizarValor(valor) {
//       // Asegúrate de que el valor sea un número; si está vacío o inválido, se convierte en 0
//       valor = parseFloat(valor) || 0;

//       // Calcula la diferencia entre el nuevo valor y el anterior
//       const diferencia = valor - this.previousValue;

//       // Actualiza la ponderación total
//       this.totalPonderacionTabla += diferencia;

//       // Guarda el nuevo valor como el "anterior" para futuros cálculos
//       this.previousValue = valor;

//       console.log(`Valor actualizado: ${valor}, Total Ponderación: ${this.totalPonderacionTabla}`);
//       if(this.totalPonderacionTabla < 101){
//         this.banderaBTNAgregar = false;
//       }else{
//         this.banderaBTNAgregar = true;
//       }
//     },
actualizarValor(valor) {
  // Convierte el valor a número, si es inválido o vacío, lo toma como 0
  const nuevoValor = parseFloat(valor) || 0;

  // Verifica si el input tenía un valor previo registrado
  if (this.previousValue !== undefined && this.previousValue !== null) {
    // Calcula la diferencia (sumar o restar) entre el nuevo valor y el anterior
    const diferencia = nuevoValor - this.previousValue;

    // Actualiza el total de ponderación sumando la diferencia
    this.totalPonderacionTabla += diferencia;
  } else {
    // Si no hay valor previo, suma el nuevo valor directo
    this.totalPonderacionTabla += nuevoValor;
  }

  // Guarda el nuevo valor como "anterior" para futuros cálculos
  this.previousValue = nuevoValor;

  // Lógica para el estado del botón según totalPonderacionTabla
  if (this.totalPonderacionTabla < 101) {
    this.banderaBTNAgregar = false;
  } else {
    this.banderaBTNAgregar = true;
  }

  // Mostrar en consola para depuración
  console.log(`Valor actualizado: ${nuevoValor}, Total Ponderación: ${this.totalPonderacionTabla}`);
},




        //Esto es para la tabla de objetivos asignados
        async metodosObjPropios(item, Nivel){
            // console.log('valor del nivel:', Nivel);
            this.formData = {
            selectedOption: null,
            input1: "",
            input2: "",
            input3: "",
            input4: "",
            input5: "",
            select1: "",
            select2: "",
        },
            console.log('valor de la area global:', this.valorAreaGlobalDIR);
            this.nivelEmp = Nivel;
            await this.MuestraObjetivosAsignados(item);
            await this.objAsignados();
            await this.getObjEstrategicos();
            //await this.actualizarPropios();
        },


async objAsignados() {
    this.dialogObjetivosEmp = true;
    this.loadingTablePuestos = true;
    console.log('valor del puesto:', this.valorPuesto, 'nivel:', this.nivelEmp);

    try {
        // Realizar la solicitud para obtener los datos
        let response;
        // Verificar el valor de nivelEmp para determinar qué solicitud ejecutar
        if (this.nivelEmp === 2) {
            response = await axios.get(`${this.$store.getters.getHostNode}/api/ObjTabla/${this.valorPuesto}`, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            });
        } else if (this.nivelEmp === 3) {
            response = await axios.get(`${this.$store.getters.getHostNode}/api/ObjTablaN2/${this.valorPuesto}`, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            });
        } else {
            console.warn("nivelEmp no es válido. Valor recibido:", this.nivelEmp);
            this.loadingTablePuestos = false;
            return; // Salir si nivelEmp no es válido
        }


        let totalPonderacion = 0;
       
        this.PondePuestos = response.data.data.map(item => item.Ponderacion);

        // Mapear los datos y asignarlos a la tablaObjAsignados
        this.tablaObjAsignados = response.data.data.map((item) => {
            // Sumar las ponderaciones para calcular el total
            totalPonderacion += parseFloat(item.Ponderacion) || 0;

            return {
                Id_ES_Objetivo_Estrategico: item.Id_ES_Objetivo_Estrategico,
                DescObjetivo: item.DescObjetivo,
                VO: item.VO,
                LE: item.LE,
                Ponderacion: item.Ponderacion,  // Asignar Ponderacion al item
                Unidad: item.Unidad,
                Categoria: item.Tipo,
                TipoCalulo: item.TipoCalculo,
                Id_Obj: item.ID_Obj,
            };
        });

        // Asignar el total de ponderación calculado
        this.totalPonderacionTabla = totalPonderacion;
        console.log("Total de ponderaciones: ", this.totalPonderacionTabla);
        if(this.totalPonderacionTabla <= 100){
            this.agregarObj = true;
            this.banderaBTNAgregar = false;

        }else{
            this.agregarObj = false;
            this.banderaBTNAgregar = false;
        }

        if(this.totalPonderacionTabla > 100){
            this.btnActualizar = false;
        }else{
            this.btnActualizar = true;
        }
      
        this.loadingTablePuestos = false;
    } catch (error) {
        console.error('Error fetching data:', error);
        this.loadingTablePuestos = false;
    }
},


        async MuestraObjetivosAsignados(item){

            this.dialogObjetivosEmp = true;
            this.loadingTablePuestos = true;

            this.selectedItem = item.folio;
            this.folioGlobalTabla1Meses = item.Resp;

            let resultado = this.selectedItem || this.folioGlobalTabla1Meses || null;
            //console.log('valor del folio final:', resultado, this.Id_Area_DirGlobal);
            this.folioFinal = resultado;

            //console.log('valor del folio:', this.folioFinal);
           
            try {
                // Realizar la solicitud para obtener los datos
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/puestoAsignado/${this.folioFinal}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                //Guardar los valores como estaban originalmente
                this.Emp_Nombre = response.data.data.map(item => item.Nombre);
                this.folio_Puesto = response.data.data.map(item => item.Folio);
                this.EMP_KEYPUE = response.data.data.map(item => item.EMP_KEYPUE);
                this.PUE_DESPUE = response.data.data.map(item => item.PUE_DESPUE);
                // this.descPuesto = response.data.data.map(item => item.PUE_DESPUE[0]);
                this.descPuesto = response.data.data[0]?.PUE_DESPUE || "Sin descripción";
                this.valorPuesto = response.data.data[0]?.EMP_KEYPUE[0] || "Sin clave";

                 // Almacenar los datos en tableData
                this.tablaPuestos = response.data.data.map((item) => ({
                folio: item.Folio,
                Nombre: item.Nombre,
                DEP_DESDEP: item.DEP_DESDEP,
                }));
                this.loadingTablePuestos = false;
               // console.log('valores de la tabla:', this.Emp_Nombre);
                        

                
            } catch (error) {
                console.error('Error fetching data:', error);
                this.loadingTablePuestos = false;
            }
           
        
        },

      

        async autorizarObjetivos(){
            this.dialogBTNAutorizar = true;
        },
      
       async guardarCambiosEditados(){
        this.dialogBTNEditar = true; 

       },

       async elimiarTodosMeses(){
        this.dialogBTNEeliminar = true; 

       },
       
       //Este metodo es para autorizar los valores del trimestre
       async autorizarValoresMeses(){
        console.log('entro a autorizar')
        this.loadingTableEditar = true;
           // Crear una lista de objetos para ordenar por Id_Indicador
           const combinedData = this.Id_IndicadorMesEditar.map((id) => ({
                Id_IndicadorMesEditar: id,
                // ValorPrimer: this.ValObjPrimerMes[index],
                // ValorSegundo: this.ValObjSegundoMes[index],
                // ValorTercero: this.ValObjTercero[index],
                Anio: this.anioTabla,
                Trimestre: this.trimestreFinal,
               
                NumeroPersonal: this.folioFinal,
            }));

        
            //console.log('valor de la data:' ,combinedData);

              // Enviar los datos sin envolverlos en una clave 'data'
              try {
                 await axios.post(`${this.$store.getters.getHostNode}/api/autorizar-valor-meses`, combinedData, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                //console.log('Todos los registros se insertaron correctamente:', response.data);
                //this.openModalCalificacionEstatus1();
                //await this.getValorMesesEmpleados(this.folioFinal);
                this.dialogBTNAutorizar = false; 
                this.loadingTableEditar = false;
                this.getDatos();
                await this.getCorreo();
                await this.enviarCorreoEditar();

         
                
            } catch (error) {
                console.error('Error al insertar los datos:', error);
                this.dialogBTNAutorizar = false; 
                this.loadingTableEditar = false;
               

            }


       },


       //Este metodo es para editar los valores
       async editarValoresMeses(){
        this.loadingTableEditar = true;
           // Crear una lista de objetos para ordenar por Id_Indicador
           const combinedData = this.Id_IndicadorMesEditar.map((id, index) => ({
                Id_IndicadorMesEditar: id,
                ValorPrimer: this.ValObjPrimerMes[index],
                ValorSegundo: this.ValObjSegundoMes[index],
                ValorTercero: this.ValObjTercero[index],
                Anio: this.anioTabla,
                Trimestre: this.trimestreFinal,
               
                NumeroPersonal: this.folioFinal,
            }));

           // console.log('valor de la data:' ,combinedData);

              // Enviar los datos sin envolverlos en una clave 'data'
              try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/editar-valor-meses`, combinedData, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                console.log('Todos los registros se editaron correctamente:', response.data);
                //this.openModalCalificacionEstatus1();
                await this.getValorMesesEmpleados(this.folioFinal);
                this.dialogBTNEditar = false; 
                this.loadingTableEditar = false;
                await this.getCorreo();
                await this.enviarCorreoEditar();
              
             
            } catch (error) {
                console.error('Error al insertar los datos:', error);
                this.dialogBTNEditar = false; 
                this.loadingTableEditar = false;

            }


       },

       //Esto es para eliminar todos los campos
       
       async eliminarValoresMeses(){
        this.loadingTableEditar = true;
           // Crear una lista de objetos para ordenar por Id_Indicador
           const combinedData = this.Id_IndicadorMesEditar.map((id) => ({
                Id_IndicadorMesEditar: id,
                // ValorPrimer: this.ValObjPrimerMes[index],
                // ValorSegundo: this.ValObjSegundoMes[index],
                // ValorTercero: this.ValObjTercero[index],
                Anio: this.anioTabla,
                Trimestre: this.trimestreFinal,
               
                NumeroPersonal: this.folioFinal,
            }));

            //console.log('valor de la data:' ,combinedData);

              // Enviar los datos sin envolverlos en una clave 'data'
              try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/eliminar-valor-meses`, combinedData, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                console.log('Todos los registros se eliminaron correctamente:', response.data);
                // this.openModalCalificacionEstatus1(item);
                await this.getValorMesesEmpleados(this.folioFinal);
                this.dialogBTNEeliminar = false; 
                this.loadingTableEditar = false;
                this.totalPonderacion = 0;
                this.totalPuntos = 0;
                // await this.getCorreo();
                // await this.enviarCorreoEditar();
              
             
            } catch (error) {
                console.error('Error al insertar los datos:', error);
                this.dialogBTNEeliminar = false; 
                this.loadingTableEditar = false;

            }


       },

       //Esto es apra sacar el correo para enviarlo
       async getCorreo() {
            
            try {
                // Realizar la solicitud para obtener los datos
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/correoEmp/${this.folioFinal}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                //Guardar los valores como estaban originalmente
                this.CorreoUser = response.data.data.map(item => item.Correo);
              

            } catch (error) {
                console.error('Error fetching data:', error);
            }
            // console.log('Salio del metodo getQuery1 %%%%%')
        },


       //Esto es para enviar el correo
       async enviarCorreoEditar(){
        const correo = this.CorreoUser;
        //console.log('valor de correo', correo);
        try {
                 await axios.get(`${this.$store.getters.getHostNode}/api/enviar-correoEditar/${correo}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                //console.log('Correo enviado', response);
                
               
              
             
            } catch (error) {
                console.error('Error al enviar el correo', error);
                
            }

       },

       async updateValue(index, type, event) {
            const value = parseFloat(event.target.value) || 0;
            if (type === 'ValorPrimer') {
                this.$set(this.ValObjPrimerMes, index, value);
            } else if (type === 'ValorSegundo') {
                this.$set(this.ValObjSegundoMes, index, value);
            } else if (type === 'ValorTercero') {
                this.$set(this.ValObjTercero, index, value);
            }
            //this.recalcularValores();
            await this.validacionesTestNuevoEditar();
        },
        

        handleModalClick(item) {
            // if (item.estatus === 1) {
            //     // this.openModalCalificacionEstatus1(item);
            //     this.openModalCalificacionEstatus1(item);
            // } else if (item.estatus === 2) {
            //     this.openModalCalificacion(item); // Reemplaza con el método para estatus = 2
            // } else {
            //     this.openModalCalificacion(item); // Para estatus = null o 0
            // }
            
                // this.openModalCalificacionEstatus1(item);
                this.openModalCalificacionEstatus1(item);
           
        },
        getFlagColor(estatus, grupo_Trabajo) {
            if (grupo_Trabajo === 1) {
                return 'gray';

            } else {

                if (estatus === 1) {
                    return 'yellow'; // Estatus 2 -> Amarillo
                } else if (estatus === 0 || estatus === '' || estatus === null) {
                    return 'red'; // Estatus 0, vacío o null -> Rojo
                } else {
                    return 'green'; // Cualquier otro estatus -> Verde
                }
            }
        },
        getCellStyle(desempenio, dReal1) {
            // Si dReal1 tiene un estilo específico, lo aplicamos
            if (dReal1 === 0) {
                return { textAlign: 'center', background: '#F78181', color: 'white', borderRadius: '25px', padding: '1px' }; // rojo
            } else if (dReal1 < 100 && dReal1 > 0) {
                return { textAlign: 'center', background: '#F4FA58', color: 'black', borderRadius: '25px', padding: '1px' }; // amarillo
            } else if (dReal1 === 100) {
                return { textAlign: 'center', background: '#81F781', color: 'white', borderRadius: '25px', padding: '1px' }; // verde
            }

            // Si no hay estilo para dReal1, revisamos el desempeño
            if (desempenio === 0) {
                return { textAlign: 'center', background: '#F78181', color: 'white', borderRadius: '25px', padding: '1px' }; // rojo
            } else if (desempenio < 100 && desempenio > 0) {
                return { textAlign: 'center', background: '#F4FA58', color: 'black', borderRadius: '25px', padding: '1px' }; // amarillo
            } else if (desempenio === 100) {
                return { textAlign: 'center', background: '#81F781', color: 'white', borderRadius: '25px', padding: '1px' }; // verde
            }

            // Estilo por defecto si no coincide ningún caso
            return { textAlign: 'center', borderRadius: '5px', padding: '5px' };
        },
        getCellStyle2(desempenio2, dReal2) {
            // Si dReal1 tiene un estilo específico, lo aplicamos
            if (dReal2 === 0) {
                return { textAlign: 'center', background: '#F78181', color: 'white', borderRadius: '25px', padding: '1px' }; // rojo
            } else if (dReal2 < 100 && dReal2 > 0) {
                return { textAlign: 'center', background: '#F4FA58', color: 'black', borderRadius: '25px', padding: '1px' }; // amarillo
            } else if (dReal2 === 100) {
                return { textAlign: 'center', background: '#81F781', color: 'white', borderRadius: '25px', padding: '1px' }; // verde
            }

            // Si no hay estilo para dReal1, revisamos el desempeño
            if (desempenio2 === 0) {
                return { textAlign: 'center', background: '#F78181', color: 'white', borderRadius: '25px', padding: '1px' }; // rojo
            } else if (desempenio2 < 100 && desempenio2 > 0) {
                return { textAlign: 'center', background: '#F4FA58', color: 'black', borderRadius: '25px', padding: '1px' }; // amarillo
            } else if (desempenio2 === 100) {
                return { textAlign: 'center', background: '#81F781', color: 'white', borderRadius: '25px', padding: '1px' }; // verde
            }

            // Estilo por defecto si no coincide ningún caso
            return { textAlign: 'center', borderRadius: '5px', padding: '5px' };
        },

        // Método para asignar los valores de los meses
        asignarMeses(signal) {
            if (signal.aborted) throw new DOMException('Abortado', 'AbortError');
            // console.log('valor del mes seleccionado::#######', this.trimestreSeleccionado);
            switch (this.trimestreSeleccionado) {
                case 1:
                    this.mes1Tabla = 1; // Enero
                    this.mes2Tabla = 2; // Febrero
                    this.mes3Tabla = 3; // Marzo
                    break;
                case 2:
                    this.mes1Tabla = 4; // Abril
                    this.mes2Tabla = 5; // Mayo
                    this.mes3Tabla = 6; // Junio
                    break;
                case 3:
                    this.mes1Tabla = 7; // Julio
                    this.mes2Tabla = 8; // Agosto
                    this.mes3Tabla = 9; // Septiembre
                    break;
                case 4:
                    this.mes1Tabla = 10; // Octubre
                    this.mes2Tabla = 11; // Noviembre
                    this.mes3Tabla = 12; // Diciembre
                    break;
                default:
                    this.mes1Tabla = 0;
                    this.mes2Tabla = 0;
                    this.mes3Tabla = 0;
                    break;
            }
        },



        //Esto es para ver los colaboradores tab
        // Alterna la fila expandida
        mostrarMensaje() {
            // console.log('Hola');
            //this.getEmpleadosDir();
            // this.gerCantDirEmp();
            this.mostrarFilas = !this.mostrarFilas; // Alterna entre mostrar/ocultar las filas
        },



        async getDatos() {
             // Vacía el array expanded2 para cerrar todas las filas expandidas
 
             this.expanded = [];
             this.expanded2 = [];
             this.expanded4 = [];
             this.expanded5 = [];
             this.expanded6 = [];
            try {
                this.loadingTable = true; // Mostrar el cargando

                if (this.AreaUser == "COMITE" && this.rhUsuario != "gsandovalg" && this.rhUsuario != "aurrea") {
                    console.log('**************************************');
                    console.log('ENTRO AL 1 COMITE IF DE REPORTE OBJETIVOS %%%%%');

                    if(this.numEmpleado === '0'){
                        await this.getQuery1_Dir();
                    await this.getQuery2();
                    this.getQuery22();
                    await this.getQuery3();
                    await this.getQuery4();
                    this.getEvaluacion();
                    this.gerCantDir();
                    await this.getPuntosEmpleados();

                    // Asigna los valores de mes1, mes2 y mes3 antes de llamar a validaciones
                    const idAreaActual = Object.keys(this.resultadosPorArea)[0]; // Asegúrate de seleccionar la Id_Area correcta
                    if (this.resultadosPorArea[idAreaActual]) {
                        this.mes1 = this.resultadosPorArea[idAreaActual].mes1 || [];
                        this.mes2 = this.resultadosPorArea[idAreaActual].mes2 || [];
                        this.mes3 = this.resultadosPorArea[idAreaActual].mes3 || [];
                        this.TipoCalculos = this.resultadosPorArea[idAreaActual].TipoCalculo || [];
                    }
                    await this.validaciones(); // Llamar a validaciones ahora que las variables están definidas correctamente
                    this.getPuntos();

                    }else{

                    this.loadingTable = true;
                    await this.getQuery1();
                    await this.getQuery2();
                    this.getQuery22();
                    await this.getQuery3();
                    await this.getQuery4();
                    this.getEvaluacion(); //Este es oara ver lo de OBJ, CUT, EXT, TOTAL
                    this.gerCantDir();
                    await this.getPuntosEmpleados();
                    // Asigna los valores de mes1, mes2 y mes3 antes de llamar a validaciones
                    const idAreaActual = Object.keys(this.resultadosPorArea)[0]; // Asegúrate de seleccionar la Id_Area correcta
                    if (this.resultadosPorArea[idAreaActual]) {
                        this.mes1 = this.resultadosPorArea[idAreaActual].mes1 || [];
                        this.mes2 = this.resultadosPorArea[idAreaActual].mes2 || [];
                        this.mes3 = this.resultadosPorArea[idAreaActual].mes3 || [];
                        this.TipoCalculos = this.resultadosPorArea[idAreaActual].TipoCalculo || [];
                    }

                    await this.validaciones(); // Llamar a validaciones ahora que las variables están definidas correctamente
                    this.getPuntos();

                    this.loadingTable = false;
                }



                   

                    //esto lo comente
                } else if (this.banderaChampion == true && this.banderaRESPONSABLE === false) {
                    console.log('**************************************');
                    console.log('ENTRO AL 2 Champions IF DE REPORTE OBJETIVOS %%%%%');
                    await this.getQuery12();
                    await this.getQuery2();
                    this.getQuery22();
                    await this.getQuery3();
                    await this.getQuery4();
                    this.getEvaluacion();
                    this.gerCantDir();
                    await this.getPuntosEmpleados();

                    // Asigna los valores de mes1, mes2 y mes3 antes de llamar a validaciones
                    const idAreaActual = Object.keys(this.resultadosPorArea)[0]; // Asegúrate de seleccionar la Id_Area correcta
                    if (this.resultadosPorArea[idAreaActual]) {
                        this.mes1 = this.resultadosPorArea[idAreaActual].mes1 || [];
                        this.mes2 = this.resultadosPorArea[idAreaActual].mes2 || [];
                        this.mes3 = this.resultadosPorArea[idAreaActual].mes3 || [];
                        this.TipoCalculos = this.resultadosPorArea[idAreaActual].TipoCalculo || [];
                    }
                    await this.validaciones(); // Llamar a validaciones ahora que las variables están definidas correctamente
                    this.getPuntos();
                } else if (this.AreaUser == "ADMING" || this.numEmpleado === '0') {
                    console.log('**************************************');
                    console.log('ENTRO AL 3 ADMING IF DE REPORTE OBJETIVOS %%%%%');
                    await this.getQuery1_Dir();
                    await this.getQuery2();
                    this.getQuery22();
                    await this.getQuery3();
                    await this.getQuery4();
                    this.getEvaluacion();
                    this.gerCantDir();
                    await this.getPuntosEmpleados();

                    // Asigna los valores de mes1, mes2 y mes3 antes de llamar a validaciones
                    const idAreaActual = Object.keys(this.resultadosPorArea)[0]; // Asegúrate de seleccionar la Id_Area correcta
                    if (this.resultadosPorArea[idAreaActual]) {
                        this.mes1 = this.resultadosPorArea[idAreaActual].mes1 || [];
                        this.mes2 = this.resultadosPorArea[idAreaActual].mes2 || [];
                        this.mes3 = this.resultadosPorArea[idAreaActual].mes3 || [];
                        this.TipoCalculos = this.resultadosPorArea[idAreaActual].TipoCalculo || [];
                    }
                    await this.validaciones(); // Llamar a validaciones ahora que las variables están definidas correctamente
                    this.getPuntos();
                    //Este es para los responsables
                } else if (this.banderaRESPONSABLE === true) {
                    console.log('**************************************');
                    console.log('ENTRO AL 4 Responsable1 IF DE REPORTE OBJETIVOS %%%%%');
                    // await this.getQuery1_Resp();
                    
                    // console.log('valores de Resp', this.Id_Resp);
                    await this.getEmpleadosDir(this.numEmpleado); 
                    await this.gerCantDirEmp();           
                    await this.getEvaluacion2Emp();        
                    await this.getBonoCoordNivel3_Resp();            
                    await this.metodoParaPuntosEmpleados();

                  
                }
                this.loadingTable = false; // Desactiva el indicador de carga después de cargar los datos
            } catch (error) {
                console.error('Error al cargar los datos Reporte Objetivos:', error);
                this.loadingTable = false; // Asegúrate de desactivar el cargando incluso si hay un error
            }
        },

        confirmarRechazo() {
             // Cierra el diálogo
             this.cancelar(); // Llama al método para rechazar el reporte de bono
        },

        async getQuery1() {
            // console.log('Entro al metodo getQuery1 %%%%%')
            // console.log('VALOR DE CAD AREAS EN QUERY 1', this.numEmpleado);
            // const response = await axios.get(`${this.$store.getters.getHostNode}/api/query1-2/(1,2,6,7,9,10,12,11)`, {
            try {
                // Realizar la solicitud para obtener los datos
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/query1/${this.numEmpleado}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                //Guardar los valores como estaban originalmente
                this.sNombre = response.data.data.map(item => item.Nombre);
                this.Id_Resp = response.data.data.map(item => item.Resp);
                this.Id_Area = response.data.data.map(item => parseFloat(item.Id_Area));
                this.Area = response.data.data.map(item => item.Area);

                const dataGroupedByName = response.data.data.reduce((acc, item) => {
                    if (!acc[item.Nombre]) {
                        acc[item.Nombre] = {
                            Nombre: item.Nombre,
                            Resp: item.Resp,
                            Id_Area: [parseFloat(item.Id_Area)],
                            Area: [item.Area], // Guardar las áreas como un array para concatenarlas después
                            PuntosObjetivos: '',
                            PuntosLogro: '',
                            PuntosMejora: '',
                            PuntosValores: '',
                            Extras: '',
                            Total: '',
                            dBonoDIR: 0 // Para asegurar que se incluyen todas las propiedades necesarias
                        };
                    } else {
                        acc[item.Nombre].Id_Area.push(parseFloat(item.Id_Area)); // Agregar los Id_Area adicionales
                        acc[item.Nombre].Area.push(item.Area); // Agregar el área adicional al array de áreas
                    }
                    return acc;
                }, {});

                // Transformar el objeto a un array y concatenar las áreas con una diagonal
                this.rows = Object.values(dataGroupedByName).map(item => ({
                    ...item,
                    Id_Area: item.Id_Area, // Mantener el array de Id_Area
                    Area: item.Area.join(' / ') // Concatenar todas las áreas con una diagonal
                }));

                // console.log('Datos agrupados por nombre:', this.rows);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            // console.log('Salio del metodo getQuery1 %%%%%')
        },

        //ESTO ES PARA MOSTRAR TODOS DIRECTORES
        async getQuery1_Dir() {
            // console.log('Entro al metodo getQuery1 %%%%%')

            try {
                // Realizar la solicitud para obtener los datos
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/query1_Directores`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                //Guardar los valores como estaban originalmente
                this.sNombre = response.data.data.map(item => item.Nombre);
                this.Id_Resp = response.data.data.map(item => item.Resp);
                this.Id_Area = response.data.data.map(item => parseFloat(item.Id_Area));
                this.Area = response.data.data.map(item => item.Area);


                // console.log('Valor de sNombre:', this.sNombre); 
                // console.log('Valor de Id_Resp 1:', this.Id_Resp);
                //  console.log('Valor de Id_Area:', this.Id_Area);
                //  console.log('Valor de Area:', this.Area);

                // Agrupar los resultados por nombre
                const dataGroupedByName = response.data.data.reduce((acc, item) => {
                    if (!acc[item.Nombre]) {
                        acc[item.Nombre] = {
                            Nombre: item.Nombre,
                            Resp: item.Resp,
                            Id_Area: [parseFloat(item.Id_Area)],
                            Area: [item.Area], // Guardar las áreas como un array para concatenarlas después
                            PuntosObjetivos: '',
                            PuntosLogro: '',
                            PuntosMejora: '',
                            PuntosValores: '',
                            Extras: '',
                            Total: '',
                            dBonoDIR: 0 // Para asegurar que se incluyen todas las propiedades necesarias
                        };
                    } else {
                        acc[item.Nombre].Id_Area.push(parseFloat(item.Id_Area)); // Agregar los Id_Area adicionales
                        acc[item.Nombre].Area.push(item.Area); // Agregar el área adicional al array de áreas
                    }
                    return acc;
                }, {});

                // Transformar el objeto a un array y concatenar las áreas con una diagonal
                this.rows = Object.values(dataGroupedByName).map(item => ({
                    ...item,
                    Id_Area: item.Id_Area, // Mantener el array de Id_Area
                    Area: item.Area.join(' / ') // Concatenar todas las áreas con una diagonal
                }));

                // console.log('Datos agrupados por nombre:', this.rows);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            // console.log('Salio del metodo getQuery1 %%%%%')
        },

        //ESTO ES PARA MOSTRAR Responsable
        async getQuery1_Resp() {
            try {
                const response = await axios.get(
                    `${this.$store.getters.getHostNode}/api/Empleados-directores/${this.anioTabla}/${this.trimestreFinal}/${this.numEmpleado}`,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );


                // Guardar los valores como estaban originalmente
                //this.sNombre = response.data.data.map(item => item.EMP_NOMEMP);
                this.Id_Resp = response.data.data.map(item => item.EMP_KEYEMP);
                //this.Area = response.data.data.map(item => item.PUE_DESPUE);
                // console.log("Nombre sNombre:", this.sNombre);
                // console.log("Nombre Id_Resp:", this.Id_Resp);
                // console.log("Nombre Area:", this.Area);

                // console.log('Datos agrupados por nombre:', this.rows);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            // console.log('Salio del metodo getQuery1 %%%%%')
        },

        getButtonColorClass(CantColab) {
            return CantColab > 0 ? 'green--text' : 'red--text';
        },

        // Método auxiliar para verificar si se debe continuar con la ejecución
        async checkExecution(executionId, callback) {
            if (this.cancelExecutionId !== executionId) {
                console.log("Ejecución cancelada para este expand");
                //throw new Error("Ejecución interrumpida debido a un cambio en el expand activo");
            }
            await callback();
        },


        //Esto es para la tabla 1
        //ESTE ES QUE ESTOY MODIFICANDO
        async toggleExpand(item, isExpanded, expand) {
            // Crear un identificador único para esta expansión
            const currentExecutionId = Symbol("executionId");

            if (isExpanded) {
                // Contraer el elemento expandido actual y removerlo de la lista
                this.expanded = this.expanded.filter(expItem => expItem !== item);
                //console.log(`Cerraste: ${item.Nombre}`);

                // Establecer el flag para cancelar la ejecución
                this.cancelExecutionId = currentExecutionId;
                //return; // Salir inmediatamente, ya que el elemento fue cerrado
            } else {

                // Contraer cualquier elemento previamente expandido y expandir el nuevo
                this.expanded = [item]; // Solo un elemento expandido a la vez
                expand();
                this.loadingTable2 = true; // Activar loading antes de comenzar la carga de datos
                this.valorAreaGlobalDIR = getAreaValue(item.Id_Area);
                //console.log('Hola Mundo', item.Resp, 'AREA:', this.valorAreaGlobalDIR);

                // Actualizar el identificador de ejecución actual
                this.cancelExecutionId = currentExecutionId;

                try {
                    // Llamar los métodos de forma secuencial, verificando el identificador antes de ejecutar cada uno
                    await this.checkExecution(currentExecutionId, () => this.getEmpleadosDir(item.Resp)); // Obtener empleados de directores
                    await this.checkExecution(currentExecutionId, () => this.gerCantDirEmp());            // Obtener cantidad de empleados
                    await this.checkExecution(currentExecutionId, () => this.getEvaluacion2Emp());        // Obtener evaluación de empleados
                    await this.checkExecution(currentExecutionId, () => this.getBonoCoord());            // Obtener bono para coordinadores
                    await this.checkExecution(currentExecutionId, () => this.metodoParaPuntosEmpleados()); // Obtener puntos de empleados

                    //console.log("Todos los métodos se ejecutaron correctamente");
                } catch (error) {

                    console.error("Error en la ejecución de los métodos asincrónicos:", error);

                } finally {
                    // Usar $nextTick para asegurar que los datos se hayan pintado
                    this.$nextTick(() => {
                        if (this.cancelExecutionId === currentExecutionId) {
                            this.loadingTable2 = false; // Desactivar loading tras asegurar la renderización
                        }
                    });
                }
            }
        },


        //-------------------------------------------
        //Esto es para la tabla 2
        //Este es el que estoy modificando: 
        async toggleExpand2(item, isExpanded, expand) {
            // Crear un identificador único para esta expansión
            const currentExecutionId = Symbol("executionId");

            if (isExpanded) {
                // Contraer el elemento expandido actual y removerlo de la lista
                this.expanded2 = this.expanded2.filter(expItem => expItem !== item);
               // console.log(`Cerraste: ${item.Nombre}`);

                // Establecer el flag para cancelar la ejecución
                this.cancelExecutionId = currentExecutionId;
            } else {
                // Contraer cualquier elemento previamente expandido y expandir el nuevo
                this.expanded2 = [item]; // Solo un elemento expandido a la vez
                expand();
                this.loadingTable3 = true; // Activar loading antes de comenzar la carga de datos
                this.valorFolioGlobalNivel3 = item.folio;
                //console.log('Hola Mundo', item.folio, 'valorAreaGlobalDIR:', this.valorFolioGlobalNivel3);

                // Actualizar el identificador de ejecución actual
                this.cancelExecutionId = currentExecutionId;

                try {
                    // Llamar los métodos de forma secuencial, verificando el identificador antes de ejecutar cada uno
                    await this.checkExecution(currentExecutionId, () => this.getEmpleadosNivel3(item.folio)); // Obtener empleados de directores
                    await this.checkExecution(currentExecutionId, () => this.gerCantDirEmpNivel3());          // Obtener cantidad de empleados
                    await this.checkExecution(currentExecutionId, () => this.getEvaluacion2EmpNivel3());      // Obtener evaluación de empleados
                    await this.checkExecution(currentExecutionId, () => this.getBonoCoordNivel3());           // Obtener bono para coordinadores
                    await this.checkExecution(currentExecutionId, () => this.metodoParaPuntosEmpleadosNivel3()); // Obtener puntos de empleados

                    // console.log("Todos los métodos se ejecutaron correctamente");
                } catch (error) {
                    console.error("Ocurrió un error al cargar los datos:", error);
                } finally {
                    // Usar $nextTick para asegurar que los datos se hayan pintado
                    this.$nextTick(() => {
                        if (this.cancelExecutionId === currentExecutionId) {
                            this.loadingTable3 = false; // Desactivar loading tras asegurar la renderización
                        }
                    });
                }
            }
        },

        async toggleExpand2_Resp(item, isExpanded, expand) {
            // Crear un identificador único para esta expansión
            const currentExecutionId = Symbol("executionId");

            if (isExpanded) {
                // Contraer el elemento expandido actual y removerlo de la lista
                this.expanded2 = this.expanded2.filter(expItem => expItem !== item);
               // console.log(`Cerraste: ${item.Nombre}`);

                // Establecer el flag para cancelar la ejecución
                this.cancelExecutionId = currentExecutionId;
            } else {
                // Contraer cualquier elemento previamente expandido y expandir el nuevo
                this.expanded2 = [item]; // Solo un elemento expandido a la vez
                expand();
                this.loadingTable3 = true; // Activar loading antes de comenzar la carga de datos
                this.valorFolioGlobalNivel3 = item.folio;
                //console.log('Hola Mundo', item.folio, 'valorAreaGlobalDIR:', this.valorFolioGlobalNivel3);

                // Actualizar el identificador de ejecución actual
                this.cancelExecutionId = currentExecutionId;

                try {
                    // Llamar los métodos de forma secuencial, verificando el identificador antes de ejecutar cada uno
                    await this.checkExecution(currentExecutionId, () => this.getEmpleadosNivel3(item.folio)); // Obtener empleados de directores
                    await this.checkExecution(currentExecutionId, () => this.gerCantDirEmpNivel3());          // Obtener cantidad de empleados
                    await this.checkExecution(currentExecutionId, () => this.getEvaluacion2EmpNivel3());      // Obtener evaluación de empleados
                    await this.checkExecution(currentExecutionId, () => this.getBonoCoordNivel3_Resp_Expand2());           // Obtener bono para coordinadores
                    await this.checkExecution(currentExecutionId, () => this.metodoParaPuntosEmpleadosNivel3()); // Obtener puntos de empleados

                    // console.log("Todos los métodos se ejecutaron correctamente");
                } catch (error) {
                    console.error("Ocurrió un error al cargar los datos:", error);
                } finally {
                    // Usar $nextTick para asegurar que los datos se hayan pintado
                    this.$nextTick(() => {
                        if (this.cancelExecutionId === currentExecutionId) {
                            this.loadingTable3 = false; // Desactivar loading tras asegurar la renderización
                        }
                    });
                }
            }
        },





        //-------------------------------------------
        //Esto es para la tabla 4
        //este es el que se va a modificar
        async toggleExpand4(item, isExpanded, expand) {
            // console.log('Se está ejecutando el método 4 %%%');
            // Verificar si el elemento está expandido
            // Crear un identificador único para esta expansión
            const currentExecutionId = Symbol("executionId");
            if (isExpanded) {
                // Si el elemento ya está expandido, lo contraemos y lo removemos de la lista `expanded4`
                this.expanded4 = this.expanded4.filter(expItem => expItem !== item);
               // console.log(`Cerraste: ${item.Nombre}`);
                // Establecer el flag para cancelar la ejecución
                this.cancelExecutionId = currentExecutionId;

            } else {
                // Contraer cualquier elemento previamente expandido y expandir el nuevo
                this.expanded4 = [item]; // Esto asegura que solo un elemento esté expandido a la vez
                expand();
                this.loadingTable4 = true; // Activar loading antes de comenzar la carga de datos
                this.valorFolioGlobalNivel4 = item.folio;
                // console.log('Hola Mundo', item.folio, 'valorAreaGlobalDIR:', this.valorFolioGlobalNivel4);
                // Actualizar el identificador de ejecución actual
                this.cancelExecutionId = currentExecutionId;

                try {
                    // Llamar los métodos de forma secuencial, verificando el identificador antes de ejecutar cada uno
                    await this.checkExecution(currentExecutionId, () => this.getEmpleadosNivel4(item.folio)); // Obtener empleados de directores
                    await this.checkExecution(currentExecutionId, () => this.gerCantDirEmpNivel4());          // Obtener cantidad de empleados
                    await this.checkExecution(currentExecutionId, () => this.getEvaluacion2EmpNivel4());      // Obtener evaluación de empleados
                    await this.checkExecution(currentExecutionId, () => this.getBonoCoordNivel4());           // Obtener bono para coordinadores
                    await this.checkExecution(currentExecutionId, () => this.metodoParaPuntosEmpleadosNivel4()); // Obtener puntos de empleados




                } catch (error) {
                    console.error("Ocurrió un error al cargar los datos en nivel 4:", error);
                } finally {
                    // Usar $nextTick para asegurar que los datos se hayan pintado
                    this.$nextTick(() => {
                        if (this.cancelExecutionId === currentExecutionId) {
                            this.loadingTable4 = false; // Desactivar loading tras asegurar la renderización
                        }

                    });
                }
            }
        },

        async toggleExpand4_Resp(item, isExpanded, expand) {
            // console.log('Se está ejecutando el método 4 %%%');
            // Verificar si el elemento está expandido
            // Crear un identificador único para esta expansión
            const currentExecutionId = Symbol("executionId");
            if (isExpanded) {
                // Si el elemento ya está expandido, lo contraemos y lo removemos de la lista `expanded4`
                this.expanded4 = this.expanded4.filter(expItem => expItem !== item);
                //console.log(`Cerraste: ${item.Nombre}`);
                // Establecer el flag para cancelar la ejecución
                this.cancelExecutionId = currentExecutionId;

            } else {
                // Contraer cualquier elemento previamente expandido y expandir el nuevo
                this.expanded4 = [item]; // Esto asegura que solo un elemento esté expandido a la vez
                expand();
                this.loadingTable4 = true; // Activar loading antes de comenzar la carga de datos
                this.valorFolioGlobalNivel4 = item.folio;
                // console.log('Hola Mundo', item.folio, 'valorAreaGlobalDIR:', this.valorFolioGlobalNivel4);
                // Actualizar el identificador de ejecución actual
                this.cancelExecutionId = currentExecutionId;

                try {
                    // Llamar los métodos de forma secuencial, verificando el identificador antes de ejecutar cada uno
                    await this.checkExecution(currentExecutionId, () => this.getEmpleadosNivel4(item.folio)); // Obtener empleados de directores
                    await this.checkExecution(currentExecutionId, () => this.gerCantDirEmpNivel4());          // Obtener cantidad de empleados
                    await this.checkExecution(currentExecutionId, () => this.getEvaluacion2EmpNivel4());      // Obtener evaluación de empleados
                    await this.checkExecution(currentExecutionId, () => this.getBonoCoordNivel4_Resp());           // Obtener bono para coordinadores
                    await this.checkExecution(currentExecutionId, () => this.metodoParaPuntosEmpleadosNivel4()); // Obtener puntos de empleados




                } catch (error) {
                    console.error("Ocurrió un error al cargar los datos en nivel 4:", error);
                } finally {
                    // Usar $nextTick para asegurar que los datos se hayan pintado
                    this.$nextTick(() => {
                        if (this.cancelExecutionId === currentExecutionId) {
                            this.loadingTable4 = false; // Desactivar loading tras asegurar la renderización
                        }

                    });
                }
            }
        },

        //-------------------------------------------
        //Esto es para la tabla 5
        async toggleExpand5(item, isExpanded, expand) {
            // console.log('Sesta ejecutane el metodo 5 %%% ')
            // Verificar si el elemento está expandido
            // Crear un identificador único para esta expansión
            const currentExecutionId = Symbol("executionId");
            if (isExpanded) {
                // Si el elemento ya está expandido, lo contraemos y lo removemos de la lista `expanded5`
                this.expanded5 = this.expanded5.filter(expItem => expItem !== item);
                // console.log(`Cerraste: ${item.Nombre}`);

                // Establecer el flag para cancelar la ejecución
                this.cancelExecutionId = currentExecutionId;
            } else {
                // Contraer cualquier elemento previamente expandido y expandir el nuevo
                this.expanded5 = [item]; // Esto asegura que solo un elemento esté expandido a la vez
                expand();
                this.loadingTable5 = true;
                this.valorFolioGlobalNivel5 = item.folio;
                // console.log('Hola Mundo', item.folio, 'valorAreaGlobalDIR:', this.valorFolioGlobalNivel5);
                //this.valorAreaGlobalDIR = getAreaValue(item.Id_Area);

                // Actualizar el identificador de ejecución actual
                this.cancelExecutionId = currentExecutionId;

                try {

                    // Llamar los métodos de forma secuencial, verificando el identificador antes de ejecutar cada uno
                    await this.checkExecution(currentExecutionId, () => this.getEmpleadosNivel5(item.folio));
                    await this.checkExecution(currentExecutionId, () => this.gerCantDirEmpNivel5());
                    await this.checkExecution(currentExecutionId, () => this.getEvaluacion2EmpNivel5());
                    await this.checkExecution(currentExecutionId, () => this.getBonoCoordNivel5());
                    await this.checkExecution(currentExecutionId, () => this.metodoParaPuntosEmpleadosNivel5());
                } catch (error) {
                    console.error("Ocurrió un error al cargar los datos:", error);
                } finally {
                    // Usar $nextTick para asegurar que los datos se hayan pintado
                    this.$nextTick(() => {
                        if (this.cancelExecutionId === currentExecutionId) {
                            this.loadingTable5 = false; // Desactivar loading tras asegurar la renderización
                        }
                    });
                }

            }
        },

        async toggleExpand5_Resp(item, isExpanded, expand) {
            // console.log('Sesta ejecutane el metodo 5 %%% ')
            // Verificar si el elemento está expandido
            // Crear un identificador único para esta expansión
            const currentExecutionId = Symbol("executionId");
            if (isExpanded) {
                // Si el elemento ya está expandido, lo contraemos y lo removemos de la lista `expanded5`
                this.expanded5 = this.expanded5.filter(expItem => expItem !== item);
                // console.log(`Cerraste: ${item.Nombre}`);

                // Establecer el flag para cancelar la ejecución
                this.cancelExecutionId = currentExecutionId;
            } else {
                // Contraer cualquier elemento previamente expandido y expandir el nuevo
                this.expanded5 = [item]; // Esto asegura que solo un elemento esté expandido a la vez
                expand();
                this.loadingTable5 = true;
                this.valorFolioGlobalNivel5 = item.folio;
                // console.log('Hola Mundo', item.folio, 'valorAreaGlobalDIR:', this.valorFolioGlobalNivel5);
                //this.valorAreaGlobalDIR = getAreaValue(item.Id_Area);

                // Actualizar el identificador de ejecución actual
                this.cancelExecutionId = currentExecutionId;

                try {

                    // Llamar los métodos de forma secuencial, verificando el identificador antes de ejecutar cada uno
                    await this.checkExecution(currentExecutionId, () => this.getEmpleadosNivel5(item.folio));
                    await this.checkExecution(currentExecutionId, () => this.gerCantDirEmpNivel5());
                    await this.checkExecution(currentExecutionId, () => this.getEvaluacion2EmpNivel5());
                    await this.checkExecution(currentExecutionId, () => this.getBonoCoordNivel5_Resp_Expand());
                    await this.checkExecution(currentExecutionId, () => this.metodoParaPuntosEmpleadosNivel5());
                } catch (error) {
                    console.error("Ocurrió un error al cargar los datos:", error);
                } finally {
                    // Usar $nextTick para asegurar que los datos se hayan pintado
                    this.$nextTick(() => {
                        if (this.cancelExecutionId === currentExecutionId) {
                            this.loadingTable5 = false; // Desactivar loading tras asegurar la renderización
                        }
                    });
                }

            }
        },

        //-------------------------------------------
        //Esto es para la tabla 6
        async toggleExpand6(item, isExpanded, expand) {
            // console.log('Sesta ejecutane el metodo 6 %%% ')
            // Crear un identificador único para esta expansión
            const currentExecutionId = Symbol("executionId");
            // Verificar si el elemento está expandido
            if (isExpanded) {
                // Si el elemento ya está expandido, lo contraemos y lo removemos de la lista `expanded5`
                this.expanded6 = this.expanded6.filter(expItem => expItem !== item);
                // console.log(`Cerraste: ${item.Nombre}`);
                // Establecer el flag para cancelar la ejecución
                this.cancelExecutionId = currentExecutionId;
            } else {
                // Contraer cualquier elemento previamente expandido y expandir el nuevo
                this.expanded6 = [item]; // Esto asegura que solo un elemento esté expandido a la vez
                expand();
                this.loadingTable6 = true;
                this.valorFolioGlobalNivel6 = item.folio;
                // console.log('Hola Mundo', item.folio, 'valorAreaGlobalDIR:', this.valorFolioGlobalNivel6);
                //this.valorAreaGlobalDIR = getAreaValue(item.Id_Area);

                // Actualizar el identificador de ejecución actual
                this.cancelExecutionId = currentExecutionId;

                try {

                    // Llamar los métodos de forma secuencial, verificando el identificador antes de ejecutar cada uno
                    await this.checkExecution(currentExecutionId, () => this.getEmpleadosNivel6(item.folio));
                    await this.checkExecution(currentExecutionId, () => this.gerCantDirEmpNivel6());
                    await this.checkExecution(currentExecutionId, () => this.getEvaluacion2EmpNivel6());
                    await this.checkExecution(currentExecutionId, () => this.getBonoCoordNivel6());
                    await this.checkExecution(currentExecutionId, () => this.metodoParaPuntosEmpleadosNivel6());
                } catch (error) {
                    console.error("Ocurrió un error al cargar los datos:", error);
                } finally {
                    // Usar $nextTick para asegurar que los datos se hayan pintado
                    this.$nextTick(() => {
                        if (this.cancelExecutionId === currentExecutionId) {
                            this.loadingTable6 = false; // Desactivar loading tras asegurar la renderización
                        }
                    });
                }
            }
        },

        getButtonColorClass2(CantColab) {
            return CantColab > 0 ? 'green--text' : 'red--text';
        },
        async getQuery12() {
            // console.log('VALOR DE CAD AREAS EN QUERY 1-2', this.CadAreas);
            // const response = await axios.get(`${this.$store.getters.getHostNode}/api/query1-2/(1,2,6,7,9,10,12,11)`, {
            if(this.numEmpleado === '9363' || this.numEmpleado === '8631'){
                this.CadAreas = [1,2,6,7,9,10,12,11];
                
            }
            try {
                // Realizar la solicitud para obtener los datos
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/query1-2/(${this.CadAreas})`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                // Guardar los valores como estaban originalmente
                this.sNombre = response.data.data.map(item => item.Nombre);
                this.Id_Resp = response.data.data.map(item => item.Resp);
                this.Id_Area = response.data.data.map(item => parseFloat(item.Id_Area));
                this.Area = response.data.data.map(item => item.Area);

                // console.log('Valor de sNombre:', this.sNombre);
                // console.log('Valor de Id_Resp 1:', this.Id_Resp);
                // console.log('Valor de Id_Area:', this.Id_Area);
                // console.log('Valor de Area:', this.Area);

                // Agrupar los resultados por nombre
                const dataGroupedByName = response.data.data.reduce((acc, item) => {
                    if (!acc[item.Nombre]) {
                        acc[item.Nombre] = {
                            Nombre: item.Nombre,
                            Resp: item.Resp,
                            Id_Area: [parseFloat(item.Id_Area)],
                            Area: [item.Area], // Guardar las áreas como un array para concatenarlas después
                            PuntosObjetivos: '',
                            PuntosLogro: '',
                            PuntosMejora: '',
                            PuntosValores: '',
                            Extras: '',
                            Total: '',
                            dBonoDIR: 0 // Para asegurar que se incluyen todas las propiedades necesarias
                        };
                    } else {
                        acc[item.Nombre].Id_Area.push(parseFloat(item.Id_Area)); // Agregar los Id_Area adicionales
                        acc[item.Nombre].Area.push(item.Area); // Agregar el área adicional al array de áreas
                    }
                    return acc;
                }, {});

                // Transformar el objeto a un array y concatenar las áreas con una diagonal
                this.rows = Object.values(dataGroupedByName).map(item => ({
                    ...item,
                    Id_Area: item.Id_Area, // Mantener el array de Id_Area
                    Area: item.Area.join(' / ') // Concatenar todas las áreas con una diagonal
                }));

                // console.log('Datos agrupados por nombre:', this.rows);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },




        async getQuery2() {
            // console.log('Entro al metodo getQuery2 $$$$$$$$$$')
            //console.log('Valor que llega', Id_Area_Folio);
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/query2/${this.Id_Area}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                // Inicializa los objetos para almacenar los datos por `Id_Area`
                if (!this.resultadosPorArea) {
                    this.resultadosPorArea = {};
                }

                response.data.data.forEach(item => {
                    const idArea = item.Id_Area;

                    // Si el `Id_Area` no existe aún en `resultadosPorArea`, inicializa el objeto
                    if (!this.resultadosPorArea[idArea]) {
                        this.resultadosPorArea[idArea] = {
                            TipoCalculo: [],
                            Ponderacion: [],
                            Id_Indicador: [],
                            mes1: [],
                            mes2: [],
                            mes3: [],
                            LE: [],
                            VO: []
                        };
                    }

                    this.resultadosPorArea[idArea].Id_Indicador.push(item.Id_Indicador);
                    this.resultadosPorArea[idArea].Ponderacion.push(parseFloat(item.Ponderacion) || 0);
                    this.resultadosPorArea[idArea].TipoCalculo.push(item.TipoCalculo || "");
                });

                // console.log('Valores almacenados en getQuery2:', this.resultadosPorArea);
            } catch (error) {
                console.error('Error fetching data:', error);
            }

            // console.log('Salio del metodo getQuery2 $$$$$$$$$$')
        },

        async getQuery2_Resp() {
            // console.log('Entro al metodo getQuery2 $$$$$$$$$$')
            //console.log('Valor que llega', this.Id_Resp, 'Trimestre:', this.trimestreFinal);
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/EmpleadosValorMesBonos/${this.Id_Resp}/${this.anioTabla}/${this.trimestreFinal}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                // Inicializa los objetos para almacenar los datos por `Id_Area`
                if (!this.resultadosPorArea) {
                    this.resultadosPorArea = {};
                }

                response.data.data.forEach(item => {
                    const idArea = item.Id_Area;

                    // Si el `Id_Area` no existe aún en `resultadosPorArea`, inicializa el objeto
                    if (!this.resultadosPorArea[idArea]) {
                        this.resultadosPorArea[idArea] = {
                            TipoCalculo: [],
                            Ponderacion: [],
                            Id_Indicador: [],
                            mes1: [],
                            mes2: [],
                            mes3: [],
                            LE: [],
                            VO: []
                        };
                    }

                    this.resultadosPorArea[idArea].Id_Indicador.push(item.Id_Indicador);
                    this.resultadosPorArea[idArea].Ponderacion.push(parseFloat(item.Ponderacion) || 0);
                    this.resultadosPorArea[idArea].TipoCalculo.push(item.TipoCalculo || "");
                });

                 //console.log('Valores almacenados en getQuery2_Resp:', this.resultadosPorArea);
            } catch (error) {
                console.error('Error fetching data:', error);
            }

            // console.log('Salio del metodo getQuery2 $$$$$$$$$$')
        },

        //Este es el mismo pero lo necesito para las tablas de los trimestres:
        async separarIdAreaDirGlobal(signal) {
            if (signal.aborted) throw new DOMException('Abortado', 'AbortError');

            if (Array.isArray(this.Id_Area_DirGlobal) && this.Id_Area_DirGlobal.length >= 2) {
                // Asignar los dos valores en variables separadas
                this.primerIdArea = this.Id_Area_DirGlobal[0];
                this.segundoIdArea = this.Id_Area_DirGlobal[1];

                // Mostrar en consola los valores asignados
                // console.log('Primer Id_Area:', this.primerIdArea);
                // console.log('Segundo Id_Area:', this.segundoIdArea);
            } else {
                console.error('El array Id_Area_DirGlobal no tiene al menos dos elementos o no es un array.');
                this.primerIdArea = this.Id_Area_DirGlobal[0];
                // console.log('Primer Id_Area:', this.primerIdArea);
            }
        },


        async getQuery2TrimTablas(signal) {
            // console.log('Valor de primerIdArea:', this.primerIdArea, 'Valor de segundoIdArea:', this.segundoIdArea);
            if (signal.aborted) throw new DOMException('Abortado', 'AbortError');

            try {
                // Validar si 'primerIdArea' tiene un valor válido (incluyendo 0) y ejecutar la solicitud
                if (this.primerIdArea !== null && this.primerIdArea !== undefined) {
                    // console.log(`Consultando datos para primerIdArea: ${this.primerIdArea}`);

                    const response1 = await axios.get(`${this.$store.getters.getHostNode}/api/query2/${this.primerIdArea}`, {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    });

                    // Almacenar ID
                    this.Id_Indicador1 = response1.data.data.map(item => item.Id_Indicador);
                    // console.log('Id_Indicador1:', this.Id_Indicador1);

                    // Almacenar Ponderación
                    this.Ponderacion1 = response1.data.data.map(item => parseFloat(item.Ponderacion) || 0);
                    // console.log('Ponderacion1:', this.Ponderacion1);

                    // Sumar los valores de Ponderación
                    this.ponderacionSuma1 = this.Ponderacion1.reduce((acc, val) => acc + val, 0);
                    // console.log('ponderacionSuma1:', this.ponderacionSuma1);

                    // Almacenar TipoCalculos
                    this.TipoCalculos1 = response1.data.data.map(item => item.TipoCalculo);
                    // console.log('TipoCalculos1:', this.TipoCalculos1);
                } else {
                    console.warn('No se proporcionó valor válido para primerIdArea.');
                }

                // Validar si 'segundoIdArea' tiene un valor válido (incluyendo 0) y ejecutar la solicitud
                if (this.segundoIdArea !== null && this.segundoIdArea !== undefined) {
                    // console.log(`Consultando datos para segundoIdArea: ${this.segundoIdArea}`);

                    const response2 = await axios.get(`${this.$store.getters.getHostNode}/api/query2/${this.segundoIdArea}`, {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    });

                    // Almacenar ID
                    this.Id_Indicador2 = response2.data.data.map(item => item.Id_Indicador);
                    // console.log('Id_Indicador2:', this.Id_Indicador2);

                    // Almacenar Ponderación
                    this.Ponderacion2 = response2.data.data.map(item => parseFloat(item.Ponderacion) || 0);
                    // console.log('Ponderacion2:', this.Ponderacion2);

                    // Sumar los valores de Ponderación
                    this.ponderacionSuma2 = this.Ponderacion2.reduce((acc, val) => acc + val, 0);
                    // console.log('ponderacionSuma2:', this.ponderacionSuma2);

                    // Almacenar TipoCalculos
                    this.TipoCalculos2 = response2.data.data.map(item => item.TipoCalculo);
                    // console.log('TipoCalculos2:', this.TipoCalculos2);
                } else {
                    console.warn('No se proporcionó valor válido para segundoIdArea.');
                }

            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        },


        async getQuery22() {
            //Query 2-2
            // console.log('Entro al metodo getQuery22 %%%%%%')

            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/query2-2/${this.Id_Area}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                this.CantObj = response.data.data.map(item => item.cantidad);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
            // console.log('Salio del metodo getQuery22 %%%%%%')
        },

        async getQuery22_Resp() {
            //Query 2-2
            // console.log('Entro al metodo getQuery22 %%%%%%')

            try {
               const response = await axios.get(`${this.$store.getters.getHostNode}/api/noColaboradoresDir/${this.anioTabla}/${this.trimestreFinal}/${this.Id_Resp}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                this.CantObj = response.data.data.map(item => item.CantidColab);
                console.log('CantObj', this.CantObj);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
            // console.log('Salio del metodo getQuery22 %%%%%%')
        },



        async getQuery3() {
            // console.log('Entro al metodo getQuery3 &&&&&&');
            try {
                if (!this.resultadosPorArea) {
                    this.resultadosPorArea = {};
                }

                // Prepara todas las solicitudes en paralelo
                const solicitudes = Object.entries(this.resultadosPorArea).map(async ([idArea, data]) => {
                    const indicadoresArray = data.Id_Indicador || [];
                    if (indicadoresArray.length === 0) {
                        this.inicializarValores(idArea, indicadoresArray);
                        return;
                    }

                    const indicadoresString = indicadoresArray.join(',');
                    try {
                        const response = await axios.get(
                            `${this.$store.getters.getHostNode}/api/verObjEspe/${indicadoresString}`,
                            { headers: { Authorization: localStorage.getItem('token') } }
                        );

                        const sortedData = (response.data?.data || []).sort(
                            (a, b) => indicadoresArray.indexOf(a.Id_Indicador) - indicadoresArray.indexOf(b.Id_Indicador)
                        );

                        data.LE = sortedData.map(item => parseFloat(item.LE) || 0);
                        data.VO = sortedData.map(item => parseFloat(item.VO) || 0);
                    } catch (error) {
                        console.error(`Error en solicitud para Id_Area ${idArea}:`, error);
                        this.inicializarValores(idArea, indicadoresArray);
                    }
                });

                // Espera a que todas las solicitudes terminen
                await Promise.all(solicitudes);
            } catch (error) {
                console.error('Error general en getQuery3:', error);
                this.inicializarTodosLosValores();
            }
            // console.log('Salio del metodo getQuery3 &&&&&&');
        },

        // Función para inicializar los valores de LE y VO a 0
        inicializarValores(idArea, indicadoresArray) {
            this.resultadosPorArea[idArea].LE = indicadoresArray.map(() => 0);
            this.resultadosPorArea[idArea].VO = indicadoresArray.map(() => 0);
        },

        // Inicializa todos los valores en caso de error general
        inicializarTodosLosValores() {
            for (const idArea in this.resultadosPorArea) {
                const indicadoresArray = this.resultadosPorArea[idArea].Id_Indicador || [];
                this.inicializarValores(idArea, indicadoresArray);
            }
        },


        //Este es el mismo pero es para la tabla de mestres trimestres en los directores

        async getQuery3MesTrimDir(signal) {
            if (signal.aborted) throw new DOMException('Abortado', 'AbortError');
            try {
                // Verificar si `Id_Indicador1` tiene datos y ejecutar la solicitud
                if (this.Id_Indicador1 && this.Id_Indicador1.length > 0) {
                    const indicadoresString1 = this.Id_Indicador1.join(',');
                    // console.log(`Consultando indicadores para Id_Indicador1: ${indicadoresString1}`);

                    const response1 = await axios.get(`${this.$store.getters.getHostNode}/api/verObjEspe/${indicadoresString1}`, {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    });

                    // Procesar los datos y almacenarlos
                    this.LE1 = response1.data.data.map(item => item.LE);
                    this.VO1 = response1.data.data.map(item => item.VO);
                    this.ObjetivoEspecifico1 = response1.data.data.map(item => item.ObjetivoEspecifico);
                    this.Unidad1 = response1.data.data.map(item => item.Unidad);

                    // console.log('LE 1:', this.LE1);
                    // console.log('VO 1:', this.VO1);
                    // console.log('Objetivo Específico 1:', this.ObjetivoEspecifico1);
                }

                // Verificar si `Id_Indicador2` tiene datos y ejecutar la solicitud
                if (this.Id_Indicador2 && this.Id_Indicador2.length > 0) {
                    const indicadoresString2 = this.Id_Indicador2.join(',');
                    // console.log(`Consultando indicadores para Id_Indicador2: ${indicadoresString2}`);

                    const response2 = await axios.get(`${this.$store.getters.getHostNode}/api/verObjEspe/${indicadoresString2}`, {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    });

                    // Procesar los datos y almacenarlos
                    this.LE2 = response2.data.data.map(item => item.LE);
                    this.VO2 = response2.data.data.map(item => item.VO);
                    this.ObjetivoEspecifico2 = response2.data.data.map(item => item.ObjetivoEspecifico);
                    this.Unidad2 = response2.data.data.map(item => item.Unidad);

                    // console.log('LE 2:', this.LE2);
                    // console.log('VO 2:', this.VO2);
                    // console.log('Objetivo Específico 2:', this.ObjetivoEspecifico2);
                }

                // Si no hay datos en ambos indicadores, mostrar advertencia
                if ((!this.Id_Indicador1 || this.Id_Indicador1.length === 0) &&
                    (!this.Id_Indicador2 || this.Id_Indicador2.length === 0)) {
                    console.warn('No se proporcionaron valores para Id_Indicador1 o Id_Indicador2.');
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },


        // async getQuery4() {
        //     try {
        //         if (!this.resultadosPorArea) {
        //             this.resultadosPorArea = {};
        //         }

        //         // Preparar las solicitudes en paralelo para todas las áreas
        //         const solicitudes = Object.entries(this.resultadosPorArea).map(async ([idArea, data]) => {
        //             const indicadoresArray = data.Id_Indicador || [];
        //             if (indicadoresArray.length === 0) {
        //                 this.inicializarValores2(idArea, indicadoresArray);
        //                 return;
        //             }

        //             const indicadoresString = indicadoresArray.join(',');

        //             try {
        //                 const response = await axios.get(
        //                     `${this.$store.getters.getHostNode}/api/ver-indValSeguimient/${indicadoresString}/${this.PrimerMesNumValorBase}/${this.anioTabla}/${this.SegundoMesNumValorBase}/${this.TercerMesNumValorBase}`,
        //                     { headers: { Authorization: localStorage.getItem("token") } }
        //                 );

        //                 if (response?.data?.data) {
        //                     const resultados = response.data.data;
        //                     // console.log('Valores de los meses:', resultados);
        //                     this.dividirYAsignarResultados(idArea, resultados, indicadoresArray);
        //                 } else {
        //                     this.inicializarValores2(idArea, indicadoresArray);
        //                 }
        //             } catch (error) {
        //                 console.error(`Error en solicitud para Id_Area ${idArea}:`, error);
        //                 this.inicializarValores2(idArea, indicadoresArray);
        //             }
        //         });

        //         // Esperar a que todas las solicitudes terminen
        //         await Promise.all(solicitudes);
        //     } catch (error) {
        //         console.error('Error general en getQuery4:', error);
        //         this.inicializarTodosLosValores2();
        //     }
        // },

        // // Función para inicializar los valores a 0
        // inicializarValores2(idArea, indicadoresArray) {
        //     this.resultadosPorArea[idArea].mes1 = indicadoresArray.map(() => 0);
        //     this.resultadosPorArea[idArea].mes2 = indicadoresArray.map(() => 0);
        //     this.resultadosPorArea[idArea].mes3 = indicadoresArray.map(() => 0);
        // },

        // // Función para inicializar todos los valores en caso de error general
        // inicializarTodosLosValores2() {
        //     for (const idArea in this.resultadosPorArea) {
        //         const indicadoresArray = this.resultadosPorArea[idArea].Id_Indicador || [];
        //         this.inicializarValores2(idArea, indicadoresArray);
        //     }
        // },

        // // Función para dividir los resultados en partes iguales y asignarlos
        // dividirYAsignarResultados(idArea, resultados, indicadoresArray) {
        //     const partSize = Math.floor(resultados.length / 3);
        //     this.resultadosPorArea[idArea].mes1 = resultados.slice(0, partSize).map(item => parseFloat(item.valorReal) || 0);
        //     this.resultadosPorArea[idArea].mes2 = resultados.slice(partSize, 2 * partSize).map(item => parseFloat(item.valorReal) || 0);
        //     this.resultadosPorArea[idArea].mes3 = resultados.slice(2 * partSize).map(item => parseFloat(item.valorReal) || 0);

        //     // Verificar y asignar valores 0 si algún mes está vacío o contiene solo ceros
        //     if (!this.resultadosPorArea[idArea].mes1.length || this.resultadosPorArea[idArea].mes1.every(val => val === 0)) {
        //         this.resultadosPorArea[idArea].mes1 = indicadoresArray.map(() => 0);
        //     }
        //     if (!this.resultadosPorArea[idArea].mes2.length || this.resultadosPorArea[idArea].mes2.every(val => val === 0)) {
        //         this.resultadosPorArea[idArea].mes2 = indicadoresArray.map(() => 0);
        //     }
        //     if (!this.resultadosPorArea[idArea].mes3.length || this.resultadosPorArea[idArea].mes3.every(val => val === 0)) {
        //         this.resultadosPorArea[idArea].mes3 = indicadoresArray.map(() => 0);
        //     }

        //     // console.log('Valor final de mes 1:', this.resultadosPorArea[idArea].mes1);
        //     // console.log('Valor final de mes 2:', this.resultadosPorArea[idArea].mes2);
        //     // console.log('Valor final de mes 3:', this.resultadosPorArea[idArea].mes3);
        // },

        async getQuery4() {
    try {
        if (!this.resultadosPorArea) {
            this.resultadosPorArea = {};
        }

        // Preparar las solicitudes en paralelo para todas las áreas
        const solicitudes = Object.entries(this.resultadosPorArea).map(async ([idArea, data]) => {
            const indicadoresArray = data.Id_Indicador || [];
            if (indicadoresArray.length === 0) {
                this.inicializarValores2(idArea, indicadoresArray);
                return;
            }

            const indicadoresString = indicadoresArray.join(',');

            try {
                const response = await axios.get(
                    `${this.$store.getters.getHostNode}/api/ver-indValSeguimient/${indicadoresString}/${this.PrimerMesNumValorBase}/${this.anioTabla}/${this.SegundoMesNumValorBase}/${this.TercerMesNumValorBase}`,
                    { headers: { Authorization: localStorage.getItem("token") } }
                );

                if (response?.data?.data) {
                    const resultados = response.data.data;

                    // Ordenar resultados por el campo 'Objetivo'
                    const resultadosOrdenados = resultados.sort((a, b) => a.Objetivo - b.Objetivo);

                    // Agrupar los datos por 'Objetivo'
                    const groupedByObjective = indicadoresArray.map((id) => {
                        const valuesForObjective = resultadosOrdenados
                            .filter((item) => item.Objetivo === id)
                            .map((item) => parseFloat(item.valorReal) || 0);

                        // Rellenar con ceros si faltan valores
                        while (valuesForObjective.length < 3) {
                            valuesForObjective.push(0);
                        }

                        return valuesForObjective;
                    });

                    // Dividir en meses según las posiciones de los valores
                    this.resultadosPorArea[idArea].mes1 = groupedByObjective.map((group) => group[0]);
                    this.resultadosPorArea[idArea].mes2 = groupedByObjective.map((group) => group[1]);
                    this.resultadosPorArea[idArea].mes3 = groupedByObjective.map((group) => group[2]);

                } else {
                    this.inicializarValores2(idArea, indicadoresArray);
                }
            } catch (error) {
                console.error(`Error en solicitud para Id_Area ${idArea}:`, error);
                this.inicializarValores2(idArea, indicadoresArray);
            }
        });

        // Esperar a que todas las solicitudes terminen
        await Promise.all(solicitudes);
    } catch (error) {
        console.error('Error general en getQuery4:', error);
        this.inicializarTodosLosValores2();
    }
},

// Función para inicializar los valores a 0
inicializarValores2(idArea, indicadoresArray) {
    this.resultadosPorArea[idArea].mes1 = indicadoresArray.map(() => 0);
    this.resultadosPorArea[idArea].mes2 = indicadoresArray.map(() => 0);
    this.resultadosPorArea[idArea].mes3 = indicadoresArray.map(() => 0);
},

// Función para inicializar todos los valores en caso de error general
inicializarTodosLosValores2() {
    for (const idArea in this.resultadosPorArea) {
        const indicadoresArray = this.resultadosPorArea[idArea].Id_Indicador || [];
        this.inicializarValores2(idArea, indicadoresArray);
    }
},



        //este es para la tabla de meses en direcotres

        // async getQuery4MesTrimTabla(signal) {
        //     if (signal.aborted) throw new DOMException('Abortado', 'AbortError');
        //     try {
        //         const fetchData = async (indicadores) => {
        //             if (!indicadores || indicadores.length === 0) return [[], [], []];

        //             const indicadoresString = indicadores.join(',');
        //             //console.log(`Consultando valores para: ${indicadoresString}`);

        //             const { data } = await axios.get(
        //                 `${this.$store.getters.getHostNode}/api/ver-indValSeguimient/${indicadoresString}/${this.mes1Tabla}/${this.anioTabla}/${this.mes2Tabla}/${this.mes3Tabla}`,
        //                 {
        //                     headers: { Authorization: localStorage.getItem("token") },
        //                 }
        //             );

        //             return data?.data || [];
        //         };

        //         const asignarResultados = (resultados, indicadores) => {
        //             const partSize = Math.max(Math.floor(resultados.length / 3), 1);

        //             const valores = [
        //                 resultados.slice(0, partSize).map(item => item.valorReal || 0),
        //                 resultados.slice(partSize, 2 * partSize).map(item => item.valorReal || 0),
        //                 resultados.slice(2 * partSize).map(item => item.valorReal || 0),
        //             ];

        //             console.log('valores ', valores);

        //             // Si algún mes está vacío, inicializar todos los valores a 0
        //             return valores.some(arr => arr.length === 0) ?
        //                 [indicadores.map(() => 0), indicadores.map(() => 0), indicadores.map(() => 0)] : valores;
        //         };

        //         const [resultados1, resultados2] = await Promise.all([
        //             fetchData(this.Id_Indicador1),
        //             fetchData(this.Id_Indicador2),
        //         ]);

        //         [this.mes1t1, this.mes2t1, this.mes3t1] = asignarResultados(resultados1, this.Id_Indicador1);
        //         [this.mes1t2, this.mes2t2, this.mes3t2] = asignarResultados(resultados2, this.Id_Indicador2);

        //         if ((!this.Id_Indicador1?.length) && (!this.Id_Indicador2?.length)) {
        //             console.warn('No se proporcionaron valores para Id_Indicador1 ni Id_Indicador2.');
        //         }
        //     } catch (error) {
        //         console.error('Error fetching data:', error);
        //     }
        // },
     

        //Este ya funciono 
//         async getQuery4MesTrimTabla(signal) {
//     if (signal.aborted) throw new DOMException('Abortado', 'AbortError');
//     try {
//         const fetchData = async (indicadores) => {
//             if (!indicadores || indicadores.length === 0) return [];

//             const indicadoresString = indicadores.join(',');
//             //console.log(`Consultando valores para: ${indicadoresString}`);

//             const { data } = await axios.get(
//                 `${this.$store.getters.getHostNode}/api/ver-indValSeguimient/${indicadoresString}/${this.mes1Tabla}/${this.anioTabla}/${this.mes2Tabla}/${this.mes3Tabla}`,
//                 {
//                     headers: { Authorization: localStorage.getItem("token") },
//                 }
//             );

//             return data?.data || [];
//         };

//         const asignarResultados = (resultados, indicadores) => {
//             const valores = [[], [], []];

//             indicadores.forEach((indicador, index) => {
//                 const valuesForObjective = resultados
//                     .filter(item => item.Objetivo === indicador)
//                     .map(item => item.valorReal);

//                 // Si faltan datos, rellenar con ceros
//                 while (valuesForObjective.length < 3) {
//                     valuesForObjective.push(0);
//                 }

//                 valores[0][index] = valuesForObjective[0] || 0;
//                 valores[1][index] = valuesForObjective[1] || 0;
//                 valores[2][index] = valuesForObjective[2] || 0;
//             });

//             return valores;
//         };

//         const [resultados1, resultados2] = await Promise.all([
//             fetchData(this.Id_Indicador1),
//             fetchData(this.Id_Indicador2),
//         ]);

//         [this.mes1t1, this.mes2t1, this.mes3t1] = asignarResultados(resultados1, this.Id_Indicador1);
//         [this.mes1t2, this.mes2t2, this.mes3t2] = asignarResultados(resultados2, this.Id_Indicador2);

//         if ((!this.Id_Indicador1?.length) && (!this.Id_Indicador2?.length)) {
//             console.warn('No se proporcionaron valores para Id_Indicador1 ni Id_Indicador2.');
//         }
//     } catch (error) {
//         console.error('Error fetching data:', error);
//     }
// },

async getQuery4MesTrimTabla(signal) {
    if (signal.aborted) throw new DOMException('Abortado', 'AbortError');
    try {
        const fetchData = async (indicadores) => {
            if (!indicadores || indicadores.length === 0) {
                console.log('No se proporcionaron indicadores.');
                return [];
            }

            const indicadoresString = indicadores.join(',');
            console.log(`Consultando valores para: ${indicadoresString}`);

            const { data } = await axios.get(
                `${this.$store.getters.getHostNode}/api/ver-indValSeguimient/${indicadoresString}/${this.mes1Tabla}/${this.anioTabla}/${this.mes2Tabla}/${this.mes3Tabla}`,
                {
                    headers: { Authorization: localStorage.getItem("token") },
                }
            );

            console.log(`Datos obtenidos para indicadores ${indicadoresString}:`, data?.data || []);
            return data?.data || [];
        };

        const asignarResultados = (resultados, indicadores) => {
            console.log('Asignando resultados. Indicadores:', indicadores, 'Resultados:', resultados);

            const valores = [[], [], []];

            indicadores.forEach((indicador, index) => {
                const valuesForObjective = resultados
                    .filter(item => item.Objetivo === indicador)
                    .map(item => item.valorReal);

                // Si faltan datos, rellenar con ceros
                while (valuesForObjective.length < 3) {
                    valuesForObjective.push(0);
                }

                console.log(`Valores para indicador ${indicador}:`, valuesForObjective);

                valores[0][index] = valuesForObjective[0] || 0;
                valores[1][index] = valuesForObjective[1] || 0;
                valores[2][index] = valuesForObjective[2] || 0;
            });

            console.log('Valores asignados:', valores);
            return valores;
        };

        console.log('Iniciando consultas de datos...');
        const [resultados1, resultados2] = await Promise.all([
            fetchData(this.Id_Indicador1),
            fetchData(this.Id_Indicador2),
        ]);

        console.log('Resultados obtenidos para indicadores 1:', resultados1);
        console.log('Resultados obtenidos para indicadores 2:', resultados2);

        [this.mes1t1, this.mes2t1, this.mes3t1] = asignarResultados(resultados1, this.Id_Indicador1);
        [this.mes1t2, this.mes2t2, this.mes3t2] = asignarResultados(resultados2, this.Id_Indicador2);

        console.log('Valores finales asignados:');
        console.log('mes1t1, mes2t1, mes3t1:', this.mes1t1, this.mes2t1, this.mes3t1);
        console.log('mes1t2, mes2t2, mes3t2:', this.mes1t2, this.mes2t2, this.mes3t2);

        if ((!this.Id_Indicador1?.length) && (!this.Id_Indicador2?.length)) {
            console.warn('No se proporcionaron valores para Id_Indicador1 ni Id_Indicador2.');
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
},






        Regresar() {
            // this.banderaReporte = false;
            // this.$emit('valorRegresar', this.banderaReporte);
           // this.banderaReporte = false;
            this.$emit('valorRegresar', false);
        },

        abrirAceptar(){

            this.dialogAceptarB = true;
        },

        aceptar() {
            this.enviarCorreo = true;
            if (this.enviarCorreo == true) {
                this.valorCorreo = 1;
            }
            try {
                axios.get(`${this.$store.getters.getHostNode}/api/enviar-correo/${this.valorCorreo}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                //console.log('Se envio el correo de aceptar');
                this.dialogAceptarB = false;
            } catch (error) {
                console.error("Error al enviar el correo:", error);
            }
        },

        cancelar() {
            this.enviarCorreo = false;
            if (this.enviarCorreo == false) {
                this.valorCorreo = 0;
            }
            try {
                axios.get(`${this.$store.getters.getHostNode}/api/enviar-correo/${this.valorCorreo}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                //  console.log('Se envio el correo de cancelar');
                this.dialogRechazar = false;
                
            } catch (error) {
                console.error("Error al enviar el correo:", error);
            }
        },


        //Este es para sacar el bono en los directores
        async getPuntos() {
            // console.log('Entro al metodo getPuntos&&&&&&&&&&&&&66')
            try {
                // Verifica que resultadosPorArea esté definido
                if (!this.resultadosPorArea) {
                    console.error("resultadosPorArea no está definido.");
                    return;
                }

                // Crear un objeto para almacenar la suma de los valores de dBonoDIR por nombre
                const dBonoDIRPorNombre = {};

                // Recorre cada área en resultadosPorArea
                for (const idAreaActual in this.resultadosPorArea) {
                    // console.log(`\nProcesando Id_Area: ${idAreaActual}`);

                    const { TipoCalculo, mes1, mes2, mes3, LE, VO, Ponderacion } = this.resultadosPorArea[idAreaActual];

                    if (!LE || !VO) {
                        console.error(`LE o VO no están definidos para Id_Area ${idAreaActual}. Asegúrate de que getQuery3 se haya ejecutado correctamente.`);
                        continue;
                    }

                    // Aplica la fórmula para calcular dReal
                    let real = mes1.map((val, index) => {
                        let tipoCalculo = TipoCalculo[index];
                        let dReal = 0;

                        if (tipoCalculo === "" || tipoCalculo === "Promedio" || tipoCalculo === "NULL") {
                            dReal = (mes1[index] + mes2[index] + mes3[index]) / 3;
                        } else {
                            if (mes3[index] != 0) {
                                dReal = mes3[index];
                            } else if (mes2[index] != 0) {
                                dReal = mes2[index];
                            } else if (mes1[index] != 0) {
                                dReal = mes1[index];
                            }
                        }
                        return dReal;
                    });

                    // Calcula el desempeño
                    let desempenio = LE.map((le, index) => {
                        let vo = VO[index];
                        let result = ((le - real[index]) / (le - vo)) * 100;
                        return Math.max(0, Math.min(result, 100));
                    });

                    // Calcula los puntos
                    let puntos = desempenio.map((d, index) => (d * Ponderacion[index]) / 100);

                    // Suma de los puntos para el área actual
                    const dSumaPuntosArea = puntos.reduce((acc, p) => acc + p, 0);
                    this.dSumaPuntosAreaGlobal = dSumaPuntosArea;
                    // console.log(`Total de puntos para Id_Area ${idAreaActual}: ${dSumaPuntosArea}`);
                    const puntosCia = this.getPuntosPorTrimestre(0, this.trimestreFinal);
                    // console.log('Puntos obtenidos #############:', puntosCia, 'mess', this.trimestreFinal);

                    // Calcula el valor de dBonoDIR para esta área
                    let dBonoDIR = (((dSumaPuntosArea * this.poAreaDir) + (puntosCia * this.poCIADir)) * 0.2);
                    dBonoDIR = parseFloat(dBonoDIR.toFixed(1)); // Ajusta el valor a 1 decimal
                    // console.log(`Valor calculado de dBonoDIR para Id_Area ${idAreaActual}: ${dBonoDIR}`);

                    // Encuentra el nombre que corresponde a esta Id_Area
                    const rowToUpdate = this.rows.find(row => row.Id_Area.includes(parseFloat(idAreaActual)));

                    if (rowToUpdate) {
                        // Sumar el valor de dBonoDIR en el objeto dBonoDIRPorNombre para el nombre correspondiente
                        if (!dBonoDIRPorNombre[rowToUpdate.Nombre]) {
                            dBonoDIRPorNombre[rowToUpdate.Nombre] = 0;
                        }
                        dBonoDIRPorNombre[rowToUpdate.Nombre] += dBonoDIR;

                        // Usa Vue.set para asegurar la reactividad y ajusta a un decimal
                        Vue.set(rowToUpdate, 'dBonoDIR', parseFloat(dBonoDIRPorNombre[rowToUpdate.Nombre].toFixed(1)));
                    }
                }

                // Verificar que `rows` tiene los valores actualizados
                // console.log('rows actualizado:', this.rows);
            } catch (error) {
                console.error('Error en getPuntos:', error);
            }
            // console.log('Salio del metodo getPuntos&&&&&&&&&&&&&66')
        },

        //Este es para sacar el bono en los coordinadores son los que estan abajo de los Directores



        async getPuntosCoordinadores() {
            try {
                if (!this.datosPorFolio || Object.keys(this.datosPorFolio).length === 0) {
                    console.warn('No se encontraron datos por folio.');
                    return;
                }

                const calcularPuntosFolio = (folio, data) => {
                    const {
                        TipoCalculoMes,
                        ValObjPrimerMes,
                        ValObjSegundoMes,
                        ValObjTercero,
                        LEMes,
                        VOMes,
                        PonderacionMes,
                    } = data;

                    // Calcular los valores reales y puntos en una sola iteración
                    let totalPuntos = 0;

                    const real = ValObjPrimerMes.map((_, index) => {
                        const tipoCalculo = TipoCalculoMes[index];
                        const promedio = (ValObjPrimerMes[index] + ValObjSegundoMes[index] + ValObjTercero[index]) / 3;
                        return tipoCalculo === "" || tipoCalculo === "Promedio" || tipoCalculo === "NULL"
                            ? promedio
                            : ValObjTercero[index] || ValObjSegundoMes[index] || ValObjPrimerMes[index];
                    });

                    real.forEach((r, index) => {
                        const le = LEMes[index];
                        const vo = VOMes[index];
                        const calcDesempenio = Math.max(0, Math.min(((le - r) / (le - vo)) * 100, 100));
                        const puntos = (calcDesempenio * PonderacionMes[index]) / 100;
                        totalPuntos += puntos;
                    });

                    // Guardar los resultados
                    this.datosPorFolio[folio].dSumaPuntosCord = parseFloat(totalPuntos.toFixed(2));

                    const puntosCia = this.getPuntosPorTrimestre(0, this.trimestreFinal);
                    const puntosArea = this.getPuntosPorTrimestre(this.valorAreaGlobalDIR, this.trimestreFinal);
                    //   console.log('valor de CIA:', puntosCia, 'Valor de Area dir:', puntosArea);

                    const dBonoDIR = parseFloat(
                        (
                            (totalPuntos * this.poDepartamentoCoord) +
                            (
                                (puntosArea * this.poAreaCoord) +
                                (puntosCia * this.poCIACoord)
                            )
                        ) * 0.2
                    ).toFixed(1);


                    // Asignar el bono al empleado correspondiente
                    const employee = this.employees.find(emp => String(emp.folio) === String(folio));
                    if (employee) {
                        employee.dBonoDIR = dBonoDIR;
                    } else {
                        console.warn(`No se encontró empleado con folio ${folio}.`);
                    }
                };

                // Ejecutar los cálculos en paralelo para cada folio
                await Promise.all(
                    Object.entries(this.datosPorFolio).map(([folio, data]) =>
                        calcularPuntosFolio(folio, data)
                    )
                );

                //console.log('Cálculo completado para todos los folios:', this.employees);
            } catch (error) {
                console.error('Error en getPuntosCoordinadores:', error);
            }
        },

        async getPuntosCoordinadoresNivel3() {
            try {
                if (!this.datosPorFolio || Object.keys(this.datosPorFolio).length === 0) {
                    console.warn('No se encontraron datos por folio.');
                    return;
                }

                const calcularPuntosFolio = (folio, data) => {
                    const {
                        TipoCalculoMes,
                        ValObjPrimerMes,
                        ValObjSegundoMes,
                        ValObjTercero,
                        LEMes,
                        VOMes,
                        PonderacionMes,
                    } = data;

                    // Calcular los valores reales y puntos en una sola iteración
                    let totalPuntos = 0;

                    const real = ValObjPrimerMes.map((_, index) => {
                        const tipoCalculo = TipoCalculoMes[index];
                        const promedio = (ValObjPrimerMes[index] + ValObjSegundoMes[index] + ValObjTercero[index]) / 3;
                        return tipoCalculo === "" || tipoCalculo === "Promedio" || tipoCalculo === "NULL"
                            ? promedio
                            : ValObjTercero[index] || ValObjSegundoMes[index] || ValObjPrimerMes[index];
                    });

                    real.forEach((r, index) => {
                        const le = LEMes[index];
                        const vo = VOMes[index];
                        const calcDesempenio = Math.max(0, Math.min(((le - r) / (le - vo)) * 100, 100));
                        const puntos = (calcDesempenio * PonderacionMes[index]) / 100;
                        totalPuntos += puntos;
                    });

                    // Guardar los resultados
                    this.datosPorFolio[folio].dSumaPuntosCord = parseFloat(totalPuntos.toFixed(2));

                    const puntosCia = this.getPuntosPorTrimestre(0, this.trimestreFinal);
                    const puntosArea = this.getPuntosPorTrimestre(this.valorAreaGlobalDIR, this.trimestreFinal);
                    const puntosNivel2 = this.getPuntosPorTrimestreFolio3(this.valorFolioGlobalNivel3, this.trimestreFinal);
            
                    //console.log('valor de CIA:', puntosCia, 'Valor de Area dir:', puntosArea, 'getPuntosPorTrimestreFolio3 ', puntosNivel2, 'valores de la persona:', totalPuntos);


                    let dBonoDIR = (
                        ((totalPuntos * this.poIndividualesColab) +
                            (puntosCia * this.poCIAColab) +
                            (puntosArea * this.poAreaColab) +
                            (puntosNivel2 * this.poDepartamentoColab)) * 0.2
                    );

                    dBonoDIR = parseFloat(dBonoDIR.toFixed(1));
                    console.log(`dBonoDIR para folio nivel 3 ${folio}:`, dBonoDIR);


                    // Asignar el bono al empleado correspondiente
                    const employee = this.employeesNivel3.find(emp => String(emp.folio) === String(folio));
                    if (employee) {
                        employee.dBonoDIR = dBonoDIR;
                    } else {
                        console.warn(`No se encontró empleado con folio ${folio}.`);
                    }
                };

                // Ejecutar los cálculos en paralelo para cada folio
                await Promise.all(
                    Object.entries(this.datosPorFolio).map(([folio, data]) =>
                        calcularPuntosFolio(folio, data)
                    )
                );

                // console.log('Cálculo completado para todos los folios:', this.employees);
                // console.log('valores del bono:', this.dBonoDIR)
            } catch (error) {
                console.error('Error en getPuntosCoordinadores:', error);
            }
        },

        async getPuntosCoordinadoresNivel3_Resp() {
            try {
                if (!this.datosPorFolio || Object.keys(this.datosPorFolio).length === 0) {
                    console.warn('No se encontraron datos por folio.');
                    return;
                }

                const calcularPuntosFolio = (folio, data) => {
                    const {
                        TipoCalculoMes,
                        ValObjPrimerMes,
                        ValObjSegundoMes,
                        ValObjTercero,
                        LEMes,
                        VOMes,
                        PonderacionMes,
                    } = data;

                    // Calcular los valores reales y puntos en una sola iteración
                    let totalPuntos = 0;

                    const real = ValObjPrimerMes.map((_, index) => {
                        const tipoCalculo = TipoCalculoMes[index];
                        const promedio = (ValObjPrimerMes[index] + ValObjSegundoMes[index] + ValObjTercero[index]) / 3;
                        return tipoCalculo === "" || tipoCalculo === "Promedio" || tipoCalculo === "NULL"
                            ? promedio
                            : ValObjTercero[index] || ValObjSegundoMes[index] || ValObjPrimerMes[index];
                    });

                    real.forEach((r, index) => {
                        const le = LEMes[index];
                        const vo = VOMes[index];
                        const calcDesempenio = Math.max(0, Math.min(((le - r) / (le - vo)) * 100, 100));
                        const puntos = (calcDesempenio * PonderacionMes[index]) / 100;
                        totalPuntos += puntos;
                    });

                    // Guardar los resultados
                    this.datosPorFolio[folio].dSumaPuntosCord = parseFloat(totalPuntos.toFixed(2));

                    const puntosCia = this.PuntosDIr_Final_ALF;

            
                    const puntosArea = this.PuntosDIr_Final;
                    const puntosNivel2 = this.PuntosColab_Final;
                    
                     console.log('valor de CIA:', puntosCia, 'Valor de Area dir:', puntosArea, 'getPuntosPorTrimestreFolio3 ', puntosNivel2, 'valores de la persona:', totalPuntos);
                     console.log('poIndividualesColab', this.poIndividualesColab, 'poCIAColab', this.poCIAColab, 'poAreaColab', this.poAreaColab, 'poDepartamentoColab', this.poDepartamentoColab)


                    let dBonoDIR = (
                        ((totalPuntos * this.poIndividualesColab) +
                            (puntosCia * this.poCIAColab) +
                            (puntosArea * this.poAreaColab) +
                            (puntosNivel2 * this.poDepartamentoColab)) * 0.2
                    );

                    dBonoDIR = parseFloat(dBonoDIR.toFixed(1));
                    console.log(`dBonoDIR para folio el de ahorita !! ${folio}:`, dBonoDIR);


                    // Asignar el bono al empleado correspondiente
                    const employee = this.employees.find(emp => String(emp.folio) === String(folio));
                    if (employee) {
                        employee.dBonoDIR = dBonoDIR;
                    } else {
                        console.warn(`No se encontró empleado con folio ${folio}.`);
                    }
                };

                // Ejecutar los cálculos en paralelo para cada folio
                await Promise.all(
                    Object.entries(this.datosPorFolio).map(([folio, data]) =>
                        calcularPuntosFolio(folio, data)
                    )
                );

                // console.log('Cálculo completado para todos los folios:', this.employees);
                // console.log('valores del bono:', this.dBonoDIR)
            } catch (error) {
                console.error('Error en getPuntosCoordinadores:', error);
            }
        },

        async getPuntosCoordinadoresNivel3_Resp_Expand() {
            try {
                if (!this.datosPorFolio || Object.keys(this.datosPorFolio).length === 0) {
                    console.warn('No se encontraron datos por folio.');
                    return;
                }

                const calcularPuntosFolio = (folio, data) => {
                    const {
                        TipoCalculoMes,
                        ValObjPrimerMes,
                        ValObjSegundoMes,
                        ValObjTercero,
                        LEMes,
                        VOMes,
                        PonderacionMes,
                    } = data;

                    // Calcular los valores reales y puntos en una sola iteración
                    let totalPuntos = 0;

                    const real = ValObjPrimerMes.map((_, index) => {
                        const tipoCalculo = TipoCalculoMes[index];
                        const promedio = (ValObjPrimerMes[index] + ValObjSegundoMes[index] + ValObjTercero[index]) / 3;
                        return tipoCalculo === "" || tipoCalculo === "Promedio" || tipoCalculo === "NULL"
                            ? promedio
                            : ValObjTercero[index] || ValObjSegundoMes[index] || ValObjPrimerMes[index];
                    });

                    real.forEach((r, index) => {
                        const le = LEMes[index];
                        const vo = VOMes[index];
                        const calcDesempenio = Math.max(0, Math.min(((le - r) / (le - vo)) * 100, 100));
                        const puntos = (calcDesempenio * PonderacionMes[index]) / 100;
                        totalPuntos += puntos;
                    });

                    // Guardar los resultados
                    this.datosPorFolio[folio].dSumaPuntosCord = parseFloat(totalPuntos.toFixed(2));

                    const puntosCia = this.PuntosDIr_Final_ALF;
                    const puntosArea = this.PuntosDIr_Final;
                    const puntosNivel2 = this.getPuntosPorTrimestreFolio3(this.valorFolioGlobalNivel3, this.trimestreFinal);
            
                    //console.log('valor de CIA:', puntosCia, 'Valor de Area dir:', puntosArea, 'getPuntosPorTrimestreFolio3 ', puntosNivel2, 'valores de la persona:', totalPuntos);


                    let dBonoDIR = (
                        ((totalPuntos * this.poIndividualesColab) +
                            (puntosCia * this.poCIAColab) +
                            (puntosArea * this.poAreaColab) +
                            (puntosNivel2 * this.poDepartamentoColab)) * 0.2
                    );

                    dBonoDIR = parseFloat(dBonoDIR.toFixed(1));
                    console.log(`dBonoDIR para folio nivel 3_resp_expand ${folio}:`, dBonoDIR);


                    // Asignar el bono al empleado correspondiente
                    const employee = this.employeesNivel3.find(emp => String(emp.folio) === String(folio));
                    if (employee) {
                        employee.dBonoDIR = dBonoDIR;
                    } else {
                        console.warn(`No se encontró empleado con folio ${folio}.`);
                    }
                };

                // Ejecutar los cálculos en paralelo para cada folio
                await Promise.all(
                    Object.entries(this.datosPorFolio).map(([folio, data]) =>
                        calcularPuntosFolio(folio, data)
                    )
                );

                // console.log('Cálculo completado para todos los folios:', this.employeesNivel3);
                // console.log('valores del bono:', this.dBonoDIR)
            } catch (error) {
                console.error('Error en getPuntosCoordinadores:', error);
            }
        },

        async getPuntosCoordinadoresNivel4() {
            console.log('nivel de tony');
            try {
                if (!this.datosPorFolio || Object.keys(this.datosPorFolio).length === 0) {
                    console.warn('No se encontraron datos por folio.');
                    return;
                }

                const calcularPuntosFolio = (folio, data) => {
                    const {
                        TipoCalculoMes,
                        ValObjPrimerMes,
                        ValObjSegundoMes,
                        ValObjTercero,
                        LEMes,
                        VOMes,
                        PonderacionMes,
                    } = data;

                    // Calcular los valores reales y puntos en una sola iteración
                    let totalPuntos = 0;

                    const real = ValObjPrimerMes.map((_, index) => {
                        const tipoCalculo = TipoCalculoMes[index];
                        const promedio = (ValObjPrimerMes[index] + ValObjSegundoMes[index] + ValObjTercero[index]) / 3;
                        return tipoCalculo === "" || tipoCalculo === "Promedio" || tipoCalculo === "NULL"
                            ? promedio
                            : ValObjTercero[index] || ValObjSegundoMes[index] || ValObjPrimerMes[index];
                    });

                    real.forEach((r, index) => {
                        const le = LEMes[index];
                        const vo = VOMes[index];
                        const calcDesempenio = Math.max(0, Math.min(((le - r) / (le - vo)) * 100, 100));
                        const puntos = (calcDesempenio * PonderacionMes[index]) / 100;
                        totalPuntos += puntos;
                    });

                    // Guardar los resultados
                    this.datosPorFolio[folio].dSumaPuntosCord = parseFloat(totalPuntos.toFixed(2));

                    const puntosCia = this.getPuntosPorTrimestre(0, this.trimestreFinal);
                    const puntosArea = this.getPuntosPorTrimestre(this.valorAreaGlobalDIR, this.trimestreFinal);
                    const puntosNivel2 = this.getPuntosPorTrimestreFolio4(this.valorFolioGlobalNivel4, this.trimestreFinal);
                    //valorFolioGlobalNivel2
                    console.log('valor de CIA:', puntosCia, 'Valor de Area dir:', puntosArea, 'getPuntosPorTrimestreFolio4 ', puntosNivel2);


                    let dBonoDIR = (
                        ((totalPuntos * this.poIndividualesColab) +
                            (puntosCia * this.poCIAColab) +
                            (puntosArea * this.poAreaColab) +
                            (puntosNivel2 * this.poDepartamentoColab)) * 0.2
                    );

                    dBonoDIR = parseFloat(dBonoDIR.toFixed(1));
                    console.log(`dBonoDIR para folio ${folio}:`, dBonoDIR);


                    // Asignar el bono al empleado correspondiente
                    const employee = this.employeesNivel4.find(emp => String(emp.folio) === String(folio));
                    if (employee) {
                        employee.dBonoDIR = dBonoDIR;
                    } else {
                        console.warn(`No se encontró empleado con folio ${folio}.`);
                    }
                };

                // Ejecutar los cálculos en paralelo para cada folio
                await Promise.all(
                    Object.entries(this.datosPorFolio).map(([folio, data]) =>
                        calcularPuntosFolio(folio, data)
                    )
                );

                //console.log('Cálculo completado para todos los folios:', this.employees);
            } catch (error) {
                console.error('Error en getPuntosCoordinadores:', error);
            }
        },

        async getPuntosCoordinadoresNivel4_Resp() {
            try {
                if (!this.datosPorFolio || Object.keys(this.datosPorFolio).length === 0) {
                    console.warn('No se encontraron datos por folio.');
                    return;
                }

                const calcularPuntosFolio = (folio, data) => {
                    const {
                        TipoCalculoMes,
                        ValObjPrimerMes,
                        ValObjSegundoMes,
                        ValObjTercero,
                        LEMes,
                        VOMes,
                        PonderacionMes,
                    } = data;

                    // Calcular los valores reales y puntos en una sola iteración
                    let totalPuntos = 0;

                    const real = ValObjPrimerMes.map((_, index) => {
                        const tipoCalculo = TipoCalculoMes[index];
                        const promedio = (ValObjPrimerMes[index] + ValObjSegundoMes[index] + ValObjTercero[index]) / 3;
                        return tipoCalculo === "" || tipoCalculo === "Promedio" || tipoCalculo === "NULL"
                            ? promedio
                            : ValObjTercero[index] || ValObjSegundoMes[index] || ValObjPrimerMes[index];
                    });

                    real.forEach((r, index) => {
                        const le = LEMes[index];
                        const vo = VOMes[index];
                        const calcDesempenio = Math.max(0, Math.min(((le - r) / (le - vo)) * 100, 100));
                        const puntos = (calcDesempenio * PonderacionMes[index]) / 100;
                        totalPuntos += puntos;
                    });

                    // Guardar los resultados
                    this.datosPorFolio[folio].dSumaPuntosCord = parseFloat(totalPuntos.toFixed(2));

                    const puntosCia = this.PuntosDIr_Final_ALF;
                    const puntosArea = this.PuntosDIr_Final;
                    const puntosNivel2 = this.getPuntosPorTrimestreFolio4(this.valorFolioGlobalNivel4, this.trimestreFinal);
                    //valorFolioGlobalNivel2
                    //console.log('valor de CIA:', puntosCia, 'Valor de Area dir:', puntosArea, 'getPuntosPorTrimestreFolio4 ', puntosNivel2);


                    let dBonoDIR = (
                        ((totalPuntos * this.poIndividualesColab) +
                            (puntosCia * this.poCIAColab) +
                            (puntosArea * this.poAreaColab) +
                            (puntosNivel2 * this.poDepartamentoColab)) * 0.2
                    );

                    dBonoDIR = parseFloat(dBonoDIR.toFixed(1));
                    //console.log(`dBonoDIR para folio ${folio}:`, dBonoDIR);


                    // Asignar el bono al empleado correspondiente
                    const employee = this.employeesNivel4.find(emp => String(emp.folio) === String(folio));
                    if (employee) {
                        employee.dBonoDIR = dBonoDIR;
                    } else {
                        console.warn(`No se encontró empleado con folio ${folio}.`);
                    }
                };

                // Ejecutar los cálculos en paralelo para cada folio
                await Promise.all(
                    Object.entries(this.datosPorFolio).map(([folio, data]) =>
                        calcularPuntosFolio(folio, data)
                    )
                );

                //console.log('Cálculo completado para todos los folios:', this.employees);
            } catch (error) {
                console.error('Error en getPuntosCoordinadores:', error);
            }
        },

        async getPuntosCoordinadoresNivel5() {
            console.log('wasa en admin');
            try {
                if (!this.datosPorFolio || Object.keys(this.datosPorFolio).length === 0) {
                    console.warn('No se encontraron datos por folio.');
                    return;
                }

                const calcularPuntosFolio = (folio, data) => {
                    const {
                        TipoCalculoMes,
                        ValObjPrimerMes,
                        ValObjSegundoMes,
                        ValObjTercero,
                        LEMes,
                        VOMes,
                        PonderacionMes,
                    } = data;

                    // Calcular los valores reales y puntos en una sola iteración
                    let totalPuntos = 0;

                    const real = ValObjPrimerMes.map((_, index) => {
                        const tipoCalculo = TipoCalculoMes[index];
                        const promedio = (ValObjPrimerMes[index] + ValObjSegundoMes[index] + ValObjTercero[index]) / 3;
                        return tipoCalculo === "" || tipoCalculo === "Promedio" || tipoCalculo === "NULL"
                            ? promedio
                            : ValObjTercero[index] || ValObjSegundoMes[index] || ValObjPrimerMes[index];
                    });

                    real.forEach((r, index) => {
                        const le = LEMes[index];
                        const vo = VOMes[index];
                        const calcDesempenio = Math.max(0, Math.min(((le - r) / (le - vo)) * 100, 100));
                        const puntos = (calcDesempenio * PonderacionMes[index]) / 100;
                        totalPuntos += puntos;
                    });

                    // Guardar los resultados
                    this.datosPorFolio[folio].dSumaPuntosCord = parseFloat(totalPuntos.toFixed(2));

                    const puntosCia = this.getPuntosPorTrimestre(0, this.trimestreFinal);
                    const puntosArea = this.getPuntosPorTrimestre(this.valorAreaGlobalDIR, this.trimestreFinal);
                    const puntosNivel2 = this.getPuntosPorTrimestreFolio5(this.valorFolioGlobalNivel5, this.trimestreFinal);
                    //valorFolioGlobalNivel2
                     console.log('valor de CIA:', puntosCia, 'Valor de Area dir:', puntosArea, 'getPuntosPorTrimestreFolio5 ', puntosNivel2);


                    let dBonoDIR = (
                        ((totalPuntos * this.poIndividualesColab) +
                            (puntosCia * this.poCIAColab) +
                            (puntosArea * this.poAreaColab) +
                            (puntosNivel2 * this.poDepartamentoColab)) * 0.2
                    );

                    dBonoDIR = parseFloat(dBonoDIR.toFixed(1));
                    console.log(`dBonoDIR para folio ${folio}:`, dBonoDIR);


                    // Asignar el bono al empleado correspondiente
                    const employee = this.employeesNivel5.find(emp => String(emp.folio) === String(folio));
                    if (employee) {
                        employee.dBonoDIR = dBonoDIR;
                    } else {
                        console.warn(`No se encontró empleado con folio ${folio}.`);
                    }
                };

                // Ejecutar los cálculos en paralelo para cada folio
                await Promise.all(
                    Object.entries(this.datosPorFolio).map(([folio, data]) =>
                        calcularPuntosFolio(folio, data)
                    )
                );

                //console.log('Cálculo completado para todos los folios:', this.employees);
            } catch (error) {
                console.error('Error en getPuntosCoordinadores:', error);
            }
        },

        async getPuntosCoordinadoresNivel5_Resp() {
            try {
                if (!this.datosPorFolio || Object.keys(this.datosPorFolio).length === 0) {
                    console.warn('No se encontraron datos por folio.');
                    return;
                }

                const calcularPuntosFolio = (folio, data) => {
                    const {
                        TipoCalculoMes,
                        ValObjPrimerMes,
                        ValObjSegundoMes,
                        ValObjTercero,
                        LEMes,
                        VOMes,
                        PonderacionMes,
                    } = data;

                    // Calcular los valores reales y puntos en una sola iteración
                    let totalPuntos = 0;

                    const real = ValObjPrimerMes.map((_, index) => {
                        const tipoCalculo = TipoCalculoMes[index];
                        const promedio = (ValObjPrimerMes[index] + ValObjSegundoMes[index] + ValObjTercero[index]) / 3;
                        return tipoCalculo === "" || tipoCalculo === "Promedio" || tipoCalculo === "NULL"
                            ? promedio
                            : ValObjTercero[index] || ValObjSegundoMes[index] || ValObjPrimerMes[index];
                    });

                    real.forEach((r, index) => {
                        const le = LEMes[index];
                        const vo = VOMes[index];
                        const calcDesempenio = Math.max(0, Math.min(((le - r) / (le - vo)) * 100, 100));
                        const puntos = (calcDesempenio * PonderacionMes[index]) / 100;
                        totalPuntos += puntos;
                    });

                    // Guardar los resultados
                    this.datosPorFolio[folio].dSumaPuntosCord = parseFloat(totalPuntos.toFixed(2));

                    const puntosCia = this.PuntosDIr_Final_ALF;
                    const puntosArea = this.PuntosDIr_Final;
                    const puntosNivel2 = this.getPuntosPorTrimestreFolio5(this.valorFolioGlobalNivel5, this.trimestreFinal);
                    //valorFolioGlobalNivel2
                    // console.log('valor de CIA:', puntosCia, 'Valor de Area dir:', puntosArea, 'getPuntosPorTrimestreFolio5 ', puntosNivel2);


                    let dBonoDIR = (
                        ((totalPuntos * this.poIndividualesColab) +
                            (puntosCia * this.poCIAColab) +
                            (puntosArea * this.poAreaColab) +
                            (puntosNivel2 * this.poDepartamentoColab)) * 0.2
                    );

                    dBonoDIR = parseFloat(dBonoDIR.toFixed(1));
                    //console.log(`dBonoDIR para folio 6 ${folio}:`, dBonoDIR);


                    // Asignar el bono al empleado correspondiente
                    const employee = this.employeesNivel5.find(emp => String(emp.folio) === String(folio));
                    if (employee) {
                        employee.dBonoDIR = dBonoDIR;
                    } else {
                        console.warn(`No se encontró empleado con folio ${folio}.`);
                    }
                };

                // Ejecutar los cálculos en paralelo para cada folio
                await Promise.all(
                    Object.entries(this.datosPorFolio).map(([folio, data]) =>
                        calcularPuntosFolio(folio, data)
                    )
                );

                //console.log('Cálculo completado para todos los folios:', this.employees);
            } catch (error) {
                console.error('Error en getPuntosCoordinadores:', error);
            }
        },


        async getPuntosCoordinadoresNivel6() {
            try {
                if (!this.datosPorFolio || Object.keys(this.datosPorFolio).length === 0) {
                    console.warn('No se encontraron datos por folio.');
                    return;
                }

                const calcularPuntosFolio = (folio, data) => {
                    const {
                        TipoCalculoMes,
                        ValObjPrimerMes,
                        ValObjSegundoMes,
                        ValObjTercero,
                        LEMes,
                        VOMes,
                        PonderacionMes,
                    } = data;

                    // Calcular los valores reales y puntos en una sola iteración
                    let totalPuntos = 0;

                    const real = ValObjPrimerMes.map((_, index) => {
                        const tipoCalculo = TipoCalculoMes[index];
                        const promedio = (ValObjPrimerMes[index] + ValObjSegundoMes[index] + ValObjTercero[index]) / 3;
                        return tipoCalculo === "" || tipoCalculo === "Promedio" || tipoCalculo === "NULL"
                            ? promedio
                            : ValObjTercero[index] || ValObjSegundoMes[index] || ValObjPrimerMes[index];
                    });

                    real.forEach((r, index) => {
                        const le = LEMes[index];
                        const vo = VOMes[index];
                        const calcDesempenio = Math.max(0, Math.min(((le - r) / (le - vo)) * 100, 100));
                        const puntos = (calcDesempenio * PonderacionMes[index]) / 100;
                        totalPuntos += puntos;
                    });

                    // Guardar los resultados
                    this.datosPorFolio[folio].dSumaPuntosCord = parseFloat(totalPuntos.toFixed(2));

                    const puntosCia = this.getPuntosPorTrimestre(0, this.trimestreFinal);
                    const puntosArea = this.getPuntosPorTrimestre(this.valorAreaGlobalDIR, this.trimestreFinal);
                    const puntosNivel2 = this.getPuntosPorTrimestreFolio6(this.valorFolioGlobalNivel6, this.trimestreFinal);
                    // console.log('valor del forlio que le llega en nivel 6: ', this.valorFolioGlobalNivel6);

                    // console.log('valor de CIA:', puntosCia, 'Valor de Area dir:', puntosArea, 'getPuntosPorTrimestreFolio6 ', puntosNivel2);


                    let dBonoDIR = (
                        ((totalPuntos * this.poIndividualesColab) +
                            (puntosCia * this.poCIAColab) +
                            (puntosArea * this.poAreaColab) +
                            (puntosNivel2 * this.poDepartamentoColab)) * 0.2
                    );

                    dBonoDIR = parseFloat(dBonoDIR.toFixed(1));
                    //console.log(`dBonoDIR para folio ${folio}:`, dBonoDIR);


                    // Asignar el bono al empleado correspondiente
                    const employee = this.employeesNivel6.find(emp => String(emp.folio) === String(folio));
                    if (employee) {
                        employee.dBonoDIR = dBonoDIR;
                    } else {
                        console.warn(`No se encontró empleado con folio ${folio}.`);
                    }
                };

                // Ejecutar los cálculos en paralelo para cada folio
                await Promise.all(
                    Object.entries(this.datosPorFolio).map(([folio, data]) =>
                        calcularPuntosFolio(folio, data)
                    )
                );

                //console.log('Cálculo completado para todos los folios:', this.employees);
            } catch (error) {
                console.error('Error en getPuntosCoordinadores:', error);
            }
        },


        //Este es para ver los puntos y el dBonoColab de la tabla 3

        async getPuntosColaboradores() {
            //console.log('Entrando al método de los puntos para cada folio (Colaboradores)...');

            try {
                // Verificar que los datos para colaboradores estén disponibles
                if (!this.datosColaboradores || Object.keys(this.datosColaboradores).length === 0) {
                    console.warn('No se encontraron datos para colaboradores.');
                    return;
                }

                // Recorrer cada folio en los datos de colaboradores
                for (const folioColaborador in this.datosColaboradores) {
                    //  console.log(`Procesando folio de colaborador: ${folioColaborador}`);

                    const {
                        TipoCalculoMes,
                        ValObjPrimerMes,
                        ValObjSegundoMes,
                        ValObjTercero,
                        LEMes,
                        VOMes,
                        PonderacionMes
                    } = this.datosColaboradores[folioColaborador];

                    // Obtener el valor de dSumaPuntosCord del folio correspondiente en los coordinadores
                    const dSumaPuntosCord = this.datosCoordinadores?.[folioColaborador]?.dSumaPuntosCord || 0;
                    //  console.log(`Usando dSumaPuntosCord para folio ${folioColaborador}:`, dSumaPuntosCord);

                    // Calcular el valor real (dReal)
                    const real = ValObjPrimerMes.map((_, index) => {
                        const tipoCalculo = TipoCalculoMes[index];
                        return tipoCalculo === "" || tipoCalculo === "Promedio" || tipoCalculo === "NULL"
                            ? (ValObjPrimerMes[index] + ValObjSegundoMes[index] + ValObjTercero[index]) / 3
                            : ValObjTercero[index] || ValObjSegundoMes[index] || ValObjPrimerMes[index];
                    });

                    // Calcular el desempeño
                    const desempenio = LEMes.map((le, index) => {
                        const vo = VOMes[index];
                        return Math.max(0, Math.min(((le - real[index]) / (le - vo)) * 100, 100));
                    });

                    // Calcular los puntos
                    const puntos = desempenio.map((d, index) => (d * PonderacionMes[index]) / 100);
                    const dSumaPuntos = puntos.reduce((acc, p) => acc + p, 0);
                    // console.log(`Total de puntos para folio ${folioColaborador}: ${dSumaPuntos}`);

                    // Obtener los puntos del trimestre para la compañía
                    const puntosCia = this.getPuntosPorTrimestre(0, this.trimestreFinal);

                    // Calcular el valor de dBonoColab
                    let dBonoColab = (
                        ((dSumaPuntos * this.poIndividualesColab) +
                            (puntosCia * this.poCIAColab) +
                            (this.dSumaPuntosAreaGlobal * this.poAreaColab) +
                            (dSumaPuntosCord * this.poDepartamentoColab)) * 0.2
                    );

                    dBonoColab = parseFloat(dBonoColab.toFixed(1));
                    //console.log(`dBonoColab para folio ${folioColaborador}:`, dBonoColab);

                    // Asignar el valor de dBonoColab al empleado correspondiente
                    const employee = this.employees.find(emp => String(emp.folio) === String(folioColaborador));
                    if (employee) {
                        employee.dBonoColab = dBonoColab;
                        // console.log(`Asignado dBonoColab a empleado ${employee.name}:`, employee.dBonoColab);
                    } else {
                        console.warn(`No se encontró empleado con folio ${folioColaborador}.`);
                    }
                }

                // console.log('Cálculo completado para todos los folios de colaboradores:', this.employees);
            } catch (error) {
                console.error('Error en getPuntosColaboradores:', error);
            }
        },

        //Este es para calculas el resul, desemp y puntos de la tabla de directores en los meses
        async getPuntosTrimMesDir1(signal) {
            if (signal.aborted) throw new DOMException('Abortado', 'AbortError');
            // Validamos si las variables necesarias están definidas
            if (!this.mes1t1 || !this.mes2t1 || !this.mes3t1 || !this.TipoCalculos1 || !this.LE1 || !this.VO1 || !this.Ponderacion1) {
                console.error("Una o más variables no están definidas");
                return;
            }

            // Cálculo del valor real
            let real = this.mes1t1.map((val, index) => {
                let tipoCalculo = this.TipoCalculos1[index];
                let dReal = 0;

                if (tipoCalculo == "" || tipoCalculo == "Promedio" || tipoCalculo == "NULL") {
                    dReal = (this.mes1t1[index] + this.mes2t1[index] + this.mes3t1[index]) / 3;
                } else {
                    if (this.mes3t1[index] != 0) {
                        dReal = this.mes3t1[index];
                    } else if (this.mes2t1[index] != 0) {
                        dReal = this.mes2t1[index];
                    } else if (this.mes1t1[index] != 0) {
                        dReal = this.mes1t1[index];
                    }
                }

                return dReal;
            });

            // Cálculo del desempeño
            let desempenio = this.LE1.map((le, index) => {
                let vo = this.VO1[index];
                let result = ((le - real[index]) / (le - vo)) * 100;
                return Math.max(0, Math.min(result, 100)); // Asegurar que está entre 0 y 100
            });

            // Cálculo de los puntos
            let puntos = desempenio.map((d, index) => (d * this.Ponderacion1[index]) / 100);

            // Suma total de puntos
            this.totalPuntos = puntos.reduce((acc, p) => acc + p, 0);

            // Actualizar el estado con los nuevos valores
            this.desempenio = desempenio;
            this.puntos = puntos;

            // Mostrar en consola los valores
            // console.log('Real 1:', real);
            this.dReal1 = real;
            // console.log('Real 1 marer:', this.dReal1);
            // console.log('Desempeño 1:', this.desempenio);
            // console.log('Puntos 1:', this.puntos);
            // console.log('Total de Puntos 1:', this.totalPuntos);
        },

        //Este es para la tabla 2
        async getPuntosTrimMesDir2(signal) {
            if (signal.aborted) throw new DOMException('Abortado', 'AbortError');
            // Validamos si las variables necesarias están definidas
            if (!this.mes1t2 || !this.mes2t2 || !this.mes3t2 || !this.TipoCalculos2 || !this.LE2 || !this.VO2 || !this.Ponderacion1) {
                console.error("Una o más variables no están definidas");
                return;
            }

            // Cálculo del valor real
            let real2 = this.mes1t2.map((val, index) => {
                let tipoCalculo = this.TipoCalculos2[index];
                let dReal = 0;

                if (tipoCalculo == "" || tipoCalculo == "Promedio" || tipoCalculo == "NULL") {
                    dReal = (this.mes1t2[index] + this.mes2t2[index] + this.mes3t2[index]) / 3;
                } else {
                    if (this.mes3t2[index] != 0) {
                        dReal = this.mes3t2[index];
                    } else if (this.mes2t2[index] != 0) {
                        dReal = this.mes2t2[index];
                    } else if (this.mes1t2[index] != 0) {
                        dReal = this.mes1t2[index];
                    }
                }

                return dReal;
            });

            // Cálculo del desempeño
            let desempenio2 = this.LE2.map((le, index) => {
                let vo = this.VO2[index];
                let result = ((le - real2[index]) / (le - vo)) * 100;
                return Math.max(0, Math.min(result, 100)); // Asegurar que está entre 0 y 100
            });

            // Cálculo de los puntos
            let puntos2 = desempenio2.map((d, index) => (d * this.Ponderacion2[index]) / 100);

            // Suma total de puntos
            this.totalPuntos = puntos2.reduce((acc, p) => acc + p, 0);

            // Actualizar el estado con los nuevos valores
            this.desempenio2 = desempenio2;
            this.puntos2 = puntos2;

            // Mostrar en consola los valores
            // console.log('Real 2:', real2);
            this.dReal2 = real2;
            // console.log('Desempeño 2:', this.desempenio2);
            // console.log('Puntos 2:', this.puntos2);
            // console.log('Total de Puntos 2:', this.totalPuntos);
        },

        async validaciones() {
            //  console.log('entro al metodo de VALIDACIONES !!!!!!!!!!!!!!!!!!!11111');
            // Asegúrate de que las variables existen y son arrays
            if (!Array.isArray(this.mes1) || !Array.isArray(this.mes2) || !Array.isArray(this.mes3) || !Array.isArray(this.TipoCalculos)) {
                console.error('mes1, mes2, mes3 o TipoCalculos no están correctamente inicializados');
                return;
            }

            function segundoIF(valor) {
                return valor === null || valor === "Promedio" || valor === "" || valor === "NULL";
            }

            // Esto es para verificar si está vacío
            this.mes1 = this.mes1.map((val, index) => {
                if (val === "" || val === null || val === undefined) {
                    console.log(`valor1[${index}] es vacío, ajustando a 0`);
                    return 0;
                }
                return val;
            });
            this.mes2 = this.mes2.map((val, index) => {
                if (val === "" || val === null || val === undefined) {
                    console.log(`valor2[${index}] es vacío, ajustando a 0`);
                    return 0;
                }
                return val;
            });
            this.mes3 = this.mes3.map((val, index) => {
                if (val === "" || val === null || val === undefined) {
                    console.log(`valor3[${index}] es vacío, ajustando a 0`);
                    return 0;
                }
                return val;
            });

            // Esto es para sacar los resultados
            this.mes1 = this.mes1.map(val => isNaN(parseFloat(val)) ? 0 : parseFloat(val));
            this.mes2 = this.mes2.map(val => isNaN(parseFloat(val)) ? 0 : parseFloat(val));
            this.mes3 = this.mes3.map(val => isNaN(parseFloat(val)) ? 0 : parseFloat(val));

            if (this.mes1.length === this.mes2.length && this.mes2.length === this.mes3.length) {
                this.dReal = this.mes1.map((valor, index) => valor + this.mes2[index] + this.mes3[index]);

                if (this.TipoCalculos.some(segundoIF)) {
                    this.dReal = this.dReal.map(valor => valor / 3);
                } else {
                    this.dReal = this.dReal.map((_, index) => {
                        if (this.mes3[index] !== 0) return this.mes3[index];
                        if (this.mes2[index] !== 0) return this.mes2[index];
                        if (this.mes1[index] !== 0) return this.mes1[index];
                        return 0;
                    });
                }
            } else {
                console.error('Los arrays no tienen la misma longitud o uno de los valores no es un array');
            }
            // console.log('Salio del metodo validaciones %%%%%')
        },

        //Esto es para


        async getEvaluacion() {
            //   console.log('Entro al metodo: getEvaluacion &&&&&');
            try {
                const url = `${this.$store.getters.getHostNode}/api/evaluacion?Id_Resps=${this.Id_Resp}`;
                // console.log('URL de la solicitud:', url);

                const response = await axios.get(url, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                const data = response?.data?.data || [];
                // console.log('Datos recibidos de la API:', data);

                // Imprimimos this.rows para ver su contenido
                // console.log('Contenido de this.rows:', this.rows);

                // Convertir los Resps a cadenas para asegurar coincidencia
                const rowsMap = new Map(this.rows.map(row => [String(row.Resp), row]));
                // console.log('Mapa de filas (`rowsMap`):', rowsMap);

                data.forEach(item => {
                    const row = rowsMap.get(String(item.Resp)); // Aseguramos que el tipo coincida
                    //   console.log(`Procesando item ${item.Resp}: Fila encontrada:`, row);

                    if (row) {
                        row.PuntosObjetivos = this.formatNumber(item.PUNTOS);
                        // console.log(`Puntos Objetivos (${item.Resp}):`, row.PuntosObjetivos);

                        row.PuntosLogro = (parseFloat(item.PuntosLogro) * 10).toFixed(1);
                        row.PuntosMejora = parseFloat(item.PuntosMejora).toFixed(1);
                        row.PuntosValores = this.formatNumber(item.Puntuacion2);

                        const puntosLogroNum = parseFloat(row.PuntosLogro);
                        const puntosMejoraNum = parseFloat(row.PuntosMejora);
                        row.Extras = this.formatNumber(puntosLogroNum + puntosMejoraNum);

                        const puntosObjetivosNum = parseFloat(row.PuntosObjetivos.replace(/,/g, ''));
                        const puntosValoresNum = parseFloat(row.PuntosValores.replace(/,/g, ''));
                        row.Total = this.formatNumber(
                            puntosObjetivosNum + puntosValoresNum + puntosLogroNum + puntosMejoraNum
                        );
                    } else {
                        console.warn(`No se encontró una fila para Resp: ${item.Resp}`);
                    }
                });

                // console.log('Filas actualizadas en rows:', this.rows);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            //   console.log('Salio del metodo: getEvaluacion &&&&&');
        },

        // Función reutilizable para formatear números
        formatNumber(value) {
            return Number(value).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        async getEvaluacion2Emp() {
            try {
                // Convertir `FolioD` a una cadena de IDs separados por comas para la URL
                const folioIds = this.FolioD.join(",");
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/evaluacion2?Id_Resps=${folioIds}`, {
                    headers: { Authorization: localStorage.getItem("token") },
                });

                if (response.data && response.data.data) {
                    const resultados = response.data.data;

                    // Recorrer `this.employees` y actualizar solo los empleados presentes en `resultados`
                    this.employees.forEach(employee => {
                        const item = resultados.find(r => r.Resp === employee.folio);

                        if (item) {
                            // Cálculo y actualización en una sola operación
                            const puntosObjetivos = parseFloat(item.PUNTOS) || 0;
                            const puntosLogro = (parseFloat(item.PuntosLogro) || 0) * 10;
                            const puntosMejora = parseFloat(item.PuntosMejora) || 0;
                            const puntosValores = parseFloat(item.Puntuacion2) || 0;

                            // Actualización con formateo
                            employee.PuntosObjetivos = puntosObjetivos.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            employee.PuntosLogro2 = puntosLogro.toFixed(1);
                            employee.PuntosMejora2 = puntosMejora.toFixed(1);
                            employee.PuntosValores = puntosValores.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                            // Calcular extras y total en una sola asignación
                            employee.Extras = (puntosLogro + puntosMejora).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            employee.Total = (puntosObjetivos + puntosValores + puntosLogro + puntosMejora).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                    });

                    // Forzar actualización en la vista para reflejar los cambios en la tabla
                    this.$forceUpdate();
                }

            } catch (error) {
                console.error("Error al obtener los datos de evaluación:", error);
            }
        },

        async getEvaluacion2EmpNivel3() {
            try {
                // Convertir `FolioD` a una cadena de IDs separados por comas para la URL
                const folioIds = this.FolioN3.join(",");
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/evaluacion2?Id_Resps=${folioIds}`, {
                    headers: { Authorization: localStorage.getItem("token") },
                });

                if (response.data && response.data.data) {
                    const resultados = response.data.data;

                    // Recorrer `this.employees` y actualizar solo los empleados presentes en `resultados`
                    this.employeesNivel3.forEach(employee => {
                        const item = resultados.find(r => r.Resp === employee.folio);

                        if (item) {
                            // Cálculo y actualización en una sola operación
                            const puntosObjetivos = parseFloat(item.PUNTOS) || 0;
                            const puntosLogro = (parseFloat(item.PuntosLogro) || 0) * 10;
                            const puntosMejora = parseFloat(item.PuntosMejora) || 0;
                            const puntosValores = parseFloat(item.Puntuacion2) || 0;

                            // Actualización con formateo
                            employee.PuntosObjetivos = puntosObjetivos.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            employee.PuntosLogro2 = puntosLogro.toFixed(1);
                            employee.PuntosMejora2 = puntosMejora.toFixed(1);
                            employee.PuntosValores = puntosValores.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                            // Calcular extras y total en una sola asignación
                            employee.Extras = (puntosLogro + puntosMejora).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            employee.Total = (puntosObjetivos + puntosValores + puntosLogro + puntosMejora).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                    });

                    // Forzar actualización en la vista para reflejar los cambios en la tabla
                    this.$forceUpdate();
                }

            } catch (error) {
                console.error("Error al obtener los datos de evaluación:", error);
            }
        },

        async getEvaluacion2EmpNivel3_Resp() {
            try {
                // Convertir `FolioD` a una cadena de IDs separados por comas para la URL
                const folioIds = this.Id_Resp.join(",");
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/evaluacion2?Id_Resps=${folioIds}`, {
                    headers: { Authorization: localStorage.getItem("token") },
                });

                if (response.data && response.data.data) {
                    const resultados = response.data.data;

                    // Recorrer `this.employees` y actualizar solo los empleados presentes en `resultados`
                    this.employeesNivel3.forEach(employee => {
                        const item = resultados.find(r => r.Resp === employee.folio);

                        if (item) {
                            // Cálculo y actualización en una sola operación
                            const puntosObjetivos = parseFloat(item.PUNTOS) || 0;
                            const puntosLogro = (parseFloat(item.PuntosLogro) || 0) * 10;
                            const puntosMejora = parseFloat(item.PuntosMejora) || 0;
                            const puntosValores = parseFloat(item.Puntuacion2) || 0;

                            // Actualización con formateo
                            employee.PuntosObjetivos = puntosObjetivos.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            employee.PuntosLogro2 = puntosLogro.toFixed(1);
                            employee.PuntosMejora2 = puntosMejora.toFixed(1);
                            employee.PuntosValores = puntosValores.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                            // Calcular extras y total en una sola asignación
                            employee.Extras = (puntosLogro + puntosMejora).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            employee.Total = (puntosObjetivos + puntosValores + puntosLogro + puntosMejora).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                    });

                    // Forzar actualización en la vista para reflejar los cambios en la tabla
                    this.$forceUpdate();
                }

            } catch (error) {
                console.error("Error al obtener los datos de evaluación:", error);
            }
        },

        async getEvaluacion2EmpNivel4() {
            try {
                // Convertir `FolioD` a una cadena de IDs separados por comas para la URL
                const folioIds = this.FolioN4.join(",");
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/evaluacion2?Id_Resps=${folioIds}`, {
                    headers: { Authorization: localStorage.getItem("token") },
                });

                if (response.data && response.data.data) {
                    const resultados = response.data.data;

                    // Recorrer `this.employees` y actualizar solo los empleados presentes en `resultados`
                    this.employeesNivel4.forEach(employee => {
                        const item = resultados.find(r => r.Resp === employee.folio);

                        if (item) {
                            // Cálculo y actualización en una sola operación
                            const puntosObjetivos = parseFloat(item.PUNTOS) || 0;
                            const puntosLogro = (parseFloat(item.PuntosLogro) || 0) * 10;
                            const puntosMejora = parseFloat(item.PuntosMejora) || 0;
                            const puntosValores = parseFloat(item.Puntuacion2) || 0;

                            // Actualización con formateo
                            employee.PuntosObjetivos = puntosObjetivos.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            employee.PuntosLogro2 = puntosLogro.toFixed(1);
                            employee.PuntosMejora2 = puntosMejora.toFixed(1);
                            employee.PuntosValores = puntosValores.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                            // Calcular extras y total en una sola asignación
                            employee.Extras = (puntosLogro + puntosMejora).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            employee.Total = (puntosObjetivos + puntosValores + puntosLogro + puntosMejora).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                    });

                    // Forzar actualización en la vista para reflejar los cambios en la tabla
                    this.$forceUpdate();
                }

            } catch (error) {
                console.error("Error al obtener los datos de evaluación:", error);
            }
        },


        async getEvaluacion2EmpNivel5() {
            try {
                // Convertir `FolioD` a una cadena de IDs separados por comas para la URL
                const folioIds = this.FolioN5.join(",");
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/evaluacion2?Id_Resps=${folioIds}`, {
                    headers: { Authorization: localStorage.getItem("token") },
                });

                if (response.data && response.data.data) {
                    const resultados = response.data.data;

                    // Recorrer `this.employees` y actualizar solo los empleados presentes en `resultados`
                    this.employeesNivel5.forEach(employee => {
                        const item = resultados.find(r => r.Resp === employee.folio);

                        if (item) {
                            // Cálculo y actualización en una sola operación
                            const puntosObjetivos = parseFloat(item.PUNTOS) || 0;
                            const puntosLogro = (parseFloat(item.PuntosLogro) || 0) * 10;
                            const puntosMejora = parseFloat(item.PuntosMejora) || 0;
                            const puntosValores = parseFloat(item.Puntuacion2) || 0;

                            // Actualización con formateo
                            employee.PuntosObjetivos = puntosObjetivos.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            employee.PuntosLogro2 = puntosLogro.toFixed(1);
                            employee.PuntosMejora2 = puntosMejora.toFixed(1);
                            employee.PuntosValores = puntosValores.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                            // Calcular extras y total en una sola asignación
                            employee.Extras = (puntosLogro + puntosMejora).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            employee.Total = (puntosObjetivos + puntosValores + puntosLogro + puntosMejora).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                    });

                    // Forzar actualización en la vista para reflejar los cambios en la tabla
                    this.$forceUpdate();
                }

            } catch (error) {
                console.error("Error al obtener los datos de evaluación:", error);
            }
        },

        async getEvaluacion2EmpNivel6() {
            try {
                // Convertir `FolioD` a una cadena de IDs separados por comas para la URL
                const folioIds = this.FolioN6.join(",");
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/evaluacion2?Id_Resps=${folioIds}`, {
                    headers: { Authorization: localStorage.getItem("token") },
                });

                if (response.data && response.data.data) {
                    const resultados = response.data.data;

                    // Recorrer `this.employees` y actualizar solo los empleados presentes en `resultados`
                    this.employeesNivel6.forEach(employee => {
                        const item = resultados.find(r => r.Resp === employee.folio);

                        if (item) {
                            // Cálculo y actualización en una sola operación
                            const puntosObjetivos = parseFloat(item.PUNTOS) || 0;
                            const puntosLogro = (parseFloat(item.PuntosLogro) || 0) * 10;
                            const puntosMejora = parseFloat(item.PuntosMejora) || 0;
                            const puntosValores = parseFloat(item.Puntuacion2) || 0;

                            // Actualización con formateo
                            employee.PuntosObjetivos = puntosObjetivos.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            employee.PuntosLogro2 = puntosLogro.toFixed(1);
                            employee.PuntosMejora2 = puntosMejora.toFixed(1);
                            employee.PuntosValores = puntosValores.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                            // Calcular extras y total en una sola asignación
                            employee.Extras = (puntosLogro + puntosMejora).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            employee.Total = (puntosObjetivos + puntosValores + puntosLogro + puntosMejora).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                    });

                    // Forzar actualización en la vista para reflejar los cambios en la tabla
                    this.$forceUpdate();
                }

            } catch (error) {
                console.error("Error al obtener los datos de evaluación:", error);
            }
        },









        /*Sacamos el numero de colaboradores que tiene cada uno de los directores*/
        async gerCantDir() {
            // console.log('Entro al metodo gerCantDir #####')
            // console.log('VALORES DE ID_Resp #############33', this.Id_Resp);
            try {
                // Hacer la petición GET a la API
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/noColaboradoresDir/${this.anioTabla}/${this.trimestreFinal}/${this.Id_Resp}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                // Imprimir la respuesta completa para depurar
                // console.log('Respuesta completa de la API:', response.data);

                // Verificar si response.data y response.data.data existen y es un array
                if (response.data && Array.isArray(response.data.data)) {
                    // Asignar los datos de CantColab a rows si ya existe, de lo contrario crear un nuevo array con CantColab
                    this.rows = this.rows.map((row, index) => ({
                        ...row,
                        CantColab: response.data.data[index] ? response.data.data[index].CantidColab : null
                    }));

                    // console.log('VALOR DE rows con CantColab %%%%%%%%%%%%%%%%%%%%%%', this.rows);
                } else {
                    console.log('La estructura de response.data.data no es un array o no contiene datos CantColab.');
                    this.rows = [];  // Asignar un array vacío si no hay datos válidos
                }

            } catch (error) {
                // Manejo de errores y mostrar el mensaje en la consola
                console.error('Error fetching data colab:', error);
            }
            // console.log('Salio del metodo gerCantDir #####')
        },


        /*Sacamos el numero de colaboradores que tiene cada uno de los directores en epleados 222*/



        async gerCantEmpTabla3(folios) {
            // console.log('Ejecutando gerCantEmpTabla3 con folios:', folios);

            try {
                for (const folio of folios) {
                    // console.log(`Consultando colaboradores para el folio: ${folio}`);

                    const response = await axios.get(
                        `${this.$store.getters.getHostNode}/api/noColaboradoresDir/${this.anioTabla}/${this.trimestreFinal}/${folio}`,
                        {
                            headers: {
                                Authorization: localStorage.getItem("token"),
                            },
                        }
                    );

                    // console.log(`Respuesta de la API para folio ${folio}:`, response.data);

                    if (response.data && Array.isArray(response.data.data) && response.data.data.length > 0) {
                        const cantidadColaboradores = response.data.data[0]?.CantidColab || 0;

                        // console.log(`Cantidad de colaboradores para folio ${folio}:`, cantidadColaboradores);

                        // Verificar si el folio tiene datos previos en thirdEmployeer
                        const empleadosData = this.thirdEmployeer[folio] || [];

                        // Si hay datos en empleadosData, actualizarlos con la cantidad de colaboradores
                        const empleadosActualizados = empleadosData.map((item) => ({
                            ...item,
                            CantColab: cantidadColaboradores,
                        }));

                        // Si no hay datos previos, crear una entrada con la cantidad de colaboradores
                        if (empleadosActualizados.length === 0) {
                            empleadosActualizados.push({
                                folio3: folio,
                                Nombre: `Empleado ${folio}`, // Nombre genérico si no hay datos
                                CantColab: cantidadColaboradores,
                            });
                        }

                        // Actualizar thirdEmployeer con los datos procesados
                        this.$set(this.thirdEmployeer, folio, empleadosActualizados);

                        // console.log(`Datos actualizados para folio ${folio}:`, this.thirdEmployeer[folio]);
                    } else {
                        console.warn(`No se encontraron datos válidos para folio ${folio}.`);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },



        //Este es para sacar la cantidaf de empleados en la tabla 2
        async gerCantDirEmp() {
            //  console.log('VALORES DE FolioD es el segundo $$$$$', this.FolioD);
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/noColaboradoresDir/${this.anioTabla}/${this.trimestreFinal}/${this.FolioD}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                if (response.data && Array.isArray(response.data.data)) {
                    // Iterar sobre los datos y asignar `CantidColab` a cada empleado correspondiente en `employees`
                    response.data.data.forEach((item, index) => {
                        const employee = this.employees[index]; // Asegúrate de que el índice coincida con el empleado
                        if (employee) {
                            // Asignar el número de colaboradores a cada empleado
                            employee.CantColab = item.CantidColab != null && item.CantidColab !== '' ? item.CantidColab : 0;
                            // console.log(`Actualizado CantColab para empleado Resp ${employee.folio}:`, employee.CantColab);
                        }
                    });

                    // console.log('Datos actualizados de CantColab:', this.employees);

                    // Llamar a la función para obtener la evaluación
                    // this.getEvaluacion2Emp();
                } else {
                    console.log('La estructura de response.data.data no es un array o no contiene datos.');
                    this.CantColab = [];
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        //Este es para sacar la cantidaf de empleados en la tabla 3
        async gerCantDirEmpNivel3() {
            //  console.log('VALORES DE FolioD es el segundo $$$$$', this.FolioD);
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/noColaboradoresDir/${this.anioTabla}/${this.trimestreFinal}/${this.FolioN3}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                if (response.data && Array.isArray(response.data.data)) {
                    // Iterar sobre los datos y asignar `CantidColab` a cada empleado correspondiente en `employees`
                    response.data.data.forEach((item, index) => {
                        const employee = this.employeesNivel3[index]; // Asegúrate de que el índice coincida con el empleado
                        if (employee) {
                            // Asignar el número de colaboradores a cada empleado
                            employee.CantColab = item.CantidColab != null && item.CantidColab !== '' ? item.CantidColab : 0;
                            // console.log(`Actualizado CantColab para empleado Resp ${employee.folio}:`, employee.CantColab);
                        }
                    });

                    // console.log('Datos actualizados de CantColab:', this.employees);

                    // Llamar a la función para obtener la evaluación
                    // this.getEvaluacion2Emp();
                } else {
                    console.log('La estructura de response.data.data no es un array o no contiene datos.');
                    this.CantColab = [];
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        async gerCantDirEmpNivel3_Resp() {
            //  console.log('VALORES DE FolioD es el segundo $$$$$', this.FolioD);
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/noColaboradoresDir/${this.anioTabla}/${this.trimestreFinal}/${this.Id_Resp}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                if (response.data && Array.isArray(response.data.data)) {
                    // Iterar sobre los datos y asignar `CantidColab` a cada empleado correspondiente en `employees`
                    response.data.data.forEach((item, index) => {
                        const employee = this.employeesNivel3[index]; // Asegúrate de que el índice coincida con el empleado
                        if (employee) {
                            // Asignar el número de colaboradores a cada empleado
                            employee.CantColab = item.CantidColab != null && item.CantidColab !== '' ? item.CantidColab : 0;
                            // console.log(`Actualizado CantColab para empleado Resp ${employee.folio}:`, employee.CantColab);
                        }
                    });

                    // console.log('Datos actualizados de CantColab:', this.employees);

                    // Llamar a la función para obtener la evaluación
                    // this.getEvaluacion2Emp();
                } else {
                    console.log('La estructura de response.data.data no es un array o no contiene datos.');
                    this.CantColab = [];
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        //Este es para sacar la cantidaf de empleados en la tabla 4
        async gerCantDirEmpNivel4() {
            //  console.log('VALORES DE FolioD es el segundo $$$$$', this.FolioD);
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/noColaboradoresDir/${this.anioTabla}/${this.trimestreFinal}/${this.FolioN4}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                if (response.data && Array.isArray(response.data.data)) {
                    // Iterar sobre los datos y asignar `CantidColab` a cada empleado correspondiente en `employees`
                    response.data.data.forEach((item, index) => {
                        const employee = this.employeesNivel4[index]; // Asegúrate de que el índice coincida con el empleado
                        if (employee) {
                            // Asignar el número de colaboradores a cada empleado
                            employee.CantColab = item.CantidColab != null && item.CantidColab !== '' ? item.CantidColab : 0;
                            // console.log(`Actualizado CantColab para empleado Resp ${employee.folio}:`, employee.CantColab);
                        }
                    });

                    // console.log('Datos actualizados de CantColab:', this.employees);

                    // Llamar a la función para obtener la evaluación
                    // this.getEvaluacion2Emp();
                } else {
                    console.log('La estructura de response.data.data no es un array o no contiene datos.');
                    this.CantColab = [];
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        //Este es para sacar la cantidaf de empleados en la tabla 5
        async gerCantDirEmpNivel5() {
            //  console.log('VALORES DE FolioD es el segundo $$$$$', this.FolioD);
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/noColaboradoresDir/${this.anioTabla}/${this.trimestreFinal}/${this.FolioN5}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                if (response.data && Array.isArray(response.data.data)) {
                    // Iterar sobre los datos y asignar `CantidColab` a cada empleado correspondiente en `employees`
                    response.data.data.forEach((item, index) => {
                        const employee = this.employeesNivel5[index]; // Asegúrate de que el índice coincida con el empleado
                        if (employee) {
                            // Asignar el número de colaboradores a cada empleado
                            employee.CantColab = item.CantidColab != null && item.CantidColab !== '' ? item.CantidColab : 0;
                            // console.log(`Actualizado CantColab para empleado Resp ${employee.folio}:`, employee.CantColab);
                        }
                    });

                    // console.log('Datos actualizados de CantColab:', this.employees);

                    // Llamar a la función para obtener la evaluación
                    // this.getEvaluacion2Emp();
                } else {
                    console.log('La estructura de response.data.data no es un array o no contiene datos.');
                    this.CantColab = [];
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        //Este es para sacar la cantidaf de empleados en la tabla 6
        async gerCantDirEmpNivel6() {
            //  console.log('VALORES DE FolioD es el segundo $$$$$', this.FolioD);
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/noColaboradoresDir/${this.anioTabla}/${this.trimestreFinal}/${this.FolioN6}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                if (response.data && Array.isArray(response.data.data)) {
                    // Iterar sobre los datos y asignar `CantidColab` a cada empleado correspondiente en `employees`
                    response.data.data.forEach((item, index) => {
                        const employee = this.employeesNivel6[index]; // Asegúrate de que el índice coincida con el empleado
                        if (employee) {
                            // Asignar el número de colaboradores a cada empleado
                            employee.CantColab = item.CantidColab != null && item.CantidColab !== '' ? item.CantidColab : 0;
                            // console.log(`Actualizado CantColab para empleado Resp ${employee.folio}:`, employee.CantColab);
                        }
                    });

                    // console.log('Datos actualizados de CantColab:', this.employees);

                    // Llamar a la función para obtener la evaluación
                    // this.getEvaluacion2Emp();
                } else {
                    console.log('La estructura de response.data.data no es un array o no contiene datos.');
                    this.CantColab = [];
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },


        async getPuntosEmpleados() {
            // console.log('Entro al metodo getPuntosEmpleados %%%%%%%%%5');
            try {
                // Inicializamos los arrays
                this.PuntosEmpQ1 = [];
                this.PuntosEmpQ2 = [];
                this.PuntosEmpQ3 = [];
                this.PuntosEmpQ4 = [];

                // Preparar todas las solicitudes en paralelo
                const solicitudes = this.Id_Area.map((id, index) =>
                    axios
                        .get(`${this.$store.getters.getHostNode}/api/puntos/${id}/${this.anioTabla}`, {
                            headers: { Authorization: localStorage.getItem("token") },
                        })
                        .then((response) => ({ index, data: response.data.data }))
                        .catch((error) => {
                            console.error(`Error al obtener puntos para área ${id}:`, error);
                            return { index, data: [] }; // Continuar en caso de error
                        })
                );

                // Esperar a que todas las solicitudes terminen
                const resultados = await Promise.allSettled(solicitudes);

                // Diccionario temporal para acumular los datos de los trimestres
                const tempRows = {};

                resultados.forEach(({ status, value }) => {
                    if (status === 'fulfilled') {
                        const { index, data } = value;
                        data.forEach((item) => {
                            const puntos = parseFloat(item.Puntos).toFixed(1) || 0;

                            if (!tempRows[index]) {
                                tempRows[index] = { Trim1: 0, Trim2: 0, Trim3: 0, Trim4: 0 };
                            }

                            // Acumular los puntos en el trimestre correspondiente
                            switch (item.Trimestre) {
                                case 1:
                                    this.PuntosEmpQ1.push(puntos);
                                    tempRows[index].Trim1 = puntos;
                                    break;
                                case 2:
                                    this.PuntosEmpQ2.push(puntos);
                                    tempRows[index].Trim2 = puntos;
                                    break;
                                case 3:
                                    this.PuntosEmpQ3.push(puntos);
                                    tempRows[index].Trim3 = puntos;
                                    break;
                                case 4:
                                    this.PuntosEmpQ4.push(puntos);
                                    tempRows[index].Trim4 = puntos;
                                    break;
                                default:
                                    console.warn(`Trimestre no válido: ${item.Trimestre}`);
                            }
                        });
                    } else {
                        console.warn(`Error en solicitud ${value.index}`);
                    }
                });

                // Asignamos todos los datos acumulados a las filas de una vez
                Object.entries(tempRows).forEach(([index, trimestres]) => {
                    if (this.rows[index]) {
                        Object.assign(this.rows[index], trimestres);
                    } else {
                        console.warn(`Fila en el índice ${index} no está definida`);
                    }
                });

                // console.log('Filas actualizadas con valores trimestrales:', this.rows);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            // console.log('Salio del metodo getPuntosEmpleados %%%%%%%%%5');
        },



        getPuntosPorTrimestre(idArea, trimestre) {
            // Buscar la fila correspondiente al ID del área
            const fila = this.rows.find(row => row.Id_Area.includes(idArea));

            // Verificar si la fila existe y tiene el valor del trimestre solicitado
            if (fila) {
                const campoTrimestre = `Trim${trimestre}`; // Por ejemplo: Trim1, Trim2, Trim3, Trim4
                const puntos = fila[campoTrimestre];

                if (puntos !== undefined) {
                    // console.log(`Puntos para Área ${idArea}, Trimestre ${trimestre}: ${puntos}`);
                    return puntos;
                } else {
                    console.warn(`No hay puntos para el Trimestre ${trimestre} en el Área ${idArea}.`);
                    return 0; // Valor por defecto si no se encuentra
                }
            } else {
                console.warn(`No se encontró la fila para el Área ${idArea}.`);
                return 0; // Valor por defecto si no se encuentra el área
            }
        },


        getPuntosPorTrimestreFolio3(FOLIO, trimestre) {
            // Buscar la fila correspondiente al ID del área
            const fila = this.employees.find(item => item.folio.includes(FOLIO));

            // Verificar si la fila existe y tiene el valor del trimestre solicitado
            if (fila) {
                const campoTrimestre = `Trim${trimestre}`; // Por ejemplo: Trim1, Trim2, Trim3, Trim4
                const puntos = fila[campoTrimestre];

                if (puntos !== undefined) {
                    // console.log(`Puntos para Área ${FOLIO}, Trimestre ${trimestre}: ${puntos}`);
                    return puntos;
                } else {
                    console.warn(`No hay puntos para el Trimestre ${trimestre} en el FOLIO ${FOLIO}.`);
                    return 0; // Valor por defecto si no se encuentra
                }
            } else {
                console.warn(`No se encontró la fila para el FOLIO ${FOLIO}.`);
                return 0; // Valor por defecto si no se encuentra el área
            }
        },

        getPuntosPorTrimestreFolio4(FOLIO, trimestre) {
            // Buscar la fila correspondiente al ID del área
            const fila = this.employeesNivel3.find(item => item.folio.includes(FOLIO));

            // Verificar si la fila existe y tiene el valor del trimestre solicitado
            if (fila) {
                const campoTrimestre = `Trim${trimestre}`; // Por ejemplo: Trim1, Trim2, Trim3, Trim4
                const puntos = fila[campoTrimestre];

                if (puntos !== undefined) {
                    // console.log(`Puntos para Área ${FOLIO}, Trimestre ${trimestre}: ${puntos}`);
                    return puntos;
                } else {
                    console.warn(`No hay puntos para el Trimestre ${trimestre} en el FOLIO ${FOLIO}.`);
                    return 0; // Valor por defecto si no se encuentra
                }
            } else {
                console.warn(`No se encontró la fila para el FOLIO ${FOLIO}.`);
                return 0; // Valor por defecto si no se encuentra el área
            }
        },


        getPuntosPorTrimestreFolio5(FOLIO, trimestre) {
            // Buscar la fila correspondiente al ID del área
            const fila = this.employeesNivel4.find(item => item.folio.includes(FOLIO));

            // Verificar si la fila existe y tiene el valor del trimestre solicitado
            if (fila) {
                const campoTrimestre = `Trim${trimestre}`; // Por ejemplo: Trim1, Trim2, Trim3, Trim4
                const puntos = fila[campoTrimestre];

                if (puntos !== undefined) {
                    // console.log(`Puntos para Área ${FOLIO}, Trimestre ${trimestre}: ${puntos}`);
                    return puntos;
                } else {
                    console.warn(`No hay puntos para el Trimestre ${trimestre} en el FOLIO ${FOLIO}.`);
                    return 0; // Valor por defecto si no se encuentra
                }
            } else {
                console.warn(`No se encontró la fila para el FOLIO ${FOLIO}.`);
                return 0; // Valor por defecto si no se encuentra el área
            }
        },



        getPuntosPorTrimestreFolio6(FOLIO, trimestre) {
            // Buscar la fila correspondiente al ID del área
            const fila = this.employeesNivel5.find(item => item.folio.includes(FOLIO));

            // Verificar si la fila existe y tiene el valor del trimestre solicitado
            if (fila) {
                const campoTrimestre = `Trim${trimestre}`; // Por ejemplo: Trim1, Trim2, Trim3, Trim4
                const puntos = fila[campoTrimestre];

                if (puntos !== undefined) {
                    // console.log(`Puntos para Área ${FOLIO}, Trimestre ${trimestre}: ${puntos}`);
                    return puntos;
                } else {
                    console.warn(`No hay puntos para el Trimestre ${trimestre} en el FOLIO ${FOLIO}.`);
                    return 0; // Valor por defecto si no se encuentra
                }
            } else {
                console.warn(`No se encontró la fila para el FOLIO ${FOLIO}.`);
                return 0; // Valor por defecto si no se encuentra el área
            }
        },


        async metodoParaPuntosEmpleados() {
            const foliosConSistema = [];
            const foliosSinSistema = [];

            // Separar los folios en dos listas según la condición del sistema
            this.FolioD.forEach((folio, index) => {
                if ([4, 5, 6].includes(this.iSistema[index])) {
                    foliosConSistema.push(folio);
                } else {
                    foliosSinSistema.push(folio);
                }
            });

            // Llamar a los métodos correspondientes si hay folios en las listas
            if (foliosConSistema.length) {
                //console.log('ENTRO AQUI ################### EN EL PRIMERO', foliosConSistema);
                await this.getPuntosEmpleados2Mensual(foliosConSistema);
            }

            if (foliosSinSistema.length) {
                //  console.log('ENTRO AQUI ################### EN EL SEGUNDO', foliosSinSistema);
                await this.getPuntosEmpleados2(foliosSinSistema);
            }
        },

        async metodoParaPuntosEmpleadosNivel3() {
            const foliosConSistema = [];
            const foliosSinSistema = [];

            // Separar los folios en dos listas según la condición del sistema
            this.FolioN3.forEach((folio, index) => {
                if ([4, 5, 6].includes(this.iSistemaN3[index])) {
                    foliosConSistema.push(folio);
                } else {
                    foliosSinSistema.push(folio);
                }
            });

            // Llamar a los métodos correspondientes si hay folios en las listas
            if (foliosConSistema.length) {
                //  console.log('ENTRO AQUI ################### EN EL PRIMERO', foliosConSistema);
                await this.getPuntosEmpleados2MensualNivel3(foliosConSistema);
            }

            if (foliosSinSistema.length) {
                //  console.log('ENTRO AQUI ################### EN EL SEGUNDO', foliosSinSistema);
                await this.getPuntosEmpleados2Nivel3(foliosSinSistema);
            }
        },

        async metodoParaPuntosEmpleadosNivel3_Resp() {
            const foliosConSistema = [];
            const foliosSinSistema = [];

            // Separar los folios en dos listas según la condición del sistema
            this.Id_Resp.forEach((folio, index) => {
                if ([4, 5, 6].includes(this.iSistemaN3[index])) {
                    foliosConSistema.push(folio);
                } else {
                    foliosSinSistema.push(folio);
                }
            });

            // Llamar a los métodos correspondientes si hay folios en las listas
            if (foliosConSistema.length) {
                //  console.log('ENTRO AQUI ################### EN EL PRIMERO', foliosConSistema);
                await this.getPuntosEmpleados2MensualNivel3(foliosConSistema);
            }

            if (foliosSinSistema.length) {
                //  console.log('ENTRO AQUI ################### EN EL SEGUNDO', foliosSinSistema);
                await this.getPuntosEmpleados2Nivel3(foliosSinSistema);
            }
        },

        async metodoParaPuntosEmpleadosNivel4() {
            const foliosConSistema = [];
            const foliosSinSistema = [];

            // Separar los folios en dos listas según la condición del sistema
            this.FolioN4.forEach((folio, index) => {
                if ([4, 5, 6].includes(this.iSistemaN4[index])) {
                    foliosConSistema.push(folio);
                } else {
                    foliosSinSistema.push(folio);
                }
            });

            // Llamar a los métodos correspondientes si hay folios en las listas
            if (foliosConSistema.length) {
                // console.log('ENTRO AQUI ################### EN EL PRIMERO', foliosConSistema);
                await this.getPuntosEmpleados2MensualNivel4(foliosConSistema);
            }

            if (foliosSinSistema.length) {
                // console.log('ENTRO AQUI ################### EN EL SEGUNDO', foliosSinSistema);
                await this.getPuntosEmpleados2Nivel4(foliosSinSistema);
            }
        },

        async metodoParaPuntosEmpleadosNivel5() {
            const foliosConSistema = [];
            const foliosSinSistema = [];

            // Separar los folios en dos listas según la condición del sistema
            this.FolioN5.forEach((folio, index) => {
                if ([4, 5, 6].includes(this.iSistemaN5[index])) {
                    foliosConSistema.push(folio);
                } else {
                    foliosSinSistema.push(folio);
                }
            });

            // Llamar a los métodos correspondientes si hay folios en las listas
            if (foliosConSistema.length) {
                //console.log('ENTRO AQUI ################### EN EL PRIMERO', foliosConSistema);
                await this.getPuntosEmpleados2MensualNivel5(foliosConSistema);
            }

            if (foliosSinSistema.length) {
                // console.log('ENTRO AQUI ################### EN EL SEGUNDO', foliosSinSistema);
                await this.getPuntosEmpleados2Nivel5(foliosSinSistema);
            }
        },

        async metodoParaPuntosEmpleadosNivel6() {
            const foliosConSistema = [];
            const foliosSinSistema = [];

            // Separar los folios en dos listas según la condición del sistema
            this.FolioN6.forEach((folio, index) => {
                if ([4, 5, 6].includes(this.iSistemaN6[index])) {
                    foliosConSistema.push(folio);
                } else {
                    foliosSinSistema.push(folio);
                }
            });

            // Llamar a los métodos correspondientes si hay folios en las listas
            if (foliosConSistema.length) {
                // console.log('ENTRO AQUI ################### EN EL PRIMERO', foliosConSistema);
                await this.getPuntosEmpleados2MensualNivel6(foliosConSistema);
            }

            if (foliosSinSistema.length) {
                //  console.log('ENTRO AQUI ################### EN EL SEGUNDO', foliosSinSistema);
                await this.getPuntosEmpleados2Nivel6(foliosSinSistema);
            }
        },


        async getPuntosEmpleados2(foliosSinSistema) {
            try {
                // Inicializar las listas trimestrales si no están definidas
                this.PuntosEmpQ1tabla2 ||= [];
                this.PuntosEmpQ2tabla2 ||= [];
                this.PuntosEmpQ3tabla2 ||= [];
                this.PuntosEmpQ4tabla2 ||= [];

                // Crear un Map para búsqueda rápida de empleados por folio
                const employeeMap = new Map(this.employees.map(emp => [emp.folio, emp]));

                // Crear todas las solicitudes de datos en paralelo
                const solicitudes = foliosSinSistema.map(id =>
                    axios.get(`${this.$store.getters.getHostNode}/api/puntos2/${id}/${this.anioTabla}`, {
                        headers: { Authorization: localStorage.getItem("token") },
                    }).then(response => ({ id, data: response.data.data }))
                        .catch(error => {
                            console.warn(`Error al obtener puntos para el folio ${id}:`, error);
                            return { id, data: [] };
                        })
                );

                // Esperar a que todas las solicitudes finalicen
                const resultados = await Promise.all(solicitudes);

                // Procesar los datos obtenidos
                resultados.forEach(({ id, data }) => {
                    const employee = employeeMap.get(id);
                    if (!employee) {
                        console.warn(`No se encontró empleado con el folio: ${id}`);
                        return;
                    }

                    data.forEach(item => {
                        const puntos = parseFloat(item.Puntos).toFixed(1);

                        switch (item.Trimestre) {
                            case 1:
                                this.PuntosEmpQ1tabla2.push(puntos);
                                Vue.set(employee, 'Trim1', puntos || 0);
                                break;
                            case 2:
                                this.PuntosEmpQ2tabla2.push(puntos);
                                Vue.set(employee, 'Trim2', puntos || 0);
                                break;
                            case 3:
                                this.PuntosEmpQ3tabla2.push(puntos);
                                Vue.set(employee, 'Trim3', puntos || 0);
                                break;
                            case 4:
                                this.PuntosEmpQ4tabla2.push(puntos);
                                Vue.set(employee, 'Trim4', puntos || 0);
                                break;
                            default:
                                console.warn(`Trimestre no válido: ${item.Trimestre}`);
                        }
                    });
                });

                // console.log('Filas actualizadas con valores trimestrales para los empleados sin sistema:', this.employees);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        async getPuntosEmpleados2Nivel3(foliosSinSistema) {
            try {
                // Inicializar las listas trimestrales si no están definidas
                this.PuntosEmpQ1tabla2 ||= [];
                this.PuntosEmpQ2tabla2 ||= [];
                this.PuntosEmpQ3tabla2 ||= [];
                this.PuntosEmpQ4tabla2 ||= [];

                // Crear un Map para búsqueda rápida de empleados por folio
                const employeeMap = new Map(this.employeesNivel3.map(emp => [emp.folio, emp]));

                // Crear todas las solicitudes de datos en paralelo
                const solicitudes = foliosSinSistema.map(id =>
                    axios.get(`${this.$store.getters.getHostNode}/api/puntos2/${id}/${this.anioTabla}`, {
                        headers: { Authorization: localStorage.getItem("token") },
                    }).then(response => ({ id, data: response.data.data }))
                        .catch(error => {
                            console.warn(`Error al obtener puntos para el folio ${id}:`, error);
                            return { id, data: [] };
                        })
                );

                // Esperar a que todas las solicitudes finalicen
                const resultados = await Promise.all(solicitudes);

                // Procesar los datos obtenidos
                resultados.forEach(({ id, data }) => {
                    const employee = employeeMap.get(id);
                    if (!employee) {
                        console.warn(`No se encontró empleado con el folio: ${id}`);
                        return;
                    }

                    data.forEach(item => {
                        const puntos = parseFloat(item.Puntos).toFixed(1);

                        switch (item.Trimestre) {
                            case 1:
                                this.PuntosEmpQ1tabla2.push(puntos);
                                Vue.set(employee, 'Trim1', puntos || 0);
                                break;
                            case 2:
                                this.PuntosEmpQ2tabla2.push(puntos);
                                Vue.set(employee, 'Trim2', puntos || 0);
                                break;
                            case 3:
                                this.PuntosEmpQ3tabla2.push(puntos);
                                Vue.set(employee, 'Trim3', puntos || 0);
                                break;
                            case 4:
                                this.PuntosEmpQ4tabla2.push(puntos);
                                Vue.set(employee, 'Trim4', puntos || 0);
                                break;
                            default:
                                console.warn(`Trimestre no válido: ${item.Trimestre}`);
                        }
                    });
                });

                // console.log('Filas actualizadas con valores trimestrales para los empleados sin sistema:', this.employees);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        async getPuntosEmpleados2Nivel4(foliosSinSistema) {
            try {
                // Inicializar las listas trimestrales si no están definidas
                this.PuntosEmpQ1tabla2 ||= [];
                this.PuntosEmpQ2tabla2 ||= [];
                this.PuntosEmpQ3tabla2 ||= [];
                this.PuntosEmpQ4tabla2 ||= [];

                // Crear un Map para búsqueda rápida de empleados por folio
                const employeeMap = new Map(this.employeesNivel4.map(emp => [emp.folio, emp]));

                // Crear todas las solicitudes de datos en paralelo
                const solicitudes = foliosSinSistema.map(id =>
                    axios.get(`${this.$store.getters.getHostNode}/api/puntos2/${id}/${this.anioTabla}`, {
                        headers: { Authorization: localStorage.getItem("token") },
                    }).then(response => ({ id, data: response.data.data }))
                        .catch(error => {
                            console.warn(`Error al obtener puntos para el folio ${id}:`, error);
                            return { id, data: [] };
                        })
                );

                // Esperar a que todas las solicitudes finalicen
                const resultados = await Promise.all(solicitudes);

                // Procesar los datos obtenidos
                resultados.forEach(({ id, data }) => {
                    const employee = employeeMap.get(id);
                    if (!employee) {
                        console.warn(`No se encontró empleado con el folio: ${id}`);
                        return;
                    }

                    data.forEach(item => {
                        const puntos = parseFloat(item.Puntos).toFixed(1);

                        switch (item.Trimestre) {
                            case 1:
                                this.PuntosEmpQ1tabla2.push(puntos);
                                Vue.set(employee, 'Trim1', puntos || 0);
                                break;
                            case 2:
                                this.PuntosEmpQ2tabla2.push(puntos);
                                Vue.set(employee, 'Trim2', puntos || 0);
                                break;
                            case 3:
                                this.PuntosEmpQ3tabla2.push(puntos);
                                Vue.set(employee, 'Trim3', puntos || 0);
                                break;
                            case 4:
                                this.PuntosEmpQ4tabla2.push(puntos);
                                Vue.set(employee, 'Trim4', puntos || 0);
                                break;
                            default:
                                console.warn(`Trimestre no válido: ${item.Trimestre}`);
                        }
                    });
                });

                // console.log('Filas actualizadas con valores trimestrales para los empleados sin sistema:', this.employees);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        async getPuntosEmpleados2Nivel5(foliosSinSistema) {
            try {
                // Inicializar las listas trimestrales si no están definidas
                this.PuntosEmpQ1tabla2 ||= [];
                this.PuntosEmpQ2tabla2 ||= [];
                this.PuntosEmpQ3tabla2 ||= [];
                this.PuntosEmpQ4tabla2 ||= [];

                // Crear un Map para búsqueda rápida de empleados por folio
                const employeeMap = new Map(this.employeesNivel5.map(emp => [emp.folio, emp]));

                // Crear todas las solicitudes de datos en paralelo
                const solicitudes = foliosSinSistema.map(id =>
                    axios.get(`${this.$store.getters.getHostNode}/api/puntos2/${id}/${this.anioTabla}`, {
                        headers: { Authorization: localStorage.getItem("token") },
                    }).then(response => ({ id, data: response.data.data }))
                        .catch(error => {
                            console.warn(`Error al obtener puntos para el folio ${id}:`, error);
                            return { id, data: [] };
                        })
                );

                // Esperar a que todas las solicitudes finalicen
                const resultados = await Promise.all(solicitudes);

                // Procesar los datos obtenidos
                resultados.forEach(({ id, data }) => {
                    const employee = employeeMap.get(id);
                    if (!employee) {
                        console.warn(`No se encontró empleado con el folio: ${id}`);
                        return;
                    }

                    data.forEach(item => {
                        const puntos = parseFloat(item.Puntos).toFixed(1);

                        switch (item.Trimestre) {
                            case 1:
                                this.PuntosEmpQ1tabla2.push(puntos);
                                Vue.set(employee, 'Trim1', puntos || 0);
                                break;
                            case 2:
                                this.PuntosEmpQ2tabla2.push(puntos);
                                Vue.set(employee, 'Trim2', puntos || 0);
                                break;
                            case 3:
                                this.PuntosEmpQ3tabla2.push(puntos);
                                Vue.set(employee, 'Trim3', puntos || 0);
                                break;
                            case 4:
                                this.PuntosEmpQ4tabla2.push(puntos);
                                Vue.set(employee, 'Trim4', puntos || 0);
                                break;
                            default:
                                console.warn(`Trimestre no válido: ${item.Trimestre}`);
                        }
                    });
                });

                // console.log('Filas actualizadas con valores trimestrales para los empleados sin sistema:', this.employees);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        async getPuntosEmpleados2Nivel6(foliosSinSistema) {
            try {
                // Inicializar las listas trimestrales si no están definidas
                this.PuntosEmpQ1tabla2 ||= [];
                this.PuntosEmpQ2tabla2 ||= [];
                this.PuntosEmpQ3tabla2 ||= [];
                this.PuntosEmpQ4tabla2 ||= [];

                // Crear un Map para búsqueda rápida de empleados por folio
                const employeeMap = new Map(this.employeesNivel6.map(emp => [emp.folio, emp]));

                // Crear todas las solicitudes de datos en paralelo
                const solicitudes = foliosSinSistema.map(id =>
                    axios.get(`${this.$store.getters.getHostNode}/api/puntos2/${id}/${this.anioTabla}`, {
                        headers: { Authorization: localStorage.getItem("token") },
                    }).then(response => ({ id, data: response.data.data }))
                        .catch(error => {
                            console.warn(`Error al obtener puntos para el folio ${id}:`, error);
                            return { id, data: [] };
                        })
                );

                // Esperar a que todas las solicitudes finalicen
                const resultados = await Promise.all(solicitudes);

                // Procesar los datos obtenidos
                resultados.forEach(({ id, data }) => {
                    const employee = employeeMap.get(id);
                    if (!employee) {
                        console.warn(`No se encontró empleado con el folio: ${id}`);
                        return;
                    }

                    data.forEach(item => {
                        const puntos = parseFloat(item.Puntos).toFixed(1);

                        switch (item.Trimestre) {
                            case 1:
                                this.PuntosEmpQ1tabla2.push(puntos);
                                Vue.set(employee, 'Trim1', puntos || 0);
                                break;
                            case 2:
                                this.PuntosEmpQ2tabla2.push(puntos);
                                Vue.set(employee, 'Trim2', puntos || 0);
                                break;
                            case 3:
                                this.PuntosEmpQ3tabla2.push(puntos);
                                Vue.set(employee, 'Trim3', puntos || 0);
                                break;
                            case 4:
                                this.PuntosEmpQ4tabla2.push(puntos);
                                Vue.set(employee, 'Trim4', puntos || 0);
                                break;
                            default:
                                console.warn(`Trimestre no válido: ${item.Trimestre}`);
                        }
                    });
                });

                // console.log('Filas actualizadas con valores trimestrales para los empleados sin sistema:', this.employees);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },





        async getPuntosEmpleados2Mensual(filteredFolioD) {
            try {
                // Inicializar las listas trimestrales si no están definidas
                this.PuntosEmpQ1tabla2M ||= [];
                this.PuntosEmpQ2tabla2M ||= [];
                this.PuntosEmpQ3tabla2M ||= [];
                this.PuntosEmpQ4tabla2M ||= [];

                // Crear un Map para búsqueda rápida de empleados por folio
                const employeeMap = new Map(this.employees.map(emp => [emp.folio, emp]));

                // Crear todas las solicitudes de datos en paralelo
                const solicitudes = filteredFolioD.map(id =>
                    axios.get(`${this.$store.getters.getHostNode}/api/puntos2M/${id}/${this.anioTabla}`, {
                        headers: { Authorization: localStorage.getItem("token") },
                    }).then(response => ({ id, data: response.data.data }))
                        .catch(error => {
                            console.warn(`Error al obtener puntos mensuales para el folio ${id}:`, error);
                            return { id, data: [] };
                        })
                );

                // Esperar a que todas las solicitudes finalicen
                const resultados = await Promise.all(solicitudes);

                // Procesar los datos obtenidos
                resultados.forEach(({ id, data }) => {
                    const employee = employeeMap.get(id);
                    if (!employee) {
                        console.warn(`No se encontró empleado con el folio: ${id}`);
                        return;
                    }

                    data.forEach(item => {
                        const puntos = parseFloat(item.Puntos).toFixed(1);

                        switch (item.Trimestre) {
                            case 1:
                                this.PuntosEmpQ1tabla2M.push(puntos);
                                Vue.set(employee, 'Trim1', puntos || 0);
                                break;
                            case 2:
                                this.PuntosEmpQ2tabla2M.push(puntos);
                                Vue.set(employee, 'Trim2', puntos || 0);
                                break;
                            case 3:
                                this.PuntosEmpQ3tabla2M.push(puntos);
                                Vue.set(employee, 'Trim3', puntos || 0);
                                break;
                            case 4:
                                this.PuntosEmpQ4tabla2M.push(puntos);
                                Vue.set(employee, 'Trim4', puntos || 0);
                                break;
                            default:
                                console.warn(`Trimestre no válido: ${item.Trimestre}`);
                        }
                    });
                });

                // console.log('Filas actualizadas con valores trimestrales para los empleados con sistema:', this.employees);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },


        async getPuntosEmpleados2MensualNivel3(filteredFolioD) {
            try {
                // Inicializar las listas trimestrales si no están definidas
                this.PuntosEmpQ1tabla2M ||= [];
                this.PuntosEmpQ2tabla2M ||= [];
                this.PuntosEmpQ3tabla2M ||= [];
                this.PuntosEmpQ4tabla2M ||= [];

                // Crear un Map para búsqueda rápida de empleados por folio
                const employeeMap = new Map(this.employeesNivel3.map(emp => [emp.folio, emp]));

                // Crear todas las solicitudes de datos en paralelo
                const solicitudes = filteredFolioD.map(id =>
                    axios.get(`${this.$store.getters.getHostNode}/api/puntos2M/${id}/${this.anioTabla}`, {
                        headers: { Authorization: localStorage.getItem("token") },
                    }).then(response => ({ id, data: response.data.data }))
                        .catch(error => {
                            console.warn(`Error al obtener puntos mensuales para el folio ${id}:`, error);
                            return { id, data: [] };
                        })
                );

                // Esperar a que todas las solicitudes finalicen
                const resultados = await Promise.all(solicitudes);

                // Procesar los datos obtenidos
                resultados.forEach(({ id, data }) => {
                    const employee = employeeMap.get(id);
                    if (!employee) {
                        console.warn(`No se encontró empleado con el folio: ${id}`);
                        return;
                    }

                    data.forEach(item => {
                        const puntos = parseFloat(item.Puntos).toFixed(1);

                        switch (item.Trimestre) {
                            case 1:
                                this.PuntosEmpQ1tabla2M.push(puntos);
                                Vue.set(employee, 'Trim1', puntos || 0);
                                break;
                            case 2:
                                this.PuntosEmpQ2tabla2M.push(puntos);
                                Vue.set(employee, 'Trim2', puntos || 0);
                                break;
                            case 3:
                                this.PuntosEmpQ3tabla2M.push(puntos);
                                Vue.set(employee, 'Trim3', puntos || 0);
                                break;
                            case 4:
                                this.PuntosEmpQ4tabla2M.push(puntos);
                                Vue.set(employee, 'Trim4', puntos || 0);
                                break;
                            default:
                                console.warn(`Trimestre no válido: ${item.Trimestre}`);
                        }
                    });
                });

                // console.log('Filas actualizadas con valores trimestrales para los empleados con sistema:', this.employees);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        async getPuntosEmpleados2MensualNivel4(filteredFolioD) {
            try {
                // Inicializar las listas trimestrales si no están definidas
                this.PuntosEmpQ1tabla2M ||= [];
                this.PuntosEmpQ2tabla2M ||= [];
                this.PuntosEmpQ3tabla2M ||= [];
                this.PuntosEmpQ4tabla2M ||= [];

                // Crear un Map para búsqueda rápida de empleados por folio
                const employeeMap = new Map(this.employeesNivel4.map(emp => [emp.folio, emp]));

                // Crear todas las solicitudes de datos en paralelo
                const solicitudes = filteredFolioD.map(id =>
                    axios.get(`${this.$store.getters.getHostNode}/api/puntos2M/${id}/${this.anioTabla}`, {
                        headers: { Authorization: localStorage.getItem("token") },
                    }).then(response => ({ id, data: response.data.data }))
                        .catch(error => {
                            console.warn(`Error al obtener puntos mensuales para el folio ${id}:`, error);
                            return { id, data: [] };
                        })
                );

                // Esperar a que todas las solicitudes finalicen
                const resultados = await Promise.all(solicitudes);

                // Procesar los datos obtenidos
                resultados.forEach(({ id, data }) => {
                    const employee = employeeMap.get(id);
                    if (!employee) {
                        console.warn(`No se encontró empleado con el folio: ${id}`);
                        return;
                    }

                    data.forEach(item => {
                        const puntos = parseFloat(item.Puntos).toFixed(1);

                        switch (item.Trimestre) {
                            case 1:
                                this.PuntosEmpQ1tabla2M.push(puntos);
                                Vue.set(employee, 'Trim1', puntos || 0);
                                break;
                            case 2:
                                this.PuntosEmpQ2tabla2M.push(puntos);
                                Vue.set(employee, 'Trim2', puntos || 0);
                                break;
                            case 3:
                                this.PuntosEmpQ3tabla2M.push(puntos);
                                Vue.set(employee, 'Trim3', puntos || 0);
                                break;
                            case 4:
                                this.PuntosEmpQ4tabla2M.push(puntos);
                                Vue.set(employee, 'Trim4', puntos || 0);
                                break;
                            default:
                                console.warn(`Trimestre no válido: ${item.Trimestre}`);
                        }
                    });
                });

                // console.log('Filas actualizadas con valores trimestrales para los empleados con sistema:', this.employees);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        async getPuntosEmpleados2MensualNivel5(filteredFolioD) {
            try {
                // Inicializar las listas trimestrales si no están definidas
                this.PuntosEmpQ1tabla2M ||= [];
                this.PuntosEmpQ2tabla2M ||= [];
                this.PuntosEmpQ3tabla2M ||= [];
                this.PuntosEmpQ4tabla2M ||= [];

                // Crear un Map para búsqueda rápida de empleados por folio
                const employeeMap = new Map(this.employeesNivel5.map(emp => [emp.folio, emp]));

                // Crear todas las solicitudes de datos en paralelo
                const solicitudes = filteredFolioD.map(id =>
                    axios.get(`${this.$store.getters.getHostNode}/api/puntos2M/${id}/${this.anioTabla}`, {
                        headers: { Authorization: localStorage.getItem("token") },
                    }).then(response => ({ id, data: response.data.data }))
                        .catch(error => {
                            console.warn(`Error al obtener puntos mensuales para el folio ${id}:`, error);
                            return { id, data: [] };
                        })
                );

                // Esperar a que todas las solicitudes finalicen
                const resultados = await Promise.all(solicitudes);

                // Procesar los datos obtenidos
                resultados.forEach(({ id, data }) => {
                    const employee = employeeMap.get(id);
                    if (!employee) {
                        console.warn(`No se encontró empleado con el folio: ${id}`);
                        return;
                    }

                    data.forEach(item => {
                        const puntos = parseFloat(item.Puntos).toFixed(1);

                        switch (item.Trimestre) {
                            case 1:
                                this.PuntosEmpQ1tabla2M.push(puntos);
                                Vue.set(employee, 'Trim1', puntos || 0);
                                break;
                            case 2:
                                this.PuntosEmpQ2tabla2M.push(puntos);
                                Vue.set(employee, 'Trim2', puntos || 0);
                                break;
                            case 3:
                                this.PuntosEmpQ3tabla2M.push(puntos);
                                Vue.set(employee, 'Trim3', puntos || 0);
                                break;
                            case 4:
                                this.PuntosEmpQ4tabla2M.push(puntos);
                                Vue.set(employee, 'Trim4', puntos || 0);
                                break;
                            default:
                                console.warn(`Trimestre no válido: ${item.Trimestre}`);
                        }
                    });
                });

                // console.log('Filas actualizadas con valores trimestrales para los empleados con sistema:', this.employees);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },


        async getPuntosEmpleados2MensualNivel6(filteredFolioD) {
            try {
                // Inicializar las listas trimestrales si no están definidas
                this.PuntosEmpQ1tabla2M ||= [];
                this.PuntosEmpQ2tabla2M ||= [];
                this.PuntosEmpQ3tabla2M ||= [];
                this.PuntosEmpQ4tabla2M ||= [];

                // Crear un Map para búsqueda rápida de empleados por folio
                const employeeMap = new Map(this.employeesNivel6.map(emp => [emp.folio, emp]));

                // Crear todas las solicitudes de datos en paralelo
                const solicitudes = filteredFolioD.map(id =>
                    axios.get(`${this.$store.getters.getHostNode}/api/puntos2M/${id}/${this.anioTabla}`, {
                        headers: { Authorization: localStorage.getItem("token") },
                    }).then(response => ({ id, data: response.data.data }))
                        .catch(error => {
                            console.warn(`Error al obtener puntos mensuales para el folio ${id}:`, error);
                            return { id, data: [] };
                        })
                );

                // Esperar a que todas las solicitudes finalicen
                const resultados = await Promise.all(solicitudes);

                // Procesar los datos obtenidos
                resultados.forEach(({ id, data }) => {
                    const employee = employeeMap.get(id);
                    if (!employee) {
                        console.warn(`No se encontró empleado con el folio: ${id}`);
                        return;
                    }

                    data.forEach(item => {
                        const puntos = parseFloat(item.Puntos).toFixed(1);

                        switch (item.Trimestre) {
                            case 1:
                                this.PuntosEmpQ1tabla2M.push(puntos);
                                Vue.set(employee, 'Trim1', puntos || 0);
                                break;
                            case 2:
                                this.PuntosEmpQ2tabla2M.push(puntos);
                                Vue.set(employee, 'Trim2', puntos || 0);
                                break;
                            case 3:
                                this.PuntosEmpQ3tabla2M.push(puntos);
                                Vue.set(employee, 'Trim3', puntos || 0);
                                break;
                            case 4:
                                this.PuntosEmpQ4tabla2M.push(puntos);
                                Vue.set(employee, 'Trim4', puntos || 0);
                                break;
                            default:
                                console.warn(`Trimestre no válido: ${item.Trimestre}`);
                        }
                    });
                });

                // console.log('Filas actualizadas con valores trimestrales para los empleados con sistema:', this.employees);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

       

         // Método para abrir el explorador de archivos
        abrirExplorador() {
        this.$refs.fileInput.click(); // Activa el input de archivo
        },

        // Método que se ejecuta al seleccionar un archivo
        archivoSeleccionado(event) {
        const file = event.target.files[0]; // Obtiene el archivo seleccionado
        if (file) {
            this.archivo = file; // Asigna el archivo a la variable
            console.log("Archivo seleccionado:", this.archivo.name);
        } else {
            console.log("No se seleccionó ningún archivo.");
        }
        },






        //RECUERDA QUE AQUI ES SI rhPerfirl == ADMING
        async handleExportarClick() {
            this.isLoading = true; // Activa el estado de carga
            try {
                // Llama al método que genera el reporte Excel
                await this.getGenerarReporteExcel();
            } catch (error) {
                console.error('Error al generar el reporte:', error);
            } finally {
                this.isLoading = false; // Desactiva el estado de carga
            }
        },

        // async getGenerarReporteExcel() {
        //     try {
        //         // Llamada a la API para obtener los datos del reporte
        //         const response = await axios.get(`${this.$store.getters.getHostNode}/api/getReporteExcel/${this.trimestreFinal}/${this.anioTabla}`, {
        //             headers: {
        //                 Authorization: localStorage.getItem("token"),
        //             },
        //         });

        //         // Procesar los datos obtenidos
        //         const datosReporte = response.data.data.map(item => ({
        //             Folio: item.Empleado,
        //             Estado: item.Estado,
        //             Trimestre: item.Trimestre,
        //             Nombre: item.Nombre,
        //             Puntos: item.Puntos,
        //             Bono: item.Bono,
        //         }));

        //         // Enviar los datos procesados al método exportarDatos
        //         this.exportarDatos(datosReporte);
        //     } catch (error) {
        //         console.error('Error fetching data:', error);
        //     }
        // },

//         este estaba al final

//         async getGenerarReporteExcel() {
//     try {
//         const axiosInstance = axios.create({
//             timeout: 60000, // Esperar hasta 60 segundos
//         });

//         const response = await axiosInstance.get(
//             `${this.$store.getters.getHostNode}/api/getReporteExcel/${this.trimestreFinal}/${this.anioTabla}`,
//             {
//                 headers: {
//                     Authorization: localStorage.getItem("token"),
//                 },
//             }
//         );

//         const datosReporte = response.data.data.map((item) => ({
//             Folio: item.Empleado,
//             Estado: item.Estado,
//             Trimestre: item.Trimestre,
//             Nombre: item.Nombre,
//             Puntos: item.Puntos,
//             Bono: item.Bono,
//         }));

//         this.exportarDatos(datosReporte);
//     } catch (error) {
//         console.error("Error fetching data:", error);
//     }
// },

async getGenerarReporteExcel() {
    try {
        // Llamada directa al backend para obtener los datos del reporte
        const response = await axios.get(
            `${this.$store.getters.getHostNode}/api/getReporteExcel/${this.trimestreFinal}/${this.anioTabla}`,
            {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
                timeout: 90000, // Ajustar el tiempo de espera según el tiempo que toma el proceso
            }
        );

        if (response.data.status === "READY") {
            // Procesar los datos obtenidos
            const datosReporte = response.data.data.map((item) => ({
                Folio: item.Empleado,
                Estado: item.Estado,
                Trimestre: item.Trimestre,
                Nombre: item.Nombre,
                Puntos: item.Puntos,
                Bono: item.Bono,
            }));

            // Exportar los datos procesados
            this.exportarDatos(datosReporte);
        } else {
            console.error("El reporte no pudo generarse:", response.data.message);
        }
    } catch (error) {
        console.error("Error al obtener el reporte:", error);
    }
},


exportarDatos(datosReporte) {
    const logoPath = require('@/assets/img/grupo_urrea_azul_gris.png'); // Ajusta esta ruta según tu estructura

    // Crear un libro de trabajo
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Reporte de Bono');

    // Convertir la imagen a Base64
    this.loadImageAsBase64(logoPath).then(base64Image => {
        // Cargar la imagen en el workbook
        const imageId = workbook.addImage({
            base64: base64Image,
            extension: 'png',
        });

        // Insertar la imagen en la hoja
        worksheet.addImage(imageId, {
            tl: { col: 1.5, row: 0.5 }, // Top-left: columna y fila
            ext: { width: 450, height: 100 }, // Ancho y alto de la imagen
        });

        // Agregar encabezados personalizados
        worksheet.mergeCells('B2:D2');
        worksheet.mergeCells('B5:D5');
        worksheet.getCell('C7').value = "Reporte de Bono";
        worksheet.getCell('C7').font = { bold: true, size: 14 };
        worksheet.getCell('C8').value = new Date().toLocaleDateString() + " - " + new Date().toLocaleTimeString();

        // Agregar datos al Excel desde la fila 9
        const headerRow = worksheet.addRow(['Folio', 'Estado', 'Trimestre', 'Nombre', 'Puntos', 'Bono']);
        headerRow.font = { bold: true };

        datosReporte.forEach(dato => {
            worksheet.addRow([dato.Folio, dato.Estado, dato.Trimestre, dato.Nombre, dato.Puntos, dato.Bono]);
        });

        // Aplicar estilo a filas donde "Estado" esté vacío, comenzando desde la fila 9
        worksheet.eachRow((row, rowIndex) => {
            if (rowIndex < 9) return; // Omitir las filas anteriores a la fila 9

            const estadoCell = row.getCell(2); // Columna "Estado" es la segunda (B)

            if (!estadoCell.value || estadoCell.value.toString().trim() === '') {
                // Aplicar estilo a las columnas desde A hasta F
                for (let col = 1; col <= 6; col++) {
                    const cell = row.getCell(col);
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FF1E88E5' }, // Azul primario (ARGB: AARRGGBB)
                    };
                    cell.font = {
                        color: { argb: 'FFFFFFFF' }, // Blanco
                        bold: true, // Negritas
                    };
                }
            }
        });

        // Ajustar el ancho de las columnas
        worksheet.columns = [
            { width: 10 }, // Folio
            { width: 35 }, // Estado
            { width: 10 }, // Trimestre
            { width: 30 }, // Nombre
            { width: 10 }, // Puntos
            { width: 10 }, // Bono
        ];

        // Guardar el archivo como un blob
        return workbook.xlsx.writeBuffer();
    }).then(buffer => {
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, 'Reporte de Bono.xlsx');
    }).catch(err => {
        console.error("Error al generar el archivo Excel:", err);
    });
},


// Función para cargar la imagen y convertirla a Base64
loadImageAsBase64(imgPath) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imgPath;
        img.crossOrigin = 'Anonymous'; // Asegúrate de que no haya problemas de CORS
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            resolve(dataURL.split(',')[1]); // Base64 sin el prefijo
        };
        img.onerror = err => reject(err);
    });
},
// Método auxiliar para convertir imágenes a base64
getBase64Image(imgPath) {
    const image = new Image();
    image.src = imgPath;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, 0, 0);
    return canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, '');
},

        async getEmpleadosDir(ID_Resp) {
            try {
                // Realizamos la solicitud GET a la API
                const response = await axios.get(
                    `${this.$store.getters.getHostNode}/api/Empleados-directores/${this.anioTabla}/${this.trimestreFinal}/${ID_Resp}`,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );

                // Verificamos si la respuesta tiene datos válidos
                if (response.data && response.data.data) {
                    const data = response.data.data;

                    // Extraemos todos los datos de una sola vez
                    this.employees = data.map((item) => ({
                        folio: item.EMP_KEYEMP,
                        Nombre: item.EMP_NOMEMP,
                        depto: item.EMP_KEYDEP,
                        descPuesto: item.DEP_DESDEP,
                    }));

                    // Extraemos listas de datos en una sola iteración
                    this.FolioD = [];
                    this.sNombreD = [];
                    this.Puesto = [];
                    this.Id_Depto = [];
                    this.AreaD = [];
                    this.DescPuesto = [];
                    this.iNumArea = [];
                    this.PuestoCoord = [];
                    this.iSistema = [];

                    data.forEach(item => {
                        this.FolioD.push(item.EMP_KEYEMP);
                        this.sNombreD.push(item.EMP_NOMEMP);
                        this.Puesto.push(item.EMP_KEYPUE);
                        this.Id_Depto.push(item.EMP_KEYDEP);
                        this.AreaD.push(item.DEP_DESDEP);
                        this.DescPuesto.push(item.PUE_DESPUE);
                        this.iNumArea.push(item.dep_nu2aux);
                        this.PuestoCoord.push(item.PuestoCoord);
                        this.iSistema.push(item.Sistema);
                    });

                    // Ejecutar getEstatus en paralelo
                    await this.getEstatusTabla2(this.FolioD);
                    // await this.getEstatus(this.FolioD);
                } else {
                    console.error('No se recibieron datos válidos desde la API.');
                }
            } catch (error) {
                console.error('Error al obtener los empleados:', error);
            }
        },

        async getEmpleadosNivel3(ID_Resp) {
           // console.log('valores de ID_Resp', ID_Resp);
            try {
                // Realizamos la solicitud GET a la API
                const response = await axios.get(
                    `${this.$store.getters.getHostNode}/api/Empleados-directores/${this.anioTabla}/${this.trimestreFinal}/${ID_Resp}`,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );

                // Verificamos si la respuesta tiene datos válidos
                if (response.data && response.data.data) {
                    const data = response.data.data;

                    // Extraemos todos los datos de una sola vez
                    this.employeesNivel3 = data.map((item) => ({
                        folio: item.EMP_KEYEMP,
                        Nombre: item.EMP_NOMEMP,
                        depto: item.EMP_KEYDEP,
                        descPuesto: item.DEP_DESDEP,
                    }));

                   // console.log('valores del getEmpleadosNivel3: ', this.employeesNivel3);

                    // Extraemos listas de datos en una sola iteración
                    this.FolioN3 = [];
                    this.sNombreN3 = [];
                    this.PuestoN3 = [];
                    this.Id_DeptoN3 = [];
                    this.AreaN3 = [];
                    this.DescPuestoN3 = [];
                    this.iNumAreaN3 = [];
                    this.PuestoCoordN3 = [];
                    this.iSistemaN3 = [];

                    data.forEach(item => {
                        this.FolioN3.push(item.EMP_KEYEMP);
                        this.sNombreN3.push(item.EMP_NOMEMP);
                        this.PuestoN3.push(item.EMP_KEYPUE);
                        this.Id_DeptoN3.push(item.EMP_KEYDEP);
                        this.AreaN3.push(item.DEP_DESDEP);
                        this.DescPuestoN3.push(item.PUE_DESPUE);
                        this.iNumAreaN3.push(item.dep_nu2aux);
                        this.PuestoCoordN3.push(item.PuestoCoord);
                        this.iSistemaN3.push(item.Sistema);
                    });

                    // Ejecutar getEstatus en paralelo
                    await this.getEstatusTabla3(this.FolioN3);

                } else {
                    console.error('No se recibieron datos válidos desde la API.');
                }
            } catch (error) {
                console.error('Error al obtener los empleados:', error);
            }
        },

        async getEmpleadosNivel4(ID_Resp) {
            try {
                // Realizamos la solicitud GET a la API
                const response = await axios.get(
                    `${this.$store.getters.getHostNode}/api/Empleados-directores/${this.anioTabla}/${this.trimestreFinal}/${ID_Resp}`,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );

                // Verificamos si la respuesta tiene datos válidos
                if (response.data && response.data.data) {
                    const data = response.data.data;

                    // Extraemos todos los datos de una sola vez
                    this.employeesNivel4 = data.map((item) => ({
                        folio: item.EMP_KEYEMP,
                        Nombre: item.EMP_NOMEMP,
                        depto: item.EMP_KEYDEP,
                        descPuesto: item.DEP_DESDEP,
                    }));

                    // Extraemos listas de datos en una sola iteración
                    this.FolioN4 = [];
                    this.sNombreN4 = [];
                    this.PuestoN4 = [];
                    this.Id_DeptoN4 = [];
                    this.AreaN4 = [];
                    this.DescPuestoN4 = [];
                    this.iNumAreaN4 = [];
                    this.PuestoCoordN4 = [];
                    this.iSistemaN4 = [];

                    data.forEach(item => {
                        this.FolioN4.push(item.EMP_KEYEMP);
                        this.sNombreN4.push(item.EMP_NOMEMP);
                        this.PuestoN4.push(item.EMP_KEYPUE);
                        this.Id_DeptoN4.push(item.EMP_KEYDEP);
                        this.AreaN4.push(item.DEP_DESDEP);
                        this.DescPuestoN4.push(item.PUE_DESPUE);
                        this.iNumAreaN4.push(item.dep_nu2aux);
                        this.PuestoCoordN4.push(item.PuestoCoord);
                        this.iSistemaN4.push(item.Sistema);
                    });

                    // Ejecutar getEstatus en paralelo
                    await this.getEstatusTabla4(this.FolioN4);

                } else {
                    console.error('No se recibieron datos válidos desde la API.');
                }
            } catch (error) {
                console.error('Error al obtener los empleados:', error);
            }
        },

        async getEmpleadosNivel5(ID_Resp) {
            try {
                // Realizamos la solicitud GET a la API
                const response = await axios.get(
                    `${this.$store.getters.getHostNode}/api/Empleados-directores/${this.anioTabla}/${this.trimestreFinal}/${ID_Resp}`,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );

                // Verificamos si la respuesta tiene datos válidos
                if (response.data && response.data.data) {
                    const data = response.data.data;

                    // Extraemos todos los datos de una sola vez
                    this.employeesNivel5 = data.map((item) => ({
                        folio: item.EMP_KEYEMP,
                        Nombre: item.EMP_NOMEMP,
                        depto: item.EMP_KEYDEP,
                        descPuesto: item.DEP_DESDEP,
                    }));

                    // Extraemos listas de datos en una sola iteración
                    this.FolioN5 = [];
                    this.sNombreN5 = [];
                    this.PuestoN5 = [];
                    this.Id_DeptoN5 = [];
                    this.AreaN5 = [];
                    this.DescPuestoN5 = [];
                    this.iNumAreaN5 = [];
                    this.PuestoCoordN5 = [];
                    this.iSistemaN5 = [];

                    data.forEach(item => {
                        this.FolioN5.push(item.EMP_KEYEMP);
                        this.sNombreN5.push(item.EMP_NOMEMP);
                        this.PuestoN5.push(item.EMP_KEYPUE);
                        this.Id_DeptoN5.push(item.EMP_KEYDEP);
                        this.AreaN5.push(item.DEP_DESDEP);
                        this.DescPuestoN5.push(item.PUE_DESPUE);
                        this.iNumAreaN5.push(item.dep_nu2aux);
                        this.PuestoCoordN5.push(item.PuestoCoord);
                        this.iSistemaN5.push(item.Sistema);
                    });

                    // Ejecutar getEstatus en paralelo
                    await this.getEstatusTabla5(this.FolioN5);

                } else {
                    console.error('No se recibieron datos válidos desde la API.');
                }
            } catch (error) {
                console.error('Error al obtener los empleados:', error);
            }
        },

        async getEmpleadosNivel6(ID_Resp) {
            try {
                // Realizamos la solicitud GET a la API
                const response = await axios.get(
                    `${this.$store.getters.getHostNode}/api/Empleados-directores/${this.anioTabla}/${this.trimestreFinal}/${ID_Resp}`,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );

                // Verificamos si la respuesta tiene datos válidos
                if (response.data && response.data.data) {
                    const data = response.data.data;

                    // Extraemos todos los datos de una sola vez
                    this.employeesNivel6 = data.map((item) => ({
                        folio: item.EMP_KEYEMP,
                        Nombre: item.EMP_NOMEMP,
                        depto: item.EMP_KEYDEP,
                        descPuesto: item.DEP_DESDEP,
                    }));

                    // Extraemos listas de datos en una sola iteración
                    this.FolioN6 = [];
                    this.sNombreN6 = [];
                    this.PuestoN6 = [];
                    this.Id_DeptoN6 = [];
                    this.AreaN6 = [];
                    this.DescPuestoN6 = [];
                    this.iNumAreaN6 = [];
                    this.PuestoCoordN6 = [];
                    this.iSistemaN6 = [];

                    data.forEach(item => {
                        this.FolioN6.push(item.EMP_KEYEMP);
                        this.sNombreN6.push(item.EMP_NOMEMP);
                        this.PuestoN6.push(item.EMP_KEYPUE);
                        this.Id_DeptoN6.push(item.EMP_KEYDEP);
                        this.AreaN6.push(item.DEP_DESDEP);
                        this.DescPuestoN6.push(item.PUE_DESPUE);
                        this.iNumAreaN6.push(item.dep_nu2aux);
                        this.PuestoCoordN6.push(item.PuestoCoord);
                        this.iSistemaN6.push(item.Sistema);
                    });

                    // Ejecutar getEstatus en paralelo
                    await this.getEstatusTabla6(this.FolioN6);

                } else {
                    console.error('No se recibieron datos válidos desde la API.');
                }
            } catch (error) {
                console.error('Error al obtener los empleados:', error);
            }
        },



        //ESTOS METODOS SON PARA EL TERCER NIVEL
        // Detectar expansión de la fila principal
        handleRowExpand(item, isExpanded) {
            if (isExpanded) {
                // Llamar a la API para cargar colaboradores
                // this.getEmpleadosDirEmp3(item.Nombre); 
                // console.log('HOLIIIIIIIIII111111111111111');
            }
        },
        handleNestedRowExpand(item, isExpanded) {
            if (isExpanded) {
                // console.log('HOLIIIIIIIIIIIIIIIIIIIIIIIIIIII');
                //this.getEmpleadosDirEmp3(item.folio); // Asegúrate de que `item.folio` tiene el valor correcto
            }
        },
        //TABLA 2
        onExpandEmployee(expandedItems) {
            // Aquí expandRow se ejecutará para cada elemento expandido en la tabla de empleados
            expandedItems.forEach((item) => {
                this.expandRow2(item);
            });
        },

        //TABLA 4
        onExpandEmployee4(expandedItems) {
            // Aquí expandRow se ejecutará para cada elemento expandido en la tabla de empleados
            expandedItems.forEach((item) => {
                this.expandRow4(item);
            });
        },


        expandRow(item) {
            const index = this.expanded.indexOf(item);
            if (index === -1) {
                // Si la fila no está expandida, expándela
                this.expanded.push(item);
            } else {
                // Si ya está expandida, colapsa la fila
                this.expanded.splice(index, 1);
            }

            // Aquí puedes agregar cualquier lógica que desees ejecutar cuando se expanda la fila
            //console.log('Fila expandida: ', item.folio);
            //ESTO ESTABA ANTES CUANDO LE DAS CLICK PARA LOS PERSONSAS A EXPANDIR
            //this.getEmpleadosDirEmp3(item.folio);
        },

        //Tabla 2
        expandRow2(item) {
            const index = this.expanded2.indexOf(item);
            if (index === -1) {
                // Si la fila no está expandida, expándela
                this.expanded2.push(item);
            } else {
                // Si ya está expandida, colapsa la fila
                this.expanded2.splice(index, 1);
            }

            // Aquí puedes agregar cualquier lógica que desees ejecutar cuando se expanda la fila
            //console.log('Fila expandida: ', item.folio);
            //ESTO ESTABA ANTES CUANDO LE DAS CLICK PARA LOS PERSONSAS A EXPANDIR
            //this.getEmpleadosDirEmp3(item.folio);
        },
        //Tabla 4
        expandRow4(item) {
            const index = this.expanded4.indexOf(item);
            if (index === -1) {
                // Si la fila no está expandida, expándela
                this.expanded4.push(item);
            } else {
                // Si ya está expandida, colapsa la fila
                this.expanded4.splice(index, 1);
            }

            // Aquí puedes agregar cualquier lógica que desees ejecutar cuando se expanda la fila
            //console.log('Fila expandida: ', item.folio);
            //ESTO ESTABA ANTES CUANDO LE DAS CLICK PARA LOS PERSONSAS A EXPANDIR
            //this.getEmpleadosDirEmp3(item.folio);
        },

        //Tabla 5
        expandRow5(item) {
            const index = this.expanded5.indexOf(item);
            if (index === -1) {
                // Si la fila no está expandida, expándela
                this.expanded5.push(item);
            } else {
                // Si ya está expandida, colapsa la fila
                this.expanded5.splice(index, 1);
            }

            // Aquí puedes agregar cualquier lógica que desees ejecutar cuando se expanda la fila
            //console.log('Fila expandida: ', item.folio);
            //ESTO ESTABA ANTES CUANDO LE DAS CLICK PARA LOS PERSONSAS A EXPANDIR
            //this.getEmpleadosDirEmp3(item.folio);
        },

        //Esto es para la ventana de los trimestres
        cerrarDialog() {
            // Limpiar las variables antes de cerrar el modal
            this.totalPonderacion = 0;
            this.totalPuntos = 0;
            if (this.abortController) {
            this.abortController.abort(); // Cancelar cualquier operación pendiente
        }
            this.dialog = false;
            this.dialogEditarMeses = false;
            this.dialogEditar = false
            this.dialogTrimestres1234 = false;
        },

        cerrarDialog2() {
            // Limpiar las variables antes de cerrar el modal
            // this.resultadosPorArea2 = {}; // Limpiar los datos cuando se cierra el diálogo
            //this.limpiarResultadosPorArea2(); // Llamar a la función para limpiar los datos
            //this.resultadosPorArea2 = {}; // Reinicia por completo el objeto, eliminando todas las áreas
            if (this.abortController) {
            this.abortController.abort(); // Cancelar cualquier operación pendiente
        }
            this.totalPonderacion = 0;
            this.totalPuntos = 0;

            this.dialog2 = false;
            this.dialog23 = false;
            //this.loadingTable = false;
            // this.loadingTabledialog23=false;
        },
        // Método para limpiar los campos de resultadosPorArea2
        limpiarResultadosPorArea2() {
            for (const idArea in this.resultadosPorArea2) {
                if (Object.prototype.hasOwnProperty.call(this.resultadosPorArea2, idArea)) {
                    // Vaciar todos los arrays dentro de cada área
                    this.resultadosPorArea2[idArea].TipoCalculo = [];
                    this.resultadosPorArea2[idArea].Ponderacion = [];
                    this.resultadosPorArea2[idArea].Id_Indicador = [];
                    this.resultadosPorArea2[idArea].mes1 = [];
                    this.resultadosPorArea2[idArea].mes2 = [];
                    this.resultadosPorArea2[idArea].mes3 = [];
                    this.resultadosPorArea2[idArea].LE = [];
                    this.resultadosPorArea2[idArea].VO = [];
                    this.resultadosPorArea2[idArea].ObjetivoEspecifico = [];
                }
            }
        },
        guardarAreas(area, signal) {
            if (signal.aborted) throw new DOMException('Abortado', 'AbortError');
            
            // Inicializamos las variables para almacenar las áreas
            let area1 = '';
            let area2 = '';

            // Verificamos si el área contiene el delimitador ' / '
            if (area.includes(' / ')) {
                const [parte1, parte2] = area.split(' / ');
                area1 = parte1.trim(); // Guardamos la primera parte y eliminamos espacios adicionales
                area2 = parte2.trim(); // Guardamos la segunda parte y eliminamos espacios adicionales
            } else {
                area1 = area.trim(); // Si no hay delimitador, toda el área se guarda en area1
            }

            // Almacenamos los valores en variables globales
            this.area1 = area1;
            this.area2 = area2;

            // console.log('Área 1:', this.area1);
            // console.log('Área 2:', this.area2);
        },

        async openModal(item, trimestre) {
            // Si ya hay un controlador, abortamos las operaciones anteriores
    if (this.abortController) {
        this.abortController.abort();
    }

    // Creamos un nuevo controlador para esta ejecución
    this.abortController = new AbortController();
    const signal = this.abortController.signal;
            try {
                // Verificar si el array tiene uno o más valores
                if (Array.isArray(item.Id_Area) && item.Id_Area.length > 0) {
                    this.Id_Area_DirGlobal = item.Id_Area;

                    // Si el array tiene más de un valor
                    if (item.Id_Area.length > 1) {
                        // this.loadingTable = true;
                        this.loadingTabledialog23 = true;
                        this.dialog2 = true;
                        //console.log('entro donde tiene 2 areas');

                        this.trimestreSeleccionado = trimestre;
                        this.nombreTabla1 = item.Nombre;

                        // Preparar datos y ejecutar métodos
                        this.guardarAreas(item.Area, signal );
                        this.asignarMeses(signal );
                        await this.separarIdAreaDirGlobal(signal );
                        await this.getQuery2TrimTablas(signal );
                        await this.getQuery3MesTrimDir(signal );
                        await this.getQuery4MesTrimTabla(signal );
                        await this.getPuntosTrimMesDir1(signal );
                        await this.getPuntosTrimMesDir2(signal );
                        this.loadingTabledialog23 = false;
                    } else {
                        // Caso donde hay un solo valor en `Id_Area`
                        //console.log('entro donde tiene 1 areas');
                        //this.loadingTable = true;
                        this.loadingTabledialog23 = true;
                        this.dialog23 = true;

                        this.trimestreSeleccionado = trimestre;
                        this.nombreTabla1 = item.Nombre;

                        // Preparar datos y ejecutar métodos
                        this.guardarAreas(item.Area, signal );
                        this.asignarMeses(signal );
                        await this.separarIdAreaDirGlobal(signal );
                        await this.getQuery2TrimTablas(signal );
                        await this.getQuery3MesTrimDir(signal );
                        await this.getQuery4MesTrimTabla(signal );
                        await this.getPuntosTrimMesDir1(signal );
                        this.loadingTabledialog23 = false;
                    }
                } else {
                    // Manejar el caso donde no es un array o está vacío
                    //console.log('caso donde no es un array o está vacío');
                    // this.loadingTable = true;
                    this.loadingTabledialog23 = true;
                    //this.dialog = true;
                    this.dialogTrimestres1234 = true;

                    this.selectedItem = item.folio;
                    this.folioGlobalTabla1Meses = item.Resp;
                    this.trimestreSeleccionado = trimestre;
                    this.NamePerson = item.Nombre;

                    // Calcular folio final
                    let resultado = this.selectedItem !== null && this.selectedItem !== undefined
                        ? this.selectedItem
                        : this.folioGlobalTabla1Meses !== null && this.folioGlobalTabla1Meses !== undefined
                            ? this.folioGlobalTabla1Meses
                            : null;

                    this.folioFinal = resultado;
              
            await this.getValorMesesEmpleadosTrim1234(this.folioFinal, signal);
            this.loadingTabledialog23 = false;
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    console.log('Operación cancelada');
                } else {
                    console.error('Error al procesar:', error);
                }
            } finally {
                this.loadingTabledialog23 = false;
            }
        },



        //Este es para la ventana para ver la calificacion de objetivos de cada persona 

       
        async openModalCalificacion(item) {
    // Si ya hay un controlador, abortamos las operaciones anteriores
    if (this.abortController) {
        this.abortController.abort();
    }

    // Creamos un nuevo controlador para esta ejecución
    this.abortController = new AbortController();
    const signal = this.abortController.signal;

    try {
        if (Array.isArray(item.Id_Area) && item.Id_Area.length > 0) {
            this.Id_Area_DirGlobal = item.Id_Area;

            if (item.Id_Area.length > 1) {
                this.loadingTabledialog23 = true;
                this.dialog2 = true;

                this.trimestreSeleccionado = this.trimestreFinal;
                this.nombreTabla1 = item.Nombre;

                this.guardarAreas(item.Area, signal);
                this.asignarMeses(signal);

                // Métodos que soportan la cancelación
                await this.separarIdAreaDirGlobal(signal);
                await this.getQuery2TrimTablas(signal);
                await this.getQuery3MesTrimDir(signal);
                await this.getQuery4MesTrimTabla(signal);
                 this.getPuntosTrimMesDir1(signal);
                 this.getPuntosTrimMesDir2(signal);

                this.loadingTabledialog23 = false;
            } else {
                this.loadingTabledialog23 = true;
                this.dialog23 = true;

                this.trimestreSeleccionado = this.trimestreFinal;
                this.nombreTabla1 = item.Nombre;

                this.guardarAreas(item.Area, signal);
                this.asignarMeses(signal);

                await this.separarIdAreaDirGlobal(signal);
                await this.getQuery2TrimTablas(signal);
                await this.getQuery3MesTrimDir(signal);
                await this.getQuery4MesTrimTabla(signal);
                this.getPuntosTrimMesDir1(signal);

                this.loadingTabledialog23 = false;
            }
        } else {
            this.loadingTabledialog23 = true;
            this.dialog = true;

            this.selectedItem = item.folio;
            this.folioGlobalTabla1Meses = item.Resp;

            this.trimestreSeleccionado = this.trimestreFinal;
            this.NamePerson = item.Nombre;

            let resultado = this.selectedItem || this.folioGlobalTabla1Meses || null;
            //console.log('valor del folio final:', resultado, this.Id_Area_DirGlobal);
            this.folioFinal = resultado;

            await this.getValorMesesEmpleados(this.folioFinal, signal);
            this.loadingTabledialog23 = false;
        }
    } catch (error) {
        if (error.name === 'AbortError') {
            console.log('Operación cancelada');
        } else {
            console.error('Error al procesar:', error);
        }
    } finally {
        this.loadingTabledialog23 = false;
    }
},
        //Esto es para que cuando el estatus es = 1 HAY QUE REVISARLO
        
        async openModalCalificacionEstatus1(item) {
    // Si ya hay un controlador, abortamos las operaciones anteriores
    if (this.abortController) {
        this.abortController.abort();
    }

    // Creamos un nuevo controlador para esta ejecución
    this.abortController = new AbortController();
    const signal = this.abortController.signal;

    try {
        if (Array.isArray(item.Id_Area) && item.Id_Area.length > 0) {
            this.Id_Area_DirGlobal = item.Id_Area;

            if (item.Id_Area.length > 1) {
                this.loadingTabledialog23 = true;
                this.dialog2 = true;

                this.trimestreSeleccionado = this.trimestreFinal;
                this.nombreTabla1 = item.Nombre;

                this.guardarAreas(item.Area, signal);
                this.asignarMeses(signal);

                // Métodos que soportan la cancelación
                await this.separarIdAreaDirGlobal(signal);
                await this.getQuery2TrimTablas(signal);
                await this.getQuery3MesTrimDir(signal);
                await this.getQuery4MesTrimTabla(signal);
                 this.getPuntosTrimMesDir1(signal);
                 this.getPuntosTrimMesDir2(signal);

                this.loadingTabledialog23 = false;
            } else {
                this.loadingTabledialog23 = true;
                this.dialog23 = true;

                this.trimestreSeleccionado = this.trimestreFinal;
                this.nombreTabla1 = item.Nombre;

                this.guardarAreas(item.Area, signal);
                this.asignarMeses(signal);

                await this.separarIdAreaDirGlobal(signal);
                await this.getQuery2TrimTablas(signal);
                await this.getQuery3MesTrimDir(signal);
                await this.getQuery4MesTrimTabla(signal);
                this.getPuntosTrimMesDir1(signal);

                this.loadingTabledialog23 = false;
            }
        } else {
            this.loadingTabledialog23 = true;
            if( this.banderaChampion === true){
                this.dialogEditarMeses = true;
            }else{
            this.dialog = true;
            }

            this.selectedItem = item.folio;
            this.folioGlobalTabla1Meses = item.Resp;

            this.trimestreSeleccionado = this.trimestreFinal;
            this.NamePerson = item.Nombre;

            let resultado = this.selectedItem || this.folioGlobalTabla1Meses || null;
            //console.log('valor del folio final:', resultado, this.Id_Area_DirGlobal);
            this.folioFinal = resultado;

            await this.getValorMesesEmpleados(this.folioFinal, signal);
            this.loadingTabledialog23 = false;
        }
    } catch (error) {
        if (error.name === 'AbortError') {
            console.log('Operación cancelada');
        } else {
            console.error('Error al procesar:', error);
        }
    } finally {
        this.loadingTabledialog23 = false;
    }
},



        //Esto es para abrir la evaluacion de desempeño
        cerrarVentanaEvaluacion() {
            // Limpiar las variables antes de cerrar el modal
            this.totalPonderacion = 0;
            this.totalPuntos = 0;
            this.dialogEvaluacion = false;

        },

        //ESTA ES PARA ABRIR LA PRIMERA VENTANA DE DESEMPEÑO EN LA PRIMER TABLA

        async openVentanaEvaluacion(item) {
            try {
                this.dialogEvaluacion = true; // Abrir el modal
               // this.loadingTable = true; // Activar el estado de carga
               this.loadingTableDialogEvaluacion= true;

                // Asignar datos del empleado seleccionado
                this.selectEmpleado = item.Resp; // Folio del empleado
                this.nombrePersona = item.Nombre; // Nombre del empleado
                this.folioGlobalTabla1 = this.selectEmpleado; // Folio global para la tabla 1

                // Ejecutar todas las peticiones en paralelo y esperar que todas terminen
                await Promise.all([
                    this.getPerfilColaborador(this.selectEmpleado),
                    this.getEmpleadosValorEva(this.selectEmpleado),
                    this.tablaCultura(this.selectEmpleado),
                ]);
            } catch (error) {
                console.error("Error al cargar los datos:", error);
            } finally {
                // Desactivar el estado de carga, ya sea que todo haya salido bien o no
               // this.loadingTable = false;
               this.loadingTableDialogEvaluacion = false;
            }
        },


        //ESTA ES PARA ABRIR LA PRIMERA VENTANA DE DESEMPEÑO EN LA SEGUNDA TABLA
        async openVentanaEvaluacionTabla2(item) {
            try {
                this.dialogEvaluacion = true; // Abrir el modal
                //this.loadingTable = true; // Activar el estado de carga
                this.loadingTableDialogEvaluacion= true;
                this.selectEmpleado = item.folio; // Folio del empleado seleccionado
                this.folioGlobal = this.selectEmpleado;


                // Ejecutar todas las peticiones en paralelo y esperar que todas terminen
                await Promise.all([
                    this.getPerfilColaborador(this.selectEmpleado),
                    this.getEmpleadosValorEva(this.selectEmpleado),
                    this.tablaCultura(this.selectEmpleado),
                    this.verificarTrimestres(),
                ]);


                //Este es para tener los valores de Desempeño 2011 y los puntos obtenidos

            } catch (error) {
                console.error("Error al cargar los datos:", error);
            } finally {
                // Desactivar el estado de carga, ya sea que todo haya salido bien o no
                //this.loadingTable = false;
                this.loadingTableDialogEvaluacion= false; 
            }
        },



        async getEmpleadosDirEmp3(folio) {
            // console.log('SE EJECUTÓ EL MÉTODO getEmpleadosDirEmp3:', folio);
            // console.log('Se esta ejecuentnad ###############################3')

            try {
                const response = await axios.get(
                    `${this.$store.getters.getHostNode}/api/Empleados-directores/${this.anioTabla}/${this.trimestreFinal}/${folio}`,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );

                if (response.data && response.data.data) {
                    this.FolioDE = response.data.data.map(item => item.EMP_KEYEMP);
                    // console.log('FolioDE:', this.FolioDE);

                    // Ejecutar dBonoColab3 para cada folio en FolioDE
                    for (const folioDE of this.FolioDE) {
                        await this.dBonoColab3([folioDE]);
                    }

                    // Llamar a gerCantEmpTabla3 con los folios obtenidos
                    await this.gerCantEmpTabla3(this.FolioDE);
                } else {
                    console.error('No se recibieron datos válidos desde la API.');
                }

                if (response.data && response.data.data) {
                    const empleadosData = response.data.data.map(item => ({
                        folio3: item.EMP_KEYEMP,
                        Nombre: item.EMP_NOMEMP,
                        depto: item.EMP_KEYDEP,
                        descPuesto: item.DEP_DESDEP,
                    }));

                    this.$set(this.thirdEmployeer, folio, empleadosData);
                    // console.log(`Datos cargados para folio EMP 3: ${folio}`, this.thirdEmployeer[folio]);
                } else {
                    console.error('No se recibieron datos válidos desde la API.');
                }
            } catch (error) {
                console.error('Error al obtener los empleados:', error);
            }
        },





        //Este es para la tabla


        //Este query es para traer los valores de los empleados en los meses
        //Hace falta poner el logo de cargar 
        async getValorMesesEmpleados(signal) {
            if (signal.aborted) throw new DOMException('Abortado', 'AbortError');
            try {
               // console.log('Obteniendo valores de meses de empleados...');

                // Realizamos la solicitud GET a la API
                const response = await axios.get(
                    `${this.$store.getters.getHostNode}/api/EmpleadosValorMesBonos/${this.folioFinal}/${this.anioTabla}/${this.trimestreSeleccionado}`,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );

                // Verificamos si la respuesta tiene datos válidos
                if (response.data && Array.isArray(response.data.data) && response.data.data.length > 0 && response.data.data != []) {
                    //console.log('Datos válidos recibidos desde la API.');

                    // Asignamos los datos a las propiedades correspondientes
                    const data = response.data.data;
                    this.Id_IndicadorMes = data.map(item => item.Id_Indicador);
                    this.VOMes = data.map(item => item.VO);
                    this.LEMes = data.map(item => item.LE);
                    this.DescObjetivoMes = data.map(item => item.DescObjetivo);
                    this.PonderacionMes = data.map(item => item.Ponderacion);
                    this.TipoCalculoMes = data.map(item => item.TipoCalculo);
                    this.ValObjPrimerMes = data.map(item => item.ValorPrimer);
                    this.ValObjSegundoMes = data.map(item => item.ValorSegundo);
                    this.ValObjTercero = data.map(item => item.ValorTercero);
                    this.Estatus = data.map(item => item.Estatus);
                    this.Id_IndicadorMesEditar = this.Id_IndicadorMes;

                    // Verificamos los valores con logs para depuración
                    // console.log('Id_IndicadorMes:', this.Id_IndicadorMes);
                    // console.log('Valor de ValObjPrimerMes:', this.ValObjPrimerMes);
                    // console.log('Valor de ValObjSegundoMes:', this.ValObjSegundoMes);
                    // console.log('Valor de ValObjTercero:', this.ValObjTercero);

                    // // Si hay datos válidos, ejecutamos validacionesTest
                    // console.log('valor de desemp', this.Desemp);
                    //Este es el que estaba antes
                    //await this.validacionesTest();
                    //Este es el nuevo
                    await this.validacionesTestNuevo(signal);
                } else {
                    // Manejo cuando la respuesta no contiene datos
                    //console.log('No se recibieron datos válidos desde la API..');
                    // Vaciar las variables para limpiar datos anteriores
                    this.Id_IndicadorMes = [];
                    this.VOMes = [];
                    this.LEMes = [];
                    this.DescObjetivoMes = [];
                    this.PonderacionMes = [];
                    this.TipoCalculoMes = [];
                    this.ValObjPrimerMes = [];
                    this.ValObjSegundoMes = [];
                    this.ValObjTercero = [];
                    this.Estatus = [];
                    this.Id_IndicadorMesEditar = [];
                }
            } catch (error) {
                console.error('Error al obtener los empleados:', error);
            }
        },

        //Esto es apra los trimestre 1,2,3,4
        // async getValorMesesEmpleadosTrim1234() {
        //     try {
        //         console.log('Obteniendo valores de meses de empleados... getValorMesesEmpleadosTrim1234');

        //         // Realizamos la solicitud GET a la API
        //         const response = await axios.get(
        //             `${this.$store.getters.getHostNode}/api/EmpleadosValorMesBonos/${this.folioFinal}/${this.anioTabla}/${this.trimestreSeleccionado}`,
        //             {
        //                 headers: {
        //                     Authorization: localStorage.getItem("token"),
        //                 },
        //             }
        //         );

        //         // Verificamos si la respuesta tiene datos válidos
        //         if (response.data && Array.isArray(response.data.data) && response.data.data.length > 0 && response.data.data != []) {
        //             console.log('Datos válidos recibidos desde la API.');

        //             // Asignamos los datos a las propiedades correspondientes
        //             const data = response.data.data;
        //             this.Id_IndicadorMes = data.map(item => item.Id_Indicador);
        //             this.VOMes = data.map(item => item.VO);
        //             this.LEMes = data.map(item => item.LE);
        //             this.DescObjetivoMes = data.map(item => item.DescObjetivo);
        //             this.PonderacionMes = data.map(item => item.Ponderacion);
        //             this.TipoCalculoMes = data.map(item => item.TipoCalculo);
        //             this.ValObjPrimerMes = data.map(item => item.ValorPrimer);
        //             this.ValObjSegundoMes = data.map(item => item.ValorSegundo);
        //             this.ValObjTercero = data.map(item => item.ValorTercero);
        //             this.Estatus = data.map(item => item.Estatus);

        //             // Verificamos los valores con logs para depuración
        //             // console.log('Id_IndicadorMes:', this.Id_IndicadorMes);
        //             // console.log('Valor de ValObjPrimerMes:', this.ValObjPrimerMes);
        //             // console.log('Valor de ValObjSegundoMes:', this.ValObjSegundoMes);
        //             // console.log('Valor de ValObjTercero:', this.ValObjTercero);

        //             // // Si hay datos válidos, ejecutamos validacionesTest
        //             // console.log('valor de desemp', this.Desemp);
        //             //Este es el que estaba antes
        //             await this.validacionesTest();
        //             //Este es el nuevo
        //             //await this.validacionesTestNuevo();
        //         } else {
        //             // Manejo cuando la respuesta no contiene datos
        //             console.log('No se recibieron datos válidos desde la API..');
        //         }
        //     } catch (error) {
        //         console.error('Error al obtener los empleados:', error);
        //     }
        // },

        async getValorMesesEmpleadosTrim1234(signal) {
            if (signal.aborted) throw new DOMException('Abortado', 'AbortError');
        try {
           // console.log('Obteniendo valores de meses de empleados... getValorMesesEmpleadosTrim1234');

            // Realizamos la solicitud GET a la API
            const response = await axios.get(
                `${this.$store.getters.getHostNode}/api/EmpleadosValorMesBonos/${this.folioFinal}/${this.anioTabla}/${this.trimestreSeleccionado}`,
                {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                }
            );

            // Verificamos si la respuesta tiene datos válidos
            if (response.data && Array.isArray(response.data.data) && response.data.data.length > 0) {
              //  console.log('Datos válidos recibidos desde la API.');

                // Asignamos los datos a las propiedades correspondientes
                const data = response.data.data;
                this.Id_IndicadorMes = data.map(item => item.Id_Indicador);
                this.VOMes = data.map(item => item.VO);
                this.LEMes = data.map(item => item.LE);
                this.DescObjetivoMes = data.map(item => item.DescObjetivo);
                this.PonderacionMes = data.map(item => item.Ponderacion);
                this.TipoCalculoMes = data.map(item => item.TipoCalculo);
                this.ValObjPrimerMes = data.map(item => item.ValorPrimer);
                this.ValObjSegundoMes = data.map(item => item.ValorSegundo);
                this.ValObjTercero = data.map(item => item.ValorTercero);
                this.Estatus = data.map(item => item.Estatus);

                // Ejecutamos validaciones
                await this.validacionesTest();
            } else {
                // Manejo cuando la respuesta no contiene datos
              //  console.log('No se recibieron datos válidos desde la API.');

                // Limpiamos las propiedades utilizadas en la tabla
                this.Id_IndicadorMes = [];
                this.VOMes = [];
                this.LEMes = [];
                this.DescObjetivoMes = [];
                this.PonderacionMes = [];
                this.TipoCalculoMes = [];
                this.ValObjPrimerMes = [];
                this.ValObjSegundoMes = [];
                this.ValObjTercero = [];
                this.Estatus = [];
                this.totalPonderacion = 0;
                this.totalPuntos = 0;
            }
        } catch (error) {
            console.error('Error al obtener los empleados:', error);

            // Limpiamos las propiedades si ocurre un error
            this.Id_IndicadorMes = [];
            this.VOMes = [];
            this.LEMes = [];
            this.DescObjetivoMes = [];
            this.PonderacionMes = [];
            this.TipoCalculoMes = [];
            this.ValObjPrimerMes = [];
            this.ValObjSegundoMes = [];
            this.ValObjTercero = [];
            this.Estatus = [];
            this.totalPonderacion = 0;
            this.totalPuntos = 0;
        }
    },


      
        async getEstatusTabla2(folios) {
        try {
            const employeeMap = new Map(this.employees.map(emp => [emp.folio, emp]));

            const requests = folios.map(folio =>
                axios
                    .get(
                        `${this.$store.getters.getHostNode}/api/EmpleadosValorMes/${folio}/${this.anioTabla}/${this.trimestreFinal}`,
                        {
                            headers: {
                                Authorization: localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(response => ({ folio, data: response.data?.data || [] }))
                    .catch(error => {
                        console.log(`Error al obtener estatus para el folio ${folio}:`, error);
                        return { folio, data: [] };
                    })
            );
              // Inicializamos estatusGlobal como un mapa para múltiples folios
                // this.estatusGlobal = {};


            const responses = await Promise.all(requests);
            console.log('Respuestas obtenidas:', responses);

            responses.forEach(({ folio, data }) => {
                const employee = employeeMap.get(folio);
                if (!employee) {
                    console.log(`No se encontró empleado con el folio: ${folio}`);
                    return;
                }

                const estatus = data.length > 0 ? Number(data[0].BEN_STATUS) : null;
                const grupoTrabajo = data.length > 0 ? Number(data[0].grupo_Trabajo) : 0;
                const descGrupoTrabajo = data.length > 0 ? data[0].grupo_Trabajo_Desc : "";
                 // Almacenar el estatus en el mapa estatusGlobal por folio
                this.$set(this.estatusGlobal, folio, estatus);

                if (!employee.estatus) {
                    this.$set(employee, 'estatus', estatus);
                    this.$set(employee, 'grupo_Trabajo', grupoTrabajo);
                    this.$set(employee, 'grupo_Trabajo_Desc', descGrupoTrabajo);
                } else {
                    employee.estatus = estatus;
                    employee.grupo_Trabajo = grupoTrabajo;
                    employee.grupo_Trabajo_Desc = descGrupoTrabajo;
                }

                // console.log(
                //     `Folio ${folio} - Estatus asignado: ${estatus}, Grupo Trabajo: ${grupoTrabajo}, Desc Grupo Trabajo: ${descGrupoTrabajo}`
                // );
            });

            //console.log('Empleados con estatus actualizados:', this.employees);

            this.$forceUpdate();
        } catch (error) {
            console.error('Error al obtener los empleados:', error);
        }
    },



        async getEstatusTabla3(folios) {
            try {
                // Crear un Map para búsqueda rápida de empleados por folio
                const employeeMap = new Map(this.employeesNivel3.map(emp => [emp.folio, emp]));

                // Crear todas las solicitudes de datos en paralelo
                const requests = folios.map(folio =>
                    axios
                        .get(
                            `${this.$store.getters.getHostNode}/api/EmpleadosValorMes/${folio}/${this.anioTabla}/${this.trimestreFinal}`,
                            {
                                headers: {
                                    Authorization: localStorage.getItem("token"),
                                },
                            }
                        )
                        .then(response => ({ folio, data: response.data?.data || [] }))
                        .catch(error => {
                            console.warn(`Error al obtener estatus para el folio ${folio}:`, error);
                            return { folio, data: [] }; // Manejar errores devolviendo un array vacío
                        })
                );

                // Esperar todas las solicitudes
                const responses = await Promise.all(requests);
                // Inicializamos estatusGlobal como un mapa para múltiples folios
                // this.estatusGlobal = {};

                // Procesar las respuestas
                responses.forEach(({ folio, data }) => {
                    const employee = employeeMap.get(folio);

                    if (!employee) {
                        console.warn(`No se encontró empleado con el folio: ${folio}`);
                        return;
                    }

                    // Asignar valores con manejo seguro y consistente
                    const estatus = data.length > 0 ? Number(data[0].BEN_STATUS) : null;
                    const grupoTrabajo = data.length > 0 ? Number(data[0].grupo_Trabajo) : 0;
                    const descGrupoTrabajo = data.length > 0 ? data[0].grupo_Trabajo_Desc : "";
                     // Almacenar el estatus en el mapa estatusGlobal por folio
                    this.$set(this.estatusGlobal, folio, estatus);

                    // Actualizar de forma reactiva los campos necesarios
                    this.$set(employee, 'estatus', estatus);
                    this.$set(employee, 'grupo_Trabajo', grupoTrabajo);
                    this.$set(employee, 'grupo_Trabajo_Desc', descGrupoTrabajo);

                    // console.log(
                    //     `Folio ${folio} - Estatus: ${estatus}, Grupo Trabajo: ${grupoTrabajo}, Desc Grupo Trabajo: ${descGrupoTrabajo}`
                    // );
                });

                // Forzar la actualización de la tabla
                this.$forceUpdate();

               // console.log('Empleados Nivel 3 actualizados:', this.employeesNivel3);

            } catch (error) {
                console.error('Error al obtener los empleados:', error);
            }
        },



        async getEstatusTabla4(folios) {
    try {
        // Crear un Map para búsqueda rápida de empleados por folio
        const employeeMap = new Map(this.employeesNivel4.map(emp => [emp.folio, emp]));

        // Crear todas las solicitudes de datos en paralelo
        const requests = folios.map(folio =>
            axios
                .get(
                    `${this.$store.getters.getHostNode}/api/EmpleadosValorMes/${folio}/${this.anioTabla}/${this.trimestreFinal}`,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                )
                .then(response => ({ folio, data: response.data?.data || [] }))
                .catch(error => {
                    console.warn(`Error al obtener estatus para el folio ${folio}:`, error);
                    return { folio, data: [] }; // Manejar errores devolviendo un array vacío
                })
        );

        // Esperar todas las solicitudes
        const responses = await Promise.all(requests);
        // Inicializamos estatusGlobal como un mapa para múltiples folios
        // this.estatusGlobal = {};

        // Procesar los datos obtenidos en todas las respuestas
        responses.forEach(({ folio, data }) => {
            const employee = employeeMap.get(folio);

            if (!employee) {
                console.warn(`No se encontró empleado con el folio: ${folio}`);
                return;
            }

            // Manejo seguro y consistente de los valores obtenidos
            const estatus = data.length > 0 ? Number(data[0].BEN_STATUS) : null;
            const grupoTrabajo = data.length > 0 ? Number(data[0].grupo_Trabajo) : 0;
            const descGrupoTrabajo = data.length > 0 ? data[0].grupo_Trabajo_Desc : "";
            // Almacenar el estatus en el mapa estatusGlobal por folio
            this.$set(this.estatusGlobal, folio, estatus);

            // Actualizar los campos de forma reactiva
            this.$set(employee, 'estatus', estatus);
            this.$set(employee, 'grupo_Trabajo', grupoTrabajo);
            this.$set(employee, 'grupo_Trabajo_Desc', descGrupoTrabajo);

            // console.log(
            //     `Folio ${folio} - Estatus: ${estatus}, Grupo Trabajo: ${grupoTrabajo}, Desc Grupo Trabajo: ${descGrupoTrabajo}`
            // );
        });

        // Forzar la actualización de la tabla
        this.$forceUpdate();

        //console.log('Empleados Nivel 4 actualizados:', this.employeesNivel4);

    } catch (error) {
        console.error('Error al obtener los empleados:', error);
    }
},


        

        async getEstatusTabla5(folios) {
    try {
        // Crear un Map para búsqueda rápida de empleados por folio
        const employeeMap = new Map(this.employeesNivel5.map(emp => [emp.folio, emp]));

        // Crear todas las solicitudes de datos en paralelo
        const requests = folios.map(folio =>
            axios
                .get(
                    `${this.$store.getters.getHostNode}/api/EmpleadosValorMes/${folio}/${this.anioTabla}/${this.trimestreFinal}`,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                )
                .then(response => ({ folio, data: response.data?.data || [] }))
                .catch(error => {
                    console.warn(`Error al obtener estatus para el folio ${folio}:`, error);
                    return { folio, data: [] }; // Manejar errores devolviendo un array vacío
                })
        );

        // Esperar todas las solicitudes
        const responses = await Promise.all(requests);
        // Inicializamos estatusGlobal como un mapa para múltiples folios
        // this.estatusGlobal = {};

        // Procesar los datos obtenidos en todas las respuestas
        responses.forEach(({ folio, data }) => {
            const employee = employeeMap.get(folio);

            if (!employee) {
                console.warn(`No se encontró empleado con el folio: ${folio}`);
                return;
            }

            // Manejo seguro y consistente de los valores obtenidos
            const estatus = data.length > 0 ? Number(data[0].BEN_STATUS) : null;
            const grupoTrabajo = data.length > 0 ? Number(data[0].grupo_Trabajo) : 0;
            const descGrupoTrabajo = data.length > 0 ? data[0].grupo_Trabajo_Desc : "";
            // Almacenar el estatus en el mapa estatusGlobal por folio
            this.$set(this.estatusGlobal, folio, estatus);

            // Actualizar los campos de forma reactiva
            this.$set(employee, 'estatus', estatus);
            this.$set(employee, 'grupo_Trabajo', grupoTrabajo);
            this.$set(employee, 'grupo_Trabajo_Desc', descGrupoTrabajo);

            // console.log(
            //     `Folio ${folio} - Estatus: ${estatus}, Grupo Trabajo: ${grupoTrabajo}, Desc Grupo Trabajo: ${descGrupoTrabajo}`
            // );
        });

        // Forzar la actualización de la tabla
        this.$forceUpdate();

        // console.log('Empleados Nivel 5 actualizados:', this.employeesNivel5);

    } catch (error) {
        console.error('Error al obtener los empleados:', error);
    }
},


       

        async getEstatusTabla6(folios) {
    try {
        // Crear un Map para búsqueda rápida de empleados por folio
        const employeeMap = new Map(this.employeesNivel6.map(emp => [emp.folio, emp]));

        // Crear todas las solicitudes de datos en paralelo
        const requests = folios.map(folio =>
            axios
                .get(
                    `${this.$store.getters.getHostNode}/api/EmpleadosValorMes/${folio}/${this.anioTabla}/${this.trimestreFinal}`,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                )
                .then(response => ({ folio, data: response.data?.data || [] }))
                .catch(error => {
                    console.warn(`Error al obtener estatus para el folio ${folio}:`, error);
                    return { folio, data: [] }; // Manejar errores devolviendo un array vacío
                })
        );

        // Esperar todas las solicitudes
        const responses = await Promise.all(requests);
        // Inicializamos estatusGlobal como un mapa para múltiples folios
        // this.estatusGlobal = {};

        // Procesar los datos obtenidos en todas las respuestas
        responses.forEach(({ folio, data }) => {
            const employee = employeeMap.get(folio);

            if (!employee) {
                console.warn(`No se encontró empleado con el folio: ${folio}`);
                return;
            }

            // Manejo seguro y consistente de los valores obtenidos
            const estatus = data.length > 0 ? Number(data[0].BEN_STATUS) : null;
            const grupoTrabajo = data.length > 0 ? Number(data[0].grupo_Trabajo) : 0;
            const descGrupoTrabajo = data.length > 0 ? data[0].grupo_Trabajo_Desc : "";
            // Almacenar el estatus en el mapa estatusGlobal por folio
            this.$set(this.estatusGlobal, folio, estatus);

            // Actualizar los campos de forma reactiva
            this.$set(employee, 'estatus', estatus);
            this.$set(employee, 'grupo_Trabajo', grupoTrabajo);
            this.$set(employee, 'grupo_Trabajo_Desc', descGrupoTrabajo);

            // console.log(
            //     `Folio ${folio} - Estatus: ${estatus}, Grupo Trabajo: ${grupoTrabajo}, Desc Grupo Trabajo: ${descGrupoTrabajo}`
            // );
        });

        // Forzar la actualización de la tabla
        this.$forceUpdate();

        // console.log('Empleados Nivel 6 actualizados:', this.employeesNivel6);

    } catch (error) {
        console.error('Error al obtener los empleados:', error);
    }
},



        async getEstatus(folios) {
            // console.log('Ejecutando getEstatus con folios:', folios);

            try {
                // Realizar todas las solicitudes en paralelo usando Promise.all
                const requests = folios.map(folio =>
                    axios.get(
                        `${this.$store.getters.getHostNode}/api/EmpleadosValorMes/${folio}/${this.anioTabla}/${this.trimestreFinal}`,
                        {
                            headers: {
                                Authorization: localStorage.getItem("token"),
                            },
                        }
                    ).then(response => ({ folio, data: response.data?.data || [] }))
                        .catch(error => {
                            console.warn(`Error al obtener estatus para el folio ${folio}:`, error);
                            return { folio, data: [] }; // Manejar errores devolviendo un array vacío
                        })
                );

                const responses = await Promise.all(requests);

                // Crear un diccionario de estatus para asignar los valores de manera eficiente
                const estatusMap = {};

                // Procesar los datos obtenidos en todas las respuestas
                responses.forEach(({ folio, data }) => {
                    estatusMap[folio] = data.map(item => item.BEN_STATUS);
                });

                // Asignar el estatus a cada empleado correspondiente
                this.employees.forEach(employee => {
                    if (estatusMap[employee.folio]) {
                        employee.estatus = estatusMap[employee.folio][0]; // Asignar el primer estatus como ejemplo
                    }
                });

                // console.log('Empleados con estatus actualizado:', this.employees);
                this.$forceUpdate(); // Forzar la actualización de la tabla
            } catch (error) {
                console.error('Error al obtener los empleados:', error);
            }
        },



        //Este metodo es para sacar los datos que se necesitan para calculas el bono de lo empleados de la tabla 2



        async getBonoCoord() {
            try {
                // Validación de folios
                if (!this.FolioD || this.FolioD.length === 0) {
                    console.error('No se proporcionaron folios válidos COORDI.');
                    return;
                }

                this.datosPorFolio = {};

                // Ejecutar todas las solicitudes en paralelo usando Promise.all
                const solicitudes = this.FolioD.map(folio =>
                    axios.get(
                        `${this.$store.getters.getHostNode}/api/EmpleadosValorMesBonos/${folio}/${this.anioTabla}/${this.trimestreFinal}`,
                        { headers: { Authorization: localStorage.getItem("token") } }
                    ).then(response => ({
                        folio,
                        data: response.data?.data || []
                    })).catch(error => {
                        console.warn(`Error al obtener datos para folio ${folio}:`, error);
                        return { folio, data: [] };
                    })
                );

                // Esperar todas las respuestas
                const resultados = await Promise.all(solicitudes);

                // Procesar los resultados y almacenar en datosPorFolio
                resultados.forEach(({ folio, data }) => {
                    if (data.length === 0) {
                        console.warn(`No se recibieron datos válidos para el folio ${folio}.`);
                        return;
                    }

                    // Estructurar los datos por folio en una sola pasada
                    this.datosPorFolio[folio] = data.reduce((acc, item) => {
                        acc.Id_IndicadorMes.push(item.Id_Indicador);
                        acc.VOMes.push(item.VO);
                        acc.LEMes.push(item.LE);
                        acc.DescObjetivoMes.push(item.DescObjetivo);
                        acc.PonderacionMes.push(item.Ponderacion);
                        acc.TipoCalculoMes.push(item.TipoCalculo);
                        acc.ValObjPrimerMes.push(item.ValorPrimer);
                        acc.ValObjSegundoMes.push(item.ValorSegundo);
                        acc.ValObjTercero.push(item.ValorTercero);
                        acc.Estatus.push(item.Estatus);
                        return acc;
                    }, {
                        Id_IndicadorMes: [], VOMes: [], LEMes: [], DescObjetivoMes: [],
                        PonderacionMes: [], TipoCalculoMes: [], ValObjPrimerMes: [],
                        ValObjSegundoMes: [], ValObjTercero: [], Estatus: []
                    });
                    // console.log('valor de :datosPorFolio inicila $$$', this.datosPorFolio)
                });

                // Verificar que datosPorFolio esté definido antes de proceder con validaciones
                if (!this.datosPorFolio) {
                    console.error("datosPorFolio no está definido.");
                    return;
                }

                // Ejecutar todas las validaciones y obtener puntos en paralelo
                await Promise.all([
                    ...this.FolioD.map(folio => this.validacionesTestCoordinadores(folio)),
                    this.getPuntosCoordinadores()
                ]);

            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        },

        //Este es para el nivel 3

        async getBonoCoordNivel3() {
            try {
                // Validación de folios
                if (!this.FolioN3 || this.FolioN3.length === 0) {
                    console.error('No se proporcionaron folios válidos COORDI.');
                    return;
                }

                this.datosPorFolio = {};

                // Ejecutar todas las solicitudes en paralelo usando Promise.all
                const solicitudes = this.FolioN3.map(folio =>
                    axios.get(
                        `${this.$store.getters.getHostNode}/api/EmpleadosValorMesBonos/${folio}/${this.anioTabla}/${this.trimestreFinal}`,
                        { headers: { Authorization: localStorage.getItem("token") } }
                    ).then(response => ({
                        folio,
                        data: response.data?.data || []
                    })).catch(error => {
                        console.warn(`Error al obtener datos para folio ${folio}:`, error);
                        return { folio, data: [] };
                    })
                );

                // Esperar todas las respuestas
                const resultados = await Promise.all(solicitudes);

                // Procesar los resultados y almacenar en datosPorFolio
                resultados.forEach(({ folio, data }) => {
                    if (data.length === 0) {
                        console.warn(`No se recibieron datos válidos para el folio ${folio}.`);
                        return;
                    }

                    // Estructurar los datos por folio en una sola pasada
                    this.datosPorFolio[folio] = data.reduce((acc, item) => {
                        acc.Id_IndicadorMes.push(item.Id_Indicador);
                        acc.VOMes.push(item.VO);
                        acc.LEMes.push(item.LE);
                        acc.DescObjetivoMes.push(item.DescObjetivo);
                        acc.PonderacionMes.push(item.Ponderacion);
                        acc.TipoCalculoMes.push(item.TipoCalculo);
                        acc.ValObjPrimerMes.push(item.ValorPrimer);
                        acc.ValObjSegundoMes.push(item.ValorSegundo);
                        acc.ValObjTercero.push(item.ValorTercero);
                        acc.Estatus.push(item.Estatus);
                        return acc;
                    }, {
                        Id_IndicadorMes: [], VOMes: [], LEMes: [], DescObjetivoMes: [],
                        PonderacionMes: [], TipoCalculoMes: [], ValObjPrimerMes: [],
                        ValObjSegundoMes: [], ValObjTercero: [], Estatus: []
                    });
                    //console.log('valor de :datosPorFolio inicila $$$', this.datosPorFolio)
                });

                // Verificar que datosPorFolio esté definido antes de proceder con validaciones
                if (!this.datosPorFolio) {
                    console.error("datosPorFolio no está definido.");
                    return;
                }

                // Ejecutar todas las validaciones y obtener puntos en paralelo
                await Promise.all([
                    ...this.FolioN3.map(folio => this.validacionesTestCoordinadores(folio)),
                    this.getPuntosCoordinadoresNivel3()
                ]);

            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        },

        async getBonoCoordNivel3_Resp() {
            try {
                // Validación de folios
                if (!this.FolioD || this.FolioD.length === 0) {
                    console.error('No se proporcionaron folios válidos COORDI.');
                    return;
                }

                this.datosPorFolio = {};

                // Ejecutar todas las solicitudes en paralelo usando Promise.all
                const solicitudes = this.FolioD.map(folio =>
                    axios.get(
                        `${this.$store.getters.getHostNode}/api/EmpleadosValorMesBonos/${folio}/${this.anioTabla}/${this.trimestreFinal}`,
                        { headers: { Authorization: localStorage.getItem("token") } }
                    ).then(response => ({
                        folio,
                        data: response.data?.data || []
                    })).catch(error => {
                        console.warn(`Error al obtener datos para folio ${folio}:`, error);
                        return { folio, data: [] };
                    })
                );

                // Esperar todas las respuestas
                const resultados = await Promise.all(solicitudes);

                // Procesar los resultados y almacenar en datosPorFolio
                resultados.forEach(({ folio, data }) => {
                    if (data.length === 0) {
                        console.warn(`No se recibieron datos válidos para el folio ${folio}.`);
                        return;
                    }

                    // Estructurar los datos por folio en una sola pasada
                    this.datosPorFolio[folio] = data.reduce((acc, item) => {
                        acc.Id_IndicadorMes.push(item.Id_Indicador);
                        acc.VOMes.push(item.VO);
                        acc.LEMes.push(item.LE);
                        acc.DescObjetivoMes.push(item.DescObjetivo);
                        acc.PonderacionMes.push(item.Ponderacion);
                        acc.TipoCalculoMes.push(item.TipoCalculo);
                        acc.ValObjPrimerMes.push(item.ValorPrimer);
                        acc.ValObjSegundoMes.push(item.ValorSegundo);
                        acc.ValObjTercero.push(item.ValorTercero);
                        acc.Estatus.push(item.Estatus);
                        return acc;
                    }, {
                        Id_IndicadorMes: [], VOMes: [], LEMes: [], DescObjetivoMes: [],
                        PonderacionMes: [], TipoCalculoMes: [], ValObjPrimerMes: [],
                        ValObjSegundoMes: [], ValObjTercero: [], Estatus: []
                    });
                    //console.log('valor de :datosPorFolio inicila $$$', this.datosPorFolio)
                });

                // Verificar que datosPorFolio esté definido antes de proceder con validaciones
                if (!this.datosPorFolio) {
                    console.error("datosPorFolio no está definido.");
                    return;
                }


                await this.getGenerarReporteTabla();
                await this.getPuntosDirFinalAlfonso_Resp();
                await this.getPuntosDirFinal_Resp(this.iAreaValorBase);
                await this.trimestresPuntosColabFinal_Resp();


                // Ejecutar todas las validaciones y obtener puntos en paralelo
                await Promise.all([
                    ...this.FolioD.map(folio => this.validacionesTestCoordinadores(folio)),
                    this.getPuntosCoordinadoresNivel3_Resp()
                ]);

            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        },

        async getBonoCoordNivel3_Resp_Expand2() {
            try {
                // Validación de folios
                if (!this.FolioN3 || this.FolioN3.length === 0) {
                    console.error('No se proporcionaron folios válidos COORDI.');
                    return;
                }

                this.datosPorFolio = {};

                // Ejecutar todas las solicitudes en paralelo usando Promise.all
                const solicitudes = this.FolioN3.map(folio =>
                    axios.get(
                        `${this.$store.getters.getHostNode}/api/EmpleadosValorMesBonos/${folio}/${this.anioTabla}/${this.trimestreFinal}`,
                        { headers: { Authorization: localStorage.getItem("token") } }
                    ).then(response => ({
                        folio,
                        data: response.data?.data || []
                    })).catch(error => {
                        console.warn(`Error al obtener datos para folio ${folio}:`, error);
                        return { folio, data: [] };
                    })
                );

                // Esperar todas las respuestas
                const resultados = await Promise.all(solicitudes);

                // Procesar los resultados y almacenar en datosPorFolio
                resultados.forEach(({ folio, data }) => {
                    if (data.length === 0) {
                        console.warn(`No se recibieron datos válidos para el folio ${folio}.`);
                        return;
                    }

                    // Estructurar los datos por folio en una sola pasada
                    this.datosPorFolio[folio] = data.reduce((acc, item) => {
                        acc.Id_IndicadorMes.push(item.Id_Indicador);
                        acc.VOMes.push(item.VO);
                        acc.LEMes.push(item.LE);
                        acc.DescObjetivoMes.push(item.DescObjetivo);
                        acc.PonderacionMes.push(item.Ponderacion);
                        acc.TipoCalculoMes.push(item.TipoCalculo);
                        acc.ValObjPrimerMes.push(item.ValorPrimer);
                        acc.ValObjSegundoMes.push(item.ValorSegundo);
                        acc.ValObjTercero.push(item.ValorTercero);
                        acc.Estatus.push(item.Estatus);
                        return acc;
                    }, {
                        Id_IndicadorMes: [], VOMes: [], LEMes: [], DescObjetivoMes: [],
                        PonderacionMes: [], TipoCalculoMes: [], ValObjPrimerMes: [],
                        ValObjSegundoMes: [], ValObjTercero: [], Estatus: []
                    });
                    //console.log('valor de :datosPorFolio inicila $$$', this.datosPorFolio)
                });

                // Verificar que datosPorFolio esté definido antes de proceder con validaciones
                if (!this.datosPorFolio) {
                    console.error("datosPorFolio no está definido.");
                    return;
                }


                // await this.getGenerarReporteTabla();
                // await this.getPuntosDirFinalAlfonso_Resp();
                // await this.getPuntosDirFinal_Resp(this.iAreaValorBase);
                // await this.trimestresPuntosColabFinal_Resp();


                // Ejecutar todas las validaciones y obtener puntos en paralelo
                await Promise.all([
                    ...this.FolioN3.map(folio => this.validacionesTestCoordinadores(folio)),
                    this.getPuntosCoordinadoresNivel3_Resp_Expand()
                ]);

            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        },

        async getGenerarReporteTabla() {
            try {
                // Llamada a la API para obtener los datos del reporte
                await axios.get(`${this.$store.getters.getHostNode}/api/getReporteExcel/${this.trimestreFinal}/${this.anioTabla}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

               
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        async getPuntosDirFinal_Resp(AreaDir) {
            try {
                // Llamada a la API para obtener los datos del reporte
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/trimestresPuntosDirFinal/${AreaDir}/${this.trimestreFinal}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                // this.PuntosDIr_Final = response.data.data.map(item => item.Puntos);
                // console.log('puntos de los dir', this.PuntosDIr_Final);

                this.PuntosDIr_Final = response.data.data.length === 1 ? response.data.data[0].Puntos : null;
                //console.log('Punto único de los dir:', this.PuntosDIr_Final);


               
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        async getPuntosDirFinalAlfonso_Resp() {
            try {
                // Llamada a la API para obtener los datos del reporte
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/trimestresPuntosDirFinal/0/${this.trimestreFinal}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                this.PuntosDIr_Final_ALF = response.data.data.length === 1 ? response.data.data[0].Puntos : null;
                //console.log('Punto único de los ALFONSO:', this.PuntosDIr_Final_ALF);


               
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        async trimestresPuntosColabFinal_Resp() {
            try {
                // Llamada a la API para obtener los datos del reporte
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/trimestresPuntosColabFinal/${this.numEmpleado}/${this.anioTabla}/${this.trimestreFinal}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                this.PuntosColab_Final = response.data.data.length === 1 ? response.data.data[0].Puntos : null;
                //his.PuntosColab_Final = response.data.data.map(item => item.Puntos);
                //console.log('puntos de los colab', this.PuntosColab_Final);

               
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        //Este es para el nivel 4

        async getBonoCoordNivel4() {
            try {
                // Validación de folios
                if (!this.FolioN4 || this.FolioN4.length === 0) {
                    console.error('No se proporcionaron folios válidos COORDI.');
                    return;
                }

                this.datosPorFolio = {};

                // Ejecutar todas las solicitudes en paralelo usando Promise.all
                const solicitudes = this.FolioN4.map(folio =>
                    axios.get(
                        `${this.$store.getters.getHostNode}/api/EmpleadosValorMesBonos/${folio}/${this.anioTabla}/${this.trimestreFinal}`,
                        { headers: { Authorization: localStorage.getItem("token") } }
                    ).then(response => ({
                        folio,
                        data: response.data?.data || []
                    })).catch(error => {
                        console.warn(`Error al obtener datos para folio ${folio}:`, error);
                        return { folio, data: [] };
                    })
                );

                // Esperar todas las respuestas
                const resultados = await Promise.all(solicitudes);

                // Procesar los resultados y almacenar en datosPorFolio
                resultados.forEach(({ folio, data }) => {
                    if (data.length === 0) {
                        console.warn(`No se recibieron datos válidos para el folio ${folio}.`);
                        return;
                    }

                    // Estructurar los datos por folio en una sola pasada
                    this.datosPorFolio[folio] = data.reduce((acc, item) => {
                        acc.Id_IndicadorMes.push(item.Id_Indicador);
                        acc.VOMes.push(item.VO);
                        acc.LEMes.push(item.LE);
                        acc.DescObjetivoMes.push(item.DescObjetivo);
                        acc.PonderacionMes.push(item.Ponderacion);
                        acc.TipoCalculoMes.push(item.TipoCalculo);
                        acc.ValObjPrimerMes.push(item.ValorPrimer);
                        acc.ValObjSegundoMes.push(item.ValorSegundo);
                        acc.ValObjTercero.push(item.ValorTercero);
                        acc.Estatus.push(item.Estatus);
                        return acc;
                    }, {
                        Id_IndicadorMes: [], VOMes: [], LEMes: [], DescObjetivoMes: [],
                        PonderacionMes: [], TipoCalculoMes: [], ValObjPrimerMes: [],
                        ValObjSegundoMes: [], ValObjTercero: [], Estatus: []
                    });
                    // console.log('valor de :datosPorFolio inicila $$$', this.datosPorFolio)
                });

                // Verificar que datosPorFolio esté definido antes de proceder con validaciones
                if (!this.datosPorFolio) {
                    console.error("datosPorFolio no está definido.");
                    return;
                }

                // Ejecutar todas las validaciones y obtener puntos en paralelo
                await Promise.all([
                    ...this.FolioN4.map(folio => this.validacionesTestCoordinadores(folio)),
                    this.getPuntosCoordinadoresNivel4()
                ]);

            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        },

        async getBonoCoordNivel4_Resp() {
            try {
                // Validación de folios
                if (!this.FolioN4 || this.FolioN4.length === 0) {
                    console.error('No se proporcionaron folios válidos COORDI.');
                    return;
                }

                this.datosPorFolio = {};

                // Ejecutar todas las solicitudes en paralelo usando Promise.all
                const solicitudes = this.FolioN4.map(folio =>
                    axios.get(
                        `${this.$store.getters.getHostNode}/api/EmpleadosValorMesBonos/${folio}/${this.anioTabla}/${this.trimestreFinal}`,
                        { headers: { Authorization: localStorage.getItem("token") } }
                    ).then(response => ({
                        folio,
                        data: response.data?.data || []
                    })).catch(error => {
                        console.warn(`Error al obtener datos para folio ${folio}:`, error);
                        return { folio, data: [] };
                    })
                );

                // Esperar todas las respuestas
                const resultados = await Promise.all(solicitudes);

                // Procesar los resultados y almacenar en datosPorFolio
                resultados.forEach(({ folio, data }) => {
                    if (data.length === 0) {
                        console.warn(`No se recibieron datos válidos para el folio ${folio}.`);
                        return;
                    }

                    // Estructurar los datos por folio en una sola pasada
                    this.datosPorFolio[folio] = data.reduce((acc, item) => {
                        acc.Id_IndicadorMes.push(item.Id_Indicador);
                        acc.VOMes.push(item.VO);
                        acc.LEMes.push(item.LE);
                        acc.DescObjetivoMes.push(item.DescObjetivo);
                        acc.PonderacionMes.push(item.Ponderacion);
                        acc.TipoCalculoMes.push(item.TipoCalculo);
                        acc.ValObjPrimerMes.push(item.ValorPrimer);
                        acc.ValObjSegundoMes.push(item.ValorSegundo);
                        acc.ValObjTercero.push(item.ValorTercero);
                        acc.Estatus.push(item.Estatus);
                        return acc;
                    }, {
                        Id_IndicadorMes: [], VOMes: [], LEMes: [], DescObjetivoMes: [],
                        PonderacionMes: [], TipoCalculoMes: [], ValObjPrimerMes: [],
                        ValObjSegundoMes: [], ValObjTercero: [], Estatus: []
                    });
                    // console.log('valor de :datosPorFolio inicila $$$', this.datosPorFolio)
                });

                // Verificar que datosPorFolio esté definido antes de proceder con validaciones
                if (!this.datosPorFolio) {
                    console.error("datosPorFolio no está definido.");
                    return;
                }

                // Ejecutar todas las validaciones y obtener puntos en paralelo
                await Promise.all([
                    ...this.FolioN4.map(folio => this.validacionesTestCoordinadores(folio)),
                    this.getPuntosCoordinadoresNivel4_Resp()
                ]);

            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        },

        async getBonoCoordNivel5() {
            try {
                // Validación de folios
                if (!this.FolioN5 || this.FolioN5.length === 0) {
                    console.error('No se proporcionaron folios válidos COORDI.');
                    return;
                }

                this.datosPorFolio = {};

                // Ejecutar todas las solicitudes en paralelo usando Promise.all
                const solicitudes = this.FolioN5.map(folio =>
                    axios.get(
                        `${this.$store.getters.getHostNode}/api/EmpleadosValorMesBonos/${folio}/${this.anioTabla}/${this.trimestreFinal}`,
                        { headers: { Authorization: localStorage.getItem("token") } }
                    ).then(response => ({
                        folio,
                        data: response.data?.data || []
                    })).catch(error => {
                        console.warn(`Error al obtener datos para folio ${folio}:`, error);
                        return { folio, data: [] };
                    })
                );

                // Esperar todas las respuestas
                const resultados = await Promise.all(solicitudes);

                // Procesar los resultados y almacenar en datosPorFolio
                resultados.forEach(({ folio, data }) => {
                    if (data.length === 0) {
                        console.warn(`No se recibieron datos válidos para el folio ${folio}.`);
                        return;
                    }

                    // Estructurar los datos por folio en una sola pasada
                    this.datosPorFolio[folio] = data.reduce((acc, item) => {
                        acc.Id_IndicadorMes.push(item.Id_Indicador);
                        acc.VOMes.push(item.VO);
                        acc.LEMes.push(item.LE);
                        acc.DescObjetivoMes.push(item.DescObjetivo);
                        acc.PonderacionMes.push(item.Ponderacion);
                        acc.TipoCalculoMes.push(item.TipoCalculo);
                        acc.ValObjPrimerMes.push(item.ValorPrimer);
                        acc.ValObjSegundoMes.push(item.ValorSegundo);
                        acc.ValObjTercero.push(item.ValorTercero);
                        acc.Estatus.push(item.Estatus);
                        return acc;
                    }, {
                        Id_IndicadorMes: [], VOMes: [], LEMes: [], DescObjetivoMes: [],
                        PonderacionMes: [], TipoCalculoMes: [], ValObjPrimerMes: [],
                        ValObjSegundoMes: [], ValObjTercero: [], Estatus: []
                    });
                    //console.log('valor de :datosPorFolio inicila $$$', this.datosPorFolio)
                });

                // Verificar que datosPorFolio esté definido antes de proceder con validaciones
                if (!this.datosPorFolio) {
                    console.error("datosPorFolio no está definido.");
                    return;
                }

                // Ejecutar todas las validaciones y obtener puntos en paralelo
                await Promise.all([
                    ...this.FolioN5.map(folio => this.validacionesTestCoordinadores(folio)),
                    this.getPuntosCoordinadoresNivel5()
                ]);

            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        },

        async getBonoCoordNivel5_Resp_Expand() {
            try {
                // Validación de folios
                if (!this.FolioN5 || this.FolioN5.length === 0) {
                    console.error('No se proporcionaron folios válidos COORDI.');
                    return;
                }

                this.datosPorFolio = {};

                // Ejecutar todas las solicitudes en paralelo usando Promise.all
                const solicitudes = this.FolioN5.map(folio =>
                    axios.get(
                        `${this.$store.getters.getHostNode}/api/EmpleadosValorMesBonos/${folio}/${this.anioTabla}/${this.trimestreFinal}`,
                        { headers: { Authorization: localStorage.getItem("token") } }
                    ).then(response => ({
                        folio,
                        data: response.data?.data || []
                    })).catch(error => {
                        console.warn(`Error al obtener datos para folio ${folio}:`, error);
                        return { folio, data: [] };
                    })
                );

                // Esperar todas las respuestas
                const resultados = await Promise.all(solicitudes);

                // Procesar los resultados y almacenar en datosPorFolio
                resultados.forEach(({ folio, data }) => {
                    if (data.length === 0) {
                        console.warn(`No se recibieron datos válidos para el folio ${folio}.`);
                        return;
                    }

                    // Estructurar los datos por folio en una sola pasada
                    this.datosPorFolio[folio] = data.reduce((acc, item) => {
                        acc.Id_IndicadorMes.push(item.Id_Indicador);
                        acc.VOMes.push(item.VO);
                        acc.LEMes.push(item.LE);
                        acc.DescObjetivoMes.push(item.DescObjetivo);
                        acc.PonderacionMes.push(item.Ponderacion);
                        acc.TipoCalculoMes.push(item.TipoCalculo);
                        acc.ValObjPrimerMes.push(item.ValorPrimer);
                        acc.ValObjSegundoMes.push(item.ValorSegundo);
                        acc.ValObjTercero.push(item.ValorTercero);
                        acc.Estatus.push(item.Estatus);
                        return acc;
                    }, {
                        Id_IndicadorMes: [], VOMes: [], LEMes: [], DescObjetivoMes: [],
                        PonderacionMes: [], TipoCalculoMes: [], ValObjPrimerMes: [],
                        ValObjSegundoMes: [], ValObjTercero: [], Estatus: []
                    });
                    //console.log('valor de :datosPorFolio inicila $$$', this.datosPorFolio)
                });

                // Verificar que datosPorFolio esté definido antes de proceder con validaciones
                if (!this.datosPorFolio) {
                    console.error("datosPorFolio no está definido.");
                    return;
                }

                // Ejecutar todas las validaciones y obtener puntos en paralelo
                await Promise.all([
                    ...this.FolioN5.map(folio => this.validacionesTestCoordinadores(folio)),
                    this.getPuntosCoordinadoresNivel5_Resp()
                ]);

            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        },


        async getBonoCoordNivel6() {
            try {
                // Validación de folios
                if (!this.FolioN6 || this.FolioN6.length === 0) {
                    console.error('No se proporcionaron folios válidos COORDI.');
                    return;
                }

                this.datosPorFolio = {};

                // Ejecutar todas las solicitudes en paralelo usando Promise.all
                const solicitudes = this.FolioN6.map(folio =>
                    axios.get(
                        `${this.$store.getters.getHostNode}/api/EmpleadosValorMesBonos/${folio}/${this.anioTabla}/${this.trimestreFinal}`,
                        { headers: { Authorization: localStorage.getItem("token") } }
                    ).then(response => ({
                        folio,
                        data: response.data?.data || []
                    })).catch(error => {
                        console.warn(`Error al obtener datos para folio ${folio}:`, error);
                        return { folio, data: [] };
                    })
                );

                // Esperar todas las respuestas
                const resultados = await Promise.all(solicitudes);

                // Procesar los resultados y almacenar en datosPorFolio
                resultados.forEach(({ folio, data }) => {
                    if (data.length === 0) {
                        console.warn(`No se recibieron datos válidos para el folio ${folio}.`);
                        return;
                    }

                    // Estructurar los datos por folio en una sola pasada
                    this.datosPorFolio[folio] = data.reduce((acc, item) => {
                        acc.Id_IndicadorMes.push(item.Id_Indicador);
                        acc.VOMes.push(item.VO);
                        acc.LEMes.push(item.LE);
                        acc.DescObjetivoMes.push(item.DescObjetivo);
                        acc.PonderacionMes.push(item.Ponderacion);
                        acc.TipoCalculoMes.push(item.TipoCalculo);
                        acc.ValObjPrimerMes.push(item.ValorPrimer);
                        acc.ValObjSegundoMes.push(item.ValorSegundo);
                        acc.ValObjTercero.push(item.ValorTercero);
                        acc.Estatus.push(item.Estatus);
                        return acc;
                    }, {
                        Id_IndicadorMes: [], VOMes: [], LEMes: [], DescObjetivoMes: [],
                        PonderacionMes: [], TipoCalculoMes: [], ValObjPrimerMes: [],
                        ValObjSegundoMes: [], ValObjTercero: [], Estatus: []
                    });
                    // console.log('valor de :datosPorFolio inicila $$$', this.datosPorFolio)
                });

                // Verificar que datosPorFolio esté definido antes de proceder con validaciones
                if (!this.datosPorFolio) {
                    console.error("datosPorFolio no está definido.");
                    return;
                }

                // Ejecutar todas las validaciones y obtener puntos en paralelo
                await Promise.all([
                    ...this.FolioN6.map(folio => this.validacionesTestCoordinadores(folio)),
                    this.getPuntosCoordinadoresNivel6()
                ]);

            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        },



        //Este metodo es para sacar los datos que se necesitan para calculas el bono de lo empleados de la tabla 3

        async dBonoColab3(folios) {
            // console.log('Valor de folios seleccionados:', folios, 'Trimestre:', this.trimestreFinal);

            // Inicializamos datosColaboradores si no existe
            if (!this.datosColaboradores) {
                this.datosColaboradores = {};
            }

            if (!folios || folios.length === 0) {
                console.error('No se proporcionaron folios válidos COLABORADOR.');
                return;
            }

            try {
                for (const folio of folios) {
                    const response = await axios.get(
                        `${this.$store.getters.getHostNode}/api/EmpleadosValorMesBonos/${folio}/${this.anioTabla}/${this.trimestreFinal}`,
                        { headers: { Authorization: localStorage.getItem("token") } }
                    );

                    if (response.data && response.data.data) {
                        this.datosColaboradores[folio] = {
                            Id_IndicadorMes3: response.data.data.map(item => item.Id_Indicador),
                            VOMes: response.data.data.map(item => item.VO),
                            LEMes: response.data.data.map(item => item.LE),
                            DescObjetivoMes: response.data.data.map(item => item.DescObjetivo),
                            PonderacionMes: response.data.data.map(item => item.Ponderacion),
                            TipoCalculoMes: response.data.data.map(item => item.TipoCalculo),
                            ValObjPrimerMes: response.data.data.map(item => item.ValorPrimer),
                            ValObjSegundoMes: response.data.data.map(item => item.ValorSegundo),
                            ValObjTercero: response.data.data.map(item => item.ValorTercero),
                            Estatus: response.data.data.map(item => item.Estatus),
                        };

                        // console.log(`Datos para folio COLABORADOR ${folio}:`, this.datosColaboradores[folio]);

                        await this.validacionesTestColab(folio);
                    } else {
                        console.warn(`No se recibieron datos válidos para el folio ${folio}.`);
                    }
                }

                // console.log('Ejecutando métodos para los puntos COLABORADOR... esto creo que es apra la tabla 3');
                //await this.getPuntosColaboradores();

            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        },




        //Este metodo es para ver los resul, desemp, puntos de cada empleado

        //Este es el da las validaciones de la tabla 1

        async validacionesTabla1() {
            function segundoIF(valor) {
                return valor === null || valor === "Promedio" || valor === "";
            }

            // Asegúrate de que todos los valores estén definidos y sean válidos
            this.ValObjPrimerMes = this.ValObjPrimerMes.map(val => val === "" ? 0 : val);
            this.ValObjSegundoMes = this.ValObjSegundoMes.map(val => val === "" ? 0 : val);
            this.ValObjTercero = this.ValObjTercero.map(val => val === "" ? 0 : val);

            // Determinar el valor real (dReal) basado en el mes
            if (this.iMes === this.PrimerMesNumValorBase) {
                this.dReal = [...this.ValObjPrimerMes];
            } else if (this.iMes === this.SegundoMesNumValorBase) {
                if (this.TipoCalculoMes.some(segundoIF)) {
                    this.dReal = this.ValObjPrimerMes.map((valor, index) => (valor + this.ValObjSegundoMes[index]) / 2);
                } else {
                    this.dReal = this.ValObjSegundoMes.some(value => value != 0) ? [...this.ValObjSegundoMes] : [...this.ValObjPrimerMes];
                }
            } else if (this.iMes === this.TercerMesNumValorBase) {
                if (this.TipoCalculoMes.some(segundoIF)) {
                    this.dReal = this.ValObjPrimerMes.map((valor, index) => (valor + this.ValObjSegundoMes[index] + this.ValObjTercero[index]) / 3);
                } else {
                    if (Array.isArray(this.ValObjTercero) && this.ValObjTercero.length > 0) {
                        this.dReal = this.ValObjTercero;
                    } else if (Array.isArray(this.ValObjSegundoMes) && this.ValObjSegundoMes.length > 0) {
                        this.dReal = this.ValObjSegundoMes;
                    } else if (Array.isArray(this.ValObjPrimerMes) && this.ValObjPrimerMes.length > 0) {
                        this.dReal = this.ValObjPrimerMes;
                    }
                }
            }

            // Cálculo de desempeño y puntos
            if (this.LEMes.length === this.VOMes.length && this.LEMes.length === this.dReal.length && this.LEMes.length === this.PonderacionMes.length) {
                this.Desemp = this.LEMes.map((le, index) => {
                    const vo = this.VOMes[index];
                    const dr = this.dReal[index];
                    if ((le - vo) !== 0) {
                        const desemp = ((le - dr) / (le - vo)) * 100;
                        return Math.min(Math.max(desemp, 0), 100);
                    } else {
                        return 0;
                    }
                });

                this.Puntos = this.Desemp.map((desemp, index) => (desemp * this.PonderacionMes[index]) / 100);

                // Calcula las sumas y almacénalas en las variables
                this.totalPonderacion = this.PonderacionMes.reduce((acc, curr) => acc + parseFloat(curr || 0), 0);
                this.totalPuntos = this.Puntos.reduce((acc, curr) => acc + parseFloat(curr || 0), 0);

                this.dSumaPuntosDepto = this.totalPuntos;

                this.combinedData = this.sortedData.map((item, index) => ({
                    ...item,
                    dReal: this.dReal[index],
                    Desemp: this.Desemp[index],
                    Puntos: this.Puntos[index]
                }));

                // console.log('validacionesTest:');
                // console.log('dReal:', this.dReal);
                // console.log('Desemp:', this.Desemp);
                // console.log('Puntos:', this.Puntos);
                // console.log('Total Ponderación:', this.totalPonderacion);
                // console.log('Total Puntos:', this.totalPuntos);
            } else {
                console.error('Las longitudes de los arrays no coinciden TABLA 1');
            }
        },


        //validaciones en la tabla 2

        async validacionesTestColab(folio) {
            // console.log(`Entrando a validacionesTestColab para folio: ${folio}`);

            // //  this.datosFolio = this.datosColaboradores[folio];
            // console.log("datosColaboradores:", this.datosColaboradores);
            // console.log("Folio:", folio);
            // console.log("Valor en datosColaboradores[folio]:", this.datosColaboradores[folio]);

            if (this.datosColaboradores && this.datosColaboradores[folio]) {
                this.datosFolio = this.datosColaboradores[folio];
            } else {
                console.error("datosColaboradores[folio] no está definido o es null.");
                this.datosFolio = {}; // Asignar un objeto vacío como valor predeterminado
            }


            // Verificación de que los datos sean arrays válidos
            if (
                !Array.isArray(this.datosFolio.ValObjPrimerMes) ||
                !Array.isArray(this.datosFolio.ValObjSegundoMes) ||
                !Array.isArray(this.datosFolio.ValObjTercero) ||
                !Array.isArray(this.datosFolio.LEMes) ||
                !Array.isArray(this.datosFolio.VOMes) ||
                !Array.isArray(this.datosFolio.PonderacionMes) ||
                !Array.isArray(this.datosFolio.TipoCalculoMes)
            ) {
                console.error('Una o más variables requeridas no son arrays o no están definidas.');
                // console.log('Datos recibidos:', this.datosFolio);
                return;
            }

            // console.log('Variables verificadas y listas para procesar:', this.datosFolio);

            // Asignar valores iniciales y convertir valores vacíos en 0
            let { ValObjPrimerMes, ValObjSegundoMes, ValObjTercero } = this.datosFolio;

            ValObjPrimerMes = ValObjPrimerMes.map(val => val === "" ? 0 : val);
            ValObjSegundoMes = ValObjSegundoMes.map(val => val === "" ? 0 : val);
            ValObjTercero = ValObjTercero.map(val => val === "" ? 0 : val);

            // console.log('Después de mapear valores vacíos a 0:');
            // console.log('ValObjPrimerMes:', ValObjPrimerMes);
            // console.log('ValObjSegundoMes:', ValObjSegundoMes);
            // console.log('ValObjTercero:', ValObjTercero);

            // Calcular dReal basado en la fórmula proporcionada
            const dReal = ValObjPrimerMes.map((valor, index) => {
                const tipoCalculo = this.datosFolio.TipoCalculoMes[index];
                if (tipoCalculo === "" || tipoCalculo === "Promedio" || tipoCalculo === "NULL") {
                    return (valor + ValObjSegundoMes[index] + ValObjTercero[index]) / 3;
                } else {
                    return ValObjTercero[index] !== 0
                        ? ValObjTercero[index]
                        : ValObjSegundoMes[index] !== 0
                            ? ValObjSegundoMes[index]
                            : valor;
                }
            });

            // console.log('dReal:', dReal);

            // Verificar que las longitudes coincidan para calcular desempeño y puntos
            if (
                this.datosFolio.LEMes.length === this.datosFolio.VOMes.length &&
                this.datosFolio.LEMes.length === dReal.length &&
                this.datosFolio.LEMes.length === this.datosFolio.PonderacionMes.length
            ) {
                // Calcular Desempeño y Puntos
                const Desemp = this.datosFolio.LEMes.map((le, index) => {
                    const vo = this.datosFolio.VOMes[index];
                    const dr = dReal[index];
                    const desemp = (le - vo) !== 0 ? ((le - dr) / (le - vo)) * 100 : 0;
                    return Math.min(Math.max(desemp, 0), 100); // Aseguramos que esté entre 0 y 100
                });

                const Puntos = Desemp.map((desemp, index) =>
                    (desemp * this.datosFolio.PonderacionMes[index]) / 100
                );

                // console.log('Desemp:', Desemp);
                // console.log('Puntos:', Puntos);

                // Calcular totales
                const totalPonderacion = this.datosFolio.PonderacionMes.reduce((acc, val) => acc + parseFloat(val || 0), 0);
                const totalPuntos = Puntos.reduce((acc, val) => acc + parseFloat(val || 0), 0);

                // Guardar los resultados en el objeto datosColaboradores
                this.datosColaboradores[folio].dSumaPuntosColab = totalPuntos;
                this.datosColaboradores[folio].totalPonderacion = totalPonderacion;

                // console.log(`Total Ponderación para folio ${folio}:`, totalPonderacion);
                // console.log(`Total Puntos para folio ${folio}:`, totalPuntos);
            } else {
                console.error(`Las longitudes de los arrays no coinciden para folio ${folio}.`);
            }
        },


        async validacionesTestTablaEditar() {
            // console.log('Entrando a validacionesTestTablaEditar...');

            // Función auxiliar para la validación de valores
            function segundoIF(valor) {
                return valor === null || valor === "Promedio" || valor === "";
            }

            // Inicializa todos los arrays relacionados a valores válidos
            this.ValObjPrimerMes = this.ValObjPrimerMes.map(val => val === "" ? 0 : val);
            this.ValObjSegundoMes = this.ValObjSegundoMes.map(val => val === "" ? 0 : val);
            this.ValObjTercero = this.ValObjTercero.map(val => val === "" ? 0 : val);

            // Asegúrate de inicializar `Desemp` y otros arrays como vacíos
            this.dReal = new Array(this.ValObjPrimerMes.length).fill(0);
            this.Desemp = new Array(this.LEMes.length).fill(0);
            this.Puntos = new Array(this.LEMes.length).fill(0);

            // Calcula los valores de `dReal` basados en las fórmulas proporcionadas
            this.dReal = this.ValObjPrimerMes.map((_, index) => {
                const tipoCalculo = this.TipoCalculoMes[index];
                if (segundoIF(tipoCalculo)) {
                    return (this.ValObjPrimerMes[index] + this.ValObjSegundoMes[index] + this.ValObjTercero[index]) / 3;
                } else if (this.ValObjTercero[index] !== 0) {
                    return this.ValObjTercero[index];
                } else if (this.ValObjSegundoMes[index] !== 0) {
                    return this.ValObjSegundoMes[index];
                } else {
                    return this.ValObjPrimerMes[index];
                }
            });

            // console.log('dReal:', this.dReal);

            // Verifica las longitudes de los arrays antes de realizar cálculos adicionales
            if (
                this.LEMes.length === this.VOMes.length &&
                this.LEMes.length === this.dReal.length &&
                this.LEMes.length === this.PonderacionMes.length
            ) {
                // Calcula los valores de `Desemp`
                this.Desemp = this.LEMes.map((le, index) => {
                    const vo = this.VOMes[index];
                    const dr = this.dReal[index];
                    let desemp = ((le - dr) / (le - vo)) * 100;
                    return Math.min(Math.max(desemp, 0), 100); // Limitar entre 0 y 100
                });

                // Calcula los valores de `Puntos`
                this.Puntos = this.Desemp.map((desemp, index) =>
                    (desemp * this.PonderacionMes[index]) / 100
                );

                // console.log('Desemp:', this.Desemp);
                // console.log('Puntos:', this.Puntos);

                // Calcula las sumas y almacénalas en las variables correspondientes
                this.totalPonderacion = this.PonderacionMes.reduce((acc, curr) => acc + parseFloat(curr || 0), 0);
                this.totalPuntos = this.Puntos.reduce((acc, curr) => acc + parseFloat(curr || 0), 0);

                // console.log('Total Ponderación:', this.totalPonderacion);
                // console.log('Total Puntos:', this.totalPuntos);

                // Asigna los puntos al departamento
                this.dSumaPuntosDepto = this.totalPuntos;

                // Crea el conjunto de datos combinado para mostrar
                this.combinedData = this.sortedData.map((item, index) => ({
                    ...item,
                    dReal: this.dReal[index],
                    Desemp: this.Desemp[index],
                    Puntos: this.Puntos[index]
                }));

                // console.log('combinedData:', this.combinedData);
            } else {
                console.error('Las longitudes de los arrays no coinciden en validacionesTest.');
            }
        },


        async validacionesTest() {
            // console.log('Entrando a validacionesTest...');

            function segundoIF(valor) {
                return valor === null || valor === "Promedio" || valor === "";
            }

            // Asegúrate de que todos los valores estén definidos y válidos
            this.ValObjPrimerMes = this.ValObjPrimerMes.map(val => val === "" ? 0 : val);
            this.ValObjSegundoMes = this.ValObjSegundoMes.map(val => val === "" ? 0 : val);
            this.ValObjTercero = this.ValObjTercero.map(val => val === "" ? 0 : val);

            // console.log('Después de mapear valores vacíos a 0:');
            // console.log('ValObjPrimerMes:', this.ValObjPrimerMes);
            // console.log('ValObjSegundoMes:', this.ValObjSegundoMes);
            // console.log('ValObjTercero:', this.ValObjTercero);

            // Determinar el valor real (dReal) basado en las fórmulas proporcionadas
            this.dReal = this.ValObjPrimerMes.map((_, index) => {
                const tipoCalculo = this.TipoCalculoMes[index];
                if (segundoIF(tipoCalculo)) {
                    return (this.ValObjPrimerMes[index] + this.ValObjSegundoMes[index] + this.ValObjTercero[index]) / 3;
                } else if (this.ValObjTercero[index] !== 0) {
                    return this.ValObjTercero[index];
                } else if (this.ValObjSegundoMes[index] !== 0) {
                    return this.ValObjSegundoMes[index];
                } else {
                    return this.ValObjPrimerMes[index];
                }
            });

            // console.log('dReal:', this.dReal);

            // Calcular desempeño y puntos
            if (
                this.LEMes.length === this.VOMes.length &&
                this.LEMes.length === this.dReal.length &&
                this.LEMes.length === this.PonderacionMes.length
            ) {
                this.Desemp = this.LEMes.map((le, index) => {
                    const vo = this.VOMes[index];
                    const dr = this.dReal[index];
                    let desemp = ((le - dr) / (le - vo)) * 100;
                    return Math.min(Math.max(desemp, 0), 100); // Limitar entre 0 y 100
                });

                this.Puntos = this.Desemp.map((desemp, index) =>
                    (desemp * this.PonderacionMes[index]) / 100
                );

                // console.log('Desemp:', this.Desemp);
                // console.log('Puntos:', this.Puntos);

                // Calcular las sumas y almacenarlas en las variables correspondientes
                this.totalPonderacion = this.PonderacionMes.reduce((acc, curr) => acc + parseFloat(curr || 0), 0);
                this.totalPuntos = this.Puntos.reduce((acc, curr) => acc + parseFloat(curr || 0), 0);

                // console.log('Total Ponderación:', this.totalPonderacion);
                // console.log('Total Puntos:', this.totalPuntos);

                // Asignar los puntos al departamento
                this.dSumaPuntosDepto = this.totalPuntos;

                // Crear el conjunto de datos combinado para mostrar
                this.combinedData = this.sortedData.map((item, index) => ({
                    ...item,
                    dReal: this.dReal[index],
                    Desemp: this.Desemp[index],
                    Puntos: this.Puntos[index]
                }));

                // console.log('combinedData:', this.combinedData);
            } else {
                console.error('Las longitudes de los arrays no coinciden en validacionesTest.');
            }
        },

        //Este es de prueba
        async validacionesTestNuevo(signal) {
            if (signal.aborted) throw new DOMException('Abortado', 'AbortError');
            // console.log('Entrando a validacionesTestNuevo...');

            function segundoIF(valor) {
                return valor === null || valor === "Promedio" || valor === "";
            }

            // Asegúrate de que todos los valores estén definidos y válidos
            this.ValObjPrimerMes = this.ValObjPrimerMes.map(val => val === "" ? 0 : val);
            this.ValObjSegundoMes = this.ValObjSegundoMes.map(val => val === "" ? 0 : val);
            this.ValObjTercero = this.ValObjTercero.map(val => val === "" ? 0 : val);

            // Determinar el valor real (calculoReal) basado en las fórmulas proporcionadas
            this.calculoReal = this.ValObjPrimerMes.map((_, index) => {
                const tipoCalculo = this.TipoCalculoMes[index];
                if (segundoIF(tipoCalculo)) {
                    return (this.ValObjPrimerMes[index] + this.ValObjSegundoMes[index] + this.ValObjTercero[index]) / 3;
                } else if (this.ValObjTercero[index] !== 0) {
                    return this.ValObjTercero[index];
                } else if (this.ValObjSegundoMes[index] !== 0) {
                    return this.ValObjSegundoMes[index];
                } else {
                    return this.ValObjPrimerMes[index];
                }
            });

            // Calcular desempeño y puntos
            if (
                this.LEMes.length === this.VOMes.length &&
                this.LEMes.length === this.calculoReal.length &&
                this.LEMes.length === this.PonderacionMes.length
            ) {
                this.desempeno = this.LEMes.map((le, index) => {
                    const vo = this.VOMes[index];
                    const dr = this.calculoReal[index];
                    let desemp = ((le - dr) / (le - vo)) * 100;
                    return Math.min(Math.max(desemp, 0), 100); // Limitar entre 0 y 100
                });

                this.puntosNew = this.desempeno.map((desemp, index) =>
                    (desemp * this.PonderacionMes[index]) / 100
                );

                // Calcular las sumas y almacenarlas en las variables correspondientes
                this.totalPonderacion = this.PonderacionMes.reduce((acc, curr) => acc + parseFloat(curr || 0), 0);
                this.totalPuntos = this.puntosNew.reduce((acc, curr) => acc + parseFloat(curr || 0), 0);

                // Asignar los puntos al departamento
                this.dSumaPuntosDepto = this.totalPuntos;

                // Crear el conjunto de datos combinado para mostrar
                this.combinedData = this.sortedData.map((item, index) => ({
                    ...item,
                    calculoReal: this.calculoReal[index],
                    desempeno: this.desempeno[index],
                    puntosNew: this.puntosNew[index] // Actualizado aquí
                }));
            } else {
                console.error('Las longitudes de los arrays no coinciden en validacionesTest.');
            }
        },

        //Este es para el de editar
        async validacionesTestNuevoEditar() {
            
            function segundoIF(valor) {
                return valor === null || valor === "Promedio" || valor === "";
            }

            // Asegúrate de que todos los valores estén definidos y válidos
            this.ValObjPrimerMes = this.ValObjPrimerMes.map(val => val === "" ? 0 : val);
            this.ValObjSegundoMes = this.ValObjSegundoMes.map(val => val === "" ? 0 : val);
            this.ValObjTercero = this.ValObjTercero.map(val => val === "" ? 0 : val);

            // Determinar el valor real (calculoReal) basado en las fórmulas proporcionadas
            this.calculoReal = this.ValObjPrimerMes.map((_, index) => {
                const tipoCalculo = this.TipoCalculoMes[index];
                if (segundoIF(tipoCalculo)) {
                    return (this.ValObjPrimerMes[index] + this.ValObjSegundoMes[index] + this.ValObjTercero[index]) / 3;
                } else if (this.ValObjTercero[index] !== 0) {
                    return this.ValObjTercero[index];
                } else if (this.ValObjSegundoMes[index] !== 0) {
                    return this.ValObjSegundoMes[index];
                } else {
                    return this.ValObjPrimerMes[index];
                }
            });

            // Calcular desempeño y puntos
            if (
                this.LEMes.length === this.VOMes.length &&
                this.LEMes.length === this.calculoReal.length &&
                this.LEMes.length === this.PonderacionMes.length
            ) {
                this.desempeno = this.LEMes.map((le, index) => {
                    const vo = this.VOMes[index];
                    const dr = this.calculoReal[index];
                    let desemp = ((le - dr) / (le - vo)) * 100;
                    return Math.min(Math.max(desemp, 0), 100); // Limitar entre 0 y 100
                });

                this.puntosNew = this.desempeno.map((desemp, index) =>
                    (desemp * this.PonderacionMes[index]) / 100
                );

                // Calcular las sumas y almacenarlas en las variables correspondientes
                this.totalPonderacion = this.PonderacionMes.reduce((acc, curr) => acc + parseFloat(curr || 0), 0);
                this.totalPuntos = this.puntosNew.reduce((acc, curr) => acc + parseFloat(curr || 0), 0);

                // Asignar los puntos al departamento
                this.dSumaPuntosDepto = this.totalPuntos;

                // Crear el conjunto de datos combinado para mostrar
                this.combinedData = this.sortedData.map((item, index) => ({
                    ...item,
                    calculoReal: this.calculoReal[index],
                    desempeno: this.desempeno[index],
                    puntosNew: this.puntosNew[index] // Actualizado aquí
                }));
            } else {
                console.error('Las longitudes de los arrays no coinciden en validacionesTest.');
            }
        },


        //ESTE ES PARA LA TABLA DE COORDINADORES SE VAN A MODICAR EL NOMBRE DE LAS VARAIBLES 


        async validacionesTestCoordinadores(folio) {
            // this.datosFolio = this.datosPorFolio[folio];

            // console.log("datosColaboradores entro al de text cordi:", this.datosPorFolio);
            // console.log("Folio:", folio);
            // console.log("Valor en datosColaboradores[folio]:", this.datosPorFolio[folio]);

            if (this.datosPorFolio && this.datosPorFolio[folio]) {
                this.datosFolio = this.datosPorFolio[folio];
            } else {
                // console.log("datosPorFolio[folio] no está definido o es null. No hay datos");
                this.datosFolio = {}; // Asignar un objeto vacío como valor predeterminado
            }


            // Verificar que todas las claves esperadas sean arrays válidos y reemplazar vacíos con 0 en una sola pasada
            const arraysEsperados = ['ValObjPrimerMes', 'ValObjSegundoMes', 'ValObjTercero', 'LEMes', 'VOMes', 'PonderacionMes', 'TipoCalculoMes'];

            // console.log("Contenido de arraysEsperados:", arraysEsperados);
            for (const key of arraysEsperados) {
                if (!Array.isArray(this.datosFolio[key])) {
                    // console.log(`El array ${key} no es válido o está indefinido en el folio ${folio}.`);
                    return;
                }
                this.datosFolio[key] = this.datosFolio[key].map(val => val === "" ? 0 : val); // Reemplaza los valores vacíos con 0
            }

            const { ValObjPrimerMes, ValObjSegundoMes, ValObjTercero, LEMes, VOMes, PonderacionMes, TipoCalculoMes } = this.datosFolio;

            // Verificación de longitud de arrays
            const longitud = LEMes.length;
            if (![ValObjPrimerMes, ValObjSegundoMes, ValObjTercero, VOMes, PonderacionMes, TipoCalculoMes].every(arr => arr.length === longitud)) {
                // console.error(`Las longitudes de los arrays no coinciden para el folio ${folio}.`);
                return;
            }

            // Calcular `dReal`, desempeño y puntos en una sola iteración
            let totalPuntos = 0;

            for (let index = 0; index < longitud; index++) {
                const tipoCalculo = TipoCalculoMes[index];
                const [v1, v2, v3] = [ValObjPrimerMes[index], ValObjSegundoMes[index], ValObjTercero[index]];

                const dReal = (tipoCalculo === "" || tipoCalculo === "Promedio" || tipoCalculo === "NULL") ? (v1 + v2 + v3) / 3 : v3 || v2 || v1;

                const vo = VOMes[index], le = LEMes[index];
                let desempenio = ((le - dReal) / (le - vo)) * 100;
                desempenio = Math.min(Math.max(desempenio, 0), 100);

                const puntos = (desempenio * PonderacionMes[index]) / 100;
                totalPuntos += puntos;
            }

            // Almacenar el resultado en `dSumaPuntosDepto` para el folio
            this.datosPorFolio[folio].dSumaPuntosDepto = totalPuntos;
        },





        //Este metodo es para la foto
        getPerfilColaborador(EMPLID) {

            let params = {
                Opcion: 19,
                NPcliente1: EMPLID,
                token: localStorage.getItem("token"),
            };
            axios
                .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
                .then((response) => {
                    if (response.data.status == "OK") {
                        // Verificar si la foto de perfil existe o si es null
                        if (response.data.data["foto"] && response.data.data["foto"] !== "") {
                            // Si la foto está disponible, actualizar la URL de la foto de perfil
                            this.fotoPerfil = "https://apidh.urrea.com/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/" + response.data.data["foto"];
                        } else {
                            // Si no hay foto disponible, asignar una imagen por defecto
                            //this.fotoPerfil = "https://community.f5.com/legacyfs/online/avatars/0051T000008uOPOQA2.png";
                            this.fotoPerfil = "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";
                        }

                        // Actualizar el folio del colaborador
                        this.folio = response.data.data["EMPLID"];
                    } else {
                        // Si no se encuentra el empleado o hay un error, usar la imagen por defecto
                        this.fotoPerfil = "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";
                        this.folio = null;  // O cualquier valor que consideres para el folio cuando no se encuentra el empleado
                    }
                })
                .catch(() => {
                    // En caso de error en la solicitud, también usar la imagen por defecto
                    this.fotoPerfil = "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";
                    this.folio = null;  // O cualquier valor que consideres para el folio cuando no se encuentra el empleado
                });

        },




        //Este es para traer los valores de las personas de uno por uno en la evaluacion

        async tablaCultura(folio) {
            try {
                const response = await axios.get(
                    `${this.$store.getters.getHostNode}/api/tablaCultura/${folio}`,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );

                if (response.data && response.data.data.length > 0) {
                    const empleadoData = response.data.data[0]; // Accede al primer objeto de datos

                    // Paso 1: Obtén el valor de la pregunta desde la API y pásalo a getNivel
                    this.Pregunta1 = this.getNivel(empleadoData.Pregunta1);
                    this.Pregunta2 = this.getNivel(empleadoData.Pregunta2);
                    this.Pregunta3 = this.getNivel(empleadoData.Pregunta3);
                    this.Pregunta4 = this.getNivel(empleadoData.Pregunta4);
                    this.Pregunta5 = this.getNivel(empleadoData.Pregunta5);
                    this.Pregunta6 = this.getNivel(empleadoData.Pregunta6);

                    this.Pregunta1V = (empleadoData.Pregunta1);
                    this.Pregunta2V = (empleadoData.Pregunta2);
                    this.Pregunta3V = (empleadoData.Pregunta3);
                    this.Pregunta4V = (empleadoData.Pregunta4);
                    this.Pregunta5V = (empleadoData.Pregunta5);
                    this.Pregunta6V = (empleadoData.Pregunta6);

                    // Paso 2: Las variables this.Pregunta1, this.Pregunta2, etc. ahora contienen los niveles 
                    // (por ejemplo, "Excelente", "Muy Bueno", etc.)
                } else {
                    //console.error("No se recibieron datos válidos desde la API.");
                    this.Pregunta1 = '';
                    this.Pregunta2 = '';
                    this.Pregunta3 = '';
                    this.Pregunta4 = '';
                    this.Pregunta5 = '';
                    this.Pregunta6 = '';

                    this.Pregunta1V = '';
                    this.Pregunta2V = '';
                    this.Pregunta3V = '';
                    this.Pregunta4V = '';
                    this.Pregunta5V = '';
                    this.Pregunta6V = '';
                }
            } catch (error) {
                console.error("Error al obtener los empleados:", error);
            }
        },

        // Paso 3: Este método recibe los valores y los transforma en el nivel correspondiente
        getNivel(preguntaValor) {
            let nivel = '';
            if (preguntaValor == 6.67) nivel = "Excelente";
            else if (preguntaValor == 4.98) nivel = "Muy Bueno";
            else if (preguntaValor == 3.32) nivel = "Bueno";
            else if (preguntaValor == 1.66) nivel = "Regular";
            else if (preguntaValor == 0) nivel = "Malo";
            return nivel;
        },


        //Este es para la tabla de cultura

        async getEmpleadosValorEva(folio) {
            try {
                const response = await axios.get(
                    `${this.$store.getters.getHostNode}/api/EmpleadosValor/${folio}`,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );

                if (response.data && response.data.data.length > 0) {
                    const empleadoData = response.data.data[0]; // Accede al primer objeto de datos
                    this.NombreEva = empleadoData.NOMBRE; // Asigna directamente el valor sin usar map
                    this.DepartamentoEva = empleadoData.DEP_DESDEP; // Asigna directamente el valor sin usar map
                    this.PuestoEva = empleadoData.PUE_DESPUE; // Asigna directamente el valor sin usar map
                } else {
                    console.error("No se recibieron datos válidos desde la API.");
                }
            } catch (error) {
                console.error("Error al obtener los empleados:", error);
            }
        },


        //Esto es para traer los trimestres de la evaluacion de desempeño

        async getTrimestresDesempeño(selectedTrimestre) {
            // console.log('valor de folio seleccionado DESEMPEÑO', 'folio tabla 2: ', this.folioGlobal, 'folio tabla 1: ', this.folioGlobalTabla1, selectedTrimestre);
            this.anioAnterior = this.anio - 1;
            //validacion de los folios si estan nulos o vacios
            let resultado = this.folioGlobal !== null && this.folioGlobal !== undefined ? this.folioGlobal : this.folioGlobalTabla1 !== null && this.folioGlobalTabla1 !== undefined ? this.folioGlobalTabla1 : null;

            // console.log('folio final $$$$$$', resultado);

            try {
                // Realizamos la solicitud GET a la API
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/trimestresDesemp/${resultado}/${selectedTrimestre}/${this.anioAnterior}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                // Verificamos si la respuesta tiene datos válidos
                if (response.data && response.data.data) {
                    // Asignamos los datos a las propiedades correspondientes
                    this.DescObjetivoT = response.data.data.map(item => item.DescObjetivo);
                    this.VOTR = response.data.data.map(item => parseFloat(item.VO).toFixed(2));
                    this.LETR = response.data.data.map(item => parseFloat(item.LE).toFixed(2));
                    this.Valor1TR = response.data.data.map(item => parseFloat(item.ValorPrimer).toFixed(2));
                    this.Valor2TR = response.data.data.map(item => parseFloat(item.ValorSegundo).toFixed(2));
                    this.Valor3TR = response.data.data.map(item => parseFloat(item.ValorTercero).toFixed(2));
                    this.PonderacionTR = response.data.data.map(item => parseFloat(item.Ponderacion).toFixed(2));

                    this.VOT = response.data.data.map(item => parseFloat(item.VO));
                    this.LET = response.data.data.map(item => parseFloat(item.LE));
                    this.Valor1T = response.data.data.map(item => parseFloat(item.ValorPrimer));
                    this.Valor2T = response.data.data.map(item => parseFloat(item.ValorSegundo));
                    this.Valor3T = response.data.data.map(item => parseFloat(item.ValorTercero));
                    this.PonderacionT = response.data.data.map(item => parseFloat(item.Ponderacion));
                    this.TipoCalculoT = response.data.data.map(item => item.TipoCalculo);

                    this.validacionesTrimestres();

                    // this.getEmpleadosDirEmp3();
                } else {
                    console.error('No se recibieron datos válidos desde la API.');
                }

            } catch (error) {
                console.error('Error al obtener los empleados:', error);
            }
        },

        //Esto es para hacer el conteo de los trimestres que si tienen datos o no tienen
        async verificarTrimestres() {
            let contadorTrimestresConDatos = 0;   // Variable para contar los trimestres con datos
            let contadorTrimestresSinDatos = 0;   // Variable para contar los trimestres sin datos

            // Inicializar arreglos para almacenar los valores de cada trimestre
            this.VOT = [];
            this.LET = [];
            this.Valor1T = [];
            this.Valor2T = [];
            this.Valor3T = [];
            this.PonderacionT = [];
            this.TipoCalculoT = [];

            // Array con los trimestres a verificar
            const trimestres = [1, 2, 3, 4];

            //ESTO ES PARA VALIDAR LOS FOLIOS
            let resultado = this.folioGlobal !== null && this.folioGlobal !== undefined ? this.folioGlobal : this.folioGlobalTabla1 !== null && this.folioGlobalTabla1 !== undefined ? this.folioGlobalTabla1 : null;

            // console.log('folio final $$$$$$', resultado);

            // Recorremos los trimestres
            for (const trimestre of trimestres) {
                // console.log(`Verificando trimestre: ${trimestre}`);

                // Ejecutamos getTrimestresDesempeño para cada trimestre
                try {
                    const response = await axios.get(`${this.$store.getters.getHostNode}/api/trimestresDesemp/${resultado}/${trimestre}/${this.anio - 1}`, {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    });

                    // Verificamos si hay datos válidos
                    if (response.data && response.data.data.length > 0) {
                        // console.log(`Trimestre ${trimestre} tiene datos.`);
                        contadorTrimestresConDatos++;   // Incrementamos el contador si tiene datos

                        // Guardar los valores de este trimestre en los arreglos correspondientes
                        this.VOT.push(...response.data.data.map(item => (item.VO)));
                        this.LET.push(...response.data.data.map(item => (item.LE)));
                        this.Valor1T.push(...response.data.data.map(item => (item.ValorPrimer)));
                        this.Valor2T.push(...response.data.data.map(item => (item.ValorSegundo)));
                        this.Valor3T.push(...response.data.data.map(item => (item.ValorTercero)));
                        this.PonderacionT.push(...response.data.data.map(item => (item.Ponderacion)));
                        this.TipoCalculoT.push(...response.data.data.map(item => item.TipoCalculo));

                        // Llamar a validacionesTrimestres después de verificar el trimestre y obtener los datos
                        this.validacionesTrimestres();
                    } else {
                        console.log(`Trimestre ${trimestre} no tiene datos.`);
                        contadorTrimestresSinDatos++;   // Incrementamos el contador si no tiene datos
                    }

                } catch (error) {
                    console.error(`Error al obtener los datos del trimestre ${trimestre}:`, error);
                    contadorTrimestresSinDatos++;   // Consideramos como sin datos en caso de error
                }
            }

            // Al finalizar, mostramos los resultados
            // console.log(`Total de trimestres con datos: ${contadorTrimestresConDatos}`);
            // console.log(`Total de trimestres sin datos: ${contadorTrimestresSinDatos}`);

            // Si deseas, puedes guardar estos contadores en el estado para mostrarlos en la UI
            this.trimestresConDatos = contadorTrimestresConDatos;
            this.trimestresSinDatos = contadorTrimestresSinDatos;

            this.otroMetodo();
        },


        //ESTE ES PARA SACAR EL DESEMPEÑO Y PUNTOS DE LOS TRIMESTRES EN LA EVALUACIN
        //Aqui falta el metodo

        async validacionesTrimestres() {
            // Función para verificar si el tipo de cálculo es "NULL", "Promedio" o vacío
            function esPromedioOTipoCalculoNulo(valor) {
                return valor === "NULL" || valor === "Promedio" || valor === "";
            }

            // Asegurarse de que todos los valores estén definidos y sean válidos (convertir "" en 0)
            this.Valor1T = this.Valor1T.map(val => val === "" ? 0 : val);
            this.Valor2T = this.Valor2T.map(val => val === "" ? 0 : val);
            this.Valor3T = this.Valor3T.map(val => val === "" ? 0 : val);

            // Determinar el valor real (dReal) basado en el tipo de cálculo o los valores trimestrales
            this.dReal = this.Valor1T.map((valor1, index) => {
                const valor2 = this.Valor2T[index];
                const valor3 = this.Valor3T[index];
                const tipoCalculo = this.TipoCalculoT[index];

                if (esPromedioOTipoCalculoNulo(tipoCalculo)) {
                    // Si es un promedio o tipo de cálculo nulo, tomar el promedio de los valores
                    return (valor1 + valor2 + valor3) / 3;
                } else {
                    // De lo contrario, seleccionar el valor en función de cuál sea diferente de 0
                    if (valor3 !== 0) return valor3;
                    else if (valor2 !== 0) return valor2;
                    else if (valor1 !== 0) return valor1;
                    else return 0;
                }
            });

            // Cálculo de desempeño y puntos
            if (this.LET.length === this.VOT.length && this.LET.length === this.dReal.length && this.LET.length === this.PonderacionT.length) {
                this.Desemp = this.LET.map((le, index) => {
                    const vo = this.VOT[index];
                    const dr = this.dReal[index];
                    // Cálculo de desempeño según la fórmula proporcionada
                    const desemp = ((dr - le) / (vo - le)) * 100;
                    // Limitar el desempeño a un máximo de 100 y un mínimo de 0
                    return Math.min(Math.max(desemp, 0), 100);
                });

                this.Puntos = this.Desemp.map((desemp, index) => {
                    // Calcular los puntos basados en el desempeño y la ponderación
                    return (desemp * this.PonderacionT[index]) / 100;
                });

                // Calcular las sumas totales
                this.totalPonderacion = this.PonderacionT.reduce((acc, curr) => acc + parseFloat(curr || 0), 0);
                this.totalPuntos = this.Puntos.reduce((acc, curr) => acc + parseFloat(curr || 0), 0);

                // Almacenar el último total de puntos en la variable global
                this.ultimoTotalPuntos = this.totalPuntos;  // Aquí almacenamos el último total de puntos

                this.dSumaPuntosDepto = this.totalPuntos;

                // Actualizar los datos combinados si es necesario
                this.combinedData = this.sortedData.map((item, index) => ({
                    ...item,
                    dReal: this.dReal[index],
                    Desemp: this.Desemp[index],
                    Puntos: this.Puntos[index]
                }));

                // console.log('validacionesTrimestrales:');
                // console.log('dReal:', this.dReal);
                // console.log('Desemp:', this.Desemp);
                // console.log('Puntos:', this.Puntos);
                // console.log('Total Ponderación:', this.totalPonderacion);
                // console.log('Total Puntos:', this.totalPuntos);
            } else {
                console.error('Las longitudes de los arrays no coinciden VALIDACIONES TRIMESTRES');
            }
        },

        // Otro método que utiliza el último valor de totalPuntos Desempeno 2011: Los puntos Obtenidos 
        otroMetodo() {
            // console.log('Último total de puntos:', this.ultimoTotalPuntos);

            // Asegurarse de que ultimoTotalPuntos tenga un valor numérico
            const totalPuntos = isNaN(this.ultimoTotalPuntos) ? 0 : this.ultimoTotalPuntos;

            // Asegurarse de que NumTrim y trimestresSinDatos sean numéricos
            const numTrim = isNaN(this.NumTrim) ? 0 : this.NumTrim;
            const trimestresSinDatos = isNaN(this.trimestresSinDatos) ? 0 : this.trimestresSinDatos;

            // Calcular contadorFinal con verificación
            let contadorFInal = numTrim - trimestresSinDatos;
            if (contadorFInal <= 0) {
                this.PuntosObtenidos = 0;
                this.Desemp2011 = 0;
            } else {
                // Redondear los valores a enteros
                this.PuntosObtenidos = Math.round((totalPuntos * 0.60) / contadorFInal);
                this.Desemp2011 = Math.round(totalPuntos / contadorFInal);
            }

            // console.log('VALORES DEL 2011 ******************+', this.PuntosObtenidos, this.Desemp2011);
        },


        async openDialogTrim(trimestre) {
            try {
                this.dialogTrim = true; // Abrir el diálogo
                this.loadingTable = true; // Activar estado de carga

                // Asignar el trimestre seleccionado
                this.selectedTrimestre = trimestre;

                // Esperar a que la función termine
                await this.getTrimestresDesempeño(this.selectedTrimestre);
            } catch (error) {
                console.error("Error al obtener los datos del trimestre:", error);
            } finally {
                // Desactivar el estado de carga al terminar, incluso si hubo error
                this.loadingTable = false;
            }
        },

        closedialogTrim() {
            this.dialogTrim = false;
            this.dialogObjetivosEmp = false;
            this.actualizarPuesto = false;
        },









    }
};
</script>

<style>
.rounded-simple-table-head-l {
    border-top-left-radius: 22px !important;
}

.rounded-simple-table-head-r {
    border-top-right-radius: 22px !important;
}

.centrado {
    text-align: center;
    font-size: 18px;
}

.blue-text {
    color: blue;
}


.trimestre-azul {
    background-color: #2196f3;
    /* Azul */
    color: white;
    /* Texto blanco para contraste */
    padding: 8px;
    /* Espaciado adicional */
    border-radius: 4px;
    /* Opcional: bordes redondeados */
}

.obj-text-blue {
    color: #2196f3;
    /* Color azul */
    text-align: center;
    /* Centrar el texto */
    font-weight: bold;
    /* Opcional: negrita para destacar */
}

.cut-text-gray {
    color: #808080;
    /* Color gris */
    text-align: center;
    /* Centrar el texto */
    font-weight: bold;
    /* Mantener el peso de la fuente normal */
}

.ext-text-red {
    color: #F78181;
    /* Color rojo */
    text-align: center;
    /* Centrar el texto */
    font-weight: bold;
    /* Mantener el peso de la fuente normal */
}

.total {

    text-align: center;
    /* Centrar el texto */
    font-weight: bold;
    /* Mantener el peso de la fuente normal */
}

.excel-green {
    background-color: #217346 !important;
    /* Color verde de Excel */
    color: white !important;
    /* Texto en blanco */
}

.cursor-move {
    cursor: move;
    /* Cambia el cursor para indicar que el modal es movible */
}

/* Esto es del toolbar */
/* Toolbar Sticky */
.sticky-toolbar {
    position: sticky;
    top: 0;
    z-index: 10;
    width: 100%;
    /* Asegura que ocupe todo el ancho del contenedor */
    background-color: #1976d2;
    /* Fondo azul consistente */
    border-radius: 0;
    /* Elimina bordes redondeados para ocupar el ancho completo */
    margin: 0;
    padding: 0;
}

/* Contenedor con scroll */
.scrollable-content {
    max-height: 400px;
    /* Ajusta según sea necesario */
    overflow-y: auto;
}

/* Eliminar los bordes blancos en los costados del v-card */
.v-card {
    background-color: transparent;
    /* Asegura que el fondo sea transparente */
    padding: 0;
    margin: 0;
    border-radius: 0;
    /* Elimina esquinas redondeadas */
}
/*Esto es para la imagen */
.info-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

.info-text {
    flex: 1;
}
.foto-perfil {
    flex: 0 0 auto;
    text-align: center;
    margin-left: 450px; /* Aumenta este valor para desplazar más la imagen */
}

.foto-perfil img {
    width: 300px;
    height:300px;
    object-fit: cover;
    border-radius: 50%;
}

.small-input {
    width: 60px;
    padding: 2px;
    font-size: 12px;
}
.text-success {
    color: green !important;
  }
  
  .text-danger {
    color: red !important;
  }

  .campo-error {
    border: 2px solid red;
    background-color: #ffe6e6;
  }
  
  .mensaje-error {
    font-size: 12px;
    color: red;
    margin-top: 4px;
    display: block;
  }
  
  .custom-dialog {
    max-width: 50%; /* Aumenta el ancho */
    max-height: 50%; /* Aumenta la altura */
    overflow: auto; /* Permite scroll si el contenido es grande */
}

  
</style>
