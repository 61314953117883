var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"px-1 py-2 fondo-card rounded-lg",attrs:{"color":"white","height":"100%"}},[_c('v-container',{staticClass:"main-container"},[_c('div',{staticClass:"avatar-container"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"rounded-circle",attrs:{"cover":"","margin":"auto","lazy-src":require("@/assets/img/placeholder_user.svg"),"src":_vm.fotoPerfil,"aspect-ratio":1 / 1}},[_c('v-expand-transition',{attrs:{"bottom":""}},[(hover)?_c('div',{staticClass:"fondo-imagen d-flex transition-fast-in-fast-out v-card--reveal white--text",staticStyle:{"display":"flex","justify-content":"center","height":"100%"}},[_c('v-chip',{staticClass:"fondo-chip",staticStyle:{"margin-top":"45%"},on:{"click":function($event){_vm.abrirModal = true}}},[_c('v-icon',[_vm._v("mdi-camera")]),_vm._v(" Editar foto")],1)],1):_vm._e()])],1)]}}])}),(_vm.valores)?_c('div',{staticClass:"avatar-effect"}):_vm._e()],1),_c('v-card-text',{staticClass:"text-center"},[_c('div',[_vm._v(_vm._s(_vm.$store.getters.getUser["Nombre"]))]),_c('div',[_vm._v(_vm._s(_vm.$store.getters.getUser["Puesto"]))]),_c('div',[_vm._v(_vm._s(_vm.$store.getters.getUser["Departamento"]))]),(_vm.$store.getters.getUser['compania'] == '00100')?_c('div',[_vm._v(" Urrea Herramientas Profesionales ")]):(_vm.$store.getters.getUser['compania'] == '01000')?_c('div',[_vm._v(" Urrea Herramientas Colombia ")]):(_vm.$store.getters.getUser['compania'] == '00700')?_c('div',[_vm._v(" Urrea Professional Tools ")]):_vm._e(),_c('br'),_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","small":"","outlined":"","fab":"","color":"light-blue darken-3"},on:{"click":function($event){_vm.expand = !_vm.expand}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.expand ? 'mdi-close' : 'mdi-card-account-details-star-outline'))])],1)]}}])},[_c('span',[_vm._v("Principios")])])],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.$store.getters.getUser['compania'] == '00100'),expression:"$store.getters.getUser['compania'] == '00100'"}],attrs:{"dark":"","small":"","outlined":"","fab":"","color":"light-blue darken-3"},on:{"click":function($event){_vm.muestraEquipoAsignado = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-laptop")])],1)]}}])},[_c('span',[_vm._v("Equipo")])])],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.$store.getters.getUser['compania'] == '00100'),expression:"$store.getters.getUser['compania'] == '00100'"}],attrs:{"dark":"","small":"","outlined":"","fab":"","color":"light-blue darken-3"},on:{"click":function($event){_vm.muestraFormInconformidades = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chat-processing-outline")])],1)]}}])},[_c('span',[_vm._v("Crear solicitud")])])],1)],1),_c('v-expand-transition',[_c('v-sheet',{directives:[{name:"show",rawName:"v-show",value:(_vm.expand),expression:"expand"}],attrs:{"id":"valores-preview","color":"transparent"}},[_c('persona-valores-preview',{attrs:{"userId":1}})],1)],1)],1),(_vm.abrirModal)?_c('CargarFotoPerfil',{attrs:{"openDialogSubirImg":_vm.abrirModal,"folio":_vm.folio,"ruta":_vm.fotoPerfil},on:{"cerrarModal":_vm.cierraModalPerfil}}):_vm._e(),_c('PersonaEquipoAsignado',{attrs:{"verInfoEquipo":_vm.muestraEquipoAsignado},on:{"cerrarEquipoAsignado":function($event){_vm.muestraEquipoAsignado = false}}}),(_vm.muestraFormInconformidades)?_c('mi-nomina-form-inconformidades',{attrs:{"dialog":_vm.muestraFormInconformidades},on:{"cierraFormInconformidades":_vm.closeFormInconformidades}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }