<template>
  <v-container fluid>
    <div class="center-item-in-div py-6">
      <v-btn class="mx-2 rounded-pill" elevation="0" color="primary" :outlined="selection !== 1"
        :disabled="loadingRanking" @click="selection = 1; updateTable()">manufactura</v-btn>
      <v-btn class="mx-2 rounded-pill" elevation="0" color="primary" :outlined="selection !== 2"
        :disabled="loadingRanking" @click="selection = 2; updateTable()">logística</v-btn>
    </div>
    <v-card class="elevation-0 rounded-xl" color="blue-grey lighten-5">
      <v-data-table dense :headers="headers" :items="rankingCalculado" :loading="loadingRanking" :search="search"
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
          pageText: '{0}-{1} de {2}',
        }" no-data-text="No hay registros" loading-text="Cargando información..."
        style="background-color: transparent;">
        <template v-slot:top>
          <div style="display: flex; justify-content: space-between;" class="px-2 py-2">
            <v-text-field v-model="search" dense outlined class="rounded-pill" prepend-inner-icon="mdi-magnify"
              placeholder="Buscar..."></v-text-field>
            <v-spacer></v-spacer>
            <div>
              <vue-json-to-csv :json-data="rankingCalculado" :labels="headers_to_export"
                csv-title="ReporteEmpleadosCajaAhorro">
                <v-tooltip bottom color="green darken-2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed outlined fab small v-bind="attrs" v-on="on" class="rounded-pill mr-2"
                      color="green darken-2" dark :loading="loadingRanking">
                      <v-icon>mdi-microsoft-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Exportar CSV</span>
                </v-tooltip>
              </vue-json-to-csv>
              <v-tooltip bottom color="blue-grey darken-2">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed fab small outlined class="mr-2" v-bind="attrs" v-on="on" color="blue-grey darken-2"
                    :loading="loadingRanking" @click="updateTable">
                    <v-icon>mdi-reload</v-icon>
                  </v-btn>
                </template>
                <span>Actualizar tabla</span>
              </v-tooltip>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios'
import { deleteToken } from '../../../../../store/Funciones/funciones';
import vueJsonToCsv from 'vue-json-to-csv';

export default {
  components: { vueJsonToCsv },
  data() {
    return {
      selection: 1,
      rankingCalculado: [],
      headers: [
        { value: "Posición", text: "Posición", align: "center", sortable: false, class: "overline font-weight-bold blue-grey--text text--darken-2" },
        { value: "Emplid", text: "Emplid", align: "center", sortable: false, class: "overline font-weight-bold blue-grey--text text--darken-2" },
        { value: "Nombre", text: "Nombre", sortable: false, class: "overline font-weight-bold blue-grey--text text--darken-2" },
        { value: "Operación", text: "Operación", sortable: false, class: "overline font-weight-bold blue-grey--text text--darken-2" },
        { value: "Departamento", text: "Departamento", sortable: false, class: "overline font-weight-bold blue-grey--text text--darken-2" },
        { value: "puntos_antiguedad", text: "Antigüedad", align: "center", sortable: false, class: "overline font-weight-bold blue-grey--text text--darken-2" },
        { value: "puntos_kardex_actas", text: "Kardex Actas", align: "center", sortable: false, class: "overline font-weight-bold blue-grey--text text--darken-2" },
        { value: "puntos_kardex_faltas", text: "Kardex faltas", align: "center", sortable: false, class: "overline font-weight-bold blue-grey--text text--darken-2" },
        { value: "puntos_valores", text: "Valores", align: "center", sortable: false, class: "overline font-weight-bold blue-grey--text text--darken-2" },
        { value: "puntos_TE", text: "Apoyo TE", align: "center", sortable: false, class: "overline font-weight-bold blue-grey--text text--darken-2" },
        //{ value: "puntos_tiempo_extra_final_mes", text: "Cierre de mes", align: "center", sortable: false, class: "overline font-weight-bold blue-grey--text text--darken-2" },
        { value: "puntos_padrino", text: "Padrinos", align: "center", sortable: false, class: "overline font-weight-bold blue-grey--text text--darken-2" },
        { value: "puntos_certificaciones", text: "Certificaciones", align: "center", sortable: false, class: "overline font-weight-bold blue-grey--text text--darken-2" },
        { value: "puntos_cierre_mes", text: "Cierre de mes", align: "center", sortable: false, class: "overline font-weight-bold blue-grey--text text--darken-2" },
        { value: "total", text: "Total", align: "center", sortable: false, class: "overline font-weight-bold blue-grey--text text--darken-2" },
      ],
      headers_to_export: {
        Posición: { title: "Posición" },
        Emplid: { title: "Emplid" },
        Nombre: { title: "Nombre" },
        Operación: { title: "Operación" },
        Departamento: { title: "Departamento" },
        Antigüedad: { title: "puntos_antiguedad" },
      },
      loadingRanking: true,
      search: "",
    };
  },
  async mounted() {
    this.rankingCalculado = await this.getRanking();
  },
  methods: {
    async updateTable() {
      this.loadingRanking = true;
      this.rankingCalculado = await this.getRanking();
    },
    getRanking() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      const area = this.selection === 1 ? 3935 : 3936;

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-ranking-operativo?id=${area}`, {
            headers,
          })
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          })
          .finally(() => {
            this.loadingRanking = false;
          });
      });
    },
  }
};
</script>
