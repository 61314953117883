var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.$vuetify.breakpoint.name != 'xs' &&
        _vm.$vuetify.breakpoint.name != 'sm' &&
        _vm.birthDay.length > 0
    )?_c('div',[_c('p',{staticClass:"subtitle-1 font-weight-bold ml-3 mb-1"},[_vm._v(" Cumpleañeros del mes ")]),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.displayedBirthDay),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"rounded-lg mx-1",attrs:{"color":"white","elevation":"0"}},[_c('v-img',{attrs:{"height":"100%","src":"https://static.vecteezy.com/system/resources/previews/007/467/530/original/graphics-festival-flag-multicolor-for-decorative-happy-for-backdrop-background-illustration-free-vector.jpg"}},[_c('v-tooltip',{attrs:{"top":"","color":"blue accent-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"glass-effect elevation-0 rounded-lg ma-2"},'v-card',attrs,false),on),[_c('v-avatar',{staticClass:"profile rounded-circle ml-2 mt-1",attrs:{"color":"grey","size":"80","tile":""}},[(item.photoName != null)?_c('v-img',{attrs:{"lazy-src":require('@/assets/icons/boy-front-clay.png'),"src":item.rutaFoto}}):_c('v-img',{attrs:{"src":require('@/assets/icons/boy-front-clay.png')}})],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(item.NAME)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.fecha))])],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.FULL_NAME)+" "),_c('br'),_vm._v(" "+_vm._s(item.DESCR)+" ")])])],1)],1)],1)}),1),(_vm.birthDay.length > 3)?_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"text-decoration-underline font-weight-bold",attrs:{"text":"","small":"","color":"blue darken-1"},on:{"click":function($event){_vm.showAll = !_vm.showAll}}},[_vm._v(" "+_vm._s(_vm.nameButtonShowBirthdays)+" ")])],1):_vm._e()],1):(
      _vm.$vuetify.breakpoint.name == 'xs' &&
        _vm.$vuetify.breakpoint.name == 'sm' &&
        _vm.birthDay.length > 0
    )?_c('div',[_c('v-card',{staticClass:"ma-3 rounded-lg"},[_c('v-card-title',[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v(" mdi-cake-variant ")]),_c('span',{staticClass:"text-h6 font-weight-light"},[_vm._v("¡Cumpleañeros del mes!")])],1),_c('v-card-text',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" Conoce a los cumpleañeros de este mes ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":_vm.validaRespuesta}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-eye")]),_vm._v(" ver ")],1)],1)],1)],1):(_vm.birthDay.length == 0)?_c('div'):_vm._e(),_c('v-sheet',[_c('data-table-generic',{directives:[{name:"show",rawName:"v-show",value:(_vm.muestraCumpleaneros),expression:"muestraCumpleaneros"}],attrs:{"headers":_vm.headers,"items":_vm.items,"titulo":_vm.titulo,"verDataTable":_vm.muestraCumpleaneros},on:{"cierraTabla":function($event){_vm.muestraCumpleaneros = false}}})],1),_c('v-sheet',{attrs:{"id":"snack-bar-error-incidencias"}},[_c('snack-bar-error-incidencias',{attrs:{"muestraBarra":_vm.muestraBarra,"texto":_vm.textoBarra,"color":_vm.colorBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }