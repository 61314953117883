<template>
    <v-dialog v-model="dialogVisible" max-width="500px">
        <v-card>

            <v-toolbar color="primary" dark style="margin-bottom: 20px;">
                {{ nuevaSeccion.nombre }}
                <v-spacer></v-spacer>
                <v-btn icon dark @click="cerrarDialogo">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-spacer></v-spacer>
            <v-card-text>
                <v-menu ref="menuInicio" v-model="menuInicio" :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="nuevaSeccion.fechaInicio" label="Fecha de Inicio" class="rounded-lg"
                            dense outlined prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
                            v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="nuevaSeccion.fechaInicio" scrollable
                        @input="menuInicio = false"></v-date-picker>
                </v-menu>
                <v-menu ref="menuFin" v-model="menuFin" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="nuevaSeccion.fechaFin" label="Fecha de Fin" class="rounded-lg" dense
                            outlined prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="nuevaSeccion.fechaFin" scrollable @input="menuFin = false"></v-date-picker>
                </v-menu>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" dark @click="guardarSeccion">Guardar</v-btn>
                <v-btn color="error" dark @click="cerrarDialogo">Cancelar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialogVisible: this.value,
            menuInicio: false,
            menuFin: false,
            nuevaSeccion: {
                nombre: '',
                fechaInicio: '',
                fechaFin: ''
            },
            contadorSecciones: 1
        };
    },
    watch: {
        value(val) {
            this.dialogVisible = val;
        },
        dialogVisible(val) {
            if (val) {
                this.nuevaSeccion.nombre = `Sección ${this.contadorSecciones}`;
            }
            this.$emit("input", val);
        }
    },
    methods: {
        guardarSeccion() {
            if (!this.nuevaSeccion.fechaInicio || !this.nuevaSeccion.fechaFin) {
                alert("Todos los campos son obligatorios.");
                return;
            }
            this.$emit("seccionAgregada", { ...this.nuevaSeccion });
            this.contadorSecciones++;
            this.cerrarDialogo();
        },
        cerrarDialogo() {
            this.dialogVisible = false;
            this.nuevaSeccion = { nombre: '', fechaInicio: '', fechaFin: '' };
        }
    }
};
</script>