<template>
  <v-dialog
    v-model="generaVisitaExpress"
    max-width="950"
    content-class="fix-border-dialog"
    scrollable
    persistent
  >
    <v-card class="rounded-xl">
      <v-card-title class="primary">
        <label class="font-weight-bold overline white--text"
          >Genera visita express</label
        >
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraGeneraVisitaExpress')"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="pt-5">
        <div id="impresion" style="display: none;">
          <center>
            <img
              alt=""
              :src="require('@/assets/img/grupo_urrea_negro.png')"
              aling="absmiddle"
              style="margin-bottom: 4px;width:800px; margin-top: 12px;"
            />
          </center>
          <div id="results" style="float: left;width: 30%;margin-top: 36px;">
            <img
              alt=""
              :src="
                photoData !== null
                  ? photoData
                  : require('@/assets/img/blank-profile-picture.webp')
              "
              aling="absmiddle"
              width="205px"
            />
          </div>
          <div style="float: left;width: 70%;">
            <div
              style="text-transform: uppercase; font-family: Arial, sans-serif; font-size: 3.2rem;"
            >
              {{ nombre + " " + paterno + " " + materno }}
            </div>
            <div style="font-family: Arial, sans-serif;  font-size: 3.2rem;">
              <strong>Tipo de visita express: </strong>{{ tipo }}
            </div>
            <div style="font-family: Arial, sans-serif;  font-size: 3.2rem;">
              <strong> Motivo: </strong>{{ motivo }}
            </div>
          </div>
          <br />
        </div>
        <div class="center-item-in-div">
          <v-card class="rounded-lg" width="600" min-height="50">
            <div class="center-item-in-div py-2">
              <v-img
                :src="require('@/assets/img/grupo_urrea_negro.png')"
                max-width="45%"
              ></v-img>
            </div>
            <v-row>
              <v-col cols="4">
                <div class="center-item-in-div py-6">
                  <v-img
                    :src="
                      photoData !== null
                        ? photoData
                        : require('@/assets/img/blank-profile-picture.webp')
                    "
                    max-width="75%"
                  ></v-img>
                </div>
              </v-col>
              <v-col cols="8" class="pt-12">
                <label class="text-uppercase"
                  ><strong>Nombre:</strong>
                  {{ nombre + " " + paterno + " " + materno }}</label
                ><br />
                <label class="text-uppercase"
                  ><strong>Tipo de visita express:</strong> {{ tipo }}</label
                ><br />
                <label class="text-uppercase"
                  ><strong>Motivo:</strong> {{ motivo }}</label
                ><br />
                <!-- <label class="text-uppercase"
                  ><strong>Comentario:</strong> {{ comentario }}</label
                > -->
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="
                  capturarImagen = true;
                  startCamera();
                "
              >
                <v-icon left>mdi-camera-enhance</v-icon>
                Tomar fotografía
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
        <v-form ref="formGafeteExpress" v-model="valid">
          <v-row dense>
            <v-col :cols="isSmallScreen ? 12 : isLargeScreen ? 4 : 6">
              <label class="overline">Nombre(s)</label>
              <v-text-field
                v-model="nombre"
                class="rounded-lg"
                required
                :rules="rules"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col :cols="isSmallScreen ? 12 : isLargeScreen ? 4 : 6">
              <label class="overline">Apellido Paterno</label>
              <v-text-field
                v-model="paterno"
                class="rounded-lg"
                required
                :rules="rules"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col :cols="isSmallScreen ? 12 : isLargeScreen ? 4 : 6">
              <label class="overline">Apellido materno</label>
              <v-text-field
                v-model="materno"
                class="rounded-lg"
                required
                :rules="rules"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col :cols="isSmallScreen ? 12 : isLargeScreen ? 4 : 6">
              <label class="overline">Tipo de visita</label>
              <v-select
                v-model="tipo"
                class="rounded-lg"
                :items="tipos"
                item-text="descr"
                @change="loadingMotivosTipos = true; getMotivosVisita();"
                :loading="loadingTipos"
                required
                :rules="rules"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col :cols="isSmallScreen ? 12 : isLargeScreen ? 8 : 6">
              <label class="overline">Motivo de visita {{ motivosVisita.length }}</label>
              <v-textarea
                v-if="motivosVisita.length == 0"
                v-model="motivo"
                :loading="loadingMotivosTipos"
                class="rounded-lg"
                :required="motivosVisita.length == 0 ? true : false"
                :rules="motivosVisita.length == 0 ? rules : []"
                dense
                outlined
                :rows="1"
              ></v-textarea>
              <v-select
                v-else
                v-model="motivo"
                :loading="loadingMotivosTipos"
                :items="motivosVisita"
                item-text="descr"
                class="rounded-lg"
                :required="motivosVisita.length == 0 ? false : true"
                :rules="motivosVisita.length == 0 ? [] : rules"
                dense
                outlined
                :rows="1"
              ></v-select>
            </v-col>
            <!-- <v-col :cols="isSmallScreen ? 12 : isLargeScreen ? 4 : 6">
              <label class="overline">Comentario</label>
              <v-textarea
                v-model="comentario"
                class="rounded-lg"
                required
                :rules="rules"
                dense
                outlined
                :rows="2"
              ></v-textarea>
            </v-col> -->
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="center-item-in-div">
        <v-btn
          class="rounded-lg"
          large
          color="teal darken-1"
          dark
          :loading="loadingSave"
          @click="loadingSave = true; validaDatos();"
        >
          <v-icon left>mdi-printer-check</v-icon>
          registrar ingreso e imprimir gafete
        </v-btn>
      </v-card-actions>

      <SnackBarErrorIncidencias v-if="muestraBarra" :texto="textoBarra" :color="colorBarra" @cierraBarra="muestraBarra = false" />
    </v-card>

    <v-dialog
      v-model="capturarImagen"
      max-width="500"
      persistent
      content-class="fix-border-dialog"
    >
      <v-card class="rounded-xl">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            @click="
              capturarImagen = false;
              stopCamera();
            "
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text class="pt-4">
          <div>
            <video
              ref="video"
              autoplay
              style="transform: scaleX(-1); max-width: 100%; border-radius: 8px;"
            ></video>
            <!-- <button @click="startCamera">Activar Cámara</button>
                    <button @click="takePhoto">Tomar Foto</button> -->
            <canvas ref="canvas" style="display: none;"></canvas>
            <!-- <img :src="photoData" alt="Foto" v-if="photoData" /> -->
          </div>
        </v-card-text>
        <v-card-actions class="center-item-in-div-column">
          <v-btn color="teal darken-1" dark class="elevation-1" fab>
            <v-icon style="font-size: 52px;" @click="takePhoto"
              >mdi-circle-slice-8</v-icon
            >
          </v-btn>
          <label class="overline teal--text text--darken-1">Tomar foto</label>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import axios from "axios";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "genera-visita-express",
  props: ["generaVisitaExpress"],
  components: {SnackBarErrorIncidencias},
  data() {
    return {
      nombre: "",
      paterno: "",
      materno: "",
      tipo: "",
      motivo: "",
      comentario: "",
      fechaFormateada: "",
      horaFormateada: "",
      colorBarra: "",
      textoBarra: "",
      photoData: null,
      videoStream: null,
      muestraBarra: false,
      capturarImagen: false,
      loadingTipos: true,
      valid: true,
      loadingSave: false,
      loadingMotivosTipos: false,
      tipos: [],
      motivosVisita: [],
      rules: [(v) => !!v || "Campo requerido."],
    };
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.name === "xs" ? true : false;
    },
    isLargeScreen() {
      return ["lg", "xl"].includes(this.$vuetify.breakpoint.name)
        ? true
        : false;
    },
  },
  async mounted(){
    let fechaHoraActual = new Date();
    
    let año = fechaHoraActual.getFullYear();
    let mes = ('0' + (fechaHoraActual.getMonth() + 1)).slice(-2); // Se suma 1 al mes porque los meses van de 0 a 11
    let dia = ('0' + fechaHoraActual.getDate()).slice(-2);

    let horas = ('0' + fechaHoraActual.getHours()).slice(-2);
    let minutos = ('0' + fechaHoraActual.getMinutes()).slice(-2);

    this.fechaFormateada = `${año}-${mes}-${dia}`;
    this.horaFormateada = `${horas}:${minutos}`;

    this.tipos = await this.getTiposVisita()

  },
  methods: {
    openSnackBar(texto, color = "pink darken-3"){
        this.textoBarra = texto;
        this.colorBarra = color;
        this.muestraBarra = true;
    },
    takePhoto() {
      if (this.videoStream) {
        const canvas = this.$refs.canvas;
        const video = this.$refs.video;
        canvas.width = video.offsetWidth;
        canvas.height = video.offsetHeight;
        const context = canvas.getContext("2d");
        context.scale(-1, 1);
        context.drawImage(video, 0, 0, canvas.width * -1, canvas.height);
        context.scale(-1, 1);
        this.photoData = canvas.toDataURL("image/png");
        this.videoStream.getTracks().forEach((track) => track.stop());
        this.capturarImagen = false;
        this.stopCamera();
      }
    },
    async startCamera() {
      try {
        this.videoStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        this.$refs.video.srcObject = this.videoStream;
      } catch (error) {
        console.error("Error al acceder a la cámara:", error);
      }
    },
    stopCamera() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => track.stop());
        this.$refs.video.srcObject = null;
        this.videoStream = null;
      }
    },
    async validaDatos() {
      if (this.$refs.formGafeteExpress.validate()) {
        let respuestaApi = await this.setVisita()
            
        if(respuestaApi){
            if(respuestaApi.status_tran == "OK"){
                this.openSnackBar("Generado con éxito", "teal darken-1")
                this.printGaffete()
                this.$refs.formGafeteExpress.reset()
                this.$emit('cierraGeneraVisitaExpress')
            } else {
                this.openSnackBar("ERROR: " + respuestaApi.message)
            }
        } else {
            this.openSnackBar("ERROR: No se pudo generar el ingreso");
        }
      } else {
        this.openSnackBar("Es necesario que llenes todos los campos.");
        this.loadingSave = false; 
      }
    },
    getTiposVisita() {
      const params = {
        Opcion: 48,
        token: localStorage.getItem("token")
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then(async (response) => {
            if (response.data.status === "OK") {
              resolve(response.data.respuesta);
            } else {
              resolve({
                status_tran: "ERROR",
                message: "No se pudo registrar la visita express",
              });
            }
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => {
            this.loadingTipos = false
          })
      });
    },
    getMotivosVisita(){

      let tipoAux = this.tipos.filter((t) => {
        return t.descr == this.tipo;
      })[0]

      this.motivosVisita.length = 0;

      const params = {
        Opcion: 41,
        token: localStorage.getItem("token"),
        idTipo: tipoAux.id
      }
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status === "OK") {
            this.motivosVisita = response.data.respuesta;
          } 
        })
        .catch((e) => {
          alert(e);
        })
        .finally(() => {
          this.loadingMotivosTipos = false
        })
    },
    setVisita() {
      let buscaTipo = this.tipos.filter((t) => {
        return t.descr == this.tipo
      })[0]
      const params = {
        Opcion: 47,
        token: localStorage.getItem("token"),
        nombre: this.nombre,
        paterno: this.paterno,
        materno: this.materno,
        motivo: this.motivo,
        comentarios: this.comentario,
        idTipo: buscaTipo.id,
        tipo: buscaTipo.descr,
        fecha: this.fechaFormateada,
        entrada: this.horaFormateada,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then(async (response) => {
            if (response.data.status === "OK") {
              resolve(response.data.respuesta);
            } else {
              resolve({
                status_tran: "ERROR",
                message: "No se pudo registrar la visita express",
              });
            }
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => {
            this.loadingSave = false;
          })
      });
    },
    printGaffete() {
      let ficha = document.getElementById("impresion");
      var ventanaImpresion = window.open(" ", "popUp");
      ventanaImpresion.document.write(
        "<html><head><link href='estilos/bootstrap.min.css' rel='stylesheet'><style>.col-sm-2 {width: 16.66666667%;}.col-sm-6 {width: 50%;max-width: 50% }.col{float:left; position: relative;min-height: 1px; padding-right: 15px;padding-left: 15px;}.imgfoto{width: 300px!important;height: 325px!important;}.foto{margin-left: 15px;width: 270px;} center {display: block;text-align: -webkit-center;}img{width:300Px;text-align: -webkit-center;}body{width:1100px;font-size: 46px}</style></head>"
      );
      ventanaImpresion.document.write("<body>");
      ventanaImpresion.document.write(ficha.innerHTML);
      ventanaImpresion.document.write("</body>");
      ventanaImpresion.document.write("</html>");
      ventanaImpresion.document.close();
      ventanaImpresion.print();
      ventanaImpresion.close();
    },
  },
};
</script>
