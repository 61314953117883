// import axios from "axios"
import PuestoEscolaridad from './components/PuestoEscolaridad.vue'
import PuestoPreguntas from './components/PuestoPreguntas.vue'

export default {
    name: 'apego-a-perfil-por-puesto',
    components: {
        PuestoEscolaridad,
        PuestoPreguntas
    },
    props: [],

    data() {
        return {
            jobcode: [],
            idPuesto: '',
            descPuesto: '',
            nivelPuesto: '',
            estPuesto: '',
            loading: true
        }
    },
    computed: {

    },
    created() {
        this.jobcode = JSON.parse(localStorage.getItem('puesto'));

        this.idPuesto = String(this.jobcode.PUESTO);
        // //(this.idPuesto + " Padre")
        this.descPuesto = this.jobcode.DESC;
        this.nivelPuesto = this.jobcode.NIVEL;
        this.estPuesto = this.jobcode.ESTATUS;
        //("padre created")
    },
    mounted() {
        //("padre mounted")
        this.loading = false;
    },
    methods: {

    }
}