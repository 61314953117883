<template>
        <v-card class="rounded-xl elevation-4 mt-1 pa-3">
            <v-data-table dense :headers="headers" :search="search" :items="maquinas"
                no-data-text="No hay registros de tabulador" :footer-props="{
                    'items-per-page-text': 'Elementos por página',
                }" :header-props="{ 'short-by-text': 'Ordenar por', }" loading-text="Cargando contenido..."
                :loading="loadingColaboradores">
                <template v-slot:top>
                    <div style="display: flex; justify-content: space-between;">
                        <v-text-field v-model="search" outlined dense class="rounded-xl" style="max-width: 350px;"
                            prepend-inner-icon="mdi-table-search" clearable
                            placeholder="Buscar en tabla..."></v-text-field>
                        <div>
                            <v-btn depressed class="rounded-pill ml-2 " color="teal" dark @click="dialog = true">
                                <v-icon left> mdi-plus </v-icon>
                                Maquina
                            </v-btn>
                            <v-tooltip bottom color="blue lighten-4">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn outlined small fab depressed color="primary" class="ml-2" v-bind="attrs"
                                        v-on="on" @click="getMaquinas()">
                                        <v-icon>mdi-reload</v-icon>
                                    </v-btn>
                                </template>
                                <label class="text-uppercase font-weight-bold primary--text">Actualizar
                                    tabla</label>
                            </v-tooltip>

                        </div>
                    </div>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon color="cyan darken-2" small @click="openAgregaMaquina(item)">
                        mdi-pencil
                    </v-icon>
                    <v-tooltip bottom color="blue lighten-4">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon color="cyan darken-2" small @click="openDialog(item)" v-bind="attrs"
                                v-on="on">mdi-account-group</v-icon>
                        </template>
                        <label class="text-uppercase font-weight-bold primary--text">Ver asignados</label>
                    </v-tooltip>
                </template>
            </v-data-table>

            <v-dialog v-model="dialog" max-width="600px" persistent>
                <v-card class="rounded-xl">
                    <v-card-title class="text-subtitle-2 primary white--text">
                        <span class="text-h6">{{ editedItem.id ? 'Editar Máquina' : 'Nueva Máquina' }}</span>
                        <v-spacer></v-spacer>
                        <v-icon small dark @click="close">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text class="pt-5">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field v-model="editedItem.nombre_maquina" label="Nombre de la Máquina"
                                        required></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-model="editedItem.ubicacion" label="Ubicación"
                                        required></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-select v-model="editedItem.estado"
                                        :items="['Operativa', 'En mantenimiento', 'Parada']" label="Estado"
                                        required></v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-menu ref="menuUltimo" v-model="menuUltimo" :close-on-content-click="false"
                                        :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="editedItem.ultimo_mantenimiento"
                                                label="Último Mantenimiento" readonly v-bind="attrs" v-on="on"
                                                required></v-text-field>
                                        </template>
                                        <v-date-picker v-model="editedItem.ultimo_mantenimiento"
                                            @input="menuUltimo = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6">
                                    <v-menu ref="menuProximo" v-model="menuProximo" :close-on-content-click="false"
                                        :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="editedItem.proximo_mantenimiento"
                                                label="Próximo Mantenimiento" readonly v-bind="attrs" v-on="on"
                                                required></v-text-field>
                                        </template>
                                        <v-date-picker v-model="editedItem.proximo_mantenimiento"
                                            @input="menuProximo = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-model="editedItem.responsable" label="Responsable"
                                        required></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-autocomplete v-model="editedItem.colaboradores" :items="colaboradores" chips
                                        deletable-chips multiple small-chips item-text="nombreMostrar"
                                        label="Colaboradores Asignados" required></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="blue darken-1" text :disabled="!valid" @click="save">
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogColaboradores" max-width="500px">
                <v-card class="rounded-xl">
                    <v-card-title class="text-subtitle-2 primary white--text">
                        <span class="text-h6">Colaboradores Asignados a {{ selectedMachine.nombre_maquina }}</span>
                        <v-spacer></v-spacer>
                        <v-icon small dark @click="dialogColaboradores = false">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-list>
                            <v-list-item v-for="(colaborador, index) in selectedMachine.colaboradores" :key="index">
                                <v-list-item-content>
                                    <v-list-item-title>{{ colaborador }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <SnackBar v-if="muestraBarra" :muestraBarra="muestraBarra" :color="colorBarra" :texto="textoBarra"
                @cierraBarra="muestraBarra = false" />
    </v-card>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
    name: "muestra-beneficios",
    components: { SnackBar },
    props: {
    },
    data() {
        return {
            headers: [
                { text: 'ID Máquina', value: 'id' },
                { text: 'Nombre de la Máquina', value: 'nombre_maquina' },
                { text: 'Ubicación', value: 'ubicacion' },
                { text: 'Estado', value: 'estado' },
                { text: 'Último Mantenimiento', value: 'ultimo_mantenimiento' },
                { text: 'Próximo Mantenimiento', value: 'proximo_mantenimiento' },
                { text: 'Responsable', value: 'responsable' },
                { text: 'Acciones', value: 'actions', sortable: false }
            ],
            maquinas: [
                {
                    id: 1,
                    nombre_maquina: 'Cortadora CNC A1',
                    ubicacion: 'Planta Norte - Línea 3',
                    estado: 'Operativa',
                    ultimo_mantenimiento: '2024-11-15',
                    proximo_mantenimiento: '2024-12-15',
                    responsable: 'Juan Pérez',
                    colaboradores: ['Pedro López', 'Ana Gómez', 'Luis Hernández'],
                },
                {
                    id: 2,
                    nombre_maquina: 'Prensa Hidráulica B5',
                    ubicacion: 'Planta Sur - Línea 1',
                    estado: 'En mantenimiento',
                    ultimo_mantenimiento: '2024-11-10',
                    proximo_mantenimiento: '2024-12-10',
                    responsable: 'María López',
                    colaboradores: ['Carlos Martínez', 'María López', 'Sofía Hernández'],
                },
                {
                    id: 3,
                    nombre_maquina: 'Torno Automático C3',
                    ubicacion: 'Planta Norte - Línea 2',
                    estado: 'Parada',
                    ultimo_mantenimiento: '2024-11-20',
                    proximo_mantenimiento: '2024-12-20',
                    responsable: 'Carlos Martínez',
                    colaboradores: ['Juan Pérez', 'Elena Rodríguez'],
                },
            ],
            dialog: false,
            valid: false,
            menuUltimo: false,
            menuProximo: false,
            editedItem: {
                id: null,
                nombre_maquina: '',
                ubicacion: '',
                estado: '',
                ultimo_mantenimiento: '',
                proximo_mantenimiento: '',
                responsable: '',
                colaboradores: [],
            },
            colaboradores: [],
            dialogColaboradores: false,
            search: '',
            loadingColaboradores: false,
            selectedMachine: {
                colaboradores: [],
            },
            textoBarra: "",
            colorBarra: "",
            muestraBarra: false,
        }
    },
    async mounted() {
        //this.beneficios = await this.getMaquinas();
        this.getColaboradores();
    },
    methods: {
        muestraSnackBar(color, texto) {
            this.colorBarra = color;
            this.textoBarra = texto;
            this.muestraBarra = true;
        },
        getColaboradores() {
            const params = {
                Opcion: 11,
                token: localStorage.getItem("token"),
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
                    params
                )
                .then((response) => {
                    this.loadingData = false;
                    if (response.data.status === "OK") {
                        this.colaboradores = response.data.respuesta;
                    } else if (response.data.status == "EXPRESSION") {
                        deleteToken();
                        throw new Error('Token expired');
                    } else {
                        throw new Error('Failed to load colaboradores');
                    }
                })
                .catch((error) => {
                    this.openSnackBar(error);
                    console.error('Error loading colaboradores:', error);
                })
                .finally(() => {
                    this.loadingColaboradores = false;
                });
        },
        getMaquinas() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem("token"),
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/Compensaciones/ws-tabulador-beneficios-flexibles.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status == "OK") {
                        this.maquinas = response.data.asignaciones;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingBenefits = false;
                });
        },
        close() {
            this.dialog = false;
            this.resetEditedItem();
        },
        save() {
            // Aquí puedes realizar el manejo de los datos: guardar o editar
            console.log(this.editedItem);
            this.dialog = false;
            this.resetEditedItem();
        },
        resetEditedItem() {
            this.editedItem = {
                id: null,
                nombre_maquina: '',
                ubicacion: '',
                estado: '',
                ultimo_mantenimiento: '',
                proximo_mantenimiento: '',
                responsable: '',
                colaboradores: [],
            };
        },
        openDialog(machine) {
            this.selectedMachine = machine;
            this.dialogColaboradores = true;
        },

    },
};
</script>