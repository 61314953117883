<template>
    <div v-if="!banderaArea2">
        <div v-show="loadingTable" class="center-item-in-div-column">
            <v-progress-circular :size="150" :width="15" color="primary" indeterminate></v-progress-circular>
            <label class="overline font-weight-bold">Cargando información...</label>
        </div>

        <div class="centrado">
            <v-btn text @click="toggleTabla">
                <v-icon v-if="mostrarTabla">mdi-close-box-multiple-outline</v-icon>
                <v-icon v-else>mdi-open-in-app</v-icon>
                <b>Objetivos de &Aacute;rea:</b>
            </v-btn>
        </div>
        <p></p>

        <div class="rounded-lg pb-4 elevation-0" v-show="!loadingTable">
            <v-simple-table id="miTabla" v-if="mostrarTabla" dense style="background-color: transparent;">
                <thead>
                    <tr>
                        <th class="primary rounded-simple-table-head-l" colspan="7"></th>
                        <th class="primary white--text text-uppercase font-weight-bold text-center" colspan="4">
                            Valor real &uacute;ltimo trimestre
                        </th>
                        <th class="primary rounded-simple-table-head-r" colspan="2"></th>
                    </tr>
                    <tr>
                        <th class="primary white--text text-uppercase font-weight-bold text-center"
                            v-for="item in formattedTablaArea" :key="item.id">
                            {{ item.text }}
                        </th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in tablaCombinada" :key="index">
                        <td style="text-align: center;">{{ item.Id_Indicador }}</td>
                        <td>{{ item.ObjetivoEspecifico }}</td>
                        <td style="text-align: center;">{{ item.VO === "" ? '0' : item.VO }}</td>
                        <td style="text-align: center;">{{ item.LE === "" ? '0' : item.LE }}</td>
                        <td style="text-align: center;">{{ item.Ponderacion }}</td>
                        <td style="text-align: center;">{{ item.Unidad }}</td>
                        <td style="text-align: center;">{{ mes1[index] }}</td>
                        <td style="text-align: center;">{{ mes2[index] }}</td>
                        <td style="text-align: center;">{{ mes3[index] }}</td>
                        <td style="text-align: center;">{{ dReal[index].toFixed(2) }}</td>
                        <td style="text-align: center;">{{ desempenio[index].toFixed(2) }}</td>
                        <td style="text-align: center;">{{ puntos[index].toFixed(2) }}</td>
                    </tr>
                    <!-- Fila para Ponderación Total -->
                    <tr>
                        <td colspan="4"></td>
                        <td class="font-weight-bold text-center">Ponderación Total: {{this.ponderacionSuma}}</td>

                    </tr>
                </tbody>
            </v-simple-table>
            <div class="text-right pt-4">
                <label class="font-weight-bold mr-3">
                    Calificación del trimestre: {{ totalPuntos.toFixed(2) }}</label>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "ver-objetivos33",
    props: [
        "Mes1", "Mes2", "Mes3", "iMes", "anio", "iAreaValorBase", "PrimerMesNumValorBase",
        "SegundoMesNumValorBase", "TercerMesNumValorBase", "banderaArea2", "anioTabla"
    ],
    data() {
        return {
            valorTest: 233,
            tabla: [],
            resultados: [],
            id_indicador: [],
            abril: [],
            mayo: [],
            junio: [],
            valores: [],

            // Variables de resultado
            LEnew: [],
            VOnew: [],
            Ponderacionnew: [],
            dReal: [],
            Desemp: [],
            Puntos: [],
            totalPuntos: 0,

            loadingTable: true, // aqui va true
            mostrarTabla: false, // Estado inicial: tabla oculta
            tablaArea: [
                { id: 1, text: 'ID' },
                { id: 2, text: 'Objetivo Específico' },
                { id: 3, text: 'VO' },
                { id: 4, text: 'LE' },
                { id: 5, text: 'Ponderación' },
                { id: 6, text: 'Unidad' },
                { id: 7, text: 'Mes 1:' },
                { id: 8, text: 'Mes 2' },
                { id: 9, text: 'Mes 3' },
                { id: 10, text: 'Resultado' },
                { id: 11, text: 'Desempeño' },
                { id: 12, text: 'Puntos' }
            ]
        };
    },
    async mounted() {
        this.getDatos();
    },
    computed: {
        formattedTablaArea() {
            return this.tablaArea.map(item => {
                if (item.id === 7) item.text = ` ${this.Mes1}`;
                if (item.id === 8) item.text = ` ${this.Mes2}`;
                if (item.id === 9) item.text = ` ${this.Mes3}`;
                return item;
            });
        }
    },
    methods: {
        async combinarDatos() {
            const maxLength = Math.max(this.tabla.length, this.tabla1.length);
            this.tablaCombinada = [];

            for (let i = 0; i < maxLength; i++) {
                const fila = this.tabla[i] || {}; // Datos de la primera tabla o un objeto vacío si no hay datos
                const item = this.tabla1[i] || {}; // Datos de la segunda tabla o un objeto vacío si no hay datos

                // Combina los datos en un nuevo objeto
                this.tablaCombinada.push({
                    Id_Objesp: fila.Id_Objesp || '',
                    ObjetivoEspecifico: fila.ObjetivoEspecifico || '',
                    VO: fila.VO || '',
                    LE: fila.LE || '',
                    Unidad: fila.Unidad || '',
                    Id_Indicador: item.Id_Indicador || '',
                    Ponderacion: item.Ponderacion || '',
                    TipoCalculo: item.TipoCalculo || '',
                    dReal: this.dReal && this.dReal[i] ? this.dReal[i] : 0
                });
            }
        },

        toggleTabla() {
            this.mostrarTabla = !this.mostrarTabla; // Alternar el estado de mostrarTabla
        },

        async enviar() {
            this.$emit('valoresAreaEvent', this.totalPuntos.toFixed(2));
        },

        async getDatos() {
            try {
                this.loadingTable = true; // Asegúrate de que se muestra el cargando
                if (this.iAreaValorBase !== '') {
                    await this.getObjArea1();
                    await this.getObjArea2();
                    await this.combinarDatos();
                    await this.getObjArea3();
                    await this.validaciones();
                    await this.getPuntos();
                    this.enviar();
                }
                this.loadingTable = false; // Desactiva el indicador de carga después de cargar los datos
            } catch (error) {
                this.totalPuntos = 0;
                this.enviar();
                console.error('Error al cargar los datos 1:', error);
                this.loadingTable = false; // Asegúrate de desactivar el cargando incluso si hay un error
            }
        },

        // Primer Query
        async getObjArea1() {
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/bono/${this.iAreaValorBase}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                // Almacenar ID
                this.Ids_Indicador = response.data.data.map(item => item.Id_Indicador);

                // Almacenar Ponderacion
                // this.Ponderaciones = response.data.data.map(item => item.Ponderacion);


                this.Ponderaciones = response.data.data.map(item => parseFloat(item.Ponderacion) || 0); // Convertir a número y manejar valores no numéricos

                // Sumar los valores de Ponderacion
                this.ponderacionSuma = this.Ponderaciones.reduce((acc, val) => acc + val, 0);
                // Almacenar TipoCalculos
                this.TipoCalculos = response.data.data.map(item => item.TipoCalculo);

                this.tabla1 = response.data.data.map(item => ({
                    Id_Indicador: item.Id_Indicador,
                    Ponderacion: item.Ponderacion,
                    TipoCalculo: item.TipoCalculo  // Corregido para asumir un campo TipoCalculo correcto
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        // Segundo query
        async getObjArea2() {
            const idsString = this.Ids_Indicador.join(',');  // Asegúrate de que idsString esté correctamente formateado como una cadena separada por comas si es necesario
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/verObjEspe/${idsString}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });

                // Primero ordena los datos recibidos basados en el ID_OES de manera ascendente
                let sortedData = response.data.data.sort((a, b) => a.ID_OES - b.ID_OES);

                // Ahora que está ordenado, mapea a VOnew y LEnew
                this.VOnew = sortedData.map(item => item.VO);
                this.LEnew = sortedData.map(item => item.LE);

                // Mapea los datos a un formato útil para la tabla
                this.tabla = sortedData.map(item => ({
                    ...item,
                    Id_Objesp: item.ID_OES,
                    ObjetivoEspecifico: item.ObjetivoEspecifico,
                    VO: item.VO,
                    LE: item.LE,
                    Unidad: item.Unidad
                }));
            } catch (error) {
                console.error("Error al obtener los datos:", error);
            }
        },

        // Tercer Query
        // async getObjArea3() {
        //     const idsString12 = this.Ids_Indicador.join(',');
        //      console.log('IDS DE AREA:', idsString12);
        //      console.log('MES 1', this.Mes1, this.PrimerMesNumValorBase, '/', this.SegundoMesNumValorBase, '/', this.TercerMesNumValorBase);
        //     // console.log('MES 2', this.Mes2);
        //     // console.log('MES 3', this.Mes3);
        //     try {
        //         const response = await axios.get(`${this.$store.getters.getHostNode}/api/ver-indValSeguimient/${idsString12}/${this.PrimerMesNumValorBase}/${this.anioTabla}/${this.SegundoMesNumValorBase}/${this.TercerMesNumValorBase}`, {
        //             headers: {
        //                 Authorization: localStorage.getItem("token"),
        //             },
        //         });
        //         if (response && response.data && response.data.data) {
        //             this.resultados = response.data.data;

        //             const partSize = Math.floor(this.resultados.length / 3);
        //             this.mes1 = this.resultados.slice(0, partSize).map(item => item.valorReal);
        //             this.mes2 = this.resultados.slice(partSize, 2 * partSize).map(item => item.valorReal);
        //             this.mes3 = this.resultados.slice(2 * partSize).map(item => item.valorReal);
        //             console.log('valor del los meses:', this.mes1, '/', this.mes2, '/', this.mes3)
        //         } else {
        //             //console.log('Datos no encontrados o respuesta malformada:', response);
        //             // Inicializar los arrays a 0 si no se encuentran valores
        //             this.mes1 = this.Ids_Indicador.map(() => 0);
        //             this.mes2 = this.Ids_Indicador.map(() => 0);
        //             this.mes3 = this.Ids_Indicador.map(() => 0);
        //         }
        //     } catch (error) {
        //         console.error('Error al cargar los datos 2:', error);
        //         // Inicializar los arrays a 0 en caso de error
        //         this.mes1 = this.Ids_Indicador.map(() => 0);
        //         this.mes2 = this.Ids_Indicador.map(() => 0);
        //         this.mes3 = this.Ids_Indicador.map(() => 0);
        //     }
        // },
        //ESTE JALO MAS O MNEOS
//         async getObjArea3() {
//     const idsString12 = this.Ids_Indicador.join(',');
//     console.log('IDS DE AREA:', idsString12);
//     console.log('MES 1', this.Mes1, this.PrimerMesNumValorBase, '/', this.SegundoMesNumValorBase, '/', this.TercerMesNumValorBase);

//     try {
//         const response = await axios.get(
//             `${this.$store.getters.getHostNode}/api/ver-indValSeguimient/${idsString12}/${this.PrimerMesNumValorBase}/${this.anioTabla}/${this.SegundoMesNumValorBase}/${this.TercerMesNumValorBase}`,
//             {
//                 headers: {
//                     Authorization: localStorage.getItem("token"),
//                 },
//             }
//         );

//         if (response && response.data && response.data.data) {
//             this.resultados = response.data.data;

//             // Se espera que la respuesta tenga Ids_Indicador.length * 3 valores
//             const expectedLength = this.Ids_Indicador.length * 3;

//             // Si faltan valores, rellenar con ceros
//             while (this.resultados.length < expectedLength) {
//                 this.resultados.push({ valorReal: 0 });
//             }

//             // Dividir los resultados en tres partes iguales
//             const partSize = Math.ceil(this.resultados.length / 3);
//             this.mes1 = this.resultados.slice(0, partSize).map(item => item.valorReal);
//             this.mes2 = this.resultados.slice(partSize, 2 * partSize).map(item => item.valorReal);
//             this.mes3 = this.resultados.slice(2 * partSize).map(item => item.valorReal);

//             console.log('Valores de los meses:', this.mes1, '/', this.mes2, '/', this.mes3);
//         } else {
//             console.warn('Datos no encontrados o respuesta malformada.');
//             // Inicializar los arrays a 0 si no se encuentran valores
//             this.mes1 = this.Ids_Indicador.map(() => 0);
//             this.mes2 = this.Ids_Indicador.map(() => 0);
//             this.mes3 = this.Ids_Indicador.map(() => 0);
//         }
//     } catch (error) {
//         console.error('Error al cargar los datos 2:', error);
//         // Inicializar los arrays a 0 en caso de error
//         this.mes1 = this.Ids_Indicador.map(() => 0);
//         this.mes2 = this.Ids_Indicador.map(() => 0);
//         this.mes3 = this.Ids_Indicador.map(() => 0);
//     }
// },

async getObjArea3() {
    const idsString12 = this.Ids_Indicador.join(',');
    //console.log('IDS DE AREA:', idsString12);
    //console.log('MES 1', this.Mes1, this.PrimerMesNumValorBase, '/', this.SegundoMesNumValorBase, '/', this.TercerMesNumValorBase);

    try {
        const response = await axios.get(
            `${this.$store.getters.getHostNode}/api/ver-indValSeguimient/${idsString12}/${this.PrimerMesNumValorBase}/${this.anioTabla}/${this.SegundoMesNumValorBase}/${this.TercerMesNumValorBase}`,
            {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            }
        );

        if (response && response.data && response.data.data) {
            // Ordenar resultados por el campo 'Objetivo'
            this.resultados = response.data.data.sort((a, b) => a.Objetivo - b.Objetivo);

            // Agrupar los datos por el campo 'Objetivo'
            const groupedByObjective = this.Ids_Indicador.map((id) => {
                const valuesForObjective = this.resultados
                    .filter((item) => item.Objetivo === id)
                    .map((item) => item.valorReal);
                
                // Si faltan datos, rellenar con ceros
                while (valuesForObjective.length < 3) {
                    valuesForObjective.push(0);
                }

                return valuesForObjective;
            });

            // Dividir en meses según las posiciones de los valores
            this.mes1 = groupedByObjective.map((group) => group[0]);
            this.mes2 = groupedByObjective.map((group) => group[1]);
            this.mes3 = groupedByObjective.map((group) => group[2]);

            //console.log('Valores de los meses ordenados:', this.mes1, '/', this.mes2, '/', this.mes3);
        } else {
            console.warn('Datos no encontrados o respuesta malformada.');
            // Inicializar los arrays a 0 si no se encuentran valores
            this.mes1 = this.Ids_Indicador.map(() => 0);
            this.mes2 = this.Ids_Indicador.map(() => 0);
            this.mes3 = this.Ids_Indicador.map(() => 0);
        }
    } catch (error) {
        console.error('Error al cargar los datos 2:', error);
        // Inicializar los arrays a 0 en caso de error
        this.mes1 = this.Ids_Indicador.map(() => 0);
        this.mes2 = this.Ids_Indicador.map(() => 0);
        this.mes3 = this.Ids_Indicador.map(() => 0);
    }
},



        async getPuntos() {
            const { TipoCalculos, mes1, mes2, mes3, LEnew, VOnew, Ponderaciones } = this;
            let real = mes1.map((val, index) => {
                let tipoCalculo = TipoCalculos[index];
                let dReal = 0;

                if (tipoCalculo == "" || tipoCalculo == "Promedio" || tipoCalculo == "NULL") {
                    dReal = (mes1[index] + mes2[index] + mes3[index]) / 3;
                } else {
                    if (mes3[index] != 0) {
                        dReal = mes3[index];
                    } else if (mes2[index] != 0) {
                        dReal = mes2[index];
                    } else if (mes1[index] != 0) {
                        dReal = mes1[index];
                    }
                }

                return dReal;
            });

            let desempenio = LEnew.map((le, index) => {
                let vo = VOnew[index];
                let result = ((le - real[index]) / (le - vo)) * 100;
                return Math.max(0, Math.min(result, 100)); // Asegurar que está entre 0 y 100
            });

            let puntos = desempenio.map((d, index) => (d * Ponderaciones[index]) / 100);

            this.totalPuntos = puntos.reduce((acc, p) => acc + p, 0);

            // Actualizar el estado con los nuevos valores
            this.desempenio = desempenio;
            this.puntos = puntos;
            // this.totalPuntos = this.totalPuntos;
            // console.log('VALORES DE LOS PUENTOS EN CARLOS:', this.totalPuntos);
        },

        async validaciones() {
            function segundoIF(valor) {
                return valor === null || valor === "Promedio" || valor === "" || valor === "NULL";
            }

            // Esto es para verificar si está vacío
            this.mes1 = this.mes1.map((val, index) => {
                if (val === "") {
                    console.log(`valor1[${index}] es vacío, ajustando a 0`);
                    return 0;
                }
                return val;
            });
            this.mes2 = this.mes2.map((val, index) => {
                if (val === "") {
                    console.log(`valor1[${index}] es vacío, ajustando a 0`);
                    return 0;
                }
                return val;
            });
            this.mes3 = this.mes3.map((val, index) => {
                if (val === "") {
                    console.log(`valor1[${index}] es vacío, ajustando a 0`);
                    return 0;
                }
                return val;
            });

            // Esto es para sacar los resultados
            this.mes1 = this.mes1.map(val => isNaN(parseFloat(val)) ? 0 : parseFloat(val));
            this.mes2 = this.mes2.map(val => isNaN(parseFloat(val)) ? 0 : parseFloat(val));
            this.mes3 = this.mes3.map(val => isNaN(parseFloat(val)) ? 0 : parseFloat(val));

            if (this.mes1.length === this.mes2.length && this.mes2.length === this.mes3.length) {
                this.dReal = this.mes1.map((valor, index) => valor + this.mes2[index] + this.mes3[index]);
                if (this.TipoCalculos.some(segundoIF)) {
                    this.dReal = this.dReal.map(valor => valor / 3);
                } else {
                    if (this.mes3 != 0) this.dReal = this.mes3;
                    else if (this.mes2 != 0) this.dReal = this.mes2;
                    else if (this.mes1 != 0) this.dReal = this.mes1;
                }
            } else {
                console.error('Los arrays no tienen la misma longitud o uno de los valores no es un array');
            }
        }
    }
};
//Mensaje
</script>

<style>
.rounded-simple-table-head-l {
    border-top-left-radius: 22px !important;
}

.rounded-simple-table-head-r {
    border-top-right-radius: 22px !important;
}

.back-card-objectives {
    background: rgb(255, 255, 255) !important;
    background: linear-gradient(301deg,
            rgba(255, 255, 255, 1) 15%,
            rgba(201, 78, 148, 0.7344071417629552) 45%,
            rgba(255, 255, 255, 1) 100%) !important;
}

.centrado {
    text-align: center;
    /* Alinea el texto al centro */
    font-size: 18px;
}
</style>
