import MustraTablaDeRanking from "./components/MustraTablaDeRanking.vue"
export default {
    name: 'ranking-operativo',
    components: { MustraTablaDeRanking },
    props: [],
    data() {
        return {

        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {

    }
}