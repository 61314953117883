<template>
    <v-dialog v-model="showCarousel" :max-width="isSmallScreen ? '95%' : '60%'" max-height="400px" persistent>
        <v-card class="pa-0 rounded-0">
            <v-card-title class="transparent-title">
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeCarousel">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class=" pa-0">
                <v-carousel v-if="imagenes.length" cycle :interval="5000" hide-delimiter-background hide-delimiters
                    height="100%" show-arrows-on-hover @change="onImageChange">
                    <v-carousel-item v-for="image, index in imagenes" :key="index" reverse-transition="fade-transition">
                        <v-img :src="image.url" @click="redirectToLink(image)" class="contain-img" contain></v-img>
                        <div style="display: flex; justify-content: space-between; align-items: center;" class="pr-3">
                            <v-card-subtitle class="overline primary--text">
                                {{ image.titulo }}
                            </v-card-subtitle>
                            <v-icon v-show="image.link" @click="redirectToLink(image)"
                                color="primary">mdi-open-in-new</v-icon>
                        </div>
                    </v-carousel-item>
                </v-carousel>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {
    deleteToken
} from '../../../../../store/Funciones/funciones';
import axios from "axios";

export default {
    name: "ComunicadosInternos",
    props: {
        imagenes: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            showCarousel: true,
            currentImageId: null // index of the currently viewed image
        };
    },
    computed: {
        isSmallScreen() {
            return this.$vuetify.breakpoint.name === "xs" ? true : false;
        },
    },
    methods: {
        redirectToLink(image) {
            axios
                .get(`${this.$store.getters.getHostNode}/api/setEstadisticasLink/${image.folio}`, {
                    headers: {
                        authorization: `${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    if (response.data.message == 'Token inválido') {
                        deleteToken();
                    } else if (response.data.status === 'OK') {
                        console.log('Estadistico actualizado');
                    } else {
                        console.error(response.data.message);
                    }
                })
                .catch(function (e) {
                    console.error(e);
                });
            if (image.link) {
                window.open(image.link, "_blank"); // Open the link in a new tab
            }
        },
        closeCarousel() {
            this.$emit('closeCarousel');
            this.showCarousel = false;
        },
        onImageChange(index) {
            this.currentImageId = this.imagenes[index].folio;
            axios
                .get(`${this.$store.getters.getHostNode}/api/setEstadisticasVisitas/${this.currentImageId}`, {
                    headers: {
                        authorization: `${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    if (response.data.message == 'Token inválido') {
                        deleteToken();
                    } else if (response.data.status === 'OK') {
                        console.log('Estadistico actualizado');
                    } else {
                        console.error(response.data.message);
                    }
                })
                .catch(function (e) {
                    console.error(e);
                });
        },
    },
}
</script>
<style scoped>
.transparent-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.07);
    /* Ajusta el valor RGBA para el nivel de transparencia */
    color: white;
    /* Cambia el color del texto si es necesario */
    z-index: 10;
    /* Asegura que esté encima de otros elementos */
}
</style>