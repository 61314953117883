<template>
  <v-dialog v-model="registraVisita" fullscreen persistent>
    <v-card class="rounded-0">
      <v-card-title class="primary">
        <div class="subtitle-1 white--text text-uppercase font-weight-bold">
          Registra ingreso [{{ datosVisita.id }}].
        </div>
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraVistaGeneraIngreso')">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <div v-show="Math.abs(diferencia) >= 30">
          <div class="caption primary--text font-weight-bold">*Nota</div>
          <div class="caption blue-grey--text text--lighten-2">
            El rango de los horarios pactados de ingreso y salida no coincide
            con la hora actual con una diferencia de
            {{ (Math.abs(diferencia) / 60).toFixed(0) }} horas. Al registrar se
            modificarán como 'Entrada' la hora del registro y la salida se
            calculará con el rango pactado anteriormente
          </div>
        </div>
        <label class="overline"> Resumen </label>
        <v-card
          class="rounded-lg elevation-0 mb-4 pa-5"
          color="blue-grey lighten-5"
        >
          <!-- {{ datosVisita }} -->
          <v-row>
            <v-col :cols="!isExtraSmallScreen ? 4 : 6">
              <v-card class="rounded-lg pa-5 elevation-0" width="100%">
                <label class="overline font-weight-bold">ID</label>
                <div class="body-2">{{ datosVisita.id }}</div>
              </v-card>
            </v-col>
            <v-col :cols="!isExtraSmallScreen ? 4 : 6">
              <v-card class="rounded-lg pa-5 elevation-0" width="100%">
                <label class="overline font-weight-bold">fecha</label>
                <div class="body-2" v-if="datosVisita.DiaFin === null">
                  {{ datosVisita.Dia.date.substring(0, 10) }}
                </div>
                <div class="body-2" v-else>
                  {{
                    datosVisita.Dia.date.substring(0, 10) +
                      " a " +
                      datosVisita.DiaFin.date.substring(0, 10)
                  }}
                </div>
              </v-card>
            </v-col>
            <v-col :cols="!isExtraSmallScreen ? 4 : 6">
              <v-card class="rounded-lg pa-5 elevation-0" width="100%">
                <label class="overline font-weight-bold">horario</label>
                <div class="body-2">
                  {{
                    datosVisita.Entrada.date.substring(11, 16) +
                      " - " +
                      datosVisita.Salida.date.substring(11, 16)
                  }}
                </div>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card class="rounded-lg pa-5 elevation-0" width="100%">
                <label class="overline font-weight-bold">Motivo</label>
                <div class="body-2">{{ datosVisita.Razon }}</div>
              </v-card>
            </v-col>
            <v-col :cols="!isExtraSmallScreen ? 4 : 6">
              <v-card class="rounded-lg pa-5 elevation-0" width="100%">
                <label class="overline font-weight-bold">ubicación</label>
                <div class="body-2">{{ datosVisita.Ubicacion }}</div>
              </v-card>
            </v-col>
            <v-col
              :cols="!isExtraSmallScreen ? 4 : 6"
              v-if="datosVisita.Compania != null && datosVisita.Compania != ''"
            >
              <v-card class="rounded-lg pa-5 elevation-0" width="100%">
                <label class="overline font-weight-bold">Compañía</label>
                <div class="body-2">{{ datosVisita.Compania }}</div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
        <label class="overline"> Datos de visitantes </label>
        <v-card class="rounded-lg elevation-0" color="blue-grey lighten-5">
          <v-card-text>
            <div v-if="loadingVisitantes" class="center-item-in-div">
              <v-progress-circular
                :size="90"
                :width="10"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <!-- {{ datosVisitantes }} -->
              <v-simple-table dense style="background-color: transparent;">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase suptitle-2 blue-grey--text text--darken-2"
                    >
                      ID
                    </th>
                    <th
                      class="text-uppercase suptitle-2 blue-grey--text text--darken-2"
                    >
                      Nombre
                    </th>
                    <th
                      class="text-uppercase suptitle-2 blue-grey--text text--darken-2"
                    >
                      Correo
                    </th>
                    <th
                      class="text-uppercase suptitle-2 blue-grey--text text--darken-2 text-center"
                    >
                      Material registrado
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="visitante in datosVisitantes" :key="visitante.int">
                    <td>{{ visitante.int }}</td>
                    <td class="text-uppercase body-2">
                      {{
                        visitante.nombre +
                          " " +
                          visitante.apellido_paterno +
                          " " +
                          visitante.apellido_materno
                      }}
                    </td>
                    <td>{{ visitante.correo }}</td>
                    <td class="text-center">
                      <v-chip
                        small
                        class="mr-1 my-1"
                        dark
                        :color="
                          visitante.registroArtPersonales === 0
                            ? 'blue-grey darken-1'
                            : 'teal darken-1'
                        "
                      >
                        <v-icon left small>{{
                          visitante.registroArtPersonales === 0
                            ? "mdi-close-octagon"
                            : "mdi-checkbox-marked-circle"
                        }}</v-icon>
                        Art. Personales
                      </v-chip>
                      <v-chip
                        small
                        class="mr-1 my-1"
                        dark
                        :color="
                          visitante.registroConsumibles === 0
                            ? 'blue-grey darken-1'
                            : 'teal darken-1'
                        "
                      >
                        <v-icon left small>{{
                          visitante.registroConsumibles === 0
                            ? "mdi-close-octagon"
                            : "mdi-checkbox-marked-circle"
                        }}</v-icon>
                        Consumibles
                      </v-chip>
                      <v-chip
                        small
                        class="mr-1 my-1"
                        dark
                        :color="
                          visitante.registroHerramientas === 0
                            ? 'blue-grey darken-1'
                            : 'teal darken-1'
                        "
                      >
                        <v-icon left small>{{
                          visitante.registroHerramientas === 0
                            ? "mdi-close-octagon"
                            : "mdi-checkbox-marked-circle"
                        }}</v-icon>
                        Herramientas
                      </v-chip>
                      <v-chip
                        small
                        class="mr-1 my-1"
                        dark
                        :color="
                          visitante.registroVehiculo === 0
                            ? 'blue-grey darken-1'
                            : 'teal darken-1'
                        "
                      >
                        <v-icon left small>{{
                          visitante.registroVehiculo === 0
                            ? "mdi-close-octagon"
                            : "mdi-checkbox-marked-circle"
                        }}</v-icon>
                        Vehículo
                      </v-chip>
                    </td>
                    <td class="text-center">
                      <v-btn
                        class="rounded-lg elevation-1"
                        v-if="visitante.en_plante === 0"
                        small
                        dark
                        color="primary"
                        @click="
                          visitanteSeleccionado = visitante;
                          generaIngreso = true;
                        "
                      >
                        marcar increso
                        <v-icon right
                          >mdi-checkbox-marked-circle-plus-outline</v-icon
                        >
                      </v-btn>
                      <v-chip
                        v-else
                        class="elevation-0 rounded-lg text-uppercase"
                        small
                        dark
                        color="blue-grey darken-1"
                        disabled
                      >
                        ingreso marcado
                        <v-icon small right>mdi-account-check</v-icon>
                      </v-chip>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <GeneraIngresoVisitante
      v-if="generaIngreso"
      :marcaIngreso="generaIngreso"
      :datosVisitante="visitanteSeleccionado"
      @cierraGeneraIngreso="closeDialog"
    />
  </v-dialog>
</template>

<script>
import { deleteToken } from "../../../../../store/Funciones/funciones.js";
import axios from "axios";

import GeneraIngresoVisitante from "./GeneraIngresoVisitante";

export default {
  name: "registra-ingreso-visitante",
  props: ["registraVisita", "datosVisita", "diferencia"],
  components: { GeneraIngresoVisitante },
  data() {
    return {
      datosVisitantes: [],
      visitanteSeleccionado: {},
      loadingVisitantes: true,
      generaIngreso: false,
    };
  },
  computed: {
    isExtraSmallScreen() {
      return this.$vuetify.breakpoint.name === "xs" ? true : false;
    },
  },
  async mounted() {
    this.datosVisitantes = await this.getDatosVisitantesPorVisita();
  },
  methods: {
    async closeDialog(response) {
      if (response) {
        this.loadingVisitantes = true;
        this.datosVisitantes = await this.getDatosVisitantesPorVisita();
      }

      this.generaIngreso = false;
    },
    async getDatosVisitantesPorVisita() {
      const params = {
        Opcion: 14,
        token: localStorage.getItem("token"),
        idVisita: this.datosVisita.id,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.visitantes);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingVisitantes = false;
          });
      });
    },
  },
};
</script>
