<template>
  <v-card class="pa-2 my-2 rounded-lg elevation-0" color="blue-grey lighten-5">
    <div v-if="visitantes.length > 0">

      <v-data-table
        dense
        :headers="headers"
        :items="visitantes"
        style="background-color: transparent;"
        :single-expand="true"
        :expanded.sync="expanded"
        item-key="int"
        show-expand
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0 ma-0">
            <v-sheet class="elevation-1 pa-2"  color="blue-grey lighten-5">
              <MuestraMaterialesPorVisitante :idVisitante="item.int"/>
            </v-sheet>
          </td>
        </template>
        <template v-slot:item.materiales="{ item }">
          <v-chip
            small
            class="mr-1"
            dark
            :color="
              item.registroArtPersonales === 0
                ? 'blue-grey darken-1'
                : 'teal darken-1'
            "
          >
            <v-icon left small>{{
              item.registroArtPersonales === 0
                ? "mdi-close-octagon"
                : "mdi-checkbox-marked-circle"
            }}</v-icon>
            Art. Personales
          </v-chip>
          <v-chip
            small
            class="mr-1"
            dark
            :color="
              item.registroConsumibles === 0
                ? 'blue-grey darken-1'
                : 'teal darken-1'
            "
          >
            <v-icon left small>{{
              item.registroConsumibles === 0
                ? "mdi-close-octagon"
                : "mdi-checkbox-marked-circle"
            }}</v-icon>
            Consumibles
          </v-chip>
          <v-chip
            small
            class="mr-1"
            dark
            :color="
              item.registroHerramientas === 0
                ? 'blue-grey darken-1'
                : 'teal darken-1'
            "
          >
            <v-icon left small>{{
              item.registroHerramientas === 0
                ? "mdi-close-octagon"
                : "mdi-checkbox-marked-circle"
            }}</v-icon>
            Herramientas
          </v-chip>
          <v-chip
            small
            class="mr-1"
            dark
            :color="
              item.registroVehiculo === 0
                ? 'blue-grey darken-1'
                : 'teal darken-1'
            "
          >
            <v-icon left small>{{
              item.registroVehiculo === 0
                ? "mdi-close-octagon"
                : "mdi-checkbox-marked-circle"
            }}</v-icon>
            Vehículo
          </v-chip>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import { deleteToken } from "../../../../../store/Funciones/funciones";
import axios from "axios";
import MuestraMaterialesPorVisitante from "./MuestraMaterialesPorVisitante.vue";

export default {
  name: "muestra-visitantes-por-grupo",
  props: ["idVisita"],
  components: {MuestraMaterialesPorVisitante},
  data() {
    return {
      visitantes: [],
      expanded: [],
      headers: [
        { value: "int", text: "ID" },
        { value: "nombre", text: "visante" },
        { value: "correo", text: "correo" },
        {
          value: "materiales",
          text: "Registro de materiales",
          align: "center",
          sortable: false,
        },
        { text: '', value: 'data-table-expand' },
      ],
      loadingVisitantes: true,
    };
  },
  async mounted() {
    this.visitantes = await this.getDatosVisitantesPorVisita();
  },
  methods: {
    async getDatosVisitantesPorVisita() {
      const params = {
        Opcion: 14,
        token: localStorage.getItem("token"),
        idVisita: this.idVisita,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status === "EXPRESSION") {
              deleteToken();
            } else {
              resolve(response.data.visitantes);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingVisitantes = false;
          });
      });
    },
  },
};
</script>
