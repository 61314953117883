<template>
  <v-row justify="center">
    <v-dialog v-model="visible" width="400px" max-width="80%" scrollable>
      <v-card id="persona-valores-dialog" class="rounded-lg">
        <v-card-title v-if="valoraciones.length > 0 && valoraciones[0].valor" class="primary white--text py-2 px-3">
          <v-icon dark left v-show="valoraciones[0].valor === 'Somos'">mdi-account-group</v-icon>
          <v-icon dark left v-show="valoraciones[0].valor === 'Pasion'">mdi-heart</v-icon>
          <v-icon dark left v-show="valoraciones[0].valor === 'No toleramos'">mdi-account-alert-outline</v-icon>
          <v-icon dark left v-show="valoraciones[0].valor === 'Solucionar'">mdi-tools</v-icon>
          <v-icon dark left v-show="valoraciones[0].valor === 'Buscamos'">mdi-magnify</v-icon>
          <v-icon dark left v-show="valoraciones[0].valor === 'Mejoramos'">mdi-account-arrow-up-outline</v-icon>
          {{ valoraciones[0].valor }}
          <v-spacer></v-spacer>
          <v-icon small dark @click="$emit('closed')">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-0 pb-1 pa-2">
          <v-img v-if="imagen" :src="require('@/assets/icons/' + imagen)" contain />
          <persona-valoracion-card class="ma-2" v-for="valoracion in valoraciones" :content="valoracion"
            :key="valoracion.key">
          </persona-valoracion-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import PersonaValoracionCard from '@/components/PersonaValoracionCard.vue'
export default {
  name: 'persona-valores-dialog',
  data: () => ({
    visible: false
  }),
  props: [
    "dialogVisible",
    "valoraciones",
    "imagen"
  ],
  watch: {
    dialogVisible(isVisible) {
      //console.log('dialogVisible changed:', isVisible);
      this.visible = isVisible;
    },
    visible(isVisible) {
      if (!isVisible) this.$emit('closed');
    }
  },
  components: {
    PersonaValoracionCard
  },
  mounted() {
    // console.log('valoraciones:', this.valoraciones);
    // console.log('imagen:', this.imagen);
  }


}

</script>
<style lang="sass">

  #persona-valores-dialog

    .actions
      position: absolute
      bottom: 12px
      right: 12px
      .v-btn
        margin-right: 6px

</style>
