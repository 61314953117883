<template>
    <v-dialog v-model="showCarousel" :max-width="isSmallScreen ? '85%' : '60%'" max-height="400px" persistent>
        <v-card class="pa-0 rounded-0">
            <v-card-title class="transparent-title">
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeCarouselMFA">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class=" pa-0">
                <v-carousel v-if="imagenesMFA.length" cycle :interval="5000" hide-delimiter-background hide-delimiters
                    height="100%" show-arrows-on-hover @change="onImageChange">
                    <v-carousel-item v-for="image, index in imagenesMFA" :key="index"
                        reverse-transition="fade-transition">
                        <v-img :src="image.url" class="contain-img" contain></v-img>
                        <div style="display: flex; justify-content: space-between; align-items: center;" class="pr-3">
                            <v-card-subtitle class="overline primary--text">
                                {{ image.titulo }}
                            </v-card-subtitle>

                        </div>
                    </v-carousel-item>
                </v-carousel>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {
    deleteToken
} from '../../../../../store/Funciones/funciones';
import axios from "axios";

export default {
    name: "ComunicadosInternos",
    props: {
        imagenesMFA: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            showCarousel: true,
            currentImageId: null, // index of the currently viewed image
            pollingInterval: null
        };
    },
    computed: {
        isSmallScreen() {
            return this.$vuetify.breakpoint.name === "xs" ? true : false;
        },
    },
    methods: {
        closeCarouselMFA() {
            this.$emit('closeCarouselMFA');
            this.showCarousel = false;
            clearInterval(this.pollingInterval);
        },
        onImageChange(index) {
            this.currentImageId = this.imagenesMFA[index].folio;
            axios
                .get(`${this.$store.getters.getHostNode}/api/setEstadisticasVisitasMFA/${this.currentImageId}`, {
                    headers: {
                        authorization: `${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    if (response.data.message == 'Token inválido') {
                        deleteToken();
                    } else if (response.data.status === 'OK') {
                        console.log('Estadistico actualizado');
                    } else {
                        console.error(response.data.message);
                    }
                })
                .catch(function (e) {
                    console.error(e);
                });
        },
        async setTimeContenidoConsumido() {
            axios
                .get(`${this.$store.getters.getHostNode}/api/setTimeContenidoConsumido/${this.currentImageId}`, {
                    headers: {
                        authorization: `${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    if (response.data.message == 'Token inválido') {
                        deleteToken();
                    } else if (response.data.status === 'OK') {
                        console.log('Estadistico actualizado');
                    } else {
                        console.error(response.data.message);
                    }
                })
                .catch(function (e) {
                    console.error(e);
                });
        }
    },
    mounted() {
        // Hacer la primera petición inmediatamente
        this.setTimeContenidoConsumido();

        // Configurar el intervalo para repetir la petición cada 5 segundos
        this.pollingInterval = setInterval(() => {
            this.setTimeContenidoConsumido();
        }, 5000);
    },
    unmounted() {
        // Limpiar el intervalo para evitar fugas de memoria
        clearInterval(this.pollingInterval);
    }
}
</script>
<style scoped>
.transparent-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.07);
    /* Ajusta el valor RGBA para el nivel de transparencia */
    color: white;
    /* Cambia el color del texto si es necesario */
    z-index: 10;
    /* Asegura que esté encima de otros elementos */
}
</style>