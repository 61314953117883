<template>
  <div>
    <div v-show="loadingTable" class="center-item-in-div-column">
      <v-progress-circular :size="150" :width="15" color="primary" indeterminate></v-progress-circular>
      <label class="overline font-weight-bold">Cargando información...</label>
    </div>

    <!-- Esto es apra cuando es Responsable y chapmpions -->
    <div v-show="banderaBTNResponsable && banderaBotones &&banderaTabla" class="my-4">
      <v-row justify="center">
        <v-col cols="auto">
          <v-btn class="primary white--text text-uppercase font-weight-bold text-center" @click="irAReporteO_Resp">
            <v-icon>{{ 'mdi-file-chart-outline' }}</v-icon>&nbsp;Reporte de Objetivos Responsable
          </v-btn>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="auto">
          <v-btn class="primary white--text text-uppercase font-weight-bold text-center" @click="irAReporteO">
            <v-icon>{{ 'mdi-file-chart-outline' }}</v-icon>&nbsp;Reporte de Objetivos
          </v-btn>
        </v-col>

        <v-col cols="auto">
          <v-btn class="primary white--text text-uppercase font-weight-bold text-center" @click="metodoResponsable">
            <v-icon>{{ 'mdi-card-account-details-outline' }}</v-icon>&nbsp;Objetivos Empleado Responsable 
          </v-btn>
        </v-col>

        <v-col cols="auto" v-show="banderaBTNRespMaster">
          <v-btn class="primary white--text text-uppercase font-weight-bold text-center" @click="metodoMasterEMP_RESP">
            <v-icon>{{ 'mdi-card-account-details-outline' }}</v-icon>&nbsp;Empleado Responsable  
          </v-btn>
        </v-col>

      </v-row>
    </div>

    <!-- Estos osn los bontenes de Responsable -->
    <div v-show="banderaBTNResponsable && banderaTabla && banderaBotones != true" class="my-4">
      <v-row justify="center">
        <v-col cols="auto">
          <v-btn class="primary white--text text-uppercase font-weight-bold text-center" @click="irAReporteO_Resp">
            <v-icon>{{ 'mdi-file-chart-outline' }}</v-icon>&nbsp;Reporte de Objetivos Responsable
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- ESTO SON LOS BOTONES PARA LAS OTRAS OPCIONES DE LOS MODULOS -->
    <div v-show="bnadera2Btn && banderaTabla  && banderaBotones != true" class="my-4">
      <v-row justify="center">
        <v-col cols="auto"  v-if="numEmpleado !== '0'">
          <v-btn class="primary white--text text-uppercase font-weight-bold text-center" @click="irAReporteO">
            <v-icon>{{ 'mdi-file-chart-outline' }}</v-icon>&nbsp;Reporte de Objetivos 
          </v-btn>
        </v-col>

      </v-row>
    </div>

    <!-- Esto es para los ADMING -->
    <div v-show="banderaADMING && banderaTabla  && banderaBotones != true " class="my-4">
      <v-row justify="center">
        <v-col cols="auto">
          <v-btn class="primary white--text text-uppercase font-weight-bold text-center" @click="irAReporteO">
            <v-icon>{{ 'mdi-file-chart-outline' }}</v-icon>&nbsp;Reporte de Objetivos 
          </v-btn>
        </v-col>

        <v-col cols="auto" v-show="banderaBTNRespMaster">
          <v-btn class="primary white--text text-uppercase font-weight-bold text-center" @click="metodoResponsable">
            <v-icon>{{ 'mdi-card-account-details-outline' }}</v-icon>&nbsp;Empleado Responsable 
          </v-btn>
        </v-col>

        <v-col cols="auto" v-if="numEmpleado !== '0'">
          <v-btn class="primary white--text text-uppercase font-weight-bold text-center" @click="metodoEmpResp">
            <v-icon>{{ 'mdi-card-account-details-outline' }}</v-icon>&nbsp;Empleado Responsable 
          </v-btn>
        </v-col>


        <v-col cols="auto" >
          <v-btn class="primary white--text text-uppercase font-weight-bold text-center" @click="metodoMasterEMP_RESP">
            <v-icon>{{ 'mdi-card-account-details-outline' }}</v-icon>&nbsp;Objetivos Empleado Responsable 
          </v-btn>
        </v-col>


      </v-row>
    </div>

    <!-- ESTO ES PARA SOLO chapmpions -->
    <div v-show="banderaTabla  && banderaBotones && banderaBTNResponsable === false" class="my-4">
      <v-row justify="center">
        <v-col cols="auto">
          <v-btn class="primary white--text text-uppercase font-weight-bold text-center" @click="irAReporteO">
            <v-icon>{{ 'mdi-file-chart-outline' }}</v-icon>&nbsp;Reporte de Objetivos
          </v-btn>
        </v-col>

        <v-col cols="auto">
          <v-btn class="primary white--text text-uppercase font-weight-bold text-center" @click="metodoEmpResp">
            <v-icon>{{ 'mdi-card-account-details-outline' }}</v-icon>&nbsp;Objetivos Empleado Responsable 
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div class="centrado">
      <v-btn text>
        <b>&nbsp;Información General de Objetivos</b>
      </v-btn>
    </div>
    <p></p>

    <div class="rounded-lg pb-4 elevation-0" v-show="banderaTabla">
      <v-simple-table dense style="background-color: transparent;">
        <thead>
          <tr>
            <th v-if="banderaArea2" width="100px"></th>
            <th v-if="banderaPropios22" width="100px"></th>

            <th class="primary rounded-simple-table-head-l" colspan="1" width="150px"></th>
            <th v-if="!banderaPropios22 && !banderaArea2"
              class="primary white--text text-uppercase font-weight-bold text-center" colspan="1" width="150px">
              Objetivos Propios<br /><span style="font-size: 11px">Mis Objetivos</span>
            </th>
            <th v-if="!bandera3 && !banderaPropios22 && !banderaArea2"
              class="primary white--text text-uppercase font-weight-bold text-center" colspan="1" width="150px">
              Objetivos de Departamento<br /><span style="font-size: 11px">Objetivos de Mi Jefe</span>
            </th>
            <th v-if="!banderaArea2" class="primary white--text text-uppercase font-weight-bold text-center" colspan="1"
              width="150px">
              Objetivos de &Aacute;rea<br /><span style="font-size: 11px">Objetivos de mi Director</span>
            </th>
            <th
              class="primary rounded-simple-table-head-r primary white--text text-uppercase font-weight-bold text-center"
              colspan="1" width="150px">
              Objetivos de CIA<br /><span style="font-size: 11px">Compa&ntilde;ia</span>
            </th>
          </tr>
          <tr style="text-align: center;">
            <th v-if="banderaArea2" width="100px"></th>
            <th v-if="banderaPropios22" width="100px"></th>
            <td class="primary white--text text-uppercase font-weight-bold text-center" style="font-size: 12px;"
              width="150px">Puntos Maximos</td>
            <td v-if="!banderaPropios22 && !banderaArea2" class="text-center" width="150px">100</td>
            <td v-if="!bandera3 && !banderaPropios22 && !banderaArea2" class="text-center" width="150px">100</td>
            <td v-if="!banderaArea2" class="text-center" width="150px">100</td>
            <td class="text-center" width="150px">100</td>
          </tr>
          <tr style="text-align: center;">
            <th v-if="banderaArea2" width="100px"></th>
            <th v-if="banderaPropios22" width="100px"></th>
            <td class="primary white--text text-uppercase font-weight-bold text-center" style="font-size: 12px;"
              width="150px">Puntos Obtenidos</td>
            <td v-if="!banderaPropios22 && !banderaArea2" width="150px" nowrap="nowrap"><span>{{
              roundNumber(varObjetivos) }}</span></td>
            <td v-if="!bandera3 && !banderaPropios22 && !banderaArea2" nowrap="nowrap" width="150px"><span>{{
              roundNumber(varEviado) }}</span></td>
            <td v-if="!banderaArea2" nowrap="nowrap" width="150px"><span>{{ roundNumber(varArea) }}</span></td>
            <td nowrap="nowrap" width="150px"><span>{{ roundNumber(varCompany) }}</span></td>
          </tr>
          <tr>
            <th v-if="banderaArea2" width="100px"></th>
            <th v-if="banderaPropios22" width="100px"></th>
            <td class="primary white--text text-uppercase font-weight-bold text-center" style="font-size: 12px;"
              width="150px">Porcentaje</td>
            <td v-if="!banderaPropios22 && !banderaArea2" class="text-center" width="150px">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">{{ ponderacionObjetivosIndividualesValorBase }} %</div>
                </template>
                Equivale al {{ ponderacionObjetivosIndividualesValorBase }}% de tu bono.
              </v-tooltip>
            </td>
            <td v-if="!bandera3 && !banderaPropios22 && !banderaArea2" class="text-center" width="150px">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">{{ ponderacionObjetivosDepartamentoValorBase }} %</div>
                </template>
                Equivale al {{ ponderacionObjetivosDepartamentoValorBase }}% de tu bono.
              </v-tooltip>
            </td>
            <td v-if="!banderaArea2" class="text-center" width="150px">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">{{ ponderacionObjetivosAreaValorBase }} %</div>
                </template>
                Equivale al {{ ponderacionObjetivosAreaValorBase }}% de tu bono.
              </v-tooltip>
            </td>
            <td class="text-center" width="150px">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">{{ ponderacionObjetivosCIAValorBase }} %</div>
                </template>
                Equivale al {{ ponderacionObjetivosCIAValorBase }}% de tu bono.
              </v-tooltip>
            </td>
          </tr>
          <tr style="text-align: center;">
            <th v-if="banderaArea2" width="100px"></th>
            <th v-if="banderaPropios22" width="100px"></th>
            <td class="primary white--text text-uppercase font-weight-bold text-center" style="font-size: 12px;"
              width="150px">
              Calificaci&oacute;n</td>
            <td v-if="!banderaPropios22 && !banderaArea2" width="150px" nowrap="nowrap"><span>{{ calMiObj.toFixed(1)
                }}</span>
            </td>
            <td v-if="!bandera3 && !banderaPropios22 && !banderaArea2" nowrap="nowrap" width="150px"><span>{{
              calMiJefe.toFixed(1)
                }}</span></td>
            <td v-if="!banderaArea2" nowrap="nowrap" width="150px"><span>{{ callMiDirector.toFixed(1) }}</span></td>
            <td nowrap="nowrap" width="150px"><span>{{ calCompania.toFixed(1) }}</span></td>
          </tr>
          <tr align="center">
            <th v-if="banderaArea2" width="100px"></th>
            <th v-if="banderaPropios22" width="100px"></th>
            <td class="primary white--text text-uppercase font-weight-bold text-center" style="font-size: 12px;"
              width="150px">
              Calificación Final</td>

            <!-- Mis Objetivos + Objetivos de mi Director + Objetivos de Compañía -->
            <td v-if="bandera3" colspan="4" class="text-center" width="150px">
              Mis Objetivos + Objetivos de mi Director + Objetivos de Compa&ntilde;ia =
            </td>

            <!-- Objetivos de mi Director + Objetivos de Compañía -->
            <td v-else-if="banderaPropios22" colspan="2" class="text-center" width="150px">
              Objetivos de mi Director + Objetivos de Compa&ntilde;ia =
            </td>

            <!-- Objetivos de Compañía -->
            <td v-else-if="banderaArea2" colspan="1" class="text-center" width="150px">
              Objetivos de Compa&ntilde;ia =
            </td>

            <!-- Mis Objetivos + Objetivos de mi Jefe + Objetivos de mi Director + Objetivos de Compañía -->
            <td v-else colspan="4" class="text-center" width="150px">
              Mis Objetivos + Objetivos de mi Jefe + Objetivos de mi Director + Objetivos de Compa&ntilde;ia =
            </td>
          </tr>

          <tr align="center">
            <th v-if="banderaArea2 == true" width="100px"></th>
            <th v-if="banderaArea2 == true" width="100px"></th>
            <th v-if="banderaPropios22 == true" width="100px"></th>
            <td v-if="bandera3 == false && banderaArea2 == false && banderaPropios22 == false" width="150px"></td>
            <th v-if="bandera3 == true" width="100px"></th>
            <td v-if="banderaPropios22 == true"></td>
            <td v-if="banderaPropios22 == true" colspan="2" width="150px">
              <span>{{ calFinal.toFixed(1) }} Puntos</span>
            </td>
            <td v-else-if="bandera3 == true" colspan="4" width="150px">
              <span>{{ calFinal.toFixed(1) }} Puntos</span>
            </td>
            <td v-else-if="banderaArea2 == true" colspan="1" width="150px">
              <span>{{ calFinal.toFixed(1) }} Puntos</span>
            </td>
            <td v-else colspan="4" width="150px">
              <span>{{ calFinal.toFixed(1) }} Puntos</span>
            </td>
          </tr>


          <tr v-if="bandera3 === false && banderaPropios22 === false && banderaArea2 === false" align="center">
            <td class="primary white--text text-uppercase font-weight-bold text-center" style="font-size: 12px;">Cálculo
              de bono
            </td>
            <td class="text-center" style="text-align: center;" colspan="4"> (<span>{{ calFinal.toFixed(1) }}</span>) x
              20%</td>
          </tr>

          <tr v-else-if="bandera3 === true" align="center">
            <td class="primary white--text text-uppercase font-weight-bold text-center" style="font-size: 12px;">Cálculo
              de bono
            </td>
            <td class="text-center" style="text-align: center;" colspan="3"> (<span>{{ calFinal.toFixed(1) }}</span>) x
              20%</td>
          </tr>

          <tr v-else-if="banderaPropios22 === true" align="center">
            <th v-if="banderaPropios22"></th>
            <td class="primary white--text text-uppercase font-weight-bold text-center" style="font-size: 12px;">Cálculo
              de bono
            </td>
            <td class="text-center" style="text-align: center;" colspan="2"> (<span>{{ calFinal.toFixed(1) }}</span>) x
              20%</td>
            <td width="15%"></td>

          </tr>

          <tr v-else-if="banderaArea2 === true" align="center">
            <th v-if="banderaArea2"></th>
            <td class="primary white--text text-uppercase font-weight-bold text-center" style="font-size: 12px;">Cálculo
              de bono
            </td>
            <td class="text-center" style="text-align: center;" colspan="1"> (<span>{{ calFinal.toFixed(1) }}</span>) x
              20%</td>

          </tr>

          <tr v-if="banderaArea2 == true" align="left">
            <td width="30%"></td>
            <td colspan="1" align="center" height="25">Bono simulado: <span>{{ bonoSimulado.toFixed(1) }}</span></td>
            <td colspan="1" align="center">Bono hasta el momento: <span>{{ bonoHasMomento.toFixed(1) }}</span></td>
            <td width="30%"></td>
          </tr>
          <tr v-else-if="banderaPropios22 == true" align="left">
            <td width="13%"></td>
            <td></td>
            <td colspan="1" align="center" height="25">Bono simulado: <span>{{ bonoSimulado.toFixed(1) }}</span></td>
            <td colspan="1" align="center">Bono hasta el momento: <span>{{ bonoHasMomento.toFixed(1) }}</span></td>
            <td width="15%"></td>
          </tr>
          <tr v-else-if="bandera3 == true" align="left">
            <td></td>
            <td colspan="1" align="center" height="25">Bono simulado: <span>{{ bonoSimulado.toFixed(1) }}</span></td>
            <td></td>
            <td colspan="1" align="center">Bono hasta el momento: <span>{{ bonoHasMomento.toFixed(1) }}</span></td>
          </tr>
          <tr v-else-if="bandera3 == false && banderaArea2 == false && banderaPropios22 == false" align="left">
            <th></th>
            <td colspan="1" align="center" height="25">Bono simulado: <span>{{ bonoSimulado.toFixed(1) }}</span></td>
            <th></th>
            <th></th>
            <td colspan="1" align="center">Bono hasta el momento: <span>{{ bonoHasMomento.toFixed(1) }}</span></td>
          </tr>
        </thead>
        <tbody></tbody>
      </v-simple-table>
      
    </div>
  </div>
</template>

<script>
 import axios from "axios";
 

export default {
  props: ["varEviado", "varArea", "varCompany", "varObjetivos", "numEmpleado", "bandera3", "banderaArea2", "banderaPropios22",
    "banderaBotones",
     "poCIAColab", "poAreaColab", "poDepartamentoColab", "poIndividualesColab",
     "poCIADir", "poAreaDir", "poDepartamentoDir", "poIndividualesDir",
     "poCIACoord", "poAreaCoord", "poDepartamentoCoord", "poIndividualesCoord",
     "bnadera2Btn", "banderaBTNResponsable", "banderaADMING", "AreaUser", "gTrabajo"
  ],
  name: "ver-objetivosInfoGeneralO",
  data() {
    return {
      idPerfilColav: 'COLABORADOR',
      idPerfilColav2: 'RESPONSABLE',
      idPerfilColav3: 'COMITE',

      Ids_Empleado: 0,
      banderaBtn1: false,
      banderaBtn2: false,
      banderaBtn2Resp: false,
      banderaRESP: false,
      

      // Variables de la tabla info general de objetivos
      ponderacionObjetivosCIAValorBase: null,
      ponderacionObjetivosAreaValorBase: null,
      ponderacionObjetivosDepartamentoValorBase: null,
      ponderacionObjetivosIndividualesValorBase: null,
      // CALIFICACIÓN
      calMiObj: 0,
      calMiJefe: 0,
      callMiDirector: 0,
      calCompania: 0,
      // CALIFICACIÓN FINAL
      calFinal: 0,
      // BONO SIMULADO Y BONO HASTA EL MOMENTO
      bonoSimulado: 0,
      bonoHasMomento: 0,

      loadingTable: true,
      banderaTabla: false,
      banderaBTNRespMaster: false,
   
    };
  },

 async created() {
  this.banderaTabla = false;
  //console.log('valor de la bandera%%%%%%%%%', this.banderaTabla);
},

  async mounted() {
  
    //this.banderaTabla = false;
    await this.checkVariables();
    // console.log('vlaor de la bandera3', this.bandera3);
    console.log('valor de gTrabajo:' ,this.gTrabajo);
   

  },
  watch: {
    varEviado: 'checkVariables',
    varArea: 'checkVariables',
    varCompany: 'checkVariables',
    varObjetivos: 'checkVariables',
  },
  methods: {



    irAReporteO() {
      this.banderaBtn1 = true;
      this.banderaRESP = false;
      this.$emit('valorBandera1', this.banderaBtn1);
      this.$emit('valorBanderaRESP', this.banderaRESP);
    },

    //Esto es para responsable
    irAReporteO_Resp() {
      this.banderaBtn1 = true;
      this.banderaRESP = true;
      this.$emit('valorBandera1', this.banderaBtn1);
      this.$emit('valorBanderaRESP', this.banderaRESP);
    },

    metodoEmpResp(){
      console.log('valor de areauser:', this.AreaUser);
      if (this.AreaUser == "ADMING" || this.AreaUser == "ADMIN" || this.AreaUser == "ADML1" || this.AreaUser == "ADML1"){

        console.log('entro en el primero');
        this.irEmpRespAdmin();
      }else{
        console.log('entro en el segundo');
        this.irAEmpResp();
      }     

    },

    metodoResponsable(){


      //console.log('entro en el primero');
      this.irAEmpResp();
      
    },

    metodoMasterEMP_RESP(){

      this.irEmpRespAdmin();
    },

    irEmpRespAdmin(){
      this.banderaBtn2Resp = true;
      this.$emit('enviarVarResp', this.banderaBtn2Resp);
      console.log('valor de bandera: ', this.banderaBtn2Resp)

    },

  
    irAEmpResp() {
      this.banderaBtn2 = true;
      this.$emit('valorBandera2', this.banderaBtn2);
    },

  
    async checkVariables() {
    // Verificar si las variables tienen valor
    if (this.varEviado && this.varArea && this.varCompany && this.varObjetivos) {
      //console.log('Valores de la talbja gneral:', this.varEviado, this.varArea, this.varCompany, this.varObjetivos);
      // Primero ejecutamos porcentajeFinal y esperamos su resultado
      await this.porcentajeFinal();
      // Después calculamos la calificación con los valores obtenidos
      await this.calcularCalificacion();
      // Finalmente, mostramos la tabla
      this.banderaTabla = true;
    }

    if(this.numEmpleado === '9363' || this.numEmpleado === '8631' ){
      this.banderaBTNRespMaster = true;
      console.log('valido el banderaBTNRespMaster')
    }else{
      this.banderaBTNRespMaster = false;
    }
  },

  async calcularCalificacion() {
    //console.log('Valor de individuales:', this.ponderacionObjetivosIndividualesValorBase);
    if (this.varObjetivos != 0) {
      this.calMiObj = this.varObjetivos * (this.ponderacionObjetivosIndividualesValorBase / 100);
    }
    if (this.varEviado != 0) {
      this.calMiJefe = this.varEviado * (this.ponderacionObjetivosDepartamentoValorBase / 100);
    }
    if (this.varArea != 0) {
      this.callMiDirector = this.varArea * (this.ponderacionObjetivosAreaValorBase / 100);
    }
    if (this.varCompany != 0) {
      this.calCompania = this.varCompany * (this.ponderacionObjetivosCIAValorBase / 100);
    }

    if (this.varObjetivos != 0 || this.varEviado != 0 || this.varArea != 0 || this.varCompany != 0) {
      this.calFinal = this.calMiObj + this.calMiJefe + this.callMiDirector + this.calCompania;
      this.bonoSimulado = this.calFinal * 0.2;
      this.bonoHasMomento = this.calFinal * 0.2;
    }
  },

  async porcentajeFinal() {
    // Esto es para la validación del porcentaje que tiene que tener % participación de cada uno de los objetivos
    //console.log('Bandera Propios 22:', this.bandera3);
    if (this.bandera3 == true) {
      await this.getPorcentaje2();
    } else if (this.banderaPropios22 == true) {
      await this.getPorcentaje3();
    } else if (this.banderaArea2 == true) {
      this.ponderacionObjetivosCIAValorBase = 100;
      this.ponderacionObjetivosAreaValorBase = 0;
      this.ponderacionObjetivosIndividualesValorBase = 0;
      this.ponderacionObjetivosDepartamentoValorBase = 0;
      this.loadingTable = false;
    } else {
      await this.getPorcentaje();
    }
  },

  getPorcentaje() {
    return new Promise((resolve) => {
      axios
        .get(`${this.$store.getters.getHostNode}/api/infoGeneralObj/${this.idPerfilColav}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          resolve(response.data.data);
          this.ponderacionObjetivosCIAValorBase = response.data.data[0].ponderacionObjetivosCIA;
          this.ponderacionObjetivosAreaValorBase = response.data.data[0].ponderacionObjetivosArea;
          this.ponderacionObjetivosDepartamentoValorBase = response.data.data[0].ponderacionObjetivosDepartamento;
          this.ponderacionObjetivosIndividualesValorBase = response.data.data[0].ponderacionObjetivosIndividuales;
        })
        .finally(() => {
          this.loadingTable = false;
        });
    });
  },

  getPorcentaje2() {
    return new Promise((resolve) => {
      axios
        .get(`${this.$store.getters.getHostNode}/api/infoGeneralObj/${this.idPerfilColav2}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          resolve(response.data.data);
          this.ponderacionObjetivosCIAValorBase = response.data.data[0].ponderacionObjetivosCIA;
          this.ponderacionObjetivosAreaValorBase = response.data.data[0].ponderacionObjetivosArea;
          this.ponderacionObjetivosDepartamentoValorBase = response.data.data[0].ponderacionObjetivosIndividuales;
          this.ponderacionObjetivosIndividualesValorBase = response.data.data[0].ponderacionObjetivosDepartamento;
        })
        .finally(() => {
          this.loadingTable = false;
        });
    });
  },

  getPorcentaje3() {
    return new Promise((resolve) => {
      axios
        .get(`${this.$store.getters.getHostNode}/api/infoGeneralObj/${this.idPerfilColav3}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          resolve(response.data.data);
          this.ponderacionObjetivosCIAValorBase = response.data.data[0].ponderacionObjetivosCIA;
          this.ponderacionObjetivosAreaValorBase = response.data.data[0].ponderacionObjetivosArea;
          this.ponderacionObjetivosDepartamentoValorBase = response.data.data[0].ponderacionObjetivosDepartamento;
          this.ponderacionObjetivosIndividualesValorBase = response.data.data[0].ponderacionObjetivosIndividuales;
        })
        .finally(() => {
          this.loadingTable = false;
        });
    });
  },
    
    roundNumber(value) {
      return Math.round(value * 10) / 10;
    }
  },
};
//Mensaje
</script>

<style>
.rounded-simple-table-head-l {
  border-top-left-radius: 22px !important;
}

.rounded-simple-table-head-r {
  border-top-right-radius: 22px !important;
}

.back-card-objectives {
  background: rgb(255, 255, 255) !important;
  background: linear-gradient(301deg, rgba(255, 255, 255, 1) 15%, rgba(201, 78, 148, 0.7344071417629552) 45%, rgba(255, 255, 255, 1) 100%) !important;
}

.centrado {
  text-align: center;
  /* Alinea el texto al centro */
  font-size: 18px;
}
</style>
